import { Row, Col, Tooltip } from 'gokwik-ui-kit'
import React, { useEffect, useState } from 'react'

type Props = {
    formState?: any
    discountType?: any
}

const SummaryCard = (props: Props) => {
    const { formState, discountType } = props
    const [actions, setActions] = useState<any>({})
    const [conditions, setConditions] = useState<any>({})
    const getUserEligiblity = () => {
        if (formState?.eligible_customer?.all_customers) {
            return 'All customers'
        } else if (formState?.eligible_customer?.customers?.mapping?.length) {
            return 'Customers'
        } else if (formState?.eligible_customer?.segments?.mapping?.length) {
            return 'Segments'
        }
    }

    useEffect(() => {
        setActions(formState?.rules[0]?.actions[0])
        setConditions(formState?.rules[0]?.conditions[0])
    }, [formState])
    return (
        <div>
            {discountType === 'capping' && (
                <Row gutter={[6, 6]} justify={'space-around'} className='text-[#000000E0] text-sm font-normal'>
                    <Col>
                        <ul className='flex flex-col gap-y-2'>
                            <li>
                                Discount percentage:{' '}
                                {actions?.value && (
                                    <span className='truncate'>{actions?.value}%</span>
                                )}
                            </li>
                            <li>
                                {' '}
                                Maximum Value:{' '}
                                {actions?.max_discount && (
                                    <span className='truncate'>
                                        <Tooltip title={actions?.max_discount}>
                                            ₹{formState?.rules[0]?.actions[0]?.max_discount}
                                        </Tooltip>
                                    </span>
                                )}{' '}
                            </li>
                            <li>
                                Combines with: <span className='capitalize'>{formState?.combinations?.join(', ')}</span>
                            </li>
                            <li className='capitalize'>User Eligibility: {getUserEligiblity()}</li>
                        </ul>
                    </Col>
                </Row>
            )}

            {discountType === 'dynamic' && (
                <Row className='text-[#000000E0] text-sm font-normal p-0'>
                    <Col>
                        <ul className='flex flex-col gap-y-2'>
                            <li>{actions?.applicability_type && (
                                <span className='capitalize'>
                                    type: {actions?.applicability_type?.includes('fixed')
                                        ? 'Flat'
                                        : 'Percentage'}{' '}

                                </span>
                            )}</li>
                            <li>
                                <div className='flex truncate'>
                                    {' '}
                                    <span>
                                        Value:{' '}
                                        {actions?.applicability_type?.includes(
                                            'percentage',
                                        ) && <span>%</span>}
                                    </span>{' '}
                                    <span>{actions?.value}</span>
                                </div>
                            </li>

                            <li>
                                <div className='flex truncate'>
                                    <span>Min Order Value:</span>{' '}
                                    <span>{conditions?.min_cart_value}</span>
                                </div>
                            </li>
                            <li>
                                <div className='flex truncate'>
                                    <span> Max Order Value:</span>{' '}
                                    <span> {conditions?.max_cart_value}</span>
                                </div>
                            </li>
                            <li>Combines with: <span className='capitalize'>{formState?.combinations?.join(', ')}</span></li>

                        </ul>
                    </Col>
                </Row>
            )}

            {discountType === 'quantity' && (
                <Row gutter={[6, 6]} justify={'space-around'} className='text-[#000000E0] text-sm font-normal'>
                    <Col
                    >
                        <ul className='pl-3 flex flex-col gap-y-2' >
                            <li>
                            {actions?.discount_method && (
                                <span className='capitalize'>
                                    type: {formState?.rules[0]?.actions[0]?.discount_method}
                                </span>
                            )}
                            </li>
                            <li>
                                <div className='flex justify-between truncate'>
                                    {' '}
                                    <span>
                                        Value:{' '} <span> {actions?.value}</span>
                                        {actions?.discount_method ===
                                            'percentage' && <span>%</span>}
                                    </span>{' '}
                                    
                                </div>
                            </li>
                            <li>
                                <div className='flex justify-between truncate'>
                                    {' '}
                                    <span>Min Quantity:</span>{' '}
                                    <span> {conditions?.min_product_quantity}</span>
                                </div>
                            </li>
                            {actions?.max_discount && (
                                <li>
                                    <div className='flex justify-between truncate'>
                                        {' '}
                                        <span>Max Order Value:</span>{' '}
                                        <span> {actions?.max_discount}</span>
                                    </div>
                                </li>
                            )}
                            <li> Combines with: <span className='capitalize'>{formState?.combinations?.join(', ')}</span>
                            </li>
                        </ul>
                    </Col>


                </Row>
            )}

            {discountType === 'buy_x_at_y' && (
                <Row gutter={[6, 6]} justify={'space-around'} className='text-[#000000E0] text-sm font-normal'>
                    {formState?.rules?.map((rule, i) => {
                        return (
                            <Col key={i}>
                                <ul className='pl-3'>
                                    <li>Minimum quantity of items: {rule?.conditions[0]?.min_product_quantity}</li>
                                    <li>Customer gets: {rule?.actions[0]?.value}</li>
                                    <li>
                                        Combines with:{' '}
                                        <span className='capitalize'>{formState?.combinations?.join(', ')}</span>
                                    </li>
                                    <li>
                                        Appicable on:{' '}
                                        <span className='capitalize'>
                                            {rule.conditions[0].collection_name_mapping?.length > 0 && 'Collections'}
                                        </span>
                                        <span className='capitalize'>
                                            {rule.conditions[0].product_name_mapping?.length > 0 && ' Products'}
                                        </span>
                                    </li>
                                    <li className='capitalize'>User Eligibility: {getUserEligiblity()}</li>
                                </ul>
                            </Col>
                        )
                    })}
                </Row>
            )}

            {discountType === 'gift_with_product' && (
                <Row gutter={[6, 6]} justify={'space-around'} className='text-[#000000E0] text-sm font-normal'>
                    {formState?.rules?.map((rule, i) => {
                        return (
                            <Col key={i}>
                                <ul className='pl-3'>
                                    {rule?.conditions[0]?.min_product_quantity > 0 && (
                                        <li>Minimum quantity of items: {rule?.conditions[0]?.min_product_quantity}</li>
                                    )}
                                    {rule?.conditions[0]?.min_product_amount > 0 && (
                                        <li>Minimum purchase amount: {rule?.conditions[0]?.min_product_amount}</li>
                                    )}
                                    <li>Number of products customer gets: {rule?.actions[0]?.free_product_quantity}</li>
                                    {rule?.conditions[0]?.limit_per_order > 0 && (
                                        <li>Maximum uses per order: {rule?.conditions[0]?.limit_per_order}</li>
                                    )}
                                    <li>
                                        Combines with:{' '}
                                        <span className='capitalize'>{formState?.combinations?.join(', ')}</span>
                                    </li>
                                    
                                    <li className='capitalize'>User Eligibility: {getUserEligiblity()}</li>
                                </ul>
                            </Col>
                        )
                    })}
                </Row>
            )}
        </div>
    )
}

export { SummaryCard }
