import dayjs from 'dayjs'

export const PermissionValues = {
    orders: {
        view: 'view_order',
        initiateRefund: 'refund',
    },
    abandonedCarts: {
        view: 'abandoned_cart',
    },
    settings: {
        view: 'setting',
    },
    checkoutAnalytics: {
        view: 'view_checkout_analytics',
    },
    reports: {
        view: 'view_reports',
    },
}

export const DiscountConfigKeys = {
    tieredPrepaidDiscount: 'show_tiered_prepaid_discount',
    discountCode: 'show_discount_code',
    gwpAndExtraDiscount: 'show_gwp_and_extra_discount',
    codChargeConfig: 'show_cod_charge_config',
    discountApplicability: 'show_discount_applicability',
    dynamicDiscount: 'show_dynamic_discounts',
    buyXAtYDiscounts: 'show_buy_x_at_y_discounts',
    gwpWithAnyDiscount: 'show_gwp_with_any_discount',
    discountCapping: 'show_discount_capping',
}

export const cohortDiscountTooltips = {
    csvDownload: [
        'Upload a CSV file containing Phone Numbers eligible for the discount.',
        'The file must be in attached CSV format.',
    ],
}

export const DiscountApplicabilityKeys = {
    shopify: { label: 'Shopify', value: 'shopify' },
    uploaded_CSV: { label: 'Uploaded CSV', value: 'uploaded_csv' },
    include: 'Include',
    exclude: 'Exclude',
    included: 'Included',
    excluded: 'Excluded',
}

export const OffersDiscountKeys = {
    only_when_eligible: { label: 'Only When Eligible', value: 'ONLY_WHEN_ELIGIBLE' },
    always: { label: 'Always', value: 'ALWAYS' },
    shopify_based_cohort: { label: 'Shopify Based Cohort', value: 'shopify_based_cohort' },
    csv_based_cohort: { label: 'CSV Based Cohort', value: 'csv_based_cohort' },
    all: { label: 'All', value: 'all' },
    new_users_only: { label: 'New Users Only', value: 'new' },
    repeat_users_only: { label: 'Repeat Users Only', value: 'existing' },
}

export const stepConfiguration = {
    password_setup: { id: 0, stepNumber: 0 },
    feature_tour_done: { id: 1, stepNumber: 1 },
    smart_cod_app_installed: { id: 2, stepNumber: 2 },
    smart_cod_app_activated: { id: 3, stepNumber: 3 },
    gokwik_shipping_setup_done: { id: 4, stepNumber: 4 },
    smart_cod_app_disabled: { id: 5, stepNumber: 5 },
    imp_checks_passed: { id: 6, stepNumber: 6 },
    kyc_viewed: { id: 7, stepNumber: 7 },
    kyc_basic_info_added: { id: 8, stepNumber: 8 },
    kyc_address_details: { id: 9, stepNumber: 9 },
    kyc_bank_details: { id: 10, stepNumber: 10 },
    kyc_business_details: { id: 11, stepNumber: 11 },
    kyc_document_upload: { id: 12, stepNumber: 12 },
    kyc_vpc: { id: 13, stepNumber: 13 },
    kyc_verification_pending: { id: 14, stepNumber: 14 },
    kyc_verification_failed: { id: 15, stepNumber: 15 },
    kyc_completed: { id: 16, stepNumber: 16 },
    kwik_checkout_button_activated: { id: 17, stepNumber: 17 },
    setup_completed: { id: 18, stepNumber: 18 },
}

export const RTO_FAKE_ORDER_REASONS = [
    'Multiple COD orders within short time frame',
    'Gibberish address',
    'Gibberish name',
]

export const manualDiscountInitialValues = {
    discountMethod: 'manual',
    discountType: 'cartItemsDiscount',
    minimumRequirements: 'noMinimum',
    topUpGifts: false,
    discountApplicableOn: 'allProducts',
    discountCartItemType: 'percentage',
    enableCombineManualDiscounts: false,
    enableCombineAutomaticDiscounts: false,
    customerEligibility: 'all',
    enableUtmParameters: false,
    discountCodeVisibilityEnabled: false,
    salesChannelWebsite: true,
    salesChannelMobileApplication: true,
}

export const bulkDiscountInitialValues = {
    discountMethod: 'manual',
    discountType: 'cartItemsDiscount',
    minimumRequirements: 'noMinimum',
    topUpGifts: false,
    discountApplicableOn: 'allProducts',
    discountCartItemType: 'percentage',
    enableCombineManualDiscounts: false,
    enableCombineAutomaticDiscounts: false,
    customerEligibility: 'all',
    enableUtmParameters: false,
    codeIdentifier: 'Prefix',
    salesChannelWebsite: true,
    salesChannelMobileApplication: true,
    expiry: dayjs().add(3, 'month').set('hour', 23).set('minute', 59).set('second', 59),
}

export const automaticDiscountInitialValues = {
    discountMethod: 'automatic',
    enableOverwriteManualDiscounts: false,
    enableCombineManualDiscounts: false,
    matchType: 1,
}

export const Freebies = 'Freebie'

export const DiyDiscountVariants = [
    {
        key: 'cartItemsDiscount',
        value: 'Give Discount on Cart Items',
    },
    {
        key: 'discountedCartItems',
        value: 'Add Discounted Items to Cart',
    },
    {
        key: 'bundleDiscount',
        value: 'Give Bundle Discount',
    },
    {
        key: 'tieredDiscount',
        value: 'Give Tiered Discount',
    },
    {
        key: 'shippingDiscount',
        value: 'Give Shipping Discount',
    },
]

export const ShopifyCollectionPlaceholderImage =
    'https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-collection-1_large.png?v=1530129113'

export const ShopifyProductPlaceholderImage =
    'https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?v=1530129081'

export const snapchatEvents = {
    addBilling: 'snapchat_add_billing',
    initiateCheckout: 'snapchat_start_checkout',
    purchase: 'snapchat_purchase',
}
export const ga4CustomEvents = [
    'gokwik_checkout_initiated',
    'order_summary_clicked_gk',
    'mobile_number_entered_gk',
    'otp_sent_gk',
    'otp_entered_gk',
    'otp_verified_gk',
    'edit_address_clicked_gk',
    'edit_mobile_clicked_gk',
    'add_address_page_landed_gk',
    'add_address_continue_gk',
    'coupon_list_viewed',
    'coupon_applied_success',
    'coupon_applied_failed',
]

export const ga4StandardEvents = ['begin_checkout', 'add_shipping_info', 'add_payment_info', 'purchase']

export const clickPostPayload = (values?: any) => {
    return {
        edd: {
            apiCall: {
                url: values?.url,
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                payload: { type: 'array', drop_pincode: '{pincode}', pickup_pincode: values?.pickup_pincode },
                timeout: 10000,
                response: { tat: ['result', 0, 'predicted_sla_min'] },
            },
            purpose: 'edd',
            fallback: true,
            provider: 'clickpost',
        },
        serviceability: {
            apiCall: {
                url: values?.url,
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                payload: { type: 'array', drop_pincode: '{pincode}', pickup_pincode: values?.pickup_pincode },
                timeout: 10000,
                isPincodeString: true,
            },
            purpose: 'serviceability',
            fallback: true,
            provider: 'clickpost',
            ignore_serviceability_edd: 'true',
        },
    }
}

export const farziPayload = (values?: any) => {
    return {
        serviceability: {
            apiCall: {
                url: values?.url,
                method: 'POST',
                payload: { type: 'json', query: 'query { pincode(pin: "{pincode}") { city, serviceable, state } }' },
                timeout: 10000,
            },
            purpose: 'serviceability',
            fallback: true,
            provider: 'farzi',
            serviceabilityFlags: {
                cod: { serviceable: true },
                upi: { serviceable: true },
                no_delivery: { pincode: null, serviceable: false },
            },
        },
    }
}

export const shiprocketPayload = (values?: any) => {
    return {
        edd: {
            apiCall: {
                url: values?.url,
                method: 'post',
                headers: { 'Content-Type': 'application/json', Authorization: 'Bearer {token}' },
                payload: {
                    type: 'json',
                    items: '{items}',
                    store_url: values?.store_url,
                    company_id: values?.company_id,
                    postal_code: '{pincode}',
                    channel_code: values?.channel_code,
                },
                timeout: 10000,
                response: { tat: ['rates', 0, 'min_delivery_date'] },
            },
            purpose: 'edd',
            fallback: true,
            provider: 'shiprocket',
            tokenCall: {
                url: values?.token_url,
                method: 'post',
                payload: { type: 'json', email: values?.email, password: values?.password },
                timeout: 10000,
                response: { token: ['token'] },
            },
            vendor_name: values?.vendor_name,
        },
        serviceability: {
            apiCall: {
                url: values?.url,
                method: 'get',
                headers: { 'Content-Type': 'application/json', Authorization: 'Bearer {token}' },
                payload: {
                    cod: false,
                    type: 'json',
                    weight: values?.weight,
                    pickup_postcode: values?.pickup_postcode,
                    delivery_postcode: '{pincode}',
                },
                timeout: 10000,
                isPincodeString: true,
            },
            purpose: 'serviceability',
            fallback: true,
            provider: 'shiprocket',
            tokenCall: {
                url: values?.token_url,
                method: 'post',
                payload: { type: 'json', email: values?.email, password: values?.password },
                timeout: 10000,
                response: { token: ['token'] },
            },
            vendor_name: values?.vendor_name,
            serviceabilityFlags: { cod: { cod: 1 }, upi: { cod: 1 }, no_delivery: {} },
        },
    }
}

export const ecomPayload = (values?: any) => {
    return {
        serviceability: {
            apiCall: {
                url: values?.url,
                method: 'POST',
                payload: {
                    type: 'formdata',
                    username: values?.username,
                    password: values?.password,
                    pincode: '{pincode}',
                },
                timeout: 4000,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
            purpose: 'serviceability',
            fallback: true,
            provider: 'ecom',
            serviceabilityFlags: {
                cod: { active: true },
                upi: { active: true },
                no_delivery: { active: false },
            },
        },
    }
}
export const delhiveryPayload = (values?: any) => {
    return {
        serviceability: {
            apiCall: {
                url: values?.url,
                method: 'GET',
                payload: {
                    token: values?.token,
                    filter_codes: '{pincode}',
                    type: 'params',
                },
                timeout: 4000,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
            purpose: 'serviceability',
            fallback: true,
            provider: 'delhivery',
            serviceabilityFlags: {
                cod: {
                    cod: 'Y',
                },
                upi: {
                    pre_paid: 'Y',
                },
                no_delivery: {
                    remarks: 'Embargo',
                },
            },
        },
    }
}
export const kwikflowActionMapping = {
    cod_blocking: 'COD Blocking',
    cod_fees: 'COD Fees',
    cod_prompt: 'COD Prompt',
    cod_captcha: 'COD Captcha',
    allow_cod: 'Allow COD',
    ppcod_upi: 'Partial COD',
    upi_discount: 'UPI Discount',
    apply_freebie: 'Freebie Applied',
}
