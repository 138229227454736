import { IShopifyAppPPCODTieredAmount } from '@library/utilities/interface'
import { ShopifyAppPPCODTypeEnum } from '@library/utilities/types'

export const tierDefaultValues: IShopifyAppPPCODTieredAmount = {
    lowerLimit: 0,
    upperLimit: null,
    type: ShopifyAppPPCODTypeEnum.fixed,
    amount: 1,
    minDeduction: null,
    maxDeduction: null,
}

export const ppcodTypes = [
    {
        label: 'Standard',
        value: 'standard',
    },
    {
        label: 'Tag based',
        value: 'tag',
    },
    {
        label: 'Risk flag based',
        value: 'risk',
    },
]

export const maxTiers = 5

export const ppcodTooltips = {
    baseCaseFlag: 'Toggle to enable or disable the PPCOD feature for your store',
    ppcodType:
        'Standard : applies a default PPCOD across all orders, Tag-based : applies PPCOD based on tags, Risk flag based : applies PPCOD based on predefined risk flags associated with orders',
    riskFlags:
        'Select risk flags that will trigger PPCOD when applied to an order. Risk flags help identify potentially high-risk transactions',
    cartRange:
        'Define the range of cart values (minimum to maximum) for which the PPCOD feature will be applied. Orders outside this range will not have PPCOD enabled',
    price: 'Set the fixed or percentage amount to be charged as Partial payment',
    minCharge: 'Set the minimum PPCOD charges that can be applied to an order',
    maxCharge: 'Set the maximum PPCOD charges that can be applied to an order',
}
