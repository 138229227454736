import {
    Button,
    LoadingOutlined,
    message,
    QuestionCircleOutlined,
    Tooltip,
    Upload,
    UploadOutlined,
} from 'gokwik-ui-kit'
import { useEffect, useRef, useState } from 'react'
import { makeAPICall } from '@gokwik/utilities'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import useShopifyMerchantConfigApi from '@library/utilities/hooks/useShopifyMerchantConfig'
import { logoSources } from '../shopify-app-ui-customisations/gokwik-checkout-ui/constants'

const LogoUpload = ({ setOnSave, setOnDiscard, handleUnsavedChanges, setIsLogoUploaded }) => {
    const merchantDetails = useSelector(getMerchantDetails)
    const { config, refetch } = useShopifyMerchantConfigApi({
        useCache: true,
    })
    const [logoUrl, setLogoUrl] = useState(null)
    const [loading, setLoading] = useState(false)
    const logoUrlRef = useRef(logoUrl)
    const timestamp = Date.now()

    useEffect(() => {
        if (config?.logo) {
            setLogoUrl(config.logo)
            logoUrlRef.current = config.logo
            setIsLogoUploaded(true)
        }
    }, [config?.logo])

    useEffect(() => {
        setOnSave(handleSave)
        setOnDiscard(handleDiscard)
    }, [setOnSave, setOnDiscard])

    const getPresignedUrl = async (logo) => {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + '/api/dashboard/utility/presigned-url',
            payload: {
                path: `merchant/${merchantDetails?.m_id}/logo${timestamp}.${logo?.name.split('.').pop()}`,
                type: 'logo',
            },
        })
        if (response.success) {
            return response.data.data.url
        } else {
            console.error('getPresignedUrl failed')
        }
    }

    const updateMerchant = async () => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateMerchant(merchantDetails?.m_id),
                payload: {
                    logo: logoUrl,
                },
            })
            if (response.success) {
                setIsLogoUploaded(true)
                message.success('Logo uploaded successfully')
            } else {
                message.error('Logo upload failed')
                setLogoUrl(config?.logo)
                logoUrlRef.current = config?.logo
            }
        } catch (error) {
            console.error(error)
            setLogoUrl(config?.logo)
            logoUrlRef.current = config?.logo
        }
    }

    const uploadLogo = async (logo) => {
        const signedURl = await getPresignedUrl(logo)
        try {
            const awsResponse = await fetch(signedURl, {
                method: 'PUT',
                body: logo,
            })

            if (awsResponse.status == 200) {
                const url = `merchant/${merchantDetails?.m_id}/logo${timestamp}.${logo?.name.split('.').pop()}`
                logoUrlRef.current = url
                setLogoUrl(url)
                handleUnsavedChanges(true)
            } else {
                message.error('Logo upload failed')
            }
        } catch (error) {
            console.error(error)
            message.error('Logo upload failed')
        }
    }

    const handleDiscard = () => {
        setLogoUrl(config?.logo)
        handleUnsavedChanges(false)
    }

    const handleSave = async () => {
        if (logoUrlRef.current && logoUrl && logoUrlRef.current !== config?.logo) {
            await updateMerchant()
            refetch()
        }

        handleUnsavedChanges(false)
    }

    //validate logo size
    const validateLogoSize = (file) => {
        const maxSizeInBytes = 128 * 1024 // 128KB in bytes
        const isLt128KB = file.size < maxSizeInBytes
        if (!isLt128KB) {
            message.error('File must be upto 128KB!')
        }
        return isLt128KB
    }

    const handleLogoUploadChange = (info) => {
        if (info.file?.status === 'uploading') {
            setLoading(true)
            return
        }
        if (info.file?.status === 'done') {
            setLoading(false)
            uploadLogo(info.file?.originFileObj)
        }
    }

    return (
        <div className='flex flex-col mb-15 items-center space-y-2 bg-gray-100 min-h-screen'>
            <div className='p-5 bg-white rounded-lg w-full'>
                <label className='text-base font-bold'>
                    Upload Brand Logo
                    <Tooltip title={<>This logo will be visible to users on CoD confirmation screen</>}>
                        <QuestionCircleOutlined className='ml-2' />
                    </Tooltip>
                </label>
                <p className='text-gray-500 text-xs mt-2 mb-2'>
                    You can upload files up to 128KB. Consider using SVG files instead of JPG or PNG for their
                    scalability and smaller size.
                </p>
                <Upload
                    className='mx-50'
                    maxCount={1}
                    multiple={false}
                    accept='.jpeg,.png,.jpg,.svg'
                    showUploadList={false}
                    beforeUpload={validateLogoSize}
                    customRequest={(info) => {
                        info.onSuccess('')
                    }}
                    onChange={handleLogoUploadChange}
                >
                    <Button disabled={loading}>{loading ? <LoadingOutlined /> : <UploadOutlined />} Upload</Button>
                </Upload>
                {logoUrl && (
                    <div className='mt-2'>
                        <img
                            src={logoSources(logoUrl)[0]}
                            alt='logo'
                            height={80}
                            width={80}
                            onError={(e) => {
                                if (e.target instanceof HTMLImageElement) {
                                    const img = e.target
                                    const currentSrc = img.src
                                    const currentIndex = logoSources(logoUrl).indexOf(currentSrc)
                                    if (logoUrl && currentIndex < 3) {
                                        img.src = logoSources(logoUrl)[currentIndex + 1]
                                    }
                                }
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default LogoUpload
