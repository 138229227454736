import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { NoShippingAvailableMesage } from './shipping-component/shippingBasedError'
import { Col, Row } from 'gokwik-ui-kit'
import ShippingService from './shipping-component/shippingBasedService'
import { useEffect, useState } from 'react'
import { updateBreadcrumbs } from '@gokwik/utilities'

const Shipping = () => {
    const [isShipping,setShipping] = useState(false);
    const { config} = useMerchantConfigApi({
        configSaveUrl: APIEndPoints?.merchantConfigs,
    })

    const shippingOptionList = config?.shipping_prices;

    useEffect(() => {
            updateBreadcrumbs((prev) => [
                prev[0],
                {
                    key: 'checkout-settings',
                    href: '/checkout/settings',
                    text: 'Checkout Settings',
                },
                {
                    key: 'shipping',
                    href: '/checkout/settings/shipping',
                    text: 'Shipping',
                },
            ])
        }, [])

    return (
        <span className='flex flex-col gap-4 bg-neutrals-15 p-2 rounded-md max-h-full h-full'>
            {shippingOptionList?.length === 0 && !isShipping ? (
                <Row justify={'center'}>
                    <Col span={24}>
                        <NoShippingAvailableMesage shipping={setShipping} />
                    </Col>
                </Row>
            ) : (
                <ShippingService />
            )}
        </span>
    )
}

export default Shipping
