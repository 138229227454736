

import { Checkbox, PictureOutlined } from 'gokwik-ui-kit'
type props = {
    list: any
    checkedList: any
    onCollectionCheckChange: any
}

const CollectionsList = (props: props) => {
    const { list, checkedList, onCollectionCheckChange } = props
    return (
        <>
            {list?.length === 0 && <div className='flex justify-center items-center p-5'>No Data</div>}
            
            <ul className='pl-0 list-none scroll-auto max-h-[calc(100vh-500px)] overflow-y-auto'>
                {list?.map((item, index) => (
                    <div key={item?.id}>
                        <li className='p-2'>
                            <Checkbox
                                checked={
                                    !!checkedList?.some((i) => {
                                        return i?.collection_id === item?.id
                                    })
                                }
                                onChange={(e) => onCollectionCheckChange(e, item)}
                            >
                                <div className='flex items-center gap-2'>
                                    <div className=' ml-2'>
                                        <>
                                            <div className=' rounded-md border border-solid border-[#E6E6E6]'>
                                                <img
                                                    src={item?.image?.url}
                                                    alt='thumbnail'
                                                    width={60}
                                                    height={70}
                                                />
                                            </div>

                                        </>

                                    </div>
                                    <span className='text-[#000000E0] text-sm font-normal'>{item?.title}</span>
                                </div>
                            </Checkbox>
                        </li>
                    </div>
                ))}
            </ul>
        </>
    )
}

export { CollectionsList }