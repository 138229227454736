import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
    Button,
    Col,
    ColumnProps,
    ColumnsType,
    DeleteOutlined,
    EditOutlined,
    Form,
    Input,
    InputNumber,
    PlusOutlined,
    Popover,
    Row,
    Select,
    Switch,
    Table,
    message,
    CheckOutlined,
    CloseOutlined,
} from 'gokwik-ui-kit'
import { MerchantConfig, SelectedProduct, TieredDiscount } from '@library/utilities/interface'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { validateTieredDiscount } from '@library/utilities/helpers/validations'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import SearchProducts from '@library/components/searchProducts'
import { Freebies } from '@library/utilities/constants/constants'

const TieredDiscounts: React.FC = () => {
    const [discountConfig, setDiscountConfig] = useState<Partial<MerchantConfig>>({})
    const [updatedDiscountConfig, setUpdatedDiscountConfig] = useState<Partial<MerchantConfig>>({})
    const [currentPrepaidDiscount, setCurrentPrepaidDiscount] = useState<Partial<TieredDiscount>>({
        discount_type: 'Non Freebie',
        discount_code: '',
        lower_limit: null,
        upper_limit: null,
        capping_limit: null,
        value: null,
        method_type: null,
        is_driven_by_rto_actions: null,
        discount_rule: {
            product: [],
        },
    })
    const [editData, setEditData] = useState<Partial<TieredDiscount>>()
    const [editingKey, setEditingKey] = useState<string | null>(null)
    const isEditing = (record: any) => record.id === editingKey
    const [originalData, setOriginalData] = useState<Partial<TieredDiscount>>(null)
    const productsLength = currentPrepaidDiscount?.discount_rule?.product?.length
    const [showModal, setShowModal] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState<SelectedProduct[]>([])
    const [isUpdated, setIsUpdated] = useState(false)
    const dcTieredDiscounts = useRef<{
        [key: string]: {
            [key: string]: TieredDiscount
        }
    }>({})

    const { config, loading, refetch, saveConfig } = useMerchantConfigApi({
        useCache: true,
        configSaveUrl: APIEndPoints.updateDiscountConfig,
    })
    const [prepaidDiscountList, setPrepaidDiscountList] = useState<TieredDiscount[]>([])
    const paymentMethods = useMemo(
        () => [
            ...new Set(
                config?.payment_methods
                    ?.reduce(
                        (result, curr) => [
                            ...result,
                            curr.includes('cc')
                                ? `${curr}/${curr.replace('cc', 'dc')}`
                                : curr.includes('dc')
                                ? `${curr.replace('dc', 'cc')}/${curr}`
                                : curr,
                        ],
                        [],
                        // Exclude 'cod' and 'ppcod-upi' methods from prepaid discounts
                        // because prepaid discounts should not be applied to cash-on-delivery options.
                    )
                    ?.filter((method: string) => method.indexOf('cod') === -1 && method.indexOf('ppcod-upi') === -1),
            ),
        ],
        [config?.payment_methods],
    )
    useEffect(() => {
        if (config) {
            setDiscountConfig((prev) => ({
                ...prev,
                enable_tiered_discounts: config.enable_tiered_discounts,
                tiered_discount_config: [],
            }))
            if (!config.tiered_discount_config) return
            let dcDiscounts = {}

            const temp = config.tiered_discount_config?.reduce((result, discount) => {
                const methodType = discount.method_type
                if (methodType.includes('dc')) {
                    dcDiscounts[methodType] = {
                        ...(dcDiscounts[methodType] || {}),
                        [discount.discount_code]: { ...discount },
                    }
                    return result
                }
                discount.method_type = methodType.includes('cc')
                    ? `${methodType}/${methodType.replace('cc', 'dc')}`
                    : methodType
                if (discount.upper_limit === 'MAX') discount.upper_limit = null
                if (discount.capping_limit === 'MAX') discount.upper_limit = null
                return [...result, discount]
            }, [])
            setPrepaidDiscountList([...temp])
            dcTieredDiscounts.current = { ...dcDiscounts }
        }
    }, [config])

    const tieredDiscountAddition = (discountData: TieredDiscount, discountList?: TieredDiscount[], index?: number) => {
        let submit = validateTieredDiscount(discountData, discountList)
        if (submit.status) {
            let temp = JSON.parse(JSON.stringify(discountData))
            temp.discount_code = temp.discount_code.trim()
            if (temp.discount_type === 'Freebie') {
                delete temp.value
                temp.capping_limit = null
            } else {
                temp.discount_rule = null
            }
            if (typeof index === 'number') {
                let newList = [...discountList]
                newList[index] = { ...temp }
                setPrepaidDiscountList(newList)
            } else {
                setPrepaidDiscountList((prev) => [...prev, { ...temp }])
            }

            const methodTypes = temp.method_type.includes('cc') ? temp.method_type.split('/') : [temp.method_type]
            setUpdatedDiscountConfig((prev) => ({
                ...prev,
                tiered_discount_config: [
                    ...(prev?.tiered_discount_config || []),
                    ...methodTypes.map((method_type) => ({
                        ...temp,
                        method_type,
                        upper_limit: temp.upper_limit ?? 'MAX',
                        capping_limit: temp.capping_limit ?? 'MAX',
                    })),
                ],
            }))
            setCurrentPrepaidDiscount({
                discount_type: 'Non Freebie',
                discount_code: '',
                lower_limit: null,
                upper_limit: null,
                capping_limit: null,
                value: null,
                method_type: null,
                is_driven_by_rto_actions: false,
                discount_rule: {
                    product: [],
                },
            })
            setIsUpdated(true)
            setSelectedProduct([])
        } else {
            let err = submit.msg
            message.error(err)
        }
    }
    const tieredDiscountDeletion = (deletedDiscount: Partial<TieredDiscount>, index: number) => {
        const isDeletedDiscountExisting = config.tiered_discount_config?.find(
            (discount) => discount?.id === deletedDiscount?.id,
        )
        const methodTypes = deletedDiscount.method_type.includes('cc')
            ? deletedDiscount.method_type.split('/')
            : [deletedDiscount.method_type]

        setPrepaidDiscountList((prev) =>
            prev.filter((discount, idx) =>
                isDeletedDiscountExisting ? discount.id !== deletedDiscount.id : index !== idx,
            ),
        )
        setUpdatedDiscountConfig((prev) => {
            let temp = {
                ...prev,
                tiered_discount_config: isDeletedDiscountExisting
                    ? [
                          ...(prev.tiered_discount_config || []),
                          ...methodTypes.map((method_type) => ({
                              id:
                                  dcTieredDiscounts.current?.[method_type]?.[deletedDiscount.discount_code]?.id ||
                                  deletedDiscount.id,
                              is_deleted: true,
                              discount_code: deletedDiscount.discount_code,
                              discount_type: 'Non Freebie',
                              method_type,
                          })),
                      ]
                    : prev.tiered_discount_config.filter(
                          (discount) => discount.discount_code !== deletedDiscount.discount_code,
                      ),
            }

            if (temp.tiered_discount_config.length === 0) {
                delete temp.tiered_discount_config
                return temp
            } else return temp
        })
        setIsUpdated(true)
    }

    const saveDiscountCodes = () => {
        const anyCappingAmount = prepaidDiscountList.some((discount) => !!discount.capping_limit)
        saveConfig(
            {
                ...updatedDiscountConfig,
                ...(anyCappingAmount !== config.prepaid_discount_capping && {
                    prepaid_discount_capping: anyCappingAmount,
                }),
            },
            () => {
                setUpdatedDiscountConfig({})
                setIsUpdated(false)
            },
        )
    }
    const saveEditDiscounts = (data, previousDiscount: Partial<TieredDiscount>, index: number) => {
        const filteredList = prepaidDiscountList.filter((itm, i) => i !== index)
        let submit = validateTieredDiscount(data, filteredList)

        if (submit.status) {
            const tempData = { ...data }
            tempData.discount_type === 'Freebie' ? delete tempData.value : delete tempData.discount_rule
            delete tempData.id
            delete tempData.merchant_id
            delete tempData.updated_at
            delete tempData.is_deleted
            delete tempData.type
            tieredDiscountDeletion(previousDiscount, index)
            tieredDiscountAddition(tempData, filteredList, index)
            setEditingKey(null)
        } else {
            message.error(submit.msg)
        }
    }
    const cancelEditDiscounts = () => {
        setEditingKey(null)
        setEditData(originalData)
        setOriginalData(null)
    }

    const editTieredDiscount = (record: any) => {
        setEditingKey(record.id)
        setOriginalData({ ...record })
        setEditData({ ...record })
    }

    const columns: ColumnsType<any> = [
        {
            title: 'Payment Method',
            dataIndex: 'method_type',
        },
        {
            title: 'Discount Code',
            dataIndex: 'discount_code',
        },
        {
            title: 'Discount Type',
            dataIndex: 'discount_type',
        },
        {
            title: 'Lower Order Limit',
            dataIndex: 'lower_limit',
            align: 'right',
            render: (_, record) => {
                return isEditing(record) ? (
                    <InputNumber
                        value={editData.lower_limit}
                        onChange={(value) => setEditData({ ...editData, lower_limit: value })}
                    />
                ) : (
                    record.lower_limit
                )
            },
        },
        {
            title: 'Upper Order Limit',
            dataIndex: 'upper_limit',
            align: 'right',
            render: (_, record) => {
                return isEditing(record) ? (
                    <InputNumber
                        value={editData.upper_limit}
                        onChange={(value: any) => setEditData({ ...editData, upper_limit: value })}
                    />
                ) : (
                    record.upper_limit
                )
            },
        },
        {
            title: 'Discount Value(in %)',
            dataIndex: 'value',
            align: 'right',
            render: (_, record) => {
                const displayValue = record.discount_type === Freebies && record.value === '0' ? '-' : record.value
                return isEditing(record) ? (
                    <InputNumber
                        value={editData.value}
                        onChange={(value: number) => setEditData({ ...editData, value: value })}
                    />
                ) : (
                    displayValue
                )
            },
        },
        {
            title: 'Freebies',
            dataIndex: 'discount_rule',
            render: (discountRule: TieredDiscount['discount_rule']) => {
                const productsLength = discountRule?.product?.length
                return productsLength ? (
                    <Popover
                        content={
                            <div className='flex flex-col gap-y-4'>
                                {discountRule.product.map((itm) => (
                                    <div key={itm.product_name} className='flex items-center gap-x-4'>
                                        <img
                                            src={itm.product_thumbnail_url}
                                            alt={itm.product_name}
                                            className='h-20 w-auto'
                                        />
                                        <div className='flex flex-col gap-y-2'>
                                            <p>{itm.product_name}</p>
                                            <div className='flex gap-x-4 w-full '>
                                                <span>
                                                    <strong className='font-semibold'>₹</strong>
                                                    {itm.price}
                                                </span>
                                                <span>
                                                    <strong className='font-semibold'>Qty</strong> {itm.quantity}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    >
                        <span className='underline'>{`${productsLength} Product${productsLength > 1 ? 's' : ''}`}</span>
                    </Popover>
                ) : (
                    'NA'
                )
            },
        },
        {
            title: 'Is Driven By RTO Actions',
            dataIndex: 'is_driven_by_rto_actions',
            align: 'right',
            render: (_, record) => {
                return record.discount_type === 'Freebie' && isEditing(record) ? (
                    <Select
                        value={editData.is_driven_by_rto_actions ? true : false}
                        onChange={(e) => setEditData({ ...editData, is_driven_by_rto_actions: e === true })}
                    >
                        <Select.Option value={true}>True</Select.Option>
                        <Select.Option value={false}>False</Select.Option>
                    </Select>
                ) : (
                    String(record.is_driven_by_rto_actions)
                )
            },
        },
        {
            title: 'Capping Amount',
            dataIndex: 'capping_limit',
            align: 'right',
            render: (_, record) => {
                return isEditing(record) ? (
                    <InputNumber
                        value={editData.capping_limit}
                        onChange={(value) => setEditData({ ...editData, capping_limit: value })}
                    />
                ) : (
                    record.capping_limit
                )
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record: Partial<TieredDiscount>, index: number) => {
                const editable = isEditing(record)
                return editable ? (
                    <>
                        <CheckOutlined
                            className='mr-2 ml-2'
                            onClick={() => saveEditDiscounts(editData, originalData, index)}
                        />
                        <CloseOutlined onClick={cancelEditDiscounts} />
                    </>
                ) : (
                    <>
                        <EditOutlined className='mr-2 ml-2' onClick={() => editTieredDiscount(record)} />
                        <DeleteOutlined
                            className='text-error-500'
                            onClick={() => tieredDiscountDeletion(record, index)}
                        />
                    </>
                )
            },
        },
    ]

    return (
        <Col className='bg-white' span={24}>
            <Row gutter={[16, 24]}>
                <Col>
                    <span className='mr-1'>Tiered Prepaid Discounts</span>
                    <Switch
                        checked={discountConfig.enable_tiered_discounts}
                        onChange={() => {
                            setIsUpdated(true)
                            setDiscountConfig((prev) => ({
                                ...prev,
                                enable_tiered_discounts: !prev.enable_tiered_discounts,
                            }))
                            setUpdatedDiscountConfig((prev) => ({
                                ...prev,
                                enable_tiered_discounts: !discountConfig.enable_tiered_discounts,
                            }))
                        }}
                    />
                </Col>
                <Col span={24}>
                    <Row gutter={16} align={'bottom'}>
                        <Col span={6}>
                            <Select
                                placeholder='Select discount type'
                                className='w-full'
                                label='Discount Type*'
                                value={currentPrepaidDiscount.discount_type}
                                onChange={(val) =>
                                    setCurrentPrepaidDiscount((prev) => ({ ...prev, discount_type: val }))
                                }
                                disabled={!discountConfig.enable_tiered_discounts}
                                options={[
                                    { label: 'Non Freebie', value: 'Non Freebie' },
                                    { label: 'Freebie', value: 'Freebie' },
                                ]}
                            />
                        </Col>
                        <Col span={6}>
                            <Select
                                placeholder='Select payment method'
                                label='Payment Method*'
                                className='w-full'
                                value={currentPrepaidDiscount.method_type}
                                onChange={(val) => setCurrentPrepaidDiscount((prev) => ({ ...prev, method_type: val }))}
                                disabled={!discountConfig.enable_tiered_discounts}
                                options={paymentMethods.map((method) => ({
                                    label: method,
                                    value: method,
                                }))}
                            />
                        </Col>
                        <Col span={6}>
                            <Input
                                placeholder='Enter any random code'
                                label='Discount Code*'
                                value={currentPrepaidDiscount.discount_code}
                                onChange={(e) =>
                                    setCurrentPrepaidDiscount((prev) => ({ ...prev, discount_code: e.target.value }))
                                }
                                disabled={!discountConfig.enable_tiered_discounts}
                            />
                        </Col>
                        <Col span={6}>
                            <InputNumber
                                placeholder='Enter number'
                                label='Lower Order Limit*'
                                labelTooltip='Enter the lower value limit for which the discount is applicable.'
                                value={currentPrepaidDiscount.lower_limit}
                                className='w-full'
                                min={0.01}
                                max={Number.MAX_SAFE_INTEGER}
                                onChange={(val) =>
                                    setCurrentPrepaidDiscount((prev) => ({ ...prev, lower_limit: +val }))
                                }
                                disabled={!discountConfig.enable_tiered_discounts}
                            />
                        </Col>
                        <Col span={6}>
                            <InputNumber
                                placeholder='Enter number'
                                label='Upper Order Limit'
                                labelTooltip='Enter the greater value limit for which the discount is applicable.'
                                value={currentPrepaidDiscount.upper_limit}
                                className='w-full'
                                min={1}
                                max={Number.MAX_SAFE_INTEGER}
                                onChange={(val) =>
                                    setCurrentPrepaidDiscount((prev) => ({ ...prev, upper_limit: +val }))
                                }
                                disabled={!discountConfig.enable_tiered_discounts}
                            />
                        </Col>
                        {currentPrepaidDiscount.discount_type === 'Non Freebie' ? (
                            <>
                                <Col span={6}>
                                    <InputNumber
                                        className='w-full'
                                        placeholder='Enter integer'
                                        label='Discount Value% *'
                                        labelTooltip='Enter the percentage discount needed for this tier'
                                        value={currentPrepaidDiscount.value}
                                        min={0}
                                        max={100}
                                        onChange={(val) =>
                                            setCurrentPrepaidDiscount((prev) => ({
                                                ...prev,
                                                value: +val > 100 ? null : +val,
                                            }))
                                        }
                                        disabled={!discountConfig.enable_tiered_discounts}
                                    />
                                </Col>
                                <Col span={6}>
                                    <InputNumber
                                        placeholder='Enter number'
                                        className='w-full'
                                        label='Capping Amount'
                                        labelTooltip='Enter the minimum capping value.'
                                        min={0}
                                        max={Number.MAX_SAFE_INTEGER}
                                        value={currentPrepaidDiscount.capping_limit}
                                        onChange={(val) =>
                                            setCurrentPrepaidDiscount((prev) => ({ ...prev, capping_limit: +val }))
                                        }
                                        disabled={!discountConfig.enable_tiered_discounts}
                                    />
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col span={6}>
                                    <Input
                                        label='Free Gift*'
                                        labelTooltip='Maximum 1 Free Gift is allowed.'
                                        placeholder=''
                                        value={
                                            productsLength >= 1
                                                ? `${productsLength} Item${productsLength > 1 ? 's' : ''} Selected`
                                                : 'Select free gift'
                                        }
                                        onClick={() => setShowModal(true)}
                                        disabled={!discountConfig.enable_tiered_discounts}
                                    />
                                </Col>

                                <Col span={6}>
                                    Is driven by RTO actions
                                    <Select
                                        value={currentPrepaidDiscount.is_driven_by_rto_actions ? true : false}
                                        onChange={(e) =>
                                            setCurrentPrepaidDiscount((prev) => ({
                                                ...prev,
                                                is_driven_by_rto_actions: e === true,
                                            }))
                                        }
                                        disabled={!discountConfig.enable_tiered_discounts}
                                        style={{ width: '100%' }}
                                    >
                                        <Select.Option value={true}>True</Select.Option>
                                        <Select.Option value={false}>False</Select.Option>
                                    </Select>
                                </Col>
                            </>
                        )}

                        <Col span={6}>
                            <Button
                                onClick={() => tieredDiscountAddition(currentPrepaidDiscount, prepaidDiscountList)}
                                disabled={!discountConfig.enable_tiered_discounts}
                            >
                                <PlusOutlined /> Add New Discount
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Table
                        dataSource={prepaidDiscountList}
                        columns={columns}
                        pagination={false}
                        rowKey={(record) => record.id}
                    />
                </Col>
                <Col>
                    <Button
                        disabled={!isUpdated}
                        variant='primary'
                        onClick={() => {
                            saveDiscountCodes()
                        }}
                    >
                        Save
                    </Button>
                </Col>
            </Row>

            <SearchProducts
                onClose={() => setShowModal(false)}
                onConfirm={(selectedProduct) => {
                    if (selectedProduct.length > 1) {
                        message.error("You can't select more than one freebie")
                        return
                    }
                    const temp = selectedProduct.map((item) => ({
                        product_id: item?.product_id,
                        variant_id: item?.variant_id,
                        quantity: item?.quantity,
                        product_name: item?.product_name,
                        product_thumbnail_url: item?.product_thumbnail_url,
                        price: item?.price,
                    }))
                    setSelectedProduct(selectedProduct)
                    setCurrentPrepaidDiscount((prev) => ({
                        ...prev,
                        discount_rule: {
                            product: temp,
                        },
                        is_active: false,
                    }))
                    setShowModal(false)
                }}
                showModal={showModal}
                alreadySelected={selectedProduct}
            />
        </Col>
    )
}

export default TieredDiscounts
