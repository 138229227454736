import React, { FC, useEffect, useState } from 'react'
import { Row, Col, Radio, InputNumber, Form, Space } from 'gokwik-ui-kit';
import { CardLayout } from './cardLayout';
import { DiscountFormState } from '../helpers/useCreateDiscount';
interface MinPurchaseRequirementCardProps {
    formState?: DiscountFormState,
    setFormState?: any
}
    
const Card: FC<MinPurchaseRequirementCardProps> = ({ formState, setFormState }) => {
  const [selectedOption, setSelectedOption] = useState<string>("no_minimum_requirement");

  useEffect(() => {
    const requirement = formState?.rules?.[0]?.conditions?.[0]?.minimum_purchase_requirement;
    if (requirement === "no_minimum_requirement" || requirement?.no_minimum_requirement) {
      setSelectedOption('no_minimum_requirement');
    } else if (requirement === "minimum_purchase_amount" || requirement?.minimum_purchase_amount > 0) {
      setSelectedOption('minimum_purchase_amount');
    } else if (requirement === "minimum_quantity"  || requirement?.minimum_quantity > 0) {
      setSelectedOption('minimum_quantity');
    }
  }, [formState]);

  const handleRadioChange = (e: any) => {
    let selectedValue = e.target.value
    setSelectedOption(selectedValue);
    if (selectedValue === 'no_minimum_requirement') {
        setFormState((prev: any) => {
            const temp = JSON.parse(JSON.stringify(prev));
            temp.rules[0].conditions[0].minimum_purchase_requirement.no_minimum_requirement = true;
            temp.rules[0].conditions[0].minimum_purchase_requirement.minimum_purchase_amount = null;
            temp.rules[0].conditions[0].minimum_purchase_requirement.minimum_quantity = null;
            return temp;
        })
    }
  }

  return formState?.method !== 'automatic' && (
    <CardLayout
        title="Minimum Purchase Requirements"
        subtitle="Minimum purchase requirements are a good practice to ensure that users don’t abuse the discounting system"
        baselineExplainer=""
    >
      <Form.List name={['rules']} initialValue={formState?.rules}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row className={`mt-5`} gutter={12} key={key}>
                <Col span={24}>
                  <Radio.Group onChange={handleRadioChange} value={selectedOption}>
                    <Space direction="vertical" size="small">
                      <Radio value="no_minimum_requirement">No minimum requirements</Radio>
                      <Radio value="minimum_purchase_amount">Minimum purchase amount (₹)</Radio>
                      <Radio value="minimum_quantity">Minimum quantity of items</Radio>
                    </Space>
                  </Radio.Group>
                </Col>

                {selectedOption === 'minimum_purchase_amount' && (
                  <Col className={`mt-6 pt-1`} >
                    <Form.Item
                      {...restField}
                      name={[0, 'conditions', 0, 'minimum_purchase_requirement', "minimum_purchase_amount"]}
                      rules={[
                        {
                          required: true,
                          message: 'Minimum purchase can’t be blank',
                        },
                        {
                          validator: async (_, value) => {
                            if (value && (isNaN(value) || value < 1)) {
                              throw new Error('Please enter a value greater than 0.');
                            }
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Minimum Amount"
                        addonBefore="₹"
                        className='w-full'
                        min={1}
                        type="number"
                      />
                    </Form.Item>
                  </Col>
                )}
                
                {selectedOption === 'minimum_quantity' && (
                  <Col className={`mt-6 pt-1`} >
                    <Form.Item
                      {...restField}
                      name={[0, 'conditions', 0, 'minimum_purchase_requirement', "minimum_quantity"]}
                      rules={[
                        {
                          required: true,
                          message: 'Minimum quantity can’t be blank',
                        },
                        {
                          validator: async (_, value) => {
                            if (value && (isNaN(value) || value < 1)) {
                              throw new Error('Please enter a value greater than 0.');
                            }
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Minimum Quantity"
                        min={1}
                        className='w-full'
                        type="number"
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            ))}
          </>
        )}
      </Form.List>
    </CardLayout>
  );
};

export const MinPurchaseRequirementCard = React.memo(Card)