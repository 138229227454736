import dayjs, { Dayjs } from 'dayjs'
import { SearchFilters, FilterCreatorModel } from '../interface'
import { IFeaturesEvents } from './eventsHelper'

const getDynamicSearchFilters = (searchFilters: SearchFilters[]): FilterCreatorModel => {
    const filterCreatorState: FilterCreatorModel = {
        title: 'Add More Filters +',
        options: searchFilters
            ?.filter((item) => item?.is_dynamic)
            ?.map((filter) => {
                return {
                    id: filter?.id,
                    text: filter?.label,
                }
            }),
    }
    return filterCreatorState
}

const formatDateString = (rawDate) => {
    if (!rawDate) {
        return ''
    }

    const date = new Date(rawDate)

    const formattedDate = date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    })

    return formattedDate
}

const formatAmount = (
    val: number,
    minFractionDigits: number = 0,
    maxFractionDigits: number = 2,
    notation: 'standard' | 'scientific' | 'engineering' | 'compact' = 'standard',
) =>
    val === undefined || typeof val === 'string' || val === null
        ? ''
        : Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'INR',
              notation,
              minimumFractionDigits: minFractionDigits,
              maximumFractionDigits: maxFractionDigits,
          })
              .format(val)
              .replace('T', 'K')

const shopifyAppCheckoutEnabledStatus = (lastLoginDate: Date): boolean => {
    const parsedLastLoginDate = new Date(lastLoginDate)
    if (isNaN(parsedLastLoginDate.getTime())) {
        return false
    }

    const twoDaysInMs = 1000 * 60 * 60 * 24 * 2
    return Date.now() - parsedLastLoginDate.getTime() <= twoDaysInMs
}

const debounce = (func: Function, wait: number, immediate?: boolean) => {
    let timeout: any
    return function (this: any) {
        const context = this
        const args = arguments
        const later = function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }
        const callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}

const disabledBeforeDate = (current: Dayjs) => {
    return current && current < dayjs().startOf('day')
}

const disabledBeforeTime = (disabledBefore?: Dayjs) => {
    const now = disabledBefore || dayjs()
    const currentHour = now.hour()
    const currentMinute = now.minute()
    const currentSecond = now.second()

    return {
        disabledHours: () => Array.from({ length: 24 }, (_, i) => i).slice(0, currentHour),
        disabledMinutes: (selectedHour) => {
            if (selectedHour === currentHour) {
                return Array.from({ length: 60 }, (_, i) => i).slice(0, currentMinute)
            }
            return []
        },
        disabledSeconds: (selectedHour, selectedMinute) => {
            if (selectedHour === currentHour && selectedMinute === currentMinute) {
                return Array.from({ length: 60 }, (_, i) => i).slice(0, currentSecond)
            }
            return []
        },
    }
}

const capitalizeFirstLetter = (string: string, forAllWords?: boolean) => {
    if (forAllWords) {
        return string.replace(/\b\w/g, (char) => char.toUpperCase())
    }
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export {
    getDynamicSearchFilters,
    formatAmount,
    shopifyAppCheckoutEnabledStatus,
    debounce,
    disabledBeforeDate,
    disabledBeforeTime,
    formatDateString,
    capitalizeFirstLetter,
}
export * from './fetchUtmList'
