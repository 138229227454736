import { NoDataIcon } from '@library/images/noDataIcon'
import { Alert, Button, Card, Col, InfoCircleOutlined, List, Pagination, Row } from 'gokwik-ui-kit'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react';

const NoShippingAvailableMesage = ({shipping}) => {
    const navigate = useNavigate()
    return (
        <Card className='w-full'>
            <Row justify={'center'} className='flex flex-col gap-y-2 w-full' align={'middle'}>
                <Col>
                    <NoDataIcon />
                </Col>
                <Col>
                    <span className='text-lg font-semibold'>No Shipping Rules Are Set Yet</span>
                </Col>
                <Col>
                    <Button variant='default' onClick={() => {navigate('/checkout/settings/shipping'); shipping(true)}} children={'Add New Shipping'} />
                </Col>
            </Row>
        </Card>
    )
}


const findMissingRanges = (config, type) => {
    const sortedRanges = config
        .filter(item => item[type[0]] !== undefined && item[type[1]] !== undefined)
        .sort((a, b) => a[type[0]] - b[type[0]]);

    const missingRanges = [];

    for (let i = 0; i < sortedRanges.length - 1; i++) {
        const currentMax = sortedRanges[i][type[1]];
        const nextMin = sortedRanges[i + 1][type[0]];

        if (nextMin > currentMax + 0.01) {
            missingRanges.push({ min: currentMax + 0.01, max: nextMin - 0.01 });
        }
    }

    return missingRanges;
};


const ShippingRangeChecker = ({ shippingConfig, type, prefix }) => {
    const [missingRanges, setMissingRanges] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5;

    useEffect(() => {
        const ranges = findMissingRanges(shippingConfig, type);
        setMissingRanges(ranges);
    }, [shippingConfig]);

    const paginatedRanges = missingRanges.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    return (
        <Row>
            {missingRanges && missingRanges.length === 1 ? (
                <Col span={24}>
                    <Alert
                        type='warning'
                        className="border-none text-sm p-1 flex items-center"
                        message={
                            <div className="flex items-center gap-2 p-2">
                                <span className="text-yellow-500 text-[16px]"><InfoCircleOutlined /></span>
                                <p className="text-sm">Shipping not defined between <b>{prefix === '₹' && prefix}{missingRanges?.[0].min}{prefix === "KG" && " " + prefix} </b> - <b>{prefix === '₹' && prefix}{missingRanges?.[0].max}{prefix === "KG" && " " + prefix}</b>  range</p>
                            </div>
                        }
                    />
                </Col>
            ) : (
                <Col span={24}>
                    {missingRanges && missingRanges.length > 1 && (
                        <Alert
                            type="warning"
                            className="border-none text-sm p-1 flex items-center"
                            message={
                                <div className="flex items-center gap-2 mt-1 justify-between pl-1 pr-1">
                                    <div className='flex flex-row items-center gap-2'>
                                        <span className="text-yellow-500 text-[16px]"><InfoCircleOutlined /></span>
                                        <p className="text-sm">Shipping not defined for below {missingRanges?.length} ranges</p>
                                    </div>
                                    <div className="flex items-center justify-end mr-1">
                                        <span
                                            className=" border-none cursor-pointer text-sm"
                                            onClick={() => setShowAll(!showAll)}
                                        >
                                            {showAll ? 'Hide All' : 'See All'}
                                        </span>
                                    </div>
                                </div>
                            }
                            description={
                                <span className='p-0 text-sm'>
                                    {showAll && (
                                        paginatedRanges.map((ranges, index) => (
                                            <ul key={index}>
                                                <li>Between <b>{prefix === '₹' && prefix}{parseFloat(ranges?.min).toFixed(2)}{prefix === "KG" && " " + prefix}</b> - <b>{prefix === '₹' && prefix}{parseFloat(ranges?.max).toFixed(2)}{prefix === "KG" && " " + prefix}</b> range</li>
                                            </ul>
                                        ))
                                    )}
                                    {showAll && missingRanges.length > pageSize && (
                                        <Pagination
                                            current={currentPage}
                                            pageSize={pageSize}
                                            total={missingRanges.length}
                                            onChange={(page) => setCurrentPage(page)}
                                            showSizeChanger={false}
                                            className='mt-2 flex justify-end text-sm'
                                        />
                                    )}
                                </span>
                            }
                        />
                    )}
                </Col>
            )}
        </Row>
    );
};


export { NoShippingAvailableMesage, ShippingRangeChecker }
