import {
    Button,
    Card,
    Checkbox,
    Col,
    DeleteOutlined,
    Drawer,
    Form,
    InfoCircleOutlined,
    Input,
    message,
    Radio,
    Row,
    Switch,
    Tooltip,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import BookDownload from '@library/images/book-download.svg'
import Meta from '@library/images/PixelIcons/ads&Analytics/meta.svg'
import GoogleAnalytics from '@library/images/PixelIcons/ads&Analytics/googleAnalytics.svg'
import Snapchat from '@library/images/PixelIcons/ads&Analytics/snapchat.svg'
import IngestLabs from '@library/images/PixelIcons/ads&Analytics/ingestLabs.svg'
import EdgeTag from '@library/images/PixelIcons/ads&Analytics/edgetag.svg'
import CustomerLabs from '@library/images/PixelIcons/ads&Analytics/customerLabs.svg'
import RenderForm from '@library/components/integrations/formRender'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { useAppDispatch } from '@library/utilities/hooks'
import { selectMerchantCreds } from '@store/integrations/selectors'
import { useSelector } from 'react-redux'
import { saveCreds } from '@store/integrations'
import { deepEqualAndDiff } from '@gokwik/utilities'
import { ga4CustomEvents, ga4StandardEvents, snapchatEvents } from '@library/utilities/constants/constants'

const AdsAnalytics = () => {
    const [drawer, setDrawer] = useState(false)
    const [form] = Form.useForm()

    const { config, refetch, saveConfig } = useMerchantConfigApi()
    const customConfig = config?.custom_config
    const formCustomConfig = Form.useWatch('custom_config', form)
    const dispatch = useAppDispatch()
    const [enabledStates, setEnabledStates] = useState({})
    const [title, setTitle] = useState('')
    const [selectedKey, setSelectedKey] = useState('')
    const merchantCredentials = useSelector(selectMerchantCreds)

    const handleSwitchChange = (key, checked, item) => {
        if (!checked) {
            let pixels = customConfig.pixels
            if (['fb_pixels', 'Snapchat'].includes(key)) {
                const startsWith = key === 'fb_pixels' ? 'fb' : 'snapchat'
                pixels = pixels.filter((pixel) => (typeof pixel === 'string' ? !pixel.startsWith(startsWith) : true))
            } else if (key === 'ga4') {
                pixels = pixels.filter((pixel) => !(typeof pixel === 'object' && pixel.ga4))
            } else {
                pixels = pixels.filter((pixel) => pixel !== key)
            }
            saveConfig({
                custom_config: { ...config.custom_config, pixels, ...(key === 'ga4' && { is_ga4S2s_enabled: false }) },
            })
        }
        if (checked && item.customize) {
            handleCustomizeClick(key, item.title)
        } else if (checked) {
            saveConfig({ custom_config: { ...config.custom_config, pixels: [...customConfig.pixels, key] } })
        }
    }

    const handleCustomizeClick = (key, title) => {
        if (key === 'ga4' && !enabledStates[key]) {
            form.setFieldsValue({
                ga4_pixels: {
                    ga4: ga4StandardEvents,
                    ga4_custom: ga4CustomEvents,
                },
            })
        } else if ((key === 'fb_pixels' || key === 'Snapchat') && !enabledStates[key]) {
            form.setFieldsValue({
                custom_config: {
                    ...config.custom_config,
                    pixels:
                        key === 'fb_pixels'
                            ? ['fb_initiate_checkout', 'fb_add_payment_info', 'fb_purchase']
                            : Object.values(snapchatEvents),
                },
            })
        }
        setSelectedKey(key)
        setTitle(title)
        setDrawer(true)
    }

    const data = [
        {
            key: 'fb_pixels',
            title: 'Meta Ads',
            icon: Meta,
            customize: true,
        },
        {
            key: 'ga4',
            title: 'Google Analytics (GA4)',
            icon: GoogleAnalytics,
            customize: true,
        },
        {
            key: 'Snapchat',
            title: 'Snapchat',
            icon: Snapchat,
            customize: true,
            tooltip:
                'Please add the following key in gokwik.liquid file on your theme under window.merchantInfo : "snapchatPixels: {YOUR PIXEL ID}" where {YOUR PIXEL ID} is the actual pixel ID for snapchat.',
        },
        {
            key: 'edgeTag',
            title: 'Edge Tag (Blotout)',
            icon: EdgeTag,
            customize: true,
        },
        {
            key: 'magic_pixel',
            title: 'Ingest Labs',
            icon: IngestLabs,
            customize: true,
        },
        {
            key: 'customerLabs',
            title: 'Customer Labs',
            icon: CustomerLabs,
            customize: false,
        },
    ]
    const handleSave = async () => {
        try {
            await form.validateFields()
            const { custom_config = {}, ga4_pixels, ...rest } = form.getFieldsValue()
            if (selectedKey === 'ga4') {
                custom_config.ga4Id = `G-${custom_config.ga4Id}`
                if (ga4_pixels.ga4_custom) {
                    const couponEvents = ga4_pixels.ga4_custom.filter((pixel: string) =>
                        pixel.includes('coupon_applied'),
                    )
                    if (couponEvents.length > 0) {
                        ga4_pixels.ga4 = [...new Set([...(ga4_pixels.ga4 || []), ...couponEvents])]
                    }
                }
                custom_config.pixels = [
                    ...(config.custom_config.pixels || []).filter((pixel) => typeof pixel === 'string'),
                    { ga4: ga4_pixels.ga4, ga4_custom: ga4_pixels.ga4_custom },
                ]
            }
            if (selectedKey === 'fb_pixels' || selectedKey === 'Snapchat') {
                const startsWith = selectedKey === 'fb_pixels' ? 'fb' : 'snapchat'
                custom_config.pixels = [
                    ...(config.custom_config.pixels || []).filter((pixel) =>
                        typeof pixel === 'string' ? !pixel.startsWith(startsWith) : true,
                    ),
                    ...custom_config.pixels.filter((pixel) =>
                        typeof pixel === 'string' ? pixel.startsWith(startsWith) : false,
                    ),
                ]
            }
            if (selectedKey === 'edgeTag') {
                custom_config.analytics_pixel_data = {
                    ...customConfig.analytics_pixel_data,
                    edgeTag: {
                        ...custom_config.analytics_pixel_data.edgeTag,
                    },
                }
                custom_config.analytics_pixel = {
                    ...customConfig.analytics_pixel,
                    edgeTag: custom_config.analytics_pixel_data.edgeTag.value,
                }
            }
            if (!['fb_pixels', 'ga4', 'Snapchat'].includes(selectedKey) && !customConfig.pixels.includes(selectedKey)) {
                custom_config.pixels = [...(customConfig.pixels || []), selectedKey]
            }

            let payload = rest[selectedKey.toLocaleLowerCase()]
            let credsResponse
            if (payload) {
                if (selectedKey === 'fb_pixels' || selectedKey === 'Snapchat') {
                    payload = (
                        payload as {
                            pixelId: string
                            access_token: string
                        }[]
                    ).reduce(
                        (
                            acc,
                            item: {
                                pixelId: string
                                access_token: string
                            },
                            index,
                        ) => {
                            if (index === 0) {
                                acc.pixelId = item.pixelId
                                acc.access_token = item.access_token
                            } else {
                                acc.pixelId += `,${item.pixelId}`
                                acc.access_token += `,${item.access_token}`
                            }
                            return acc
                        },
                        {
                            pixelId: '',
                            access_token: '',
                        },
                    )
                }
                credsResponse = await dispatch(saveCreds({ [selectedKey.toLowerCase()]: payload }))
            }
            if (credsResponse || !payload) {
                const configPayload = deepEqualAndDiff(config, {
                    custom_config: {
                        ...customConfig,
                        ...custom_config,
                    },
                })
                if (configPayload.isEqual) {
                    setDrawer(false)
                    return
                } else {
                    saveConfig(configPayload.diff, () => {
                        setDrawer(false)
                    })
                }
            } else {
                message.error('Failed to save credentials')
            }
        } catch (error) {
            console.log('error', error)

            return
        }
    }

    const populateFormConfig = () => {
        if (config?.custom_config) {
            const ga4_pixels = config.custom_config.pixels?.find((pixel) => typeof pixel === 'object' && pixel.ga4)

            form.setFieldsValue({
                custom_config: {
                    ...config.custom_config,
                    ga4Id: config.custom_config?.ga4Id?.replace('G-', ''),
                },
                ga4_pixels,
            })
            setEnabledStates(
                data
                    .filter((item) => !['ga4', 'fb_pixels', 'Snapchat'].includes(item.key))
                    .reduce((acc, item) => ({ ...acc, [item.key]: config.custom_config.pixels?.includes(item.key) }), {
                        fb_pixels: config.custom_config.pixels?.some(
                            (pixel) => typeof pixel === 'string' && pixel.startsWith('fb'),
                        ),
                        ga4: config.custom_config.pixels?.find(
                            (pixel) => typeof pixel === 'object' && pixel.ga4.length > 0,
                        ),
                        Snapchat: config.custom_config.pixels?.some(
                            (pixel) => typeof pixel === 'string' && pixel.startsWith('snapchat'),
                        ),
                    }),
            )
        }
    }

    const populateFormCreds = () => {
        const separateCreds = (creds: { pixelId: string; access_token: string }) =>
            Object.entries(creds).reduce((acc: any[], [key, value]: [string, string]) => {
                const splitValue = value.split(',')
                for (let i = 0; i < splitValue.length; i++) {
                    if (acc[i]) {
                        acc[i][key] = splitValue[i]
                        continue
                    } else
                        acc.push({
                            [key]: splitValue[i],
                        })
                }

                return acc
            }, [])
        if (merchantCredentials) {
            let fbPixels = []
            let snapchatPixels = []
            if (merchantCredentials.fb_pixels) {
                fbPixels = separateCreds(merchantCredentials.fb_pixels)
            }
            if (merchantCredentials.snapchat) {
                snapchatPixels = separateCreds(merchantCredentials.snapchat)
            }

            form.setFieldsValue(
                data.reduce((acc, item) => {
                    if (item.key === 'fb_pixels') {
                        return {
                            ...acc,
                            fb_pixels: fbPixels,
                        }
                    } else if (item.key === 'Snapchat') {
                        return {
                            ...acc,
                            snapchat: snapchatPixels,
                        }
                    } else {
                        acc[item.key.toLowerCase()] = merchantCredentials[item.key]
                    }
                    return acc
                }, {}),
            )
        }
    }
    useEffect(() => {
        populateFormConfig()
    }, [config])
    useEffect(() => {
        populateFormCreds()
    }, [merchantCredentials])

    return (
        <>
            <Row gutter={[16, 16]} className='mb-20'>
                <Col span={18}>
                    <div className='flex items-center text-lg leading-7'>
                        <p className='text-lg font-semibold'>Ads and Analytics</p>
                    </div>
                </Col>
                {data.map((item) => (
                    <Col span={24} key={item.key}>
                        <Card className='custom-card'>
                            <Row align='middle'>
                                <Col flex='40px'>
                                    <img src={item.icon} alt={item.title} />
                                </Col>
                                <Col flex='auto'>
                                    <p className='font-semibold'>
                                        {item.title}{' '}
                                        {item.tooltip && (
                                            <Tooltip title={item.tooltip}>
                                                <InfoCircleOutlined className='text-gray-600 pl-1 cursor-pointer' />
                                            </Tooltip>
                                        )}
                                    </p>
                                </Col>
                                <Col>
                                    {item.customize === true && enabledStates[item.key] && (
                                        <Button
                                            className='mr-4 focus:outline-none'
                                            onClick={() => handleCustomizeClick(item.key, item.title)}
                                        >
                                            Customise
                                        </Button>
                                    )}
                                    <Switch
                                        className='focus:outline-none'
                                        checked={!!enabledStates[item.key]}
                                        onChange={(checked) => handleSwitchChange(item.key, checked, item)}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Drawer
                title={title}
                width={500}
                open={drawer}
                onClose={() => setDrawer(false)}
                footer={
                    <div className='flex justify-end space-x-4'>
                        <Button
                            type='default'
                            onClick={() => {
                                populateFormConfig()
                                populateFormCreds()
                                setDrawer(false)
                            }}
                        >
                            Discard
                        </Button>
                        <Button type='primary' onClick={handleSave}>
                            Save Changes
                        </Button>
                    </div>
                }
            >
                <RenderForm fieldKey={selectedKey} form={form} />
            </Drawer>
        </>
    )
}

export default AdsAnalytics
