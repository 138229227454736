import DraggableList from '@library/components/draggableList'
import { OfferAndDiscount } from '@library/utilities/interface'

interface Props {
    discounts: OfferAndDiscount[]
    setDiscountOrder: (newOrder: OfferAndDiscount[]) => void
}

const OfferSortOrder = ({ discounts, setDiscountOrder }: Props) => {
    return (
        <div className='flex items-center w-full'>
            <DraggableList
                items={discounts}
                labelKey={'discount_code'}
                setListOrder={(newOrder: OfferAndDiscount[]) => {
                    setDiscountOrder(newOrder)
                }}
            />
        </div>
    )
}

export default OfferSortOrder
