import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from './constants/apiEndpoints'
import { SearchedProduct } from './interface'

type FetchParams =
    | { searchType: 'collections'; title?: string; page?: never; pageSize?: never }
    | { searchType: 'products'; title: string; page?: never; pageSize?: never }
    | { searchType: 'products'; title?: never; page: number; pageSize: number }

export const fetchShopifyCollectionsOrProducts = async ({
    searchType = 'products',
    title,
    page,
    pageSize,
}: FetchParams) => {
    if (title) {
        const response = await makeAPICall({
            url:
                process.env.REACT_APP_BASE_URL +
                (searchType === 'products' ? APIEndPoints.productSearch : APIEndPoints.diyDiscounts.collection),
            method: 'get',
            params: searchType === 'products' ? { name: title } : { title },
            skipMode: true,
        })
        if (searchType === 'products') {
            response.data.data = response.data.data.filter((product: SearchedProduct) => product.status === 'active')
        }
        return response
    } else {
        const response = await makeAPICall({
            url:
                process.env.REACT_APP_BASE_URL +
                (searchType === 'products' ? APIEndPoints.allProducts : APIEndPoints.allCollections),
            method: 'get',
            params: { page: page, pageSize: pageSize },
            skipMode: true,
        })
        if (searchType === 'products') {
            response.data.hasMore = response.data.data.length === pageSize
            response.data.data = response.data.data
                .filter((product: SearchedProduct) => product.status === 'active')
                .reduce((all, product) => {
                    if (product.variants.length > 0) {
                        return [
                            ...all,
                            ...product.variants.map((variant) => ({
                                ...product,
                                ...variant,
                                variant_name: variant.name,
                            })),
                        ]
                    }
                    return [...all, product]
                }, [])
        }
        return response
    }
}

export const updateMerchantData = async (merchant_id: string, data: any) => {
    const response = await makeAPICall({
        url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateMerchant(merchant_id),
        method: 'post',
        payload: data,
    })
    return response
}
