import { updateBreadcrumbs } from '@gokwik/utilities'
import { ArrowLeftOutlined, Button, EnvironmentOutlined, LoginOutlined, Tabs } from 'gokwik-ui-kit'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useShopifyMerchantConfigApi from '@library/utilities/hooks/useShopifyMerchantConfig'
import LoginPageCustomisation from './loginPage'
import AddressPageCustomisation from './addressPage'
import { ICommonEvents } from '@library/utilities/helpers/eventsHelper'

const CheckoutUiCustomisation = (props: {events: ICommonEvents}) => {
    const navigate = useNavigate()
    const { configsUpsert } = props.events;
    const { config, saveConfig } = useShopifyMerchantConfigApi({
        useCache: true,
        fireFailureEvent: configsUpsert.edit.fireFailureEvent,
        fireSuccessEvent: configsUpsert.edit.fireSuccessEvent
    })
    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'checkout_settings',
                href: '/checkout/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'ui-customisation',
                href: '/checkout/settings/ui-customisation',
                text: 'UI Customisation',
            },
            {
                key: 'checkout-ui',
                href: '/checkout/settings/ui-customisation/checkout-ui',
                text: 'Gokwik Checkout UI',
            },
        ])
    }, [])

    const items = [
        {
            label: 'Login',
            key: 'login',
            icon: <LoginOutlined />,
            children: <LoginPageCustomisation config={config} saveConfig={saveConfig} fireDiscardSaveEvent={configsUpsert.discardSave}/>,
        },
        {
            label: 'Address',
            key: 'address',
            icon: <EnvironmentOutlined />,
            children: <AddressPageCustomisation config={config} saveConfig={saveConfig} fireDiscardSaveEvent={configsUpsert.discardSave} />,
        },
    ]

    return (
        <div className='rounded-sm p-2'>
            <div className='flex items-top'>
                <Button className='bg-gray-200 border-none' onClick={() => navigate(-1)}>
                    <ArrowLeftOutlined />
                </Button>
                <div className='ml-2'>
                    <h2 className='text-lg font-semibold'>Gokwik Checkout UI</h2>
                    <p>
                        This configuration allows to control the banner text and colors, as well as the text on the
                        call-to-action buttons on GoKwik Checkout UI.
                    </p>
                </div>
            </div>
            <div>
                <Tabs items={items} defaultActiveKey='login' />
            </div>
        </div>
    )
}

export default CheckoutUiCustomisation
