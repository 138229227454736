import React, { useEffect, useState } from 'react'
import { Switch, Input, Modal, message, Table, ColumnsType, Tooltip, Button, Row, Col } from 'gokwik-ui-kit'
import { SearchOutlined, HeartOutlined, RedoOutlined } from 'gokwik-ui-kit'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'

const HealthServices: React.FC = () => {
    const [globalEnabled, setGlobalEnabled] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [modalVisible, setModalVisible] = useState<boolean>(false)
    const [modalAction, setModalAction] = useState<string>('')
    const [currentSwitch, setCurrentSwitch] = useState<string>('')
    const [confirmationInput, setConfirmationInput] = useState<string>('')
    const [merchantStatus, setMerchantStatus] = useState<Record<string, boolean>>({})
    const [previousSwitchState, setPreviousSwitchState] = useState<boolean>(false)
    const [merchants, setMerchants] = useState<Array<any>>([])

    useEffect(() => {
        fetchMerchants()
        fetchGlobalStatus()
    }, [])
    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'health',
                href: '/checkout/health',
                text: 'Shopify Checkout Health',
            },
        ])
    }, [])

    const fetchMerchants = async () => {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getBulkMerchantHealth,
            })
            if (response.success) {
                const merchantsData = response?.data?.data?.merchants
                setMerchants(merchantsData)
            } else {
                message.error('Failed to fetch Merchants')
            }
        } catch (error) {
            console.error('Failed to fetch Merchants')
        }
    }

    const fetchGlobalStatus = async () => {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getGlobalStatus,
            })
            if (response.success) {
                setGlobalEnabled(response?.data?.data?.global_health_status === 'active')
            } else {
                message.error('Failed to fetch Global Status')
            }
        } catch (error) {
            console.error('Failed to fetch global status')
        }
    }

    const fetchMerchantHealth = async (merchant_id) => {
        const healthResponse = await makeAPICall({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL + APIEndPoints.getMerchantHealth}`,
            params: {
                id: merchant_id,
            },
        })
        if (healthResponse.success) {
            const health = healthResponse.data.data
            setMerchants([health])
        } else {
            message.error('Failed to fetch Merchant Health')
        }
    }

    const showModal = (action: string, switchType: string) => {
        setModalAction(action)
        setCurrentSwitch(switchType)
        setConfirmationInput('')
        setModalVisible(true)
    }

    const handleGlobalSwitchChange = (checked: boolean) => {
        setPreviousSwitchState(globalEnabled)
        showModal(checked ? 'enable' : 'disable', 'global')
    }

    const handleMerchantSwitchChange = (merchant: string, checked: boolean) => {
        setPreviousSwitchState(merchantStatus[merchant] || false)
        showModal(checked ? 'enable' : 'disable', merchant)
    }

    const handleModalOk = async () => {
        if (confirmationInput === modalAction) {
            if (currentSwitch === 'global') {
                try {
                    let response = await makeAPICall({
                        method: 'post',
                        url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateGlobalStatus,
                        payload: {
                            merchant_id: 'global',
                            action: modalAction === 'enable' ? 'enable' : 'disable',
                        },
                    })

                    if (response.success) {
                        fetchGlobalStatus()
                        fetchMerchants()

                        message.success(`Global functionality ${modalAction}d`)
                    } else {
                        message.error('Failed to update global status.')
                    }
                } catch (error) {
                    message.error(`Failed to update global status`)
                    console.error('Error updating global status:', error)
                }
            } else {
                try {
                    let response = await makeAPICall({
                        method: 'put',
                        url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateMerchantHealth,
                        payload: { merchant_id: currentSwitch, is_checkout_healthy: modalAction === 'enable' },
                    })

                    if (response.success) {
                        if (searchTerm === currentSwitch) {
                            fetchMerchantHealth(currentSwitch)
                        } else {
                            fetchMerchants()
                        }

                        message.success(`Merchant functionality ${modalAction}d successfully`)
                    } else {
                        message.error('Failed to update merchant status')
                    }
                } catch (error) {
                    message.error(`Failed to update merchant status`)
                    console.error('Error updating merchant status:', error)
                }
            }
            setModalVisible(false)
        } else {
            message.error(`Please type "${modalAction}" to confirm`)
        }
    }

    const handleModalCancel = () => {
        if (currentSwitch === 'global') {
            setGlobalEnabled(previousSwitchState)
        } else {
            setMerchantStatus((prev) => ({
                ...prev,
                [currentSwitch]: previousSwitchState,
            }))
        }
        setModalVisible(false)
    }
    const handleReset = () => {
        setSearchTerm('')
        setMerchants([])
        fetchMerchants()
    }

    const columns: ColumnsType<any> = [
        {
            title: 'Merchant Name',
            dataIndex: 'business_name',
            key: 'name',
            align: 'left',
        },
        {
            title: 'Merchant ID',
            dataIndex: 'merchant_id',
            key: 'merchant_id',
            align: 'left',
        },
        {
            title: 'Status',
            key: 'status',
            align: 'right',
            render: (text: any, record: any) => (
                <Switch
                    checked={record.is_checkout_healthy}
                    onChange={(checked) => handleMerchantSwitchChange(record.merchant_id, checked)}
                />
            ),
        },
    ]

    return (
        <div className='p-3 w-full'>
            <div className='bg-white p-3 rounded-lg w-full mb-5'>
                <div className='flex justify-between items-center mb-3'>
                    <div>
                        <p className='text-base font-medium mb-1'>
                            {' '}
                            <HeartOutlined className='text-lg' /> Shopify Checkout Health
                        </p>
                        <p className='text-xs text-gray-400 font-normal mt-1'>
                            Enable/Disable checkout on the basis of its health.
                        </p>
                    </div>
                    <div className='flex items-end space-x-2'>
                        <Tooltip
                            placement='right'
                            title='Toggle this global switch to enable or disable all merchant switches at once.'
                        >
                            <span className='text-small font-medium text-gray-500'>Global Health Status</span>
                            <Switch
                                checked={globalEnabled}
                                onChange={handleGlobalSwitchChange}
                                checkedChildren='Healthy'
                                unCheckedChildren='Unhealthy'
                                className={`ml-4 ${globalEnabled ? '!bg-success-500' : 'bg-error-500'}`}
                            />
                        </Tooltip>
                    </div>
                </div>

                <Row gutter={16}>
                    <Col span={8}>
                        <Input
                            type='text'
                            placeholder='Search merchant ID ex: 12wyqc2guqmkrw6406j'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            suffix={<SearchOutlined className='text-gray-400' />}
                        />
                    </Col>

                    <Col className='flex items-center gap-x-3'>
                        <Button onClick={() => fetchMerchantHealth(searchTerm)} variant='primary'>
                            <SearchOutlined />
                            Search
                        </Button>
                        <Button variant='default' onClick={handleReset}>
                            <RedoOutlined />
                            Reset
                        </Button>
                    </Col>
                </Row>
            </div>

            <div className='bg-white p-3 rounded-lg'>
                <Table
                    dataSource={merchants}
                    columns={columns}
                    rowKey='merchant_id'
                    pagination={merchants?.length > 10 ? { pageSize: 10 } : false}
                    className='w-full bg-white rounded-lg overflow-hidden'
                />
            </div>

            <Modal
                title='Confirm Action'
                visible={modalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                maskClosable={false}
                className='rounded-md '
            >
                <p className='mb-4'>
                    To {modalAction}, please type "{modalAction}" below to confirm.
                </p>
                <Input
                    placeholder='Type here'
                    value={confirmationInput}
                    onChange={(e) => setConfirmationInput(e.target.value)}
                    className='w-full border border-gray-300 rounded-md'
                />
            </Modal>
        </div>
    )
}

export default HealthServices
