import {
    Col,
    Input,
    Modal,
    Row,
    message,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import { ProductList } from './productsList'
import { CollectionsList } from './collectionsList'
import { debounce } from 'lodash'

type Props = {
    showProductSelectionModal?: any
    setShowProductSelectionModal?: any
    modalProps?: any
    searchValue?: any
    setSearchValue?: any
    category?: any
    selectedProducts?: any
    setSelectedProducts?: any
    setRenderProductMapping?: any
    selectedProductVariants?: any
    setSelectedProductVariants?: any
    setShowCheckedListTags?: any
    setFormState?: any
    formState?:any
    selectedCollections?: any
    setSelectedCollections?: any
}

const ProductsOrCollectionsSearchModal = (props: Props) => {
     const {
        showProductSelectionModal,
        setShowProductSelectionModal,
        searchValue,
        setSearchValue,
        category,
        selectedProducts,
        setSelectedProducts,
        setRenderProductMapping,
        selectedProductVariants,
        setSelectedProductVariants,
        setSelectedCollections,
        selectedCollections,
        setShowCheckedListTags,
        setFormState,
        formState
    } = props
    const { title, searchPlaceholder } = props.modalProps
    const merchant_details = useSelector(getMerchantDetails)
    const [list, setList] = useState([])
    const [checkedList, setCheckedList] = useState([...selectedProducts])
    const [checkedVariantsList, setCheckedVariantsList] = useState([...selectedProductVariants])
    const [checkedCollectionsList, setCheckedCollectionsList] = useState(selectedCollections?.length > 0 ? [...selectedCollections] : [])

    const onProductCheckChange = (e, product) => {
        const isProductSelected = checkedList?.some((item) => item?.product_id === product?.product_id)
        if (e?.target?.checked && !isProductSelected) {
            setCheckedList((prev) => [...prev, product])
            setCheckedVariantsList((prev) => [...prev, ...product?.variants])
        } else {
            setCheckedList(checkedList?.filter((item) => item?.product_id !== product?.product_id))
            setCheckedVariantsList(checkedVariantsList?.filter((variant) => variant?.product_id !== product?.product_id))
        }
    }

    const onProductVariantsCheckChange = (e, product, variant) => {
        const isVariantSelected = checkedVariantsList?.some((item) => item?.variant_id === variant?.variant_id)
        if (e?.target?.checked && !isVariantSelected) {
            if (!checkedList?.some((item) => item?.product_id === variant?.product_id)) {
                setCheckedList((prev) => [...prev, product])
            }
            setCheckedVariantsList((prev) => [...prev, variant]) 
        } else {
            if (checkedVariantsList?.filter((item) => item?.product_id === product?.product_id)?.length === 1) {
                setCheckedList(checkedList?.filter((item) => item?.product_id !== product?.product_id))
            }
            setCheckedVariantsList(checkedVariantsList?.filter((item) => item?.variant_id !== variant?.variant_id))
        }
    }
    
 
    const onCollectionCheckChange = (e, collection) => {
        const collection_id = collection.id
        const collection_name = collection.title
         const isCollectionSelected = checkedCollectionsList?.some((item) => item?.collection_id === collection_id)
        if (e?.target?.checked && !isCollectionSelected) {
            setCheckedCollectionsList((prev) => [...prev, {
                collection_id,
                collection_name
            }])            
        } else {
            setCheckedCollectionsList(checkedCollectionsList.filter((item) => item?.collection_id !== collection_id))
        }
     }
 
    const handleSearchChange = (e) => {
        const value = e.target.value
        setSearchValue(value)
    }
    const fetchCollectionListDebounced = debounce(async (searchValue) => {
            try {
                let response = await makeAPICall({
                    method: 'get',
                    url:
                        process.env.REACT_APP_BASE_URL +
                        APIEndPoints.discountConfig.getCollectionsList(merchant_details?.m_id, searchValue),
                })
                if (response?.data?.status_code === 200) {
                    setList(response?.data?.data)
                } else {
                    message.error(`Error fetching products list`)
                    setList([])
                }
             } catch (error) {
                console.error('Error fetching products:', error)
                setList([])
            }
        
    }, 3000)

    const fetchProductsListDebounced = debounce(async (search_text) => {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.DISCOUNT_CONFIG_BASE_URL + APIEndPoints.discountConfig.getProductsList,
                params: { search_text: search_text, merchant_id: merchant_details?.m_id },
            })
            if (response?.data?.status_code === 200) {
                setList(response?.data?.data)
            } else {
                message.error(`Error fetching products list`)
                setList([])
            }
        } catch (error) {
            console.error('Error fetching products:', error)
            setList([])
        }
    }, 3000)

    const saveDetails =  () => {
        if(category === 'specific_products') {
            setSearchValue('')
            setShowProductSelectionModal(false)
            setShowCheckedListTags(true)
            setFormState((prev) => {
                const temp = JSON.parse(JSON.stringify(prev))
                temp.rules[0].conditions[0].applicable_list = getCheckedProductsExcludingVariants(checkedList, checkedVariantsList)
                temp.rules[0].conditions[0].product_variant_applicable_list = getCheckedVariantIdsExcludingProducts(checkedList, checkedVariantsList)
                setRenderProductMapping(temp?.rules[0]?.conditions[0]?.product_name_mapping);
                temp.rules[0].conditions[0].selected_products = checkedList
                temp.rules[0].conditions[0].selected_product_variants = checkedVariantsList
                if ('collection_name_mapping' in temp.rules[0].conditions[0]) {
                    delete temp.rules[0].conditions[0].collection_name_mapping;
                }
                return temp
            })
        }
        else {
            setSearchValue('')
            setShowProductSelectionModal(false)
            setShowCheckedListTags(true)
             setSelectedCollections(checkedCollectionsList)
             setFormState((prev) => {
                const temp = JSON.parse(JSON.stringify(prev))
                temp.rules[0].conditions[0].applicable_list = checkedCollectionsList?.map((item) => item?.collection_id)
                if ('product_name_mapping' in temp.rules[0].conditions[0]) {
                    delete temp.rules[0].conditions[0].product_name_mapping;
                }
                return temp
            })
        }
    }

    const getCheckedProductsExcludingVariants = (checkedList, checkedVariantsList) => {
        let selectedProductIds: string[] = checkedList?.flatMap((productItem) => {
            const checkedVariantsForProduct = checkedVariantsList?.filter(
                (variant) => variant?.product_id === productItem?.product_id
            )
            if (
                checkedVariantsForProduct?.length === productItem?.variants?.length &&
                checkedVariantsForProduct?.length > 0
            ) {
                return [productItem?.product_id]
            }
            return [];
        })
        selectedProductIds = selectedProductIds?.filter(Boolean);
        return selectedProductIds
    }

    const getCheckedVariantIdsExcludingProducts = (checkedList, checkedVariantsList) => {
        let selectedVariantIds: string[] = checkedList?.flatMap((productItem) => {
            const checkedVariantsForProduct = checkedVariantsList?.filter(
                (variant) => variant?.product_id === productItem?.product_id
            )
            if (
                checkedVariantsForProduct?.length !== productItem?.variants?.length &&
                checkedVariantsForProduct?.length > 0
            ) {
                return checkedVariantsForProduct?.map((variantItem) => variantItem?.variant_id)
            }
            return [];
        })
        selectedVariantIds = selectedVariantIds?.filter(Boolean);
        return selectedVariantIds
    }

    useEffect(() => {
         if (category === 'specific_products') {
            fetchProductsListDebounced(searchValue)
        } else {
            fetchCollectionListDebounced(searchValue)
        }
    }, [searchValue])


    useEffect(() => {
        if (category === 'specific_products') {
            setFormState((prev) => {
                const temp = JSON.parse(JSON.stringify(prev))
                temp.rules[0].conditions[0].product_name_mapping = checkedList?.map((productItem) => {
                    const checkedVariantsForProduct = checkedVariantsList?.filter(
                        (variant) => variant?.product_id === productItem?.product_id
                    );
                    if (
                        checkedVariantsForProduct?.length === productItem?.variants?.length &&
                        checkedVariantsForProduct?.length > 0
                    ) {
                        return {
                            product_name: productItem?.product_name,
                            product_id: productItem?.product_id,
                            variant_ids: [],
                        };
                    }
                    const selectedVariants = checkedVariantsForProduct?.map((variantItem) => ({
                        variant_name: variantItem?.name,
                        variant_id: variantItem?.variant_id,
                    }));
                    return {
                        product_name: productItem?.product_name,
                        product_id: productItem?.product_id,
                        variant_ids: selectedVariants,
                    };
                });
                return temp
            })
        }
    }, [checkedList, checkedVariantsList])

    useEffect(() => {
        if(category === 'specific_collections') {
            setFormState((prev) => {
                const temp = JSON.parse(JSON.stringify(prev))
                temp.rules[0].conditions[0].collection_name_mapping = checkedCollectionsList?.map((item) => ({
                    collection_id: item?.collection_id,
                    collection_name: item?.collection_name,
                }))
                return temp
            })
        }
    }, [checkedCollectionsList])

    return (
        <Modal
            title={title}
            width={555}
            cancelText={'Cancel'}
            okText={'Add'}
            centered
            open={showProductSelectionModal}
            onOk={() => saveDetails()}
            onCancel={() => {
                setSearchValue('')
                setShowProductSelectionModal(false)
                setCheckedList([])
                setCheckedVariantsList([])
            }}
        >
            <div>
                <Row>
                    {' '}
                    <Col span={24}>
                        <Input
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder={searchPlaceholder}
                            allowClear
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {/* {list?.length === 0 && <div className='flex justify-center items-center p-5'>No Data</div>} */}
                        {category === 'specific_products' ? <ProductList list={list} onProductCheckChange={onProductCheckChange} onProductVariantsCheckChange={onProductVariantsCheckChange} checkedList={checkedList} checkedVariantsList={checkedVariantsList} /> : <CollectionsList list={list} onCollectionCheckChange={onCollectionCheckChange} checkedList={checkedCollectionsList} />}
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export { ProductsOrCollectionsSearchModal }