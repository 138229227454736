import { DiscountTypeEnum } from '@library/utilities/types'
import { Button, Form, message, notification } from 'gokwik-ui-kit'
import React, { useEffect, useMemo } from 'react'
import { useState } from 'react'
import { discountConfig } from './config'
import { filterDateFormatter, makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import dayjs from 'dayjs'
import { useLocation, useNavigate } from 'react-router-dom'
import { saveFiltersData } from '@store/filters'
import { getRule } from './rules'
import { discountFormValidations } from './validationHelper'
import { start } from 'single-spa'
dayjs.locale('en')
interface CreateDiscountProps { }

interface DiscountRule {
    rule_id?: string
    priority?: number
    conditions?: {
        discount_type?: string
        min_cart_value?: number
    }[]
    actions?: {
        applicability_type?: string
        discount_type?: string
        max_discount?: number
        value?: number
    }[]
}

interface EligibleCustomer {
    eligiblity_type?: string
    add?: String[]
}

interface DiscountFormState {
    id?: string
    // customer_segments?: string
    method?: string
    code?: string
    discount_category?: string
    discount_codes?: String[]
    discount_codes_url?: string
    combinations?: any
    start_date?: any
    end_date?: any
    max_discount_per_customer?: number
    available_coupons?: number
    applicable_for_all_users?: boolean
    merchant_id?: string
    discount_type?: string
    rules?: any
    eligible_customer?: any
    is_total_usage_limit?: boolean
    // eligible_customer?: EligibleCustomer
}

const today = dayjs()

const useCreateDiscount = (type: string) => {
    const initialValues = useMemo(() => {
       
        return {
            // start_date: dayjs(),
            // customer_segments: 'all',
            method: 'code',
            discount_category: 'single',
            // applicable_for_all_users: true,
            discount_type: type,
            eligible_customer: {
              all_customers: true,
              customers: {
                add: [],
                 mapping: []
              },
              segments: {
                add: [],
                 mapping: []
              }
            },
            rules: getRule(type),
        }
    }, [type])
    const navigate = useNavigate()
    const location = useLocation()
    const [discountAction, setDiscountAction] = useState('')
    const [bulkCodesPath, setBulkCodesPath] = useState('')
    const merchant_details = useSelector(getMerchantDetails)
    const [discountType, setDiscountType] = useState(type)
    const [currentStep, setCurrentStep] = useState(0) //do-not-change-this-and-push
    const [form] = Form.useForm<DiscountFormState>()
    const [formState, setFormState] = useState<DiscountFormState>({ ...initialValues })
    const [codesList, setCodesList] = useState([])
    const [fileList, setFileList] = useState([])

    const fetchDiscountWithDiscountId = async (discount_id: string) => {
         try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.DISCOUNT_CONFIG_BASE_URL + APIEndPoints.discountConfig.getDiscount(discount_id),
            }) 
             if (response?.data?.status_code === 200) {
                const discount_type = response?.data?.data?.discount_type
                setDiscountType(discount_type)
                const updatedState = response?.data?.data
                form.setFieldsValue({
                    ...updatedState,
                    ...(updatedState?.max_coupon_per_customer && {
                        max_discount_per_customer: 1
                    }),
                    ...(updatedState?.available_coupons > 0 && {
                        is_total_usage_limit: true
                    }),
                    ...(response?.data?.data?.start_date && {
                        start_date: dayjs(response?.data?.data?.start_date),
                    }),
                    ...(response?.data?.data?.end_date && {
                        end_date: dayjs(response?.data?.data?.end_date),
                    }),
                })
                setFormState({ ...updatedState })
            } else {
                message.error(`Discount Error: Not found discount with id ${discount_id}`)
            }
        } catch (err) {
            console.warn(err)
        }
    }

    useEffect(() => {
        if (formState?.discount_codes?.length) {
            setCodesList(formState?.discount_codes)
        }
    }, [formState])

    useEffect(() => {
        if (location?.state?.action && location?.state?.discount_id) {
            fetchDiscountWithDiscountId(location?.state?.discount_id)
            setDiscountAction(location?.state?.action)
        } else if (window?.location?.pathname && window?.location?.pathname?.includes('edit-discount')) {
            let pathParts = window?.location?.pathname?.split('/')
            let id = pathParts[pathParts.length - 1]
            if (id) {
                fetchDiscountWithDiscountId(id)
                setDiscountAction('edit')
            } else {
                message.warning('No Discount Id Found : Redirecting...')
                navigate('/checkout/settings/discount')
            }
        } else {
            setDiscountAction('')
        }
    }, [type, window?.location?.pathname])
 
    const onFormValuesChange = (changedValues: Partial<DiscountFormState>, allValues: DiscountFormState) => {
        if (
            discountType === DiscountTypeEnum.buy_x_at_y ||
            discountType === DiscountTypeEnum.gift_with_product ||
            (discountType === DiscountTypeEnum.quantity && !changedValues?.rules?.length)
        ) {
            delete allValues.rules
        }
        // if (discountType === DiscountTypeEnum.quantity && !formState?.rules?.length) {
        //     formState.rules = allValues?.rules
        // } else if (discountType === DiscountTypeEnum.quantity && formState?.rules?.length) {
        //     delete allValues?.rules
        // }
        const updatedValues = {
            ...formState,
            ...allValues,
            ...(allValues?.start_date && formState?.start_date && { start_date: dayjs(allValues?.start_date) }),
            ...(allValues?.end_date && formState?.end_date && { end_date: dayjs(allValues?.end_date) }),

            ...(changedValues?.code && { code: changedValues?.code?.toUpperCase().replace(/[^A-Z0-9]/g, '') }),
            ...('max_discount_per_customer' in changedValues && {
                max_discount_per_customer: Number(changedValues?.max_discount_per_customer),
            }),
            ...(changedValues?.available_coupons && { available_coupons: Number(changedValues?.available_coupons) }),
        }
         
         if(updatedValues?.max_discount_per_customer?.toString() === 'false') {
            delete updatedValues.max_discount_per_customer
         }
         
        const selectedOption = changedValues?.rules?.[0]?.conditions?.[0]?.minimum_purchase_requirement;
       
        if (selectedOption) {
            if (selectedOption?.no_minimum_requirement === "no_minimum_requirement") {
                updatedValues.rules[0].conditions[0].minimum_purchase_requirement = {
                    no_minimum_requirement: true,
                    minimum_purchase_amount: null,
                    minimum_quantity: null,
                }
            } else if (selectedOption?.minimum_purchase_amount !== undefined) {
                updatedValues.rules[0].conditions[0].minimum_purchase_requirement = {
                    no_minimum_requirement: false,
                    minimum_purchase_amount: selectedOption.minimum_purchase_amount,
                    minimum_quantity: null,
                }
            } else if (selectedOption?.minimum_quantity !== undefined) {
                updatedValues.rules[0].conditions[0].minimum_purchase_requirement = {
                    no_minimum_requirement: false,
                    minimum_purchase_amount: null,
                    minimum_quantity: selectedOption.minimum_quantity,
                }
             }
        } else if (formState?.rules[0]?.conditions[0]?.minimum_purchase_requirement?.no_minimum_requirement) {
            updatedValues.rules[0].conditions[0].minimum_purchase_requirement = {
                no_minimum_requirement: true,
                minimum_purchase_amount: null,
                minimum_quantity: null,
            }
        }

        if (changedValues?.discount_category === 'bulk') {
            updatedValues.method = 'bulk'
        } else if (changedValues?.discount_category === 'single') {
            updatedValues.method = 'code'
        }

        if (Object.keys(changedValues)?.includes('is_total_usage_limit') && !changedValues.is_total_usage_limit) {
            updatedValues.available_coupons = null
        }

        form.setFieldsValue({ ...updatedValues })

        setFormState({ ...updatedValues })
    }

    const checkFileValidity = async (path) => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.DISCOUNT_CONFIG_BASE_URL + APIEndPoints.discountConfig.CSVValidityCheck,
                payload: { path: path, merchant_id: merchant_details?.m_id },
            })
            if (response?.response?.status === 400) {
                return false
            } else if (response?.data?.status_code === 200) {
                return true
            }
        } catch (error) {
            console.warn(error)
        }
    }

    const uploadDiscountCSV = async (originFileObj, merchant_id) => {
        const preSignedURLResponse = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.discountConfig.getDiscountPresignedURL,
            params: { file_name: 'discount_codes.csv', merchant_id: merchant_id },
        })

        if (preSignedURLResponse?.data?.status_code === 200) {
            const preSignedURL = preSignedURLResponse?.data?.data?.url
            if (preSignedURL) {
                const urlParts = preSignedURL.split('/')
                const lastPart = urlParts[urlParts.length - 1]
                const fileNameWithParams = lastPart.split('?')[0]
                const CSVfileNameFromPresignedURL = fileNameWithParams.substring(
                    fileNameWithParams.lastIndexOf('/') + 1,
                )

                const csvFile = originFileObj
                const awsResponse = await fetch(preSignedURL, {
                    method: 'PUT',
                    body: csvFile,
                })

                if (awsResponse?.status === 200) {
                    const URLPathResponse = await makeAPICall({
                        method: 'post',
                        url:
                            process.env.REACT_APP_BASE_URL +
                            APIEndPoints.discountConfig.getFileUploadPath +
                            '?file=' +
                            originFileObj?.name,
                        payload: { file_name: CSVfileNameFromPresignedURL, merchant_id: String(merchant_id) },
                    })

                    if (URLPathResponse?.data?.status_code === 200) {
                        const discount_codes_url = URLPathResponse?.data?.data?.path
                        const isFileValid = await checkFileValidity(discount_codes_url)

                        if (isFileValid) {
                            const updatedValues = {
                                ...formState,
                                discount_codes_url,
                            }
                            setFileList([originFileObj])
                            form.setFieldsValue({ ...updatedValues })
                            setFormState({ ...updatedValues })
                            message.success('File uploaded successfully')
                        } else {
                            message.error('Invalid File: Please check instructions')
                            setFileList([])
                            return
                        }
                    } else {
                        message.error('Upload Failed: Failed to fetch URL path')
                        return
                    }
                } else {
                    message.error('Upload Error : AWS Error')
                }
            }
        } else {
            message.error('Upload Failed: Presigned URL error')
            return
        }
    }
    async function handleGenerateCodeResponse(response) {
        try {
            if (!response || !response.data || response.data.status_code !== 200) {
                message.error('Something went wrong! Try again.')
                return
            }

            const responseData = response.data.data
            const codes = responseData?.codes
            const path = responseData?.path

            const updatedValues = { ...formState }

            if (Array.isArray(codes) && codes.length > 1 && path) {
                //bulk code generation
                const isRandomGeneratedCodeValid = await checkFileValidity(path)

                if (!isRandomGeneratedCodeValid) {
                    message.error('This combination is invalid, please try different combinations')
                    return
                }

                setCodesList(codes)
                setBulkCodesPath(path)

                updatedValues.discount_codes_url = path
                updatedValues.discount_codes = codes
            } else {
                //single code generation
                updatedValues.code = codes[0]
                updatedValues.discount_codes_url = ''
            }

            form.setFieldsValue({ ...updatedValues })
            setFormState({ ...updatedValues })
        } catch (error) {
            message.error(error.message)
        }
    }

    const generateDiscountCode = async (payload) => {
        const payloadData = payload
        payloadData.merchant_id = merchant_details?.m_id
        try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.DISCOUNT_CONFIG_BASE_URL + APIEndPoints.discountConfig.generateDiscountCode,
                payload: payloadData,
            })
            handleGenerateCodeResponse(response)
        } catch (err) {
            console.warn(err)
        }
    }

    const saveStepAndProceed = () => {
        if (discountAction === 'edit' || !discountAction) {
            if (discountFormValidations(discountType, formState)) {
                 addOrEditDiscount(formState) 
                
                if (currentStep < 3) {
                    setCurrentStep((prev) => prev + 1)
                }
            } else {
                return
            }
        } else {
            if (currentStep < 3) {
                setCurrentStep((prev) => prev + 1)
            }
        }

        // if (currentStep < (formState?.method === 'automatic' && formState?.discount_category !== 'bulk' ? 2 : 3)) {
        //     setCurrentStep((prev) => prev + 1)
        // }
    }

    const goBack = () => {
        if (discountAction === 'view' && currentStep === 0) {
            navigate('/checkout/settings/discount')
        }
        if (currentStep > 0) {
            setCurrentStep((prev) => prev - 1)
          
         }
    }

    const getCards = React.useMemo(() => {
        return (
            <Form
                disabled={discountAction === 'view'}
                form={form}
                onValuesChange={onFormValuesChange}
                initialValues={{ ...formState }}
                className='flex flex-col gap-2'
                onFinish={saveStepAndProceed}
            >
                {discountConfig({
                    formState,
                    generateDiscountCode,
                    uploadDiscountCSV,
                    codesList,
                    bulkCodesPath,
                    form,
                    setFormState,
                    discountAction,
                    fileList,
                 })?.[discountType]?.[currentStep]?.map((item, index) => {
                    return <div key={index}>{item}</div>
                })}
                {discountAction !== 'view' && (
                    <div className='w-full flex gap-1 justify-end p-3 rounded mt-2'>
                        {(currentStep > 0 || discountAction === 'view') && (
                            <Button variant='default' onClick={goBack}>
                                {discountAction === 'view' ? 'Back' : 'Go Back'}
                            </Button>
                        )}
                        <Form.Item>
                            <Button variant='primary' htmlType='submit'>
                                {discountAction === 'view' ? 'View Next' : 'Save & Proceed'}
                            </Button>
                        </Form.Item>
                    </div>
                )}
            </Form>
        )
    }, [discountType, currentStep, formState, codesList, discountAction, fileList])

    const getFocusWord = useMemo(() => {
        if (discountAction === 'edit') {
            return 'Edit'
        } else if (discountAction === 'view') {
            return 'View'
        } else {
            return 'Create a'
        }
    }, [discountAction, type])

    const getTitles = React.useMemo(() => {
        switch (discountType) {
            case DiscountTypeEnum.capping:
                return {
                    headerTitle: `${getFocusWord} capping discount`,
                    subTitle: 'Reduce product prices with a percentage discount up to a set limit',
                }

            case DiscountTypeEnum.buy_x_at_y:
                return {
                    headerTitle: `${getFocusWord} Buy X, At Y discount`,
                    subTitle: 'Buy specific products together at a special price',
                }

            case DiscountTypeEnum.dynamic:
                return {
                    headerTitle: `${getFocusWord} dynamic order value based discount`,
                    subTitle: 'Discounts apply within certain order value ranges',
                }

            case DiscountTypeEnum.quantity:
                return {
                    headerTitle: `${getFocusWord} product quantity based discount`,
                    subTitle: 'Discounts increase with the quantity purchased',
                }
            
            case DiscountTypeEnum.gift_with_product:
                return {
                    headerTitle: `${getFocusWord} gift with products discount`,
                    subTitle: 'Free gifts for specific products purchased',
                }

            default:
                return {
                    headerTitle: 'Invalid Discount',
                    subTitle: null,
                }
        }
    }, [discountType, discountAction])

    const addOrEditDiscount = async (formState) => {
    
        if (!merchant_details?.m_id) {
            message.error('Error: No merchant id')
        }

        if (!formState?.max_discount_per_customer) {
            formState.max_coupon_per_customer = 0
            formState.max_discount_per_customer = 0
        } else {
            formState.max_coupon_per_customer = 1
            formState.max_discount_per_customer = 1
        }

        delete formState?.is_total_usage_limit

        const payload = {
            ...formState,
            ...(formState?.start_date && {
                start_date: dayjs(formState?.start_date)?.toISOString(),
            }),
            ...(formState?.end_date && {
                end_date: dayjs(formState?.end_date)?.toISOString(),
            }),
        }
        if (!payload?.start_date && currentStep === 3) {
            //if last step is reached and no start date selected the send current datetime
             payload.start_date = dayjs()
        } 

        if (!payload?.start_date && currentStep === 2 && formState.method === 'automatic') {
            //if last step is reached in automatic
             payload.start_date = dayjs()
        }

        if (payload?.rules[0]?.actions[0]?.value) {
            payload.rules[0].actions[0].value = Number(payload?.rules[0]?.actions[0]?.value)
        }

        if (payload?.rules[0]?.actions[0]?.max_discount) {
            payload.rules[0].actions[0].max_discount = Number(payload?.rules[0]?.actions[0]?.max_discount)
        }

        if (formState?.discount_category === 'bulk') {
            formState.max_discount_per_customer = 1
        }

        payload.merchant_id = merchant_details?.m_id
        if(formState?.method === 'automatic' && currentStep === 2) {
            payload.status = 'scheduled'
            payload.eligible_customer = {}
        }
        if (currentStep === 3) {
            payload.status = 'scheduled'
        }
    

        if (formState?.discount_type === 'quantity') {
            let applicableList = formState.rules[0].conditions[0].applicable_list
            for (let i = 1; i < formState.rules.length; i++) {
                formState.rules[i].conditions[0].applicable_list = applicableList.slice()
            }
        }
        // !formState?.id && delete payload?.customer_segments 
          try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.DISCOUNT_CONFIG_BASE_URL + APIEndPoints.discountConfig.createDiscount,
                payload: payload,
            })

            if (response?.data?.status_code === 200) {
                 const discount_id = response?.data?.data?.id
                const discount_data = response?.data?.data
                const discount_rules = discount_data?.rules
               
                
                const updated_rules = discount_rules
                    ? discount_rules.map((rule) => ({
                        ...rule,
                        rule_id: rule.rule_id,
                    }))
                    : []
                 setFormState((prev) => ({
                    ...prev,
                    id: discount_id,
                    
                    // customer_segments:
                    //     discount_data?.applicable_for_all_users === true
                    //         ? 'all'
                    //         : discount_data?.eligible_customer?.eligiblity_type,
                    ...(response?.data?.data?.start_date && {
                        start_date: response?.data?.data?.start_date,
                    }),
                    ...(response?.data?.data?.end_date && {
                        end_date: response?.data?.data?.end_date ?? undefined,
                    }),
                    rules: updated_rules,
                    ...discount_data,
                }))
                 
             
                form.setFieldsValue({
                    ...(response?.data?.data?.start_date && {
                        start_date: dayjs(response?.data?.data?.start_date),
                    }),
                    ...(response?.data?.data?.end_date && {
                        end_date: dayjs(response?.data?.data?.end_date) ?? undefined,
                    }),
                })

                if (formState?.method === 'automatic' && currentStep === 2) {
                    message.success(`Discount ${discountAction === 'edit' ? 'Edited' : 'Created'} Successfully`)
                    navigate('/checkout/settings/discount')
                }

                if (
                    (formState?.method === 'code' ||
                        formState?.method === 'automatic' ||
                        formState?.method === 'bulk') &&
                    currentStep === 3
                ) {
                    message.success(`Discount ${discountAction === 'edit' ? 'Edited' : 'Created'} Successfully`)
                    if (formState?.method === 'bulk' && !discountAction) {
                        navigate(`/checkout/settings/discount`, {
                            state: {
                                notificationConfig: {
                                    show: true,
                                    title: 'Important Message',
                                    description:
                                        'We will dynamically create the discounts in your Shopify account as they are applied, to avoid reaching your Shopify discount limit',
                                    duration: 10,
                                    type: 'info',
                                },
                            },
                        })
                    } else {
                         navigate('/checkout/settings/discount')
                    }
                }

                // if (formState?.method === 'code' && currentStep === 2) {
                //     message.success(`Discount ${discountAction === 'edit' ? 'Edited' : 'Created'} Successfully`)
                //     navigate('/checkout/settings/discount')
                // } else if (formState?.method === 'automatic' && currentStep === 2) {
                //     message.success(`Discount ${discountAction === 'edit' ? 'Edited' : 'Created'} Successfully`)
                //     navigate('/checkout/settings/discount')
                // }
            } else if (response?.response?.status === 400) {
                setCurrentStep(0)
                const errorText = response?.response?.data?.data
                message.error(errorText)
                return
            }
        } catch (err) {
            console.warn(err)
        }
    }

    const stepsItems = [
        { title: 'Discount Setup', content: <>{getCards}</> },
        { title: 'Cart Conditions', content: <>{getCards}</> },
        { title: 'User Eligibilty', content: <>{getCards}</> },
        { title: 'Cart Limits and dates', content: <>{getCards}</> },
    ]

    const automaticStepItems = [
        { title: 'Discount Setup', content: <>{getCards}</> },
        { title: 'Cart Conditions', content: <>{getCards}</> },
        { title: 'Cart dates', content: <>{getCards}</> },
    ]

    const dynamicStepItems = () =>
        formState && formState?.method === 'automatic' && formState?.discount_category !== 'bulk'
            ? automaticStepItems
            : stepsItems

    return {
        saveStepAndProceed,
        goBack,
        dynamicStepItems,
        getTitles,
        formState,
        currentStep,
        discountAction,
        discountType,
    }
}

export { useCreateDiscount, DiscountFormState }
