import ColorPicketComponent from '@library/components/colorpicker'
import RichTextEditor from '@library/components/richtexteditor'
import { Button, Card, Col, Divider, Form, LinkOutlined, Row, Switch } from 'gokwik-ui-kit'
import React, { useEffect, useState } from 'react'
import BookDownload from '@library/images/book-download.svg'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { navigateToUrl } from 'single-spa'
import Link from '@library/images/PixelIcons/link.svg'

const initialValues = {
    banner_content: '',
}

const Payments = ({ onChange }) => {
    const [form] = Form.useForm()
    const [visibility, setVisibility] = useState({
        isBannerVisible: false,
        isSaveButtonVisible: true,
    })
    const [paymentData, setPaymentData] = useState<any>({
        banner_content: '',
        background_color: '#000000',
        text_color: '#FFFFFF',
    })
    const { config, loading, refetch, saveConfig } = useMerchantConfigApi({
        useCache: true,
    })

    useEffect(() => {
        onChange(paymentData)
    }, [paymentData])

    useEffect(() => {
        if (config && config?.custom_config?.payment_data) {
            setPaymentData((prev: any) => ({ ...prev, ...(config.custom_config.payment_data || paymentData) }))
            form.setFieldValue('background_color', config.custom_config.payment_data.background_color)
            form.setFieldValue('text_color', config.custom_config.payment_data.text_color)
            config?.custom_config?.payment_data?.isBannerVisible
                ? setVisibility((prev) => ({ ...prev, isBannerVisible: true }))
                : setVisibility((prev) => ({ ...prev, isBannerVisible: false }))
        }
    }, [config])

    const handleVisibilityChange = (key: string, value: boolean) => {
        setVisibility((prev) => ({ ...prev, [key]: value }))
        setVisibility((prev) => ({ ...prev, isSaveButtonVisible: false }))
        setPaymentData((prev) => ({ ...prev, [key]: value }))
    }

    const handleRichTextChange = (field: string) => (content: JSON) => {
        form.setFieldValue(field, content || {})
        setPaymentData((prev: any) => ({ ...prev, [field]: content }))
        setVisibility((prev) => ({ ...prev, isBannerVisible: true, isSaveButtonVisible: false }))
    }

    const renderFormItem = (
        label: string,
        subLabel: string,
        switchKey: string,
        editorKey: string,
        responseText: string,
        colorPicker: boolean,
        editorChangeHandler: (content: JSON) => void,
        description,
    ) => (
        <Form.Item name={editorKey} className='m-0' label={<span className='text-base font-medium'>{label}</span>}>
            <div className='flex w-full flex-row justify-between gap-x-2 pb-2'>
                <span className='font-md text-sm font-normal'>{subLabel}</span>
                <Switch
                    checked={visibility.isBannerVisible}
                    onChange={(checked) => handleVisibilityChange(switchKey, checked)}
                />
            </div>
            <p className='text-sm text-gray-500'>{description}</p>
            {visibility[switchKey] && (
                <span>
                    <RichTextEditor onChange={editorChangeHandler} selectedValue={responseText} />
                    {colorPicker && (
                        <>
                            <Divider />
                            <ColorPicketComponent
                                label={''}
                                primary={'background'}
                                secondary={'text'}
                                defaultValues={{
                                    firstColor: paymentData.background_color,
                                    secondColor: paymentData.text_color,
                                }}
                            />
                        </>
                    )}
                </span>
            )}
        </Form.Item>
    )

    const handleFormSubmit = () => {
        saveConfig({
            custom_config: {
                ...config.custom_config,
                payment_data: { ...paymentData },
            },
        })
        setVisibility((prev) => ({ ...prev, isSaveButtonVisible: true }))
    }

    return (
        <Form
            form={form}
            layout='vertical'
            onValuesChange={(value) => {
                setVisibility((prev) => ({ ...prev, isSaveButtonVisible: false }))
                setPaymentData((prev: any) => ({ ...prev, ...value }))
            }}
            onFinish={handleFormSubmit}
            initialValues={initialValues}
            className='gap-5 flex flex-col'
        >
            <div className='flex justify-between'>
                <div className='m-0'>
                    <span className='text-lg font-semibold'>Payments</span>
                    <p className='text-gray-500 gap-0'>
                        Control payment step UI on checkout - add banners, control CTA text and more.
                    </p>
                </div>
                <div className='m-0'>
                    <Button type='primary' htmlType='submit' disabled={visibility.isSaveButtonVisible}>
                        Save Changes
                    </Button>
                </div>
            </div>
            <Card size='small'>
                {renderFormItem(
                    'Banner',
                    'Display Banner',
                    'isBannerVisible',
                    'banner_content',
                    paymentData.banner_content.actualText,
                    true,
                    handleRichTextChange('banner_content'),
                    'Enable this to display an announcement banner to the customers on payment step of checkout',
                )}
            </Card>
            <Card size='small'>
                <div className='flex justify-between'>
                    <div className='m-0 p-0'>
                        <span className='text-base font-medium'>Payments UI</span>
                    </div>
                    <div className='m-0 p-0'>
                        <Button
                            type='link'
                            onClick={() => {
                                navigateToUrl('/payment/settings')
                            }}
                            className='flex items-center justify-center border-solid border-gray-100 p-2 text-semibold text-black rounded-md'
                        >
                            <span>
                                <img src={Link} className='w-3 h-3 mr-2' alt='link icon' />
                                Payments Control
                            </span>
                        </Button>
                    </div>
                </div>
            </Card>
        </Form>
    )
}

export default Payments
