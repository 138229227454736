import { Button, Card, Col, Drawer, Form, InfoCircleFilled, message, Row, Switch } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import BookDownload from '@library/images/book-download.svg'
import Farzi from '@library/images/PixelIcons/farzi.svg'
import Gokwik from '@library/images/PixelIcons/gokwik.svg'
import ClickPost from '@library/images/PixelIcons/eddShipping/clickpost.svg'
import Delhivery from '@library/images/PixelIcons/eddShipping/delhivery.svg'
import Shiprocket from '@library/images/PixelIcons/eddShipping/shiprocket.svg'
import Ecom from '@library/images/PixelIcons/eddShipping/ecom.svg'
import CustomUrl from '@library/images/PixelIcons/eddShipping/customUrl.svg'
import Sparkle from '@library/images/PixelIcons/sparkle.svg'
import Link from '@library/images/PixelIcons/link.svg'
import { useNavigate } from 'react-router-dom'
import RenderForm from '@library/components/integrations/formRender'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { selectMerchantCreds } from '@store/integrations/selectors'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@library/utilities/hooks'
import { saveCreds } from '@store/integrations'
import { deepEqualAndDiff, makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'

const EddShipping = () => {
    const [drawer, setDrawer] = useState(false)
    const [title, setTitle] = useState('')
    const [selectedKey, setSelectedKey] = useState('')
    const [selectedSection, setSelectedSection] = useState('')
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const { config, refetch, saveConfig } = useMerchantConfigApi()
    const customConfig = config?.custom_config
    const [resetFields, setResetFields] = useState(false)
    const merchantCredentials = useSelector(selectMerchantCreds)
    const eddProvider = merchantCredentials?.pincode_serviceability?.edd?.provider
    const pincodeProvider = merchantCredentials?.pincode_serviceability?.serviceability?.provider
    const dispatch = useAppDispatch()

    const handleSwitchChange = (key, checked, item, section) => {
        const items = section === 'edd' ? Edd : PincodeService
        const anyOtherEnabled = items.some((i) => i.key !== key && i.checked)
        if (checked && anyOtherEnabled) {
            message.error(
                'Only one provider can be active at a time. Disable the currently active provider to enable this one.',
            )
            return
        }

        if (checked && item.customize) {
            handleCustomizeClick(key, item, section)
        } else if (!checked) {
            if (
                (section === 'edd' && !merchantCredentials?.pincode_serviceability?.pincode_serviceability) ||
                (section === 'serviceability' && !merchantCredentials?.pincode_serviceability?.edd)
            ) {
                saveConfig({
                    use_pincode_api: false,
                })
            }
            const payload = {
                pincode_serviceability: {
                    ...(merchantCredentials.pincode_serviceability || {}),
                },
            }
            delete payload.pincode_serviceability[section]

            dispatch(
                saveCreds({
                    pincode_serviceability: Object.keys(payload.pincode_serviceability).length
                        ? payload.pincode_serviceability
                        : null,
                }),
            )
        }
    }

    const handleCustomizeClick = (key, item, section) => {
        const value = merchantCredentials.pincode_serviceability?.[section]
        if (value && value.provider === key.split('_')[0]) {
            form.setFieldsValue({
                pincode_serviceability: {
                    [section]: value,
                },
            })
        } else if (value && value.provider !== key.split('_')[0]) {
            form.setFieldsValue({
                pincode_serviceability: {
                    [section]: JSON.stringify(value),
                },
            })
        } else {
            setResetFields(true)
            setTimeout(() => {
                setResetFields(false)
            }, 1000)
        }

        setSelectedKey(key)
        setTitle(item.title)
        setDrawer(true)
        setSelectedSection(section)
    }
    const Edd = [
        {
            key: 'clickpost_edd',
            title: 'Clickpost',

            checked: eddProvider === 'clickpost',
            icon: ClickPost,
            customize: true,
        },
        {
            key: 'shiprocket_edd',
            title: 'Shiprocket',

            icon: Shiprocket,
            checked: eddProvider === 'shiprocket',
            customize: true,
        },
        {
            key: 'customUrl_edd',
            title: 'Add Custom URL',

            checked: !!eddProvider && !['clickpost', 'shiprocket'].includes(eddProvider),
            icon: CustomUrl,
            customize: true,
        },
        {
            key: 'staticEdd',
            title: 'Gokwik Static EDD',

            icon: Gokwik,
            edd: true,
        },
    ]
    const PincodeService = [
        {
            key: 'clickpost_serviceability',
            title: 'Clickpost',
            checked: pincodeProvider === 'clickpost',

            icon: ClickPost,
            customize: true,
        },
        {
            key: 'shiprocket_serviceability',
            title: 'Shiprocket',

            checked: pincodeProvider === 'shiprocket',
            icon: Shiprocket,
            customize: true,
        },
        {
            key: 'delhivery',
            title: 'Delhivery',

            checked: pincodeProvider === 'delhivery',
            icon: Delhivery,
            customize: true,
        },
        {
            key: 'ecom',
            title: 'Ecom Express',

            checked: pincodeProvider === 'ecom',
            icon: Ecom,
            customize: true,
        },
        {
            key: 'farzi',
            title: 'Farzi Engineer',

            checked: pincodeProvider === 'farzi',
            icon: Farzi,
            customize: true,
        },
        {
            key: 'customUrl_serviceability',
            title: 'Custom URL',

            checked:
                !!pincodeProvider &&
                !['clickpost', 'shiprocket', 'delhivery', 'ecom', 'farzi'].includes(pincodeProvider),
            icon: CustomUrl,
            customize: true,
        },
        {
            key: 'pincode',
            title: 'Gokwik Pincode Serviceability',

            icon: Gokwik,
            pincode: true,
        },
    ]
    const togglePincodeServiceablity = async (showMessage: boolean) => {
        const res = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.enablePin,
            payload: {
                enable: true,
                checkout_platform: 'checkout_1',
            },
        })
        if (res.success && showMessage) {
            const capitalizedSection = selectedSection.charAt(0).toUpperCase() + selectedSection.slice(1)
            message.success(`${capitalizedSection} enabled successfully`)
        }
    }
    const handleSave = async () => {
        try {
            if (!form.isFieldsTouched()) {
                setDrawer(false)
                return
            }
            await form.validateFields()
            const { custom_config = {}, pincode_serviceability = {} } = form.getFieldsValue(true)
            const newCreds = pincode_serviceability[selectedSection]
            let payload = { ...(merchantCredentials.pincode_serviceability || {}) }
            if (selectedKey === 'customUrl_edd' || selectedKey === 'customUrl_serviceability') {
                const value = JSON.parse(newCreds)
                payload[selectedSection] = value
            } else {
                payload[selectedSection] = newCreds
            }
            let res
            const credsChanged = !deepEqualAndDiff(merchantCredentials.pincode_serviceability, payload).isEqual
            if (credsChanged) {
                res = await dispatch(
                    saveCreds(
                        {
                            pincode_serviceability: {
                                ...merchantCredentials.pincode_serviceability,
                                ...payload,
                            },
                        },
                        true,
                    ),
                )
            }
            if (res || !credsChanged) {
                let configPayload
                !config.use_pincode_api && (configPayload = { use_pincode_api: true })
                configPayload && (await saveConfig(configPayload))
                await togglePincodeServiceablity(!configPayload)
                setDrawer(false)
            } else {
                message.error('Failed to save')
            }
        } catch (error) {
            console.error('Failed to save:', error)
        }
    }
    useEffect(() => {
        if (merchantCredentials.pincode_serviceability) {
            form.setFieldsValue({
                pincode_serviceability: merchantCredentials.pincode_serviceability,
            })
        }
    }, [merchantCredentials])

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <div className='flex items-center text-lg leading-7'>
                        <p className='text-lg font-semibold'>EDD & Shipping</p>
                    </div>
                </Col>
                <Col span={24}>
                    <h4 className='font-semibold text-base mb-1 mt-0'>EDD</h4>
                    <p className='bg-blue-50 w-full px-4 py-1 rounded-md flex items-center'>
                        <InfoCircleFilled className='text-blue-500 mr-2' />
                        Only one provider can be active at a time.
                    </p>
                </Col>
                {Edd.map((item) => (
                    <Col span={24} key={item.key}>
                        <Card className='custom-card'>
                            <Row align='middle' justify='space-between'>
                                <Col flex='40px'>
                                    <img src={item.icon} alt={item.title} className='h-10 w-10' />
                                </Col>
                                <Col flex='auto' className='pl-4'>
                                    <p className='font-semibold mb-1'>
                                        {item.title}
                                        {item.key === 'staticEdd' && (
                                            <span className='ml-2 px-2 py-1 bg-blue-100 text-blue-500 text-xs font-medium rounded'>
                                                <img src={Sparkle} style={{ width: '15px', height: '15px' }}></img>
                                                Recommended
                                            </span>
                                        )}
                                    </p>
                                </Col>
                                <Col className='text-right'>
                                    {item.edd ? (
                                        <Button
                                            className='mr-4 focus:outline-none'
                                            onClick={() =>
                                                navigate('/checkout/settings/customise-ui#myForm_static_edd_value', {
                                                    state: { activeTab: 'checkout-element' },
                                                })
                                            }
                                        >
                                            <img src={Link} className='w-3 h-3 mr-2' alt='link icon' />
                                            <span>Take me there</span>
                                        </Button>
                                    ) : (
                                        <>
                                            {item.customize === true && item.checked && (
                                                <Button
                                                    className='mr-4 focus:outline-none'
                                                    onClick={() => handleCustomizeClick(item.key, item, 'edd')}
                                                >
                                                    Customise
                                                </Button>
                                            )}
                                            <Switch
                                                className='focus:outline-none'
                                                checked={item.checked}
                                                onChange={(checked) =>
                                                    handleSwitchChange(item.key, checked, item, 'edd')
                                                }
                                            />
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}

                <Col span={24}>
                    <h4 className='font-semibold text-base mb-1 mt-0'>Pincode Serviceability</h4>
                    <p className='bg-blue-50 w-full px-4 py-1 rounded-md flex items-center'>
                        <InfoCircleFilled className='text-blue-500 mr-2' />
                        Only one provider can be active at a time.
                    </p>
                </Col>
                {PincodeService.map((item) => (
                    <Col span={24} key={item.key}>
                        <Card className='custom-card'>
                            <Row align='middle' justify='space-between'>
                                <Col flex='40px'>
                                    <img src={item.icon} alt={item.title} className='h-10 w-10' />
                                </Col>
                                <Col flex='auto' className='pl-4'>
                                    <p className='font-semibold mb-1'>
                                        {item.title}{' '}
                                        {item.key === 'pincode' && (
                                            <span className='ml-2 px-2 py-1 bg-blue-100 text-blue-500 text-xs font-medium rounded'>
                                                <img src={Sparkle} style={{ width: '15px', height: '15px' }}></img>
                                                Recommended
                                            </span>
                                        )}
                                    </p>
                                </Col>
                                <Col className='text-right'>
                                    {item.pincode ? (
                                        <Button
                                            className='mr-4 focus:outline-none'
                                            onClick={() => navigate('/checkout/settings/pincode')}
                                        >
                                            <img src={Link} className='w-3 h-3 mr-2' alt='link icon' />
                                            <span>Take me there</span>
                                        </Button>
                                    ) : (
                                        <>
                                            {item.customize === true && item.checked && (
                                                <Button
                                                    className='mr-4 focus:outline-none'
                                                    onClick={() =>
                                                        handleCustomizeClick(item.key, item, 'serviceability')
                                                    }
                                                >
                                                    Customise
                                                </Button>
                                            )}
                                            <Switch
                                                className='focus:outline-none'
                                                checked={item.checked}
                                                onChange={(checked) =>
                                                    handleSwitchChange(item.key, checked, item, 'serviceability')
                                                }
                                            />
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Drawer
                title={title}
                width={500}
                open={drawer}
                onClose={() => setDrawer(false)}
                footer={
                    <div className='flex justify-end space-x-4'>
                        <Button
                            type='default'
                            onClick={() => {
                                form.setFieldsValue({
                                    pincode_serviceability: merchantCredentials.pincode_serviceability,
                                })
                                setDrawer(false)
                            }}
                        >
                            Discard
                        </Button>
                        <Button type='primary' onClick={handleSave}>
                            Save Changes
                        </Button>
                    </div>
                }
            >
                <RenderForm key={selectedKey} fieldKey={selectedKey} form={form} resetFields={resetFields} />
            </Drawer>
        </>
    )
}

export default EddShipping
