//single or bulk discount card
import React, { FC, useEffect, useState } from 'react'
import { DiscountFormState } from '../helpers/useCreateDiscount'
import { CardLayout } from './cardLayout'
import { Divider, Form, Input, Radio, Space } from 'gokwik-ui-kit'
import useShopifyMerchantConfigApi from '@library/utilities/hooks/useShopifyMerchantConfig'
import { DiscountTypeEnum } from '@library/utilities/types'

interface DiscountCategoryCardProps {
    formState?: DiscountFormState
    discountAction?: any
}

const Card: FC<DiscountCategoryCardProps> = (props) => {
    const { config, loading, refetch, saveConfig } = useShopifyMerchantConfigApi({
        useCache: true,
    })
    const { formState, discountAction } = props
    const [showInput, setShowInput] = useState(false)
    const [error, setError] = useState('')
    const isNonPlusMerchant = config?.isNonPlusMerchant || false

    const handleChange = (e) => {
        if (e?.target?.value === 'bulk') {
            setShowInput(true)
        } else {
            setShowInput(false)
        }
    }

    useEffect(() => {
        if (formState?.discount_category === 'bulk') {
            setShowInput(true)
        }
    }, [formState?.discount_category])

    const validateDiscountCode = (value) => {
        if (!value) {
            setError('Please enter your discount set title')
        } else if (/\s/.test(value)) {
            setError('Spaces are not allowed')
        } else if (!/^[A-Za-z0-9]+$/.test(value)) {
            setError('Only alphabets A-Z, a-z, and numbers 0-9 are allowed')
        } else {
            setError('')
        }
    }

    const transformInput = (value) => {
        // Capitalize the first letter
        return value.replace(/\b\w/g, (char) => char.toUpperCase())
    }

    const handleInputChange = (e) => {
        const { value } = e.target
        const transformedValue = transformInput(value)
        validateDiscountCode(transformedValue)
    }

    return (
        <CardLayout
            title='Should this be a single discount or a bulk discount set?'
            subtitle='Decide whether to create a single discount or create multiple discounts simultaneously'
        >
            <Divider className='m-1' />

            <Form.Item name='discount_category'>
                <Radio.Group onChange={handleChange} defaultValue={'single'} disabled={discountAction}>
                    <Space direction='vertical'>
                        <Radio value={'single'}>Single discount creation</Radio>
                        {!isNonPlusMerchant && formState?.discount_type !== DiscountTypeEnum.gift_with_product && (
                            <Radio value={'bulk'}>Bulk discount creation</Radio>
                        )}
                    </Space>
                </Radio.Group>
            </Form.Item>

            {showInput && (
                <Form.Item
                    name='code'
                    rules={
                        showInput && [
                            {
                                pattern: /^[A-Z0-9]+$/,
                                message: 'Please enter only capital letters (A-Z) and numbers (0-9).',
                            },
                            {
                                required: true,
                                message: 'Please enter a title for the discount set',
                            },
                            {
                                max: 30,
                                message: 'Title should not exceed 30 characters',
                            },
                        ]
                    }
                >
                    <Input
                        disabled={discountAction}
                        placeholder='Enter your discount set title here'
                        onChange={handleInputChange}
                        maxLength={30}
                    />
                </Form.Item>
            )}
        </CardLayout>
    )
}

export const DiscountCategoryCard = React.memo(Card)
