import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '../constants/apiEndpoints'
import { UtmListParams } from '../interface'

export const fetchUtmList = async (params: UtmListParams) => {
    try {
        let response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.getUtmList,
            params: {
                page: 1,
                sortOrder: -1,
                pageSize: 25,
                ...params,
            },
            skipLoader: params.table !== 'cart',
        })
        if (response.success) return response.data.data
    } catch (err) {
        console.log(err)
    }
}
