import { createAsyncThunk, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, RootStoreType } from '../index'
import { analyticsAPIs, fetchAnalyticsData } from './api'

export interface AnalyticsSlice {
    [key: string]: {
        [key: string]: any
        status?: 'idle' | 'loading' | 'failed'
    }
}

const assignToState = ({
    state,
    key,
    nestedKeys,
    payload,
    status,
}: {
    state: AnalyticsSlice
    status: 'idle' | 'loading' | 'failed'
    key: string
    nestedKeys: string[]
    payload: any
}) => {
    if (nestedKeys && nestedKeys.length > 0) {
        nestedKeys.forEach((nestedKey) => {
            state[key][nestedKey].status = status
            if (payload && payload.data) state[key][nestedKey].data = payload.data[nestedKey]
        })
    } else {
        state[key].status = status
        state[key].data = payload.data
    }
    return state
}

const initialState: AnalyticsSlice = {
    realtimeStats: {
        data: null,
        status: 'idle',
    },
    funnel: {
        data: null,
        status: 'idle',
    },
    funnelTrendMetrics: {
        data: null,
        status: 'idle',
    },
    vdOrderMetrics: {
        total_orders: {
            data: null,
            status: 'idle',
        },
        total_sales: {
            data: null,
            status: 'idle',
        },
        prepaid_percentage: {
            data: null,
            status: 'idle',
        },
        aov: {
            data: null,
            status: 'idle',
        },
    },
    vdFunnelMetrics: {
        address_prefilled_percentage: {
            data: null,
            status: 'idle',
        },
        checkout_conversion_percentage: {
            data: null,
            status: 'idle',
        },
        sso_percentage: {
            data: null,
            status: 'idle',
        },
    },
    vdCodShare: {
        cod_share: {
            data: null,
            status: 'idle',
        },
    },
    vdCodRTORate: {
        cod_rto_rate: {
            data: null,
            status: 'idle',
        },
    },
    orderMetrics: {
        averageOrderStats: {
            data: null,
            status: 'idle',
        },
        orderStats: {
            data: null,
            status: 'idle',
        },
        saleStats: {
            data: null,
            status: 'idle',
        },
    },
    marketingMetrics: {
        data: null,
        status: 'idle',
    },
    paymentMetrics: {
        data: null,
        status: 'idle',
    },
    topProducts: {
        data: null,
        status: 'idle',
    },
    discountUsedMetrics: {
        data: null,
        status: 'idle',
    },
    gmvSplitMetrics: {
        data: null,
        status: 'idle',
    },
    discountUsageTrendMetrics: {
        data: null,
        status: 'idle',
    },
    discountFailedMetrics: {
        data: null,
        status: 'idle',
    },
    addressPrefillTrendMetrics: {
        data: null,
        status: 'idle',
    },
}

export const fetchAnalyticsDataAsync = createAsyncThunk(
    'analytics/fetchAnalytics',
    async (
        { key, params }: { key: string; nestedKey?: string; params?: { [key: string]: any } },
        { rejectWithValue },
    ) => {
        const response = await fetchAnalyticsData({ key, params })
        if (response.success) return response
        else return rejectWithValue(response)
    },
)

export const fetchAllAnalyticsData =
    ({ params, ignoreKeys }: { params: { [key: string]: any }; ignoreKeys?: string[] }) =>
    async (dispatch: AppDispatch) => {
        Object.keys(analyticsAPIs).forEach((key) => {
            !ignoreKeys.includes(key) && dispatch(fetchAnalyticsDataAsync({ key, params }))
        })
    }

export const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAnalyticsDataAsync.pending, (state, action) => {
                const nestedKeys = action.meta.arg.nestedKey
                    ? [action.meta.arg.nestedKey]
                    : analyticsAPIs[action.meta.arg.key].nestedKeys || []
                assignToState({ state, key: action.meta.arg.key, nestedKeys, payload: {}, status: 'loading' })
            })
            .addCase(fetchAnalyticsDataAsync.fulfilled, (state, action) => {
                const nestedKeys = action.meta.arg.nestedKey
                    ? [action.meta.arg.nestedKey]
                    : analyticsAPIs[action.meta.arg.key].nestedKeys || []
                assignToState({ state, key: action.meta.arg.key, nestedKeys, payload: action.payload, status: 'idle' })
            })
            .addCase(fetchAnalyticsDataAsync.rejected, (state, action) => {
                const nestedKeys = action.meta.arg.nestedKey
                    ? [action.meta.arg.nestedKey]
                    : analyticsAPIs[action.meta.arg.key].nestedKeys || []
                assignToState({ state, key: action.meta.arg.key, nestedKeys, payload: {}, status: 'failed' })
            })
    },
})

export default analyticsSlice.reducer
