// .list-item-class {
//     cursor: grab;
//     transition: all 0.2s ease-in-out;
// }

// .next-position {
//     padding: 20px;
//     opacity: 0;
//     z-index: 5000;
//     transform: scale(0.8);
//     transition: all 0.2s ease;
// }

import {
    Button,
    Col,
    Divider,
    Modal,
    Row,
    Spin,
    Tag,
    Tooltip,
    message,
    DeleteOutlined,
    EditOutlined,
    InfoCircleOutlined,
    PauseOutlined,
    EyeOutlined,
    LoadingOutlined,
} from 'gokwik-ui-kit'
import React, { useEffect, useState } from 'react'
import AddShippingRateForm from './addOrEditShippingRateForm'
import { logUserEvent, makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { NoDataIcon } from '@library/images/noDataIcon'
const index = () => {
    const [configJSON, setConfigJSON] = useState(null)
    const [listItems, setListItems] = useState([])
    const [dragItemIndex, setDragItemIndex] = useState()
    const [draggedListItem, setDraggedListItem] = useState(null)
    const [showTooltip, setShowTooltip] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
    const [ongoingDrag, setOngoingDrag] = useState(false)
    const [action, setAction] = useState({ name: null, id: null })
    const [modalTitle, setModalTitle] = useState('')
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowTooltip(false)
        }, 5000)

        return () => clearTimeout(timer)
    }, [])

    const handleDragStart = (e, index) => {
        setTimeout(() => {
            setDraggedListItem(index)
        })
        e.dataTransfer.setData('index', index)
        setOngoingDrag(true)
    }

    const handleDragOver = (e, index) => {
        e.preventDefault()
        if (draggedListItem !== index) {
            const newData = [...listItems]
            const [removedItem] = newData.splice(draggedListItem, 1)
            newData.splice(index, 0, removedItem)
            setListItems(newData)
            setDraggedListItem(index)
        }
    }

    const handleDragEnd = (e) => {
        setDraggedListItem(null)
        setOngoingDrag(false)
    }

    const handleDrop = (e) => {
        setDraggedListItem(null)
        setOngoingDrag(false)
    }

    const getShippingConfig = async () => {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.merchantConfigs,
        })

        if (response?.data?.status_code === 200) {
            setConfigJSON(response?.data?.data)
            setListItems(response?.data?.data?.shipping_prices)
        }
    }

    useEffect(() => {
        getShippingConfig()
    }, [])

    const deleteShippingMethod = async (shippingMethodId) => {
        const temp = { ...configJSON }
        const indexToRemove = temp.shipping_prices.findIndex((item) => item.id === shippingMethodId)
        if (indexToRemove !== -1) {
            temp?.shipping_prices?.splice(indexToRemove, 1)
        }

        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.merchantConfigs,
                payload: temp,
            })

            if (response.data.status_code === 200) {
                setShowDeleteConfirmationModal(false)
                message.success('Shipping method deleted successfully')
                setConfigJSON({ ...temp })
            }
        } catch (error) {
            setShowDeleteConfirmationModal(false)
            console.error('Error:', error)
            message.error('Failed to delete shipping method')
        }
    }

    useEffect(() => {
        if (action?.name) {
            if (action?.name && action?.name !== 'delete') {
                if (action?.name === 'edit') {
                    setModalTitle(
                        `Edit ${
                            configJSON?.shipping_prices.find((item) => item.id === action?.id).presentment_name
                        } shipping method`,
                    )
                } else if (action?.name === 'view') {
                    setModalTitle(
                        `${
                            configJSON?.shipping_prices.find((item) => item.id === action?.id).presentment_name
                        } shipping method`,
                    )
                } else {
                    setModalTitle('Add new shipping method')
                }
                setShowModal(true)
            } else {
                setShowDeleteConfirmationModal(true)
            }
        }
    }, [action])

    const handleActionButtonClick = (actionName, shippingMethodId) => {
        setAction({ name: actionName, id: shippingMethodId })
    }

    return (
        <div className='w-full  p-2 flex flex-col gap-4'>
            {showDeleteConfirmationModal && (
                <Modal
                    title={`Delete ${
                        configJSON?.shipping_prices?.find((item) => item?.id === action?.id)?.presentment_name
                    } shipping method ?`}
                    onCancel={() => setShowDeleteConfirmationModal(false)}
                    open={showDeleteConfirmationModal ? true : false}
                    onOk={() => deleteShippingMethod(action?.id)}
                    centered
                    width={'450px'}
                >
                    Do you really want to delete{' '}
                    {configJSON?.shipping_prices?.find((item) => item?.id === action?.id)?.presentment_name} shipping
                    method ?
                </Modal>
            )}
            {showModal && (
                <Modal
                    title={modalTitle}
                    footer={null}
                    onCancel={() => setShowModal(false)}
                    open={showModal ? true : false}
                    centered
                    width={'450px'}
                >
                    <AddShippingRateForm
                        setShowModal={setShowModal}
                        configJSON={configJSON}
                        setConfigJSON={setConfigJSON}
                        action={action}
                    />
                </Modal>
            )}
            <Row className='bg-white rounded-md p-2' justify={'space-between'} align={'middle'} wrap={true}>
                <Col>
                    <div className='flex flex-col justify-between'>
                        <span className=' text-[#000000E0] font-semibold text-lg pb-1'>Shipping Methods</span>
                        <span className=' text-[#00000080] font-normal text-sm pt-1'>
                            Choose where you ship and how much you charge for shipping at checkout
                        </span>
                    </div>
                </Col>
                <Col>
                    {ongoingDrag ? (
                        <div className='w-full flex gap-4'>
                            <Button
                                variant='default'
                                onClick={() => {
                                    // setListItems()
                                    setOngoingDrag(false)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button variant='primary' onClick={() => {}}>
                                Save Sequence
                            </Button>
                        </div>
                    ) : (
                        <Button variant='primary' onClick={() => handleActionButtonClick('add', null)}>
                            + &nbsp; Add Shipping Method
                        </Button>
                    )}
                </Col>
            </Row>
            {listItems && listItems?.length > 0 ? (
                <Row gutter={24} justify={'space-between'}>
                    <Col className=' rounded-lg' span={18}>
                        <>
                            {listItems?.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`my-2 border border-solid border-[#00000026] bg-[#FFF] rounded-lg pt-3 pb-4 px-4 transition-all duration-200 ease-in-out ${
                                            draggedListItem === index
                                                ? 'list-item-class next-position'
                                                : 'list-item-class'
                                        }`}
                                        style={
                                            draggedListItem === index
                                                ? {
                                                      cursor: 'grab',
                                                      transition: 'all 0.2s ease-in-out',
                                                      padding: '20px',
                                                      opacity: '0',
                                                      zIndex: '5000',
                                                      transform: 'scale(0.8)',
                                                  }
                                                : { transition: 'all 0.2s ease-in-out' }
                                        }
                                        // draggable
                                        onDragStart={(e) => handleDragStart(e, index)}
                                        onDragOver={(e) => handleDragOver(e, index)}
                                        onDragEnd={(e) => handleDragEnd(e)}
                                        onDrop={(e) => handleDrop(e)}
                                    >
                                        <Row align={'middle'}>
                                            <Col span={14}>
                                                <div className='flex flex-wrap flex-col'>
                                                    <div className=' text-lg font-semibold'>
                                                        {item?.presentment_name}
                                                    </div>
                                                    <div className='text-[#000] flex gap-1 flex-wrap items-center text-sm font-normal opacity-60'>
                                                        <span>Price: {item?.price}</span>
                                                        <Divider type='vertical' />
                                                        <span>Min order value: {item?.min}</span>
                                                        <Divider type='vertical' />

                                                        <span>Max order value: {item?.max}</span>
                                                        <Divider type='vertical' />

                                                        {/* <span>{item?.pins} pins</span> */}
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col span={10}>
                                                <div className='w-full flex flex-wrap justify-end gap-2 items-center'>
                                                    <div className='flex flex-grow items-center justify-end gap-2'>
                                                        {item?.payment_options === 'all' ? (
                                                            <>
                                                                <Tag
                                                                    variant={'default'}
                                                                    className='border border-solid border-[#1677FF] rounded flex items-center'
                                                                >
                                                                    <span className='text-[#1677FF]'>COD</span>
                                                                </Tag>
                                                                <Tag
                                                                    variant={'default'}
                                                                    className='border border-solid border-[#1677FF] rounded flex items-center'
                                                                >
                                                                    <span className='text-[#1677FF]'>Prepaid</span>
                                                                </Tag>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Tag
                                                                    variant={'default'}
                                                                    className='border border-solid border-[#1677FF] rounded flex items-center'
                                                                >
                                                                    <span className='text-[#1677FF]'>
                                                                        {item?.payment_options.toUpperCase()}
                                                                    </span>
                                                                </Tag>
                                                            </>
                                                        )}
                                                    </div>

                                                    <div className=' border opacity-60 border-solid border-gray-300 rounded-md p-1 cursor-pointer'>
                                                        <Tooltip title={'View'}>
                                                            <EyeOutlined
                                                                onClick={() => handleActionButtonClick('view', item.id)}
                                                            />
                                                        </Tooltip>
                                                        <Divider type='vertical' />

                                                        <Tooltip title={'Edit'}>
                                                            <EditOutlined
                                                                onClick={() => handleActionButtonClick('edit', item.id)}
                                                            />
                                                        </Tooltip>
                                                        <Divider type='vertical' />
                                                        <Tooltip title={'Delete'}>
                                                            <DeleteOutlined
                                                                onClick={() =>
                                                                    handleActionButtonClick('delete', item.id)
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </div>

                                                    {/* <div className='dummy-drag cursor-grab'>
                                                    <Tooltip
                                                        title='Drag and drop will reorder your shipping'
                                                        placement='bottom'
                                                    >
                                                        <PauseOutlined className='rotate-90' />
                                                    </Tooltip>
                                                </div> */}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })}
                        </>
                    </Col>
                    <Col span={6}>
                        <div>
                            <div className='flex flex-col gap-3'>
                                <div>Address</div>
                                <div
                                    style={{ boxShadow: 'box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02)' }}
                                    className=' pl-2 pr-3 py-2 border border-solid border-[#004B8D26] bg-[#004B8D0D] rounded-md'
                                ></div>
                                <div
                                    style={{ boxShadow: 'box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02)' }}
                                    className=' pl-2 pr-3 py-2 border border-solid border-[#004B8D26] bg-[#004B8D0D] rounded-md'
                                ></div>
                            </div>
                            <Divider />
                            <div className='flex flex-col gap-3'>
                                <div>Shipping Methods</div>
                                {ongoingDrag ? (
                                    // <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                                    'loading...'
                                ) : (
                                    <div className='flex flex-col gap-2 max-h-60 overflow-y-auto'>
                                        {listItems?.map((item) => {
                                            return (
                                                <div
                                                    key={item.id}
                                                    className='flex justify-between border border-solid border-[#00000026] bg-white rounded-md p-3'
                                                >
                                                    <span className=' text-sm font-normal'>
                                                        {item.presentment_name}&nbsp;({item.payment_options})
                                                    </span>{' '}
                                                    <span className=' text-sm font-semibold'>₹ {item.price}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                            <Divider />
                            <div className='flex flex-col gap-3'>
                                <div>Payment</div>
                                <div
                                    style={{ boxShadow: 'box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02)' }}
                                    className=' pl-2 pr-3 py-2 border border-solid border-[#004B8D26] bg-[#004B8D0D] rounded-md'
                                ></div>
                                <div
                                    style={{ boxShadow: 'box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02)' }}
                                    className=' pl-2 pr-3 py-2 border border-solid border-[#004B8D26] bg-[#004B8D0D] rounded-md'
                                ></div>
                            </div>
                        </div>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col span={24}>
                        <div className='p-20 text-center flex flex-col items-center align-middle justify-center'>
                            <NoDataIcon />
                            <h3>No Shipping method added.</h3>
                        </div>
                    </Col>
                </Row>
            )}
        </div>
    )
}

export default index
