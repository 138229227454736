import { ColumnsType, Tooltip } from 'gokwik-ui-kit'
import dayjs from 'dayjs'
import { addBreadcrumb } from '@gokwik/utilities'
import { Link } from 'react-router-dom'
import { formatAmount } from '@library/utilities/helpers/helper'

const renderAmount = (val) => <span className='font-medium'>{formatAmount(+(val || 0), 2)}</span>

export const abcDetailsItemsColumn: ColumnsType<any> = [
    {
        title: 'Product ID',
        dataIndex: 'product_id',
        width: 150,
        ellipsis: {
            showTitle: false,
        },
        fixed: 'left',
    },
    {
        title: 'SKU',
        dataIndex: 'sku',
        width: 150,
    },
    {
        title: 'Variant Title',
        dataIndex: 'variant_title',
        width: 150,
    },
    {
        title: 'Title',
        dataIndex: 'title',
        width: 150,
        ellipsis: true,
        render:(t) =>  <Tooltip title={t}><span>{t}</span></Tooltip>
    },
    {
        title: 'Product Type',
        dataIndex: 'product_type',
        width: 150,
    },
    {
        title: 'Product Name',
        dataIndex: 'p_name',
        width: 150,
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        width: 150,
    },

    {
        title: 'Final Line Price',
        dataIndex: 'final_line_price',
        width: 150,
        render: (r) => <p>{renderAmount(r)}</p>,
    },

    {
        title: 'Total Discount',
        dataIndex: 'total_discount',
        width: 150,
        render: (r) => <p>{renderAmount(r)}</p>,
    },
    {
        title: 'Final Price',
        dataIndex: 'final_price',
        width: 150,
        render: (r) => <p>{renderAmount(r)}</p>,
    },

    {
        title: 'Order Status',
        dataIndex: 'order_status',
        width: 150,
    },
]
