import React, { useEffect, useState } from 'react'
import { Button, Form, Card, Switch, Input, Divider } from 'gokwik-ui-kit'
import RichTextEditor from '@library/components/richtexteditor'
import ColorPicketComponent from '@library/components/colorpicker'
import BookDownload from '@library/images/book-download.svg'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { deepEqualAndDiff } from '@gokwik/utilities'

const initialValues = {
    primary_button_text: 'Continue',
    consent_text: 'Consent Section',
}

const Login = ({ onChange }) => {
    const [form] = Form.useForm()
    const [visibility, setVisibility] = useState({
        isBannerVisible: true,
        isBannerEnabled: false,
        isConsentVisible: false,
        isContinueButtonVisible: false,
    })
    const { config, loading, refetch, saveConfig } = useMerchantConfigApi({
        useCache: true,
    })
    const [ischanged, setIsChanged] = useState(false)
    const [loginData, setLoginData] = useState<any>({
        banner_content: '',
        background_color: '#000000',
        text_color: '#FFFFFF',
        primary_button_text: '',
        consent_text: '',
    })

    useEffect(() => {
        onChange(loginData)
    }, [loginData])

    useEffect(() => {
        if (config && config?.custom_config?.login_data) {
            setLoginData((prev) => ({ ...prev, ...(config.custom_config.login_data || loginData) }))
            form.setFieldValue('background_color', config.custom_config.login_data?.background_color)
            form.setFieldValue('text_color', config.custom_config.login_data?.text_color)
            config?.custom_config?.login_data?.isContinueButtonVisible
                ? setVisibility((prev) => ({ ...prev, isContinueButtonVisible: true }))
                : setVisibility((prev) => ({ ...prev, isContinueButtonVisible: false }))
            config?.custom_config?.login_data?.isBannerEnabled
                ? setVisibility((prev) => ({ ...prev, isBannerEnabled: true, isBannerVisible: true }))
                : setVisibility((prev) => ({ ...prev, isBannerEnabled: false, isBannerVisible: false }))
            config?.custom_config?.login_data?.isConsentVisible
                ? setVisibility((prev) => ({ ...prev, isConsentVisible: true }))
                : setVisibility((prev) => ({ ...prev, isConsentVisible: false }))
        }
    }, [config])

    const handleVisibilityChange = (key: string, value: boolean) => {
        setVisibility((prev) => ({ ...prev, [key]: value }))
        setLoginData((prev) => ({ ...prev, [key]: value }))
        setIsChanged(true)
    }

    const handleRichTextChange = (field: string) => (content: JSON) => {
        form.setFieldValue(field, content || {})
        setLoginData((prev) => ({ ...prev, [field]: content }))
        setVisibility((prev) => ({ ...prev, isBannerVisible: false }))
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldValue('primary_button_text', e.target.value)
        setVisibility((prev) => ({ ...prev, isContinueButtonVisible: true, isBannerVisible: false }))
        setLoginData((prev: any) => ({ ...prev, primary_button_text: e.target.value }))
        setIsChanged(true)
    }

    const handleFormSubmit = () => {
        try {
            const configPayload = deepEqualAndDiff(config, {
                custom_config: {
                    ...config?.custom_config,
                    login_data: { ...loginData },
                },
            })
            if (configPayload.isEqual) {
                setIsChanged(false)
                return
            }
            saveConfig(configPayload.diff)
            setIsChanged(false)
        } catch (err) {
            console.log(err)
        }
    }

    const renderFormItem = (
        label: string,
        subLabel: string,
        switchKey: string,
        editorKey: string,
        repsonseText: string,
        text: boolean,
        colorPicker: boolean,
        editorChangeHandler: (content: JSON) => void,
        description,
    ) => (
        <Form.Item name={editorKey} className='m-0' label={<span className='text-base font-medium'>{label}</span>}>
            <div className='flex w-full flex-row justify-between gap-x-2 pb-2'>
                <span className='font-md text-sm font-normal'>{subLabel}</span>
                <Switch checked={text} onChange={(checked) => handleVisibilityChange(switchKey, checked)} />
            </div>
            <p className='text-sm text-gray-500'>{description}</p>
            {visibility[switchKey] && (
                <span>
                    <RichTextEditor
                        onChange={(content) => {
                            editorChangeHandler(content)
                            setIsChanged(true)
                        }}
                        selectedValue={repsonseText}
                    />
                    {colorPicker && (
                        <>
                            <Divider />
                            <ColorPicketComponent
                                label={''}
                                primary={'background'}
                                secondary={'text'}
                                defaultValues={{
                                    firstColor: loginData?.background_color,
                                    secondColor: loginData?.text_color,
                                }}
                            />
                        </>
                    )}
                </span>
            )}
        </Form.Item>
    )

    return (
        <Form
            form={form}
            layout='vertical'
            onFinish={handleFormSubmit}
            initialValues={initialValues}
            className='gap-5 flex flex-col'
            onValuesChange={(value) => {
                setLoginData({ ...loginData, ...value })
                setIsChanged(true)
            }}
        >
            <div className='flex justify-between'>
                <div className='m-0'>
                    <span className='text-lg font-semibold'>Login</span>
                    <p className='text-gray-500 gap-0'>
                        Control login step UI on checkout - add banners, control CTA text and more.
                    </p>
                </div>
                <div className='m-0'>
                    <Button type='primary' htmlType='submit' disabled={!ischanged}>
                        Save Changes
                    </Button>
                </div>
            </div>
            <Card size='small'>
                {renderFormItem(
                    'Banner',
                    'Display Banner',
                    'isBannerEnabled',
                    'banner_content',
                    loginData?.banner_content?.actualText,
                    visibility.isBannerEnabled,
                    true,
                    handleRichTextChange('banner_content'),
                    'Enable this to display an announcement banner to the customers on login step of checkout',
                )}
            </Card>
            <Card size='small'>
                <Form.Item
                    name='primary_button_text'
                    className='p-0 m-0'
                    label={<span className='text-base font-medium'>Primary Button</span>}
                >
                    <div className='flex w-full flex-row justify-between gap-x-2 pb-2'>
                        <span className='font-md text-sm font-normal'>CTA Text</span>
                        <Switch
                            checked={visibility.isContinueButtonVisible}
                            onChange={(checked) => handleVisibilityChange('isContinueButtonVisible', checked)}
                        />
                    </div>
                    <p className='text-sm text-gray-500'>Control the text to shown on main CTA button on login step.</p>
                    {visibility.isContinueButtonVisible && (
                        <Input
                            name='primary_button_text'
                            count={{ show: true, max: 15 }}
                            maxLength={15}
                            onChange={handleInputChange}
                            defaultValue={loginData?.primary_button_text}
                        />
                    )}
                </Form.Item>
            </Card>
            <Card size='small'>
                {renderFormItem(
                    'Consent Section',
                    'Consent Section Text',
                    'isConsentVisible',
                    loginData?.consent_text,
                    loginData?.consent_text?.actualText,
                    visibility.isConsentVisible,
                    false,
                    handleRichTextChange('consent_text'),
                    'Control the text to display to the users on the consent section box on login step',
                )}
            </Card>
        </Form>
    )
}

export default Login
