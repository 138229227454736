import { HolderOutlined } from 'gokwik-ui-kit'
import React, { useState } from 'react'

interface Props {
    labelKey: string
    items: any[]
    listItemClassName?: HTMLElement['className']
    setListOrder: (newOrder: any) => void
}

const DraggableList = ({ items, listItemClassName, setListOrder, labelKey }: Props) => {
    const [listItems, setListItems] = useState(items)
    const [draggedListItem, setDraggedListItem] = useState(null)

    const handleDragStart = (e, index) => {
        setTimeout(() => {
            setDraggedListItem(index)
        })
        e.dataTransfer.setData('index', index)
    }

    const handleDragOver = (e, index) => {
        e.preventDefault()
        if (draggedListItem !== index) {
            const newData = [...listItems]
            const [removedItem] = newData.splice(draggedListItem, 1)

            newData.splice(index, 0, removedItem)

            setListItems(newData)
            setDraggedListItem(index)
            setListOrder(newData)
        }
    }

    const handleDragEnd = (e) => {
        setDraggedListItem(null)
    }

    const handleDrop = (e) => {
        setDraggedListItem(null)
    }

    return (
        <ul className={'p-0 m-0 list-none flex flex-col gap-y-4 w-full'}>
            {listItems.map((item, index) => (
                <li
                    key={item.id} // Use 'id' as the key
                    className={`border border-solid p-4 w-full border-gray-300 rounded cursor-grab ${listItemClassName} ${
                        draggedListItem === index && 'opacity-0'
                    }`}
                    draggable
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragOver={(e) => handleDragOver(e, index)}
                    onDragEnd={(e) => handleDragEnd(e)}
                    onDrop={(e) => handleDrop(e)}
                >
                    <HolderOutlined />
                    <span className='ml-4 sortable-item'>{item[labelKey]}</span>
                </li>
            ))}
        </ul>
    )
}

export default DraggableList
