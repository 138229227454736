import { useCallback, useEffect, useRef } from 'react'

export const useInterval = (callback: () => void, time: string | number) => {
    const interval = useRef(null)
    const initiateInterval = useCallback(() => {
        removeInterval()
        interval.current = setInterval(() => {
            console.log({ time })

            callback?.()
        }, +time)
    }, [callback, time])
    const removeInterval = useCallback(() => {
        if (interval.current) clearInterval(interval.current)
    }, [interval.current])

    useEffect(() => {
        return () => removeInterval()
    }, [])

    return [interval.current, initiateInterval, removeInterval]
}
