import React, { FC, useMemo, useState } from 'react'
import { CardLayout } from './cardLayout'
import {
    Button,
    ColumnProps,
    DeleteOutlined,
    EditOutlined,
    Input,
    InputNumber,
    Modal,
    Row,
    Select,
    Table,
    Tag,
    Tooltip,
    message,
} from 'gokwik-ui-kit'
import { DiscountFormState } from '../helpers/useCreateDiscount'

interface DynamicDiscountCardProps {
    value?: Rule[]
    onChange?: (rules: Rule[]) => void
    finalFormState?: DiscountFormState
    discountAction?: any
}

interface Condition {
    discount_type: string
    min_cart_value: number | null
    max_cart_value: number | null
}

interface Action {
    discount_type: string
    applicability_type: string
    value: number | null
}

interface Rule {
    rule_id: string
    priority: number
    conditions: Condition[]
    actions: Action[]
}

interface ModalState {
    isOpen: boolean
    formState?: Rule
    isEdit?: boolean
}

const Card: FC<DynamicDiscountCardProps> = (props) => {
    const { finalFormState, discountAction } = props
    const [modalState, setModalState] = useState<ModalState>({
        isOpen: false,
    })
    const [errorMessage, setErrorMessage] = useState('')

    const columns: ColumnProps<any>[] = [
        {
            title: <span>Fixed or %</span>,
            dataIndex: ['actions', 0, 'applicability_type'],
            width: 25,
            render: (text: string, record) => {
                return <>{text === 'cart_fixed_discount' ? 'Fixed' : '%'}</>
            },
        },

        {
            title: <span>Value</span>,
            dataIndex: ['actions', 0, 'value'],
            width: 20,
            render: (text: string) => <>{text}</>,
        },
        {
            title: <span>Min Order Value</span>,
            dataIndex: ['conditions', 0, 'min_cart_value'],
            width: 30,
            render: (text: string) => <>{text}</>,
        },
        {
            title: <span>Max Order Value</span>,
            dataIndex: ['conditions', 0, 'max_cart_value'],
            width: 30,
            render: (text: string) => <>{text}</>,
        },
        {
            title: <span>Actions</span>,
            dataIndex: 'actions',
            ellipsis: true,
            align: 'center',
            fixed: 'right',
            width: 20,
            render: (_, record, index) => (
                <Row className='flex gap-3 m-0' justify={'center'}>
                    {discountAction !== 'view' && (
                        <>
                            <Tooltip className=' cursor-pointer' placement='topLeft' title={'Edit'}>
                                <EditOutlined
                                    onClick={() => {
                                        handleModalState({ isOpen: true, isEdit: true, formState: { ...record, priority: index } })
                                    }}
                                />
                            </Tooltip>

                            <Tooltip className=' cursor-pointer' placement='topLeft' title={'Delete'}>
                                <DeleteOutlined
                                    onClick={() => {
                                        const updatedData = [...props.value]
                                        updatedData.splice(index, 1)
                                        props?.onChange([...updatedData])
                                    }}
                                />
                            </Tooltip>
                        </>
                    )}
                </Row>
            ),
        },
    ]

    const handleModalState = (state?: ModalState) => {
        setModalState({ ...state })
    }

    const onValuesChange = (formState: Rule) => {
        setErrorMessage('')
        setModalState({ ...modalState, formState: formState })
    }

    const getInputConfigs = useMemo(() => {
        const isFixedDiscount = Boolean(
            finalFormState?.rules?.some((r) =>
                r?.actions?.some((a) => a?.applicability_type === 'cart_fixed_discount'),
            ),
        )

        const isPercentageDiscount = Boolean(
            finalFormState?.rules?.some((r) =>
                r?.actions?.some((a) => a?.applicability_type === 'cart_percentage_discount'),
            ),
        )
        if (isFixedDiscount) {
            return {
                options: [{ label: 'Fixed', value: 'cart_fixed_discount' }],
                defaultValue: 'cart_fixed_discount',
                isDisabled: true,
            }
        } else if (isPercentageDiscount) {
            return {
                options: [{ label: 'Percentage', value: 'cart_percentage_discount' }],
                defaultValue: 'cart_percentage_discount',
                isDisabled: true,
            }
        } else {
            return {
                options: [
                    { label: 'Fixed', value: 'cart_fixed_discount' },
                    { label: 'Percentage', value: 'cart_percentage_discount' },
                ],
                defaultValue: 'cart_fixed_discount',
                isDisabled: false,
            }
        }
    }, [modalState, finalFormState])

    return (
        <>
            <Modal
                title={modalState?.isEdit ? 'Edit condition' : 'Add new condition'}
                width={555}
                cancelText={'Cancel'}
                okText={modalState?.isEdit ? 'Edit' : 'Add'}
                centered
                open={modalState?.isOpen}
                onOk={() => {
                    const { formState } = modalState

                    if (
                        formState?.actions[0]?.applicability_type === 'cart_percentage_discount' &&
                        formState.actions[0]?.value > 100
                    ) {
                        setErrorMessage('Error: Percentage cannot be greater than 100')
                        return
                    }
                    if (formState) {
                        const minValue = formState.actions[0]?.value
                        const minCartValue = formState.conditions[0]?.min_cart_value
                        const maxCartValue = formState.conditions[0]?.max_cart_value
                        const priority = formState?.priority

                        const previousMinCartValue = finalFormState?.rules[priority - 1]?.conditions[0]?.min_cart_value
                        const previousMaxCartValue = finalFormState?.rules[priority - 1]?.conditions[0]?.max_cart_value

                        const nextMinCartValue = finalFormState?.rules[priority + 1]?.conditions[0]?.min_cart_value
                        const nextMaxCartValue = finalFormState?.rules[priority + 1]?.conditions[0]?.max_cart_value

                        const rangesOverlap = ([x1, x2], [y1, y2]) => x1 <= y2 && y1 <= x2

                        if (previousMinCartValue && previousMaxCartValue) {
                            if (
                                rangesOverlap(
                                    [previousMinCartValue, previousMaxCartValue],
                                    [minCartValue, maxCartValue],
                                )
                            ) {
                                setErrorMessage('Error: Min and Max values cannot overlap the previous range.')
                                return
                            }
                        }

                        if (nextMinCartValue && nextMaxCartValue) {
                            if (rangesOverlap([minCartValue, maxCartValue], [nextMinCartValue, nextMaxCartValue])) {
                                setErrorMessage('Error: Min and Max values cannot overlap the next range.')
                                return
                            }
                        }

                        if (!minValue) {
                            setErrorMessage('Error: Value is required.')
                            return
                        }

                        if (!minCartValue) {
                            setErrorMessage('Error: Min order value is required.')
                            return
                        }

                        if (
                            formState?.actions[0]?.applicability_type !== 'cart_percentage_discount' &&
                            minCartValue <= minValue
                        ) {
                            // Minimum order value should be greater than discount value
                            setErrorMessage('Error: Minimum order value should be greater than the discount value.')
                            return
                        }
                        if (maxCartValue <= minCartValue) {
                            // Maximum order value should be greater than the minimum order value
                            setErrorMessage(
                                'Error: Maximum order value should be greater than the minimum order value.',
                            )
                            return
                        }

                        if (modalState?.isEdit) {
                            const updatedData = props?.value?.map((v) =>
                                v?.rule_id === formState?.rule_id ? { ...v, ...formState } : { ...v },
                            )
                            props?.onChange([...updatedData])
                        } else {
                            props?.onChange([...props?.value, formState])
                        }

                        setErrorMessage('')
                        handleModalState({ isOpen: false })
                    }
                }}
                onCancel={() => {
                    setErrorMessage('')
                    handleModalState({ isOpen: false })
                }}
            >
                {errorMessage && (
                    <span className='flex justify-center'>
                        <Tag
                            variant={'error'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            {errorMessage}
                        </Tag>
                    </span>
                )}
                <Select
                    disabled={getInputConfigs?.isDisabled}
                    className='w-full'
                    label='Choose discount validation: Percentage or Fixed?'
                    options={getInputConfigs.options}
                    defaultValue={getInputConfigs.defaultValue}
                    // value={modalState?.formState?.actions[0]?.applicability_type ?? undefined}
                    onChange={(value) =>
                        onValuesChange({
                            ...modalState?.formState,
                            actions: [
                                {
                                    ...modalState?.formState?.actions[0],
                                    applicability_type: value,
                                },
                            ],
                        })
                    }
                />
                <InputNumber
                    label='Value'
                    // prefix={
                    //     modalState?.formState?.actions[0]?.applicability_type === 'cart_percentage_discount' ? '%' : '₹'
                    // }
                    className='w-full'
                    placeholder='Enter value'
                    type='number'
                    min={1}
                    value={`${
                        modalState?.formState?.actions[0]?.value ? modalState?.formState?.actions[0]?.value : ''
                    }`}
                    onChange={(value) =>
                        onValuesChange({
                            ...modalState?.formState,
                            actions: [
                                {
                                    ...modalState?.formState?.actions[0],
                                    value: Number(value),
                                },
                            ],
                        })
                    }
                />
                <InputNumber
                    className='w-full'
                    label='Min Order Value'
                    type='number'
                    addonBefore='₹'
                    placeholder='Enter value'
                    min={1}
                    value={`${
                        modalState?.formState?.conditions[0]?.min_cart_value
                            ? modalState?.formState?.conditions[0]?.min_cart_value
                            : ''
                    }`}
                    onChange={(value) =>
                        onValuesChange({
                            ...modalState?.formState,
                            conditions: [
                                {
                                    ...modalState?.formState?.conditions[0],
                                    min_cart_value: Number(value),
                                },
                            ],
                        })
                    }
                />
                <InputNumber
                    className='w-full'
                    label='Max Order Value'
                    addonBefore='₹'
                    placeholder='Enter value'
                    type='number'
                    min={1}
                    value={`${
                        modalState?.formState?.conditions[0]?.max_cart_value
                            ? modalState?.formState?.conditions[0]?.max_cart_value
                            : ''
                    }`}
                    onChange={(value) =>
                        onValuesChange({
                            ...modalState?.formState,
                            conditions: [
                                {
                                    ...modalState?.formState?.conditions[0],
                                    max_cart_value: Number(value),
                                },
                            ],
                        })
                    }
                />
            </Modal>
            <CardLayout title='How should this discount function? Set discount value'>
                <br />
                <>
                    <Button
                        variant='default'
                        onClick={() =>
                            handleModalState({
                                isOpen: true,
                                formState: {
                                    rule_id: '',
                                    priority: props?.value?.length,
                                    conditions: [
                                        {
                                            discount_type: 'CartDiscount',
                                            min_cart_value: null,
                                            max_cart_value: null,
                                        },
                                    ],
                                    actions: [
                                        {
                                            discount_type: 'CartDiscount',
                                            applicability_type: Boolean(
                                                finalFormState?.rules?.some((r) =>
                                                    r?.actions?.some(
                                                        (a) => a?.applicability_type === 'cart_percentage_discount',
                                                    ),
                                                ),
                                            )
                                                ? 'cart_percentage_discount'
                                                : 'cart_fixed_discount',
                                            value: null,
                                        },
                                    ],
                                },
                            })
                        }
                    >
                        Add new value based conditions
                    </Button>

                    <Table className='mt-4' columns={columns} dataSource={props?.value || []} />
                </>
            </CardLayout>
        </>
    )
}

export const DynamicFunctionCard = React.memo(Card)
