import { logUserEvent } from '@gokwik/utilities'
import APIEndPoints from '../constants/apiEndpoints'
import { getCookie, setCookie } from '@library/utilities/helpers/cookieHelper'
import { customAlphabet } from 'nanoid';

export const logEvent = (eventName, eventType, screen, email?, m_id?, m_name?, user_name?, data?) => {
    let userSessionIdFromCookie = getCookie('userSessionId');
    if (!userSessionIdFromCookie) {
        const randomId = customAlphabet('1234567890', 18);
        userSessionIdFromCookie = randomId();
        setCookie('userSessionId', userSessionIdFromCookie, 365);
    }
    const session_id = email + '_' + userSessionIdFromCookie
    logUserEvent({
        url: process.env.REACT_APP_BASE_URL + APIEndPoints.userEvent,
        event_type: eventType,
        event_name: eventName,
        screen_name: screen,
        request_id: email,
        session_id: session_id,
        merchant_id: m_id,
        merchant_name: m_name,
        username: user_name,
        timestamp: new Date().toISOString(),
        data: data
    })
}
