import { ArrowRightOutlined, Button } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { getCookie, updateBreadcrumbs } from '@gokwik/utilities'
import BookDownload from '@library/images/book-download.svg'
import { useNavigate } from 'react-router-dom'
import { setCookie } from '@library/utilities/helpers/cookieHelper'
import { FloatingTourIcon, RenderTour } from '../tour/tour'
import { paymentFeaturesTourSteps } from '../tour/tourSteps'
import { IMenuPageEvents } from '@library/utilities/helpers/eventsHelper'

export const PaymentFeatures = (props: {events: IMenuPageEvents}) => {
    const { tourStepsEvents } = props.events;
    const navigate = useNavigate();
    const [showTour, setShowTour] = useState(false);


    useEffect(() => {
        const checkCookies = () => {
            const isTourVisited = getCookie('paymentsFeatureTour') || null;
            if (!isTourVisited) {
                setShowTour(true)
            }
        }
        setTimeout(checkCookies, 100)
    }, [])

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'settings',
                href: '/checkout/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'payment-features',
                href: '/checkout/settings/payment-features',
                text: 'Payments Features',
            },
        ])
    }, [])

    function startTour() {
        setShowTour(true);
        tourStepsEvents.fireStartedEvent();
    }

    const nextStep = (step) => {
        tourStepsEvents.fireNextStepEvent({
            stepTitle: paymentFeaturesTourSteps[step].title
        });
    }

    const closeTour = () => {
        setShowTour(false)
        setCookie('paymentsFeatureTour', true, 365)
        tourStepsEvents.fireSkippedEvent();
    }

    const finishTour = () => {
        setShowTour(false)
        setCookie('paymentsFeatureTour', true, 365)
        tourStepsEvents.fireCompletedEvent();
    }

    return (
        <div>
            <div className='w-full px-2 flex flex-col gap-2 inter payment-features-tour'>
                <RenderTour showTour={showTour} steps={paymentFeaturesTourSteps} onClose={closeTour} onChange={nextStep} onFinish={finishTour} />
                <p className='text-lg font-bold p-2'>
                    Payments Features
                    <a
                        className='ml-3 text-sm font-normal'
                        href='https://scribehow.com/page/Guide_for_Payment_features__SCqUZmY-Sva_shjw1HjubQ'
                        target='_blank'
                        rel='noopener noreferrer'
                        onClick={() => tourStepsEvents.fireGuideOpenedEvent()}
                    >
                        <img className='mr-1 mb-1' src={BookDownload} height={'18'} width={'18'} />
                        Open Guide
                    </a>
                    <p className='text-sm text-gray-400 font-normal'>
                        Configure different Payment related features such as prepaid discounts, partial pay COD etc.
                    </p>
                </p>

                <div className='flex flex-col mb-15 items-center space-y-4 bg-gray-100 min-h-screen'>
                    <Button
                        className='w-full py-8 px-2 bg-white border-none flex justify-between items-center focus:outline-none prepaid-discounts-tour'
                        style={{ borderBottom: '1px solid #d9d9d9' }}
                        onClick={() => navigate('/checkout/settings/payment-features/prepaid-discounts')}
                    >
                        <span className='text-base font-bold p-1'>Prepaid Discounts</span>
                        <span className='bg-gray-100 rounded-lg px-5 py-3'>
                            <ArrowRightOutlined />
                        </span>
                    </Button>
                    <Button
                        className='w-full py-8 px-2 bg-white border-none flex justify-between items-center focus:outline-none ppcod-tour'
                        style={{ borderBottom: '1px solid #d9d9d9' }}
                        onClick={() => {
                            navigate('/checkout/settings/payment-features/ppcod')
                        }}
                    >
                        <span className='text-base font-bold p-1'>Partial Payment COD (PP/COD)</span>
                        <span className='bg-gray-100 rounded-lg px-5 py-3'>
                            <ArrowRightOutlined />
                        </span>
                    </Button>
                </div>
                <FloatingTourIcon onClick={startTour}/>
            </div>
        </div>
    )
}

export default PaymentFeatures;
