import { deepEqualAndDiff } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { Button, Col, InputNumber, Row, message } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'

const CodLimits = () => {
    const { config, loading, refetch, saveConfig } = useMerchantConfigApi({
        useCache: true,
        configSaveUrl: APIEndPoints.updateDiscountConfig,
    })
    const [isUpdated, setIsUpdated] = useState(false)
    const [codLimits, setCodLimits] = useState({
        cod_limit: null,
        cod_lower_limit: null,
    })
    useEffect(() => {
        if (config) {
            setCodLimits({
                cod_limit: config.cod_limit,
                cod_lower_limit: config.cod_lower_limit,
            })
        }
    }, [config])

    const saveDiscountCodes = () => {
        if (codLimits?.cod_lower_limit && codLimits?.cod_limit && +codLimits?.cod_lower_limit > +codLimits?.cod_limit) {
            message.warning('COD Lower Limit should be less than the COD Upper Limit.')
            return
        }
        const diff = deepEqualAndDiff(config, codLimits)
        if (diff.isEqual) return
        saveConfig(
            {
                ...diff.diff,
            },
            () => {
                setIsUpdated(false)
            },
        )
    }
    return (
        <Col className='bg-white' span={24}>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <InputNumber
                        className='w-full'
                        label={'COD Lower Limit'}
                        value={codLimits.cod_lower_limit}
                        labelTooltip='The limit below which Cash on Delivery option will not be available for the customers on GoKwik Checkout'
                        onChange={(value) => {
                            setCodLimits((prev) => ({ ...prev, cod_lower_limit: value }))
                            setIsUpdated(true)
                        }}
                        min={0}
                    />
                </Col>
                <Col span={12}>
                    <InputNumber
                        className='w-full'
                        label={'COD Upper Limit'}
                        value={codLimits.cod_limit}
                        labelTooltip='The limit above which Cash on Delivery option will not be available for the customers on GoKwik Checkout'
                        onChange={(value) => {
                            setIsUpdated(true)
                            setCodLimits((prev) => ({ ...prev, cod_limit: value }))
                        }}
                        min={0}
                    />
                </Col>
                <Col>
                    <Button disabled={!isUpdated} variant='primary' onClick={() => saveDiscountCodes()}>
                        Save
                    </Button>
                </Col>
            </Row>
        </Col>
    )
}

export default CodLimits
