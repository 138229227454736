import { Button, Col, DownloadOutlined, InboxOutlined, Row, Upload, message } from 'gokwik-ui-kit'
import Test from '@public/assets/discount_sample.csv'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'

const { Dragger } = Upload
type Props = {
    uploadDiscountCSV?: (originFileObj: any, merchant_id: any) => void
    formState?: any
    fileList?: any
}

export const FileUploader = (props: Props) => {
    const { uploadDiscountCSV, formState, fileList } = props
    const merchant_details = useSelector(getMerchantDetails)

    const downloadSample = () => {
        let csvContent = 'codes\r\n'
        csvContent += [
            'wel2D6AWE8FUSOAHOVZKcome',
            'wel0XW9A821F133YH2ZXcome',
            'wel2BJJNPMQ5GXHJYZD9come',
            'welMSSMOHTZ4OWI32VOVcome',
            'wel12QCJAGW05MQISVD4come',
            'welFK2D0C3QXFXZZ6IKZcome',
        ].join('\r\n')
        var pom = document.createElement('a')
        pom.href = Test
        pom.setAttribute('download', 'discount.csv')
        pom.click()
        message.success('Sample downloaded successfully!')
    }

    const Draggerprops = {
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
    }

    return (
        <div>
            <Dragger
                fileList={fileList}
                accept='.csv'
                name='file'
                maxCount={1}
                multiple={false}
                beforeUpload={(file, fileList) => {
                    uploadDiscountCSV(file, merchant_details?.id)
                    return false
                }}
            >
                <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                </p>
                <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                <p className='text-[#00000073] text-sm'>*Multiple files upload is not allowed</p>
            </Dragger>
            <br />

            <Row>
                <Col span={25}>
                    <p className=' font-semibold'>Instructions</p>
                    <ul className='text-sm pl-4 mt-2 mb-0'>
                        <li>Upload a CSV with a single column of discount codes</li>
                        <li>
                            Each code must be between 3 and 30 characters, using only letters and numbers—no special
                            characters
                        </li>
                        <li>Ensure no duplicate codes are present in the file</li>
                        <li>All codes should be uppercase (ALL CAPS)</li>
                    </ul>
                </Col>
            </Row>

            <br />

            <Row align={'middle'} className='border-solid rounded-md border-[#1677FF8C] p-2 bg-[#1677FF0D]'>
                <Col span={16}>
                    <span className='text-xs text-gray-600'>
                        Please ensure that the uploaded file is in the suggested format as shown in the sample file on
                        the right
                    </span>
                </Col>
                <Col span={8}>
                    <Button onClick={downloadSample} type='link' variant='default'>
                        <DownloadOutlined />
                        Download Sample
                    </Button>
                </Col>
            </Row>

            <br />
            <Row className='text-sm'>
                <Col span={16}>
                    <span>Supported Formats: CSV</span>
                </Col>
                <Col span={8}>
                    <span className='text-[#00000073]'>Maximum file size: 10 MB</span>
                </Col>
            </Row>
        </div>
    )
}
