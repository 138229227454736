import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'

type IntegrationsState = {
    rewards: any
    creds: any
}

const initialState: IntegrationsState = {
    rewards: 0,
    creds: 0,
}

const integrationsSlice = createSlice({
    name: 'integrations',
    initialState,
    reducers: {
        setRewards(state, action: PayloadAction<any>) {
            state.rewards = action.payload
        },
        setCreds(state, action: PayloadAction<any>) {
            state.creds = action.payload
        },
    },
})

export const { setRewards, setCreds } = integrationsSlice.actions

export const fetchRewardsAndCreds = () => async (dispatch) => {
    try {
        const rewards = await fetchRewardsFromAPI()
        const creds = await fetchCredsFromAPI()
        dispatch(setRewards(rewards))
        dispatch(setCreds(creds))
    } catch (error) {
        console.error('Failed to fetch rewards and creds:', error)
    }
}

const fetchRewardsFromAPI = async (): Promise<any> => {
    try {
        const response = await makeAPICall({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.merchantRewards}`,
        })
        return response.data.data
    } catch (error) {
        console.error('Failed to fetch rewards:', error)
    }
}

const fetchCredsFromAPI = async (): Promise<any> => {
    try {
        const response = await makeAPICall({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.merchantCreds}`,
        })
        return response.data.data
    } catch (error) {
        console.error('Failed to fetch creds:', error)
    }
}

export const saveRewards = (rewards: any) => async (dispatch) => {
    try {
        const response = await makeAPICall({
            method: 'put',
            url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.merchantRewards}`,
            payload: rewards,
        })
        if (response.success) {
            const newRewards = await fetchRewardsFromAPI()
            dispatch(setRewards(newRewards))
        }
        return response.data.data
    } catch (error) {
        console.error('Failed to save rewards:', error)
    }
}

export const saveCreds = (creds: any, refetch?: boolean) => async (dispatch) => {
    try {
        const response = await makeAPICall({
            method: 'patch',
            url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.merchantCreds}`,
            payload: creds,
        })
        if (response.success) {
            const newCreds = await fetchCredsFromAPI()
            dispatch(setCreds(newCreds))
        }
        return response.data.data
    } catch (error) {
        console.error('Failed to save creds:', error)
    }
}

export default integrationsSlice.reducer
