import { updateBreadcrumbs } from '@gokwik/utilities'
import { Col, Row, Tabs } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import CodLimits from './codLimits'
import TieredDiscounts from '../checkout-configs/tieredDiscounts'

const PaymentSettings = () => {
    const location = useLocation()
    const [activeTab, setActiveTab] = useState('brand')
    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                text: 'Checkout Settings',
                key: 'settings',
                href: '/checkout/settings',
            },
            {
                key: 'payment-settings',
                text: 'Payment Settings',
                href: '/checkout/settings/payment-settings',
            },
        ])
    }, [])
    return (
        <Row gutter={[16, 16]} className='bg-white rounded-md h-full '>
            <Col span={24}>
                <Tabs
                    className='p-3'
                    defaultActiveKey={location.state?.activeTab || 'brand'}
                    items={[
                        {
                            key: 'cod-limits',
                            label: 'COD Limits',
                            children: <CodLimits />,
                        },
                        {
                            key: 'prepaid-discounts',
                            label: 'Tiered Prepaid Discounts',
                            children: <TieredDiscounts />,
                        },
                    ]}
                    onTabClick={(key) => {
                        setActiveTab(key)
                    }}
                />
            </Col>
        </Row>
    )
}

export default PaymentSettings
