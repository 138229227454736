import {
    Col,
    Row,
    Tabs,
} from 'gokwik-ui-kit'
import { useState } from 'react'
import ShippingMethodConfig from './shippingBasedMethods'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import WeightBaseShippingMethods from './weightShipping'
import DiscountBaseShipping from './discountShipping'

const ShippingService = () => {
    const [activeTab, setActiveTab] = useState('1')


    const createConfigSaver = (isPatch) => {
        return useMerchantConfigApi({
            configSaveUrl: APIEndPoints?.merchantConfigs,
            patch: isPatch
        })
    }


    const shippingMethodConfig = createConfigSaver(false)
    const weightBasedConfig = createConfigSaver(true)
    const discountBasedConfig = createConfigSaver(true)


    const { refetch: refetchShipping } = shippingMethodConfig
    const { refetch: refetchWeight } = weightBasedConfig
    const { refetch: refetchDiscount } = discountBasedConfig


    const shippingPriceObject = shippingMethodConfig?.config?.shipping_prices || []
    const shippingWeightObject = weightBasedConfig?.config?.weight_based_shipping_rates || []
    const discountBasedObject = discountBasedConfig?.config?.overwrite_shipping_charge || []
    const discounted_level_srates = shippingMethodConfig?.config?.discounted_level_srates


    const handleShippingSave = async (data) => {
        await shippingMethodConfig.saveConfig(data)
    }

    const handleWeightSave = async (data) => {
        await weightBasedConfig.saveConfig(data)
    }

    const handleDiscountSave = async (data) => {
        await discountBasedConfig.saveConfig(data)
    }


    const handleTabChange = (key) => {
        setActiveTab(key)
        switch (key) {
            case '1':
                refetchShipping()
                break
            case '2':
                refetchWeight()
                break
            case '3':
                refetchDiscount()
                break
            default:
                break
        }
    }


    const tabsList = [
        {
            key: '1',
            label: 'Shipping Method',
            children: <ShippingMethodConfig
                shippingConfig={shippingPriceObject}
                saveConfig={handleShippingSave}
                weightConfig={weightBasedConfig}
                discounted_level_srates= {discounted_level_srates}
            />,
        },
        {
            key: '2',
            label: 'Weight Based Shipping',
            children: <WeightBaseShippingMethods
                shippingConfig={shippingPriceObject}
                saveConfig={handleWeightSave}
                weightConfigs={shippingWeightObject}
                config={weightBasedConfig.config}
            />
        },
        {
            key: '3',
            label: 'Discount Based Shipping',
            children: <DiscountBaseShipping
                discountConfigObject={discountBasedObject}
                saveConfig={handleDiscountSave}
            />
        }
    ]


    const finalTabListFilter = tabsList.filter((tab) => {
        return shippingPriceObject?.length === 0 ? tab.key === '1' : tabsList
    })

    return (
        <span className='bg-neutrals-15 p-2 flex flex-col gap-4'>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <span className='font-semibold text-lg'>Shipping</span>
                    <p className='text-black/40'>
                        This section enables you to choose where you ship and how much you charge for shipping at checkout.
                    </p>
                </Col>
            </Row>
            {!shippingPriceObject?.length ? (
                <ShippingMethodConfig
                    shippingConfig={shippingPriceObject}
                    saveConfig={handleShippingSave}
                />
            ) : (
                <Tabs
                    defaultActiveKey={activeTab}
                    items={finalTabListFilter}
                    onChange={handleTabChange}
                />
            )}
        </span>
    )
}

export default ShippingService
