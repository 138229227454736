import { customerSegementTypes } from '@library/utilities/types'
import { Col, Input, Modal, Row, message } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { getMerchantDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { CustomerSegmentsList } from './customerSegmentsList'
import { CustomersList } from './customersList'

type Props = {
    modalProps?: any
    searchValue?: any
    setSearchValue?: any
    selectedCustomers?: any
    setSelectedCustomers?: any
    setShowCheckedListTags?: any
    setFormState?: any
    setSelectedCustomerSegments?: any
    selectedCustomerSegments?: any
    showCustomerSelectionModal?: any
     searchPlaceholder?: any
    setShowCustomerSelectionModal?: any
    customerSegmentType?: any
 }

const CustomerAndSegmentsSearchModal = (props: Props) => {
    const {
        showCustomerSelectionModal,
        searchValue,
        setFormState,
        setSearchValue,
        modalProps,
        setShowCustomerSelectionModal,
        customerSegmentType,
        setSelectedCustomerSegments,
        setSelectedCustomers,
        selectedCustomerSegments,
        selectedCustomers,
     } = props
    const { title, searchPlaceholder } = modalProps
    const merchant_details = useSelector(getMerchantDetails)

    const [customerList, setCustomerList] = useState([])
    const [customerSegmentList, setCustomerSegmentList] = useState([])
    const [checkedSegmentsList, setCheckedSegmentsList] = useState([...selectedCustomerSegments])
    const [checkedCustomers, setCheckedCustomers] = useState([...selectedCustomers])
    const handleSearchChange = (e) => {
        const value = e.target.value
        setSearchValue(value)
    }

    const fetchCustomerSegments = debounce(async (searchValue) => {
        try {
            const url = process.env.REACT_APP_BASE_URL +
                APIEndPoints.discountConfig.getCustomerSegments(merchant_details?.m_id, searchValue)
            let response = await makeAPICall({
                method: 'get',
                url: url,
                skipLoader: true,
            })
            if (response?.data?.status_code === 200) {
                setCustomerSegmentList(response?.data?.data)
            } else {
                message.error(`Error fetching customers list`)
                setCustomerSegmentList([])
            }
        } catch (error) {
            console.error('Error fetching customers:', error)
            setCustomerSegmentList([])
        }
    }, 800)

    const fetchSpecificCustomers = debounce(async (searchValue) => {
        try {
            let response = await makeAPICall({
                method: 'get',
                url:
                    process.env.REACT_APP_BASE_URL +
                    APIEndPoints.discountConfig.getCustomersList(merchant_details?.m_id, searchValue),
                skipLoader: true,
            })
            if (response?.data?.status_code === 200) {
                setCustomerList(response?.data?.data)
            } else {
                message.error(`Error fetching customers list`)
                setCustomerList([])
            }
        } catch (error) {
            console.error('Error fetching customers:', error)
            setCustomerList([])
        }
    }, 800)

    const onCustomerSegmentCheckChange = (e: any, segment: any) => {
        const isSegmentSelected = checkedSegmentsList?.some((item) => item?.id === segment?.id)
        if (e?.target?.checked && !isSegmentSelected) {
             setCheckedSegmentsList((prev) => [...prev, segment])
        } else {
            setCheckedSegmentsList(checkedSegmentsList.filter((item) => item?.id !== segment?.id))
        }
    }
    const onCustomerCheckChange = (e: any, customer: any) => {
         const isCustomerSelected = checkedCustomers?.some((item) => item?.id === customer?.id)
        if (e?.target?.checked && !isCustomerSelected) {
             setCheckedCustomers((prev) => [...prev, {
                id: customer.id,
                name: customer.firstName + customer.lastName,
                email: customer.email,
                phone: customer.phone
            }])
        } else {
            setCheckedCustomers(checkedCustomers.filter((item) => item?.id !== customer?.id))
        }
    }

    const saveDetails = () => {
        if (customerSegmentType === customerSegementTypes.CustomerSegment) {
            setSelectedCustomerSegments(checkedSegmentsList)
            setFormState((prev) => {
                const temp = JSON.parse(JSON.stringify(prev))
                temp.eligible_customer.segments.add = checkedSegmentsList?.map((item) => item.id)
                if (temp.eligible_customer?.customers.mapping?.length) {
                    temp.eligible_customer.customers.mapping = []
                }
                  return temp
            })
        } else if (customerSegmentType === customerSegementTypes.SpecificCustomer) {
            setSelectedCustomers(checkedCustomers)
            setFormState((prev) => {
                const temp = JSON.parse(JSON.stringify(prev))
                temp.eligible_customer.customers.add = checkedCustomers?.map((item) => item.id)

                if (temp.eligible_customer?.segments.mapping?.length) {
                    temp.eligible_customer.segments.mapping = []
                }
                return temp
            })
        }
        setShowCustomerSelectionModal(false)
        setSearchValue('')
    }

    useEffect(() => {
        if (customerSegmentType === customerSegementTypes.CustomerSegment) {
            fetchCustomerSegments(searchValue)
        } else if (customerSegmentType === customerSegementTypes.SpecificCustomer) {
            fetchSpecificCustomers(searchValue)
        }
    }, [searchValue])

    useEffect(() => {
        if(customerSegmentType === customerSegementTypes.SpecificCustomer) {
            setFormState((prev) => {
                const temp = JSON.parse(JSON.stringify(prev))
                 temp.eligible_customer.customers.mapping = checkedCustomers?.map((item) => ({
                    id: item.id,
                    name: item.name,
                    phone: item.phone,
                    email: item.email
                }));
                 return temp
            })
        }
    }, [checkedCustomers])

    useEffect(() => {
      if(customerSegmentType === customerSegementTypes.CustomerSegment) {
        setFormState((prev) => {
            const temp = JSON.parse(JSON.stringify(prev))
             temp.eligible_customer.segments.mapping = checkedSegmentsList?.map((item) => ({
                id: item.id,
                name: item.name
            }));
             return temp
        })
      }
    }, [checkedSegmentsList])
    
   

    return (
        <Modal
            title={title}
            width={555}
            cancelText={'Cancel'}
            okText={'Add'}
            centered
            open={showCustomerSelectionModal}
            onOk={() => saveDetails()}
            onCancel={() => {
                setShowCustomerSelectionModal(false)
                setSearchValue('')
                setCustomerList([])
                setCustomerSegmentList([])
            }}
        >
            <div>
                <Row>
                    {' '}
                    <Col span={24}>
                        <Input
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder={searchPlaceholder}
                            allowClear
                        />
                    </Col>
                </Row>
                <Row>
                    {customerSegmentType === customerSegementTypes.CustomerSegment && (
                        <Col span={24}>
                            <CustomerSegmentsList
                                list={customerSegmentList}
                                onCustomerSegmentCheckChange={onCustomerSegmentCheckChange}
                                checkedList={checkedSegmentsList}
                            />
                        </Col>
                    )}
                    {customerSegmentType === customerSegementTypes.SpecificCustomer && (
                        <Col span={24}>
                            <CustomersList
                                list={customerList}
                                onCustomerCheckChange={onCustomerCheckChange}
                                checkedList={checkedCustomers}
                            />
                        </Col>
                    )}
                </Row>
            </div>
        </Modal>
    )
}

export { CustomerAndSegmentsSearchModal }
