import React, { useEffect, useState } from 'react'

import { ColumnProps, Table, Tooltip } from 'gokwik-ui-kit'
import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useLocation, useParams } from 'react-router-dom'
import ReactJson from 'react-json-view'

export default function () {
    let location = useLocation()
    const [data, setData] = useState([])
    const parsedUrl = new URL(window.location.href)
    const params = new URLSearchParams(parsedUrl.search)
    const request_id = params.get('request_id')
    const created_at = params.get('created_at')
    const updated_at = params.get('updated_at')

    const columns: ColumnProps<any>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            width: 100, // Set specific width for each column
        },
        {
            title: 'Data',
            dataIndex: 'data',
            width: 200,
            render: (text, record) => (
                <Tooltip
                    className='bg-white'
                    title={
                        <div className='max-h-72 overflow-y-auto bg-white p-0 truncate'>
                            <ReactJson
                                theme='rjv-default'
                                src={JSON.parse(record.data)}
                                collapsed={false}
                                enableClipboard={false}
                            />
                        </div>
                    }
                >
                    <div className='max-h-24 overflow-y-auto max-w-72'>{text}</div>
                </Tooltip>
            ),
        },
        {
            title: 'Device',
            dataIndex: 'device',
            width: 80,
        },
        {
            title: 'Model',
            dataIndex: 'model',
            width: 120,
        },
        {
            title: 'OS',
            dataIndex: 'os',
            width: 100,
        },
        {
            title: 'OS Version',
            dataIndex: 'os_version',
            width: 120,
        },
        {
            title: 'User Agent',
            dataIndex: 'user_agent',
            width: 230,
        },
        {
            title: 'Version',
            dataIndex: 'version',
            width: 100,
        },
        {
            title: 'Browser',
            dataIndex: 'browser',
            width: 120,
        },
        {
            title: 'Browser Version',
            dataIndex: 'browser_version',
            width: 120,
        },
        {
            title: 'Customer Id',
            dataIndex: 'customer_id',
            width: 120,
        },
        {
            title: 'Ip Address',
            dataIndex: 'ip_address',
            width: 150,
        },
        {
            title: 'ISP',
            dataIndex: 'isp',
            width: 100,
        },
        {
            title: 'Status Code',
            dataIndex: 'status_code',
            width: 100,
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            width: 150,
        },
    ]

    useEffect(() => {
        getOrderAnalytics()
    }, [])

    const getOrderAnalytics = async () => {
        const response = await makeAPICall({
            url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.orderAnalytics}`,
            method: 'get',
            params: {
                request_id,
                created_at,
                updated_at,
            },
            skipMode: true,
        })

        if (response?.status === 200 && response?.data?.data?.length) {
            setData(response?.data?.data)
        } else {
            setData([])
        }
    }

    useEffect(() => {
        updateBreadcrumbs((prev) =>
            prev[prev?.length - 1]?.text === String(request_id)
                ? prev
                : [
                      prev[0],

                      {
                          key: location?.state?.pageName || 'Orders',
                          href: location?.state?.path || '/checkout/orders',
                          text: location?.state?.pageName || 'Orders',
                      },
                      {
                          key: 'order-analytics',
                          href: `/checkout/orders-analytics?request_id=${request_id}&created_at=${created_at}&updated_at=${updated_at}`,
                          text: String(request_id),
                      },
                  ],
        )
    }, [])

    return (
        <div className='main-content'>
            <div className='w-full bg-white mt-2 p-4'>
                {!data || data.length === 0 ? (
                    <div className='text-xl text-center'>No Data Found</div>
                ) : (
                    <Table
                        columns={columns}
                        dataSource={data}
                        scroll={{ x: 'max-content' }}
                        pagination={{
                            current: 1, // Current page number
                            pageSize: data.length, // Number of items to display per page
                            total: data.length, // Total number of items in the data array
                            showSizeChanger: false,
                            onChange: () => {},
                        }}
                    />
                )}
            </div>
        </div>
    )
}
