import React, { ReactNode, useEffect } from 'react'
import { Alert, Row } from 'gokwik-ui-kit'

type Props = {
    title: string
    subtitle?: string
    children: ReactNode
    baselineExplainer?: string
    alertContent?: any
}

const cardLayout = (props: Props) => {
    const { title, subtitle, children, baselineExplainer, alertContent } = props
    useEffect(() => {
        // window.onbeforeunload = () => {
        //     return true
        // }
    }, [])

    return (
        <>
            {/* border border-solid border-[#CBD2DE] */}
            <div className='bg-[#FFFFFF] rounded-lg p-4 w-full'>
                {alertContent?.alertTitle && alertContent?.alertMessage && (
                    <Alert
                        className='my-2'
                        message={alertContent?.alertTitle}
                        description={alertContent?.alertMessage}
                        showIcon
                        type={'warning'}
                    />
                )}
                <p className='text-base font-semibold'>{title}</p>
                {subtitle && <p className='text-sm text-[#00000080] my-2 font-normal'>{subtitle}</p>}
                {children}
                {baselineExplainer && <p className='text-[#000000A6] text-xs font-normal'>{baselineExplainer}</p>}
            </div>
        </>
    )
}

export const CardLayout = React.memo(cardLayout)
