import { useAppDispatch } from '@library/utilities/hooks'
import { AutoCombinedDiscount } from '@library/utilities/interface'
import { fetchAutomaticDiscounts, updateSummary } from '@store/discounts'
import {
    Alert,
    Button,
    ChipInput,
    Col,
    DeleteOutlined,
    Form,
    Input,
    message,
    Radio,
    Row,
    Select,
    Switch,
    Table,
    Tag,
} from 'gokwik-ui-kit'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

const DiscountCombinations = ({ form }: { form: any }) => {
    const location = useLocation()
    const enableCombineManualDiscounts = Form.useWatch('enableCombineManualDiscounts', form)
    const enableCombineAutomaticDiscounts = Form.useWatch('enableCombineAutomaticDiscounts', form)
    const combineManualDiscounts = Form.useWatch('combineManualDiscounts', form)
    const combineOverwriteAutoDiscounts = Form.useWatch('combineOverwriteAutoDiscounts', form)
    const [automaticDiscounts, setAutomaticDiscounts] = useState({
        name: '',
        behavior: '',
    })
    const dispatch = useAppDispatch()
    const [autoDiscounts, setAutoDiscounts] = useState<AutoCombinedDiscount[]>([])
    const options = useMemo(() => {
        const behaviorKey =
            automaticDiscounts.behavior === 'combines' ? 'extra_automatic_discount' : 'overwrite_automatic_discount'
        const otherKey =
            automaticDiscounts.behavior === 'combines' ? 'overwrite_automatic_discount' : 'extra_automatic_discount'
        const discountTitle = form.getFieldValue('title')

        return autoDiscounts
            .filter(
                (discount) =>
                    !combineOverwriteAutoDiscounts?.some((item) => item.name === discount.title) &&
                    discount[behaviorKey]?.[0]?.automatic_code_clubs_with_discount !== 'ALL' &&
                    discount[otherKey]?.[0]?.automatic_code_clubs_with_discount !== 'ALL' &&
                    discount[otherKey]?.every((item) => item.allowed_manual_code !== discountTitle),
            )
            .map((discount) => ({
                label: discount.title,
                value: discount.title,
            }))
    }, [autoDiscounts, combineOverwriteAutoDiscounts, automaticDiscounts.behavior])

    const changeUpdatedCodes = (behavior: string, value: string, action: 'add' | 'remove') => {
        const update_codes = form.getFieldValue('update_codes') || {}
        const toCompare = action === 'add' ? 'remove' : 'add'

        if (update_codes[behavior]?.[toCompare]?.includes(value)) {
            form.setFieldsValue({
                update_codes: {
                    ...update_codes,
                    [behavior]: {
                        ...(update_codes[behavior] || {}),
                        [toCompare]: update_codes[behavior][toCompare].filter((item: string) => item !== value),
                    },
                },
            })
            return
        }

        form.setFieldsValue({
            update_codes: {
                ...update_codes,
                [behavior]: {
                    ...(update_codes[behavior] || {}),
                    [action]: [...(update_codes?.[behavior]?.[action] || []), value],
                },
            },
        })
    }

    useEffect(() => {
        ;(async () => {
            const res = await dispatch(fetchAutomaticDiscounts())
            if (res.success) {
                setAutoDiscounts(res.data.data.discounts.map((discount: any) => discount))
            }
        })()
    }, [])

    return (
        <Form
            form={form}
            layout='vertical'
            requiredMark='optional'
            scrollToFirstError
            onValuesChange={(changedValues, allValues) => {
                dispatch(updateSummary(allValues))
            }}
        >
            <Row gutter={[16, 16]}>
                {form.getFieldValue('discountType') !== 'discountedCartItems' && (
                    <Col span={24}>
                        <Form.Item
                            label='Allow customers to combine this discount with other manual discounts?'
                            name='enableCombineManualDiscounts'
                            className='label-bold'
                            rules={[{ required: true, message: 'Please select manual discounts combination!' }]}
                        >
                            <Radio.Group>
                                <Radio value={false}>No</Radio>
                                <Radio value={true}>Yes</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {enableCombineManualDiscounts && (
                            <>
                                <Row gutter={[12, 12]} align={'bottom'}>
                                    <Alert
                                        message={
                                            <ul className='m-0 pl-4'>
                                                <li>BXGY manual codes can not be combined.</li>
                                            </ul>
                                        }
                                        type='info'
                                        showIcon
                                        className='mt-2 w-full'
                                    />
                                </Row>
                            </>
                        )}
                    </Col>
                )}

                <Col span={24}>
                    <Form.Item
                        label='Allow customers to combine/overwrite pre-applied automatic discounts?'
                        name='enableCombineAutomaticDiscounts'
                        className='label-bold'
                        rules={[{ required: true, message: 'Please select automatic discounts combination!' }]}
                    >
                        <Radio.Group>
                            <Radio value={false}>No</Radio>
                            <Radio value={true}>Only Specific Discount Codes</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {enableCombineAutomaticDiscounts && (
                        <>
                            <Row gutter={[12, 12]} align={'bottom'}>
                                <Col span={6}>
                                    <Select
                                        className='w-full'
                                        label='Behavior'
                                        placeholder='Select behavior'
                                        value={automaticDiscounts.behavior}
                                        onChange={(value) =>
                                            setAutomaticDiscounts({ ...automaticDiscounts, behavior: value })
                                        }
                                        options={[
                                            { label: 'Combines', value: 'combines' },
                                            { label: 'Overwrite', value: 'overwrite' },
                                        ]}
                                    />
                                </Col>
                                <Col span={6}>
                                    <Select
                                        disabled={!automaticDiscounts.behavior}
                                        placeholder='Select code'
                                        label='Automatic Discount Codes'
                                        value={automaticDiscounts.name}
                                        onChange={(value) =>
                                            setAutomaticDiscounts({ ...automaticDiscounts, name: value })
                                        }
                                        options={options}
                                        className='w-full'
                                    />
                                </Col>
                                <Col>
                                    <Button
                                        onClick={() => {
                                            if (!automaticDiscounts.name || !automaticDiscounts.behavior) return
                                            if (
                                                combineManualDiscounts?.some(
                                                    (code) => code.name === automaticDiscounts.name,
                                                )
                                            )
                                                message.error('Code already added')
                                            const behavior =
                                                automaticDiscounts.behavior === 'combines' ? 'extra' : 'overwrite'
                                            form.setFieldsValue({
                                                combineOverwriteAutoDiscounts: [
                                                    ...(combineOverwriteAutoDiscounts || []),
                                                    automaticDiscounts,
                                                ],
                                            })
                                            changeUpdatedCodes(behavior, automaticDiscounts.name, 'add')

                                            setAutomaticDiscounts({ name: '', behavior: '' })
                                        }}
                                    >
                                        Add more
                                    </Button>
                                </Col>
                                <Col span={24}>
                                    <Table
                                        columns={[
                                            {
                                                title: 'Behavior',
                                                dataIndex: 'behavior',
                                                key: 'behavior',
                                            },
                                            {
                                                title: 'Allowed Codes',
                                                dataIndex: 'name',
                                                key: 'name',
                                            },
                                            {
                                                title: '',
                                                dataIndex: 'action',
                                                render: (text: any, record: any) => (
                                                    <DeleteOutlined
                                                        className='cursor-pointer text-error-500'
                                                        onClick={() => {
                                                            form.setFieldsValue({
                                                                combineOverwriteAutoDiscounts:
                                                                    combineOverwriteAutoDiscounts.filter(
                                                                        (item: any) =>
                                                                            JSON.stringify(item) !==
                                                                            JSON.stringify(record),
                                                                    ),
                                                            })
                                                            changeUpdatedCodes(
                                                                record.behavior === 'combines' ? 'extra' : 'overwrite',
                                                                record.name,
                                                                'remove',
                                                            )
                                                        }}
                                                    />
                                                ),
                                            },
                                        ]}
                                        dataSource={combineOverwriteAutoDiscounts}
                                        pagination={{
                                            pageSize: 10,
                                            total: combineOverwriteAutoDiscounts?.length,
                                            showSizeChanger: false,
                                            position: ['none', 'bottomRight'],
                                            showTotal: (total, range) =>
                                                `Showing ${range[0]}-${range[1]} of ${total} Records`,
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Form.Item
                                className='pl-4 hide-control-input'
                                name={'combineOverwriteAutoDiscounts'}
                                rules={[
                                    {
                                        required: enableCombineAutomaticDiscounts,
                                        message: 'Please add discounts to combine!',
                                    },
                                ]}
                            ></Form.Item>
                            <Alert
                                message={'Please make sure that automatic discount codes are configured on GoKwik.'}
                                type='info'
                                showIcon
                                className='mt-2 w-full'
                            />
                        </>
                    )}
                </Col>
            </Row>
        </Form>
    )
}

export default DiscountCombinations
