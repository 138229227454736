import { deepEqualAndDiff } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { Button, ChipInput, Col, Row } from 'gokwik-ui-kit'
import { useEffect, useMemo, useState } from 'react'

const DiscountCodes = () => {
    const { config, loading, refetch, saveConfig } = useMerchantConfigApi({
        useCache: true,
        configSaveUrl: APIEndPoints.updateDiscountConfig,
    })
    const [isUpdated, setIsUpdated] = useState(false)
    const [discountCodes, setDiscountCodes] = useState({
        gwp_discounts: config?.gwp_discounts,
    })

    useEffect(() => {
        if (config) {
            const newConfig: any = Object.keys(discountCodes).reduce(
                (result, key) => ({ ...result, [key]: config[key] }),
                {},
            )
            setDiscountCodes(newConfig)
        }
    }, [config])

    const saveDiscountCodes = () => {
        const diff = deepEqualAndDiff({ gwp_discounts: config?.gwp_discounts }, discountCodes)

        if (diff.isEqual) return
        saveConfig(
            {
                ...diff.diff,
            },
            () => {
                setIsUpdated(false)
            },
        )
    }

    return (
        <Col className='bg-white' span={24}>
            <Row gutter={[0, 16]}>
                <Col span={24}>
                    <ChipInput
                        label='GWP Discounts'
                        chips={discountCodes.gwp_discounts || []}
                        onAddChip={(chip) => {
                            if (!chip.trim()) return
                            setDiscountCodes((prev) => ({
                                ...prev,
                                gwp_discounts: [...(prev.gwp_discounts || []), chip],
                            }))
                            setIsUpdated(true)
                        }}
                        onRemoveChip={(chip) => {
                            setDiscountCodes((prev) => ({
                                ...prev,
                                gwp_discounts: prev.gwp_discounts.filter((c) => c !== chip),
                            }))

                            setIsUpdated(true)
                        }}
                    />
                </Col>

                <Col>
                    <Button variant='primary' disabled={!isUpdated} onClick={() => saveDiscountCodes()}>
                        Save
                    </Button>
                </Col>
            </Row>
        </Col>
    )
}

export default DiscountCodes
