export const welcomeSteps = [
    {
        title: 'Welcome',
        description: 'You can choose different configuration from this section',
        target: () => document.querySelector('.setting-menu')!,
        placement: 'left',
    },
]

export const pincodeServiceabilityTourSteps = [
    {    
        id: 'setting_up_deliverable_pincodes',
        title: 'Setting up Deliverable pincodes',
        description: 'You can Configure deliverable pincodes on your checkout from here.',
        target: () => document.querySelector('.pincode-serviceability-item')!,
        placement: 'right',
    },
    {   
        id: 'explore_guide',
        title: 'Explore Guide',
        description: 'You can click on the "Open Guide" to know everything about Pin code serviceability.',
        target: () => document.querySelector('#pincode-serviceability-guide')!,
        placement: 'bottom',
    },
    {    
        id: 'upload_pincode',
        title: 'Upload pincode',
        description: 'You can click on this button to upload pin code file.',
        target: () => document.querySelector('#upload-pincode')!,
        placement: 'left',
    },
    {
        id: 'enable_button',
        title: 'Enable button',
        description: 'After uploading pin codes click here to  enable this feature.',
        target: () => document.querySelector('#enable-pincode')!,
        placement: 'left',
    },
    {
        id: 'start_tour',
        title: 'Start Tour',
        description: 'You can revisit tour from here.',
        target: () => document.querySelector('#start-tour-button')!,
        placement: 'top',
    },
]

export const shopifyShippingServiceabilityTourSteps = [
    {   
        id: 'mapping_payment_methods_with_shipping',
        title: 'Mapping Payment methods with shipping',
        description: 'You can map your already created shipping on shopify to prepaid / CoD Payment methods.',
        target: () => document.querySelector('.shipping-serviceability-item')!,
        placement: 'right',
    },
    {    
        id: 'explore_guide',
        title: 'Explore Guide',
        description: 'You can click on the "Open Guide" to know everything about shipping serviceability.',
        target: () => document.querySelector('#shipping-serviceability-guide')!,
        placement: 'bottom',
    }, 
    {   
        id: 'add_shipping_method',
        title: 'Add Shipping method',
        description: 'Click here to map your shopify shipping methods with payment methods.',
        target: () => document.querySelector('#add-shipping-method')!,
        placement: 'left',
    },
    { 
        id: 'start_tour',
        title: 'Start Tour',
        description: 'You can revisit tour from here.',
        target: () => document.querySelector('#start-tour-button')!,
        placement: 'top',
    },
]

export const allDiscountsTourSteps = [
    {   
        id: 'creating_new_discounts',
        title: 'Creating New Discounts',
        description:
            'You can create discounts from gokwik which can not be created on shopify e.g. 50% upto Rs. 200, Buy 3 at 299 etc.',
        target: () => document.querySelector('.all-discounts-item')!,
        placement: 'right',
    },
    {  
        id: 'explore_guide',
        title: 'Explore Guide',
        description: 'You can click on the "Open Guide" to know everything about gokwik discounts.',
        target: () => document.querySelector('#all-discounts-guide')!,
        placement: 'bottom',
    },
    {  
        id: 'create_discount',
        title: 'Create Discount',
        description:
            'Click here to explore multiple discounts from gokwik which will automatically reflect on shopify.',
        target: () => document.querySelector('#create-discount')!,
        placement: 'left',
    },
    {
        id: 'start_tour',
        title: 'Start Tour',
        description: 'You can revisit tour from here.',
        target: () => document.querySelector('#start-tour-button')!,
        placement: 'bottom',
    }
]

export const codFeaturesTourSteps = [
    {  
        id: 'setting_up_cod_interventions',
        title: 'Setting up CoD interventions',
        description:
            'You can apply users verifications such as CoD OTP, CoD Captcha and can hide/ show the shipping, payments methods based on cart value, customer etc.',
        target: () => document.querySelector('.cod-features-item')!,
        placement: 'left',
    },
    { 
        id: 'explore_guide',
        title: 'Explore Guide',
        description: 'You can click on the "Open Guide" to know everything about gokwik cod features.',
        target: () => document.querySelector('#cod-features-guide')!,
        placement: 'bottom',
    },
    {  
        id: 'add_new_rules',
        title: 'Add new rules',
        description:
            'Click here to configure rules for hiding, renaming, showing shipping & payment method basis 10+ diff conditions.',
        target: () => document.querySelector('#add-new-rule')!,
        placement: 'left',
    },
    { 
        id: 'cod_verification',
        title: 'CoD Verification',
        description: 'Use these configs to set user verification on CoD payment method.',
        target: () => document.querySelector('.cod-verification')!,
        placement: 'top',
    },
    {   
        id: 'start_tour',
        title: 'Start Tour',
        description: 'You can revisit tour from here.',
        target: () => document.querySelector('#start-tour-button')!,
        placement: 'bottom',
    },
]

export const ppcodFeatureTourSteps = [
    {
        id: 'toggle_ppcod',
        title: 'Enable/Disable PPCOD',
        description: 'This is the feature flag which enables/disables PPCOD feature.',
        target: () => document.querySelector('.toggle-ppcod-button')!,
        placement: 'top',
    },
    {
        id: 'select_ppcod_type',
        title: 'Select PPCOD Type',
        description:
            'PPCOD type can be (i) Standard where PPCOD will be applicable for all users, (ii) Risk flag PPCOD will be applicable basis risk i.e. low, medium or high risk customers (iii) Tag based PPCOD will be applicable on selected products where "ppcod" tag is present.',
        target: () => document.querySelector('.select-ppcod-type-tour')!,
        placement: 'top',
    },
    {
        id: 'select_risk_flags',
        title: 'Add Risk Flags',
        description:
            'In case of risk based PPCOD, you can select the risk from this field. If you choose medium & high risk then PPCOD will be available for only medium and high risk and not for low risk customers.',
        target: () => document.querySelector('.select-ppcod-risk-flags-tour')!,
        placement: 'top',
    },
    {
        id: 'percentage_ppcod_range',
        title: 'Percentage Amount Range',
        description: 'This is how a percentage amount ppcod range looks like.',
        target: () => document.querySelector('.percentage-ppcod-tour')!,
        placement: 'bottom',
    },
    {
        id: 'add_cart_range',
        title: 'Set Cart Range',
        description: 'Define the range of cart values (minimum to maximum) for which the PPCOD will be applied.',
        target: () => document.querySelector('.ppcod-cart-range-tour')!,
        placement: 'bottom',
    },
    {
        id: 'add_ppcod_price',
        title: 'Set Partial Payment Amount',
        description: 'Set the fixed or percentage amount to be charged as Partial payment.',
        target: () => document.querySelector('.ppcod-price-tour')!,
        placement: 'bottom',
    },
    {
        id: 'add_ppcod_min_charges',
        title: 'Set Minimum Partial Payment Amount',
        description: 'Set the minimum amount to be charged as Partial payment.',
        target: () => document.querySelector('.ppcod-min-charges-tour')!,
        placement: 'bottom',
    },
    {
        id: 'add_ppcod_max_charges',
        title: 'Set Maximum Partial Payment Amount',
        description: 'Set the maximum amount that can be charged as Partial payment.',
        target: () => document.querySelector('.ppcod-max-charges-tour')!,
        placement: 'bottom',
    },
    {
        id: 'fixed_ppcod_range',
        title: 'Fixed Amount Range',
        description: 'This is how a fixed amount ppcod range looks like.',
        target: () => document.querySelector('.fixed-ppcod-tour')!,
        placement: 'bottom',
    },
    {
        id: 'add_ppcod_range_tour',
        title: 'Add New Range',
        description: 'Add new range of cart values (minimum to maximum) and set PPCOD charges.',
        target: () => document.querySelector('#add-ppcod-range-tour')!,
        placement: 'bottom',
    },
    {   
        id: 'start_tour',
        title: 'Start Tour',
        description: 'You can revisit tour from here.',
        target: () => document.querySelector('#start-tour-button')!,
        placement: 'top',
    },
]

export const paymentFeaturesTourSteps = [
    {  
        id: 'setting_up_payment_features',
        title: 'Setting up Payments Features',
        description: 'This section allows you to customise the payment page with engaging features, such as discounts on prepaid methods, partial COD(pre-pay COD), Order First Pay Later (OFPL) etc.',
        target: () => document.querySelector('.payment-features')!,
        placement: 'left',
    },
    {  
        id: 'setting_up_prepaid_discounts',
        title: 'Setting up Prepaid Discounts',
        description: `This section allows you to create discount rules that will apply discounts on user's payment page.`,
        target: () => document.querySelector('.prepaid-discounts-tour')!,
        placement: 'bottom',
    },
    {  
        id: 'setting_up_ppcod',
        title: 'Setting up Partial Pay COD (PPCOD) ',
        description: `This section allows you to configure a partial prepaid amount on COD payment method, User will be asked to pay partial amount as UPI the rest will be taken as COD.`,
        target: () => document.querySelector('.ppcod-tour')!,
        placement: 'bottom',
    },
    {   
        id: 'start_tour',
        title: 'Start Tour',
        description: 'You can revisit tour from here.',
        target: () => document.querySelector('#start-tour-button')!,
        placement: 'top',
    }
]

export const prepaidDiscountFeatureTourSteps = [
    {  
        id: 'add_prepaid_discount_tour',
        title: 'Creating Prepaid Discounts',
        description: 'Click on the Add Button to create new Prepaid Discounts.',
        target: () => document.querySelector('.add-prepaid-discount-button')!,
        placement: 'left',
    },
    {  
        id: 'toggle_prepaid_discount_tour',
        title: 'Toggle Prepaid Discounts',
        description: 'This is the feature flag which enables/disables Prepaid Discounts feature.',
        target: () => document.querySelector('.toggle-prepaid-discount-button')!,
        placement: 'bottom',
    },
    {  
        id: 'select_discount_type',
        title: 'Select Prepaid Discount Type',
        description: 'Discounts can be either Fixed Amount (Flat Off) or Percentage type.',
        target: () => document.querySelector('.view-prepaid-discount-type-tour')!,
        placement: 'top',
    },
    {  
        id: 'view_discount_limit_fields',
        title: 'Explore Discount Limits',
        description: 'This section allows you to set the range for which the discount will be applicable. It also allows you to set the maximum possible discount amount (cap the discount value).',
        target: () => document.querySelector('.view-prepaid-discount-tiers-tour')!,
        placement: 'top',
    },
    {  
        id: 'select_discount_method_type',
        title: 'Select Payment Method',
        description: 'This dropdown allows you to set prepaid discounts for a certain payment method.',
        target: () => document.querySelector('.view-prepaid-discount-method-type-tour')!,
        placement: 'bottom',
    },
    {
        id: 'view_table_prepaid_discount_tour',
        title: 'View Existing Prepaid Discounts',
        description: 'Upon successfull creation of prepaid discount rules, the created discounts can be viewed from this table.',
        target: () => document.querySelector('.view-existing-prepaid-discounts')!,
        placement: 'top',
    },
    {  
        id: 'filter_prepaid_discount_tour',
        title: 'Filtering Prepaid Discounts',
        description: 'Select the payment method from the dropdown, to filter existing prepaid discounts.',
        target: () => document.querySelector('.filter-prepaid-discount-tour')!,
        placement: 'top',
    },
    {   
        id: 'start_tour',
        title: 'Start Tour',
        description: 'You can revisit tour from here.',
        target: () => document.querySelector('#start-tour-button')!,
        placement: 'top',
    }
]
