import { titleCase } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { validateDynamicDiscount } from '@library/utilities/helpers/validations'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import {
    DynamicDiscount,
    DynamicDiscountConditioned,
    DynamicDiscountInfo,
    DynamicDiscountFlattened,
} from '@library/utilities/interface'
import {
    Button,
    Col,
    ColumnsType,
    DeleteOutlined,
    Input,
    InputNumber,
    PlusOutlined,
    Radio,
    Row,
    Select,
    Table,
    Tooltip,
    message,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'

const DiscountCategoryMapping = {
    value: 'Order Value',
    volume: 'Product Quantity',
}

const formatDynamicDiscountObject = (discounts: DynamicDiscount[]) => {
    if (!discounts || ['{}', '[]'].includes(JSON.stringify(discounts))) return null
    const tempDiscounts = JSON.parse(JSON.stringify(discounts)).reduce(
        (result, discount) => ({
            ...result,
            [discount.discount_name]: {
                discount_category: discount.discount_category || 'volume', //discount code to be handled as volumne if discount_category doesn't exists
                discount_info: [...(discount.discount_info || [])],
            },
        }),
        {},
    )
    return { ...tempDiscounts }
}

const formatDynamicDiscountArray = (discounts: { [key: string]: Partial<DynamicDiscount> }, type: string) =>
    type === 'view'
        ? Object.entries(discounts).reduce(
              (result, [key, val]) => [
                  ...result,
                  ...val.discount_info.map((value) => ({
                      ...value,
                      discount_category: val.discount_category,
                      discount_name: key,
                  })),
              ],
              [],
          )
        : Object.entries(discounts).reduce(
              (result, [key, val]) => [
                  ...result,
                  {
                      discount_name: key,
                      discount_category: val.discount_category,
                      discount_info: [...val.discount_info],
                  },
              ],
              [],
          )

const DynamicDiscounts = () => {
    const { config, loading, refetch, saveConfig } = useMerchantConfigApi({
        useCache: true,
        configSaveUrl: APIEndPoints.updateDiscountConfig,
    })
    const [discountData, setDiscountData] = useState<{
        [key: string]: DynamicDiscountConditioned
    }>({})
    const [isUpdated, setIsUpdated] = useState(false)
    const [dynamicDiscount, setDynamicDiscount] = useState<DynamicDiscountFlattened>({
        discount_category: 'value',
        discount_name: '',
        item_volume: null,
        discount_type: null,
        discount_value: null,
        min_price: null,
        max_price: '',
    })

    const dynamicDiscountAddition = () => {
        let submit = validateDynamicDiscount(dynamicDiscount, discountData?.[dynamicDiscount.discount_name] || {})
        if (submit.status) {
            let temp = JSON.parse(JSON.stringify(dynamicDiscount))
            temp.discount_name = temp.discount_name.trim()
            const { discount_name, discount_category, ...discount_info } = { ...dynamicDiscount }
            if (discount_category === 'value') {
                discount_info.max_price = discount_info.max_price ?? ''
                delete discount_info.item_volume
            } else if (discount_category === 'volume') {
                delete discount_info.min_price
                delete discount_info.max_price
            }
            setDiscountData((prev) => ({
                ...prev,
                [discount_name]: {
                    discount_category,
                    discount_info: [...(prev?.[discount_name]?.discount_info || []), { ...discount_info }],
                },
            }))
            setDynamicDiscount((prev) => ({
                ...prev,
                discount_name: '',
                item_volume: null,
                discount_type: '',
                discount_value: null,
                min_price: null,
                max_price: null,
            }))
            setIsUpdated(true)
        } else {
            let err = submit.msg
            message.error(err)
        }
    }
    const dynamicDiscountDeletion = (deletedDiscount: DynamicDiscountFlattened) => {
        const { discount_name, discount_category, ...discount_info } = deletedDiscount
        const tempDynamicDiscounts = JSON.parse(JSON.stringify(discountData))
        if (tempDynamicDiscounts[discount_name].discount_info.length === 1) {
            delete tempDynamicDiscounts[discount_name]
        } else {
            tempDynamicDiscounts[discount_name].discount_info = tempDynamicDiscounts[
                discount_name
            ].discount_info.filter((info) => JSON.stringify(info) !== JSON.stringify(discount_info))
        }
        setIsUpdated(true)
        setDiscountData((prev) => (Object.keys(tempDynamicDiscounts).length ? { ...tempDynamicDiscounts } : null))
    }

    const columns: ColumnsType<any> = [
        {
            title: 'Discount Category',
            dataIndex: 'discount_category',
            render: (val: string) => DiscountCategoryMapping[val],
        },
        {
            title: 'Discount Code',
            dataIndex: 'discount_name',
        },
        {
            title: 'Discount Type',
            dataIndex: 'discount_type',
            render: (val: string) => titleCase(val),
        },
        {
            title: 'Discount Value',
            dataIndex: 'discount_value',
            align: 'right',
        },
        {
            title: 'Item Volume',
            dataIndex: 'item_volume',
            align: 'right',
        },
        {
            title: 'Min Order Value',
            dataIndex: 'min_price',
            align: 'right',
        },
        {
            title: 'Max Order Value',
            dataIndex: 'max_price',
            align: 'right',
        },
        {
            title: ' ',
            dataIndex: 'operation',
            render: (_: any, record: DynamicDiscountFlattened, index: number) => {
                return <DeleteOutlined className='text-error-500' onClick={() => dynamicDiscountDeletion(record)} />
            },
        },
    ]

    useEffect(() => {
        setDiscountData(formatDynamicDiscountObject(config?.dynamic_discount))
    }, [config])

    return (
        <Col className='bg-white' span={24}>
            <Row gutter={[16, 24]}>
                <Col span={24}>
                    <Row gutter={[16, 16]} align={'bottom'}>
                        <Col span={24} className='flex items-center gap-x-2'>
                            <span>Discount Category* :</span>
                            <Radio.Group
                                onChange={(e) => {
                                    setDynamicDiscount((prev) => ({
                                        ...prev,
                                        discount_category: e.target.value,
                                    }))
                                }}
                                value={dynamicDiscount.discount_category}
                            >
                                {Object.entries(DiscountCategoryMapping).map(([key, value]) => (
                                    <Radio key={key} value={key}>
                                        {value}
                                    </Radio>
                                ))}
                            </Radio.Group>
                        </Col>
                        <Col span={4}>
                            <Input
                                label='Discount Code*'
                                placeholder={'Enter Shopify discount code'}
                                value={dynamicDiscount.discount_name}
                                onChange={(e) => {
                                    setDynamicDiscount((prev) => ({
                                        ...prev,
                                        discount_name: e.target.value,
                                    }))
                                }}
                            />
                        </Col>
                        <Col span={4}>
                            <Select
                                label='Discount Type*'
                                className='w-full'
                                placeholder='Select type'
                                value={dynamicDiscount.discount_type}
                                onChange={(val) => {
                                    setDynamicDiscount((prev) => ({
                                        ...prev,
                                        discount_type: val,
                                    }))
                                }}
                                options={['fixed', 'percentage'].map((val) => ({ label: titleCase(val), value: val }))}
                            />
                        </Col>
                        {dynamicDiscount.discount_category === 'volume' ? (
                            <Col span={4}>
                                <InputNumber
                                    label='Item Count*'
                                    placeholder={'Enter integer'}
                                    className='w-full'
                                    min={1}
                                    max={Number.MAX_SAFE_INTEGER}
                                    value={dynamicDiscount.item_volume}
                                    onChange={(value) => {
                                        setDynamicDiscount((prev) => ({
                                            ...prev,
                                            item_volume: +value,
                                        }))
                                    }}
                                />
                            </Col>
                        ) : (
                            <>
                                <Col span={4}>
                                    <InputNumber
                                        label={`Min Order Value *`}
                                        className='w-full'
                                        placeholder='Enter value'
                                        min={0}
                                        max={Number.MAX_SAFE_INTEGER}
                                        value={dynamicDiscount.min_price}
                                        onChange={(value) => {
                                            setDynamicDiscount((prev) => ({
                                                ...prev,
                                                min_price: +value,
                                            }))
                                        }}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumber
                                        label={`Max Order Value`}
                                        className='w-full'
                                        placeholder='Enter value'
                                        min={0}
                                        max={Number.MAX_SAFE_INTEGER}
                                        value={dynamicDiscount.max_price}
                                        onChange={(value) => {
                                            setDynamicDiscount((prev) => ({
                                                ...prev,
                                                max_price: +value,
                                            }))
                                        }}
                                    />
                                </Col>
                            </>
                        )}
                        <Col span={4}>
                            <InputNumber
                                label={`Discount Value${dynamicDiscount.discount_type === 'percentage' ? '(%)' : ''}*`}
                                className='w-full'
                                placeholder='Enter decimal'
                                min={0}
                                max={dynamicDiscount.discount_type === 'percentage' ? 100 : Number.MAX_SAFE_INTEGER}
                                value={dynamicDiscount.discount_value}
                                onChange={(value) => {
                                    setDynamicDiscount((prev) => ({
                                        ...prev,
                                        discount_value: +value,
                                    }))
                                }}
                            />
                        </Col>
                        <Col span={4}>
                            <Button className='w-full flex items-center truncate' onClick={dynamicDiscountAddition}>
                                <PlusOutlined />
                                <Tooltip title='Add new discount'>
                                    <span className='truncate'>Add New Discount</span>
                                </Tooltip>
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Table
                        dataSource={discountData ? formatDynamicDiscountArray(discountData, 'view') : []}
                        columns={columns}
                        pagination={false}
                    />
                </Col>
                <Col>
                    <Button
                        variant='primary'
                        disabled={!isUpdated}
                        onClick={() =>
                            saveConfig({
                                dynamic_discount: formatDynamicDiscountArray(discountData, 'submit'),
                            })
                        }
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        </Col>
    )
}

export default DynamicDiscounts
