import { deepEqualAndDiff } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { Button, ChipInput, Col, message, Row, Switch, Popconfirm } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'

const BlockDiscountCodes = () => {
    const { config, loading, refetch, saveConfig } = useMerchantConfigApi({
        useCache: true,
        configSaveUrl: APIEndPoints.updateDiscountConfig,
    })
    const [isUpdated, setIsUpdated] = useState(false)
    const [discountCodes, setDiscountCodes] = useState({
        enable_no_discount: false,
        discount_code_config: {
            cod: [],
            upi: [],
            cod_blocking: [],
        },
    })
    useEffect(() => {
        if (config) {
            setDiscountCodes({
                enable_no_discount: config.enable_no_discount,
                discount_code_config: {
                    cod: config.discount_code_config?.cod || [],
                    upi: config.discount_code_config?.upi || [],
                    cod_blocking: config.discount_code_config?.cod_blocking || [],
                },
            })
        }
    }, [config])

    const saveDiscountCodes = () => {
        const diff = deepEqualAndDiff(config, discountCodes)
        if (diff.isEqual) return
        saveConfig(
            {
                ...diff.diff,
            },
            () => {
                setIsUpdated(false)
            },
        )
    }
    const showPopConfirm = discountCodes?.discount_code_config?.cod?.length === 0 && discountCodes.enable_no_discount
    return (
        <Col className='bg-white' span={24}>
            <Row gutter={[0, 16]}>
                <Col className='flex gap-x-2 items-center'>
                    <span className='mr-1'>{!discountCodes.enable_no_discount ? 'Enable' : 'Disable'} Blocking</span>{' '}
                    <Switch
                        checked={discountCodes.enable_no_discount}
                        onChange={() => {
                            setIsUpdated(true)
                            setDiscountCodes((prev) => ({
                                ...prev,
                                enable_no_discount: !prev.enable_no_discount,
                            }))
                        }}
                    />
                </Col>
                <Col span={24}>
                    <ChipInput
                        label='Discount Codes'
                        labelTooltip='To disable COD on certain discount code, add the code in below text input field. For example, If code ”autofixed20” is Enable/Disabled for COD. If the user adds a product to the cart and applies the code ”autofixed20”. Now COD will be removed from the payment options.'
                        labelTooltipPlacement='top'
                        placeholder='Press Enter to add discount codes'
                        disabled={!discountCodes.enable_no_discount}
                        chips={discountCodes.discount_code_config?.cod}
                        onAddChip={(chip) => {
                            if (!chip.trim()) return
                            setIsUpdated(true)
                            setDiscountCodes((prev) => ({
                                ...prev,
                                discount_code_config: {
                                    ...prev.discount_code_config,
                                    cod: [...(prev.discount_code_config?.cod || []), chip],
                                    cod_blocking: [
                                        ...(prev.discount_code_config?.cod_blocking || []),
                                        {
                                            code: chip,
                                            match_type: 1,
                                        },
                                    ],
                                },
                            }))
                        }}
                        onRemoveChip={(chip) => {
                            setIsUpdated(true)
                            setDiscountCodes((prev) => ({
                                ...prev,
                                discount_code_config: {
                                    ...prev.discount_code_config,
                                    cod: prev.discount_code_config.cod.filter((c) => c !== chip),
                                    cod_blocking: prev.discount_code_config.cod_blocking.filter((c) => c.code !== chip),
                                },
                            }))
                        }}
                    />
                </Col>
                <Col>
                    {showPopConfirm ? (
                        <Popconfirm
                            title='COD will be disabled for all discount codes. Are you sure?'
                            onConfirm={() => saveDiscountCodes()}
                        >
                            <Button variant='primary' disabled={!isUpdated}>
                                Save
                            </Button>
                        </Popconfirm>
                    ) : (
                        <Button variant='primary' disabled={!isUpdated} onClick={() => saveDiscountCodes()}>
                            Save
                        </Button>
                    )}
                </Col>
            </Row>
        </Col>
    )
}

export default BlockDiscountCodes
