import {
    Table,
    Switch,
    ColumnProps,
    Popconfirm,
    Tooltip,
    EyeOutlined,
    Divider,
    EditOutlined,
    DeleteOutlined,
} from 'gokwik-ui-kit'

const RulesTable = ({ title, rules, type, onView, onEdit, onDelete, onStatusChange }) => {
    const columns: ColumnProps<any>[] = [
        {
            title: `${type.charAt(0).toUpperCase() + type.slice(1)} Rule Name`,
            dataIndex: 'name',
            key: 'name',
            width: '30%',
        },
        {
            title: 'Rule Description',
            dataIndex: 'description',
            key: 'description',
            width: '45%',
        },
        {
            title: 'Status',
            key: 'enabled',
            fixed: 'right',
            render: (_, record) => <Switch checked={record.enabled} onChange={() => onStatusChange(record)} />,
        },
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right',
            render: (_, record) => (
                <div className='border opacity-60 rounded-md p-1 cursor-pointer'>
                    <Tooltip title={'View'}>
                        <EyeOutlined onClick={() => onView(record)} />
                    </Tooltip>
                    <Divider type='vertical' />

                    <Tooltip title={'Edit'}>
                        <EditOutlined onClick={() => onEdit(record)} />
                    </Tooltip>
                    <Divider type='vertical' />
                    <Popconfirm
                        title='Are you sure to delete this rule?'
                        okText='Yes'
                        okButtonProps={{ className: 'bg-error-500 hover:!bg-error-500 text-white' }}
                        cancelText='No'
                        onConfirm={() => onDelete(record)}
                    >
                        <Tooltip title={'Delete'}>
                            <DeleteOutlined className='text-error-500' />
                        </Tooltip>
                    </Popconfirm>
                </div>
            ),
        },
    ]
    return (
        <Table
            scroll={{
                x: 'max-content',
            }}
            dataSource={rules}
            columns={columns}
            pagination={false}
        />
    )
}
export default RulesTable
