import { message } from 'gokwik-ui-kit'

const discountFormValidations = (discountType, finalFormStateBeforeSubmit) => {
    if (finalFormStateBeforeSubmit?.discount_category === 'bulk' && !finalFormStateBeforeSubmit?.discount_codes_url) {
        message.error('Please upload or generate bulk discount codes')
        return
    }

    if (discountType === 'dynamic') {
        const rules = finalFormStateBeforeSubmit?.rules[0]
        if (!rules?.conditions[0]?.min_cart_value || !rules?.actions[0]?.value) {
            message.error('Please add atleast one value based condition')
            return
        }
    }

    if (discountType === 'quantity') {
        const rules = finalFormStateBeforeSubmit?.rules[0]
        if (
            !rules?.actions[0]?.discount_method ||
            !rules?.actions[0]?.value ||
            !rules?.conditions[0]?.min_product_quantity ||
            !(rules?.conditions[0]?.applicable_list?.length > 0 || rules?.conditions[0]?.product_variant_applicable_list?.length > 0)
        ) {
            message.error('Please add atleast one value based condition and relevant products')
            return
        }
    }

    if (discountType === 'buy_x_at_y') {
        const rules = finalFormStateBeforeSubmit?.rules[0]
        if (
            !rules?.actions[0]?.value ||
            !rules?.conditions[0]?.min_product_quantity ||
            !(rules?.conditions[0]?.applicable_list?.length > 0 || rules?.conditions[0]?.product_variant_applicable_list?.length > 0)
        ) {
            message.error('Quantity, Products & Value are required')
            return
        }
    }

    if (discountType === 'gift_with_product') {
        const rules = finalFormStateBeforeSubmit?.rules[0]

        if (
            !(rules?.conditions[0]?.min_product_amount || rules?.conditions[0]?.min_product_quantity) ||
            !(rules?.conditions[0]?.applicable_list?.length > 0 || rules?.conditions[0]?.product_variant_applicable_list?.length > 0)
        ) {
            message.error('Purchase Amount or Quantity & Products that the customer must buy are required')
            return
        }

        if (
            !rules?.actions[0]?.free_product_quantity ||
            !rules?.actions[0]?.applicable_list?.length
        ) {
            message.error('Quantity & Products that the customer will get are required')
            return
        }
    }

    return true
}

export { discountFormValidations }
