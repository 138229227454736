import React, { useEffect, useRef, useState } from 'react'
import { Input, Button, Space, Checkbox, MinusCircleOutlined, PlusCircleOutlined, message } from 'gokwik-ui-kit'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'

const SearchProduct = ({
    lineItems,
    setLineItems,
    placeholder,
    label,
}: {
    lineItems: any
    setLineItems: any
    placeholder?: string
    label?: string
}) => {
    const [searchProduct, setSearchProduct] = useState('')
    const [searchedSuggestions, setSearchedSuggestions] = useState([])
    const [newLineItems, setNewLineItems] = useState([])
    const [showSuggestions, setShowSuggestions] = useState(false)
    const [addBtnEnable, setAddBtnEnable] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const blurWrapperRef = useRef()

    const getProducts = async () => {
        await makeAPICall({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.productSearch}`,
            params: {
                name: searchProduct,
            },
            skipMode: true,
        })
            .then((res) => {
                const existingItems = lineItems.map((item) => item.variant_id)
                const filteredItems = res.data.data.filter((item) => !existingItems.includes(item.variant_id))
                if (filteredItems.length) setSearchedSuggestions([...filteredItems])
                else setErrorMessage('No items found')
            })
            .catch((err) => {
                message.error(err.response?.data?.message || 'Unable to fetch data')
            })
    }

    useEffect(() => {
        if (!searchProduct || searchProduct.length < 3) return
        const delayDebounceFn = setTimeout(() => {
            getProducts()
        }, 400)
        errorMessage && setErrorMessage('')
        return () => clearTimeout(delayDebounceFn)
    }, [searchProduct])

    const suggestionHandleChange = (e, currentProduct) => {
        const toggle = e.target.checked
        if (toggle) {
            setNewLineItems((prev) => [...prev, { ...currentProduct }])
            setAddBtnEnable(true)
        } else {
            const index = newLineItems.findIndex((product) => product.id === currentProduct.id)
            if (index < 0) return
            const tempLineItems = [...newLineItems]
            tempLineItems.splice(index, 1)
            setNewLineItems([...tempLineItems])
            !tempLineItems.length && setAddBtnEnable(false)
        }
    }
    const cancelSearch = () => {
        setNewLineItems([])
        setShowSuggestions(false)
        setSearchedSuggestions([])
        setSearchProduct('')
        setErrorMessage('')
    }
    const addLineItems = () => {
        if (!addBtnEnable) return
        cancelSearch()
        setLineItems((prev) => [
            ...(prev || []),
            ...newLineItems.map((item) => ({
                ...item,
                quantity: 1,
                addedFromSearch: true,
            })),
        ])
    }
    return (
        <div className='w-full  relative'>
            <Input
                className='max-h-96'
                placeholder={placeholder || 'Search Products by Name or Product ID'}
                label={label}
                value={searchProduct}
                onChange={(e) => {
                    setSearchProduct(e.target.value)
                    if (searchProduct.length >= 3) {
                        setShowSuggestions(true)
                    } else {
                        setShowSuggestions(false)
                    }
                }}
                onFocus={() => {
                    // mainContentRef.current.style.overflowY = 'hidden'
                    if (searchedSuggestions.length > 3) {
                        setShowSuggestions(true)
                    }
                }}
            />

            {showSuggestions && (
                <div className='absolute w-full z-[1] max-h-96 overflow-auto border-solid border-[0.5px] rounded-md border-gray-500 '>
                    {searchedSuggestions.map((item) => (
                        <div
                            key={item.id}
                            style={{ borderBottomStyle: 'solid' }}
                            className='flex items-center justify-between bg-white cursor-pointer hover:bg-gray-100 px-2 py-4   border-b-[0.5px] border-b-gray-300 '
                        >
                            <Space className='px-2'>
                                <Checkbox
                                    name={item.id}
                                    type={'checkbox'}
                                    checked={newLineItems.some((product) => product.id === item.id)}
                                    onChange={(e) => suggestionHandleChange(e, item)}
                                />
                                <img
                                    src={
                                        item.product_thumbnail_url ||
                                        'https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=webp&v=1530129081'
                                    }
                                    alt='Product Image'
                                    className='w-8 h-8 object-cover rounded-md'
                                />
                                <a href={item.product_url} target='_blank' className='margin-l10 text-primary '>
                                    {`${item.product_name} - ${item.variant_name}`}
                                </a>
                            </Space>
                            <Space>
                                <div className='px-3 py-2 gap-x-2 flex items-center'>
                                    <p>₹{(+item.price).toFixed(2)}</p>
                                </div>
                            </Space>
                        </div>
                    ))}
                    <div className='flex justify-end items-center bg-white px-2 py-2 sticky bottom-0'>
                        <Button variant='default' className='cancel-btn' onClick={cancelSearch}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            className={`save-btn ml-4 ${!addBtnEnable && 'disabled cursor-disabled'}`}
                            onClick={addLineItems}
                            disabled={!addBtnEnable}
                        >
                            Add
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default SearchProduct
