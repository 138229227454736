import React, { FC } from 'react'
import { DiscountFormState } from '../helpers/useCreateDiscount'
import { CardLayout } from './cardLayout'
import { Divider, Radio, Space, Row, Col, Input, Button, Form, Checkbox } from 'gokwik-ui-kit'

interface DiscountCombinationCardProps {
    formState?: DiscountFormState
}

const alertContent = {
    alertTitle: 'Some combinations can result in large discounts',
    alertMessage: (
        <span className='text-[#084426] text-[13px] font-normal'>
            Test a few combinations. If the total discount is too large, adjust which discounts can combine
            <p className='text-[#000000E0] text-sm font-normal'>
                <a
                    href='https://help.shopify.com/en/manual/discounts/combining-discounts'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    Learn More
                </a>
            </p>
        </span>
    ),
}

const Card: FC<DiscountCombinationCardProps> = (props) => {
    const { formState } = props
    return (
        <CardLayout
            title='Discounts can combine with other available discounts.Choose your combinations below'
            subtitle={`What discount categories should ${formState?.code} be clubbed with?`}
            alertContent={alertContent}
        >
            <Divider className='my-3' />
            <Form.Item name='combinations'>
                <Checkbox.Group>
                    <Row>
                        <Col span={24}>
                            <Checkbox value='shipping' style={{ lineHeight: '32px' }}>
                                Shipping Discounts
                            </Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value='product' style={{ lineHeight: '32px' }}>
                                Product Dicounts
                            </Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value='order' style={{ lineHeight: '32px' }}>
                                Order Discounts
                            </Checkbox>
                        </Col>
                    </Row>
                </Checkbox.Group>
            </Form.Item>
        </CardLayout>
    )
}

export const DiscountCombinationCard = React.memo(Card)
