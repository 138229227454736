import {
    Drawer,
    Form,
    Input,
    Switch,
    TextArea,
    Button,
    Modal,
    Row,
    Upload,
    PlusOutlined,
    message,
    InputNumber,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { makeAPICall } from '@gokwik/utilities'
import { useSelector } from 'react-redux'
import { isMerchantSelector } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import dayjs from 'dayjs'

function refundDrawer({ orderId, merchant_id, fullRefundAmount, onClose, openRefundDrawer }) {
    const [refundData, setRefundData] = useState({
        amount: fullRefundAmount,
        refund_request_file: null,
        refund_request_description: '',
    })
    const [isRefundTypeFull, setIsRefundTypeFull] = useState(true)
    const [proceed, setProceed] = useState(false)
    const isMerchantUser = useSelector(isMerchantSelector)

    useEffect(() => {
        setRefundData((prev) => ({ ...prev, amount: fullRefundAmount }))
    }, [fullRefundAmount])

    const [form] = Form.useForm()
    const onRefundTypeChange = (checked: boolean) => {
        setIsRefundTypeFull(checked)
    }

    const handleAmountChange = (inputValue) => {
        setRefundData((prev) => ({ ...prev, amount: inputValue }))
    }

    const onDescriptionChange = (e) => {
        const { value: inputValue } = e.target
        setRefundData((prev) => ({ ...prev, refund_request_description: inputValue }))
    }

    const onCheck = async () => {
        try {
            const values = await form.validateFields()
            setProceed(true)
        } catch (errorInfo) {
            message.error(errorInfo.errorFields?.[0].errors?.[0])
        }
    }

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    const initiateRefund = async () => {
        try {
            let fileUrl = ''
            if (refundData.refund_request_file && !isMerchantUser) {
                const presignedUrlResponse = await makeAPICall({
                    url: process.env.REACT_APP_BASE_URL + APIEndPoints.refundPresignedUrl,
                    method: 'get',
                    params: {
                        url: `${merchant_id}/${new Date().getTime()}-${refundData.refund_request_file?.fileName
                            ?.split(' ')
                            .join('-')}`,
                    },
                })
                if (presignedUrlResponse.error) {
                    message.error('Unable to upload document')
                    return
                }
                fileUrl = presignedUrlResponse.data.data
                fetch(fileUrl, {
                    method: 'PUT',
                    body: refundData.refund_request_file.file,
                })
            }

            const response = await makeAPICall({
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.initiateRefund,
                method: 'post',
                payload: {
                    id: orderId,
                    amount: `${refundData.amount}`,
                    ...(!isMerchantUser && {
                        ...(refundData.refund_request_file && { refund_request_file: fileUrl }),
                        ...(refundData.refund_request_description && {
                            refund_request_description: refundData.refund_request_description,
                        }),
                    }),
                },
            })

            if (response?.success) {
                message.success(response.data.message || 'Refund Initiated Successfully!')
                onClose(true)
                setProceed(false)
            } else {
                message.error(response.response?.data?.message || 'Something went wrong. Try again later!')
            }
        } catch (err) {
            console.warn(err)
        }
    }

    return (
        <>
            <Drawer
                title='Select your refund type'
                placement='right'
                onClose={() => onClose(false)}
                open={openRefundDrawer}
            >
                <Form layout={'vertical'} form={form} itemProp=''>
                    <Form.Item name='partialRefund' valuePropName='checked' label='Refund Type'>
                        <Switch
                            className='bg-primary-500'
                            checkedChildren='Full Refund'
                            unCheckedChildren='Partial Refund'
                            defaultChecked
                            onChange={onRefundTypeChange}
                        />
                    </Form.Item>

                    <Form.Item
                        rules={
                            isRefundTypeFull
                                ? []
                                : [
                                      {
                                          required: isRefundTypeFull ? true : false,
                                          message: 'Please enter refund amount',
                                      },

                                      {
                                          validator: (_, value) => {
                                              if (value && Number(value) <= Number(fullRefundAmount)) {
                                                  return Promise.resolve()
                                              }
                                              return Promise.reject(
                                                  `Partial refund amount must be below ₹ ${fullRefundAmount}`,
                                              )
                                          },
                                      },
                                  ]
                        }
                        name='refundAmount'
                        valuePropName='input'
                        label={isRefundTypeFull ? 'Full Refund Amount' : 'Please Enter Partial Refund Amount'}
                    >
                        <InputNumber
                            className='w-full'
                            prefix={'₹'}
                            placeholder={isRefundTypeFull ? '' : `Enter amount less than ${fullRefundAmount}`}
                            value={refundData.amount}
                            disabled={isRefundTypeFull}
                            onChange={(e) => handleAmountChange(e)}
                        />
                    </Form.Item>
                    {!isMerchantUser && (
                        <>
                            <Form.Item
                                rules={[
                                    ({ getFieldValue }) => ({
                                        required: !getFieldValue('refundFileUpload'), // Disable required validation if refundDetails is not empty
                                        message: 'Please enter refund details',
                                    }),
                                    {
                                        max: 300,
                                        message: 'Refund details must not exceed 300 characters',
                                    },
                                    {
                                        min: 30,
                                        message: 'Refund details should be at least 30 characters',
                                    },
                                ]}
                                name='refundDescription'
                                label='Merchant Refund Request Description'
                            >
                                <TextArea
                                    onChange={onDescriptionChange}
                                    value={refundData.refund_request_description}
                                    label=''
                                    placeholder='Enter Description'
                                />
                            </Form.Item>

                            <Form.Item
                                label='Merchant Refund Request Doc'
                                valuePropName='fileList'
                                rules={[
                                    ({ getFieldValue }) => ({
                                        required: !getFieldValue('refundDescription'), // Disable required validation if refundDetails is not empty
                                        message: 'Please upload a file',
                                    }),

                                    ({ getFieldValue }) => ({
                                        validator: (_, value) => {
                                            return value?.[0]?.size / 1024 ** 2 < 5 ||
                                                (!value?.[0] && getFieldValue('refundDescription'))
                                                ? Promise.resolve()
                                                : Promise.reject(new Error('File size should not be more than 5 MB'))
                                        },
                                    }),
                                ]}
                                getValueFromEvent={normFile}
                                name='refundFileUpload'
                            >
                                <Upload
                                    beforeUpload={(file) => {}}
                                    listType='text'
                                    maxCount={1}
                                    className='w-full'
                                    customRequest={async (options) => {
                                        const { onSuccess, onError, file, onProgress } = options
                                        const { size, name } = file as any
                                        var data = new FormData()
                                        data.append('file', file)
                                        setRefundData((prev) => ({
                                            ...prev,
                                            refund_request_file: { file: data, fileName: name },
                                        }))
                                        if (size / 1024 ** 2 >= 5) {
                                            onError({ name: 'error', message: 'File upload error' })
                                        } else onSuccess(data)
                                    }}
                                    accept='.doc,.docx,.png,.jpg,.jpeg,.pdf'
                                >
                                    <Button variant='default' className='flex items-center gap-1'>
                                        <PlusOutlined />
                                        <div>Upload</div>
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </>
                    )}

                    <Form.Item className='w-full' name='submit' valuePropName='button'>
                        <div className='w-full flex justify-between'>
                            <Button className='w-[49%]' variant='primary' onClick={onCheck}>
                                Submit
                            </Button>
                            <Button className='w-[49%]' variant='default' onClick={() => onClose(false)}>
                                Cancel
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>

            <Modal
                title={<p>Are your sure, you want to refund ?</p>}
                open={proceed ? true : false}
                onOk={() => {
                    initiateRefund()
                    setProceed(false)
                }}
                onCancel={() => {
                    setProceed(false)
                }}
                centered
                width={'450px'}
                footer={() => (
                    <Row className='flex justify-end gap-2'>
                        <Button onClick={() => setProceed(null)} variant='default'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                initiateRefund()
                                setProceed(null)
                            }}
                            variant='primary'
                        >
                            Confirm
                        </Button>
                    </Row>
                )}
            >
                <h3 className='mt-1'>Order Id {orderId}</h3>
            </Modal>
        </>
    )
}

export default refundDrawer
