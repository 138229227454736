import {
    Button,
    Card,
    Col,
    Drawer,
    Form,
    InfoCircleFilled,
    InfoCircleOutlined,
    message,
    QuestionCircleOutlined,
    Row,
    Switch,
    Tooltip,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import BookDownload from '@library/images/book-download.svg'
import Shopify from '@library/images/PixelIcons/reMarketingTools/shopify.svg'
import QuickSilver from '@library/images/PixelIcons/quicksilver.svg'
import Wallet from '@library/images/PixelIcons/wallet.svg'
import Gyftr from '@library/images/PixelIcons/gyftr.svg'
import RenderForm from '@library/components/integrations/formRender'
import { selectMerchantCreds, selectMerchantRewards } from '@store/integrations/selectors'
import { useSelector } from 'react-redux'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { useAppDispatch } from '@library/utilities/hooks'
import { MerchantConfig } from '@library/utilities/interface'
import { saveCreds, saveRewards } from '@store/integrations'
import { deepEqualAndDiff } from '@gokwik/utilities'

const GiftCardsVouchers = () => {
    const [drawer, setDrawer] = useState(false)
    const [title, setTitle] = useState('')
    const [selectedKey, setSelectedKey] = useState('')
    const [form] = Form.useForm()
    const { config, refetch, saveConfig } = useMerchantConfigApi()
    const customConfig = config?.custom_config
    const merchantRewards = useSelector(selectMerchantRewards)
    const merchantCreds = useSelector(selectMerchantCreds)

    const dispatch = useAppDispatch()
    const handleSwitchChange = (key, checked, item) => {
        if (
            checked &&
            config.reward_providers?.gyftr &&
            (key === 'store_credits' || GiftCards.some((giftCard) => giftCard.key === key))
        ) {
            message.error('Disable Gyftr before enabling any other credits.')
            return
        }
        if (
            checked &&
            StoreCredit.every((credit) => credit.key !== key) &&
            InternalWallet.every((credit) => credit.key !== key) &&
            GiftCards.some((giftCard) => giftCard.key !== key && giftCard.checked)
        ) {
            message.error('Only one gift card provider can be active at a time.')
            return
        }
        if (
            checked &&
            key === 'gyftr' &&
            (config?.store_credits?.shopify?.enabled || config.custom_config?.gift_cards_enabled)
        ) {
            message.error('Disable other credits before enabling Gyftr.')
            return
        }
        if (checked && item.customize) {
            setSelectedKey(key)
            setDrawer(true)
            setTitle(item.title)
        } else {
            const payload: Partial<MerchantConfig> = {}
            if (key === 'store_credits') {
                payload['store_credits'] = {
                    ...config.store_credits,
                    shopify: {
                        ...config.store_credits.shopify,
                        enabled: checked,
                    },
                }
            } else if (key == 'internal_wallet') {
                payload['store_credits'] = {
                    ...config?.store_credits,
                    internal_wallet: {
                        ...config?.store_credits?.internal_wallet,
                        enabled: checked,
                    },
                }
            } else if (key === 'gyftr') {
                payload['reward_providers'] = {
                    ...config.reward_providers,
                    gyftr: checked,
                }
            } else {
                payload['custom_config'] = {
                    ...config.custom_config,
                    gift_cards_enabled: checked,
                }
            }
            saveConfig(payload)
        }
    }

    const handleCustomizeClick = (key, title) => {
        setSelectedKey(key)
        setTitle(title)
        setDrawer(true)
    }
    const InternalWallet = [
        {
            key: 'internal_wallet',
            title: 'Internal Wallet',
            icon: Wallet,
            checked: config?.store_credits?.internal_wallet?.enabled,
            customize: true,
        },
    ]

    const GiftCards = [
        {
            key: 'giftCard',
            title: 'Shopify Gift Card',
            icon: Shopify,
            checked: config?.custom_config.gift_cards_enabled && config?.custom_config.gift_card_provider === 'shopify',
            customize: true,
        },
        {
            key: 'qwik_cilver',
            title: 'Qwickcilver',
            checked:
                config?.custom_config.gift_cards_enabled && config?.custom_config.gift_card_provider === 'qwik_cilver',
            icon: QuickSilver,
            customize: true,
        },
    ]
    const Voucher = [
        {
            key: 'gyftr',
            title: 'Gyftr',
            checked: config?.reward_providers?.gyftr,
            icon: Gyftr,
            customize: true,
        },
    ]
    const StoreCredit = [
        {
            key: 'store_credits',
            title: 'Shopify Store Credits',
            checked: config?.store_credits?.shopify?.enabled,
            icon: Shopify,
            customize: true,
        },
    ]
    const handleSave = async () => {
        const isKeyChecked = [...GiftCards, ...Voucher, ...StoreCredit, ...InternalWallet].find(
            (item) => item.key === selectedKey,
        ).checked
        try {
            if (!form.isFieldsTouched() && isKeyChecked) {
                setDrawer(false)
                return
            }
            await form.validateFields()

            const { custom_config = {}, merchant_config = {}, ...rest } = form.getFieldsValue()
            if (custom_config.hasOwnProperty('enable_discount_with_giftcard')) {
                custom_config.disable_discount_with_giftcard = !custom_config.enable_discount_with_giftcard
                delete custom_config.enable_discount_with_giftcard
            }

            if (selectedKey === 'gyftr' && !config.reward_providers?.gyftr) {
                merchant_config.reward_providers = {
                    ...config.reward_providers,
                    gyftr: true,
                }
            } else if (
                selectedKey === 'qwik_cilver' &&
                (!customConfig.gift_cards_enabled || customConfig.gift_card_provider !== 'qwik_cilver')
            ) {
                custom_config.gift_cards_enabled = true
                custom_config.gift_card_provider = 'qwik_cilver'
            } else if (
                selectedKey === 'giftCard' &&
                (!customConfig.gift_cards_enabled || customConfig.gift_card_provider !== 'shopify')
            ) {
                custom_config.gift_cards_enabled = true
                custom_config.gift_card_provider = 'shopify'
            } else if (selectedKey === 'store_credits') {
                merchant_config.store_credits = {
                    ...(config.store_credits || {}),
                    shopify: {
                        ...(config?.store_credits?.shopify || {}),
                        enabled: true,
                        maximum_redeem_amount: merchant_config.store_credits?.shopify?.maximum_redeem_amount
                            ? '' + merchant_config.store_credits?.shopify?.maximum_redeem_amount
                            : null,
                    },
                }
                delete merchant_config.store_credits.internal_wallet
            } else if (selectedKey === 'internal_wallet') {
                merchant_config.store_credits = {
                    ...config.store_credits,
                    internal_wallet: {
                        ...(config?.store_credits?.internal_wallet || {}),
                        enabled: true,
                        maximum_redeem_amount: merchant_config.store_credits?.internal_wallet?.maximum_redeem_amount
                            ? '' + merchant_config.store_credits?.internal_wallet?.maximum_redeem_amount
                            : null,
                        provider: merchant_config.store_credits?.internal_wallet?.provider,
                        tag: merchant_config.store_credits?.internal_wallet?.tag,
                        gateway_name: merchant_config.store_credits?.internal_wallet?.gateway_name,
                    },
                }
                delete merchant_config.store_credits.shopify
            }
            const payload = rest[selectedKey]
            let res
            if (payload && selectedKey === 'internal_wallet') {
                res = dispatch(
                    saveCreds({
                        [selectedKey.toLowerCase()]: payload,
                    }),
                )
                if (!res) {
                    message.error('Failed to save credentials')
                }
            }
            if (['gyftr', 'qwik_cilver'].includes(selectedKey)) {
                res = dispatch(
                    saveRewards({
                        ...payload,
                        reward_type: selectedKey === 'qwik_cilver' ? ['gift_card'] : ['reward', 'rechargable'],
                        provider: selectedKey,
                        brand_key: selectedKey === 'qwik_cilver' ? 'qwik_cilver' : payload.brand_key,
                    }),
                )
            }
            if (res || !payload) {
                const payload = deepEqualAndDiff(config, {
                    custom_config: {
                        ...customConfig,
                        ...custom_config,
                    },
                    ...merchant_config,
                })
                if (!payload.isEqual && Object.values(payload.diff).some((value) => value !== undefined)) {
                    saveConfig(payload.diff, () => {
                        setDrawer(false)
                    })
                } else {
                    setDrawer(false)
                }
            } else {
                setDrawer(false)
            }
        } catch (e) {
            console.log(e)
        }
    }
    const populateForm = () => {
        if (merchantCreds) {
            form.setFieldsValue({
                internal_wallet: {
                    url: merchantCreds?.internal_wallet?.url,
                    customer_identifier: merchantCreds?.internal_wallet?.customer_identifier,
                    auth_token: merchantCreds?.internal_wallet?.auth_token,
                },
            })
        }
        if (merchantRewards) {
            form.setFieldsValue({
                gyftr: merchantRewards.reward_providers.find((provider) => provider.type === 'gyftr'),
                qwik_cilver: merchantRewards.reward_providers.find((provider) => provider.type === 'qwik_cilver'),
            })
        }
        const disableKeys = ['disable_discount_with_giftcard']
        if (!config) return

        form.setFieldsValue({
            custom_config: {
                enable_prepaid_discount_with_gift_card: customConfig.enable_prepaid_discount_with_gift_card,
                credits_display_name: customConfig.credits_display_name,
                ...Object.keys(customConfig).reduce((acc, key) => {
                    if (disableKeys.includes(key)) {
                        acc[`enable_${key.replace('disable_', '')}`] = !customConfig[key]
                    }

                    return acc
                }, {}),
            },
            merchant_config: {
                ...config,
            },
        })
    }
    useEffect(() => {
        populateForm()
    }, [config, merchantRewards, merchantCreds])
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <div className='flex items-center text-lg leading-7'>
                        <p className='text-lg font-semibold'>Gift Cards & Vouchers</p>
                    </div>
                </Col>
                <Col span={24}>
                    <h4 className='font-semibold text-base mb-1 mt-0'>Voucher</h4>
                </Col>
                {Voucher.map((item) => (
                    <Col span={24} key={item.key}>
                        <Card className='custom-card'>
                            <Row align='middle' justify='space-between'>
                                <Col flex='40px'>
                                    <img src={item.icon} alt={item.title} className='h-10 w-10' />
                                </Col>
                                <Col flex='auto' className='pl-4'>
                                    <p className='font-semibold mb-1'>{item.title}</p>
                                </Col>
                                <Col className='text-right'>
                                    {item.customize && item.checked && (
                                        <Button
                                            className='mr-4 focus:outline-none'
                                            onClick={() => handleCustomizeClick(item.key, item.title)}
                                        >
                                            Customise
                                        </Button>
                                    )}
                                    <Switch
                                        className='focus:outline-none'
                                        checked={item.checked}
                                        disabled={
                                            config?.store_credits?.shopify?.enabled ||
                                            config?.custom_config?.gift_cards_enabled ||
                                            config?.store_credits?.internal_wallet?.enabled
                                        }
                                        onChange={(checked) => handleSwitchChange(item.key, checked, item)}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}
                <Col span={24}>
                    <h4 className='font-semibold text-base mb-1 mt-0'>
                        Internal Wallet
                        <Tooltip title='Configure any internally built refund or loyalty wallet at your end to allow redemption on Checkout.'>
                            <QuestionCircleOutlined className='ml-1' />
                        </Tooltip>
                    </h4>
                    <p className='bg-blue-50 w-full px-4 py-2 rounded-md flex items-center'>
                        <InfoCircleFilled className='text-blue-500 mr-2' />
                        Internal Wallet cannot be enabled with Gyftr or Store Credits.
                    </p>
                </Col>

                {InternalWallet.map((item) => (
                    <Col span={24} key={item.key}>
                        <Card className='custom-card'>
                            <Row align='middle' justify='space-between'>
                                <Col flex='40px'>
                                    <img src={item.icon} alt={item.title} className='h-8 w-8' />
                                </Col>
                                <Col flex='auto' className='pl-4'>
                                    <p className='font-semibold mb-1'>{item.title}</p>
                                </Col>
                                <Col className='text-right'>
                                    {item.customize && item.checked && (
                                        <Button
                                            className='mr-4 focus:outline-none'
                                            onClick={() => handleCustomizeClick(item.key, item.title)}
                                        >
                                            Customise
                                        </Button>
                                    )}
                                    <Switch
                                        className='focus:outline-none'
                                        disabled={
                                            config?.store_credits?.shopify?.enabled || config?.reward_providers?.gyftr
                                        }
                                        checked={config?.store_credits?.internal_wallet?.enabled}
                                        onChange={(checked) => handleSwitchChange(item.key, checked, item)}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}

                <Col span={24}>
                    <h4 className='font-semibold text-base mb-1 mt-0'>Store Credits</h4>
                    <p className='bg-blue-50 w-full px-4 py-1 rounded-md flex items-center'>
                        <InfoCircleFilled className='text-blue-500 mr-2' />
                        Store Credits cannot be enabled with Gyftr or Internal Wallet. Shopify store credits does not
                        work for Shopify basic plan brands.
                    </p>
                </Col>
                {StoreCredit.map((item) => (
                    <Col span={24} key={item.key}>
                        <Card className='custom-card'>
                            <Row align='middle' justify='space-between'>
                                <Col flex='40px'>
                                    <img src={item.icon} alt={item.title} className='h-10 w-10' />
                                </Col>
                                <Col flex='auto' className='pl-4'>
                                    <p className='font-semibold mb-1'>{item.title} </p>
                                </Col>
                                <Col className='text-right'>
                                    {item.customize && item.checked && (
                                        <Button
                                            className='mr-4 focus:outline-none'
                                            onClick={() => handleCustomizeClick(item.key, item.title)}
                                        >
                                            Customise
                                        </Button>
                                    )}
                                    <Switch
                                        className='focus:outline-none'
                                        checked={item.checked}
                                        disabled={
                                            config?.reward_providers?.gyftr ||
                                            config?.store_credits?.internal_wallet?.enabled
                                        }
                                        onChange={(checked) => handleSwitchChange(item.key, checked, item)}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}

                <Col span={24}>
                    <h4 className='font-semibold text-base mb-1 mt-0'>Gift Cards</h4>
                    <p className='bg-blue-50 w-full px-4 py-1 rounded-md flex items-center'>
                        <InfoCircleFilled className='text-blue-500 mr-2' />
                        Gift Cards cannot be enabled with Gyftr. Only one Gift Card provider can be active at a time.
                    </p>
                </Col>
                {GiftCards.map((item) => (
                    <Col span={24} key={item.key}>
                        <Card className='custom-card'>
                            <Row align='middle' justify='space-between'>
                                <Col flex='40px'>
                                    <img src={item.icon} alt={item.title} className='h-10 w-10' />
                                </Col>
                                <Col flex='auto' className='pl-4'>
                                    <p className='font-semibold mb-1'>{item.title}</p>
                                </Col>
                                <Col className='text-right'>
                                    {item.customize && item.checked && (
                                        <Button
                                            className='mr-4 focus:outline-none'
                                            onClick={() => handleCustomizeClick(item.key, item.title)}
                                        >
                                            Customise
                                        </Button>
                                    )}
                                    <Switch
                                        className='focus:outline-none'
                                        disabled={config?.reward_providers?.gyftr}
                                        checked={item.checked}
                                        onChange={(checked) => handleSwitchChange(item.key, checked, item)}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Drawer
                title={title}
                width={500}
                open={drawer}
                onClose={() => setDrawer(false)}
                footer={
                    <div className='flex justify-end space-x-4'>
                        <Button
                            type='default'
                            onClick={() => {
                                populateForm()
                                setDrawer(false)
                            }}
                        >
                            Discard
                        </Button>
                        <Button type='primary' onClick={handleSave}>
                            Save Changes
                        </Button>
                    </div>
                }
            >
                <RenderForm fieldKey={selectedKey} form={form} />
            </Drawer>
        </>
    )
}

export default GiftCardsVouchers
