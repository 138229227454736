import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { OfferAndDiscount } from '@library/utilities/interface'
import { ArrowLeftOutlined, Button, Col, Modal, PlusOutlined, Row, message } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DiscountList from './discountsList'
import DiscountSuggestionForm from './discountSuggestionForm'
import useShopifyMerchantConfigApi from '@library/utilities/hooks/useShopifyMerchantConfig'
import { ShopifyAppDiscountOffersSortKeyEnum } from '@library/utilities/types'
import { ICommonEvents } from '@library/utilities/helpers/eventsHelper'

const DiscountSuggestions = (props: {events: ICommonEvents}) => {
    const navigate = useNavigate()
    const { tourStepsEvents, configsUpsert } = props.events;
    const [openDiscountSuggestionForm, setOpenDiscountSuggestionForm] = useState<boolean>(false)
    const [discountSuggestionFormTitle, setDiscountSuggestionFormTitle] = useState<string>('')
    const [selectedDiscount, setSelectedDiscount] = useState<Partial<OfferAndDiscount>>(null)
    const [discountCodes, setDiscountCodes] = useState<Partial<OfferAndDiscount>[]>([])
    const [highlightedDiscountObj, setHighlightedDiscountObj] = useState<OfferAndDiscount>()
    const { config } = useShopifyMerchantConfigApi({
        useCache: true,
    })

    const isNonPlusMerchant = config?.isNonPlusMerchant || false
    const [changeReordering, setChangeReordering] = useState(false)
    const [action, setAction] = useState({
        name: '',
    })

    const fetchDiscountCodes = async (updateSortOrder = false) => {
        const params = {
            page: 1,
            pageSize: 150,
        }
        const res = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.offersAndDiscounts,
            params,
        })
        if (res.success) {
            if (res.data?.data?.merchantDiscounts) {
                const data = [...res.data?.data?.merchantDiscounts]
                setDiscountCodes(data.sort((a, b) => a?.sort_order - b?.sort_order))
                setHighlightedDiscountObj(res.data?.data?.merchantDiscounts?.find((dis) => dis?.highlighted) || {})
                if (updateSortOrder) {
                    await updateDiscountsOrder(data)
                }
            } else {
                setDiscountCodes([])
            }
        }
    }

    const postDiscountCall = async (discount: Partial<OfferAndDiscount>) => {
        if (!discount?.sort_order && !discount?.is_deleted) {
            discount['sort_order'] =
                discountCodes.sort((a, b) => a?.sort_order - b?.sort_order)[discountCodes?.length - 1]?.sort_order +
                    1 || 1
        }
        let discountId = discount.id
        delete discount.id
        delete discount.m_id
        delete discount.created_at
        delete discount.updated_at
        const response = await makeAPICall({
            method: 'post',
            url:
                process.env.REACT_APP_BASE_URL +
                APIEndPoints.offersAndDiscounts +
                (discountId ? `/${discountId}` : '') +
                '?checkout_platform=kwik_checkout',
            payload: {
                ...discount,
                ...(!discount.is_deleted && { min_price: discount.min_price ?? 0 }),
                ...(!discount.is_deleted && { min_quantity: discount.min_quantity ?? 0 }),
            },
            skipMode: true,
        })
        return response
    }

    const updateAllDiscountCodes = async (discountCodesList: Partial<OfferAndDiscount>[]) => {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.offersAndDiscountsAll,
            payload: {
                discount_codes: discountCodesList,
            },
            skipMode: true,
        })
        if (response.success) {
            fetchDiscountCodes()
            message.success(response.data.data || 'Discounts order updated successfully')
            setChangeReordering(false)
        }
        if (response.error) {
            message.error(response.response?.data?.error?.message || 'Unable to update discounts order')
        }
    }

    function fireEvent(type, action, discount = null, errorMessage = null) {
        if (type === 'success') {
            if (action === 'add') {
                configsUpsert.add.fireSuccessEvent()
            } else if (action === 'delete') {
                configsUpsert.delete.fireSuccessEvent()
            } else {
                configsUpsert.edit.fireSuccessEvent()
            }
        } else if (type === 'failure') {
            if (action === 'add') {
                configsUpsert.add.fireFailureEvent({
                    errorMessage
                })
            } else if (action === 'delete') {
                configsUpsert.delete.fireFailureEvent({
                    errorMessage
                })
            } else {
                configsUpsert.edit.fireFailureEvent({
                    errorMessage
                })
            }
        } else {
            if (action === 'add') {
                configsUpsert.add.fireClickedEvent({
                    currentConfig: discount
                })
            } else if (action === 'delete') {
                configsUpsert.delete.fireClickedEvent({
                    currentConfig: discount
                })
            } else {
                configsUpsert.edit.fireClickedEvent({
                    currentConfig: discount
                })
            }
        }
    }

    const updateDiscount = async (discount: Partial<OfferAndDiscount>, action: string) => {
        fireEvent('clicked', action, discount);
        if (discount.additional_details)
            discount.additional_details.bullet_points =
                typeof discount?.additional_details?.bullet_points === 'string'
                    ? discount?.additional_details?.bullet_points.split(',').map((value) => value.trim())
                    : discount?.additional_details?.bullet_points
        const response = await postDiscountCall({ ...discount })
        if (discount?.highlighted && highlightedDiscountObj?.highlighted && response?.data?.success) {
            const newRes = await postDiscountCall({ ...highlightedDiscountObj, highlighted: false })
            if (!newRes?.data?.success) {
                await postDiscountCall({ ...discount, highlighted: discount?.highlighted ? false : true })
            }
        }
        if (response.error) {
            fireEvent('failure', action, null, response.response?.data?.error?.message ||
            `Unable to ${action === 'delete' ? 'delete' : 'save'} discount`,);
            message.error(
                response.response?.data?.error?.message ||
                    `Unable to ${action === 'delete' ? 'delete' : 'save'} discount`,
            )
            return
        } else {
            fireEvent('success', action);
            message.success(
                response.response?.data?.message ||
                    response.response?.data?.data ||
                    `Discount ${action === 'delete' ? 'deleted' : 'saved'} successfully`,
            )
            setOpenDiscountSuggestionForm(false)
        }
        await fetchDiscountCodes(action === 'add')
    }

    const updateDiscountsOrder = async (discountCodesList: Partial<OfferAndDiscount>[]) => {
        try {
            const res = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.shopifyMerchantConfigs,
                payload: {
                    discountSuggestionSortKey: ShopifyAppDiscountOffersSortKeyEnum.SORT_INDEX,
                    discountSuggestionSortOrder: discountCodesList.reduce((acc, discount, index) => {
                        if (discount.discount_code) {
                            acc[discount.discount_code] = index + 1
                        }
                        return acc
                    }, {} as Record<string, number>),
                },
            })
            if (res.success) {
                updateAllDiscountCodes(discountCodesList)
                configsUpsert.edit.fireSuccessEvent({
                    currentDiscountSortOrder: discountCodesList.reduce((acc, discount, index) => {
                        if (discount.discount_code) {
                            acc[discount.discount_code] = index + 1
                        }
                        return acc
                    }, {}),
                    prevDiscountSortOrder: discountCodes.reduce((acc, discount, index) => {
                        if (discount.discount_code) {
                            acc[discount.discount_code] = index + 1
                        }
                        return acc
                    }, {})
                })
            } else {
                message.error(res.response?.data?.message || 'Unable to update discounts order')
                console.error('Unable to update discounts order:', res.response?.data?.message)
                configsUpsert.edit.fireFailureEvent({
                    currentDiscountSortOrder: discountCodesList.reduce((acc, discount, index) => {
                        if (discount.discount_code) {
                            acc[discount.discount_code] = index + 1
                        }
                        return acc
                    }, {}),
                    prevDiscountSortOrder: discountCodes.reduce((acc, discount, index) => {
                        if (discount.discount_code) {
                            acc[discount.discount_code] = index + 1
                        }
                        return acc
                    }, {}),
                    errorMessage: "Failed to update discounts order"
                })
            }
        } catch (error) {
            message.error('Unable to update discounts order')
            console.error('Unable to update discounts order:', error)
            configsUpsert.edit.fireFailureEvent({
                currentDiscountSortOrder: discountCodesList.reduce((acc, discount, index) => {
                    if (discount.discount_code) {
                        acc[discount.discount_code] = index + 1
                    }
                    return acc
                }, {}),
                prevDiscountSortOrder: discountCodes.reduce((acc, discount, index) => {
                    if (discount.discount_code) {
                        acc[discount.discount_code] = index + 1
                    }
                    return acc
                }, {}),
                errorMessage: error?.message || "Failed to update discounts order"
            })
        }
    }

    const handleActionButtonClick = (actionName, discount) => {
        setAction({
            name: actionName,
        })
        setSelectedDiscount(discount)

        if (actionName === 'delete') {
            updateDiscount({ id: discount.id, discount_code: discount.discount_code, is_deleted: true }, 'delete')
        } else {
            if (actionName === 'edit') {
                setDiscountSuggestionFormTitle('Edit Discount')
            } else if (actionName === 'view') {
                setDiscountSuggestionFormTitle(`${discount?.discount_code}`)
            } else if (actionName === 'add') {
                setDiscountSuggestionFormTitle(`Add New Discount`)
            }
            setOpenDiscountSuggestionForm(true)
        }
    }

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'checkout_settings',
                href: '/checkout/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'ui-customisation',
                href: '/checkout/settings/ui-customisation',
                text: 'UI Customisation',
            },
            {
                key: 'discount-suggestions',
                href: '/checkout/settings/ui-customisation/discount-suggestions',
                text: 'Discount Suggestions',
            },
        ])
        fetchDiscountCodes()
    }, [])

    return (
        <div>
            <Row className='rounded-sm p-2' gutter={[0, 24]} justify={'space-between'}>
                <Col span={16}>
                    <div className='flex items-top'>
                        <Button className='bg-gray-200 border-none' onClick={() => navigate(-1)}>
                            <ArrowLeftOutlined />
                        </Button>
                        <div className='ml-2'>
                            <p className='text-base font-semibold'>Discount Suggestions</p>
                            <p>
                                The below list shows the discounts available in the suggestion box on shopify checkout
                                page
                            </p>
                        </div>
                    </div>
                </Col>
                <Col>
                    {changeReordering ? (
                        <div className='flex items-center gap-x-4'>
                            <Button
                                onClick={() => {
                                    setChangeReordering(false)
                                    fetchDiscountCodes()
                                }}
                            >
                                Discard
                            </Button>
                            <Button
                                type='primary'
                                onClick={() => {
                                    updateDiscountsOrder(discountCodes)
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    ) : (
                        <div className='flex items-center gap-x-4'>
                            <Button type='primary' onClick={() => handleActionButtonClick('add', null)}>
                                <PlusOutlined /> Add New Coupon
                            </Button>
                        </div>
                    )}
                </Col>

                <Col span={24}>
                    <DiscountList
                        discountCodes={discountCodes}
                        setDiscountCodes={setDiscountCodes}
                        draggable={true}
                        handleActionButtonClick={(action, discount) => handleActionButtonClick(action, discount)}
                        isNonPlusMerchant={isNonPlusMerchant}
                        setChangeReordering={setChangeReordering}
                    />
                </Col>
            </Row>

            {openDiscountSuggestionForm && (
                <Modal
                    title={discountSuggestionFormTitle}
                    footer={null}
                    onCancel={() => setOpenDiscountSuggestionForm(false)}
                    open={openDiscountSuggestionForm}
                    centered
                    width={'700px'}
                >
                    <DiscountSuggestionForm
                        discount={selectedDiscount}
                        preHighlightedDiscount={highlightedDiscountObj}
                        action={action}
                        setShowModal={setOpenDiscountSuggestionForm}
                        saveDiscount={(discount, action) => updateDiscount(discount, action)}
                    />
                </Modal>
            )}
        </div>
    )
}

export default DiscountSuggestions
