import React, { useEffect, useState } from 'react'
import { Col, Row, Select, Form, Input, Button, message, Alert, InputNumber } from 'gokwik-ui-kit'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { customAlphabet } from 'nanoid'
const shipping_uuid_Generator = () => {
    const generateShippingId = customAlphabet('0123456789', 4)
    return `#${generateShippingId()}`
}

const AddShippingRateForm = ({ setShowModal, configJSON, setConfigJSON, action }) => {
    const [form] = Form.useForm()

    useEffect(() => {}, [action])

    const initialValues = () => {
        const shipping_prices = configJSON?.shipping_prices?.find((item) => item.id === action.id)
        if (action.name === 'edit' || action.name === 'view') {
            return {
                presentment_name: shipping_prices?.presentment_name,
                price: Number(shipping_prices?.price),
                min: Number(shipping_prices?.min),
                max: Number(shipping_prices?.max),
                payment_options: shipping_prices?.payment_options,
            }
        }
    }

    const addShippingMethod = async (values) => {
        const temp = configJSON
        temp?.shipping_prices?.push({
            id: configJSON?.shipping_prices?.length + 1,
            presentment_name: values.presentment_name,
            price: values.price,
            min: Number(values.min) ||0,
            max: Number(values.max) || Number.MAX_SAFE_INTEGER,
            pincodes: [],
            shipping_uuid: shipping_uuid_Generator(),
            pincodes_defined: false,
            payment_options: values.payment_options,
        })
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.merchantConfigs,
                payload: temp,
            })

            if (response.data.status_code === 200) {
                form.resetFields()
                message.success('Shipping rate added successfully')
            }
        } catch (error) {
            console.error('Error:', error)
            message.error('Failed to add shipping rate')
        }
    }

    const editShippingMethod = async (values) => {
        const temp = { ...configJSON }
        const index = temp.shipping_prices.findIndex((item) => item.id === action.id)

        if (index !== -1) {
            temp.shipping_prices[index] = {
                ...temp.shipping_prices[index], // Keep existing properties
                ...values,
                min: Number(values.min) ||0,
                max: Number(values.max) || Number.MAX_SAFE_INTEGER, // Update with new values
            }
        }

        

        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.merchantConfigs,
                payload: temp,
            })

            if (response.data.status_code === 200) {
                form.resetFields()
                message.success('Shipping method edited successfully')
                setConfigJSON({ ...temp })
            }
        } catch (error) {
            console.error('Error:', error)
            message.error('Failed to edit shipping rate')
        }
    }

    const onFinish = async (values) => {
        if (action?.name === 'edit') {
            editShippingMethod(values)
        } else {
            addShippingMethod(values)
        }

        setShowModal(false)
    }

    const handleKeyDown = (e) => {
        if (e.key === '-' || e.key === 'e') {
            e.preventDefault()
        }
    }

    return (
        <Form form={form} onFinish={onFinish} layout='vertical' initialValues={initialValues()}>
            <Row gutter={12} justify={'space-between'}>
                <Col span={24}>
                    <Form.Item
                        label={
                            <div className='flex flex-col gap-2 w-full'>
                                <span>Shipping Name</span>
                                <span className='flex w-full'>
                                    <Alert
                                        message={
                                            'Please ensure the shipping name entered is an exact match with the one specified in your Shopify settings'
                                        }
                                        showIcon
                                        type={'warning'}
                                        className='mb-2'
                                    />
                                </span>
                            </div>
                        }
                        name='presentment_name'
                        rules={[{ required: true, message: 'Please enter the shipping name' }]}
                    >
                        <Input placeholder='Enter shipping name here' disabled={action?.name === 'view'} />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label='Shipping Price'
                        name='price'
                        rules={[{ required: true, message: 'Please enter the shipping price' }]}
                    >
                        <InputNumber
                            className='w-full'
                            placeholder='Enter price here'
                            disabled={action?.name === 'view'}
                            min={0}
                            step={0.01}
                            stringMode={true}
                            precision={2}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        label='Min Order Value'
                        name='min'
                        rules={[
                            { required: true, message: 'Please input min order value' },

                            // ({ getFieldValue }) => ({
                            //     validator(_, value) {
                            //         const maxFieldValue = form.getFieldValue('max')
                            //         if (maxFieldValue !== undefined && value <= maxFieldValue) {
                            //             return Promise.resolve()
                            //         } else {
                            //             return Promise.reject(
                            //                 new Error('Min order value cannot be greater than max order value'),
                            //             )
                            //         }
                            //     },
                            // }),
                        ]}
                    >
                        <InputNumber
                            placeholder='-'
                            prefix={'₹'}
                            disabled={action?.name === 'view'}
                            onKeyDown={handleKeyDown}
                            // formatter={(value) => `${value}`.replace(/[^0-9]/g, '')}
                            // parser={(value) => `${value}`.replace(/[^0-9]/g, '')}
                            className='w-full'
                            min={0}
                            step={0.01}
                            stringMode={true}
                            precision={2}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        label='Max Order Value'
                        name='max'
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    const minFieldValue = form.getFieldValue('min')

                                    if (minFieldValue !== undefined && value >= minFieldValue) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject(
                                            new Error('Max order value cannot be less than min order value'),
                                        )
                                    }
                                },
                            }),
                        ]}
                    >
                        <InputNumber
                            placeholder='-'
                            prefix={'₹'}
                            disabled={action?.name === 'view'}
                            onKeyDown={handleKeyDown}
                            // formatter={(value) => `${value}`.replace(/[^0-9]/g, '')}
                            // parser={(value) => `${value}`.replace(/[^0-9]/g, '')}
                            className='w-full'
                            min={0}
                            step={0.01}
                            stringMode={true}
                            precision={2}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label='Choose payment method'
                        name='payment_options'
                        rules={[{ required: true, message: 'Please choose a payment method' }]}
                    >
                        <Select disabled={action?.name === 'view'}>
                            <Select.Option value='all'>All</Select.Option>
                            <Select.Option value='cod'>COD</Select.Option>
                            <Select.Option value='prepaid'>Prepaid</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                {action?.name !== 'view' && (
                    <>
                        <Col span={12}>
                            <Form.Item className='mb-0'>
                                <Button className='w-full' variant='primary' htmlType='submit'>
                                    {action?.name === 'edit' ? 'Edit' : 'Add'}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item className='mb-0'>
                                <Button className='w-full' variant='default' onClick={() => setShowModal(false)}>
                                    Cancel
                                </Button>
                            </Form.Item>
                        </Col>
                    </>
                )}
            </Row>
        </Form>
    )
}

export default AddShippingRateForm
