import { DiscountTypeEnum } from '@library/utilities/types'
import React, { ReactNode } from 'react'
import { DiscountBuyXYFunction } from '../discount-cards/discountBuyXatYFunctionCard'
import { DiscountMethodCard } from '../discount-cards/discountMethodCard'
import { DiscountValueCard } from '../discount-cards/discountValueCard'
import { DiscountFormState } from './useCreateDiscount'
import { DiscountCombinationCard } from '../discount-cards/discountCombinationCard'
import { DiscountLimitPreventionCard } from '../discount-cards/discountLimitPreventionCard'
import { DiscountScheduleCard } from '../discount-cards/discountScheduleCard'
import { DiscountCategoryCard } from '../discount-cards/discountCategoryCard'
import { BulkDiscountUploadCard } from '../discount-cards/bulkDiscountUploadCard'
import { DynamicFunctionCard } from '../discount-cards/dynamicFunctionCard'
import { MinPurchaseRequirementCard } from '../discount-cards/minPurchaseRequirementCard'
import { DiscountUserEligibilityCard } from '../discount-cards/discountUserEligibilityCard'
import { useLocation } from 'react-router-dom'
import { Form } from 'gokwik-ui-kit'
import { ProductQuantityFunctionCard } from '../discount-cards/productQuantityFunctionCard'
import { GwpDiscountFunctionCard } from '../discount-cards/GwpDiscountFunctionCard'

interface FormSteps {
    [key: number]: ReactNode[]
}

type DiscountConfigType = {
    [key in DiscountTypeEnum]: FormSteps
}

interface DiscountConfigProps {
    formState?: DiscountFormState
    generateDiscountCode?: (any) => void
    uploadDiscountCSV?: (originFileObj: any, merchant_id: any) => void
    codesList?: any
    bulkCodesPath?: any
    form?: any
    setFormState?: any
    discountAction?: any
    fileList?: any
}

const discountConfig = (props?: DiscountConfigProps): Partial<DiscountConfigType> => {
    const {
        formState,
        generateDiscountCode,
        uploadDiscountCSV,
        codesList,
        bulkCodesPath,
        form,
        setFormState,
        discountAction,
        fileList,
    } = props || {}
    const location = useLocation()
    const discountName = location?.state?.type || ''
    function modifyConfig() {
        const updatedConfig: Partial<DiscountConfigType> = {}
        const isAutomaticDiscount = formState?.method === 'automatic'
        const isBulkDiscount = formState?.discount_category === 'bulk'
        for (const discountType in config) {
            if (Object.prototype.hasOwnProperty.call(config, discountType)) {
                const discountTypeObj = config[discountType]

                updatedConfig[discountType] = {}

                for (const step in discountTypeObj) {
                    if (Object.prototype.hasOwnProperty.call(discountTypeObj, step)) {
                        const stepNumber = parseInt(step)
                        const bulkDiscountCardsArray = [
                            <DiscountCategoryCard discountAction={discountAction} formState={formState} />,
                            <BulkDiscountUploadCard
                                fileList={fileList}
                                formState={formState}
                                uploadDiscountCSV={uploadDiscountCSV}
                                generateDiscountCode={generateDiscountCode}
                                codesList={codesList}
                                bulkCodesPath={bulkCodesPath}
                                discountAction={discountAction}
                            />,
                        ]

                        const automaticDiscountCardsArray = [
                            <DiscountLimitPreventionCard discountAction={discountAction} formState={formState} />,
                            <DiscountScheduleCard formState={formState} />,
                        ]

                        if (discountName === 'capping') {
                            if (stepNumber === 0 && isBulkDiscount) {
                                updatedConfig[discountType][stepNumber] = [
                                    ...bulkDiscountCardsArray,
                                    <DiscountValueCard formState={formState} />,
                                ]
                                continue
                            }
                            if(stepNumber === 2 && isAutomaticDiscount) {
                                updatedConfig[discountType][stepNumber] = [
                                    ...automaticDiscountCardsArray
                                ]                                
                                continue
                            }
                        } else if (discountType === 'buy_x_at_y') {
                            if (stepNumber === 0 && isBulkDiscount) {
                                updatedConfig[discountType][stepNumber] = [
                                    ...bulkDiscountCardsArray,
                                    <DiscountBuyXYFunction
                                        discountAction={discountAction}
                                        formState={formState}
                                        setFormState={setFormState}
                                    />,
                                ]
                                continue
                            }
                            if(stepNumber === 2 && isAutomaticDiscount) {
                                updatedConfig[discountType][stepNumber] = [
                                    ...automaticDiscountCardsArray
                                ]                                
                                continue
                            }
                            
                             
                        } else if (discountName === 'dynamic') {
                            if (stepNumber === 0 && isBulkDiscount) {
                                updatedConfig[discountType][stepNumber] = [
                                    ...bulkDiscountCardsArray,
                                    <Form.Item name={['rules']}>
                                        <DynamicFunctionCard
                                            finalFormState={formState}
                                            discountAction={discountAction}
                                        />
                                    </Form.Item>,
                                ]
                                continue
                            }
                            if(stepNumber === 2 && isAutomaticDiscount) {
                                updatedConfig[discountType][stepNumber] = [
                                    ...automaticDiscountCardsArray
                                ]                                
                                continue
                            }
                        } else if (discountName === 'quantity') {
                            if (stepNumber === 0 && isBulkDiscount) {
                                updatedConfig[discountType][stepNumber] = [
                                    ...bulkDiscountCardsArray,
                                    <Form.Item name={['rules']}>
                                        <ProductQuantityFunctionCard
                                            finalFormState={formState}
                                            discountAction={discountAction}
                                            setFormState={setFormState}
                                        />
                                    </Form.Item>,
                                ]
                                continue
                            }
                            if(stepNumber === 2 && isAutomaticDiscount) {
                                updatedConfig[discountType][stepNumber] = [
                                    ...automaticDiscountCardsArray
                                ]                                
                                continue
                            }
                        } else if (discountName === 'gift_with_product') {
                            if (stepNumber === 0 && isBulkDiscount) {
                                updatedConfig[discountType][stepNumber] = [
                                    ...bulkDiscountCardsArray,
                                    <GwpDiscountFunctionCard
                                        discountAction={discountAction}
                                        formState={formState}
                                        setFormState={setFormState}
                                    />,
                                ]
                                continue
                            }
                            if (stepNumber === 2 && isAutomaticDiscount) {
                                updatedConfig[discountType][stepNumber] = [
                                    ...automaticDiscountCardsArray
                                ]                                
                                continue
                            }
                        }

                        updatedConfig[discountType][stepNumber] = discountTypeObj[step]
                    }
                }
            }
        }
        return updatedConfig
    }

    const config: Partial<DiscountConfigType> = {
        [DiscountTypeEnum.capping]: {
            0: [
                <DiscountCategoryCard discountAction={discountAction} formState={formState} />,
                <DiscountMethodCard
                    discountAction={discountAction}
                    generateDiscountCode={generateDiscountCode}
                    formState={formState}
                />,
                <DiscountValueCard formState={formState} />,
                <MinPurchaseRequirementCard formState={formState} setFormState={setFormState} />
            ],
            1: [<DiscountCombinationCard formState={formState} />],
            2: [<DiscountUserEligibilityCard formState={formState} setFormState={setFormState} discountAction={discountAction} />],
            3: [
                <DiscountLimitPreventionCard discountAction={discountAction} formState={formState} />,
                <DiscountScheduleCard formState={formState} />,
            ],
        },

        [DiscountTypeEnum.buy_x_at_y]: {
            0: [
                <DiscountCategoryCard discountAction={discountAction} formState={formState} />,
                <DiscountMethodCard
                    discountAction={discountAction}
                    generateDiscountCode={generateDiscountCode}
                    formState={formState}
                />,
                <DiscountBuyXYFunction
                    discountAction={discountAction}
                    formState={formState}
                    setFormState={setFormState}
                />,
            ],
            1: [<DiscountCombinationCard formState={formState} />],
            2: [<DiscountUserEligibilityCard formState={formState} setFormState={setFormState} discountAction={discountAction} />],
            3: [
                <DiscountLimitPreventionCard discountAction={discountAction} formState={formState} />,
                <DiscountScheduleCard formState={formState} />,
            ],
        },

        [DiscountTypeEnum.dynamic]: {
            0: [
                <DiscountCategoryCard discountAction={discountAction} formState={formState} />,
                <DiscountMethodCard
                    discountAction={discountAction}
                    generateDiscountCode={generateDiscountCode}
                    formState={formState}
                />,
                <Form.Item name={['rules']}>
                    <DynamicFunctionCard finalFormState={formState} discountAction={discountAction} />
                </Form.Item>,
            ],
            1: [<DiscountCombinationCard formState={formState} />],
            2: [<DiscountUserEligibilityCard formState={formState} setFormState={setFormState} discountAction={discountAction} />],
            3: [
                <DiscountLimitPreventionCard discountAction={discountAction} formState={formState} />,
                <DiscountScheduleCard formState={formState} />,
            ],
        },

        [DiscountTypeEnum.quantity]: {
            0: [
                <DiscountCategoryCard discountAction={discountAction} formState={formState} />,
                <DiscountMethodCard
                    discountAction={discountAction}
                    generateDiscountCode={generateDiscountCode}
                    formState={formState}
                />,
                <Form.Item name={['rules']}>
                    <ProductQuantityFunctionCard
                        finalFormState={formState}
                        discountAction={discountAction}
                        setFormState={setFormState}
                    />
                </Form.Item>,
            ],
            1: [<DiscountCombinationCard formState={formState} />],
            2: [<DiscountUserEligibilityCard formState={formState} setFormState={setFormState} discountAction={discountAction} />],
            3: [
                <DiscountLimitPreventionCard discountAction={discountAction} formState={formState} />,
                <DiscountScheduleCard formState={formState} />,
            ],
        },

        [DiscountTypeEnum.gift_with_product]: {
            0: [
                <DiscountCategoryCard discountAction={discountAction} formState={formState} />,
                <DiscountMethodCard
                    discountAction={discountAction}
                    generateDiscountCode={generateDiscountCode}
                    formState={formState}
                />,
                <GwpDiscountFunctionCard
                    discountAction={discountAction}
                    formState={formState}
                    setFormState={setFormState}
                />,
            ],
            1: [<DiscountCombinationCard formState={formState} />],
            2: [<DiscountUserEligibilityCard formState={formState} setFormState={setFormState} discountAction={discountAction} />],
            3: [
                <DiscountLimitPreventionCard discountAction={discountAction} formState={formState} />,
                <DiscountScheduleCard formState={formState} />,
            ],
        },
    }

    return modifyConfig()
}

export { discountConfig }
