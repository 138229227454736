import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
    Row,
    Col,
    Tag,
    Tooltip,
    Button,
    Input,
    Table,
    Space,
    ColumnProps,
    MinusCircleOutlined,
    PlusCircleOutlined,
    Modal,
    CloseCircleOutlined,
    message,
    Radio,
    DeleteOutlined,
    InfoCircleOutlined,
    Divider,
    Alert,
    Drawer,
    Form,
    Card,
    Skeleton,
    TextArea,
    InputNumber,
} from 'gokwik-ui-kit'
import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import { useSelector } from 'react-redux'
import { getMerchantDetails, isMerchantSelector, getUserConfig } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import SearchProduct from './search-product'
import { useLocation, useNavigate } from 'react-router'
import { navigateToUrl } from 'single-spa'
import { formatAmount } from '@library/utilities/helpers/helper'
import { EditOrderPayload, OrderData } from '@library/utilities/interface'

export default function ({}) {
    const [lineItems, setLineItems] = useState([])
    const [modalData, setModalData] = useState({ show: false, item: null })
    const [submitBtnEnable, setSubmitBtnEnable] = useState(true) // Assuming you have logic to enable/disable the submit button
    const [orderData, setOrderData] = useState(null)
    const [editOrderDetails, setEditOrderDetails] = useState(null)
    const [editError, setEditError] = useState('')
    const [errorItems, setErrorItems] = useState([])
    const config = useSelector(getMerchantDetails)
    const userConfig = useSelector(getUserConfig)
    const isMerchantUser = useSelector(isMerchantSelector)
    const location = useLocation()
    const { pathname } = location
    const orderNumber = pathname.split('/').pop()
    const [form] = Form.useForm()
    const [selectedOption, setSelectedOption] = useState(0)
    const [isSaveEnabled, setIsSaveEnabled] = useState(false)
    const [isDrawerOpen, setOpenDrawer] = useState<boolean>(false)
    const [addresData, setAddressData] = useState(null)
    const [checked, setChecked] = useState(true)
    const [addressList, setAddressList] = useState(null)
    const [shippingPrice, setShippingPrice] = useState({
        shipping_name: '',
        shipping_fee: '',
    })
    const lineItemsTotal = lineItems.reduce((sum, item) => sum + item.quantity * parseFloat(item.price), 0)
    const lineItemsTotalFormatted = formatAmount(lineItemsTotal, 0, 2)

    const [discount, setDiscount] = useState({
        recentlyDeletedItems: [],
        isModalVisible: false,
        discountType: 'fixed_amount',
        discountValue: null,
        discountTitle: '',
        discountApplied: false,
        discountButtonLabel: 'Add Discount',
        discountVisible: false,
        addProductModal: false,
        preAppliedDiscount: false,
        fetchedItems: [],
        discountedItems: [],
        itemToDelete: null,
        itemDiscount: 0,
    })
    const [pendingProduct, setPendingProduct] = useState(null)
    const updateState = (key, value) => {
        setDiscount((prev) => ({ ...prev, [key]: value }))
    }

    const columns: ColumnProps<any>[] = [
        { title: 'S No.', dataIndex: 'index', key: 'index', width: 50, align: 'left' },
        {
            title: 'Product',
            dataIndex: 'product',
            key: 'product',
            width: 200,
            align: 'left',
            render: (_, record) => {
                return (
                    <Space direction='horizontal'>
                        <p className='w-[200px]'>{_}</p>

                        <img className='w-8 rounded' src={record.image} />
                    </Space>
                )
            },
        },
        { title: 'SKU ID', dataIndex: 'skuId', key: 'skuId', width: 150, align: 'left' },
        { title: 'Product ID', dataIndex: 'productId', key: 'productId', width: 200, align: 'left' },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center',
            width: 100,
            render: (_, record) => {
                const isItemDiscounted = record.itemDiscount > 0
                return (
                    <div className='justify-center mt-2'>
                        <Space>
                            <div className='border-solid border-[0.4px] border-gray-500 rounded-md px-3 py-2 gap-x-3 flex items-center  '>
                                <MinusCircleOutlined
                                    onClick={() => {
                                        record.quantity > 1
                                            ? modifyQty(record.variant_id, record.quantity - 1)
                                            : showDelete(record)
                                        // modifyQty(record.variant_id, record.quantity - 1)
                                    }}
                                    className='cursor-pointer'
                                />
                                {_}
                                <PlusCircleOutlined
                                    onClick={() => modifyQty(record.variant_id, record.quantity + 1)}
                                    className='cursor-pointer'
                                />
                            </div>
                        </Space>
                        {isItemDiscounted ? (
                            <p className='mt-1 text-xs text-gray-400 flex items-end'>
                                Each @
                                <span className='flex flex-col '>
                                    <span className='text-gray-300 line-through mr-1'>
                                        {formatAmount(+record.price)}
                                    </span>
                                    {formatAmount(+record.price - record.itemDiscount / _)}
                                </span>
                            </p>
                        ) : (
                            <p className='mt-1 text-xs text-gray-400'>Each @ {formatAmount(+record.price)}</p>
                        )}
                    </div>
                )
            },
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'center',
            width: 150,
            render: (_, record) => {
                const itemDiscount = record.itemDiscount || 0
                const itemTotal = record.quantity * parseFloat(record.price)

                return itemDiscount ? (
                    <p className='text-center width-10'>
                        <span className='text-gray-400 line-through'>{formatAmount(itemTotal, 0, 2)}</span>
                        <br />
                        {formatAmount(record.discountedTotal, 0, 2)}
                    </p>
                ) : (
                    <p className='text-center width-10'>{formatAmount(itemTotal, 0, 2)}</p>
                )
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: 100,
            render: (_, record) => (
                <Space>
                    <DeleteOutlined
                        onClick={() => deleteItem(record.variant_id)}
                        className='w-5 text-red-400 cursor-pointer'
                    />
                </Space>
            ),
        },
    ]

    const recentlyDeleted: ColumnProps<any>[] = [
        {
            title: 'S No.',
            dataIndex: 'index',
            key: 'index',
            align: 'left',
            width: 60,
            render: (_, __, index) => index + 1,
        },
        {
            title: 'Product',
            dataIndex: 'product',
            key: 'product',
            width: 200,
            align: 'left',
            render: (_, record) => {
                return (
                    <Space direction='horizontal'>
                        <p className='w-[200px]'>{_}</p>

                        <img className='w-8 rounded' src={record.image} />
                    </Space>
                )
            },
        },
        {
            title: 'SKU ID',
            dataIndex: 'skuId',
            key: 'skuId',
            align: 'left',
            width: 150,
        },
        {
            title: 'Product ID',
            dataIndex: 'productId',
            key: 'productId',
            align: 'left',
            width: 200,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center',
            width: 100,
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'center',
            width: 150,
            render: (text) => `₹${text}`,
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 100,
            render: (_, record) => (
                <Button
                    onClick={() => {
                        handleReAddItem(record.variant_id)
                    }}
                >
                    Re-Add Line Item
                </Button>
            ),
        },
    ]
    const data = useMemo(
        () =>
            lineItems?.map((item, i) => {
                const shouldApplyDiscount = discount.discountedItems.includes(item.variant_id)
                const itemTotal = item.quantity * parseFloat(item.price)
                const itemDiscount = shouldApplyDiscount
                    ? discount.discountType === 'fixed_amount'
                        ? parseFloat('' + (itemTotal / lineItemsTotal) * discount.discountValue)
                        : (discount.discountValue / 100) * itemTotal
                    : 0

                const discountedTotal = itemTotal - itemDiscount
                return {
                    key: i,
                    index: i + 1,
                    product: item.p_name || `${item.product_name}-${item.variant_name}`,
                    skuId: item.sku,
                    productId: item.product_id,
                    quantity: item.quantity,
                    total: item.quantity * item.price,
                    price: item.price,
                    id: item.id,
                    image: item.product_thumbnail_url,
                    variant_id: item.variant_id,
                    isItemReadded: item.isItemReadded || false,
                    addedFromSearch: item.addedFromSearch,
                    discountedTotal,
                    itemDiscount,
                }
            }),
        [lineItems, discount],
    )
    const recentlyDeletedData = useMemo(
        () =>
            discount.recentlyDeletedItems?.map((item, i) => ({
                key: i,
                index: i + 1,
                product: item.p_name || `${item.product_name}-${item.variant_name}`,
                skuId: item.sku,
                productId: item.product_id,
                quantity: item.quantity,
                total: item.quantity * item.price,
                price: item.price,
                id: item.id,
                image: item.product_thumbnail_url,
                variant_id: item.variant_id,
            })),
        [discount.recentlyDeletedItems],
    )

    const calculatedGrandTotal = useMemo(() => {
        let itemTotal = 0
        const total = lineItems.reduce((sum, item) => {
            itemTotal += item.quantity * parseFloat(item.price)
            const applyDiscount = discount.discountedItems.includes(item.variant_id)
            const itemDiscount =
                applyDiscount && discount?.discountApplied
                    ? discount?.discountType === 'fixed_amount'
                        ? Math.min(discount.discountValue, itemTotal)
                        : (discount.discountValue / 100) * itemTotal
                    : 0

            const discountedTotal = discount.discountApplied ? itemTotal - itemDiscount : itemTotal
            return discountedTotal
        }, 0)
        return formatAmount(total, 0, 2)
    }, [lineItems, discount])

    const handleReAddItem = (itemId) => {
        const itemToReAdd = discount.recentlyDeletedItems.find((item) => item.variant_id === itemId)
        const isItemAlreadyExists = lineItems.some(
            (item) => item.productId === itemToReAdd.productId && item.variant_id === itemToReAdd.variant_id,
        )
        if (isItemAlreadyExists) {
            message.error('Product already exists in the table.')
            return
        }
        updateState(
            'recentlyDeletedItems',
            discount.recentlyDeletedItems.filter((item) => item.variant_id !== itemId),
        )
        setLineItems((prev) => [...prev, { ...itemToReAdd, isItemReadded: true }])
    }

    const handleRemoveDiscounts = () => {
        updateState('discountTitle', '')
        updateState('discountType', 'fixed_amount')
        updateState('discountValue', null)
        updateState('discountButtonLabel', 'Add Discount')
    }
    const handleOk = () => {
        if (!discount.discountTitle || discount.discountTitle.trim() === '') {
            message.error('Discount title is required.')
            return
        }
        if (!discount.discountValue) {
            message.error('Discount value is required')
            return
        }

        const discountedVariantIds = lineItems.map((item) => item.variant_id)
        updateState('discountedItems', discountedVariantIds)
        updateState('discountApplied', true)
        updateState(
            'discountButtonLabel',
            discount.discountType === 'fixed_amount'
                ? `Total Discount: ₹${discount.discountValue}`
                : `Total Discount: ${discount.discountValue}%`,
        )
        updateState('isModalVisible', false)
        setSubmitBtnEnable(true)
    }

    const showDelete = (item) => {
        setModalData({ show: true, item: item })
    }
    const shouldHideUI = () => {
        if (!discount.itemDiscount) {
            return true
        }
        if (lineItems.length === 0) {
            return true
        }

        const areItemsIdentical = lineItems.every((item, index) => {
            const fetchedItem = discount.fetchedItems[index]
            return (
                item?.product_id === fetchedItem?.product_id &&
                item?.quantity === fetchedItem?.quantity &&
                item?.variant_id === fetchedItem?.variant_id
            )
        })
        if (areItemsIdentical && discount.preAppliedDiscount) {
            return true
        }

        const isQuantityModified = lineItems.some((item) => {
            const fetchedItem = discount.fetchedItems.find((fetched) => fetched.product_id === item.product_id)
            return fetchedItem && item.quantity !== fetchedItem.quantity
        })
        if (discount.preAppliedDiscount && isQuantityModified) {
            return true
        }

        if (discount.preAppliedDiscount) {
            return true
        }
        return false
    }

    useEffect(() => {
        updateBreadcrumbs((prev) =>
            prev[prev?.length - 1]?.text === String(orderNumber)
                ? prev
                : [
                      prev[0],

                      {
                          key: 'orders',
                          href: '/checkout/orders',
                          text: 'Orders',
                      },
                      {
                          key: 'edit',
                          href: `/checkout/orders/edit/${orderNumber}`,
                          text: 'Edit',
                      },
                      {
                          key: 'order-details',
                          href: `/checkout/orders/${orderNumber}`,
                          text: String(orderNumber),
                      },
                  ],
        )
    }, [])
    useEffect(() => {
        if (lineItems.length === 0 && discount.discountValue !== 0) {
            updateState('discountValue', null)
            updateState('discountButtonLabel', 'Add Discount')
        }
    }, [lineItems, discount.discountValue])

    const fetchOrderDetails = async () => {
        await makeAPICall({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.getEditOrder}/${orderNumber}`,
        })
            .then((res) => {
                const orderData = { ...res.data.data.order }
                const unableToEditOrder = orderData.price_breakup
                if (unableToEditOrder && userConfig?.enable_edit_order && isMerchantUser) {
                    if (unableToEditOrder.automaticDiscounts || unableToEditOrder.prepaidDiscount) {
                        message.error(
                            "You won't be able to Increase Quantity when Automatic or prepaid discount is applied",
                        )
                    }
                }
                form.setFieldsValue({
                    recipientName: orderData?.billing_first_name || '',
                })
                setOrderData({ ...res.data.data.order })
                if (orderData.order_edit_status === 1) {
                    return setEditError('orderEdited')
                } else if (!orderData.order_name || orderData.order_status !== 'confirmed') {
                    return setEditError('notConfirmed')
                } else if (orderData.fulfilled === 'fulfilled') {
                    return setEditError('orderFulfilled')
                } else {
                    setEditOrderDetails({ ...res.data.data })
                    setLineItems([...res.data.data.line_items])
                    updateState('fetchedItems', [...res.data.data.line_items])
                    const orderDiscount = parseFloat(res.data.data?.order?.discount_amount) || 0
                    const prepaidDiscount = res.data.data?.order?.price_breakup?.prepaidDiscount
                    if (orderDiscount) {
                        updateState('itemDiscount', orderDiscount)
                    } else if (prepaidDiscount) {
                        updateState('itemDiscount', prepaidDiscount)
                    }
                    if (prepaidDiscount > 0 || orderDiscount > 0) {
                        updateState('preAppliedDiscount', true)
                    }
                }
            })
            .catch((err) => message.error(err.response?.data?.message || 'Unable to fetch order'))
    }
    const modifyQty = (id, qty) => {
        const tempLineItems = lineItems.map((item) =>
            item.variant_id === id
                ? {
                      ...item,
                      quantity: qty,
                      type: 'adjust',
                  }
                : item,
        )
        setLineItems(tempLineItems)
    }

    const deleteItem = (id) => {
        const deletedItem = lineItems.find((item) => item.variant_id === id)
        if (discount.preAppliedDiscount && !deletedItem.addedFromSearch) {
            updateState('itemToDelete', deletedItem)
            updateState('discountVisible', true)
            setModalData({
                show: false,
                item: null,
            })
        } else {
            const tempLineItems = [...lineItems].filter((item) => item.variant_id !== id)
            setLineItems([...tempLineItems])
            updateState('recentlyDeletedItems', [...discount.recentlyDeletedItems, deletedItem])
            updateState(
                'discountedItems',
                discount.discountedItems.filter((variantId) => variantId !== id),
            )

            if (tempLineItems.length === 0) {
                updateState('preAppliedDiscount', false)
            }
        }
    }

    const handleConfirmDelete = () => {
        const tempLineItems = lineItems.filter((item) => item.variant_id !== discount.itemToDelete.variant_id)
        setLineItems([...tempLineItems])
        updateState('recentlyDeletedItems', [...discount.recentlyDeletedItems, discount.itemToDelete])
        updateState(
            'discountedItems',
            discount.discountedItems.filter((variantId) => variantId !== discount.itemToDelete.variant_id),
        )

        if (tempLineItems.length === 0) {
            updateState('preAppliedDiscount', false)
        }
        updateState('discountVisible', false)
        updateState('itemToDelete', null)
    }
    const handleAddProduct = (newProduct) => {
        const productsWithFlag = newProduct.map((product) => {
            const shouldApplyDiscount = discount.discountedItems.includes(product.variant_id)
            return {
                ...product,
                addedFromSearch: true,
                isItemReadded: shouldApplyDiscount,
            }
        })
        setLineItems((prev) => [...prev, ...productsWithFlag])
    }

    const submitEditOrder = async () => {
        const existingLineItems = editOrderDetails?.line_items.reduce(
            (acc, item) => ({ ...acc, [item.variant_id]: { ...item } }),
            {},
        )
        const currentLineItems = lineItems.reduce((acc, item) => ({ ...acc, [item.variant_id]: { ...item } }), {})
        const removedItems = editOrderDetails?.line_items.reduce((acc, existingItem) => {
            if (!currentLineItems[existingItem.variant_id]) {
                return [
                    ...acc,
                    {
                        type: 'remove',
                        lineItemId: existingItem.line_item_id,
                        variantId: existingItem.variant_id,
                        productId: existingItem.product_id,
                    },
                ]
            }
            return acc
        }, [])

        const adjustedItems = editOrderDetails?.line_items.reduce((acc, existingItem) => {
            if (
                currentLineItems[existingItem.variant_id] &&
                existingItem.quantity !== currentLineItems[existingItem.variant_id].quantity &&
                !lineItems.find((item) => item.isItemReadded && item.variant_id === existingItem.variant_id)
            ) {
                return [
                    ...acc,
                    {
                        type: 'adjust',
                        lineItemId: existingItem.line_item_id,
                        variantId: existingItem.variant_id,
                        productId: existingItem.product_id,
                        quantity: currentLineItems[existingItem.variant_id].quantity - existingItem.quantity,
                    },
                ]
            }
            return acc
        }, [])

        const newItems = lineItems.reduce((acc, currentLineItem) => {
            if (!existingLineItems[currentLineItem.variant_id]) {
                return [
                    ...acc,
                    {
                        type: 'add',
                        variantId: currentLineItem.variant_id,
                        productId: currentLineItem.product_id,
                        quantity: currentLineItem.quantity,
                    },
                ]
            }
            return acc
        }, [])
        let payloadLineItems = [...removedItems, ...adjustedItems, ...newItems]
        if (discount.itemDiscount) {
            const reAddedItems = lineItems.reduce((acc, currentItem) => {
                if (
                    currentItem.isItemReadded &&
                    editOrderDetails?.line_items.some(
                        (originalItem) => originalItem.variant_id === currentItem.variant_id,
                    )
                ) {
                    return [
                        ...acc,
                        {
                            type: 'remove',
                            lineItemId: existingLineItems[currentItem.variant_id]?.line_item_id,
                            variantId: currentItem.variant_id,
                            productId: currentItem.product_id,
                        },
                        {
                            type: 'add',
                            variantId: currentItem.variant_id,
                            productId: currentItem.product_id,
                            quantity: currentItem.quantity,
                        },
                    ]
                }
                return acc
            }, [])
            const reAddIds = reAddedItems.map((item) => item.variantId)
            payloadLineItems = [
                ...removedItems.filter((item) => !reAddIds.includes(item.variantId)),
                ...adjustedItems,
                ...newItems,
                ...reAddedItems,
            ]
        }

        const discountData = {
            title: discount.discountTitle || '',
            type: (discount.discountType === 'fixed_amount' || discount.discountType === 'percentage'
                ? discount.discountType
                : 'fixed_amount') as 'fixed_amount' | 'percentage',
            value: Number(discount.discountValue) || 0,
        }

        const payload: EditOrderPayload = {
            moid: editOrderDetails.order.moid,
            shippingCharge: undefined,
            shippingAddress: undefined,
        }

        if (shippingPrice) {
            let amount = parseFloat(shippingPrice.shipping_fee)
            let title = shippingPrice.shipping_name
            if (!isNaN(amount) && title !== '') {
                payload.shippingCharge = { amount: amount, title: title }
            } else {
                delete payload.shippingCharge
            }
        }

        if (selectedOption && selectedOption !== 3) {
            payload.shippingAddress = { id: selectedOption }
        }

        if (selectedOption && selectedOption === 3) {
            payload.shippingAddress = {
                details: {
                    pincode: parseInt(orderData?.shipping_pincode),
                    city: orderData?.shipping_city,
                    address: orderData?.shipping_address1 + ' ' + orderData?.shipping_address2,
                    firstname: orderData?.shipping_first_name,
                    lastname: orderData?.shipping_last_name,
                },
            }
        }

        if (!payload.shippingAddress) delete payload.shippingAddress

        if (payloadLineItems.length > 0) {
            payload.line_items = payloadLineItems
        }
        if (discount.discountValue > 0) {
            payload.discount = discountData
        }

        await makeAPICall({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.saveEditOrder}`,
            payload,
        })
            .then((res) => {
                if (res.status === 200) {
                    message.success('Order Updated Successfully')
                    setTimeout(() => {
                        navigateToUrl(`/checkout/orders/${orderNumber}`)
                    }, 1000)
                    setSelectedOption(null)
                    setShippingPrice(null)
                } else {
                    message.error(
                        'Order Update Failed ' + res?.response?.data?.data?.data?.message || 'Order Update Failed',
                    )
                }
                errorItems.length && setErrorItems([])
            })
            .catch((err) => {
                const data = { ...err.response?.data }
                if (data?.data?.data && data.data.error) {
                    const errIds = data.data.data.reduce((ids, item) => {
                        return item.message ? [...ids, item.variantId] : ids
                    }, [])
                    setErrorItems([...errIds])
                    message.error(data.data.message || 'Unable to update order'),
                        {
                            position: 'top-center',
                            autoClose: 1500,
                        }
                } else {
                    message.error(err.response?.data?.message || 'Unable to update order'),
                        {
                            position: 'top-center',
                            autoClose: 1500,
                        }
                }
            })
    }

    useEffect(() => {
        if (!config.platform) return
        if (config.platform !== 'shopify') return setEditError('nonShopify')
        else fetchOrderDetails()
    }, [config.platform])

    useEffect(() => {
        if (!lineItems.length && !editOrderDetails?.line_items?.length) return
        if (editOrderDetails?.line_items.length !== lineItems.length) setSubmitBtnEnable(true)
        else {
            const currentLineItems = lineItems.reduce((acc, item) => ({ ...acc, [item.variant_id]: { ...item } }), {})
            const itemsChanged = editOrderDetails.line_items.reduce(
                (acc, item) => acc || currentLineItems[item.variant_id]?.quantity !== item.quantity,
                false,
            )
            setSubmitBtnEnable(itemsChanged)
        }
    }, [lineItems])

    if (!config?.enable_edit_order && !isMerchantUser) {
        return <h1 className=' text-center text-lg font-bold'>This feature is disabled for your store.</h1>
    }

    const billingInformation = useMemo(
        () => ({
            name: orderData?.billing_first_name + ' ' + orderData?.billing_last_name,
            phone: orderData?.billing_phone,
            address: `${orderData?.billing_address1} ${orderData?.billing_address2}, ${orderData?.billing_city}`,
            state: `${orderData?.billing_state}${
                orderData?.billing_pincode ? `- ${orderData?.billing_pincode || ''}` : ''
            }`,
        }),
        [orderData],
    )

    const shippingInformation = useMemo(
        () => ({
            name: (orderData?.shipping_first_name || '') + ' ' + (orderData?.shipping_last_name || ''),
            phone: orderData?.shipping_phone,
            address: `${orderData?.shipping_address1 || ''} ${orderData?.shipping_address2 || ''}${
                orderData?.shipping_city ? `, ${orderData?.shipping_city}` : ''
            }`,
            state: `${orderData?.shipping_state || ''}${
                orderData?.shipping_pincode ? ` - ${orderData?.shipping_pincode}` : ''
            }`,
        }),
        [orderData],
    )

    const openShippingDrawer = () => {
        setOpenDrawer(true)
    }

    const closeShippingDrawer = () => {
        setOpenDrawer(false)
    }

    const formData = () => {
        const formData = form.getFieldsValue()
        if (selectedOption === 3) {
            setOrderData((prev: any) => ({
                ...prev,
                shipping_first_name: formData?.recipientName.split(' ')[0] || formData?.recipientName,
                shipping_last_name: formData.recipientName.split(' ')[1] || '.',
                shipping_phone: formData?.recipientNumber,
                shipping_address1: formData?.address,
                shipping_address2: '',
                shipping_city: formData?.city,
                shipping_pincode: formData?.pincode,
                shipping_state: formData?.state,
            }))
        } else {
            setOrderData((prev: any) => ({
                ...prev,
                shipping_first_name: addresData.firstname,
                shipping_last_name: addresData.lastname,
                shipping_phone: addresData?.phone,
                shipping_address1: addresData?.address,
                shipping_address2: '',
                shipping_city: addresData?.city,
                shipping_pincode: addresData?.pincode,
                shipping_state: addresData?.state,
            }))
        }
        closeShippingDrawer()
        setIsSaveEnabled(false)
        setSubmitBtnEnable(true)
    }

    useEffect(() => {
        if (orderData && orderData.customer_id) {
            makeAPICall({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}${
                    APIEndPoints.getAddressByCustomerId + `${orderData?.customer_id}/addresses`
                }`,
            }).then((res) => {
                if (res && res.error) setAddressList(null)
                if (res && res?.data?.data?.length > 0) {
                    let addressList = res?.data?.data
                    if (addressList) {
                        let reverseArrayList = addressList.reverse()
                        const indexOfData = reverseArrayList.map((data) => data.id).indexOf(orderData.aid)
                        const newArray = [reverseArrayList[indexOfData], ...reverseArrayList.slice(3, 5)]
                        if (indexOfData === -1) {
                            setAddressList(addressList.slice(0, 3))
                        } else {
                            setAddressList(newArray)
                        }
                    }
                }
            })
        }
    }, [orderData])

    const handleRadioChange = (e: any) => {
        setSelectedOption(e.target.value)
        if (e.target.value !== 3) {
            form.resetFields(['pincode', 'state', 'city', 'address', 'recipientName', 'recipientNumber'])
        } else {
            if (orderData) {
                form.setFieldsValue({
                    state: orderData?.shipping_state,
                    city: orderData?.shipping_city,
                })
            }
        }
    }

    const handleFormChange = (e) => {
        setIsSaveEnabled(true)
    }

    return (
        <Row className='w-full min-w-full'>
            {editError ? (
                <div className='w-full h-full flex flex-col items-center'>
                    {editError === 'nonShopify' ? (
                        <h4 className='text-center font-semibold'>This feature is for Shopify merchants only.</h4>
                    ) : editError === 'orderEdited' ? (
                        <h4 className='text-center font-semibold'>
                            Order No.:{' '}
                            <a href={`/checkout/orders/${orderNumber}`} className='text-primary cursor-pointer'>
                                {orderNumber}
                            </a>
                            , has already been edited once.
                        </h4>
                    ) : editError === 'notConfirmed' ? (
                        <h4 className='text-center font-semibold'>
                            Order No.:{' '}
                            <a href={`/checkout/orders/${orderNumber}`} className='text-primary cursor-pointer'>
                                {orderNumber}
                            </a>
                            , is not confirmed yet, thus cannot be edited.
                        </h4>
                    ) : (
                        editError === 'orderFulfilled' && (
                            <h4 className='text-center font-semibold'>
                                Order No.:{' '}
                                <a href={`/checkout/orders/${orderNumber}`} className='text-primary cursor-pointer'>
                                    {orderNumber}
                                </a>
                                , is fulfilled.
                            </h4>
                        )
                    )}
                </div>
            ) : (
                <Col flex='1 1 200px' className='overflow-y-auto h-full overflow-x-hidden'>
                    <div className='order-details-page w-full  flex flex-col gap-y-4 inter'>
                        <Row className='p-2 rounded bg-neutrals-15' align={'middle'} justify={'space-between'}>
                            <Col sm={12} className='flex items-center gap-x-2'>
                                <span className='text-black text-base inter font-semibold m-0 tracking-wide'>
                                    Edit Order <span className='text-blue-500'> #{orderNumber}</span>
                                </span>
                            </Col>
                            <Col className='flex flex-row items-center gap-x-2 align-middle'>
                                <p className=''>
                                    Grand Total: <span className='font-bold'>{calculatedGrandTotal}</span>
                                    {calculatedGrandTotal !== lineItemsTotalFormatted && (
                                        <span className='text-xs text-gray-400 line-through ml-2'>
                                            {lineItemsTotalFormatted}
                                        </span>
                                    )}
                                </p>
                                <Button
                                    disabled={!submitBtnEnable}
                                    onClick={submitEditOrder}
                                    className='w-[5rem]'
                                    variant='primary'
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className='flex flex-col gap-y-4' sm={18}>
                                <Row
                                    gutter={[12, 12]}
                                    className='p-2 rounded bg-neutrals-15'
                                    align={'middle'}
                                    justify={'space-between'}
                                >
                                    <Col span={8}>
                                        <p className='text-lg font-bold mb-1 '>Line Items</p>
                                        {lineItems.length > 0 && (
                                            <p className='text-blue-500 text-sm font-semibold mb-1'>
                                                Total Items: {lineItems.length}
                                            </p>
                                        )}
                                    </Col>
                                    <Col span={10}>
                                        {/* <Input type='text' className='w-[400px]' placeholder='Enter product id or name' /> */}
                                        <SearchProduct
                                            lineItems={lineItems}
                                            setLineItems={(newItems) => {
                                                if (lineItems.length > 0 && discount.itemDiscount) {
                                                    setPendingProduct(newItems)
                                                    updateState('addProductModal', true)
                                                } else {
                                                    setLineItems(newItems)
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <Table columns={columns} dataSource={data} />
                                    </Col>
                                </Row>
                                {!shouldHideUI() && (
                                    <Row gutter={[12, 12]} className='p-2 rounded bg-neutrals-15' align='middle'>
                                        <Col span={12}>
                                            <p className='text-black text-base font-semibold'>Add Custom Discount</p>
                                            <p className='text-sm text-gray-500'>
                                                Discounts will be distributed on the line item level proportionally.
                                            </p>
                                        </Col>

                                        <Col span={12} className='flex justify-end'>
                                            <Button
                                                type='default'
                                                className='border border-gray-300'
                                                aria-label='Add Custom Discount'
                                                onClick={() => {
                                                    updateState('isModalVisible', true)
                                                }}
                                            >
                                                {discount.discountButtonLabel}
                                            </Button>

                                            {discount.discountValue && discount.isModalVisible === false && (
                                                <Button className='ml-2' onClick={handleRemoveDiscounts}>
                                                    Remove
                                                </Button>
                                            )}

                                            <Modal
                                                title='Add Discount'
                                                visible={discount.isModalVisible}
                                                onOk={handleOk}
                                                onCancel={() => {
                                                    updateState('isModalVisible', false)
                                                }}
                                                footer={[
                                                    <Button key='submit' type='primary' onClick={handleOk}>
                                                        Add Discount
                                                    </Button>,
                                                ]}
                                                className='max-w-md'
                                            >
                                                <div className='px-2'>
                                                    <div className='space-y-4'>
                                                        <div>
                                                            <p className='text-gray-700 mb-2 font-semibold'>
                                                                Discount Type
                                                            </p>
                                                            <Radio.Group
                                                                value={discount.discountType}
                                                                onChange={(e) =>
                                                                    updateState('discountType', e.target.value)
                                                                }
                                                                className='flex gap-4'
                                                            >
                                                                <Radio value='fixed_amount'>Fixed</Radio>
                                                                <Radio value='percentage'>Percentage</Radio>
                                                            </Radio.Group>
                                                        </div>
                                                        <div>
                                                            <p className='text-gray-700 mb-2 font-semibold'>
                                                                Discount Value
                                                            </p>
                                                            <Input
                                                                type='number'
                                                                value={discount.discountValue}
                                                                onChange={(e) =>
                                                                    updateState('discountValue', e.target.value)
                                                                }
                                                                prefix={
                                                                    discount.discountType === 'fixed_amount' ? '₹' : ''
                                                                }
                                                                suffix={
                                                                    discount.discountType === 'percentage' ? '%' : ''
                                                                }
                                                                className='w-full rounded border border-gray-200'
                                                            />
                                                        </div>
                                                        <div>
                                                            <p className='text-gray-700 font-semibold'>
                                                                Discount Title
                                                            </p>
                                                            <p className='text-gray-500 text-sm mb-2'>
                                                                This code will be visible on the Shopify order as well.
                                                            </p>
                                                            <Input
                                                                placeholder='XYZ'
                                                                value={discount.discountTitle}
                                                                onChange={(e) =>
                                                                    updateState('discountTitle', e.target.value)
                                                                }
                                                                className='w-full rounded border border-gray-200'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal>
                                        </Col>
                                    </Row>
                                )}
                                {discount.recentlyDeletedItems.length > 0 && (
                                    <Row gutter={[12, 12]} className='p-2 rounded bg-neutrals-15'>
                                        <Col>
                                            <div className='flex flex-col'>
                                                <p className='text-lg font-bold mb-1'>Recently Removed Items</p>
                                            </div>
                                            <Table
                                                columns={recentlyDeleted}
                                                dataSource={recentlyDeletedData}
                                                pagination={false}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <Col className='flex flex-col gap-y-4' sm={6}>
                                <Row
                                    gutter={[0, 16]}
                                    className='p-2 w-full rounded bg-neutrals-15'
                                    align={'middle'}
                                    justify={'space-between'}
                                >
                                    <Col span={24}>
                                        <span className='text-black text-base inter font-semibold m-0'>
                                            Billing Details
                                        </span>
                                    </Col>
                                    {!billingInformation ? (
                                        <Skeleton />
                                    ) : (
                                        <Col className='flex flex-col gap-y-2 text-sm text-gray-600' span={24}>
                                            <span className='text-black font-semibold'>{billingInformation.name}</span>
                                            <span>{billingInformation.phone}</span>
                                            <span>{billingInformation.address}</span>
                                            <span>{billingInformation.state}</span>
                                            <Alert
                                                message={'Billing details are not editable'}
                                                type='info'
                                                showIcon
                                                className='border-none'
                                            />
                                        </Col>
                                    )}
                                </Row>
                                <Row
                                    gutter={[0, 16]}
                                    className='p-2 w-full rounded bg-neutrals-15'
                                    align={'middle'}
                                    justify={'space-between'}
                                >
                                    <Col span={24}>
                                        <span className='flex flex-row justify-between items-center align-middle'>
                                            <span className='text-black text-base inter font-semibold m-0'>
                                                Shipping Details
                                            </span>
                                            <span>
                                                <Button
                                                    type='link'
                                                    size='small'
                                                    children={'Edit'}
                                                    onClick={openShippingDrawer}
                                                />
                                                <Drawer
                                                    width={480}
                                                    title='Shipping Details'
                                                    onClose={closeShippingDrawer}
                                                    open={isDrawerOpen}
                                                >
                                                    <Form
                                                        form={form}
                                                        layout='vertical'
                                                        onFinish={formData}
                                                        onValuesChange={handleFormChange}
                                                        className='p-0 m-0'
                                                        requiredMark={'optional'}
                                                    >
                                                        <Form.Item name='address-list'>
                                                            <Radio.Group
                                                                onChange={handleRadioChange}
                                                                className='w-full'
                                                                buttonStyle='outline'
                                                                size='large'
                                                                defaultValue={addressList ? addressList[0].id : ''}
                                                            >
                                                                {addressList &&
                                                                    addressList.length > 0 &&
                                                                    addressList.map((address: any) => (
                                                                        <Radio
                                                                            key={address.id}
                                                                            value={address.id}
                                                                            className='w-full p-2 border-solid border-gray-100 rounded-md mb-2'
                                                                            onClick={() => {
                                                                                setAddressData(address)
                                                                            }}
                                                                        >
                                                                            <div className='p-2'>
                                                                                <strong>
                                                                                    {address.firstname +
                                                                                        ' ' +
                                                                                        address.lastname}
                                                                                </strong>
                                                                                <div>{address.phone}</div>
                                                                                <div>{address.address}</div>
                                                                            </div>
                                                                        </Radio>
                                                                    ))}
                                                                <span className='flex flex-col w-full border-solid border-gray-100 rounded-md p-2'>
                                                                    <span className='w-full'>
                                                                        {' '}
                                                                        <Radio value={3} className='w-full'>
                                                                            <span className='flex flex-row w-full justify-between'>
                                                                                <span className='font-semibold ml-2'>
                                                                                    {' '}
                                                                                    Add New Address
                                                                                </span>
                                                                                <span className='font-thin italic ml-20'>
                                                                                    {' '}
                                                                                    {/* All Fields are manditory. */}
                                                                                </span>
                                                                            </span>
                                                                        </Radio>
                                                                    </span>
                                                                    <span className='w-full'>
                                                                        {selectedOption === 3 && (
                                                                            <>
                                                                                <Row
                                                                                    justify={'space-between'}
                                                                                    gutter={[12, 12]}
                                                                                    className='mt-5'
                                                                                >
                                                                                    <Col span={8}>
                                                                                        <Form.Item
                                                                                            name='pincode'
                                                                                            label='PIN Code'
                                                                                            required={true}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message:
                                                                                                        'Please enter your PIN Code',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <InputNumber
                                                                                                onChange={(
                                                                                                    value: number,
                                                                                                ) => {
                                                                                                    if (
                                                                                                        value &&
                                                                                                        value?.toString()
                                                                                                            .length ===
                                                                                                            6
                                                                                                    ) {
                                                                                                        makeAPICall({
                                                                                                            method: 'get',
                                                                                                            url: `${
                                                                                                                process
                                                                                                                    .env
                                                                                                                    .REACT_APP_BASE_URL
                                                                                                            }${
                                                                                                                APIEndPoints.getAddressDetails +
                                                                                                                `?pincode=${value}`
                                                                                                            }`,
                                                                                                        }).then(
                                                                                                            (res) => {
                                                                                                                if (
                                                                                                                    res.status ===
                                                                                                                    200
                                                                                                                ) {
                                                                                                                    form.setFieldsValue(
                                                                                                                        {
                                                                                                                            city: res
                                                                                                                                ?.data
                                                                                                                                ?.data
                                                                                                                                ?.city,
                                                                                                                            state: res
                                                                                                                                ?.data
                                                                                                                                ?.data
                                                                                                                                ?.state,
                                                                                                                        },
                                                                                                                    )
                                                                                                                }
                                                                                                            },
                                                                                                        )
                                                                                                    }
                                                                                                }}
                                                                                                maxLength={6}
                                                                                                parser={(value) =>
                                                                                                    value.replace(
                                                                                                        /\D/g,
                                                                                                        '',
                                                                                                    )
                                                                                                }
                                                                                                controls={false}
                                                                                                onKeyPress={(event) => {
                                                                                                    if (
                                                                                                        !/^\d$/.test(
                                                                                                            event.key,
                                                                                                        )
                                                                                                    ) {
                                                                                                        event.preventDefault()
                                                                                                    }
                                                                                                }}
                                                                                                className='w-full max-w-full'
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                    <Col span={8}>
                                                                                        <Form.Item
                                                                                            name='state'
                                                                                            label='State'
                                                                                            required={true}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message:
                                                                                                        'Please enter your state',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <Input disabled />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                    <Col span={8}>
                                                                                        <Form.Item
                                                                                            name='city'
                                                                                            label='City'
                                                                                            required={true}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message:
                                                                                                        'Please enter your city',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <Input />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col span={24}>
                                                                                        <Form.Item
                                                                                            name='address'
                                                                                            label='Full Address'
                                                                                            required={true}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message:
                                                                                                        'Please enter your full address',
                                                                                                },
                                                                                                {
                                                                                                    min: 10,

                                                                                                    message:
                                                                                                        'short address please cross verify once..',
                                                                                                },
                                                                                                {
                                                                                                    pattern:
                                                                                                        /^[a-zA-Z0-9\s,.'-]*$/,
                                                                                                    message:
                                                                                                        'Address cannot contain special characters please cross verify once!!',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <TextArea maxLength={255} />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row
                                                                                    justify={'space-between'}
                                                                                    gutter={[8, 16]}
                                                                                >
                                                                                    <Col span={12}>
                                                                                        <Form.Item
                                                                                            name='recipientName'
                                                                                            label='Recipient Full Name'
                                                                                            required={true}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message:
                                                                                                        "Please enter recipient's full name",
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <Input maxLength={16} />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                    <Col span={12}>
                                                                                        <Form.Item
                                                                                            name='recipientNumber'
                                                                                            label='Recipient Number'
                                                                                            required={false}
                                                                                        >
                                                                                            <InputNumber
                                                                                                className='w-full max-w-full'
                                                                                                maxLength={10}
                                                                                                parser={(value) =>
                                                                                                    value.replace(
                                                                                                        /\D/g,
                                                                                                        '',
                                                                                                    )
                                                                                                }
                                                                                                controls={false}
                                                                                                onKeyPress={(event) => {
                                                                                                    if (
                                                                                                        !/^\d$/.test(
                                                                                                            event.key,
                                                                                                        )
                                                                                                    ) {
                                                                                                        event.preventDefault()
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                </Row>
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                </span>
                                                            </Radio.Group>
                                                        </Form.Item>

                                                        <Space className='flex flex-row-reverse w-full z-50'>
                                                            <Button
                                                                type='primary'
                                                                disabled={!isSaveEnabled}
                                                                htmlType='submit'
                                                            >
                                                                Apply
                                                            </Button>
                                                            <Button
                                                                onClick={() => {
                                                                    form.resetFields([
                                                                        'pincode',
                                                                        'state',
                                                                        'city',
                                                                        'address',
                                                                        'recipientName',
                                                                        'recipientNumber',
                                                                    ])
                                                                    closeShippingDrawer()
                                                                    setIsSaveEnabled(false)
                                                                }}
                                                            >
                                                                Discard
                                                            </Button>
                                                        </Space>
                                                    </Form>
                                                </Drawer>
                                            </span>
                                        </span>
                                        {orderData?.address?.deliver_order_count > 0 && (
                                            <Tooltip
                                                title={`Past Deliveries = ${orderData?.address?.deliver_order_count}`}
                                            >
                                                <InfoCircleOutlined className='ml-2 text-base' />
                                            </Tooltip>
                                        )}
                                    </Col>
                                    {!shippingInformation ? (
                                        <Skeleton />
                                    ) : (
                                        <Col className='flex flex-col gap-y-2 text-sm text-gray-600' span={24}>
                                            <span className='text-black font-semibold'>
                                                {shippingInformation.name.trim()
                                                    ? shippingInformation.name || '-'
                                                    : billingInformation.name}
                                            </span>
                                            <span>{shippingInformation.phone || billingInformation.phone}</span>
                                            <span>
                                                {/[a-zA-Z0-9]/.test(shippingInformation.address.trim())
                                                    ? shippingInformation.address
                                                    : billingInformation.address}
                                            </span>
                                            <span>
                                                {/[a-zA-Z0-9]/.test(shippingInformation.state.trim())
                                                    ? shippingInformation.state
                                                    : billingInformation.state}
                                            </span>
                                        </Col>
                                    )}
                                </Row>
                                <Row
                                    gutter={[0, 16]}
                                    className='p-2 w-full rounded bg-neutrals-15'
                                    align={'middle'}
                                    justify={'space-between'}
                                >
                                    <Col span={24} className='flex flex-col gap-5'>
                                        <span className='flex flex-row justify-between items-center align-middle'>
                                            <span className='text-black text-base inter font-semibold m-0'>
                                                Shipping & COD Fee
                                            </span>
                                            <span>
                                                <Button
                                                    className='border-none bg-transparent text-sm text-blue-500'
                                                    children={checked ? 'Edit' : 'Save'}
                                                    onClick={() => {
                                                        setChecked(!checked)
                                                        form.setFieldsValue({
                                                            shipping_name: shippingPrice.shipping_name,
                                                            shipping_fee: shippingPrice.shipping_fee,
                                                        })
                                                        setSubmitBtnEnable(true)
                                                    }}
                                                />
                                            </span>
                                        </span>
                                        <span>
                                            <Form
                                                variant={checked ? 'borderless' : 'outlined'}
                                                layout='vertical'
                                                className='flex flex-row justify-between p-0 gap-2 text-left'
                                                disabled={checked}
                                                onValuesChange={(text) => {
                                                    setShippingPrice((prev) => ({
                                                        ...prev,
                                                        ...text,
                                                    }))
                                                }}
                                            >
                                                <Form.Item
                                                    label='Shipping Name'
                                                    name='shipping_name'
                                                    className='p-0'
                                                    getValueFromEvent={(e) => {
                                                        return e.target.value
                                                    }}
                                                >
                                                    <Input
                                                        style={{ backgroundColor: 'transparent', color: 'black' }}
                                                        size='small'
                                                        placeholder={orderData?.price_breakup?.shipping?.title || 'NA'}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label='Value'
                                                    name='shipping_fee'
                                                    className='p-0'
                                                    getValueFromEvent={(e) => {
                                                        return e.target.value
                                                    }}
                                                >
                                                    <Input
                                                        style={{ backgroundColor: 'transparent', color: 'black' }}
                                                        size='small'
                                                        prefix='₹'
                                                        type='number'
                                                        placeholder={
                                                            orderData?.price_breakup?.shipping?.totalCharge || 0
                                                        }
                                                    />
                                                </Form.Item>
                                            </Form>
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <Modal
                        cancelText={'Cancel'}
                        okText={'Delete'}
                        okType='danger'
                        centered
                        // className='w-1/2'
                        width={'30vw'}
                        open={modalData.show}
                        onOk={() => {
                            deleteItem(modalData.item.variant_id)
                            setModalData({
                                show: false,
                                item: null,
                            })
                        }}
                        onCancel={() => {
                            setModalData({
                                show: false,
                                item: null,
                            })
                        }}
                    >
                        <p className='font-semibold '>Are you sure ?</p>
                        <p className='text-normal  '>
                            Do you want to remove product{' '}
                            <span className='font-semibold'>{modalData?.item?.product}</span>
                        </p>
                    </Modal>
                    <Modal
                        title='Alert!'
                        visible={discount.discountVisible}
                        onOk={handleConfirmDelete}
                        onCancel={() => {
                            updateState('discountVisible', false)
                        }}
                        okText='Remove Product'
                        cancelText='Exit'
                    >
                        <p>
                            {lineItems.length > 1
                                ? 'Existing discount will also be removed and to re-apply the discount on a new product you need to remove all the products and re add them and then Add Discount '
                                : 'Note that removing this item will also remove the applied discount, but you can manually re-apply it to a new product.'}
                        </p>
                    </Modal>
                    <Modal
                        title='Alert!'
                        visible={discount.addProductModal}
                        onOk={() => {
                            handleAddProduct(pendingProduct)
                            setPendingProduct(null)
                            updateState('addProductModal', false)
                        }}
                        onCancel={() => {
                            updateState('addProductModal', false)
                        }}
                        okText='Add Product'
                        cancelText='Cancel'
                    >
                        <p>
                            An additional discount cannot be applied to a new product. To apply a discount to the new
                            product, you need to remove all products, re-add them, and then apply a "custom discount".
                        </p>
                    </Modal>
                </Col>
            )}
        </Row>
    )
}
