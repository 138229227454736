import React, { memo, useRef } from 'react'
import { Select, Input, DatePicker, Tooltip } from 'gokwik-ui-kit'
import { SearchFilters } from '@library/utilities/interface'
import dayjs, { Dayjs } from 'dayjs'
import { filterDateFormatter, phoneInputValidation, validatePermission } from '@gokwik/utilities'
import { navigateToUrl } from 'single-spa'
import { useLocation, useNavigate } from 'react-router-dom'
import { PermissionValues } from '@library/utilities/constants/constants'

const { Option } = Select

interface RenderFilterProps extends SearchFilters {
    removable?: boolean
    onRemove?: (key: string) => void
    value: string
    onChange: (data: { [key: string]: string }) => void
    inputKey: string
    onClick?: React.MouseEventHandler
    sixMonthsRange?: boolean
}

const RenderFilter = ({
    input_details,
    value,
    label,
    removable,
    onChange,
    onRemove,
    id,
    is_dynamic,
    inputKey,
    onClick,
    sixMonthsRange,
}: RenderFilterProps) => {
    const inputRef = useRef(null)
    const navigate = useNavigate()
    const location = useLocation()
    const handleInput = (newValue: any, key: string) => {
        if (key.toLowerCase().includes('phone')) {
            newValue = phoneInputValidation(newValue)
        }
        onChange({ [key]: newValue })
    }

    const getInputType = (label: any): any => {
        let type = 'text'
        if (label === 'Email') {
            type = 'email'
        } else {
            type = 'text'
        }
        return type
    }
    const today = dayjs()
    const yesterday = today.subtract(1, 'day')
    const presets: {
        label: any
        value: any
    }[] = [
        { label: 'Today', value: [today, today] },
        { label: 'Yesterday', value: [yesterday, yesterday] },
        {
            label: 'Last 7 days',
            value: [today.subtract(6, 'days'), today],
        },
        {
            label: 'Last 30 days',
            value: [today.subtract(29, 'days'), today],
        },
        ...(validatePermission(PermissionValues.reports.view) &&
        (location.pathname === '/checkout/abandoned-cart' || location.pathname === '/checkout/orders')
            ? [
                  {
                      label: (
                          <Tooltip title='For data beyond 45 days, please head over to the reports page.'>
                              Last 45 days
                          </Tooltip>
                      ),
                      value: [today.subtract(46, 'days'), today],
                  },
              ]
            : []),
    ]

    return (
        <Tooltip title={label}>
            {input_details?.input_type === 'date-range' ? (
                <DatePicker.RangePicker
                    className='w-full'
                    size='middle'
                    value={[dayjs(value?.split(',')[0]), dayjs(value?.split(',')[1])]}
                    onChange={([start, end, string]: any) => {
                        let date1 = dayjs(start)
                        let date2 = dayjs(end)
                        let diff = date2.diff(date1, 'day')
                        let path = location.pathname
                        console.log(path)
                        if (diff > 43) {
                            if (path === '/checkout/abandoned-cart') {
                                navigateToUrl(
                                    '/general/reports?from=abandonedCartReport&' + 'start=' + start + '&end=' + end,
                                )
                            } else if (path === '/checkout/orders') {
                                navigateToUrl('/general/reports?from=order&' + 'start=' + start + '&end=' + end)
                            }
                        } else {
                            const key = inputKey.split('/')
                            onChange({
                                [key[0]]: filterDateFormatter(start),
                                [key[1]]: filterDateFormatter(end),
                            })
                        }
                    }}
                    format={'MMM DD, YY'}
                    allowClear={false}
                    presets={presets}
                    onClick={onClick}
                    disabledDate={
                        sixMonthsRange
                            ? (current) => {
                                  const sixMonthsAgo = dayjs().subtract(6, 'months')
                                  const today = dayjs().endOf('day')
                                  return current && (current < sixMonthsAgo || current > today)
                              }
                            : undefined
                    }
                />
            ) : input_details?.input_type === 'text' || input_details?.input_type === 'number' ? (
                <Input
                    // ref={inputRef}
                    // onWheel={() => inputRef.current.blur()}
                    value={value}
                    type={getInputType(label)}
                    size='middle'
                    className={'w-full'}
                    placeholder={label}
                    key={id}
                    maxLength={label === 'Phone' && 10}
                    allowClear
                    onChange={(e) => handleInput(e.target.value, inputKey)}
                    {...(is_dynamic && {
                        removable,
                        onRemove,
                    })}
                    onClick={onClick}
                />
            ) : (
                <Select
                    value={value || []}
                    size='middle'
                    className={'w-full'}
                    placeholder={label}
                    showSearch={true}
                    options={
                        input_details?.mode == 'multi-select'
                            ? input_details.options.map((itm) => ({ value: itm, label: `${itm}` }))
                            : input_details?.options?.map((option) => ({
                                  value: input_details?.value_key ? option[input_details.value_key] : option?.value,
                                  label: option?.text || option?.business_name || option?.short_name,
                              }))
                    }
                    allowClear
                    key={id}
                    mode={input_details?.mode === 'multi-select' ? 'multiple' : undefined}
                    onChange={(newValue: any) => {
                        handleInput(newValue, inputKey)
                    }}
                    {...(is_dynamic && {
                        removable,
                        onRemove,
                    })}
                    onClick={onClick}
                />
            )}
        </Tooltip>
    )
}

export default memo(RenderFilter)
