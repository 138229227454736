import { ManualDiscountIcon } from '@library/images/discountIcons'
import { useAppDispatch } from '@library/utilities/hooks'
import { updateSummary } from '@store/discounts'
import { selectDiscountCodeData } from '@store/discounts/selectors'
import {
    Alert,
    Button,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    PlusOutlined,
    Radio,
    Row,
    TextArea,
} from 'gokwik-ui-kit'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import externalLink from '@library/images/external-link.svg'
import { Link } from 'react-router-dom'

const DiscountVisibility = ({ form }: { form: any }) => {
    const [showAdditionalConditions, setShowAdditionalConditions] = useState(false)
    const discountCodeVisibilityEnabled = Form.useWatch('discountCodeVisibilityEnabled', form)
    const discountCodeVisibility = Form.useWatch('discountCodeVisibility', form)
    const discountCodeDetails = Form.useWatch('discountCodeDetails', form)
    const discountCodeDescription = Form.useWatch('discountCodeDescription', form)
    const dispatch = useAppDispatch()
    const discountData = useSelector(selectDiscountCodeData)
    const validateDescription = (_: any, value: string) => {
        if (discountCodeVisibilityEnabled && !value) {
            return Promise.reject('Please enter a description')
        }
        return Promise.resolve()
    }

    const validateDiscountVisibility = (_: any, value: string) => {
        if (discountCodeVisibilityEnabled && !value) {
            return Promise.reject('Please select visibility')
        }
        if (value === 'ALWAYS' && discountData?.customerEligibility !== 'all') {
            return Promise.reject('Visibility cannot be set to always for user specific offers')
        }
        return Promise.resolve()
    }

    return (
        <Form
            form={form}
            layout='vertical'
            requiredMark='optional'
            onValuesChange={(changedValues, allValues) => {
                dispatch(updateSummary(allValues))
            }}
        >
            <Row gutter={[16, 16]}>
                <Col span={16}>
                    <div className='flex flex-col gap-y-3'>
                        <Form.Item
                            label='Show discount code to the customer on checkout?'
                            name='discountCodeVisibilityEnabled'
                            className='label-bold'
                            rules={[{ required: true, message: 'Please select visibility!' }]}
                        >
                            <Radio.Group>
                                <Radio value={false}>No</Radio>
                                <Radio value={true}>Yes</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {discountCodeVisibilityEnabled && (
                            <>
                                <Form.Item
                                    rules={[{ validator: validateDescription }]}
                                    label='Description'
                                    className='label-bold'
                                    required={discountCodeVisibilityEnabled}
                                    name='discountCodeDescription'
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label='Additional Details'
                                    className='label-bold'
                                    name='discountCodeDetails'
                                    required={discountCodeVisibilityEnabled}
                                >
                                    <TextArea />
                                </Form.Item>
                                <Form.Item
                                    label='Visibility'
                                    name='discountCodeVisibility'
                                    className='label-bold'
                                    rules={[{ validator: validateDiscountVisibility }]}
                                    required={discountCodeVisibilityEnabled}
                                >
                                    <Radio.Group>
                                        <Radio disabled={discountData?.customerEligibility !== 'all'} value={'ALWAYS'}>
                                            Always
                                        </Radio>
                                        <Radio value={'ONLY_WHEN_ELIGIBLE'}>Only When Eligible</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Alert
                                    message='User specific offers are only visible to eligible users post login on checkout'
                                    type='info'
                                    showIcon
                                />
                            </>
                        )}
                    </div>

                    {discountCodeVisibilityEnabled &&
                        (!showAdditionalConditions ? (
                            <Button className='pl-0 mt-2' type='link' onClick={() => setShowAdditionalConditions(true)}>
                                <PlusOutlined /> Add More Conditions
                            </Button>
                        ) : (
                            <>
                                <Divider />
                                <Row gutter={[12, 12]}>
                                    <Col span={12}>
                                        <Form.Item
                                            label='Min Price'
                                            name='discountCodeVisibilityMinPrice'
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (
                                                            value &&
                                                            form.getFieldValue('discountCodeVisibilityMaxPrice') &&
                                                            value > form.getFieldValue('discountCodeVisibilityMaxPrice')
                                                        ) {
                                                            return Promise.reject(
                                                                'Min price should be less than max price',
                                                            )
                                                        }
                                                        return Promise.resolve()
                                                    },
                                                },
                                            ]}
                                        >
                                            <InputNumber prefix='₹' className='w-full' min={0} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label='Max Price'
                                            name='discountCodeVisibilityMaxPrice'
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (
                                                            value &&
                                                            form.getFieldValue('discountCodeVisibilityMinPrice') &&
                                                            value < form.getFieldValue('discountCodeVisibilityMinPrice')
                                                        ) {
                                                            return Promise.reject(
                                                                'Max price should be greater than min price',
                                                            )
                                                        }
                                                        return Promise.resolve()
                                                    },
                                                },
                                            ]}
                                        >
                                            <InputNumber prefix='₹' className='w-full' min={0} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (
                                                            value &&
                                                            form.getFieldValue('discountCodeVisibilityMaxQty') &&
                                                            value > form.getFieldValue('discountCodeVisibilityMaxQty')
                                                        ) {
                                                            return Promise.reject(
                                                                'Min quantity should be less than max quantity',
                                                            )
                                                        }
                                                        return Promise.resolve()
                                                    },
                                                },
                                            ]}
                                            label='Min Quantity'
                                            name='discountCodeVisibilityMinQty'
                                        >
                                            <InputNumber className='w-full' min={0} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label='Max Quantity'
                                            name='discountCodeVisibilityMaxQty'
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (
                                                            value &&
                                                            form.getFieldValue('discountCodeVisibilityMinQty') &&
                                                            value < form.getFieldValue('discountCodeVisibilityMinQty')
                                                        ) {
                                                            return Promise.reject(
                                                                'Max quantity should be greater than min quantity',
                                                            )
                                                        }
                                                        return Promise.resolve()
                                                    },
                                                },
                                            ]}
                                        >
                                            <InputNumber className='w-full' min={0} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        ))}
                </Col>

                {discountCodeVisibilityEnabled && (
                    <>
                        <Col span={8}>
                            <div className='w-full p-3 bg-gray-200 rounded-md'>
                                <div className='flex flex-col'>
                                    <div className='bg-neutrals-15 py-2 px-3 flex flex-col gap-y-3'>
                                        <div className='w-full flex justify-between'>
                                            <div className='flex items-center'>
                                                <ManualDiscountIcon className='text-lg' />
                                                <span className='ml-2 px-1.5 border border-dashed border-gray-300 rounded'>
                                                    Discount Code
                                                </span>
                                            </div>
                                            <span className='text-primary-500 font-semibold'>APPLY</span>
                                        </div>
                                        <span className='text-success-500 text-sm'>You save ₹XXX.XX</span>
                                        {discountCodeDescription && (
                                            <span className='text-gray-400 font-medium text-sm'>
                                                {discountCodeDescription}
                                            </span>
                                        )}
                                        {discountCodeDetails && (
                                            <div className='rounded-lg text-gray-400 font-medium text-sm bg-primary-25 p-2 min-h-[5rem]'>
                                                <ul className='pl-4  my-0'>
                                                    {discountCodeDetails.split(',').map((detail, index) => (
                                                        <li key={index}>{detail}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                        <span className='text-gray-400 font-medium text-sm underline'>Show less</span>
                                    </div>
                                    <Alert message='When discount conditions are met.' type='info' showIcon />

                                    {discountCodeVisibility === 'ALWAYS' && (
                                        <>
                                            <div className='bg-neutrals-15 py-2 px-3 flex flex-col gap-y-3 mt-4'>
                                                <div className='w-full flex justify-between'>
                                                    <div className='flex items-center'>
                                                        <ManualDiscountIcon className='text-lg' />
                                                        <span className='ml-2 px-1.5 border border-dashed border-gray-300 rounded'>
                                                            Discount Code
                                                        </span>
                                                    </div>
                                                    <span className='text-primary-500 font-semibold'>APPLY</span>
                                                </div>
                                                <span className='text-error-500 text-sm'>
                                                    Add items worth ₹XXX.XX amount more to avail
                                                </span>
                                                {discountCodeDescription && (
                                                    <span className='text-gray-400 font-medium text-sm'>
                                                        {discountCodeDescription}
                                                    </span>
                                                )}
                                                {discountCodeDetails && (
                                                    <div className='rounded-lg text-gray-400 font-medium text-sm bg-primary-25 p-2 min-h-[5rem]'>
                                                        <ul className='pl-4  my-0'>
                                                            {discountCodeDetails.split(',').map((detail, index) => (
                                                                <li key={index}>{detail}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                                <span className='text-gray-400 font-medium text-sm underline'>
                                                    Show less
                                                </span>
                                            </div>
                                            <Alert
                                                message='When discount conditions are not met.'
                                                type='info'
                                                showIcon
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </Col>

                        <Col span={24}>
                            <Divider />
                            <p className='text-sm font-semibold w-full pb-2'>Control appearance of discounts</p>
                            <div className='flex justify-between flex-col gap-y-3'>
                                <Alert
                                    message='Show best/worst/specific discount upfront to the customers for one-click application when eligible. Want to enable?'
                                    type='info'
                                    showIcon
                                />
                                <Alert
                                    message='Auto-apply best/worst/specific discount when eligible for the customers on checkout. Want to enable?'
                                    type='info'
                                    showIcon
                                />
                                <Link
                                    className='w-fit'
                                    to='/checkout/settings/customise-ui'
                                    state={{
                                        activeTab: 'coupons',
                                    }}
                                >
                                    <Button className='w-fit flex items-center gap-x-2'>
                                        <img src={externalLink} alt='External Link' />
                                        Take me there
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                    </>
                )}
            </Row>
        </Form>
    )
}

export default DiscountVisibility
