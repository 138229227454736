import { Col, Row, Tabs } from 'gokwik-ui-kit'
import { useEffect, useMemo, useState } from 'react'
import AdsAnalytics from './adsAnalytics'
import ReMarketingTools from './reMarkingTools'
import LoyaltyPoints from './loyaltyPoints'
import ExternalDiscounts from './externalDiscounts'
import GiftCardsVouchers from './giftCardVouchers'
import EddShipping from './eddShipping'
import OtherIntegrations from './otherIntegrations'
import { useAppDispatch } from '@library/utilities/hooks'
import { fetchRewardsAndCreds } from '@store/integrations'
import { Route, Routes, useLocation } from 'react-router-dom'
import { updateBreadcrumbs } from '@gokwik/utilities'

const Integrations = () => {
    const location = useLocation()
    const [activeTab, setActiveTab] = useState('ads-analytics')
    const dispatch = useAppDispatch()
    const tabs = useMemo(
        () => [
            {
                key: 'ads-analytics',
                label: 'Ads & Analytics',
                children: <AdsAnalytics />,
            },
            {
                key: 're-Marketing-tools',
                label: 'Re-Marketing Tools',
                children: <ReMarketingTools />,
            },
            {
                key: 'loyalty-points',
                label: 'Loyalty Points',
                children: <LoyaltyPoints />,
            },
            {
                key: '3rdParty-discounts',
                label: '3rd Party Discounts',
                children: <ExternalDiscounts />,
            },
            {
                key: 'giftcard-vouchers',
                label: 'Gift Cards & Vouchers',
                children: <GiftCardsVouchers />,
            },
            {
                key: 'edd-shipping',
                label: 'EDD & Shipping',
                children: <EddShipping />,
            },
            {
                key: 'others',
                label: 'Others',
                children: <OtherIntegrations />,
            },
        ],
        [],
    )
    useEffect(() => {
        if (location.state?.activeTab) {
            setActiveTab(location.state.activeTab)
        }
    }, [location.state])

    useEffect(() => {
        dispatch(fetchRewardsAndCreds())
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                text: 'Checkout Settings',
                key: 'settings',
                href: '/checkout/settings',
            },
            {
                key: 'integrations',
                text: 'Integrations',
                href: '/checkout/settings/integrations',
            },
        ])
    }, [])

    return (
        <>
            <Row className='p-2 rounded bg-neutrals-15 mb-16' align='middle' justify='space-between'>
                <Col sm={24}>
                    <Tabs
                        className='inter'
                        items={tabs}
                        activeKey={activeTab}
                        onChange={(key) => {
                            setActiveTab(key)
                        }}
                    />
                </Col>
            </Row>
        </>
    )
}

export default Integrations
