import { HTMLProps, useEffect, useState } from 'react'
import { AnalyticsDate } from '../../library/utilities/interface'
import { apexChartConfig, ApexOptions, ReactApexChart } from 'gokwik-ui-kit'

interface ChartProps {
    dates: { start: AnalyticsDate; end: AnalyticsDate }
    data: any
    chartType:
        | 'line'
        | 'area'
        | 'bar'
        | 'pie'
        | 'donut'
        | 'radialBar'
        | 'scatter'
        | 'bubble'
        | 'heatmap'
        | 'candlestick'
        | 'boxPlot'
        | 'radar'
        | 'polarArea'
        | 'rangeBar'
        | 'rangeArea'
        | 'treemap'
    hideComparison?: boolean
    seriesNames?: string[]
    isAmount?: boolean
    wrapperClassName?: HTMLProps<HTMLElement>['className']
    valueKey?: string
}

const Chart = ({
    dates,
    data,
    chartType,
    seriesNames,
    hideComparison,
    isAmount,
    wrapperClassName,
    valueKey,
}: ChartProps) => {
    const [series, setSeries] = useState([])
    const [options, setOptions] = useState<ApexOptions>({})
    const key = valueKey || 'value'

    useEffect(() => {
        if (!data) return
        const { primaryBreakdown, secondaryBreakdown } = data
        const diff = dates.start.to.diff(dates.start.from, 'days')
        const chartData = primaryBreakdown.reduce(
            (result, current, i) => ({
                primary: [...result.primary, current[key] || 0],
                secondary: [...result.secondary, secondaryBreakdown?.[i]?.[key] || 0],
                xPrimary: [...result.xPrimary, current.hour?.split(' +')[0] || current.date],
                xSecondary: [
                    ...result.xSecondary,
                    secondaryBreakdown?.[i]?.hour?.split(' +')[0] || secondaryBreakdown?.[i]?.date,
                ],
            }),
            {
                primary: [],
                secondary: [],
                xPrimary: [],
                xSecondary: [],
            },
        )
        setSeries([
            {
                name: seriesNames?.[0] || dates.start.label,
                data: chartData.primary,
            },
            ...(chartData.secondary.some((val) => val) && !hideComparison
                ? [
                      {
                          name: seriesNames?.[1] || dates.end.label,
                          data: chartData.secondary,
                      },
                  ]
                : []),
        ])
        setOptions(
            apexChartConfig({
                xLabelsPrimary: chartData.xPrimary,
                xLabelsSecondary: chartData.xSecondary,
                datesDiff: diff,
                isAmount,
            }),
        )
    }, [dates, data, valueKey])

    return (
        <div className={wrapperClassName}>
            <ReactApexChart options={options} type={chartType} series={series} height={'100%'} />
        </div>
    )
}
export default Chart
