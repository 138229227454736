import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '../constants/apiEndpoints'
import { UtmListParams } from '../interface'

interface DistinctCampignData {
    start_dt: string
    end_dt: string
    // campaign?: string
    // medium?: string
    // source?: string
    // table: 'order' | 'cart' | 'marketing_analytics'
}

export const fetchDistinctCampignData = async (params: DistinctCampignData) => {
    try {
        let response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.distinctCampiagnData,
            params: {
                page: 1,
                sortOrder: -1,
                pageSize: 25,
                ...params,
            },
            skipLoader: true,
        })
        if (response.success) return response?.data?.data
    } catch (err) {
        console.log(err)
    }
}
