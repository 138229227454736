export const setCookie = (name, value, expiresInDays) => {
    const options = {
        expires: new Date(Date.now() + expiresInDays * 24 * 60 * 60 * 1000),
        SameSite: 'None',
        Secure: true,
        path: '/',
    }
    let cookieString = `${name}=${value}`
    for (const [key, val] of Object.entries(options)) {
        cookieString += `; ${key}`
        if (val !== true) {
            cookieString += `=${val}`
        }
    }
    document.cookie = cookieString
}

export const getCookie = (cname: string) => {
    const name = cname + '='
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
        let c = cookies[i]
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}
