import { makeAPICall, titleCase } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { validateDiscountCapping } from '@library/utilities/helpers/validations'
import { DiscountCapping } from '@library/utilities/interface'
import {
    Button,
    Col,
    ColumnsType,
    DeleteOutlined,
    Input,
    InputNumber,
    PlusOutlined,
    Row,
    Select,
    Switch,
    Table,
    message,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'

const matchTypes = {
    1: 'is',
    2: 'contains',
    3: 'starts with',
    4: 'ends with',
    5: 'RegEx',
}

const DiscountCappingConfig = () => {
    const [discountCappingData, setDiscountCappingData] = useState({
        enable_discount_capping: false,
        discount_capping: [],
    })

    const [discountCappingObj, setDiscountCappingObj] = useState<Partial<DiscountCapping>>({
        code: '',
        match_type: null,
        is_deleted: false,
        capping_limit: '',
        regex: null,
    })

    const [isUpdated, setIsUpdated] = useState(false)

    const addCappingDiscount = () => {
        const validation = validateDiscountCapping(discountCappingObj)
        if (!validation.status) {
            message.error(validation.msg)
            return
        }
        setDiscountCappingData((prev) => ({
            ...prev,
            discount_capping: [...(prev?.discount_capping || []), discountCappingObj],
        }))
        setIsUpdated(true)
        setDiscountCappingObj({
            code: '',
            match_type: null,
            is_deleted: false,
            capping_limit: '',
            regex: null,
        })
    }
    const deleteItem = (deletedItem, idx) => {
        let temp = []
        if (deletedItem.id) {
            temp = [...discountCappingData.discount_capping].map((item) =>
                item.id === deletedItem.id ? { ...item, is_deleted: true } : item,
            )
        } else {
            temp = [...discountCappingData.discount_capping].filter((item, i) => i !== idx)
        }
        setIsUpdated(true)
        setDiscountCappingData((prev) => ({ ...prev, discount_capping: temp }))
    }
    const fetchData = async () => {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.fetchInternalConfig,
        })
        if (response?.success) {
            setDiscountCappingData(response.data.data)
        }
    }
    const handleSave = async () => {
        const body = {
            ...discountCappingData,
        }
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateInternalConfig,
            payload: body,
        })
        if (response?.success) {
            message.success(response.data.message || 'Settings saved successfully')
            fetchData()
            setIsUpdated(false)
        }
    }
    const columns: ColumnsType<any> = [
        {
            title: 'Match Type',
            dataIndex: 'match_type',
            key: 'match_type',
            render: (text, record, index) => {
                return matchTypes[text]
            },
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            render: (text, record) => (record.match_type === 5 ? record.regex : record.code),
        },
        {
            title: 'Capping Limit',
            dataIndex: 'capping_limit',
            key: 'capping_limit',
        },
        ...(discountCappingData.enable_discount_capping
            ? [
                  {
                      title: '',
                      key: 'action',
                      render: (text, record, index) => (
                          <DeleteOutlined className='text-error-500' onClick={() => deleteItem(record, index)} />
                      ),
                  },
              ]
            : []),
    ]
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <Col className='bg-white' span={24}>
            <Row gutter={[16, 24]} align={'bottom'}>
                <Col span={24}>
                    <span className='mr-1'>
                        {discountCappingData.enable_discount_capping ? 'Disable' : 'Enable'} Discount Capping
                    </span>{' '}
                    <Switch
                        checked={discountCappingData.enable_discount_capping}
                        onChange={(checked) => {
                            setDiscountCappingData((prev) => ({
                                ...prev,
                                enable_discount_capping: checked,
                            }))
                            setIsUpdated(true)
                        }}
                    />
                </Col>
                <Col span={4}>
                    <Select
                        placeholder='Select type'
                        label='Match Type*'
                        className='w-full'
                        value={discountCappingObj.match_type ? '' + discountCappingObj.match_type : null}
                        options={Object.keys(matchTypes).map((key) => ({
                            value: '' + key,
                            label: titleCase(matchTypes[key]),
                        }))}
                        onChange={(val) =>
                            setDiscountCappingObj((prev) => ({
                                ...prev,
                                match_type: Number(val),
                            }))
                        }
                        disabled={!discountCappingData.enable_discount_capping}
                    />
                </Col>
                <Col span={4}>
                    <Input
                        placeholder={'Enter code'}
                        label={'Shopify Code*'}
                        value={discountCappingObj[discountCappingObj.match_type === 5 ? 'regex' : 'code']}
                        onChange={(e) =>
                            setDiscountCappingObj((prev) => ({
                                ...prev,
                                [discountCappingObj.match_type === 5 ? 'regex' : 'code']: e.target.value.trimStart(),
                            }))
                        }
                        disabled={!discountCappingData.enable_discount_capping}
                    />
                </Col>
                <Col span={4}>
                    <InputNumber
                        placeholder='Enter value'
                        className='w-full'
                        label={'Maximum Limit*'}
                        min={0}
                        disabled={!discountCappingData.enable_discount_capping}
                        value={discountCappingObj.capping_limit}
                        onChange={(val) =>
                            setDiscountCappingObj((prev) => ({
                                ...prev,
                                capping_limit: Number(val),
                            }))
                        }
                    />
                </Col>
                <Col span={4}>
                    <Button onClick={addCappingDiscount} disabled={!discountCappingData.enable_discount_capping}>
                        <PlusOutlined /> Add New Discount
                    </Button>
                </Col>
                <Col span={24}>
                    <Table
                        dataSource={(discountCappingData.discount_capping || []).filter((item) => !item.is_deleted)}
                        columns={columns}
                        pagination={false}
                    />
                </Col>
                <Col>
                    <Button disabled={!isUpdated} variant='primary' onClick={handleSave}>
                        Save
                    </Button>
                </Col>
            </Row>
        </Col>
    )
}

export default DiscountCappingConfig
