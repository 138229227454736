import {
    ArrowRightOutlined,
    Button,
    ColorPicker,
    LoadingOutlined,
    message,
    Upload,
    UploadOutlined,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { deepEqualAndDiff, makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import { useNavigate } from 'react-router-dom'
import UnsavedChangesButton from './unsavedChangesButton'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import useShopifyMerchantConfigApi from '@library/utilities/hooks/useShopifyMerchantConfig'
import { defaultShopifyNewCheckoutConfig, logoSources } from './gokwik-checkout-ui/constants'
import BookDownload from '@library/images/book-download.svg'
import { IFeaturesEvents } from '@library/utilities/helpers/eventsHelper'

const UiCustomisation = (props: {events: IFeaturesEvents}) => {
    const navigate = useNavigate()
    const { uiCustomisationsEvents, loginUICustomisationsEvents, discountSuggestionsEvents} = props.events;
    const { tourStepsEvents, configsUpsert } = uiCustomisationsEvents;
    const merchantDetails = useSelector(getMerchantDetails)
    const { config, saveConfig, refetch } = useShopifyMerchantConfigApi({
        useCache: true,
        fireFailureEvent: configsUpsert.edit.fireFailureEvent,
        fireSuccessEvent: configsUpsert.edit.fireSuccessEvent
    })
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [logoUrl, setLogoUrl] = useState(null)
    const [updatedColorConfig, setUpdatedColorConfig] = useState(defaultShopifyNewCheckoutConfig.colorConfig)
    const [loading, setLoading] = useState(false)
    const timestamp = Date.now()

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'settings',
                href: '/checkout/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'ui-customisation',
                href: '/checkout/settings/ui-customisation',
                text: 'UI Customisation',
            },
        ])
    }, [])

    useEffect(() => {
        if (config?.newCheckoutUIConfig?.colorConfig) {
            setUpdatedColorConfig(config.newCheckoutUIConfig.colorConfig)
        }
        setLogoUrl(config?.logo)
    }, [config?.logo, config?.newCheckoutUIConfig?.colorConfig])

    const getPresignedUrl = async (logo) => {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + '/api/dashboard/utility/presigned-url',
            payload: {
                path: `merchant/${merchantDetails?.m_id}/logo${timestamp}.${logo?.name.split('.').pop()}`,
                type: 'logo',
            },
        })
        if (response.success) {
            return response.data.data.url
        } else {
            console.error('getPresignedUrl failed')
        }
    }

    function fireEvent(type, data) {
        if (type == 'success') {
            if (!config?.logo && logoUrl) {
                configsUpsert.add.fireSuccessEvent(data);
            } else if (config?.logo && logoUrl) {
                configsUpsert.edit.fireSuccessEvent(data);
            } else if (config?.logo && !logoUrl) {
                configsUpsert.delete.fireSuccessEvent(data);
            }
        } else {
            if (!config?.logo && logoUrl) {
                configsUpsert.add.fireFailureEvent(data);
            } else if (config?.logo && logoUrl) {
                configsUpsert.edit.fireFailureEvent(data);
            } else if (config?.logo && !logoUrl) {
                configsUpsert.delete.fireFailureEvent(data);
            }
        }
    }

    const updateMerchant = async () => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateMerchant(merchantDetails?.m_id),
                payload: {
                    logo: logoUrl,
                },
            })
            if (response.success) {
                fireEvent('success', {
                    step: 'logo_updated_in_db'
                });
                message.success('Logo uploaded successfully')
            } else {
                message.error('Logo upload failed')
                fireEvent('failure', {
                    step: 'logo_updated_in_db',
                    errorMessage: "Failed to update logo in database"
                });
            }
        } catch (error) {
            console.error(error)
            fireEvent('failure', {
                step: 'logo_updated_in_db',
                errorMessage: error?.message || "Failed to update logo in database"
            });
        }
    }

    const uploadLogo = async (logo) => {
        const signedURl = await getPresignedUrl(logo)
        try {
            const awsResponse = await fetch(signedURl, {
                method: 'PUT',
                body: logo,
            })

            if (awsResponse.status == 200) {
                setLogoUrl(`merchant/${merchantDetails?.m_id}/logo${timestamp}.${logo?.name.split('.').pop()}`)
                setUnsavedChanges(true)
                fireEvent('success', {
                    step: 'logo_uploaded_to_aws'
                });
            } else {
                message.error('Logo upload failed')
                fireEvent('failure', {
                    step: 'logo_uploaded_to_aws',
                    errorMessage: "Failed to update logo in AWS"
                });
            }
        } catch (error) {
            console.error(error)
            message.error('Logo upload failed')
            fireEvent('failure', {
                step: 'logo_uploaded_to_aws',
                errorMessage: error?.message || "Failed to update logo in AWS"
            });
        }
    }

    const handleDiscard = () => {
        setLogoUrl(config?.logo)
        setUpdatedColorConfig(config?.newCheckoutUIConfig?.colorConfig ?? defaultShopifyNewCheckoutConfig.colorConfig)
        setUnsavedChanges(false)
        configsUpsert.discardSave();
    }

    const handleSave = async () => {

        if (logoUrl !== config?.logo) {
            if (!config?.logo && logoUrl) {
                configsUpsert.add.fireClickedEvent({
                    step: 'logo_added'
                });
            } else {
                configsUpsert.edit.fireClickedEvent({
                    step: 'logo_edited'
                });
            }
            await updateMerchant()
        }

        if (!deepEqualAndDiff(config?.newCheckoutUIConfig?.colorConfig, updatedColorConfig)?.isEqual) {
            configsUpsert.edit.fireClickedEvent({
                prevConfig: config?.newCheckoutUIConfig?.colorConfig,
                currentConfig: updatedColorConfig
            });
            await saveConfig({
                newCheckoutUIConfig: {
                    ...config?.newCheckoutUIConfig,
                    colorConfig: updatedColorConfig,
                },
            })
        } else {
            refetch()
        }
        setUnsavedChanges(false)
    }

    const handleNavigate = (path) => {
        if (unsavedChanges) {
            message.warning('Please save the changes first')
        } else {
            navigate(path)
        }
    }

    //validate logo size
    const validateLogoSize = (file) => {
        const maxSizeInBytes = 128 * 1024 // 128KB in bytes
        const isLt128KB = file.size < maxSizeInBytes
        if (!isLt128KB) {
            message.error('File must be upto 128KB!')
        }
        return isLt128KB
    }

    const handleLogoUploadChange = (info) => {
        if (info.file?.status === 'uploading') {
            setLoading(true)
            return
        }
        if (info.file?.status === 'done') {
            setLoading(false)
            uploadLogo(info.file?.originFileObj)
        }
    }

    return (
        <div className='w-full px-2 mt-4 flex flex-col gap-2 inter payment-features-tour'>
            {unsavedChanges && <UnsavedChangesButton handleDiscard={handleDiscard} handleSave={handleSave} />}
            <div className='flex flex-col mb-15 items-start space-y-4 bg-gray-100 min-h-screen'>
                {/* Gokwik Checkout UI */}
                <p className='text-lg font-bold p-2'>
                UI Customisation
                    <a
                        className='ml-3 text-sm font-normal'
                        href='https://scribehow.com/page/Guide_for_UI_Customisation__3D_krpJjSGO4Yn5AzUVFFw'
                        target='_blank'
                        rel='noopener noreferrer'
                        onClick={() => tourStepsEvents.fireGuideOpenedEvent()}
                    >
                        <img className='mr-1 mb-1' src={BookDownload} height={'18'} width={'18'} />
                        Open Guide
                    </a>
                </p>
                <div className='p-4 bg-white rounded-lg w-full '>
                    <div className='flex items-center justify-between mb-4'>
                        <h2 className='text-lg font-semibold'>GoKwik Checkout UI</h2>
                        <Button
                            className='py-5 px-0 bg-white border-none flex justify-end items-center focus:outline-none'
                            onClick={() => {
                                loginUICustomisationsEvents.fireClickedEvent();
                                handleNavigate('/checkout/settings/ui-customisation/checkout-ui')
                            }}
                        >
                            <span className='bg-gray-100 rounded-lg px-5 py-3'>
                                <ArrowRightOutlined />
                            </span>
                        </Button>
                    </div>

                    {/* Logo Upload Section */}
                    <div className='mb-6'>
                        <label className='block mb-2 font-semibold'>Upload Logo</label>
                        <p className='text-gray-500 text-xs mb-2'>
                            You can upload files up to 128KB. Consider using SVG files instead of JPG or PNG for their
                            scalability and smaller size.
                        </p>
                        <Upload
                            className='mx-50'
                            maxCount={1}
                            multiple={false}
                            accept='.jpeg,.png,.jpg,.svg'
                            showUploadList={false}
                            beforeUpload={validateLogoSize}
                            customRequest={(info) => {
                                info.onSuccess('')
                            }}
                            onChange={handleLogoUploadChange}
                        >
                            <Button disabled={loading}>
                                {loading ? <LoadingOutlined /> : <UploadOutlined />} Upload Logo
                            </Button>
                        </Upload>
                        {logoUrl && (
                            <div className='mt-1'>
                                <img
                                    src={logoSources(logoUrl)[0]}
                                    alt='logo'
                                    height={80}
                                    width={80}
                                    onError={(e) => {
                                        if (e.target instanceof HTMLImageElement) {
                                            const img = e.target
                                            const currentSrc = img.src
                                            const currentIndex = logoSources(logoUrl).indexOf(currentSrc)
                                            if (logoUrl && currentIndex < 3) {
                                                img.src = logoSources(logoUrl)[currentIndex + 1]
                                            }
                                        }
                                    }}
                                />
                            </div>
                        )}
                    </div>

                    {/* Theme Section */}
                    <div>
                        <p className='text-base font-semibold mb-1'>Theme</p>
                        <div className='flex flex-row space-x-4'>
                            <div className='mb-4 mr-5'>
                                <label className='block mb-1 text-sm font-medium text-gray-700'>Primary Color</label>
                                <ColorPicker
                                    value={updatedColorConfig.brandColor}
                                    onChange={(color) => {
                                        setUpdatedColorConfig((prev) => ({
                                            ...prev,
                                            brandColor: color.toHexString(),
                                        }))
                                        setUnsavedChanges(true)
                                    }}
                                    showText
                                    input
                                />
                            </div>
                            <div>
                                <label className='block mb-1 text-sm font-medium text-gray-700'>Text Color</label>
                                <ColorPicker
                                    value={updatedColorConfig.textColor}
                                    onChange={(color) => {
                                        setUpdatedColorConfig((prev) => ({
                                            ...prev,
                                            textColor: color.toHexString(),
                                        }))
                                        setUnsavedChanges(true)
                                    }}
                                    showText
                                    input
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Button
                    className='w-full py-8 px-4 bg-white border-none flex justify-between items-center focus:outline-none'
                    style={{ borderBottom: '1px solid #d9d9d9' }}
                    onClick={() => {
                        discountSuggestionsEvents.fireClickedEvent();
                        handleNavigate('/checkout/settings/ui-customisation/discount-suggestions')
                    }}
                >
                    <span className='text-base font-bold '>Discount Suggestions</span>
                    <span className='bg-gray-100 rounded-lg px-5 py-3'>
                        <ArrowRightOutlined />
                    </span>
                </Button>
            </div>
        </div>
    )
}

export default UiCustomisation
