import { FC, useEffect, useState } from 'react'
import { CardLayout } from './cardLayout'
import { Input, Radio, Space, Row, Button, Col, Form, Divider } from 'gokwik-ui-kit'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'

import React from 'react'
import { DiscountFormState } from '../helpers/useCreateDiscount'

interface DiscountMethodCardProps {
    formState?: DiscountFormState
    generateDiscountCode?: (any) => void
    discountAction?: any
}

const Card: FC<DiscountMethodCardProps> = (props) => {
    const { formState, generateDiscountCode, discountAction } = props
    const [baseLineExplainer, setBaseLineExplainer] = useState('Customers must enter this code at checkout')
    const [codeValue, setCodeValue] = useState('adad')

    useEffect(() => {
        if (formState?.method === 'automatic') {
            setBaseLineExplainer('Customers will see title this in their cart and at checkout')
        } else {
            setBaseLineExplainer('Customers must enter this code at checkout')
        }
    }, [formState?.method])

    const discountCodeRules =
        formState?.method === 'automatic'
            ? [
                  {
                      pattern: /^[A-Z0-9]+$/,
                      message: 'Please enter only capital letters (A-Z) and numbers (0-9).',
                  },
                  {
                      required: true,
                      message: 'Please enter discount code',
                  },
              ]
            : [
                  {
                      required: true,
                      message: 'Please enter discount code',
                  },
                  {
                      max: 30,
                      message: 'Discount code should not exceed 30 characters',
                  },
              ]

    return (
        <CardLayout
            title='Method of application: How should this discount be applied?'
            subtitle='Choose how the discount will be applied to customer purchase'
            baselineExplainer={baseLineExplainer}
        >
            <Divider className='m-1' />
            <Form.Item name='method'>
                <Radio.Group defaultValue={'code'}>
                    <Space direction='horizontal'>
                        <Radio
                            disabled={
                                discountAction &&
                                formState?.discount_category === 'single' &&
                                formState?.method === 'automatic'
                            }
                            value={'code'}
                        >
                            Discount Code
                        </Radio>
                        <Radio
                            disabled={
                                discountAction &&
                                formState?.discount_category === 'single' &&
                                formState?.method === 'code'
                            }
                            value={'automatic'}
                        >
                            Automatic Discount
                        </Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>

            <Row className='w-full gap-2 my-2'>
                <Col span={18}>
                    <Form.Item name='code' rules={discountCodeRules}>
                        <Input placeholder='Input Discount Code here' className='w-full' maxLength={30} />
                    </Form.Item>
                </Col>

                <Col>
                    <Button
                        variant='primary'
                        onClick={() =>
                            generateDiscountCode &&
                            generateDiscountCode({
                                length: 6,
                            })
                        }
                        disabled={formState?.code ? true : false}
                    >
                        Generate
                    </Button>
                </Col>
            </Row>
        </CardLayout>
    )
}

export const DiscountMethodCard = React.memo(Card)
