import {
    Alert,
    Button,
    Card,
    Col,
    DeleteOutlined,
    Divider,
    Drawer,
    Dropdown,
    EditOutlined,
    InboxOutlined,
    InfoCircleFilled,
    InfoCircleOutlined,
    Input,
    Menu,
    message,
    Modal,
    Pagination,
    QuestionCircleOutlined,
    Radio,
    Row,
    Select,
    Space,
    Switch,
    Table,
    Tooltip,
    Upload,
    UploadProps,
    WarningOutlined,
} from 'gokwik-ui-kit'
import { Form } from 'gokwik-ui-kit'
import threeDotsIcon from '@library/images/three-dots.svg'
import { useEffect, useMemo, useState } from 'react'
import samplefile from '../../../../public/assets/sample_file.csv'
import fileImageSvg from '../../../../library/images/file-download.svg'
import { formatAmount } from '@library/utilities/helpers/helper'
import { ShippingRangeChecker } from './shippingBasedError'
import RenderField from '@library/utilities/helpers/renderFormItem'

const ShippingMethodConfig = ({ ...props }) => {
    const { shippingConfig, saveConfig, weightConfig, discounted_level_srates } = props
    const [form] = Form.useForm()
    const [isSwitchEnabled, setSwitchChange] = useState(false)
    const [optons, setOption] = useState(false)
    const [open, setOpen] = useState(false)
    const [shippingVisibility, setShippingVisibility] = useState(true)
    const [prevFormState, setPrevFormState] = useState(null)
    const [ishidden, setIshidden] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isEditEnabled, setEditEnabled] = useState(false)

    const rangesOverlap = ([x1, x2], [y1, y2]) => x1 <= y2 && y1 <= x2

    const { Dragger } = Upload
    const fields: any = [
        {
            name: ['shipping_prices', 'presentment_name'],
            label: (
                <>
                    Shipping Name &nbsp;
                    <Tooltip title='It is advised to have shipping names less than 16 characters for better visibility on checkout.'>
                        <QuestionCircleOutlined />
                    </Tooltip>
                </>
            ),
            inputProps: {
                type: 'text',
                placeholder: 'eg. Express',
            },
            colon: false,
            required: false,
            rules: [
                {
                    required: true,
                    message: 'Field cannot be empty.',
                },
                {
                    validator: (_, value) => {
                        if (!isEditEnabled) {
                            const isNamePresent = shippingConfig?.some((item) => item?.presentment_name === value)
                            if (isNamePresent) return Promise.reject(new Error('Provided name exist.'))
                        }

                        return Promise.resolve()
                    },
                },
            ],
            type: 'input',

            span: 4,
        },
        {
            name: ['shipping_prices', 'price'],
            label: 'Shipping Price',
            type: 'input',
            required: false,
            colon: false,
            inputProps: {
                type: 'number',
                prefix: '₹',
                onKeyPress: (e) => {
                    if (!/^[0-9]$/.test(e.key)) {
                        e.preventDefault()
                    }
                },
            },
            rules: [
                {
                    required: true,
                    message: 'Field cannot be empty.',
                },
                {
                    pattern: /^[0-9]+$/,
                    message: 'Please enter a valid positive number!',
                },
            ],

            span: 4,
        },
        {
            name: ['shipping_prices', 'min'],
            label: 'Min Order Value',
            type: 'input',
            colon: false,
            required: false,
            inputProps: {
                type: 'number',
                prefix: '₹',
                onKeyPress: (e) => {
                    if (!/^[0-9]$/.test(e.key)) {
                        e.preventDefault()
                    }
                },
            },
            rules: [
                {
                    required: true,
                    message: 'Field cannot be empty.',
                },
                {
                    pattern: /^[0-9]*\.?[0-9]*$/,
                    message: 'Please enter a valid positive number!',
                },
            ],
            span: 4,
        },
        {
            name: ['shipping_prices', 'max'],
            label: 'Max Order Value',
            placeholder: '',
            type: 'input',
            required: false,
            colon: false,
            inputProps: {
                type: 'number',
                prefix: '₹',
                onKeyPress: (e) => {
                    if (!/^[0-9]$/.test(e.key)) {
                        e.preventDefault()
                    }
                },
            },
            rules: [
                {
                    pattern: /^[0-9]*\.?[0-9]*$/,
                    message: 'Please enter a valid positive number!',
                },
            ],
            span: 4,
        },
        {
            type: 'button',
            inputProps: {
                type: 'link',
                className: 'mt-7',
                onClick: () => {
                    setOpen(true)
                    setEditEnabled(false)
                    setOption(false)
                },
                children: 'Advanced Conditions',
            },
            span: 4,
        },
        {
            type: 'button',
            inputProps: {
                className: 'border-gray-500 mt-7',
                children: 'Add Shipping',
                onClick: () => {
                    form.submit()
                },
                type: 'default',
            },
            span: 4,
        },
    ]

    const Inputs: UploadProps = {
        name: 'file',
        accept: '.csv',
        maxCount: 1,
        showUploadList: { showRemoveIcon: false },
        itemRender: (originNode, file, fileList, actions) => (
            <div className='flex justify-between text-xs align-middle items-center'>
                {originNode}
                <Button
                    icon={<DeleteOutlined className='bg-transparent' />}
                    type='primary'
                    danger
                    size='small'
                    children={''}
                    onClick={() => actions.remove()}
                    className='ml-2 mt-1 p-1 text-sm bg-white'
                />
            </div>
        ),
        beforeUpload: (file) => {
            if (file.type !== 'text/csv') {
                message.error('Incorrect file format')
                return
            }
            const reader = new FileReader()
            reader.readAsText(file)
            reader.onload = (e) => {
                const content = e.target.result as string
                const regex = /^[0-9]{6}$/
                const trimmedContent = content.trim()
                const isHorizontal = trimmedContent.includes(',') && !trimmedContent.includes('\n')
                const isVertical = trimmedContent.includes('\n') && !trimmedContent.includes(',')
                if ((isHorizontal && isVertical) || (!isHorizontal && !isVertical)) {
                    message.error('Invalid file format')
                    return
                }

                let arrayOfPincodes = []

                if (isHorizontal) {
                    arrayOfPincodes = trimmedContent.split(',').map((item) => item.trim())
                } else if (isVertical) {
                    arrayOfPincodes = trimmedContent.split(/\r?\n/).map((item) => item.trim())
                }

                const filteredItem = arrayOfPincodes.filter((item) => item !== '')
                const isValid = filteredItem.every((line) => regex.test(line))

                if (!isValid) {
                    message.error('Invalid data: Please ensure all entries are valid 6-digit pincodes!')
                    return
                }

                form.setFieldValue(['shipping_prices', 'pincodes'], filteredItem.map(Number))
                form.setFieldValue(['shipping_prices', 'pincodes_defined'], true)
            }

            reader.onerror = () => {
                message.error('Error reading the file.')
                return
            }

            return false
        },
        onChange: (info: any) => {
            const { status, percent } = info.file
            if (status === 'uploading') {
            } else if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`)
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`)
            }
        },
    }

    const additionShippingFields: any = [
        {
            name: ['shipping_prices', 'visibility'],
            label: 'Shipping Visbility',
            prefix: '',
            type: 'select',
            disabled: true,
            options: [
                { value: true, label: 'Visible' },
                { value: false, label: 'Not Visible' },
            ],
            defaultChecked: { shippingVisibility },
            onChange: () => {
                setShippingVisibility(!shippingVisibility)
            },
            element: 1,
            span: 12,
        },
        {
            name: ['shipping_prices', 'payment_options'],
            label: 'Select Payment Option',
            placeholder: '',
            prefix: '',
            type: 'select',
            defaultValue: 'all',
            options: [
                { value: 'all', label: 'All' },
                { value: 'upi', label: 'UPI' },
                { value: 'cod', label: 'COD' },
            ],
            layout: '',
            element: 1,
            span: 12,
        },
        {
            name: ['shipping_prices', 'user_type'],
            label: 'User Type',
            title: 'Who do you want to give discounted price to? (Optional)',
            type: 'select',
            options: [
                { value: '' , label: "Select Discount on User Type" },
                { value: 'new', label: 'New User' },
                { value: 'repeat', label: 'Repeat User' },
            ],
            defaultValue: null,
            element: 2,
            span: 12,
        },
        {
            name: ['shipping_prices', 'discounted_price'],
            label: 'Discounted Shipping Price',
            title: '',
            type: 'number',
            element: 2,
            disabled: !optons,
            span: 12,
            rules: [
                {
                    pattern: /^[0-9]*\.?[0-9]*$/,
                    message: 'Please enter a valid positive number',
                },
            ],
        },
        {
            name: ['shipping_prices', 'total_amount'],
            label: 'Total Shipping Price',
            type: 'number',
            defaultValue: '',
            element: 3,
            span: 12,
            rules: [
                {
                    pattern: /^[0-9]*\.?[0-9]*$/,
                    message: 'Please enter a valid positive number',
                },
            ],
        },
        {
            name: ['shipping_prices', 'cod_charges'],
            label: 'COD Charges',
            title: '',
            type: 'number',
            element: 3,
            disabled: true,
            span: 12,
            rules: [
                {
                    pattern: /^[0-9]*\.?[0-9]*$/,
                    message: 'Please enter a valid positive number',
                },
            ],
        },
    ]

    const tableHeader: any = [
        {
            title: 'Shipping ID',
            dataIndex: 'shipping_uuid',
            key: 'shipping_uuid',
        },
        {
            title: 'Shipping Name',
            dataIndex: 'presentment_name',
            key: 'presentment_name',
        },
        {
            title: 'Shipping Price',
            dataIndex: 'price',
            key: 'price',
            render: (t, r) => {
                return formatAmount(t, 2)
            },
        },
        {
            title: 'Minimum Order Value',
            dataIndex: 'min',
            key: 'min',
            render: (t, r) => {
                return formatAmount(t, 2)
            },
        },
        {
            title: 'Maximum Order Value',
            dataIndex: 'max',
            key: 'max',
            render: (t, r) => {
                return formatAmount(t, 2)
            },
        },
        {
            title: 'Map Payment Method',
            dataIndex: 'payment_options',
            key: 'payment_options',
            render: (t, r) => {
                return t?.toUpperCase().trim()
            },
        },
        {
            title: 'Servicable PIN Code',
            dataIndex: 'pincodes',
            key: 'pincodes',
            render: (text, record) => {
                return (
                    <span className='flex justify-center cursor-pointer'>
                        {text && text?.length ? (
                            <img
                                src={fileImageSvg}
                                alt='file_image'
                                onClick={() => {
                                    const csvContent = text.join(',')
                                    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
                                    const url = URL.createObjectURL(blob)
                                    const link = document.createElement('a')
                                    link.setAttribute('href', url)
                                    link.setAttribute('download', 'data.csv')
                                    document.body.appendChild(link)
                                    link.click()
                                    document.body.removeChild(link)
                                }}
                            />
                        ) : (
                            ''
                        )}
                    </span>
                )
            },
        },
        {
            title: '',
            dataIndex: '',
            key: 'action',
            render: (text, record) => {
                return (
                    <Dropdown
                        placement='bottomLeft'
                        className='p-0'
                        menu={{
                            items: [
                                {
                                    key: '1',
                                    label: (
                                        <Button
                                            type='link'
                                            onClick={() => {
                                                setOpen(true)
                                                setEditEnabled(true)
                                                setPrevFormState(record)
                                                form.setFieldsValue({
                                                    shipping_prices: record,
                                                })
                                                if (record?.shipping_tag !== '') {
                                                    setIshidden(true)
                                                } else {
                                                    setIshidden(false)
                                                }
                                            }}
                                            className='w-full p-0 flex gap-x-1 items-center hover:text-primary-500'
                                        >
                                            <EditOutlined className='p-0' /> Edit
                                        </Button>
                                    ),
                                },
                                {
                                    key: '2',
                                    label: (
                                        <Button
                                            danger
                                            type='link'
                                            className='p-0 flex gap-x-1 items-center'
                                            onClick={() => {
                                                const filteredArrayOfShipping = shippingConfig?.filter(
                                                    (item) => item.shipping_uuid !== record.shipping_uuid,
                                                )
                                                const filterWeightConfig =
                                                    weightConfig?.config?.weight_based_shipping_rates?.length > 0
                                                        ? weightConfig?.config?.weight_based_shipping_rates?.filter(
                                                            (item) => item.shipping_uuid !== record.shipping_uuid,
                                                        )
                                                        : []
                                                saveConfig({
                                                    shipping_prices: filteredArrayOfShipping,
                                                    weight_based_shipping_rates: filterWeightConfig,
                                                })
                                            }}
                                        >
                                            <DeleteOutlined className='p-0' /> Delete
                                        </Button>
                                    ),
                                },
                            ],
                        }}
                    >
                        <img src={threeDotsIcon} alt='three dots' />
                    </Dropdown>
                )
            },
        },
    ]

    const dataSource = shippingConfig || []

    const getValesFromForm = () => {
        const { shipping_prices } = form.getFieldsValue(true) || {}
        let checkForErrors = false
        let requestObject = []

        const generateUniqueId = (shippingConfig) => {
            const maxId = shippingConfig.reduce((max, item) => Math.max(max, item?.id), 0)
            return maxId + 1
        }

        if (!shipping_prices.id && !shipping_prices.shipping_uuid) {
            const randomNum = Math.floor(Math.random() * 900) + 100
            form.setFieldsValue({
                shipping_prices: {
                    shipping_uuid: `#${randomNum}`,
                    id: shippingConfig && shippingConfig?.length ? generateUniqueId(shippingConfig) : 1,
                },
            })
        }

        if (
            parseFloat(form.getFieldValue(['shipping_prices', 'max'])) <=
            parseFloat(form.getFieldValue(['shipping_prices', 'min']))
        ) {
            form.setFields([
                {
                    name: ['shipping_prices', 'max'],
                    errors: ['Less then min value !'],
                },
            ])
            checkForErrors = true
        }

        if (isEditEnabled) {
            const existingShippingIndex = shippingConfig.findIndex(
                (config) => config.shipping_uuid === form.getFieldValue(['shipping_prices', 'shipping_uuid']),
            )
            if (existingShippingIndex !== -1) {
                const updatedConfig = [...shippingConfig]
                updatedConfig[existingShippingIndex] = {
                    ...form.getFieldsValue(true).shipping_prices,
                    min: parseFloat(form.getFieldValue(['shipping_prices', 'min'])),
                    max: parseFloat(form.getFieldValue(['shipping_prices', 'max'])),
                    price: parseInt(form.getFieldValue(['shipping_prices', 'price'])) || 0,
                    total_amount: parseFloat(form.getFieldValue(['shipping_prices', 'total_amount'])) || 0,
                    discounted_price: parseFloat(form.getFieldValue(['shipping_prices', 'discounted_price'])) || 0,
                }
                requestObject = updatedConfig
            } else {
                requestObject = [...shippingConfig]
            }
        } else {
            requestObject = [
                ...shippingConfig,
                {
                    ...form.getFieldsValue(true).shipping_prices,
                    min: parseFloat(form.getFieldValue(['shipping_prices', 'min'])),
                    max: parseFloat(form.getFieldValue(['shipping_prices', 'max'])),
                    price: parseInt(form.getFieldValue(['shipping_prices', 'price'])) || 0,
                    total_amount: parseFloat(form.getFieldValue(['shipping_prices', 'total_amount'])) || 0,
                    discounted_price: parseFloat(form.getFieldValue(['shipping_prices', 'discounted_price'])) || 0,
                },
            ]
        }

        if (checkForErrors) return

        saveConfig({
            shipping_prices: requestObject,
        })

        setOpen(false)
        setEditEnabled(false)
        form.resetFields()
    }

    const fieldValidation = (data: any) => {
        setTimeout(function () {
            if (data && data['presentment_name'] && isEditEnabled) {
                const isNamePresent = shippingConfig?.some(
                    (item) =>
                        item?.presentment_name === data['presentment_name'] &&
                        item?.presentment_name !== prevFormState?.presentment_name,
                )

                if (isNamePresent) {
                    form.setFields([
                        {
                            name: ['shipping_prices', 'presentment_name'],
                            errors: ['Provided name already exist.'],
                        },
                    ])
                }
            }

            if (data && (parseFloat(data['min']) >= 0 || parseFloat(data['max']) >= 0)) {
                let minOverlapName = ''
                let maxOverlapName = ''
                let minOverlapRange = ''
                let maxOverlapRange = ''

                if (shippingConfig && shippingConfig.length > 0) {
                    shippingConfig.forEach((item) => {
                        const itemMin = parseFloat(item.min) || 0
                        const itemMax = parseFloat(item.max) || Number.MAX_SAFE_INTEGER
                        const dataMin = parseFloat(data['min'])
                        const dataMax = parseFloat(data['max'])

                        if (!isNaN(dataMin) && rangesOverlap([itemMin, itemMax], [dataMin, dataMin])) {
                            minOverlapRange = item?.min
                            minOverlapName = item?.shipping_uuid
                        }

                        if (!isNaN(dataMax) && rangesOverlap([itemMin, itemMax], [dataMax, dataMax])) {
                            maxOverlapRange = item?.max
                            maxOverlapName = item?.shipping_uuid
                        }
                    })

                    if (
                        (minOverlapName && !isEditEnabled) ||
                        (minOverlapName &&
                            isEditEnabled &&
                            (parseFloat(data['min']) < parseFloat(prevFormState?.min) ||
                                parseFloat(data['min']) > parseFloat(prevFormState?.max)))
                    ) {
                        form.setFields([
                            {
                                name: ['shipping_prices', 'min'],
                                errors: ['Range similar to ' + minOverlapName],
                            },
                        ])
                    }

                    if (
                        (maxOverlapName && !isEditEnabled) ||
                        (maxOverlapName &&
                            isEditEnabled &&
                            (parseFloat(data['max']) < parseFloat(prevFormState?.min) ||
                                parseFloat(data['max']) > parseFloat(prevFormState?.max)))
                    ) {
                        form.setFields([
                            {
                                name: ['shipping_prices', 'max'],
                                errors: ['Range similar to ' + maxOverlapName],
                            },
                        ])
                    }
                }
            }

            if (data && parseFloat(data['total_amount']) > 0) {
                const totalAmount = parseFloat(data['total_amount'])
                const price = parseFloat(form.getFieldValue(['shipping_prices', 'price']))
                form.setFieldValue(
                    ['shipping_prices', 'cod_charges'],
                    totalAmount > price ? (totalAmount - price).toFixed(2) : 0,
                )
            }
        }, 10)
    }

    const selectOptions = [
        { label: 'Actual Price', value: 'false' },
        { label: 'Discounted Price', value: 'true' },
    ]


    return (
        <Card size='small'>
            <Form
                className='w-full'
                form={form}
                layout='vertical'
                onFinish={getValesFromForm}
                requiredMark={false}
                onValuesChange={(e) => {
                    fieldValidation(e?.shipping_prices)
                }}
                initialValues={{
                    shipping_prices: {
                        discounted_price: '',
                        external_shipping_match: '',
                        max: '',
                        min: '',
                        payment_options: 'all',
                        pincodes: [],
                        pincodes_defined: false,
                        price: '',
                        shipping_uuid: null,
                        id: null,
                        presentment_name: '',
                        total_amount: '',
                        user_type: '',
                        cod_charges: 0,
                        visibility: true,
                        shipping_tag: '',
                    },
                }}
            >
                <span className='flex flex-col gap-4'>
                    <Row className='h-20'>
                        <Col span={24} className='flex flex-col gap-4'>
                            <span className='font-semibold'>Shipping method should be applicable on which price?</span>
                            <Form.Item name='discounted_level_srates' className='w-40'>
                                <Select
                                    options={selectOptions}
                                    defaultValue={discounted_level_srates ? 'true' : 'false'}
                                    onChange={() => {
                                        setIsModalVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {shippingConfig && shippingConfig.length > 1 && (
                        <ShippingRangeChecker shippingConfig={shippingConfig} type={['min', 'max']} prefix={'₹'} />
                    )}
                    <Row>
                        <Col span={24}>
                            <Modal
                                title={
                                    <div className='flex justify-center items-center text-lg'>
                                        <WarningOutlined className='text-orange-500 mr-2' />
                                        <p className='text-orange-500'>Warning</p>
                                    </div>
                                }
                                visible={isModalVisible}
                                centered
                                width={'400px'}
                                closeIcon={false}
                                footer={
                                    <div className='flex flex-row justify-center space-x-4'>
                                        <Button
                                            onClick={() => {
                                                setIsModalVisible(false)
                                                form.setFieldValue('discounted_level_srates', 'false')
                                            }}
                                            children={'No'}
                                        />
                                        <Button
                                            type='primary'
                                            onClick={() => {
                                                let discounted_shipping = form.getFieldValue('discounted_level_srates')
                                                saveConfig({
                                                    discounted_level_srates: discounted_shipping === 'true',
                                                })
                                                setIsModalVisible(false)
                                            }}
                                            children={'Yes'}
                                        />
                                    </div>
                                }
                            >
                                <p className='flex justify-center text-center items-center text-sm'>
                                    Discounted Price will be applicable to all shipping methods. Do you want to
                                    continue?
                                </p>
                            </Modal>
                            <Row gutter={[12, 24]} align='middle' justify='space-between'>
                                {fields &&
                                    fields?.map((item, index) => (
                                        <Col span={item?.span} key={index}>
                                            <RenderField form={form} field={item} />
                                        </Col>
                                    ))}
                            </Row>
                        </Col>
                    </Row>
                </span>
                <Drawer
                    open={open}
                    width={'35rem'}
                    onClose={() => {
                        setOpen(false)
                        setOption(false)
                        if (isEditEnabled) {
                            form.resetFields()
                            setEditEnabled(false)
                        }
                    }}
                    bodyStyle={{ paddingTop: '5px' }}
                    title={!isEditEnabled ? 'Add New Shipping' : 'Edit Shipping'}
                    footer={
                        <span className='flex flex-row float-end gap-2'>
                            <Button
                                children={'Discard'}
                                onClick={() => {
                                    form.resetFields()
                                    setOpen(false)
                                }}
                            />
                            <Button
                                variant='primary'
                                children={!isEditEnabled ? 'Add Shipping' : 'Save Changes'}
                                onClick={() => {
                                    form.submit()
                                }}
                            />
                        </span>
                    }
                    className='overflow-y-auto p-0 m-0'
                >
                    <Row gutter={[16, 8]}>
                        {fields &&
                            fields.map((item, index) => (
                                <Col key={index} span={12}>
                                    {item.type !== 'button' && item.type !== 'link' && (
                                        <RenderField form={form} field={item} />
                                    )}
                                </Col>
                            ))}
                    </Row>
                    <Row gutter={[8, 8]}>
                        {additionShippingFields?.map(
                            (item, index) =>
                                item.element === 1 && (
                                    <Col span={item.span} key={index}>
                                        <Form.Item
                                            name={item?.name}
                                            label={item?.label}
                                            colon={false}
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            className='mb-1'
                                        >
                                            {item.type === 'select' && (
                                                <Select
                                                    defaultValue={item?.defaultValue}
                                                    options={item?.options}
                                                    disabled={item?.disabled}
                                                />
                                            )}
                                            {item.type === 'text' && <Input type={item?.type as 'text'} />}
                                        </Form.Item>
                                    </Col>
                                ),
                        )}
                        <Divider className='p-0 m-0' />
                        <Col span={24} className='p-0 m-0'>
                            <label className='font-semibold'>
                                Who do you want to give discounted price to? (Optional)
                            </label>
                        </Col>
                        {additionShippingFields?.map(
                            (item, index) =>
                                item.element === 2 && (
                                    <Col span={item.span} key={index}>
                                        <Form.Item
                                            name={item?.name}
                                            label={item?.label}
                                            colon={false}
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            className='mb-1'
                                            rules={item?.rules}
                                        >
                                            {item.type === 'select' && (
                                                <Select
                                                    defaultValue={item?.defaultValue}
                                                    options={item?.options}
                                                    onChange={(value) => {
                                                        if (value !== '' && value !== null) {
                                                            setOption(true)
                                                        } else {
                                                            form.setFieldValue(['shipping_prices', 'discounted_price'],'')
                                                            setOption(false)
                                                        }
                                                    }}
                                                />
                                            )}
                                            {item.type === 'number' && (
                                                <Input type={item?.type as 'number'} disabled={item?.disabled} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                ),
                        )}
                        <Divider className='p-0 m-0' />
                        <Col span={24} className='flex flex-row justify-between'>
                            <label className='font-semibold'>Do you want to charge dynamic COD fee? (Optional)</label>
                            <Switch
                                defaultChecked={isSwitchEnabled}
                                onChange={() => setSwitchChange(!isSwitchEnabled)}
                            />
                        </Col>

                        {additionShippingFields?.map(
                            (item, index) =>
                                item.element === 3 &&
                                isSwitchEnabled && (
                                    <Col span={item.span} key={index}>
                                        <Form.Item
                                            colon={false}
                                            className='mb-1'
                                            name={item?.name}
                                            label={item?.label}
                                            rules={item?.rules}
                                        >
                                            <Input type={item?.type as 'number'} disabled={item?.disabled} />
                                        </Form.Item>
                                    </Col>
                                ),
                        )}
                    </Row>
                    <Row gutter={[12, 24]}>
                        <Col span={24}>
                            <Form.Item
                                name={['shipping_prices']['pincodes']}
                                label={
                                    <span className='font-semibold'>
                                        Upload List of Serviceable PIN Codes (Optional)
                                    </span>
                                }
                                colon={false}
                                className='mb-1'
                            >
                                <Dragger {...Inputs}>
                                    <p className='ant-upload-drag-icon'>
                                        <InboxOutlined />
                                    </p>
                                    <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                                    <p className='ant-upload-hint'>Supports .CSV file (Maximum file size 200 MB)</p>
                                </Dragger>
                                <span className='flex w-full p-2 mt-1 rounded-md flex-row justify-between text-sm items-center border-opacity-50 bg-[#e6f4ff]'>
                                    <span className='flex flex-row gap-1'>
                                        <span>
                                            <InfoCircleFilled className='text-[#3a9dfa]' />
                                        </span>
                                        <span>Use suggested format shown in the sample file</span>
                                    </span>
                                    <span>
                                        <a href={samplefile} download='sample'>
                                            Download Sample
                                        </a>
                                    </span>
                                </span>
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Row align='middle' justify='space-between' className='w-full'>
                        <Col className='flex items-center font-semibold'>
                            Do you want this shipping to be visible on checkout?
                        </Col>
                        <Col className='flex items-center justify-end'>
                            <Form.Item name={['shipping_prices', 'visibility']} style={{ margin: 0 }}>
                                <Switch
                                    defaultChecked={shippingVisibility}
                                    onChange={() => {
                                        setShippingVisibility(!shippingVisibility)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row> */}

                    <Row gutter={[12, 16]} align={'middle'}>
                        <Col span={18} className='font-semibold mt-1'>
                            Do you want to pass a specific tag to Shopify against this shipping name? (Optional)
                        </Col>
                        <Col span={6} className='text-right'>
                            <Switch
                                checked={ishidden}
                                onChange={() => {
                                    setIshidden(!ishidden)
                                    if (ishidden) {
                                        form.setFieldValue(['shipping_prices', 'shipping_tag'], '')
                                    }
                                }}
                            />
                        </Col>
                        {ishidden && (
                            <Col span={24}>
                                <Form.Item
                                    className='mb-1'
                                    name={['shipping_prices', 'shipping_tag']}
                                    rules={[
                                        {
                                            pattern: /^[a-zA-Z]+(,[a-zA-Z]+)*$/,
                                            message:
                                                'Enter a single word or multiple words separated by commas (e.g., SDD,NDD,Express)',
                                        },
                                    ]}
                                >
                                    <Input
                                        count={{
                                            max: 32,
                                            show: true,
                                        }}
                                        placeholder='Eg. SDD,NDD,Express'
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <span className='flex w-full p-2 mt-1 rounded-md flex-row justify-between text-sm items-center border-opacity-50 bg-[#e6f4ff]'>
                            <span className='flex flex-row gap-1'>
                                <span>
                                    <InfoCircleFilled className='text-[#3a9dfa]' />
                                </span>
                                <span>
                                    You can configure Weight Based and Discount Based Shipping from the main menu after
                                    saving this Shipping config from the options available.
                                </span>
                            </span>
                        </span>
                    </Row>
                </Drawer>
            </Form>

            <Row className='mt-5'>
                {shippingConfig && shippingConfig?.length > 0 && (
                    <Col span={24}>
                        <Table
                            className='capitalize'
                            columns={tableHeader}
                            dataSource={dataSource}
                            pagination={{ pageSize: 8, position: ['bottomRight'] }}
                        />
                    </Col>
                )}
                <Col span={24}>
                    <Alert
                        type='info'
                        message={
                            <p className='text-sm'>
                                You can set product level shipping on Shopify. Just add ship@price tag on Shopify.
                            </p>
                        }
                        description={
                            <>
                                Eg: To set free shipping for a product, add <code>ship@0</code> tag. <br />
                                <Space /> To set a shipping price for a specific product, add{' '}
                                <code>ship@price</code> tag.
                            </>
                        }
                        showIcon
                        icon={<InfoCircleOutlined className='mt-1' style={{ fontSize: '14px' }} />}
                        className='p-2 text-xs'
                    />
                </Col>
            </Row>

        </Card>
    )
}

export default ShippingMethodConfig
