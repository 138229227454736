import React, { useEffect, useRef, useState } from 'react'
import {
    Tooltip,
    Button,
    BoldOutlined,
    ItalicOutlined,
    UnderlineOutlined,
    LinkOutlined,
    Input,
    message,
    Popover,
    MinusOutlined,
    PlusOutlined,
} from 'gokwik-ui-kit'

const RichTextEditor = ({ ...props }) => {
    const [textCount, setActualTextCount] = useState<String>('')
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
    const [selectedRange, setSelectedRange] = useState(null)
    const [url, setUrlInput] = useState('')
    const { onChange, selectedValue } = props
    const ref = useRef(selectedValue)


    const handleFormatting = (tag: string) => {
        if (tag === 'createLink') {
            const selection = window.getSelection()
            if (selection && selection.toString().length > 0) {
                setSelectedRange(selection.getRangeAt(0))
                setIsModalVisible(true)
            } else {
                message.info('Please Select a Text to Proceed !!')
            }
        } else {
            document.execCommand(tag, false, null)
            setUrlInput('')
        }
        document.getElementById('editor')?.innerHTML
    }

    const extractMatchFromString = (html: String) => {
        const result = {
            htmlString: html,
            actualText: '',
            italicContent: '',
            boldContent: '',
            underlineContent: '',
        }

        const italicMatch = html.match(/<i>(.*?)<\/i>/)
        if (italicMatch) {
            result.italicContent = italicMatch[1]
            result.actualText += result.italicContent
        }

        const boldMatch = html.match(/<b>(.*?)<\/b>/)
        if (boldMatch) {
            result.boldContent = boldMatch[1]
            result.actualText += result.boldContent
        }
        const underlineMatch = html.match(/<u>(.*?)<\/u>/)
        if (underlineMatch) {
            result.underlineContent = underlineMatch[1]
            result.actualText += result.underlineContent
        }
        result.actualText = html
            .replace(/<[^>]*>/g, '')
            .replace('&nbsp;', ' ')
            .replace('<br>', '')
            .trim()
        setActualTextCount(result.actualText)
        return result
    }

    const handleInputChange = (e: React.FormEvent<HTMLDivElement>) => {
        let currentElementValue = e.currentTarget.innerHTML || null; 
        const editor = e.currentTarget;
        const text = editor.innerText;
        
        if(text.length > 64){
            const trimmedText = text.slice(0,64);
            editor.innerHTML = trimmedText;
            setActualTextCount(trimmedText)
            return;
        }
        
        if (currentElementValue) {
            const currentFetchedText = extractMatchFromString(currentElementValue)
            onChange(currentFetchedText || {})
        } else {
            onChange({})
        }
    }

    const handleLinkSubmit = () => {
        if (url && selectedRange) {
            const selection = window.getSelection()
            selection.removeAllRanges()
            selection.addRange(selectedRange)
            document.execCommand('createLink', false, url)
            setIsModalVisible(false)
            setUrlInput('')
            setSelectedRange(null)
            document.getElementById('editor')?.innerHTML
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false)
        setUrlInput('')
        setSelectedRange(null)
    }

    return (
        <div className='border rounded-md bg-white'>
            <div className='flex  gap-2 bg-gray-100 rounded-tl-[5px] rounded-tr-[5px]'>
                <Tooltip title='Bold text-lg'>
                    <Button
                        type='text'
                        icon={<BoldOutlined />}
                        children={''}
                        onClick={() => handleFormatting('bold')}
                    />
                </Tooltip>
                <Tooltip title='Italic'>
                    <Button
                        type='text'
                        icon={<ItalicOutlined />}
                        children={''}
                        onClick={() => handleFormatting('italic')}
                    />
                </Tooltip>
                <Tooltip title='Underline'>
                    <Button
                        type='text'
                        icon={<UnderlineOutlined />}
                        children={''}
                        onClick={() => handleFormatting('underline')}
                    />
                </Tooltip>
                <Popover
                    content={
                        <div className='flex flex-col gap-2'>
                            <Input
                                placeholder='Enter URL'
                                value={url}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUrlInput(e.target.value)}
                            />
                            <div className='flex justify-end gap-2'>
                                <Button onClick={handleCancel}>
                                    <MinusOutlined/>
                                </Button>
                                <Button type='primary' onClick={handleLinkSubmit}>
                                    <PlusOutlined/>
                                </Button>
                            </div>
                        </div>
                    }
                    trigger={'click'}
                    open={isModalVisible}
                    onOpenChange={handleLinkSubmit}
                >
                    <Button
                        type='text'
                        icon={<LinkOutlined />}
                        children={''}
                        onClick={() => handleFormatting('createLink')}
                    />
                </Popover>
            </div>
            <div
                id='editor'
                contentEditable
                onInput={handleInputChange}
                className='p-[8px] min-h-[8px] border-solid border-gray-100 rounded-br-[5px] rounded-bl-[5px]'
                data-max-length='64'
            >
                {ref.current}
            </div>
            
            <div className='text-right text-gray-500 text-sm rounded-md border'>
                {textCount?.length - 1 < 0 ? textCount?.length : textCount?.length}/64
            </div>
        </div>
    )
}

export default RichTextEditor
