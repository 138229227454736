// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@tailwind base;
@tailwind components;
@tailwind utilities;

.checkout-analytics .non-gk-abc .summary-blocks {
    flex-wrap: nowrap;
}
.checkout-analytics .non-gk-abc .summary-blocks .summary-block {
    max-width: 25%;
}

.checkout-analytics .non-gk-abc .summary-blocks .summary-block .summary-title {
    width: 100%;
    padding: 0 0.5rem;
}

.checkout-analytics .non-gk-abc .summary-blocks .summary-block .summary-title span.capitalize {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,cAAc;AACd,oBAAoB;AACpB,mBAAmB;;AAEnB;IACI,iBAAiB;AACrB;AACA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":["@tailwind base;\n@tailwind components;\n@tailwind utilities;\n\n.checkout-analytics .non-gk-abc .summary-blocks {\n    flex-wrap: nowrap;\n}\n.checkout-analytics .non-gk-abc .summary-blocks .summary-block {\n    max-width: 25%;\n}\n\n.checkout-analytics .non-gk-abc .summary-blocks .summary-block .summary-title {\n    width: 100%;\n    padding: 0 0.5rem;\n}\n\n.checkout-analytics .non-gk-abc .summary-blocks .summary-block .summary-title span.capitalize {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
