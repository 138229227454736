import { Alert, Button, Card, Col, Form, Row } from 'gokwik-ui-kit'
import { Link } from 'react-router-dom'
import LinkIcon from '@library/images/PixelIcons/link.svg'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import RenderField from '@library/utilities/helpers/renderFormItem'
import { useEffect } from 'react'

const CodLimits = () => {
    const [form] = Form.useForm()
    const { config, loading, refetch, saveConfig } = useMerchantConfigApi({
        useCache: true,
        configSaveUrl: APIEndPoints.updateDiscountConfig,
    })

    const removeError = () => {
        const lowerLimitValue = form.getFieldValue('cod_lower_limit')
        const upperLimitValue = form.getFieldValue('cod_limit')
        if (
            lowerLimitValue <= upperLimitValue &&
            (form.getFieldError('cod_lower_limit') || form.getFieldError('cod_limit'))
        ) {
            form.validateFields(['cod_lower_limit', 'cod_limit'])
        }
    }

    const fields = [
        {
            type: 'inputNumber',
            name: 'cod_lower_limit',
            label: 'COD Lower Limit',
            labelWithDescription: true,
            description:
                'The limit below which Cash on Delivery option will not be available tof the customers on GoKwik Checkout.',
            span: 24,
            inputProps: {
                min: 0,
            },
            rules: [
                {
                    validator(_, value) {
                        console.log('value', value, form.getFieldValue('cod_limit'))

                        if (value > form.getFieldValue('cod_limit')) {
                            return Promise.reject('COD Lower Limit should be less than COD Upper Limit')
                        }
                        return Promise.resolve()
                    },
                },
            ],
        },
        {
            type: 'inputNumber',
            name: ['cod_limit'],
            label: 'COD Upper Limit',
            labelWithDescription: true,
            description:
                'The limit above which Cash on Delivery option will not be available for the customers on GoKwik Checkout.',
            span: 24,
            rules: [
                {
                    validator(_, value) {
                        if (value < form.getFieldValue('cod_lower_limit')) {
                            return Promise.reject('COD Upper Limit should be greater than COD Lower Limit')
                        }
                        return Promise.resolve()
                    },
                },
            ],
            inputProps: {
                min: 0,
            },
        },
    ]
    useEffect(() => {
        if (config) {
            form.setFieldsValue({
                cod_lower_limit: config.cod_lower_limit,
                cod_limit: config.cod_limit,
            })
        }
    }, [config])
    return (
        <Card size='small'>
            <Alert
                showIcon
                type='info'
                message='This setting overrides Kwik Flow rules and works independently. Check Kwik Flows.'
                action={
                    <Link to='/rto/settings/kwik-flows'>
                        <Button className='focus:outline-none'>
                            <img src={LinkIcon} className='w-3 h-3 mr-2' alt='link icon' />
                            <span>Take me there</span>
                        </Button>
                    </Link>
                }
            />
            <Form form={form} onFinish={saveConfig} layout='vertical' onValuesChange={removeError} scrollToFirstError>
                <Row gutter={[16, 16]} className='mt-4'>
                    {fields.map((field) => (
                        <Col span={field.span} key={field.label}>
                            <RenderField field={field} form={form} />
                        </Col>
                    ))}
                    <Col>
                        <Form.Item className='my-0'>
                            <Button type='primary' htmlType='submit'>
                                Save
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}

export default CodLimits
