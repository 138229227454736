import { validateOfferDiscount } from '@library/utilities/helpers/validations'
import { OfferAndDiscount } from '@library/utilities/interface'
import { OffersDiscountKeys } from '@library/utilities/constants/constants'
import { Button, Col, Drawer, Input, InputNumber, Row, Select, Switch, TextArea, message } from 'gokwik-ui-kit'
import { useState } from 'react'

interface Props {
    discount?: Partial<OfferAndDiscount>
    open: boolean
    onCancel: () => void
    onSave: (discount: Partial<OfferAndDiscount>) => void
}

const AddEditOffer = ({ discount, open, onCancel, onSave }: Props) => {
    const [inputs, setInputs] = useState<Partial<OfferAndDiscount>>({
        discount_code: discount?.discount_code ?? null,
        discount_detail: discount?.discount_detail ?? null,
        additional_details: discount?.additional_details
            ? {
                  ...discount?.additional_details,
                  bullet_points: discount?.additional_details?.bullet_points.toString() ?? '',
              }
            : { bullet_points: '' },
        is_active: discount ? discount?.is_active : true,
        visibility:
            discount?.visibility ?? (discount?.is_cohort_based ? OffersDiscountKeys.only_when_eligible.value : null),
        user_type: discount?.user_type ?? null,
        min_price: discount?.min_price ? +discount?.min_price : 0,
        max_price: discount?.max_price ? +discount?.max_price : null,
        sort_order: discount?.sort_order,
        min_quantity: discount?.min_quantity ? +discount?.min_quantity : 0,
        max_quantity: discount?.max_quantity ? +discount?.max_quantity : 0,
        is_cohort_based: discount ? discount?.is_cohort_based : false,
    })
    return (
        <Drawer title={`${discount ? 'Edit' : 'Add'} discount`} open={open} onClose={onCancel}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <div className='flex items-center gap-x-2'>
                        <span>Status</span>
                        <Switch
                            checked={inputs.is_active}
                            onChange={(value) => setInputs((prev) => ({ ...prev, is_active: value }))}
                        />
                        <span>Is Cohort based</span>
                        <Switch
                            checked={inputs.is_cohort_based}
                            onChange={(value) =>
                                setInputs((prev) => ({
                                    ...prev,
                                    is_cohort_based: value,
                                    visibility: value ? OffersDiscountKeys.only_when_eligible.value : prev.visibility,
                                }))
                            }
                        />
                    </div>
                </Col>
                <Col span={24}>
                    <Input
                        placeholder='Enter Shopify discount code'
                        label='Discount code*'
                        value={inputs.discount_code}
                        onChange={(e) => setInputs((prev) => ({ ...prev, discount_code: e.target.value }))}
                        maxLength={60}
                    />
                </Col>
                <Col span={24}>
                    <Input
                        placeholder='Enter description'
                        label='Discount description*'
                        value={inputs.discount_detail}
                        onChange={(e) => setInputs((prev) => ({ ...prev, discount_detail: e.target.value }))}
                        maxLength={60}
                    />
                </Col>
                <Col span={24}>
                    <TextArea
                        placeholder='Enter additional details'
                        label='Additional details'
                        value={
                            typeof inputs.additional_details.bullet_points === 'string'
                                ? inputs.additional_details.bullet_points
                                : ''
                        }
                        onChange={(e) =>
                            setInputs((prev) => ({
                                ...prev,
                                additional_details: {
                                    ...prev.additional_details,
                                    bullet_points: e.target.value,
                                },
                            }))
                        }
                    />
                </Col>
                <Col span={24}>
                    <Select
                        className='w-full'
                        label='Visibility*'
                        placeholder='Please select'
                        value={inputs.visibility}
                        onChange={(value) => setInputs((prev) => ({ ...prev, visibility: value }))}
                        options={[
                            {
                                label: OffersDiscountKeys.only_when_eligible.label,
                                value: OffersDiscountKeys.only_when_eligible.value,
                            },
                            { label: OffersDiscountKeys.always.label, value: OffersDiscountKeys.always.value },
                        ]}
                    />
                </Col>
                <Col span={24}>
                    <Select
                        className='w-full'
                        label='User type*'
                        placeholder='Please select'
                        value={inputs.user_type}
                        onChange={(value) => setInputs((prev) => ({ ...prev, user_type: value }))}
                        options={
                            inputs.is_cohort_based
                                ? [
                                      {
                                          label: OffersDiscountKeys.shopify_based_cohort.label,
                                          value: OffersDiscountKeys.shopify_based_cohort.value,
                                      },
                                      {
                                          label: OffersDiscountKeys.csv_based_cohort.label,
                                          value: OffersDiscountKeys.csv_based_cohort.value,
                                      },
                                  ]
                                : [
                                      { label: OffersDiscountKeys.all.label, value: OffersDiscountKeys.all.value },
                                      {
                                          label: OffersDiscountKeys.new_users_only.label,
                                          value: OffersDiscountKeys.new_users_only.value,
                                      },
                                      {
                                          label: OffersDiscountKeys.repeat_users_only.label,
                                          value: OffersDiscountKeys.repeat_users_only.value,
                                      },
                                  ]
                        }
                    />
                </Col>
                <Col span={24}>
                    <InputNumber
                        className='w-full'
                        label='Min price'
                        value={inputs.min_price}
                        onChange={(value) => setInputs((prev) => ({ ...prev, min_price: value }))}
                    />
                </Col>
                <Col span={24}>
                    <InputNumber
                        className='w-full'
                        label='Max price'
                        value={inputs.max_price}
                        onChange={(value) => setInputs((prev) => ({ ...prev, max_price: value }))}
                    />
                </Col>
                <Col span={24}>
                    <InputNumber
                        className='w-full'
                        label='Min quantity'
                        value={inputs.min_quantity}
                        onChange={(value) => setInputs((prev) => ({ ...prev, min_quantity: +value }))}
                    />
                </Col>
                <Col span={24}>
                    <InputNumber
                        className='w-full'
                        label='Max quantity'
                        value={inputs.max_quantity}
                        onChange={(value) => setInputs((prev) => ({ ...prev, max_quantity: +value }))}
                    />
                </Col>
                <Col span={12} className='mt-2'>
                    <Button
                        className='w-full'
                        variant='primary'
                        onClick={() => {
                            const validation = validateOfferDiscount(inputs)
                            if (validation.status) {
                                onSave({ ...(discount && { id: discount.id }), ...inputs })
                            } else {
                                message.error(validation.msg)
                            }
                        }}
                    >
                        Save
                    </Button>
                </Col>
                <Col span={12} className='mt-2'>
                    <Button
                        className='w-full'
                        onClick={() => {
                            onCancel()
                        }}
                    >
                        Cancel
                    </Button>
                </Col>
            </Row>
        </Drawer>
    )
}

export default AddEditOffer
