import { useState, useEffect } from 'react';
import { RateComponent } from './RateComponent';
import { Button, Modal } from 'gokwik-ui-kit';
import { ReviewModal } from './ReviewModal';
import useShopifyMerchantConfigApi from '@library/utilities/hooks/useShopifyMerchantConfig';
export const CloseReviewModal = (props: { setIsConfigSaved, events: any }) => {
    const { setIsConfigSaved, events } = props;
    const [rating, setRating] = useState(0);
    const [review, setReview] = useState("");
    const [isReviewModalVisible, setIsReviewModalVisible] = useState(false);
    const [initialModalVisible, setInitialModalVisible] = useState(true);
    const [error, setError] = useState('');
    const [closeCount, setCloseCount] = useState(0);
    const [isReviewSubmitted, setIsReviewSubmitted] = useState(false);
    const { sendRatingFeedback } = useShopifyMerchantConfigApi()
    useEffect(() => {
        const storedCloseCount = localStorage.getItem('reviewCloseCount');
        const storedIsReviewSubmitted = localStorage.getItem('isReviewSubmitted');
        if (storedCloseCount) {
            setCloseCount(parseInt(storedCloseCount, 10));
        }
        if (storedIsReviewSubmitted === 'true') {
            setIsReviewSubmitted(true);
        }
    }, []);

    const handleRateChange = (value) => {
        events.fireClickedEvent({
            event: 'rating-change-clicked',
            Rating: value, 
            ratingSource: "pop-up",
        });
        if (value <= 4) {
            setRating(value);
            setInitialModalVisible(false);
            setIsReviewModalVisible(true);
        } else if (value === 5) {
            resetToInitialStates();
            window.open('https://apps.shopify.com/kwikcheckout#modal-show=WriteReviewModal', '_self');
        }
    };
    const updateLSReviewCount = () => {
        const newCloseCount = closeCount + 1;
        setCloseCount(newCloseCount);
        localStorage.setItem('reviewCloseCount', newCloseCount.toString());
    }
    const handleCancel = () => {
        setIsConfigSaved(false);
        updateLSReviewCount();
    };
    const resetToInitialStates = () => {
        setIsReviewModalVisible(false);
        setInitialModalVisible(true)
        setIsConfigSaved(false);
        setRating(0);
    }

    const fireRatingStarClicked = () => {
        events.fireClickedEvent({
            event: 'rating-change-clicked',
            Rating: rating, 
            ratingSource: "pop-up",
        });
    }
    const submitReview = async () => {
        if (review?.length && review?.length < 30) {
            setError('Minimum character limit is 30.');
        } else {
            setError('');
            let popupModal = true;
            await sendRatingFeedback(review, rating, popupModal, setReview, setIsReviewSubmitted, events);
            resetToInitialStates();
        }
    }
    
    return (closeCount < 2 && !isReviewSubmitted) &&  (
        <>
            <Modal
                centered
                open={initialModalVisible}
                onCancel={handleCancel}
                className='w-full'
                footer={<div className='bg-gray-200 mt-6 flex justify-center py-4'>
                    <Button onClick={handleCancel} variant='primary'>
                        Close
                    </Button>
                </div>}
                closeIcon={null}
            >
                <div className='flex flex-col justify-center items-center gap-2' >
                    <p className='font-semibold text-[20px] text-[rgba(0,0,0,0.88)]' >We’d really appreciate it if you could also leave us a 5-star rating on the Shopify App Store.</p>
                    <RateComponent rating={rating} onRateChange={handleRateChange} />
                </div>
            </Modal>

            <ReviewModal
                review={review}
                onReviewChange={(trimmedReview) => setReview(trimmedReview)}
                onSubmit={submitReview}
                visible={isReviewModalVisible}
                onCancel={() => {
                    updateLSReviewCount();
                    resetToInitialStates();
                    fireRatingStarClicked();
                }}
                error={error}
            />
        </>
    );
};