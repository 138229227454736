import React, { useEffect, useRef, useState } from 'react'
import {
    Row,
    Col,
    Tag,
    Tooltip,
    Button,
    Input,
    Table,
    Space,
    ColumnProps,
    MinusCircleOutlined,
    PlusCircleOutlined,
    Modal,
    CloseCircleOutlined,
    message,
    Radio,
    DeleteOutlined,
} from 'gokwik-ui-kit'
import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import { useSelector } from 'react-redux'
import { getMerchantDetails, isMerchantSelector } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import SearchProduct from './search-product'
import { useLocation } from 'react-router'
import { navigateToUrl } from 'single-spa'
import { formatAmount } from '@library/utilities/helpers/helper'
import { EditOrderPayload } from '@library/utilities/interface'

export default function ({}) {
    const [lineItems, setLineItems] = useState([])
    const [modalData, setModalData] = useState({ show: false, item: null })
    const [submitBtnEnable, setSubmitBtnEnable] = useState(true) // Assuming you have logic to enable/disable the submit button

    const [editOrderDetails, setEditOrderDetails] = useState(null)
    const [editError, setEditError] = useState('')
    const [errorItems, setErrorItems] = useState([])
    const config = useSelector(getMerchantDetails)
    const isMerchantUser = useSelector(isMerchantSelector)
    const route = useLocation()
    const mainContentRef = useRef()
    const location = useLocation()
    const { pathname } = location
    const orderNumber = pathname.split('/').pop()

    const [discount, setDiscount] = useState({
        recentlyDeletedItems: [],
        isModalVisible: false,
        discountType: 'fixed_amount',
        discountValue: null,
        discountTitle: '',
        discountApplied: false,
        discountButtonLabel: 'Add Discount',
        discountVisible: false,
        addProductModal: false,
        preAppliedDiscount: false,
        fetchedItems: [],
        discountedItems: [],
        itemToDelete: null,
        itemDiscount: 0,
    })
    const [pendingProduct, setPendingProduct] = useState(null)
    const updateState = (key, value) => {
        setDiscount((prev) => ({ ...prev, [key]: value }))
    }

    const columns: ColumnProps<any>[] = [
        { title: 'S No.', dataIndex: 'index', key: 'index', width: 50, align: 'left' },
        {
            title: 'Product',
            dataIndex: 'product',
            key: 'product',
            width: 200,
            align: 'left',
            render: (_, record) => {
                return (
                    <Space direction='horizontal'>
                        <p className='w-[200px]'>{_}</p>

                        <img className='w-8 rounded' src={record.image} />
                    </Space>
                )
            },
        },
        { title: 'SKU ID', dataIndex: 'skuId', key: 'skuId', width: 150, align: 'left' },
        { title: 'Product ID', dataIndex: 'productId', key: 'productId', width: 200, align: 'left' },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center',
            width: 100,
            render: (_, record) => (
                <div className='justify-center mt-2'>
                    <Space>
                        <div className='border-solid border-[0.4px] border-gray-500 rounded-md px-3 py-2 gap-x-3 flex items-center  '>
                            <MinusCircleOutlined
                                onClick={() => {
                                    record.quantity > 1
                                        ? modifyQty(record.variant_id, record.quantity - 1)
                                        : showDelete(record)
                                    // modifyQty(record.variant_id, record.quantity - 1)
                                }}
                                className='cursor-pointer'
                            />
                            {_}
                            <PlusCircleOutlined
                                onClick={() => modifyQty(record.variant_id, record.quantity + 1)}
                                className='cursor-pointer'
                            />
                        </div>
                    </Space>
                    <p className='mt-1 text-xs text-gray-400'>Each @ ₹{(+record.price).toFixed(2)}</p>
                </div>
            ),
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'center',
            width: 150,
            render: (_, record) => {
                const itemTotal = record.quantity * +record.price
                const shouldApplyDiscount = discount.discountedItems.includes(record.variant_id)
                const itemDiscount =
                    shouldApplyDiscount && discount.discountApplied
                        ? discount.discountType === 'fixed_amount'
                            ? Math.min(discount.discountValue, itemTotal)
                            : (discount.discountValue / 100) * itemTotal
                        : 0

                const discountedTotal = discount.discountApplied ? itemTotal - itemDiscount : itemTotal

                return <p className='text-center width-10'>{formatAmount(discountedTotal, 0, 2)}</p>
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: 100,
            render: (_, record) => (
                <Space>
                    <DeleteOutlined
                        onClick={() => deleteItem(record.variant_id)}
                        className='w-5 text-red-400 cursor-pointer'
                    />
                </Space>
            ),
        },
    ]

    const recentlyDeleted: ColumnProps<any>[] = [
        {
            title: 'S No.',
            dataIndex: 'index',
            key: 'index',
            align: 'left',
            width: 60,
            render: (_, __, index) => index + 1,
        },
        {
            title: 'Product',
            dataIndex: 'product',
            key: 'product',
            width: 200,
            align: 'left',
            render: (_, record) => {
                return (
                    <Space direction='horizontal'>
                        <p className='w-[200px]'>{_}</p>

                        <img className='w-8 rounded' src={record.image} />
                    </Space>
                )
            },
        },
        {
            title: 'SKU ID',
            dataIndex: 'skuId',
            key: 'skuId',
            align: 'left',
            width: 150,
        },
        {
            title: 'Product ID',
            dataIndex: 'productId',
            key: 'productId',
            align: 'left',
            width: 200,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center',
            width: 100,
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'center',
            width: 150,
            render: (text) => `₹${text}`,
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 100,
            render: (_, record) => (
                <Button
                    onClick={() => {
                        handleReAddItem(record.variant_id)
                    }}
                >
                    Re-Add Line Item
                </Button>
            ),
        },
    ]
    const data = lineItems?.map((item, i) => ({
        key: i,
        index: i + 1,
        product: item.p_name || `${item.product_name}-${item.variant_name}`,
        skuId: item.sku,
        productId: item.product_id,
        quantity: item.quantity,
        total: item.quantity * item.price,
        price: item.price,
        id: item.id,
        image: item.product_thumbnail_url,
        variant_id: item.variant_id,
        isItemReadded: item.isItemReadded || false,
        addedFromSearch: item.addedFromSearch,
    }))
    const recentlyDeletedData = discount.recentlyDeletedItems?.map((item, i) => ({
        key: i,
        index: i + 1,
        product: item.p_name || `${item.product_name}-${item.variant_name}`,
        skuId: item.sku,
        productId: item.product_id,
        quantity: item.quantity,
        total: item.quantity * item.price,
        price: item.price,
        id: item.id,
        image: item.product_thumbnail_url,
        variant_id: item.variant_id,
    }))

    const calculateGrandTotal = () => {
        const total = lineItems.reduce((sum, item) => {
            const itemTotal = item.quantity * item.price
            const applyDiscount = discount.discountedItems.includes(item.variant_id)
            const itemDiscount =
                applyDiscount && discount.discountApplied
                    ? discount.discountType === 'fixed_amount'
                        ? Math.min(discount.discountValue, itemTotal)
                        : (discount.discountValue / 100) * itemTotal
                    : 0

            const discountedTotal = discount.discountApplied ? itemTotal - itemDiscount : itemTotal

            return sum + discountedTotal
        }, 0)

        return formatAmount(total, 0, 2)
    }

    const handleReAddItem = (itemId) => {
        const itemToReAdd = discount.recentlyDeletedItems.find((item) => item.variant_id === itemId)
        const isItemAlreadyExists = lineItems.some(
            (item) => item.productId === itemToReAdd.productId && item.variant_id === itemToReAdd.variant_id,
        )
        if (isItemAlreadyExists) {
            message.error('Product already exists in the table.')
            return
        }
        updateState(
            'recentlyDeletedItems',
            discount.recentlyDeletedItems.filter((item) => item.variant_id !== itemId),
        )
        setLineItems((prev) => [...prev, { ...itemToReAdd, isItemReadded: true }])
    }

    const handleRemoveDiscounts = () => {
        updateState('discountTitle', '')
        updateState('discountType', 'fixed_amount')
        updateState('discountValue', null)
        updateState('discountButtonLabel', 'Add Discount')
    }
    const handleOk = () => {
        if (!discount.discountTitle || discount.discountTitle.trim() === '') {
            message.error('Discount title is required.')
            return
        }
        if (!discount.discountValue) {
            message.error('Discount value is required')
            return
        }

        const discountedVariantIds = lineItems.map((item) => item.variant_id)
        updateState('discountedItems', discountedVariantIds)
        updateState('discountApplied', true)
        updateState(
            'discountButtonLabel',
            discount.discountType === 'fixed_amount'
                ? `Total Discount: ₹${discount.discountValue}`
                : `Total Discount: ${discount.discountValue}%`,
        )
        updateState('isModalVisible', false)
        setSubmitBtnEnable(true)
    }

    const showDelete = (item) => {
        setModalData({ show: true, item: item })
    }
    const shouldHideUI = () => {
        if (!discount.itemDiscount) {
            return true
        }
        if (lineItems.length === 0) {
            return true
        }

        const areItemsIdentical = lineItems.every((item, index) => {
            const fetchedItem = discount.fetchedItems[index]
            return (
                item?.product_id === fetchedItem?.product_id &&
                item?.quantity === fetchedItem?.quantity &&
                item?.variant_id === fetchedItem?.variant_id
            )
        })
        if (areItemsIdentical && discount.preAppliedDiscount) {
            return true
        }

        const isQuantityModified = lineItems.some((item) => {
            const fetchedItem = discount.fetchedItems.find((fetched) => fetched.product_id === item.product_id)
            return fetchedItem && item.quantity !== fetchedItem.quantity
        })
        if (discount.preAppliedDiscount && isQuantityModified) {
            return true
        }

        if (discount.preAppliedDiscount) {
            return true
        }
        return false
    }

    useEffect(() => {
        updateBreadcrumbs((prev) =>
            prev[prev?.length - 1]?.text === String(orderNumber)
                ? prev
                : [
                      prev[0],

                      {
                          key: 'orders',
                          href: '/checkout/orders',
                          text: 'Orders',
                      },
                      {
                          key: 'edit',
                          href: `/checkout/orders/edit/${orderNumber}`,
                          text: 'Edit',
                      },
                      {
                          key: 'order-details',
                          href: `/checkout/orders/${orderNumber}`,
                          text: String(orderNumber),
                      },
                  ],
        )
    }, [])
    useEffect(() => {
        if (lineItems.length === 0 && discount.discountValue !== 0) {
            updateState('discountValue', null)
            updateState('discountButtonLabel', 'Add Discount')
        }
    }, [lineItems, discount.discountValue])

    const fetchOrderDetails = async () => {
        await makeAPICall({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.getEditOrder}/${orderNumber}`,
        })
            .then((res) => {
                const orderData = { ...res.data.data.order }
                if (orderData.order_edit_status === 1) {
                    return setEditError('orderEdited')
                } else if (!orderData.order_name || orderData.order_status !== 'confirmed') {
                    return setEditError('notConfirmed')
                } else if (orderData.fulfilled === 'fulfilled') {
                    return setEditError('orderFulfilled')
                } else {
                    setEditOrderDetails({ ...res.data.data })
                    setLineItems([...res.data.data.line_items])
                    updateState('fetchedItems', [...res.data.data.line_items])
                    const orderDiscount = parseFloat(res.data.data?.order?.discount_amount) || 0
                    updateState('itemDiscount', orderDiscount)
                    if (orderDiscount > 0) {
                        updateState('preAppliedDiscount', true)
                    }
                }
            })
            .catch((err) => message.error(err.response?.data?.message || 'Unable to fetch order'))
    }
    const modifyQty = (id, qty) => {
        const tempLineItems = lineItems.map((item) =>
            item.variant_id === id
                ? {
                      ...item,
                      quantity: qty,
                      type: 'adjust',
                  }
                : item,
        )
        setLineItems(tempLineItems)
    }

    const deleteItem = (id) => {
        const deletedItem = lineItems.find((item) => item.variant_id === id)
        if (discount.preAppliedDiscount && !deletedItem.addedFromSearch) {
            updateState('itemToDelete', deletedItem)
            updateState('discountVisible', true)
            setModalData({
                show: false,
                item: null,
            })
        } else {
            const tempLineItems = [...lineItems].filter((item) => item.variant_id !== id)
            setLineItems([...tempLineItems])
            updateState('recentlyDeletedItems', [...discount.recentlyDeletedItems, deletedItem])
            updateState(
                'discountedItems',
                discount.discountedItems.filter((variantId) => variantId !== id),
            )

            if (tempLineItems.length === 0) {
                updateState('preAppliedDiscount', false)
            }
        }
    }

    const handleConfirmDelete = () => {
        const tempLineItems = lineItems.filter((item) => item.variant_id !== discount.itemToDelete.variant_id)
        setLineItems([...tempLineItems])
        updateState('recentlyDeletedItems', [...discount.recentlyDeletedItems, discount.itemToDelete])
        updateState(
            'discountedItems',
            discount.discountedItems.filter((variantId) => variantId !== discount.itemToDelete.variant_id),
        )

        if (tempLineItems.length === 0) {
            updateState('preAppliedDiscount', false)
        }
        updateState('discountVisible', false)
        updateState('itemToDelete', null)
    }
    const handleAddProduct = (newProduct) => {
        const productsWithFlag = newProduct.map((product) => {
            const shouldApplyDiscount = discount.discountedItems.includes(product.variant_id)
            return {
                ...product,
                addedFromSearch: true,
                isItemReadded: shouldApplyDiscount,
            }
        })
        setLineItems((prev) => [...prev, ...productsWithFlag])
    }

    const submitEditOrder = async () => {
        const existingLineItems = editOrderDetails?.line_items.reduce(
            (acc, item) => ({ ...acc, [item.variant_id]: { ...item } }),
            {},
        )
        const currentLineItems = lineItems.reduce((acc, item) => ({ ...acc, [item.variant_id]: { ...item } }), {})
        const removedItems = editOrderDetails?.line_items.reduce((acc, existingItem) => {
            if (!currentLineItems[existingItem.variant_id]) {
                return [
                    ...acc,
                    {
                        type: 'remove',
                        lineItemId: existingItem.line_item_id,
                        variantId: existingItem.variant_id,
                        productId: existingItem.product_id,
                    },
                ]
            }
            return acc
        }, [])

        const adjustedItems = editOrderDetails?.line_items.reduce((acc, existingItem) => {
            if (
                currentLineItems[existingItem.variant_id] &&
                existingItem.quantity !== currentLineItems[existingItem.variant_id].quantity &&
                !lineItems.find((item) => item.isItemReadded && item.variant_id === existingItem.variant_id)
            ) {
                return [
                    ...acc,
                    {
                        type: 'adjust',
                        lineItemId: existingItem.line_item_id,
                        variantId: existingItem.variant_id,
                        productId: existingItem.product_id,
                        quantity: currentLineItems[existingItem.variant_id].quantity - existingItem.quantity,
                    },
                ]
            }
            return acc
        }, [])

        const newItems = lineItems.reduce((acc, currentLineItem) => {
            if (!existingLineItems[currentLineItem.variant_id]) {
                return [
                    ...acc,
                    {
                        type: 'add',
                        variantId: currentLineItem.variant_id,
                        productId: currentLineItem.product_id,
                        quantity: currentLineItem.quantity,
                    },
                ]
            }
            return acc
        }, [])
        let payloadLineItems = [...removedItems, ...adjustedItems, ...newItems]
        if (discount.itemDiscount) {
            const reAddedItems = lineItems.reduce((acc, currentItem) => {
                if (
                    currentItem.isItemReadded &&
                    editOrderDetails?.line_items.some(
                        (originalItem) => originalItem.variant_id === currentItem.variant_id,
                    )
                ) {
                    return [
                        ...acc,
                        {
                            type: 'remove',
                            lineItemId: existingLineItems[currentItem.variant_id]?.line_item_id,
                            variantId: currentItem.variant_id,
                            productId: currentItem.product_id,
                        },
                        {
                            type: 'add',
                            variantId: currentItem.variant_id,
                            productId: currentItem.product_id,
                            quantity: currentItem.quantity,
                        },
                    ]
                }
                return acc
            }, [])
            const reAddIds = reAddedItems.map((item) => item.variantId)
            payloadLineItems = [
                ...removedItems.filter((item) => !reAddIds.includes(item.variantId)),
                ...adjustedItems,
                ...newItems,
                ...reAddedItems,
            ]
        }

        const discountData = {
            title: discount.discountTitle || '',
            type: (discount.discountType === 'fixed_amount' || discount.discountType === 'percentage'
                ? discount.discountType
                : 'fixed_amount') as 'fixed_amount' | 'percentage',
            value: Number(discount.discountValue) || 0,
        }

        const payload: EditOrderPayload = {
            moid: editOrderDetails.order.moid,
        }
        if (payloadLineItems.length > 0) {
            payload.line_items = payloadLineItems
        }
        if (discount.discountValue > 0) {
            payload.discount = discountData
        }
        await makeAPICall({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.saveEditOrder}`,
            payload,
        })
            .then((res) => {
                if (res.data.data.error) {
                    message.error(res.data?.data?.data?.message || 'Order Update Failed')
                } else {
                    message.success('Order Updated Successfully')
                    setTimeout(() => {
                        navigateToUrl(`/checkout/orders/${orderNumber}`)
                    }, 1000)
                }
                errorItems.length && setErrorItems([])
            })
            .catch((err) => {
                const data = { ...err.response?.data }
                if (data?.data?.data && data.data.error) {
                    const errIds = data.data.data.reduce((ids, item) => {
                        return item.message ? [...ids, item.variantId] : ids
                    }, [])
                    setErrorItems([...errIds])
                    message.error(data.data.message || 'Unable to update order'),
                        {
                            position: 'top-center',
                            autoClose: 1500,
                        }
                } else {
                    message.error(err.response?.data?.message || 'Unable to update order'),
                        {
                            position: 'top-center',
                            autoClose: 1500,
                        }
                }
            })
    }

    useEffect(() => {
        if (!config.platform) return
        if (config.platform !== 'shopify') return setEditError('nonShopify')
        else fetchOrderDetails()
    }, [config.platform])

    useEffect(() => {
        if (!lineItems.length && !editOrderDetails?.line_items?.length) return
        if (editOrderDetails?.line_items.length !== lineItems.length) setSubmitBtnEnable(true)
        else {
            const currentLineItems = lineItems.reduce((acc, item) => ({ ...acc, [item.variant_id]: { ...item } }), {})
            const itemsChanged = editOrderDetails.line_items.reduce(
                (acc, item) => acc || currentLineItems[item.variant_id]?.quantity !== item.quantity,
                false,
            )
            setSubmitBtnEnable(itemsChanged)
        }
    }, [lineItems])

    if (!config?.enable_edit_order && !isMerchantUser) {
        return <h1 className=' text-center text-lg font-bold'>This feature is disabled for your store.</h1>
    }
    if (editError) {
        return (
            <div className='w-full h-full flex flex-col items-center'>
                {editError === 'nonShopify' ? (
                    <h4 className='text-center font-semibold'>This feature is for Shopify merchants only.</h4>
                ) : editError === 'orderEdited' ? (
                    <h4 className='text-center font-semibold'>
                        Order No.:{' '}
                        <a href={`/checkout/orders/${orderNumber}`} className='text-primary cursor-pointer'>
                            {orderNumber}
                        </a>
                        , has already been edited once.
                    </h4>
                ) : editError === 'notConfirmed' ? (
                    <h4 className='text-center font-semibold'>
                        Order No.:{' '}
                        <a href={`/checkout/orders/${orderNumber}`} className='text-primary cursor-pointer'>
                            {orderNumber}
                        </a>
                        , is not confirmed yet, thus cannot be edited.
                    </h4>
                ) : (
                    editError === 'orderFulfilled' && (
                        <h4 className='text-center font-semibold'>
                            Order No.:{' '}
                            <a href={`/checkout/orders/${orderNumber}`} className='text-primary cursor-pointer'>
                                {orderNumber}
                            </a>
                            , is fulfilled.
                        </h4>
                    )
                )}
            </div>
        )
    }

    return (
        <>
            <div className='order-details-page w-full  flex flex-col gap-y-4 inter'>
                <Row className='p-2 rounded bg-neutrals-15' align={'middle'} justify={'space-between'}>
                    <Col sm={12} className='flex items-center gap-x-2'>
                        <span className='text-black text-base inter font-semibold m-0 tracking-wide'>
                            Edit Order <span className='text-blue-500'> #{orderNumber}</span>
                        </span>
                    </Col>
                </Row>
                <Row className='p-2 rounded bg-neutrals-15' align={'middle'} justify={'space-between'}>
                    <Col span={6}>
                        <p className='text-base'>Add products</p>
                        <p className='text-sm'>(Please enter at least three characters)</p>
                    </Col>
                    <Col span={8} className='flex  gap-x-2'>
                        {/* <Input type='text' className='w-[400px]' placeholder='Enter product id or name' /> */}
                        <SearchProduct
                            lineItems={lineItems}
                            setLineItems={(newItems) => {
                                if (lineItems.length > 0 && discount.itemDiscount) {
                                    setPendingProduct(newItems)
                                    updateState('addProductModal', true)
                                } else {
                                    setLineItems(newItems)
                                }
                            }}
                        />
                    </Col>
                    <Col span={8}>
                        <Row className='gap-2 items-center justify-end'>
                            <p className=''>
                                Grand Total: <span className='font-bold'>{calculateGrandTotal()}</span>
                            </p>
                            <Button
                                disabled={!submitBtnEnable}
                                onClick={submitEditOrder}
                                className='w-[5rem]'
                                variant='primary'
                            >
                                Save
                            </Button>
                        </Row>
                    </Col>
                </Row>
                <div className='p-2 rounded bg-neutrals-15'>
                    <div className='flex flex-col'>
                        <p className='text-lg font-bold mb-1 '>Line Items</p>
                        {lineItems.length > 0 && (
                            <p className='text-blue-500 text-sm font-semibold mb-1'>Total Items: {lineItems.length}</p>
                        )}
                    </div>
                    <Table columns={columns} dataSource={data} />
                </div>

                {!shouldHideUI() && (
                    <Row className='p-2 rounded bg-neutrals-15' align='middle'>
                        <Col span={12}>
                            <p className='text-black text-base font-semibold'>Add Custom Discount</p>
                            <p className='text-sm text-gray-500'>
                                Discounts will be distributed on the line item level proportionally.
                            </p>
                        </Col>

                        <Col span={12} className='flex justify-end'>
                            <Button
                                type='default'
                                className='border border-gray-300'
                                aria-label='Add Custom Discount'
                                onClick={() => {
                                    updateState('isModalVisible', true)
                                }}
                            >
                                {discount.discountButtonLabel}
                            </Button>

                            {discount.discountValue && discount.isModalVisible === false && (
                                <Button className='ml-2' onClick={handleRemoveDiscounts}>
                                    Remove
                                </Button>
                            )}

                            <Modal
                                title='Add Discount'
                                visible={discount.isModalVisible}
                                onOk={handleOk}
                                onCancel={() => {
                                    updateState('isModalVisible', false)
                                }}
                                footer={[
                                    <Button key='submit' type='primary' onClick={handleOk}>
                                        Add Discount
                                    </Button>,
                                ]}
                                className='max-w-md'
                            >
                                <div className='px-2'>
                                    <div className='space-y-4'>
                                        <div>
                                            <p className='text-gray-700 mb-2 font-semibold'>Discount Type</p>
                                            <Radio.Group
                                                value={discount.discountType}
                                                onChange={(e) => updateState('discountType', e.target.value)}
                                                className='flex gap-4'
                                            >
                                                <Radio value='fixed_amount'>Fixed</Radio>
                                                <Radio value='percentage'>Percentage</Radio>
                                            </Radio.Group>
                                        </div>
                                        <div>
                                            <p className='text-gray-700 mb-2 font-semibold'>Discount Value</p>
                                            <Input
                                                type='number'
                                                value={discount.discountValue}
                                                onChange={(e) => updateState('discountValue', e.target.value)}
                                                prefix={discount.discountType === 'fixed_amount' ? '₹' : ''}
                                                suffix={discount.discountType === 'percentage' ? '%' : ''}
                                                className='w-full rounded border border-gray-200'
                                            />
                                        </div>
                                        <div>
                                            <p className='text-gray-700 font-semibold'>Discount Title</p>
                                            <p className='text-gray-500 text-sm mb-2'>
                                                This code will be visible on the Shopify order as well.
                                            </p>
                                            <Input
                                                placeholder='XYZ'
                                                value={discount.discountTitle}
                                                onChange={(e) => updateState('discountTitle', e.target.value)}
                                                className='w-full rounded border border-gray-200'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                )}

                {discount.recentlyDeletedItems.length > 0 && (
                    <div className='p-2 rounded bg-neutrals-15'>
                        <div className='flex flex-col'>
                            <p className='text-lg font-bold mb-1'>Recently Removed Items</p>
                        </div>
                        <Table columns={recentlyDeleted} dataSource={recentlyDeletedData} pagination={false} />
                    </div>
                )}
            </div>
            <Modal
                cancelText={'Cancel'}
                okText={'Delete'}
                okType='danger'
                centered
                // className='w-1/2'
                width={'30vw'}
                open={modalData.show}
                onOk={() => {
                    deleteItem(modalData.item.variant_id)
                    setModalData({
                        show: false,
                        item: null,
                    })
                }}
                onCancel={() => {
                    setModalData({
                        show: false,
                        item: null,
                    })
                }}
            >
                <p className='font-semibold '>Are you sure ?</p>
                <p className='text-normal  '>
                    Do you want to remove product <span className='font-semibold'>{modalData?.item?.product}</span>
                </p>
            </Modal>
            <Modal
                title='Alert!'
                visible={discount.discountVisible}
                onOk={handleConfirmDelete}
                onCancel={() => {
                    updateState('discountVisible', false)
                }}
                okText='Remove Product'
                cancelText='Exit'
            >
                <p>
                    {lineItems.length > 1
                        ? 'Existing discount will also be removed and to re-apply the discount on a new product you need to remove all the products and re add them and then Add Discount '
                        : 'Note that removing this item will also remove the applied discount, but you can manually re-apply it to a new product.'}
                </p>
            </Modal>
            <Modal
                title='Alert!'
                visible={discount.addProductModal}
                onOk={() => {
                    handleAddProduct(pendingProduct)
                    setPendingProduct(null)
                    updateState('addProductModal', false)
                }}
                onCancel={() => {
                    updateState('addProductModal', false)
                }}
                okText='Add Product'
                cancelText='Cancel'
            >
                <p>
                    An additional discount cannot be applied to a new product. To apply a discount to the new product,
                    you need to remove all products, re-add them, and then apply a "custom discount".
                </p>
            </Modal>
        </>
    )
}
