//single or bulk discount card
import React, { FC, useEffect, useState } from 'react'
import { DiscountFormState } from '../helpers/useCreateDiscount'
import { CardLayout } from './cardLayout'
import { Col, Divider, FileExcelOutlined, Form, Input, InputNumber, Radio, Row, Space, message } from 'gokwik-ui-kit'
import { FileUploader } from './fileUploader'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'

interface BulkDiscountUploadCardProps {
    formState?: DiscountFormState
    uploadDiscountCSV?: (originFileObj: any, merchant_id: any) => void
    generateDiscountCode?: (any) => void
    codesList?: any
    bulkCodesPath?: any
    discountAction?: any
    fileList?: any
}

const Card: FC<BulkDiscountUploadCardProps> = (props) => {
    const { formState, uploadDiscountCSV, generateDiscountCode, codesList, bulkCodesPath, discountAction, fileList } =
        props
    const [codeGenerationMethod, setCodeGenerationMethod] = useState(
        discountAction ? (formState?.discount_codes?.length ? 'random' : 'csv_file') : 'random',
    )
    const [codeGenerationData, setCodeGenerationData] = useState({
        length: 0,
        number: 0,
        prefix: '',
        suffix: '',
        charset_type: '',
    })

    const [errors, setErrors] = useState({
        number: '',
        length: '',
        charset_type: '',
    })

    const codeGenerationFormula = (codeGenerationData) => {
        const NUMBERS_CHARSET = 10
        const ALPHABET_CHARSET = 26
        const COMBINE_CHARSET = 36
        const { length, number, prefix, suffix, charset_type } = codeGenerationData

        let a, b
        if (charset_type === 'alphabet') {
            a = ALPHABET_CHARSET
        }
        if (charset_type === 'numbers') {
            a = NUMBERS_CHARSET
        }
        if (charset_type === 'both') {
            a = COMBINE_CHARSET
        }

        b = length - prefix?.length - suffix?.length - 1

        const maxCodeGenerationCapacity = Math.pow(a, b)

        if (number > maxCodeGenerationCapacity) {
            setCodeGenerationData((prevState) => ({
                ...prevState,
                charset_type: '',
            }))

            message.error(`The combination you are trying is not feasible. Please try after increasing the length`)
            return false
        } else {
            return true
        }
    }

    useEffect(() => {
        if (codeGenerationData?.charset_type) {
            if (!codeGenerationData?.number && !codeGenerationData?.length) {
                message.error('Number & length are mandatory and non-negative')
                setCodeGenerationData((prevState) => ({
                    ...prevState,
                    charset_type: '',
                }))
                return
            }

            if (
                codeGenerationData?.prefix?.length + codeGenerationData?.suffix?.length >
                Number(codeGenerationData?.length) - 1
            ) {
                message.error('Combined length of Prefix or Suffix must be less than "Code Length"')
                setCodeGenerationData((prevState) => ({
                    ...prevState,
                    charset_type: '',
                    prefix: '',
                    suffix: '',
                }))
                return
            }

            if (codeGenerationFormula(codeGenerationData)) {
                generateDiscountCode(codeGenerationData)
            }
        }
    }, [codeGenerationData?.charset_type])

    const handleMethodChange = (e) => {
        setCodeGenerationMethod(e?.target?.value)
    }

    const handleChange = (e) => {
        let { name, value } = e?.target

        if (name === 'prefix' || name === 'suffix') {
            value = value.toUpperCase().replace(/[^A-Z0-9]/g, '')
        }

        if (name === 'length' || name === 'number') {
            value = Number(value)
        }
        if (name === 'length' || name === 'number') {
            value = Number(value)

            if (value <= 0) {
                value = null
            }
        }

        if (value) {
            setErrors((prevState) => ({
                ...prevState,
                [name]: '',
            }))
        }

        setCodeGenerationData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }

    const handleDownloadCSV = async () => {
        let payload
        if (!discountAction && !bulkCodesPath?.length) {
            //user coming to create new discount
            message.error('Download Error : No Upload Path Found')
            return
        } else if (discountAction && !formState?.discount_codes_url) {
            //user coming to edit/view discount
            message.error('Download Error : No Upload Path Found')
            return
        } else {
            if (discountAction) {
                payload = {
                    path: formState?.discount_codes_url,
                }
            } else {
                payload = {
                    path: bulkCodesPath,
                }
            }
        }

        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.DISCOUNT_CONFIG_BASE_URL + APIEndPoints.discountConfig.getBulkCodesCSV,
                payload,
            })

            if (response?.data?.status_code === 200) {
                const downloadUrl = response?.data?.data?.download_url
                const link = document.createElement('a')
                link.href = downloadUrl
                link.setAttribute('download', 'discount_codes.csv')
                document.body.appendChild(link)
                link.click()
                link.parentNode.removeChild(link)
            } else {
                message.error('Download Error : No S3 URL')
            }
        } catch (error) {
            console.error('Error downloading file:', error)
        }
    }

    const handleBlur = () => {
        if (codeGenerationData?.length && codeGenerationData?.number && !codeGenerationData?.charset_type) {
            setErrors((prev) => ({
                ...prev,
                charset_type: 'Please select any combination to generate bulk codes',
            }))
        } else if (codeGenerationData?.length && !codeGenerationData?.number && codeGenerationData?.charset_type) {
            setErrors((prev) => ({ ...prev, number: 'Number is required' }))
        } else if (!codeGenerationData?.length && codeGenerationData?.number && codeGenerationData?.charset_type) {
            setErrors((prev) => ({ ...prev, number: 'Length is required' }))
        } else if (codeGenerationData?.length && !codeGenerationData?.number && !codeGenerationData?.charset_type) {
            setErrors((prev) => ({
                ...prev,
                number: 'Number is required',
                charset_type: 'Please select any combination to generate bulk codes',
            }))
        } else if (!codeGenerationData?.length && codeGenerationData?.number && !codeGenerationData?.charset_type) {
            setErrors((prev) => ({
                ...prev,
                length: 'Number is required',
                charset_type: 'Please select any combination to generate bulk codes',
            }))
        }
    }

    return (
        <CardLayout title='How do you want to create the discount codes for this set?'>
            <br />
            <Form.Item>
                <Radio.Group
                    disabled={discountAction && formState?.discount_category === 'bulk'}
                    onChange={handleMethodChange}
                    defaultValue={codeGenerationMethod}
                >
                    <Space direction='vertical'>
                        <Radio value={'random'}>Generate random codes</Radio>
                        <Radio value={'csv_file'}>Upload a CSV</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>

            {codeGenerationMethod === 'random' ? (
                <>
                    <Row gutter={12}>
                        <Col span={12}>
                            <InputNumber
                                disabled={discountAction && formState?.discount_category === 'bulk'}
                                onChange={(value) => handleChange({ target: { value: value, name: 'number' } })}
                                name='number'
                                className='w-full'
                                placeholder=''
                                label='Number of codes to generate'
                                min={1}
                                onBlur={handleBlur}
                            />
                            {errors?.number && <span className=' text-red-500'>{errors?.number}</span>}
                        </Col>

                        <Col span={12}>
                            <InputNumber
                                disabled={discountAction && formState?.discount_category === 'bulk'}
                                name='length'
                                onChange={(value) => handleChange({ target: { value: value, name: 'length' } })}
                                className='w-full'
                                placeholder=''
                                label='Code length (in characters)'
                                min={1}
                                max={30}
                                onBlur={handleBlur}
                            />
                            {errors?.length && <span className=' text-red-500'>{errors?.length}</span>}
                        </Col>
                    </Row>

                    <Row gutter={12}>
                        <Col span={12}>
                            <Input
                                value={codeGenerationData?.prefix}
                                disabled={discountAction && formState?.discount_category === 'bulk'}
                                name='prefix'
                                onChange={handleChange}
                                className='w-full'
                                placeholder=''
                                label={'Prefix (Optional)'}
                            />
                        </Col>

                        <Col span={12}>
                            <Input
                                value={codeGenerationData?.suffix}
                                disabled={discountAction && formState?.discount_category === 'bulk'}
                                name='suffix'
                                onChange={handleChange}
                                className='w-full'
                                placeholder=''
                                label={'Suffix (Optional)'}
                            />
                        </Col>
                    </Row>

                    <br />

                    <Row>
                        <Col span={24}>
                            <span className='text-sm font-semibold'>Allowed characters</span>

                            {errors?.charset_type && (
                                <>
                                    <br />
                                    <span className=' text-red-500'>{errors?.charset_type}</span>
                                </>
                            )}
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Radio.Group
                                onChange={handleChange}
                                name='charset_type'
                                value={codeGenerationData?.charset_type}
                                disabled={discountAction && formState?.discount_category === 'bulk'}
                            >
                                <Space direction='horizontal'>
                                    <Radio value={'both'}>Letters and numbers</Radio>
                                    <Radio value={'alphabet'}>Only letters</Radio>
                                    <Radio value={'numbers'}>Only numbers</Radio>
                                </Space>
                            </Radio.Group>
                        </Col>
                    </Row>

                    <br />

                    {codesList?.length > 0 && (
                        <div className='border border-solid border-[#F09819] bg-[#F098190D] rounded-lg p-4 flex flex-col gap-2'>
                            <p className='text-[#000000E0] text-base font-semibold'>Created discount codes</p>
                            <div className='text-[#00000080] text-sm font-normal overflow-hidden flex justify-between flex-wrap max-w-full'>
                                {codesList?.slice(0, 4).map((item, index) => (
                                    <div className='truncate' key={index}>
                                        {item}
                                        {index !== 3 && ','}
                                    </div>
                                ))}
                                {codesList.length > 4 && <div className='truncate'>...</div>}
                                <div className='cursor-pointer underline' onClick={handleDownloadCSV}>
                                    Download all codes
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <>
                    {discountAction === 'edit' && formState?.discount_codes_url ? (
                        <div className='cursor-pointer underline flex gap-2 items-end' onClick={handleDownloadCSV}>
                            <FileExcelOutlined className=' text-green-500 text-3xl' />{' '}
                            <span className=' text-l'>Download your uploaded CSV file</span>
                        </div>
                    ) : (
                        <FileUploader uploadDiscountCSV={uploadDiscountCSV} formState={formState} fileList={fileList} />
                    )}
                </>
            )}
        </CardLayout>
    )
}

export const BulkDiscountUploadCard = React.memo(Card)
