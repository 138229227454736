import { Button, Card, Col, Drawer, Form, InfoCircleFilled, message, Row, Switch } from 'gokwik-ui-kit'
import BookDownload from '@library/images/book-download.svg'
import { useEffect, useState } from 'react'
import EasyRewards from '@library/images/PixelIcons/loyaltyPoints/easyRewards.svg'
import Marmeto from '@library/images/PixelIcons/marmeto.svg'
import Farzi from '@library/images/PixelIcons/farzi.svg'
import Gokwik from '@library/images/PixelIcons/gokwik.svg'
import Sparkle from '@library/images/PixelIcons/sparkle.svg'
import Link from '@library/images/PixelIcons/link.svg'
import { useNavigate } from 'react-router-dom'
import RenderForm from '@library/components/integrations/formRender'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { useSelector } from 'react-redux'
import { selectMerchantCreds } from '@store/integrations/selectors'
import { useAppDispatch } from '@library/utilities/hooks'
import { saveCreds } from '@store/integrations'
import { getUserConfig } from '@store/user/selectors'
import { MerchantConfig } from '@library/utilities/interface'
import { deepEqualAndDiff } from '@gokwik/utilities'
const ExternalDiscounts = () => {
    const [drawer, setDrawer] = useState(false)
    const [enabledStates, setEnabledStates] = useState({})
    const [title, setTitle] = useState('')
    const [selectedKey, setSelectedKey] = useState('')
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const { config, refetch, saveConfig } = useMerchantConfigApi()
    const customConfig = config?.custom_config
    const userConfig = useSelector(getUserConfig)
    const merchantCredentials = useSelector(selectMerchantCreds)
    const dispatch = useAppDispatch()
    const handleSwitchChange = (key, checked, item) => {
        if (
            checked &&
            OmniChannelDiscounts.every((channel) => channel.key !== key) &&
            BulkChannelProviders.some((credit) => credit.key !== key && credit.checked)
        ) {
            message.error('Only one Bulk Discount can be active at a time.')
            return
        }
        if (!checked && key === 'easy_rewards_coupon') {
            saveConfig({
                call_easy_rewards_coupon_api: checked,
            })
            return
        }
        if (checked && item.customize) {
            setSelectedKey(key)
            setDrawer(true)
            setTitle(item.title)
        } else if (!checked) {
            saveConfig({
                bulk_discount_config: {
                    provider: '',
                },
            })
        }
    }

    const handleCustomizeClick = (key, title) => {
        setSelectedKey(key)
        setTitle(title)
        setDrawer(true)
    }
    const BulkChannelProviders = [
        {
            key: 'farzi_api',
            title: 'Farzi Engineer Discounts',
            icon: Farzi,
            checked: config?.bulk_discount_config?.provider === 'farzi',
            customize: true,
        },
        {
            key: 'marmeto_api',
            title: 'Marmeto Discounts',
            checked: config?.bulk_discount_config?.provider === 'marmeto',
            icon: Marmeto,
            customize: true,
        },
        {
            key: 'meta',
            title: 'GoKwik Bulk Discounts',
            description:
                'Using Gokwik bulk discounts will allow you to use any custom discount use cases powered by Gokwik ',
            icon: Gokwik,
            customize: false,
            meta: true,
        },
    ]
    const OmniChannelDiscounts = [
        {
            key: 'easy_rewards_coupon',
            title: 'Easy Rewards Coupon API',
            checked: config?.call_easy_rewards_coupon_api,
            icon: EasyRewards,
            customize: true,
        },
    ]
    const handleSave = async () => {
        try {
            const isKeyChecked = [...BulkChannelProviders, ...OmniChannelDiscounts].find(
                (item) => item.key === selectedKey,
            ).checked
            if (!form.isFieldsTouched() && isKeyChecked) {
                setDrawer(false)
                setSelectedKey('')
                return
            }
            await form.validateFields()

            const { merchant_config = {}, ...rest } = form.getFieldsValue()
            let configPayload: any = {}
            if (selectedKey === 'easy_rewards_coupon') {
                configPayload.call_easy_rewards_coupon_api = true
            } else {
                configPayload.bulk_discount_config = {
                    provider: selectedKey === 'farzi_api' ? 'farzi' : 'marmeto',
                    ...(selectedKey === 'marmeto_api' && {
                        marmeto_prefixes: merchant_config.bulk_discount_config.marmeto_prefixes,
                    }),
                }
            }
            if (
                !deepEqualAndDiff(
                    {
                        call_easy_rewards_coupon_api: config?.call_easy_rewards_coupon_api,
                        bulk_discount_config: config?.bulk_discount_config,
                    },
                    configPayload,
                ).isEqual
            ) {
                const res = await saveConfig(configPayload)
            }
            let payload = rest[selectedKey.toLocaleLowerCase()]
            const credsChanged = !deepEqualAndDiff(
                merchantCredentials?.[selectedKey.toLocaleLowerCase()] || {},
                payload,
            ).isEqual
            if (payload && credsChanged) {
                const res = await dispatch(saveCreds({ [selectedKey.toLowerCase()]: payload }))
                if (res) {
                    setDrawer(false)
                } else {
                    message.error('Failed to save credentials')
                }
            } else {
                setDrawer(false)
            }
        } catch (error) {
            console.log(error)
        }
        setSelectedKey('')
    }
    const populateForm = () => {
        if (merchantCredentials) {
            form.setFieldsValue(
                [...BulkChannelProviders, ...OmniChannelDiscounts].reduce((acc, item) => {
                    acc[item.key.toLowerCase()] = merchantCredentials[item.key]

                    return acc
                }, {}),
            )
        }

        if (!config) return

        form.setFieldsValue({
            merchant_config: {
                ...config,
            },
        })
    }
    useEffect(() => {
        populateForm()
    }, [config, merchantCredentials])
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <div className='flex items-center text-lg leading-7'>
                        <p className='text-lg font-semibold'>3rd Party Discounts</p>
                    </div>
                </Col>

                <Col span={24}>
                    <h4 className='font-semibold text-base mb-1 mt-0'>Omni Channel Discounts</h4>
                </Col>
                {OmniChannelDiscounts.map((item) => (
                    <Col span={24} key={item.key}>
                        <Card className='custom-card'>
                            <Row align='middle' justify='space-between'>
                                <Col flex='40px'>
                                    <img src={item.icon} alt={item.title} className='h-10 w-10' />
                                </Col>

                                <Col flex='auto' className='pl-4'>
                                    <p className='font-semibold mb-1'>{item.title}</p>
                                </Col>

                                <Col className='text-right'>
                                    {item.customize === true && item.checked && (
                                        <Button
                                            className='mr-4 focus:outline-none'
                                            onClick={() => handleCustomizeClick(item.key, item.title)}
                                        >
                                            Customise
                                        </Button>
                                    )}
                                    <Switch
                                        className='focus:outline-none'
                                        checked={item.checked}
                                        onChange={(checked) => handleSwitchChange(item.key, checked, item)}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}

                <Col span={24}>
                    <h4 className='font-semibold text-base mb-2'>Bulk Discounts Provider</h4>
                    <p className='bg-blue-50 w-full px-4 py-1 rounded-md flex items-center'>
                        <InfoCircleFilled className='text-blue-500 mr-2' />
                        Only one Bulk Discount provider can be active at a time.
                    </p>
                </Col>
                {BulkChannelProviders.map((item) => (
                    <Col span={24} key={item.key}>
                        <Card className='custom-card'>
                            <Row align='middle' justify='space-between'>
                                <Col flex='40px'>
                                    <img src={item.icon} alt={item.title} className='h-10 w-10' />
                                </Col>

                                <Col flex='auto' className='pl-4'>
                                    <p className='font-semibold mb-1'>
                                        {item.title}
                                        {item.key === 'meta' && (
                                            <span className='ml-2 px-2 py-1 bg-blue-100 text-blue-500 text-xs font-medium rounded'>
                                                <img src={Sparkle} style={{ width: '15px', height: '15px' }}></img>
                                                Recommended
                                            </span>
                                        )}
                                    </p>
                                    <p className='text-gray-500 text-sm'>{item.description}</p>
                                </Col>

                                <Col className='text-right'>
                                    {item.meta ? (
                                        <Button
                                            className='mr-4 focus:outline-none'
                                            onClick={() =>
                                                userConfig.diy_enabled
                                                    ? navigate('/checkout/settings/discounts', {
                                                          state: { activeTab: 'bulk' },
                                                      })
                                                    : navigate('/checkout/settings/bulk-discounts')
                                            }
                                        >
                                            <img src={Link} className='w-3 h-3 mr-2' alt='link icon' />
                                            <span>Take me there</span>
                                        </Button>
                                    ) : (
                                        <>
                                            {item.customize && item.checked && (
                                                <Button
                                                    className='mr-4 focus:outline-none'
                                                    onClick={() => handleCustomizeClick(item.key, item.title)}
                                                >
                                                    Customise
                                                </Button>
                                            )}
                                            <Switch
                                                className='focus:outline-none'
                                                checked={item.checked}
                                                onChange={(checked) => handleSwitchChange(item.key, checked, item)}
                                            />
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Drawer
                title={title}
                width={500}
                open={drawer}
                onClose={() => setDrawer(false)}
                footer={
                    <div className='flex justify-end space-x-4'>
                        <Button
                            type='default'
                            onClick={() => {
                                populateForm()
                                setDrawer(false)
                            }}
                        >
                            Discard
                        </Button>
                        <Button type='primary' onClick={handleSave}>
                            Save Changes
                        </Button>
                    </div>
                }
            >
                <RenderForm fieldKey={selectedKey} form={form} />
            </Drawer>
        </>
    )
}

export default ExternalDiscounts
