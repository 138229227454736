import { formatAmount } from '@library/utilities/helpers/helper'
import { Popover } from 'gokwik-ui-kit'
import { useState } from 'react'

export const DiscountBreakupsData = ({ data, value }) => {
    const [open, setOpen] = useState(false)
    if (!value) {
        return <span>{formatAmount(value)}</span>
    }
    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen)
    }
    return (
        <Popover
            content={
                <div className='mb-10  bg-white p-2'>
                    <table>
                        <tbody>
                            {data &&
                                Object.keys(data)
                                    .filter((key) => data[key])
                                    .map((key, index) => (
                                        <tr key={key}>
                                            <td className={index ? 'mt-10' : ''} colSpan={2}>
                                                {(typeof data[key] === 'number' || typeof data[key] === 'string') && (
                                                    <>
                                                        <strong>{key}:</strong> {data[key]}
                                                    </>
                                                )}
                                                {typeof data[key] === 'object' && !(key === 'Automatic Discounts' || key === 'Coupon Code Based Discount') && (
                                                    <>
                                                        <strong>{key}:</strong>
                                                        {Object.keys(data[key]).map((first) => (
                                                            <div key={first}>
                                                                {first}: {data[key][first]}
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                                {(key === 'Automatic Discounts' || key === 'Coupon Code Based Discount')  && data[key] && (
                                                    <>
                                                        <strong>{key}:</strong>
                                                        {data[key]
                                                            ? data[key].map((each, index) => (
                                                                  <div key={index}>
                                                                      {Object.keys(each).map((first) => (
                                                                          <div key={first}>
                                                                              {first}: {each[first]}
                                                                          </div>
                                                                      ))}
                                                                  </div>
                                                              ))
                                                            : '--'}
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                        </tbody>
                    </table>
                </div>
            }
            title='Total Discount'
            trigger='click'
            open={open}
            onOpenChange={handleOpenChange}
            className='cursor-pointer'
        >
            {formatAmount(value)}{' '}
            <span className='text-xs text-blue-500' onClick={() => handleOpenChange(true)}>
                See Details
            </span>
        </Popover>
    )
}

export const TaxBreakupData = ({ data, value }) => {
    const [open, setOpen] = useState(false)
    if (!value) {
        return <span>{formatAmount(value)}</span>
    }
    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen)
    }
    return (
        <Popover
            content={
                <div className='mb-10 bg-white p-2'>
                    <table>
                        <tbody>
                            {data &&
                                Object.keys(data).map(
                                    (key) =>
                                        data[key] && (
                                            <tr key={key}>
                                                <td className='font-semibold'>{key} :</td>
                                                <td>{data[key] ? data[key].toString() : '--'}</td>
                                            </tr>
                                        ),
                                )}
                        </tbody>
                    </table>
                </div>
            }
            title='Tax Breakups'
            trigger='click'
            open={open}
            onOpenChange={handleOpenChange}
            className='cursor-pointer'
        >
            {formatAmount(value)}{' '}
            <span className='text-xs text-blue-500' onClick={() => handleOpenChange(true)}>
                See Details
            </span>
        </Popover>
    )
}

export const ShippingBreakupData = ({ data, value }) => {
    const [open, setOpen] = useState(false)
    if (!value) {
        return <span>{formatAmount(value)}</span>
    }
    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen)
    }
    return (
        <Popover
            content={
                <div className='bg-white p-2 mb-2'>
                    <table>
                        <tbody>
                            {data &&
                                Object.keys(data)?.map(
                                    (key) =>
                                        data[key] && (
                                            <tr key={key}>
                                                <td className='font-semibold'>{key} :</td>
                                                <td>{data[key]}</td>
                                            </tr>
                                        ),
                                )}
                        </tbody>
                    </table>
                </div>
            }
            title='Shipping And COD Breakup'
            trigger='click'
            open={open}
            onOpenChange={handleOpenChange}
            className='cursor-pointer'
        >
            {formatAmount(value)}{' '}
            <span className='text-xs text-blue-500' onClick={() => handleOpenChange(true)}>
                See Details
            </span>
        </Popover>
    )
}

export const GiftBreakupData = ({ data, value }) => {
    const [open, setOpen] = useState(false)
    if (!value) {
        return <span>{formatAmount(value)}</span>
    }
    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen)
    }

    return (
        <Popover
            content={
                <div className='mb-2  bg-white p-2'>
                    <table>
                        <tbody>
                            {data &&
                                Object.keys(data)
                                    .filter((key) => data[key])
                                    .map((key, index) => (
                                        <tr key={key}>
                                            <td className={index ? 'mt-10' : ''}>
                                                <strong>{key} :</strong>
                                            </td>
                                            <td>
                                                {data[key]
                                                    ? data[key].map((each, index) => (
                                                          <div key={index}>
                                                              {Object.keys(each).map((first) => (
                                                                  <div key={first}>
                                                                      {first}: {each[first]}
                                                                  </div>
                                                              ))}
                                                          </div>
                                                      ))
                                                    : '--'}
                                            </td>
                                        </tr>
                                    ))}
                        </tbody>
                    </table>
                </div>
            }
            title='Gift'
            trigger='click'
            open={open}
            onOpenChange={handleOpenChange}
            className='cursor-pointer'
        >
            {formatAmount(value)}{' '}
            <span className='text-xs text-blue-500' onClick={() => handleOpenChange(true)}>
                See Details
            </span>
        </Popover>
    )
}

export const findOrderFailureReason = (orders, post_order_process_item) => {
    let orderFailureReason = '',
        is_cancelled = false
    if (post_order_process_item?.order_id && post_order_process_item?.type === 'orderCancellation') {
        orderFailureReason = post_order_process_item?.additional_info?.cancellationReason
        is_cancelled = true
    } else if (orders && orders?.order_status === 'rejected') {
        orderFailureReason = orders?.order_state
    }
    switch (orderFailureReason) {
        case 'partial_paid_order':
            orderFailureReason = 'mismatch in order total between gokwik and shopify'
            break
        case 'cancelled_order':
            orderFailureReason = 'the order was already cancelled at the time of payment'
            break
        case 'invalid_gift_card':
            orderFailureReason = 'giftcard used was invalid'
            break
        case 'shopify_failure':
            orderFailureReason = 'order was rejected by shopify platform'
            break
        case 'gift_card_adjustment_failure':
            orderFailureReason = 'gift card redemption failed'
            break
        case 'test_discount_code':
            orderFailureReason = 'a Test discount was used to place the order'
            break
        case 'loyalty_points_failure':
        case 'loyalty_coupon_failure':
            orderFailureReason = 'loyalty points redemption failed'
            break
        case 'onpl_cod_order':
            orderFailureReason = 'a duplicate COD order was already in process'
            break
        case 'cart_verification_failed_invalid_discount':
            orderFailureReason = 'of invalid automatic discount code'
            break
        case 'cart_verification_failed_product_price_change':
            orderFailureReason = 'price of one or more items in the cart was changed'
            break
        case 'cart_verification_failed_product_not_active':
        case 'cart_verification_failed_product_not_found':
            orderFailureReason = 'one or more products in the cart were not active'
            break
        case 'cart_verification_failed_total_mismtach':
        case 'cart_verification_failed_grand_total_mismtach':
        case 'amount_mismatch':
            orderFailureReason = 'of mismatch in order total'
            break
        case 'invalid_discount_usage_limit_exceeded':
        case 'invalid_discount_once_per_customer_check_failed':
            orderFailureReason = 'the discount code used had exhausted the usage limit'
            break
        case 'invalid_discount_internal_api_failure':
        case 'invalid_discount_shopify_api_failure':
            orderFailureReason = 'discount verification failed due to an internal issue'
            break
        case 'invalid_discount_customer_info_not_found':
        case 'invalid_discount_shopify_constraint_user_ineligible_user_ineligible':
        case 'invalid_discount_email_not_present':
            orderFailureReason = 'the discount code could not be applied due to mismatch in customer information'
            break
        case 'invalid_discount_shopify_invalid_code':
            orderFailureReason = 'discount code does not exist'
            break
        case 'order_cancelled_in_automation_testing':
            orderFailureReason = 'the order was cancelled in automation testing'
            break
        case 'cod_order_conversion_failed':
            orderFailureReason = 'the order could not be converted to cod'
            break
        case 'cart_verification_failed':
            orderFailureReason = 'cart verification failed'
            break
        default:
            break
    }
    if (orderFailureReason == '') return ''
    return is_cancelled
        ? `This order was auto-cancelled because ${orderFailureReason}`
        : `This order could not be placed because ${orderFailureReason}`
}
