import { useState, useEffect, useRef } from 'react'

interface ObserverProps {
    options?: IntersectionObserverInit
    ref: React.RefObject<Element>
    reset?: boolean
}

export const useIntersectionObserver = ({ ref, options = {}, reset = false }: ObserverProps) => {
    const [isVisible, setIsVisible] = useState(false)
    const observerRef = useRef<IntersectionObserver | null>(null)

    useEffect(() => {
        if (ref.current) {
            observerRef.current = new IntersectionObserver(([entry]) => {
                setIsVisible(entry.isIntersecting)
            }, options)

            observerRef.current.observe(ref.current)
        }

        return () => {
            if (observerRef.current && ref.current) {
                observerRef.current.unobserve(ref.current)
            }
        }
    }, [ref, options, reset])

    useEffect(() => {
        if (observerRef.current && ref.current) {
            observerRef.current.observe(ref.current)
        }
    }, [ref.current, reset])

    return isVisible
}
