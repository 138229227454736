import { useEffect, useState } from 'react'
import {
    addBreadcrumb,
    convertIsoToLocaleDateString,
    convertIsoToLocaleString,
    makeAPICall,
    setBreadcrumb,
    updateBreadcrumbs,
} from '@gokwik/utilities'
// import csv from 'csvtojson'
import {
    Button,
    Table,
    Tag,
    Tooltip,
    Row,
    Drawer,
    Col,
    Select,
    Upload,
    ColumnProps,
    Switch,
    UploadProps,
    RedoOutlined,
    DownloadOutlined,
    InboxOutlined,
    UploadOutlined,
    SearchOutlined,
    InfoCircleOutlined,
    FileExcelFilled,
    Input,
    Modal,
    RightOutlined,
    CheckCircleFilled,
    InfoCircleFilled,
    message,
    Segmented,
} from 'gokwik-ui-kit'
import BookDownload from '@library/images/book-download.svg'

import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserDetails, isMerchantSelector } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import dayjs from 'dayjs'
import Test from '@public/assets/pincode_sample.csv'
import { getCookie, setCookie } from '@library/utilities/helpers/cookieHelper'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'
const { Option } = Select
const { Dragger } = Upload

export default function index(props) {
    const isMerchantUser = useSelector(isMerchantSelector)
    const userDetails = useSelector(getUserDetails)
    const [parameters, setParameters] = useState({
        merchant: null,
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
    })
    const [isDrawerOpen, setDrawerOpen] = useState(false)
    const [merchantList, setMerchantList] = useState([])
    const [pincodeList, setPincodeList] = useState([])
    const [pincodeDetails, setDetails] = useState<any>(null)
    const [uploadPincodesList, setUploadedPincodes] = useState([])
    const [uploadedFile, setUploadedFile] = useState('')
    const [file, setFile] = useState({ file: '', fileName: '' })
    const [total, setTotalPincode] = useState(0)
    const [filesList, setFileLists] = useState([])
    const [fileSelected, setCurrentFileSeletecd] = useState<any>(null)
    const [showReplaceWarning, setReplaceWarning] = useState(false)
    const [showUpload, setShowUpload] = useState(false)
    const [currentPincode, setCurrentPincode] = useState(null)
    const [searchPincode, setSearchPincode] = useState('')
    const merchantDetails = useSelector(getMerchantDetails)
    const [showTour, setShowTour] = useState(false)

    useEffect(() => {
        const checkCookies = () => {
            const isTourVisited = getCookie('pincodeServiceabilityTour')
            if (!isTourVisited) {
                setShowTour(true)
            }
        }
        setTimeout(checkCookies, 100)
    }, [])

    const closeTour = () => {
        setShowTour(false)
        setCookie('pincodeServiceabilityTour', true, 365)
    }

    const columns: ColumnProps<any>[] = [
        {
            title: <span>Pincode</span>,
            dataIndex: 'pincode',
            width: 150,
            ellipsis: true,
        },
        {
            title: <span>Cash on Delivery</span>,
            dataIndex: 'cod',
            width: 150,
            render: (itm) => <Tag variant={itm ? 'default' : 'error'}>{itm ? 'Enabled' : 'Disabled'}</Tag>,
        },

        {
            title: <span>Prepaid</span>,
            dataIndex: 'upi',
            width: 150,
            render: (itm) => <Tag variant={itm ? 'default' : 'error'}>{itm ? 'Enabled' : 'Disabled'}</Tag>,
        },
    ]

    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
    }

    const downloadCSV = async (file) => {
        try {
            const response = await makeAPICall({
                url:
                    process.env.REACT_APP_BASE_URL +
                    '/api/dashboard/pincode/download/file' +
                    '?file_name=' +
                    'latest' +
                    '&response_mode=download',
                method: 'get',
            })
            console.log(response)
            if (response.status == 200) {
                const filename = 'Pincode.csv'
                const link = document.createElement('a')
                var blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' })
                link.href = window.URL.createObjectURL(blob)
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                message.success('Downloaded successfully!')
                // console.log(response.data)
            } else {
                console.error('Failed to fetch Pincode file :', response.status, response.statusText)
                message.error('Failed to fetch Pincode file !')
            }
        } catch (error) {
            console.error('Download Failed:', error)
            message.error('Download failed!')
        }
    }

    const reset = (e) => {
        e.preventDefault()
        setParameters((prev) => ({
            ...prev,
            merchant: null,
        }))
        setDetails(null)
        setPincodeList([])
        setTotalPincode(0)
    }
    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'settings',
                href: '/checkout/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'pincode',
                href: '/checkout/settings/pincode',
                text: 'Pincode Serviceability',
            },
        ])

        if (!isMerchantUser) {
            fetchMerchantList()
        } else {
            makeAPICall({
                method: 'get',
                url:
                    process.env.REACT_APP_BASE_URL +
                    `/api/dashboard/pincode/merchant-pincode?page=${parameters.page}&pageSize=${parameters.pageSize}`,
            }).then((res) => {
                setPincodeList(res.data.data?.pincodes)
                setTotalPincode(res.data.data?.totalCount)
                setCurrentPincode({
                    file: res.data.data.file_name,
                    fileName: res.data.data.uploaded_file,
                })
            })
        }
    }, [parameters.pageSize, parameters.page])

    useEffect(() => {

        makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.getPin + '?checkout_platform=' + props.checkoutPlatform,
        }).then((res) => {
            setDetails(res.data.data)
        })
        getFilesUploadedList()

    }, [])

    const getFilesUploadedList = () => {
        makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.merchantPincodeFiles,
        }).then((res) => {
            setFileLists(res.data.data)
        })
    }

    useEffect(() => {
        if (searchPincode.length === 6 || searchPincode.length === 0) {
            getPincodeLists()
        }
    }, [searchPincode])

    const handleSearchClick = (e) => {
        e?.preventDefault()
        if (isMerchantUser || merchantDetails?.id) {
            getPincodeLists()
        } else {
            message.error('Merchant name is required')
        }
    }

    const fetchMerchantList = async () => {
        const respnse = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.getMerchantList,
        })
            .then((res) => {
                if (res.data.data) {
                    let arr = res.data.data.map((u) => ({
                        name: u.short_name,
                        id: u.id,
                        m_id: u.m_id,
                    }))
                    let final = arr
                    setMerchantList(final)
                }
            })
            .catch(() => {
                message.error('Unable to fetch merchants')
            })
    }

    const togglePincodeServiceablity = () => {
        makeAPICall({
            method: 'post',
            url:
                process.env.REACT_APP_BASE_URL +
                APIEndPoints.enablePin ,
            // (!isMerchantUser ? '?merchant_id=' + merchantDetails.id : ''),
            payload: {
                enable: !pincodeDetails.enable_pincode,
                checkout_platform: props.checkoutPlatform
            },
        }).then((res) => {
            getPincodeDetails()
        })
    }

    const getPincodeLists = () => {
        setParameters((prev) => ({ ...prev, page: 1 }))

        makeAPICall({
            method: 'get',
            url:
                process.env.REACT_APP_BASE_URL +
                APIEndPoints.pincodeList +
                `?page=${parameters.page}&pageSize=${parameters.pageSize}&pincode=${searchPincode}`,
            // (!isMerchantUser ? '?merchant_id=' + parameters.merchant.id : ''),
        }).then((res) => {
            setPincodeList(res.data.data?.pincodes)
            setTotalPincode(res.data.data?.totalCount)
            setCurrentPincode({
                file: res.data.data.file_name,
                fileName: res.data.data.uploaded_file,
            })
        })
        makeAPICall({
            method: 'get',
            url:
                process.env.REACT_APP_BASE_URL +
                APIEndPoints.getPin 
                + '?checkout_platform=' + props.checkoutPlatform,
        }).then((res) => {
            setDetails(res.data.data)
        })
    }

    const getPincodeDetails = () => {
        makeAPICall({
            method: 'get',
            url:
                process.env.REACT_APP_BASE_URL +
                APIEndPoints.getPin 
                + '?checkout_platform=' + props.checkoutPlatform,
        }).then((res) => {
            console.log(res)
            setDetails(res.data.data)
        })
    }

    const donwloadSample = () => {
        let csvContent = 'pincode,cod,upi\r\n'
        csvContent += ['506001,true,true', '506002,true,false', '506003,false,true'].join('\r\n')
        var pom = document.createElement('a')
        // var blob = new Blob(Test, { type: 'text/csv;charset=utf-8;' })
        // var url = URL.createObjectURL(blob)
        pom.href = Test
        pom.setAttribute('download', 'pincode.csv')
        pom.click()

        // document.body.removeChild(link)
        message.success('Sample downloaded successfully!')
    }

    function uploadPincodeCsv(file) {
        let reader = new FileReader()
        reader.readAsText(file)
        reader.onload = (e) => {
            const content = e.target.result
            const rows = content
                //@ts-ignore
                .split('\n')
                .filter((row) => row.trim() !== '')
                .map((row) => row.split(','))
            if (
                rows.length < 2 ||
                rows[0].length < 3 ||
                (rows[0][1].toLowerCase() !== 'cod' && rows[0][2].toLowerCase() !== 'upi')
            ) {
                message.error('Wrong CSV file structure. Please look at the sample to know the right structure!')
                return
            }
            // Remove header row
            rows.shift()
            // Validate and process each row
            for (let i = 0; i < rows.length; i++) {
                const [pincode, cod, upi] = rows[i].map((value) => value.trim())
                if (!/^\d+$/.test(pincode) || !/^(true|false)$/i.test(cod) || !/^(true|false)$/i.test(upi)) {
                    message.error('Invalid value in CSV detected. Please check and upload.')
                    return
                }
            }
            // Convert rows to an array of objects
            const pincodes = rows.map((row) => ({
                pincode: Number(row[0]),
                cod: row[1].toLowerCase() === 'true',
                upi: row[2].toLowerCase() === 'true',
            }))
            setUploadedPincodes([...pincodes])
            setUploadedFile(file.name)
            console.log(file, '>>>')
            setFile({
                file: file,
                fileName: file,
            })

            // Use the pincodes array as needed
            console.log('Parsed Pincodes:', pincodes)
            uploadPincodesData2(file, file.name)
        }

        reader.readAsText(file)
    }

    const getSignedUrl = async () => {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.fileS3PreSignedUrl,
            params: { file_name: 'Pincode.csv', ...(!isMerchantUser && { merchant_id: merchantDetails?.id }) },
        })
        if (response.success) {
            return response.data.data.url
        }
    }

    async function uploadPincodesData2(csvFile, fileName) {
        try {
            const url = await getSignedUrl()
            console.log(file, '>>')
            const awsResponse = await fetch(url, {
                method: 'PUT',
                body: csvFile,
            })
            if (awsResponse.status == 200) {
                const parsedUrl = new URL(url)
                const parts = parsedUrl.pathname.split('/')
                const nonEmptyParts = parts.filter((part) => part !== '')
                const filename = nonEmptyParts.pop()

                const postData = {
                    file_name: filename,
                    ...(!isMerchantUser && { merchant_id: merchantDetails?.id + ""}),
                }
                const response = await makeAPICall({
                    method: 'post',
                    url: process.env.REACT_APP_BASE_URL + APIEndPoints.fileS3UploadNotifyUrl + '?file=' + fileName,
                    payload: {
                        ...postData,
                    },
                }).then(() => {
                    message.success('Upload successfully!')
                    getPincodeLists()
                    setDrawerOpen(false)
                    setShowUpload(null)
                    setReplaceWarning(false)
                    setCurrentFileSeletecd(null)
                    getFilesUploadedList()
                })
                if (response.status != 200)
                    console.error('Failed to make POST request:', response.status, response.data, response.statusText)
            } else {
                console.error('Failed to make POST request:', awsResponse.status, awsResponse.statusText)
            }
        } catch {
            // toast.error('Upload Failed!', {
            //     position: 'top-center',
            //     autoClose: 1500,
            // })
        }
    }
    async function replacePincodesData(pincodes) {
        console.log(pincodes, fileSelected)
        try {
            const postData = {
                file_name: fileSelected.file_name,
                ...(!isMerchantUser && { merchant_id: merchantDetails?.id + "" }),
            }

            const response = await makeAPICall({
                method: 'post',
                url:
                    process.env.REACT_APP_BASE_URL +
                    APIEndPoints.fileS3UploadNotifyUrl +
                    '?file=' +
                    fileSelected.file +
                    '&replace=true',
                payload: {
                    ...postData,
                },
            })
            if (response.status != 200)
                console.error('Failed to make POST request:', response.status, response.data, response.statusText)
            // } else {
            //     console.error('Failed to make POST request:', awsResponse.status, awsResponse.statusText)
            // }

            message.success('Upload successfully!')
            getPincodeLists()
            setDrawerOpen(false)
            setShowUpload(null)
            setReplaceWarning(false)
            setCurrentFileSeletecd(null)
            getFilesUploadedList()
        } catch {
            message.error('Upload Failed!')
        }
    }

    if (!currentPincode) {
        return (
            <div className='w-full px-2  flex flex-col gap-2 inter justify-center items-center'>
                <div className='flex justify-between items-center bg-white p-3 gap-1  rounded-md '>
                    <div className='w-1/2'>
                        <div className='flex gap-2  items-center'>
                            <p className='text-base font-semibold  mb-2'>Pincode Serviceability</p>
                                    {
                                        props.checkoutPlatform=='kwik_checkout' && 
                                <a
                                    className='ml-2 mb-1 text-sm font-normal'
                                    href='https://scribehow.com/page/Guide_to_Set_Up_Shipping_Serviceability_on_GoKwik_Dashboard__cxszaSrOQ6GjY8KvNdtWOg'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <img className='mr-1 mb-1' src={BookDownload} height={'18'} width={'18'} />
                                    Open Guide
                                </a>
                                    }
                        </div>

                        <p className='text-sm  text-gray-400'>
                                    {
                                        (props.checkoutPlatform != 'kwik_checkout') ?
                                        'By enabling this feature, you will allow to deliver in uploaded pincode locations. To enable pincode serviceability for unique payment methods, contact GoKwik team.' : 
                                        'Enabling this feature allows you to ship to specific uploaded pin codes. When this service is not enabled, all Indian pin codes are serviceable for both prepaid and COD.'
                                    }
                        </p>
                            
                    </div>
                    <div className='flex gap-2 items-center' />
                </div>
                <div className='p-3  w-full'>
                    
                    <Dragger
                        accept='.csv'
                        name='file'
                        maxCount={1}
                        multiple={true}
                        onChange={(info) => {
                            const { status, originFileObj } = info.file
                            info.file.status = 'done'
                            uploadPincodeCsv(originFileObj)
                        }}
                    >
                        <p className='ant-upload-drag-icon'>
                            <InboxOutlined />
                        </p>
                        <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                    </Dragger>
                    <div className='flex items-center justify-between border-solid rounded-md border-[#1677FF8C] p-2 bg-[#1677FF0D] my-2'>
                        <p className='text-xs text-gray-600'>
                            Please ensure that the uploaded file is in the suggested format as shown in the sample file
                            on the right
                        </p>
                        <Button onClick={donwloadSample} type='link' variant='default' className='ml-3 pr-0'>
                            <DownloadOutlined />
                            Download Sample
                        </Button>
                    </div>

                    <Row justify='space-between' className='my-2 mt-3'>
                        <p className='text-sm'>Supported Formats: CSV</p>
                        <p className='text-sm text-gray-500'>Maximum file size: 200 MB</p>
                    </Row>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className='w-full px-2 flex flex-col gap-2 inter'>
                {/* Filter Comopnents */}

                {/* Actions Items */}
                {pincodeDetails && (
                    <div className='flex justify-between items-center bg-white p-3 gap-1  rounded-md '>
                        <div className='w-1/2'>
                            <div className='flex gap-2  items-center'>
                                {isMerchantUser ? (
                                    <p className='text-base font-semibold  mb-2'>Pincode Serviceability</p>
                                ) : (
                                    <p className='text-lg font-bold  mb-2'>{merchantDetails?.name}</p>
                                )}
                                {
                                        props.checkoutPlatform=='kwik_checkout' && 
                                    <a
                                        className='ml-2 mb-1 text-sm font-normal'
                                        id='pincode-serviceability-guide'
                                        href='https://scribehow.com/page/Guide_to_Set_Up_Shipping_Serviceability_on_GoKwik_Dashboard__cxszaSrOQ6GjY8KvNdtWOg'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        <img className='mr-1 mb-1' src={BookDownload} height={'18'} width={'18'} />
                                        Open Guide
                                    </a>
                                    }
                            </div>
                            {!pincodeDetails.enable_pincode ? (
                                <p className='text-sm  text-gray-400'>
                                   {
                                        (props.checkoutPlatform != 'kwik_checkout') ?
                                        'By enabling this feature, you will allow to deliver in uploaded pincode locations. To enable pincode serviceability for unique payment methods, contact GoKwik team.' : 
                                        'Enabling this feature allows you to ship to specific uploaded pin codes. When this service is not enabled, all Indian pin codes are serviceable for both prepaid and COD.'
                                    }
                                </p>
                            ) : (
                                <p className='text-sm  text-gray-400'>
                                    {
                                        (props.checkoutPlatform != 'kwik_checkout') ?
                                        'By enabling this feature, you will allow to deliver in uploaded pincode locations. To enable pincode serviceability for unique payment methods, contact GoKwik team.' : 
                                        'Enabling this feature allows you to ship to specific uploaded pin codes. When this service is not enabled, all Indian pin codes are serviceable for both prepaid and COD.'
                                    }
                                </p>
                            )}
                        </div>
                        <div className='flex gap-2 items-center'>
                            {/* <Button onClick={() => setDrawerOpen(true)} className='' variant='primary'>
                                <UploadOutlined /> Upload CSV
                            </Button> */}
                            <p>{pincodeDetails.enable_pincode ? 'Disable' : 'Enable'}</p>
                            <Switch
                                id='enable-pincode'
                                onClick={togglePincodeServiceablity}
                                checked={
                                    Object.keys(pincodeDetails).includes('enable_pincode') &&
                                    pincodeDetails.enable_pincode
                                }
                                className='mx-3 '
                            />
                        </div>
                    </div>
                )}
                {/* <div className='my-3'>
                    <p className='text-2xl font-semibold'>
                        Serviced pincodes <InfoCircleOutlined className='opacity-40' />
                    </p>
                </div> */}
                <div className='bg-white p-3 rounded-md'>
                    <p className='text-base font-semibold'>
                        Serviced pincodes
                        <Tooltip title={<>Pin codes not included in the uploaded file will be marked as non-serviceable for both prepaid and COD.</>}>
                            <InfoCircleOutlined className='opacity-40' />
                        </Tooltip>
                    </p>
                    <div className='gap-2 rounded-md flex items-center justify-between  '>
                        <div className='flex gap-2'>
                            <DownloadOutlined
                                onClick={() => downloadCSV(currentPincode?.file)}
                                className='text-blue-500'
                            />
                            <p>
                                {currentPincode?.fileName}
                                <span className='opacity-40 ml-2'>• {total} pincodes </span>
                            </p>
                        </div>
                        <Button
                            id='upload-pincode'
                            onClick={() => {
                                logEvent(
                                    'kwik_checkout_settings_pincode_servicibility_change_file_clicked',
                                    'click',
                                    'pincode',
                                    userDetails?.user_details?.email,
                                    merchantDetails?.m_id,
                                    merchantDetails?.short_name,
                                    userDetails?.user_details?.short_name,
                                )
                                setDrawerOpen(true)
                            }}
                            variant='primary'
                        >
                            {props.checkoutPlatform == 'kwik_checkout' && !currentPincode?.file
                                ? 'Upload file'
                                : 'Change File'}
                        </Button>
                    </div>
                </div>
                {pincodeDetails && (
                    <div className='mt-1 bg-white rounded-md pt-1  overflow-auto'>
                        <div className='w-3/12 mt-2 px-2 '>
                            <Input
                                onChange={(e) => setSearchPincode(e.target.value)}
                                type='text'
                                placeholder='Search Pincode'
                                maxLength={6}
                                suffix={<SearchOutlined />}
                            />
                        </div>
                        <div>
                            <Table
                                columns={columns}
                                dataSource={pincodeList || []}
                                pagination={{
                                    current: parameters.page, // Current page number
                                    pageSize: parameters.pageSize, // Number of items to display per page
                                    total: total, // Total number of items in the data array
                                    showSizeChanger: false,
                                    onChange: handlePaginationChange,

                                    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                                }}
                                // className='pt-2'
                                scroll={{ x: 300 }}
                            />
                        </div>
                    </div>
                )}
            </div>        
            <Modal
                footer={
                    showReplaceWarning ? (
                        <Row justify='end'>
                            <Button variant='default' onClick={() => setReplaceWarning(false)}>
                                No, go back
                            </Button>
                            <Button variant='primary' onClick={replacePincodesData} className='ml-2'>
                                Yes,replace
                            </Button>
                        </Row>
                    ) : showUpload ? null : (
                        <Row justify='end'>
                            <Button disabled={!fileSelected} variant='primary' onClick={() => setReplaceWarning(true)}>
                                Replace
                            </Button>
                        </Row>
                    )
                }
                okText={null}
                title={'Change pincodes'}
                className='w-[80vw] p-0'
                onCancel={() => {
                    setDrawerOpen(false)
                }}
                open={isDrawerOpen}
                styles={{
                    content: {
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        width: '40vw',
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        background: '#F3F3F3',
                    },
                    body: {
                        padding: '0px',
                        background: '#FFFFFF',
                    },
                    header: {
                        background: '#F3F3F3',
                        padding: '8px',
                    },
                    footer: {
                        padding: '8px',
                    },
                }}
            >
                <div className='bg-white p-2 flex items-center justify-center '>
                    <Segmented
                        label={null}
                        defaultValue='Choose from pre-existing file'
                        onChange={(e) => {
                            if (e == 'Upload File') {
                                setReplaceWarning(false)
                                setShowUpload(true)
                            } else if (e == 'Choose from pre-existing file') {
                                setReplaceWarning(false)
                                setShowUpload(false)
                            }
                            // fileSelected && showReplaceWarning && setReplaceWarning(true)
                        }}
                        options={['Upload File', 'Choose from pre-existing file']}
                    />
                </div>
                {!showReplaceWarning &&
                    !showUpload &&
                    filesList?.map((item, i) => (
                        <Row
                            key={i}
                            className='bg-white py-4 px-2 border-l-0 border-r-0 border-t-0 border-solid border-b-[0.5px] border-gray-200 cursor-pointer hover:cursor-pointer hover:bg-slate-200'
                            justify='space-between'
                            align='middle'
                            onClick={() => {
                                setCurrentFileSeletecd(item)
                            }}
                        >
                            <p className='text-base '>
                                <FileExcelFilled className='text-green-500 text-[20px]' />
                                <span className='ml-2'>{item.file}</span>
                                {fileSelected?.id === item.id && <CheckCircleFilled className='text-green-500 ml-2' />}
                            </p>
                            <Row className=''>
                                <p className='text-sm text-gray-400 gap-2 '>
                                    <span className='ml-2'>{dayjs(item.updated_at).format("DD MMM'YY")}</span>
                                    <RightOutlined className='ml-2' />
                                </p>
                            </Row>
                        </Row>
                    ))}
                {fileSelected && showReplaceWarning && (
                    <div className='flex flex-col items-center justify-center p-4'>
                        <InfoCircleFilled className='text-5xl text-blue-800' />
                        <p className='text-xl font-semibold my-2'>Are you sure you want to move ahead?</p>
                        <p className='text-sm text-gray-400 text-center'>
                            Changing the pincode file will impact all of your shipping related settings. Are you sure
                            you want to move ahead?
                        </p>
                        <p className='my-2 mt-4'>
                            The current pincode file ({currentPincode?.fileName}) will be replaced by
                        </p>
                        <Row className='gap-2 my-2'>
                            <p className='border-[0.7px] border-solid border-gray-300 rounded-md p-2 '>
                                <FileExcelFilled className='text-green-500 text-lg' />
                                {fileSelected?.file}
                            </p>
                            <p className='border-[0.7px] border-solid border-gray-300 rounded-md p-2'>
                                {fileSelected?.records_count || 0} Pincode
                            </p>
                        </Row>
                    </div>
                )}

                {showUpload && (
                    <div className='p-3'>
                        <Dragger
                            accept='.csv'
                            name='file'
                            maxCount={1}
                            multiple={true}
                            itemRender={(originNode, file) => {
                                return (
                                    <div>
                                        <span>{file.name}</span>
                                    </div>
                                )
                            }}
                            onChange={(info) => {
                                const { status, originFileObj } = info.file
                                info.file.status = 'done'
                                uploadPincodeCsv(originFileObj)
                            }}
                        >
                            <p className='ant-upload-drag-icon'>
                                <InboxOutlined />
                            </p>
                            <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                        </Dragger>
                        <div className='flex items-center justify-between border-solid rounded-md border-[#1677FF8C] p-2 bg-[#1677FF0D] my-2'>
                            <p className='text-xs text-gray-600'>
                                Please ensure that the uploaded file is in the suggested format as shown in the sample
                                file on the right
                            </p>
                            <Button onClick={donwloadSample} type='link' variant='default' className='ml-3 pr-0'>
                                <DownloadOutlined />
                                Download Sample
                            </Button>
                        </div>

                        <Row justify='space-between' className='my-2 mt-3'>
                            <p className='text-sm'>Supported Formats: CSV</p>
                            <p className='text-sm text-gray-500'>Maximum file size: 200 MB</p>
                        </Row>
                    </div>
                )}
            </Modal>
        </>
    )
}
