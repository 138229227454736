import { Collapse, QuestionCircleOutlined, Tooltip, Button, InfoCircleOutlined } from 'gokwik-ui-kit'
import { useEffect, useState, useRef } from 'react'
import { updateBreadcrumbs } from '@gokwik/utilities'
import BookDownload from '@library/images/book-download.svg'
import ShippingAndPaymentCustomizations from './shipping-and-payment'
import CodVerification from './cod-verification'
import { FloatingTourIcon, RenderTour } from '../tour/tour'
import { getCookie, setCookie } from '@library/utilities/helpers/cookieHelper'
import { codFeaturesTourSteps } from '../tour/tourSteps'
import "./helpers/globalButton.css"
import { IFeaturesEvents } from '@library/utilities/helpers/eventsHelper'
import LogoUpload from './logoUpload'
import { isShopifyAppCheckoutEnabled } from '@store/shopify-app/selectors'
import { useSelector } from 'react-redux'

const CodFeatures = (props: { events: IFeaturesEvents }) => {
    const [showTour, setShowTour] = useState(false)
    const [unsavedChanges, setUnsavedChanges] = useState(false)

    const { codFeaturesEvents, codConfigsEvents, shippingCustomisationsEvents, paymentCustomisationsEvents, reviewFlowEvents } = props.events;

    const shippingAndPaymentSaveRef = useRef<() => void>(() => {});
    const shippingAndPaymentDiscardRef = useRef<() => void>(() => {});
    const codVerificationSaveRef = useRef<() => void>(() => {});
    const codVerificationDiscardRef = useRef<() => void>(() => {});
    const logoUploadSaveRef = useRef<() => void>(() => {});
    const logoUploadDiscardRef = useRef<() => void>(() => {});
    const shopifyAppCheckoutEnabled = useSelector(isShopifyAppCheckoutEnabled)
    const [isLogoUploaded , setIsLogoUploaded] = useState(false)

    useEffect(() => {
        const checkCookies = () => {
            const isTourVisited = getCookie('codFeatureTour')
            if (!isTourVisited) {
                setShowTour(true)

            }
        }
        setTimeout(checkCookies, 100)
    }, [])

    const panelStyle = {
        marginBottom: 15,
        background: 'white',
        borderRadius: 10,
        padding: 5,
        borderBottom: '1px solid #d9d9d9',
    }

    const collapseItems = [
        {
            key: 'shipping-and-payment-customization',
            label: (
                <p>
                    <span className='text-base font-bold p-1'>Shipping and Payment Rules</span>
                    <Tooltip title={<>Hide, show, reorder & more for different Payment and shipping methods</>}>
                        <QuestionCircleOutlined className='ml-2' />{' '}
                    </Tooltip>
                </p>
            ),
            children: <ShippingAndPaymentCustomizations 
                        setOnSave={(saveFn) => { shippingAndPaymentSaveRef.current = saveFn; }}
                        setOnDiscard={(discardFn) => { shippingAndPaymentDiscardRef.current = discardFn; }} 
                        handleUnsavedChanges={setUnsavedChanges} 
                        shippingCustomisationEvents={shippingCustomisationsEvents}
                        paymentCustomisationEvents={paymentCustomisationsEvents}
                        reviewFlowEvents={reviewFlowEvents}
                    />,
            style: panelStyle,
        },
        {
            key: 'cod-verification',
            label: (
                <p>
                    <span className='text-base font-bold p-1'>COD Verification</span>
                    <Tooltip title={<>Enable Verification of the users placing CoD orders using verification methods listed below</>}>
                        <QuestionCircleOutlined className='ml-2' />{' '}
                    </Tooltip>
                </p>
            ),
            children: <CodVerification 
                        setOnSave={(saveFn) => { codVerificationSaveRef.current = saveFn; }} 
                        setOnDiscard={(discardFn) => { codVerificationDiscardRef.current = discardFn; }} 
                        handleUnsavedChanges={setUnsavedChanges} 
                        isLogoUploaded={isLogoUploaded} 
                        setIsLogoUploaded={setIsLogoUploaded}
                        codConfigEvents={codConfigsEvents}
                        reviewFlowEvents={reviewFlowEvents}
                    />,
            style: panelStyle,
        },
    ]

    function startTour() {
        setShowTour(true);
        codFeaturesEvents.tourStepsEvents.fireStartedEvent();
    }

    const goToNextStep = (step) => {
        codFeaturesEvents.tourStepsEvents.fireNextStepEvent({
            stepTitle: codFeaturesTourSteps[step].title
        });
    }

    const closeTour = () => {
        setShowTour(false)
        setCookie('codFeatureTour', true, 365)
        codFeaturesEvents.tourStepsEvents.fireSkippedEvent();
    }

    const finishTour = () => {
        setShowTour(false)
        setCookie('codFeatureTour', true, 365)
        codFeaturesEvents.tourStepsEvents.fireCompletedEvent();
    }

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'settings',
                href: '/checkout/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'cod-features',
                href: '/checkout/settings/cod-features',
                text: 'COD Features',
            },
        ])
    }, [])

    const handleSave = () => {
        shippingAndPaymentSaveRef.current();
        codVerificationSaveRef.current();
        logoUploadSaveRef.current();
        setUnsavedChanges(false);
    }

    const handleDiscard = () => {
        shippingAndPaymentDiscardRef.current();
        codVerificationDiscardRef.current();
        logoUploadDiscardRef.current();
        setUnsavedChanges(false);
    }

    return (
        <div className='w-full px-2 flex flex-col gap-2 inter'>
            <RenderTour showTour={showTour} steps={codFeaturesTourSteps} onClose={closeTour} onFinish={finishTour} onChange={goToNextStep} />
            <p className='text-lg font-bold p-2'>
                COD Features
                <a
                    className='ml-3 text-sm font-normal'
                    id='cod-features-guide'
                    href='https://scribehow.com/page/Untitled__q1KTFWmjSHKvv2RBV3_atA'
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() => codFeaturesEvents.tourStepsEvents.fireGuideOpenedEvent()}
                >
                    <img className='mr-1 mb-1' src={BookDownload} height={'18'} width={'18'} />
                    Open Guide 
                </a>
                <p className='text-sm text-gray-400 font-normal'>
                    Configure different CoD related features from here including hiding, re-ordering Shipping and payment methods.<br/> Also enabling various ways to verify users before placing CoD orders.
                </p>
            </p>
            {unsavedChanges && (
                <span className='flex justify-center gap-3 globalSave'>
                    <div className='flex items-center gap-1 text-white'>
                        <Tooltip>
                            <InfoCircleOutlined className='ml-2 mr-2' />{' '}
                        </Tooltip>
                        <p className='text-white text-base font-normal'>Unsaved Changes</p>
                    </div>
                    <div className='flex gap-3'>
                        <Button type='default' onClick={handleDiscard}>
                            Discard
                        </Button>
                        <Button type='primary' onClick={handleSave} className='bg-green-500 text-white'>
                            Save
                        </Button>
                    </div>
                </span>
            )}
            {showTour ? (
                <Collapse
                    className='bg-[#f0e8e805]'
                    activeKey={['shipping-and-payment-customization', 'cod-verification']}
                    bordered={false}
                    expandIconPosition='end'
                    items={collapseItems}
                />
            ) : (
                <Collapse
                    className='bg-[#f0e8e805]'
                    accordion
                    bordered={false}
                    expandIconPosition='end'
                    items={collapseItems}
                />
            )}

            {/* Logo upload section for non-checkout merchants */}
            {/* checkout merchants can upload logo from UI Customisations section */}
            {!shopifyAppCheckoutEnabled && (
                <LogoUpload
                    setOnSave={(saveFn) => {
                        logoUploadSaveRef.current = saveFn
                    }}
                    setOnDiscard={(discardFn) => {
                        logoUploadDiscardRef.current = discardFn
                    }}
                    handleUnsavedChanges={setUnsavedChanges}
                    setIsLogoUploaded={setIsLogoUploaded}
                />
            )}

            <FloatingTourIcon onClick={startTour}/>
        </div>
    )
}

export default CodFeatures
