import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { Button, Col, DatePicker, Drawer, Form, Input, InputNumber, message, Radio, Row, Space } from 'gokwik-ui-kit'
import { useRef, useState } from 'react'
import { debounce } from 'lodash'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import dayjs from 'dayjs'
import { FileUploader } from './uploadBulk'

const CreateBulkDiscounts = (props) => {
    const [codeGenerationMethod, setCodeGenerationMethod] = useState('random')
    const [priceRuleId, setPriceRuleId] = useState('')
    const [form] = Form.useForm()
    const merchant_details = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)

    const [buttonDisabled, setButtonDisabled] = useState(false)
    const uploadUrl = useRef('')
    const validateDiscountCode = async (code: string, merchant_id: string) => {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BULK_DISCOUNT_URL + APIEndPoints.bulkDiscounts.validateDiscountSet,
            payload: {
                merchant_id,
                code,
            },
        })
        if (response.success) {
            if (response?.data?.data?.priceRule?.id) {
                setPriceRuleId(response?.data?.data?.priceRule?.id)
                return true
            } else return false
        } else {
            message.error('Unable to validate discount code')
            return false
        }
    }

    const codeGenerationFormula = (codeGenerationData) => {
        const NUMBERS_CHARSET = 10
        const ALPHABET_CHARSET = 26
        const COMBINE_CHARSET = 36
        const { length, total_codes, prefix, suffix } = codeGenerationData

        let a = COMBINE_CHARSET
        const minimumLength =
            Math.floor(Math.ceil(Math.log(total_codes) / Math.log(a)) + 3) + prefix?.length + suffix?.length

        if (length < minimumLength) {
            message.error(
                `The combination you are trying is not feasible. Please try minimum length of ${minimumLength}`,
            )
            return false
        } else {
            return true
        }
    }

    const handleMethodChange = (e) => {
        setCodeGenerationMethod(e?.target?.value)
    }

    const uploadDiscountCSV = async (originFileObj, callBack) => {
        const fileName = `discount_codes_${dayjs().unix()}.zip`
        const preSignedURLResponse = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BULK_DISCOUNT_URL + APIEndPoints.bulkDiscounts.presignedUrl,
            payload: {
                url: fileName,
                merchant_id: merchant_details.m_id,
                operation_type: 'UPLOAD',
            },
        })

        if (preSignedURLResponse?.success && preSignedURLResponse.data?.data?.presignedUrl) {
            const preSignedURL = preSignedURLResponse.data?.data?.presignedUrl
            if (preSignedURL) {
                const csvFile = originFileObj
                const awsResponse = await fetch(preSignedURL, {
                    method: 'PUT',
                    body: csvFile,
                })
                if (awsResponse.status === 200) {
                    uploadUrl.current = fileName
                } else {
                    message.error('Upload Failed: Presigned URL error')
                    return
                }
            }
            callBack()
        } else {
            message.error('Upload Failed: Presigned URL error')
            return
        }
    }
    const handleChange = debounce((values) => {
        if (codeGenerationMethod === 'random') {
            const { total_codes, length, prefix, suffix } = values
            if (!total_codes || !length || !priceRuleId) {
                return
            } else if (prefix.length + suffix.length > length - 1) {
                setButtonDisabled(true)
                message.error('Combined length of Prefix or Suffix must be less than "Code Length"')
                return
            } else {
                setButtonDisabled(
                    !codeGenerationFormula({
                        total_codes,
                        length,
                        prefix,
                        suffix,
                    }),
                )
            }
        } else {
            const { total_codes, prefix, suffix } = values
            if (!total_codes || !priceRuleId) {
                return
            } else {
                setButtonDisabled(false)
            }
        }
    }, 1200)

    const generateDiscountCode = async (values) => {
        if (codeGenerationMethod === 'random') {
            if (buttonDisabled) {
                message.error('Please fill all the fields correctly')
                return
            }
        }

        const payload = codeGenerationMethod !== 'random' ? { discount_codes_url: uploadUrl.current } : {}

        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BULK_DISCOUNT_URL + APIEndPoints.bulkDiscounts.createDiscount,
                payload: {
                    ...payload,
                    ...values,
                    merchant_id: merchant_details.m_id,
                    ends_at: dayjs(values.ends_at).toISOString(),
                    is_active: true,
                    email: userDetails.email,
                    campaign_meta_id: priceRuleId,
                    charset_type: 'both',
                },
            })

            if (response.success) {
                message.success(
                    `Your coupon generation is successfully processed. You will receive the same on ${userDetails.email} within 15 minutes`,
                )
                props.onSubmit()
                props.onCancel()
            } else {
                message.error(response.response?.data?.data || 'Unable to generate discount code')
            }
        } catch (error) {
            console.error('Error generating discount code:', error)
        }
    }

    return (
        <Drawer title={`Create Bulk Discount`} width={500} open={props.open} onClose={props.onCancel}>
            <Form
                form={form}
                layout='vertical'
                initialValues={{
                    length: 0,
                    total_codes: 0,
                    prefix: '',
                    suffix: '',
                    ends_at: dayjs().add(3, 'month').set('hour', 23).set('minute', 59).set('second', 59),
                }}
                onValuesChange={(changedValues, allValues) => {
                    handleChange(allValues)
                }}
                onFinish={(values) => {
                    if (!values.prefix && !values.suffix) {
                        message.error('Please enter either a prefix or a suffix')
                        return
                    }
                    if (codeGenerationMethod === 'csv_file' && !uploadUrl.current) {
                        message.error('Please upload a file')
                        return
                    }
                    generateDiscountCode(values)
                }}
            >
                <Form.Item
                    name='title'
                    rules={[
                        {
                            required: true,
                            message: 'Please enter a title for the discount set',
                        },
                        {
                            max: 30,
                            message: 'Title should not exceed 30 characters',
                        },
                        {
                            validator: async (rule, value) => {
                                const res = await validateDiscountCode(value, merchant_details.m_id)
                                if (!res) {
                                    return Promise.reject('Discount set does not exist')
                                } else {
                                    return Promise.resolve()
                                }
                            },
                        },
                    ]}
                    label='Discount Set Title'
                    validateDebounce={800}
                >
                    <Input
                        placeholder='Enter your discount set title here'
                        // onChange={handleInputChange}
                        maxLength={30}
                    />
                </Form.Item>
                <Form.Item required label='Expiry' name={'ends_at'}>
                    <DatePicker
                        minDate={dayjs().add(1, 'day').set('hour', 0).set('minute', 0).set('second', 0)}
                        format='DD/MM/YYYY'
                        placeholder='Select expiry date'
                        className='w-full'
                        showTime
                    />
                </Form.Item>
                <Form.Item label='How do you want to create the discount codes for this set?'>
                    <Radio.Group onChange={handleMethodChange} defaultValue={codeGenerationMethod}>
                        <Space direction='vertical'>
                            <Radio value={'random'}>Generate random codes</Radio>
                            <Radio value={'csv_file'}>Upload a ZIP of CSV file(s)</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name={'total_codes'}
                    label={`Number of codes ${codeGenerationMethod === 'random' ? 'to generate' : 'in file(s)'}`}
                    rules={[
                        {
                            required: true,
                            message: 'Number of codes is required',
                        },
                        {
                            type: 'number',
                            max: 20000000,
                            min: 1,
                            message: 'Number of codes should not exceed 2,00,00,000',
                        },
                    ]}
                >
                    <InputNumber className='w-full' placeholder='' max={20_000_000} />
                </Form.Item>

                <Form.Item
                    name={'prefix'}
                    label='Code Prefix'
                    rules={[
                        {
                            pattern: /^[A-Z0-9]+$/,
                            message: 'Please enter only capital letters (A-Z) and numbers (0-9).',
                        },
                    ]}
                >
                    <Input className='w-full' placeholder='' />
                </Form.Item>

                <Form.Item
                    name={'suffix'}
                    label='Code Suffix'
                    rules={[
                        {
                            pattern: /^[A-Z0-9]+$/,
                            message: 'Please enter only capital letters (A-Z) and numbers (0-9).',
                        },
                    ]}
                >
                    <Input className='w-full' placeholder='' />
                </Form.Item>

                <p className='border-solid my-4 rounded-md border-primary-400 p-2 bg-primary-25'>
                    Defining either a prefix or a suffix enables you to configure the generated discount codes for any
                    Gokwik custom discount use case.
                </p>
                {codeGenerationMethod === 'random' ? (
                    <Form.Item
                        name={'length'}
                        label='Code length (in characters)'
                        rules={[
                            {
                                required: true,
                                message: 'Length is required',
                            },
                            {
                                type: 'number',
                                max: 30,
                                min: 1,
                                message: 'Length should be between 1 and 30',
                            },
                        ]}
                    >
                        <InputNumber name='length' className='w-full' placeholder='' min={1} max={20} />
                    </Form.Item>
                ) : (
                    <>
                        <FileUploader
                            uploadDiscountCSV={uploadDiscountCSV}
                            removeDiscountCSV={() => {
                                uploadUrl.current = ''
                            }}
                        />
                    </>
                )}
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item>
                            <Button className='w-full' htmlType='submit' type='primary' disabled={buttonDisabled}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Button
                            className='w-full'
                            onClick={() => {
                                form.resetFields()
                                props.onCancel()
                            }}
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}

export default CreateBulkDiscounts
