import {
    Button,
    Col,
    ColumnProps,
    Divider,
    Modal,
    Row,
    Table,
    Tag,
    Tooltip,
    message,
    DeleteOutlined,
    TagOutlined,
    Popconfirm,
    DownloadOutlined,
} from 'gokwik-ui-kit'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { previousDiscountFilters } from '@store/filters/selectors'
import { saveFiltersData } from '@store/filters'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { convertIsoToLocaleString, downloadFromURL, makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import CreateBulkDiscounts from './createBulkDiscounts'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'

const BulkDiscounts = () => {
    const userDetails = useSelector(getUserDetails)
    const merchantDetails = useSelector(getMerchantDetails)
    const dispatch = useDispatch()
    const [showDrawer, setShowDrawer] = useState(false)
    const [discounts, setDiscounts] = useState([])
    const discountInProcessing = useMemo(
        () => discounts.some((discount) => discount.job_status?.toLowerCase() === 'initiated'),
        [discounts],
    )
    const merchant_details = useSelector(getMerchantDetails)
    const prevFilters = useSelector(previousDiscountFilters)
    const [parameters, setParameters] = useState<any>({
        merchantId: String(merchant_details?.m_id),
        ...prevFilters,
    })

    const discountTableColumns: ColumnProps<any>[] = [
        {
            title: <span>Title</span>,
            dataIndex: 'code',
            ellipsis: true,
            width: 18,
            render: (text: string) => (
                <Tooltip placement='topRight' title={text}>
                    {text?.toUpperCase()}
                </Tooltip>
            ),
        },
        {
            title: <span>Status</span>,
            dataIndex: 'status',
            ellipsis: true,
            width: 15,
            align: 'center',
            render: (text: string, record) =>
                record.job_status?.toLowerCase() === 'initiated' ? (
                    <Tag className='rounded' variant='default'>
                        Processing
                    </Tag>
                ) : (
                    <Tag className='rounded' variant={text ? 'success' : 'error'}>
                        {text ? 'Active' : 'Inactive'}
                    </Tag>
                ),
        },

        {
            title: <span>Number of Codes</span>,
            dataIndex: 'count',
            align: 'center',
            width: 15,
            render: (text: number) => <span>{text ? Intl.NumberFormat('en-IN').format(+text) : ''}</span>,
        },
        {
            title: <span>Created At</span>,
            dataIndex: 'created_at',
            align: 'center',
            width: 15,
            render: (text: string) => <span>{text ? convertIsoToLocaleString(text) : ''}</span>,
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            align: 'center',
            width: 15,
            render: (text: string) => <span>{text ? convertIsoToLocaleString(text) : ''}</span>,
        },
        {
            title: <span>Actions</span>,
            dataIndex: 'actions',
            ellipsis: true,
            align: 'center',
            fixed: 'right',
            width: 15,
            render: (_, record) => {
                const isCompleted = record.job_status?.toLowerCase() === 'completed'
                const isProcessing = record.job_status?.toLowerCase() === 'initiated'
                return (
                    <Row className='flex gap-3 m-0' justify={'center'}>
                        <Tooltip
                            className={`${isCompleted ? ' cursor-pointer' : 'cursor-not-allowed'}`}
                            placement='topLeft'
                            title={isCompleted ? 'Download' : 'Download not available, record is being processed'}
                        >
                            <DownloadOutlined
                                className={`${isCompleted && 'text-primary-450'}`}
                                onClick={() => isCompleted && handleDownload(record)}
                            />
                        </Tooltip>
                        <Popconfirm
                            disabled={isProcessing}
                            title='Delete'
                            onConfirm={() => !isProcessing && deleteDiscount(record)}
                            okButtonProps={{ className: 'bg-error-500 hover:bg-error-500' }}
                        >
                            <Tooltip
                                className={`${!isProcessing ? ' cursor-pointer' : 'cursor-not-allowed'}`}
                                placement='topLeft'
                                title={
                                    isProcessing ? 'Discount can not be deleted if under processing' : 'Delete Discount'
                                }
                            >
                                <DeleteOutlined className={`${isProcessing ? '' : 'text-error-500'}`} />
                            </Tooltip>
                        </Popconfirm>
                    </Row>
                )
            },
        },
    ]

    const handleDownload = async (discount) => {
        try {
            let preSignedURLResponse = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BULK_DISCOUNT_URL + APIEndPoints.bulkDiscounts.presignedUrl,
                payload: {
                    merchant_id: merchant_details?.m_id,
                    campaign_id: discount.campaign_id,
                    url: discount.file_url,
                    operation_type: 'FETCH',
                },
            })
            if (preSignedURLResponse?.success && preSignedURLResponse.data?.data?.presignedUrl) {
                downloadFromURL(preSignedURLResponse.data?.data?.presignedUrl, discount.code)
            }
        } catch (err) {
            console.warn(err)
        }
    }

    const deleteDiscount = async (discountToDelete) => {
        if (!discountToDelete.campaign_id) {
            message.error('Delete Error: No discount id')
            return
        }
        try {
            let response = await makeAPICall({
                method: 'delete',
                url: process.env.REACT_APP_BULK_DISCOUNT_URL + APIEndPoints.bulkDiscounts.createDiscount,
                params: {
                    merchantId: merchant_details?.m_id,
                    campaignId: discountToDelete.campaign_id,
                },
            })
            if (response?.success) {
                makeDiscountsApiCall({ ...parameters })
                message.success('Discount deleted successfully')
            } else {
                message.error('Delete Error: Something went wrong')
            }
        } catch (err) {
            console.warn(err)
        }
    }

    const makeDiscountsApiCall = async (parameters: any) => {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BULK_DISCOUNT_URL + APIEndPoints.bulkDiscounts.getDiscountsList,
                params: parameters,
            })

            if (response?.success) {
                setDiscounts(response?.data?.data)
            }
            dispatch<any>(saveFiltersData('bulk-discounts', { ...parameters }))
        } catch (err) {
            console.warn(err)
        }
    }
    useEffect(() => {
        makeDiscountsApiCall({ ...parameters })
    }, [])
    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'checkout_settings',
                href: '/checkout/settings/bulk-discount',
                text: 'Checkout Settings',
            },
            {
                key: 'discount',
                href: '/checkout/settings/bulk-discount',
                text: 'Bulk Discounts Setup',
            },
        ])
    }, [])

    const handleCreateDiscount = () => {
        setShowDrawer(true)
    }

    return (
        <>
            <div className='w-full flex flex-col gap-4'>
                <Row className='p-2 rounded bg-neutrals-15' align={'middle'} justify={'space-between'}>
                    <Col span={12}>
                        <div className=' text-lg'>
                            <TagOutlined className='text-blue-500 border border-solid border-blue-500 rounded-full p-1' />
                            &nbsp;<span className=' font-semibold'>List of Bulk Discount Sets</span>
                        </div>
                    </Col>
                    <Col>
                        <Tooltip
                            title={
                                discountInProcessing
                                    ? 'A bulk discount generation is already in progress. Please wait for it’s completion to initiate another generation.'
                                    : ''
                            }
                        >
                            <Button
                                disabled={discountInProcessing}
                                onClick={() => {
                                    logEvent(
                                        'kwik_checkout_settings_bulk_discount_setup_Create_discount_clicked',
                                        'click',
                                        'pincode',
                                        userDetails?.user_details?.email,
                                        merchantDetails?.m_id,
                                        merchantDetails?.short_name,
                                        userDetails?.user_details?.short_name,
                                    )
                                    handleCreateDiscount()
                                }}
                                variant='primary'
                            >
                                <TagOutlined />
                                Create Discount
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className=' bg-white rounded overflow-clip'>
                            <Table
                                columns={discountTableColumns}
                                dataSource={discounts}
                                pagination={{
                                    total: discounts.length,
                                    pageSize: 25,
                                    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Offers`,
                                    showSizeChanger: false,
                                    position: ['bottomLeft'],
                                }}
                                // scroll={{ x: 350 }}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <CreateBulkDiscounts
                onSubmit={() => makeDiscountsApiCall({ ...parameters })}
                open={showDrawer}
                onCancel={() => setShowDrawer(false)}
            />
        </>
    )
}

export default BulkDiscounts
