import { Alert, Button, ChipInput, Col, Divider, Form, Input, message, Row, Select, Switch } from 'gokwik-ui-kit'
import BookDownload from '@library/images/book-download.svg'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import React, { useEffect, useMemo } from 'react'
import { stringMatchTypeOptions } from '@library/utilities/constants/constants'
import RenderField from '@library/utilities/helpers/renderFormItem'
import { deepEqualAndDiff } from '@gokwik/utilities'
import { navigateToUrl } from 'single-spa'
import LinkImg from '@library/images/PixelIcons/link.svg'
import { useSelector } from 'react-redux'
import { getUserConfig } from '@store/user/selectors'

const OtherConfigs = () => {
    const [form] = Form.useForm()
    const { config, saveConfig } = useMerchantConfigApi({
        patch: true,
    })
    const merchantConfig = Form.useWatch('merchant_config', form)
    const enableNoCod = Form.useWatch('enable_variant_level_no_cod', form)
    const getUserConfiguration = useSelector(getUserConfig)
    const enableKwikPass = getUserConfiguration?.kwikpass_merchant
    const changedValues = useMemo(
        () =>
            deepEqualAndDiff(
                {
                    ...config,
                },
                form.getFieldsValue(true).merchant_config,
            ),
        [merchantConfig, config],
    )
    const kwikFlowAlert = {
        alertMessage: 'For SKU based blocking we recommend using Kwik Flows',
        alertAction: (
            <Button onClick={() => navigateToUrl('/rto/kwikflows')}>
                <img src={LinkImg} className='w-3 h-3 mr-2' alt='link icon' />
                Take me there.
            </Button>
        ),
    }

    const fields = [
        {
            type: 'switch',
            name: ['merchant_config', 'check_inventory'],
            label: 'Check Inventory on Checkout?',
            description:
                'Enabling this config ensures that we check for inventory status before order creation to avoid placing orders for out of stock products.',
            // knowMoreLink:
            //     'https://scribehow.com/page/Guide_to_Set_Up_Shipping_Serviceability_on_GoKwik_Dashboard__cxszaSrOQ6GjY8KvNdtWOg',
            alertMessage:
                'Please ensure that products are set to "continue selling when out of stock" on Shopify to avoid post order placement cancellations.',
            span: 24,
        },
        {
            type: 'switch',
            name: ['merchant_config', 'create_inventory_update'],
            label: 'Update Inventory on Shopify',
            description:
                'Enabling this config ensures that we adjust inventory count on order placement and cancellations on Shopify.',
            // knowMoreLink:
            //     'https://scribehow.com/page/Guide_to_Set_Up_Shipping_Serviceability_on_GoKwik_Dashboard__cxszaSrOQ6GjY8KvNdtWOg',
            alertMessage: `Please keep this disabled if your inventory is managed by 3rd party and you don't want us to update the same.`,
            span: 24,
        },

        {
            type: 'switch',
            name: ['merchant_config', 'shopify_refund'],
            label: 'Process Refunds from Shopify?',
            description:
                'Enabling this config ensures that any cancelled order on Shopify portal is automatically refunded from our end.',
            // knowMoreLink:
            //     'https://scribehow.com/page/Guide_to_Set_Up_Shipping_Serviceability_on_GoKwik_Dashboard__cxszaSrOQ6GjY8KvNdtWOg',
            alertMessage:
                !merchantConfig?.shopify_refund &&
                `Please keep this disabled if you are processing refunds manually or using our refund APIs or some other method to process refunds.`,
            span: 24,
            additionalFields: [
                {
                    type: 'switch',
                    name: ['merchant_config', 'shopify_partial_refunds'],
                    label: 'Partial Refunds',
                    description:
                        'Enabling this config ensures that partial refunds triggered from Shopify portal are also triggered from our end.',
                    hidden: !merchantConfig?.shopify_refund,
                    alertMessage: `Please keep this disabled if you are processing refunds manually or using our refund APIs or some other method to process refunds.`,

                    span: 24,
                },
            ],
        },
        {
            type: 'switch',
            name: ['merchant_config', 'custom_email', 'enabled'],
            label: 'Custom Email Domain',
            description:
                'Enabling this config ensures we send static email IDs to Shopify for orders in the format {phone_number}@{custom domain}. This ensures that no duplicate accounts are created for the same phone number on Shopify.',
            // knowMoreLink: 'https://scribehow.com/page/Custom_Email_Configuration__cxszaSrOQ6GjY8KvNdtWOg',
            additionalFields: [
                {
                    type: 'input',
                    name: ['merchant_config', 'custom_email', 'domain'],
                    label: 'Domain name',
                    hidden: !merchantConfig?.custom_email?.enabled,
                    inputProps: {
                        placeholder: 'Eg: gokwik.co',
                        prefix: '@',
                    },
                    rules: [
                        {
                            required: true,
                            validator(_, value) {
                                if (!value?.trim() || !/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
                                    return Promise.reject('Please enter a valid domain name')
                                }
                                return Promise.resolve()
                            },
                        },
                    ],
                    span: 8,
                },
            ],
            span: 24,
        },
        {
            type: 'switch',
            name: ['merchant_config', 'verify_new_users'],
            label: 'OTP for All Users on Checkout',
            description:
                'Enabling this config will ensure we ask OTP for all users on login step (except pre-verified logged in users). By default OTP is only required from users whose addresses are present for prefilling (PII data compliance).',
            // knowMoreLink:
            //     'https://scribehow.com/page/Guide_to_Set_Up_Shipping_Serviceability_on_GoKwik_Dashboard__cxszaSrOQ6GjY8KvNdtWOg',
            span: 24,
        },
        {
            type: 'switch',
            name: ['merchant_config', 'custom_config', 'freebie_value_as_zero'],
            label: 'Enable Freebie Attribution',
            description:
                'Enabling this config ensures that free gift discounts and BXGY discount products are passed at 0 value instead of original value to Shopify.',
            // knowMoreLink:
            //     'https://scribehow.com/page/Guide_to_Set_Up_Shipping_Serviceability_on_GoKwik_Dashboard__cxszaSrOQ6GjY8KvNdtWOg',
            span: 24,
        },
        {
            type: 'switch',
            hidden: !enableKwikPass,
            name: ['merchant_config', 'custom_config', 'kp_email_update_config'],
            label: 'Multiple Account Creation with KwikPass',
            description:
                'Disabling this will create multiple accounts for customers if they place an order using an account other than what they have logged in with.',
            // knowMoreLink:
            //     'https://scribehow.com/page/Guide_to_Set_Up_Shipping_Serviceability_on_GoKwik_Dashboard__cxszaSrOQ6GjY8KvNdtWOg',
            span: 24,
        },
        {
            type: 'switch',
            name: ['enable_variant_level_no_cod'],
            label: 'No COD on Variant Title',

            description:
                'This config allows you to block cash on delivery payment method for specific product variants (eg: personalised or tailored variants of products).',
            alertMessage: !enableNoCod ? kwikFlowAlert.alertMessage : '',
            alertAction: !enableNoCod ? kwikFlowAlert.alertAction : undefined,
            // knowMoreLink:
            //     'https://scribehow.com/page/Guide_to_Set_Up_Shipping_Serviceability_on_GoKwik_Dashboard__cxszaSrOQ6GjY8KvNdtWOg',
            span: 24,
            additionalFields: [
                {
                    type: 'select',
                    name: ['merchant_config', 'match_type_enable_variant_level_no_cod'],
                    label: 'Match Type',
                    hidden: !enableNoCod,
                    inputProps: {
                        options: stringMatchTypeOptions,
                        placeholder: 'Select a match type',
                    },
                    rules: [
                        {
                            required: true,
                            message: 'Please select a match type',
                        },
                    ],
                    span: 6,
                },
                {
                    type: 'chip',
                    name: ['merchant_config', 'enable_variant_level_no_cod'],
                    label: 'Variant Titles',
                    span: 24,
                    hidden: !enableNoCod,
                    ...(!enableNoCod ? kwikFlowAlert : {}),
                    rules: [
                        {
                            required: true,
                            message: 'Please enter at least one variant title',
                        },
                    ],
                    inputProps: {
                        placeholder: 'Add unique variant title and press enter',
                    },
                    alertMessage: enableNoCod ? kwikFlowAlert.alertMessage : '',
                    alertAction: enableNoCod ? kwikFlowAlert.alertAction : undefined,
                },
            ],
        },
    ]

    useEffect(() => {
        form.setFieldsValue({
            merchant_config: config,
            enable_variant_level_no_cod: !!config?.enable_variant_level_no_cod?.length,
        })
    }, [config])

    return (
        <Row gutter={[16, 16]} className='px-3'>
            <Col span={18}>
                <div className='flex items-center text-lg leading-7'>
                    <p className='text-lg font-semibold'>Other Settings</p>
                    {/* <a
                        className='ml-2 flex items-center text-sm font-normal'
                        href='https://scribehow.com/page/Guide_to_Set_Up_Shipping_Serviceability_on_GoKwik_Dashboard__cxszaSrOQ6GjY8KvNdtWOg'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <img className='mr-1' src={BookDownload} height='18' width='18' alt='Open Guide' />
                        Open Guide
                    </a> */}
                </div>

                <p className='py-0 text-gray-600'>
                    Configure inventory settings, refund setting and more from this section.
                </p>
            </Col>
            <Col span={6} className='flex justify-end'>
                <Button
                    type='primary'
                    disabled={
                        !(!changedValues.isEqual || enableNoCod !== !!config?.enable_variant_level_no_cod?.length)
                    }
                    onClick={() => {
                        form.submit()
                    }}
                >
                    Save
                </Button>
            </Col>
            <Col span={24}>
                <Form
                    form={form}
                    layout='vertical'
                    requiredMark={'optional'}
                    scrollToFirstError
                    initialValues={{
                        kp_email_update_config: true,
                    }}
                    onFinish={async (values) => {
                        const payload = {
                            ...changedValues.diff,
                        }
                        if (!enableNoCod) {
                            payload.enable_variant_level_no_cod = []
                        }
                        await saveConfig(payload)
                    }}
                >
                    <Row gutter={[16, 16]}>
                        {fields.map((field, i) => (
                            <React.Fragment key={field.name.join('-')}>
                                {field?.hidden ? null : (
                                    <Col span={field.span}>
                                        <RenderField field={field} form={form} />
                                        {field.alertMessage && (
                                            <Alert
                                                message={field.alertMessage}
                                                type='info'
                                                showIcon
                                                className='mt-2'
                                                action={field.alertAction}
                                            />
                                        )}
                                    </Col>
                                )}

                                {field.additionalFields &&
                                    field.additionalFields.map((additionalField) =>
                                        additionalField.hidden ? null : (
                                            <Col span={additionalField.span} key={additionalField.label}>
                                                <RenderField field={additionalField} form={form} />
                                                {additionalField.alertMessage && (
                                                    <Alert
                                                        message={additionalField.alertMessage}
                                                        type='info'
                                                        showIcon
                                                        className='mt-2'
                                                        action={additionalField.alertAction}
                                                    />
                                                )}
                                            </Col>
                                        ),
                                    )}

                                {i !== fields.length - 1 && <Divider className='my-0' />}
                            </React.Fragment>
                        ))}
                    </Row>
                </Form>
            </Col>
        </Row>
    )
}

export default OtherConfigs
