import { addBreadcrumb, makeAPICall, updateBreadcrumbs, validatePermission } from '@gokwik/utilities'
import {
    Button,
    Col,
    Row,
    Divider,
    Table,
    ColumnsType,
    Tabs,
    Tag,
    Tooltip,
    SolutionOutlined,
    UserOutlined,
    LinkOutlined,
    PhoneOutlined,
    MailOutlined,
    LaptopOutlined,
    RedoOutlined,
    Popover,
    message,
    InfoCircleOutlined,
    InfoCircleFilled,
    CheckCircleFilled,
    WarningFilled,
    UpOutlined,
    DownOutlined,
} from 'gokwik-ui-kit'
import external_link from '@library/images/external-link.svg'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { CartItem, LineItem, OrderData, Refund, Transaction } from '@library/utilities/interface'
import { giftCards, lineItemColumns, paymentColumns, refundColumns, storeCredits } from './data'
import RefundDrawer from './refundDrawer'
import {
    getMerchantDetails,
    getMerchantPlatform,
    getUserConfig,
    getUserDetails,
    isMerchantSelector,
} from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { PermissionValues, RTO_FAKE_ORDER_REASONS } from '@library/utilities/constants/constants'
import { navigateToUrl } from 'single-spa'
import {
    DiscountBreakupsData,
    findOrderFailureReason,
    GiftBreakupData,
    ShippingBreakupData,
    TaxBreakupData,
} from './priceBreakups'
import { formatAmount } from '@library/utilities/helpers/helper'
import PastPrepaidCustomerIcon from '@library/images/pastPrepaidCustomer'

const statusMappingForColors = {
    confirmed: 1,
    pending: 2,
    true: 1,
    false: 3,
    cancelled: 3,
    failed: 3,
}

const statusMappingForTag = {
    confirmed: 'success',
    pending: 'warning',
    cancelled: 'error',
}

export interface MergedRepeatedRefund extends Refund {
    attempts: number
}

const mergeRepeatedRefunds = (refundsData: Refund[]): MergedRepeatedRefund[] => {
    const sortByRids = refundsData.reduce(
        (acc, refund) =>
            (acc = {
                ...acc,
                [refund.gokwik_rid]: acc[refund.gokwik_rid] ? [...acc[refund.gokwik_rid], refund] : [refund],
            }),
        {},
    )
    const mergedRefunds = Object.values(sortByRids).map((val: Refund[]) => {
        const primaryRefund = val.find((refund) => refund.bank_status !== 'payment_failed') || val[val.length - 1]
        if (primaryRefund.bank_status === 'payment_successful') {
            primaryRefund.status = 'Completed'
        } else if (primaryRefund.bank_status === 'payment_failed' || primaryRefund.bank_status === 'failed') {
            primaryRefund.status = 'Failed'
        } else if (primaryRefund.bank_status === 'pending' || primaryRefund.bank_status === 'timeout') {
            primaryRefund.status = 'Initiated'
        }
        return {
            ...primaryRefund,
            attempts: val.length,
        }
    })
    return mergedRefunds
}

const OrderDetails = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [orderData, setOrderData] = useState<OrderData>(null)
    const [openRefundDrawer, setOpenRefundDrawer] = useState(false)
    const isMerchantUser = useSelector(isMerchantSelector)
    const merchantPlatform = useSelector(getMerchantPlatform)
    const merchantConfig = useSelector(getUserConfig)
    const showRTOScoreToMerchant = merchantConfig?.show_rto_score || false
    const [order_failure_reason, setOrderFailureReason] = useState(null)
    const [refundBtnState, setRefundBtnState] = useState<{
        disabled: boolean
        refundStatus: string
        refundableAmount: number
    }>({
        disabled: false,
        refundStatus: '',
        refundableAmount: 0,
    })
    const [showFakeOrderReasons, setShowFakeOrderReasons] = useState(true)

    const fetchOrderDetails = useCallback(async () => {
        const response = await makeAPICall({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.getOrderById(id)}`,
        })
        if (response.success) {
            const conditionedRefunds = mergeRepeatedRefunds(response.data.data.refund)
            if (!response.data.data.orders.is_deleted) {
                let totalRefundAbleAmount: number = 0
                const refundBtn = { ...refundBtnState }
                response.data.data?.transactions?.map((each: Transaction) => {
                    if (
                        (each.bank_status === 'payment_successful' ||
                            (each.bank_status === 'timeout' && each.payment_provider === 'ybl')) &&
                        !each.is_deleted &&
                        !each.is_auto_refund
                    ) {
                        if (each.bank_status === 'timeout' && each.payment_provider === 'ybl') {
                            each.status = 'Timeout'
                            //   setIsTransactionTimeout(true)
                        }
                        totalRefundAbleAmount += parseFloat('' + (each.amount || '0.0'))
                    } else if (!each.is_deleted && !each.is_auto_refund) {
                        refundBtn.refundStatus = 'not-applicable'
                    }
                })

                const triggeredRefund = +conditionedRefunds
                    .reduce((acc, currentRefund) => {
                        if (
                            ['payment_successful', 'pending', 'timeout', 'payment_initiated'].includes(
                                currentRefund.bank_status,
                            ) &&
                            !currentRefund.is_auto_refund
                        ) {
                            acc = acc + +currentRefund.amount
                            if (['pending', 'timeout', 'payment_initiated'].includes(currentRefund.bank_status))
                                refundBtn.refundStatus = currentRefund.status
                        }
                        return acc
                    }, 0)
                    .toFixed(2)

                refundBtn.disabled = +totalRefundAbleAmount <= triggeredRefund

                let priceBreakupValues = response?.data?.data?.orders?.price_breakup || {}
                const hasStoreCredits = priceBreakupValues?.store_credits?.totalAmount > 0
                const isCodOrder = response.data.data.orders.payment_method === 'COD'

                if (hasStoreCredits && isCodOrder) {
                    refundBtn.disabled = false
                }
                setRefundBtnState({
                    ...refundBtn,
                    refundableAmount: (+totalRefundAbleAmount || 0) - (triggeredRefund || 0),
                })
            }
            let priceBreakupValues = response?.data?.data?.orders?.price_breakup || {}
            setOrderData({
                ...response.data.data,
                refund: conditionedRefunds,
                total_discount:
                    (priceBreakupValues?.ABCDiscount?.amount || 0) +
                    (priceBreakupValues?.C2PDiscount || 0) +
                    (priceBreakupValues?.paymentOffer?.amount || 0) +
                    (priceBreakupValues?.loyaltyPoints?.amount || 0) +
                    (priceBreakupValues?.prepaidDiscount || 0) +
                    (priceBreakupValues?.postEditOrderDiscount?.amount || 0) +
                    (priceBreakupValues?.shopifyDiscounts?.totalAmount ||
                        priceBreakupValues?.shopifyDiscount?.amount ||
                        0) +
                    (priceBreakupValues?.automaticDiscounts?.totalAmount || 0) +
                    (priceBreakupValues?.customDiscount || 0),
                tax: priceBreakupValues?.tax?.amount || 0,
                shipping_and_cod_charges:
                    (priceBreakupValues?.shipping?.rtoCharges || 0) +
                    (priceBreakupValues?.shipping?.codCharges || 0) +
                    (priceBreakupValues?.shipping?.standardPrice || 0) +
                    (priceBreakupValues?.shipping?.weightBasedPrice || 0),
                gift_card_and_rewards:
                    (priceBreakupValues?.giftCards?.totalAmount || 0) + (priceBreakupValues?.rewards?.totalAmount || 0),
            })
            setOrderFailureReason(
                findOrderFailureReason(response?.data?.data?.orders, response?.data?.data?.post_order_process_item),
            )
        } else if (response.error) {
            message.error('Order not found')
            navigate('/checkout/orders')
        }
    }, [id])

    const discountBreakup = useMemo(() => {
        let priceBreakupValues = orderData?.orders?.price_breakup
        return {
            'Abandoned cart recovery Discount': priceBreakupValues?.ABCDiscount,
            'Cash to Prepaid conversion Discount': priceBreakupValues?.C2PDiscount,
            'Payment Discount': priceBreakupValues?.paymentOffer,
            'Loyalty Points Discount': priceBreakupValues?.loyaltyPoints,
            'Prepaid Discount': priceBreakupValues?.prepaidDiscount,
            'Automatic Discounts': priceBreakupValues?.automaticDiscounts?.discounts,
            'Custom Discounts': priceBreakupValues?.customDiscount,
            'Coupon Code Based Discount':
                priceBreakupValues?.shopifyDiscounts?.discounts ||
                (priceBreakupValues?.shopifyDiscount ? [priceBreakupValues?.shopifyDiscount] : null),
            'Post Edit Order Discount': priceBreakupValues?.postEditOrderDiscount,
        }
    }, [orderData])

    const taxBreakup = useMemo(
        () => ({
            tax: orderData?.orders?.price_breakup?.tax?.amount,
            'Tax Type': orderData?.orders?.price_breakup?.tax?.isExclusive
                ? 'Exclusive'
                : orderData?.orders?.price_breakup?.tax?.isExclusive === false
                ? 'Inclusive'
                : '',
        }),
        [orderData],
    )
    const shippingBreakup = useMemo(
        () => ({
            'RTO Charge': orderData?.orders?.price_breakup?.shipping?.rtoCharges,
            'COD Charges': orderData?.orders?.price_breakup?.shipping?.codCharges,
            'Standard Price': orderData?.orders?.price_breakup?.shipping?.standardPrice,
            'Weight Based Price': orderData?.orders?.price_breakup?.shipping?.weightBasedPrice,
        }),
        [orderData],
    )
    const giftBreakup = useMemo(
        () => ({
            Gifts: orderData?.orders?.price_breakup?.giftCards?.cards,
            Rewards: orderData?.orders?.price_breakup?.rewards?.items,
        }),
        [orderData],
    )

    const keyInformation = useMemo(
        () => [
            [
                { value: orderData?.orders?.order_status, label: 'Order Status' },
                { value: orderData?.orders?.m_order_status, label: 'Platform Order Status' },
                {
                    value: orderData?.orders?.moid,
                    label: merchantPlatform !== 'shopify' ? 'Platform Order ID' : 'Shopify Order ID',
                },
                ...(isMerchantUser && merchantPlatform === 'shopify'
                    ? [{ value: orderData?.orders?.additional?.shopify_order_name, label: 'Shopify Order Number' }]
                    : []),

                // { value: `${orderData?.orders?.payment_status}`, label: 'Payment Status' },
                { label: 'Coupon Code', value: orderData?.orders.coupon_code },
                ...(!isMerchantUser
                    ? [{ value: orderData?.orders?.payment_status?.toString(), label: 'Payment Status' }]
                    : []),
                { value: orderData?.orders?.store_source, label: 'Store Source', noCapitalize: true },
                { value: orderData?.orders?.c2p_converted?.toString(), label: 'C2P Order' },
                { value: orderData?.orders?.is_onpl_order?.toString(), label: 'ONPL Order' },
                // { value: orderData?.orders?.store_platform, label: 'Store Platform', noCapitalize: true },
            ],
            [
                { value: orderData?.orders?.mkt_source, label: 'UTM Source' },
                { value: orderData?.orders?.mkt_medium, label: 'UTM Medium' },
                { value: orderData?.orders?.mkt_content, label: 'UTM Content' },
                { value: orderData?.orders?.mkt_campaign, label: 'UTM Campaign' },
                { value: orderData?.orders?.mkt_term, label: 'UTM Term' },
                { value: `${orderData?.orders?.landing_page || ''}`, label: 'Landing Page' },
                { value: orderData?.orders?.orig_referrer, label: 'Orig Referrer', noCapitalize: true },
                ...(!isMerchantUser ? [{ value: orderData?.orders?.is_deleted?.toString(), label: 'Is Deleted' }] : []),
            ],
            [
                {
                    label: 'Events Log',
                    value: isMerchantUser ? (
                        <Tooltip title={orderData?.orders?.request_id}>{orderData?.orders?.request_id}</Tooltip>
                    ) : (
                        <a className='text-primary'>
                            <Link
                                to={`/checkout/order-analytics?request_id=${orderData?.orders?.request_id}&created_at=${orderData?.orders?.created_at}&updated_at=${orderData?.orders?.updated_at}`}
                                state={{
                                    path: `/checkout/orders/${id}`,
                                    pageName: 'Orders',
                                    order_id: id,
                                }}
                            >
                                <Tooltip title={orderData?.orders?.request_id}>{orderData?.orders?.request_id}</Tooltip>
                            </Link>
                        </a>
                    ),
                },
                {
                    value: (orderData?.orders?.ordertype || orderData?.orders?.order_type)?.toUpperCase(),
                    label: 'Payment Type',
                },
                { value: formatAmount(orderData?.orders?.grand_total, 2), label: 'Payable Amount', suffix: '' },
                // { value: orderData?.orders?.discount_amount, label: 'Total Discount', suffix: '₹ ' },
                // { value: orderData?.cart?.[0]?.shipping?.price, label: 'Shipping Charges', suffix: '₹ ' },
                // { value: '', label: 'Refund Status' },
                {
                    value: <DiscountBreakupsData value={orderData?.total_discount} data={discountBreakup} />,
                    label: 'Total Discount',
                    realValue: '₹' + orderData?.total_discount,
                },
                {
                    value: <TaxBreakupData value={orderData?.tax} data={taxBreakup} />,
                    label: 'Tax',
                    realValue: '₹' + orderData?.tax,
                },

                {
                    value: <ShippingBreakupData value={orderData?.shipping_and_cod_charges} data={shippingBreakup} />,
                    label: 'Shipping And COD Charges',
                    realValue: '₹' + orderData?.shipping_and_cod_charges,
                },

                {
                    value: <GiftBreakupData value={orderData?.gift_card_and_rewards} data={giftBreakup} />,
                    label: 'Gift Card And Rewards',
                    realValue: '₹' + orderData?.gift_card_and_rewards,
                },
            ],
        ],
        [orderData, merchantPlatform, isMerchantUser],
    )

    const customerInformation = useMemo(
        () => [
            ...(!isMerchantUser
                ? [{ icon: SolutionOutlined, value: orderData?.orders?.customer_id, label: 'ID' }]
                : []),
            {
                icon: UserOutlined,
                value: orderData?.orders?.billing_first_name + ' ' + orderData?.orders?.billing_last_name,
                label: 'Name',
            },
            { icon: PhoneOutlined, value: orderData?.orders?.phone, label: 'Phone' },
            { icon: MailOutlined, value: orderData?.orders?.email, label: 'Email Address' },
            ...(!isMerchantUser
                ? [
                      { icon: LinkOutlined, value: orderData?.orders?.ip, label: 'IP Address' },
                      { icon: LaptopOutlined, value: orderData?.orders?.user_agent, label: 'User Agent' },
                  ]
                : []),
            {
                icon: PastPrepaidCustomerIcon,
                value:
                    orderData?.orders?.past_prepaid_customer === undefined
                        ? '-'
                        : orderData?.orders?.past_prepaid_customer
                        ? 'Yes'
                        : 'No',
                label: 'Past Prepaid in Gokwik',
            },
        ],
        [orderData],
    )

    const billingInformation = useMemo(
        () => ({
            name: orderData?.orders?.billing_first_name + ' ' + orderData?.orders?.billing_last_name,
            phone: orderData?.orders?.billing_phone,
            address: `${orderData?.orders?.billing_address1} ${orderData?.orders?.billing_address2}, ${orderData?.orders?.billing_city}`,
            state: `${orderData?.orders?.billing_state}${
                orderData?.orders?.billing_pincode ? `- ${orderData?.orders?.billing_pincode || ''}` : ''
            }`,
        }),
        [orderData],
    )

    const shippingInformation = useMemo(
        () => ({
            name: (orderData?.orders?.shipping_first_name || '') + ' ' + (orderData?.orders?.shipping_last_name || ''),
            phone: orderData?.orders?.shipping_phone,
            address: `${orderData?.orders?.shipping_address1 || ''} ${orderData?.orders?.shipping_address2 || ''}${
                orderData?.orders?.shipping_city ? `, ${orderData?.orders?.shipping_city}` : ''
            }`,
            state: `${orderData?.orders?.shipping_state || ''}${
                orderData?.orders?.shipping_pincode ? ` - ${orderData?.orders?.shipping_pincode}` : ''
            }`,
        }),
        [orderData],
    )

    const rtoData = useMemo(
        () => [
            [
                { label: 'Merchant Name', value: '--' },
                {
                    label: 'Events Log',
                    value: isMerchantUser ? (
                        <Tooltip title={orderData?.orders?.request_id}>{orderData?.orders?.request_id}</Tooltip>
                    ) : (
                        <a className='text-primary'>
                            <Link
                                to={`/checkout/order-analytics?request_id=${orderData?.orders?.request_id}&created_at=${orderData?.orders?.created_at}&updated_at=${orderData?.orders?.updated_at}`}
                                state={{
                                    path: `/checkout/orders/${id}`,
                                    pageName: 'Orders',
                                    order_id: id,
                                }}
                            >
                                <Tooltip title={orderData?.orders?.request_id}>{orderData?.orders?.request_id}</Tooltip>
                            </Link>
                        </a>
                    ),
                },
                { label: 'Payload', value: <a>View payload</a> },
                { label: 'RTO Score', value: +orderData?.orders?.rto_score },
            ],
            [
                { label: 'RTO Reason', value: orderData?.orders?.rto_reason },
                { label: 'RTO Risk Flag', value: orderData?.orders?.rto_risk_flag },
            ],
        ],
        [orderData],
    )

    const refundTableColumns: any = useMemo(
        () => [
            ...(isMerchantUser
                ? refundColumns.filter((column) =>
                      ['Refund Request Description', 'Refund Request Doc'].every((key) => key !== column?.title),
                  )
                : refundColumns),
            ...(validatePermission(PermissionValues?.orders?.initiateRefund)
                ? [
                      {
                          title: 'Actions',
                          width: 90,
                          fixed: 'right',
                          render: (id, record: Refund) => {
                              return (
                                  <Row justify={'center'}>
                                      <Tooltip
                                          title={
                                              <p>
                                                  Refund already{' '}
                                                  {record?.bank_status.includes('initiated')
                                                      ? 'initiated'
                                                      : 'successful'}
                                              </p>
                                          }
                                      >
                                          <RedoOutlined
                                              disabled={record?.bank_status !== 'payment_failed'}
                                              className={
                                                  record?.bank_status === 'payment_failed'
                                                      ? 'text-primary-450'
                                                      : 'text-gray-500 !cursor-not-allowed'
                                              }
                                              onClick={() => {
                                                  // setModalOpen(record)
                                              }}
                                              // style={{ color: 'green' }}
                                          />
                                      </Tooltip>
                                  </Row>
                              )
                          },
                      },
                  ]
                : []),
        ],
        [isMerchantUser],
    )

    const tabs = useMemo(
        () => [
            {
                key: 'payment',
                label: 'Payment',
                children: (
                    <Col className='rounded w-full overflow-x-auto' sm={24}>
                        <Table
                            scroll={{ x: 1000, ...(orderData?.transactions?.length > 3 && { y: 130 }) }}
                            columns={paymentColumns}
                            pagination={false}
                            dataSource={orderData?.transactions}
                        />
                    </Col>
                ),
            },
            {
                key: 'refund',
                label: 'Refund',
                children: (
                    <Col className='rounded w-full overflow-x-auto' sm={24}>
                        <Table
                            scroll={{ x: 1000, ...(orderData?.refund?.length > 3 && { y: 130 }) }}
                            columns={refundTableColumns}
                            pagination={false}
                            dataSource={orderData?.refund}
                        />
                    </Col>
                ),
            },
            {
                key: 'giftcard',
                label: 'Gift Card',
                children: (
                    <Col className='rounded w-full overflow-x-auto' sm={24}>
                        <Table columns={giftCards} pagination={false} dataSource={orderData?.orders?.gift_card} />
                    </Col>
                ),
            },
            ...(!isMerchantUser || showRTOScoreToMerchant
                ? [
                      {
                          key: 'RTOSore',
                          label: 'RTO Score',
                          children: (
                              <Col sm={24}>
                                  <Row gutter={16} justify={'space-between'}>
                                      {rtoData.map((itm, i) => (
                                          <React.Fragment key={JSON.stringify(itm)}>
                                              <Col className='flex flex-col gap-y-2' span={i === 0 ? 12 : 11}>
                                                  {itm.map((key) => (
                                                      <p
                                                          title={`${key.value}`}
                                                          className='inter flex text-sm w-full '
                                                          key={key.label}
                                                      >
                                                          <span className='text-gray-600 whitespace-nowrap'>
                                                              {key.label}:
                                                          </span>
                                                          <span
                                                              className={`${
                                                                  typeof key.value === 'string'
                                                                      ? statusMappingForColors[key.value] === 1
                                                                          ? 'text-success-600'
                                                                          : statusMappingForColors[key.value] === 2
                                                                          ? 'text-warning-500'
                                                                          : statusMappingForColors[key.value] === 3
                                                                          ? 'text-error-700'
                                                                          : 'text-black'
                                                                      : ''
                                                              } font-medium flex-grow ml-2 truncate`}
                                                          >
                                                              {key.value}
                                                          </span>
                                                      </p>
                                                  ))}
                                              </Col>
                                              {i !== rtoData.length - 1 && (
                                                  <Col span={1}>
                                                      <Divider type='vertical' className='h-full' />
                                                  </Col>
                                              )}
                                          </React.Fragment>
                                      ))}
                                  </Row>
                              </Col>
                          ),
                      },
                  ]
                : []),
            {
                key: 'storeCredit',
                label: 'Store Credits',
                children: (
                    <Col className='rounded w-full overflow-x-auto' sm={24}>
                        <Table
                            columns={storeCredits}
                            pagination={false}
                            dataSource={orderData?.orders?.price_breakup?.store_credits?.items}
                        />
                    </Col>
                ),
            },
        ],
        [orderData],
    )

    const isCustomerPrepaid = useCallback(async () => {
        const response = await makeAPICall({
            method: 'get',
            url: `${process.env.RTO_DASHBOARD_SERVICE_URL}${APIEndPoints.getCustomerPrepaidHistory}`,
            skipLoader: true,
            headers: {
                Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
            },
            params: {
                order_number: id,
            },
        })
        if (response.success) {
            setOrderData((prevOrderData) => ({
                ...prevOrderData,
                orders: {
                    ...prevOrderData?.orders,
                    past_prepaid_customer: response?.data?.is_prepaid,
                },
            }))
        } else {
            console.error('Failed to fetch prepaid customer status:', response.error)
        }
    }, [id])

    useEffect(() => {
        fetchOrderDetails()
        isCustomerPrepaid()
    }, [id])

    useEffect(() => {
        updateBreadcrumbs((prev) =>
            prev[prev?.length - 1]?.text === String(id)
                ? prev
                : [
                      prev[0],

                      {
                          key: 'orders',
                          href: '/checkout/orders',
                          text: 'Orders',
                      },
                      {
                          key: 'order-details',
                          href: `/checkout/orders/${id}`,
                          text: String(id),
                      },
                  ],
        )
    }, [])

    const getRefundBtnTooltipMessage = (refundBtnState) => {
        const all_refunds_successful = orderData?.refund?.every((entry) => entry.bank_status === 'payment_successful')
        const any_payment_successful = orderData?.transactions?.some(
            (entry) => entry.bank_status === 'payment_successful',
        )
        const any_refund_initiated = orderData?.refund?.some(
            (entry) => entry.bank_status === 'payment_initiated' || entry.bank_status === 'pending',
        )
        const any_payment_failed_or_initiated_or_timeout = orderData?.transactions?.some(
            (entry) =>
                entry.bank_status === 'payment_initiated' ||
                entry.bank_status === 'pending' ||
                entry.bank_status === 'payment_failed' ||
                entry.bank_status === 'timeout',
        )
        if (all_refunds_successful && any_payment_successful && refundBtnState?.refundableAmount === 0) {
            return 'Order already refunded'
        } else if (any_payment_successful && any_refund_initiated) {
            return 'Refund initiated: Under processing'
        } else if (any_payment_failed_or_initiated_or_timeout && orderData?.refund?.length === 0) {
            return 'Refund cannot be initated now because payment has either initiated or failed or timed-out'
        } else if (orderData?.transactions?.length === 0 && orderData?.refund?.length === 0) {
            return 'No payment found, hence cannot initiate refund'
        }
    }

    const isFakeOrder = RTO_FAKE_ORDER_REASONS.includes(orderData?.orders?.rto_reason)

    return (
        <>
            <RefundDrawer
                onClose={(refetch: boolean) => {
                    setOpenRefundDrawer(false)
                    refetch && fetchOrderDetails()
                }}
                openRefundDrawer={openRefundDrawer}
                orderId={orderData?.orders?.id ? orderData?.orders?.id : orderData?.orders?.order_number}
                merchant_id={orderData?.orders?.merchant_id}
                fullRefundAmount={refundBtnState.refundableAmount}
            />
            <div className='order-details-page w-full  flex flex-col gap-y-4 inter'>
                <Row
                    className={`p-2 rounded ${isFakeOrder ? 'bg-[rgba(255,251,250,1)]' : 'bg-neutrals-15'}`}
                    align={'middle'}
                    justify={'space-between'}
                >
                    <Col sm={12} className='flex items-center gap-x-2'>
                        <span
                            className={`text-base inter font-semibold m-0 tracking-wide ${
                                isFakeOrder ? 'text-error-500' : 'text-black'
                            } inline-flex items-center`}
                        >
                            {isFakeOrder && (
                                <div className='p-1 m-2 bg-red-50 rounded inline-flex items-center justify-center'>
                                    <WarningFilled className='text-error-500 text-sm' />
                                </div>
                            )}
                            {id}
                        </span>
                        <Tag
                            variant={statusMappingForTag[orderData?.orders?.order_status]}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            {orderData?.orders?.order_status}
                        </Tag>
                        {isFakeOrder && (
                            <Tag
                                variant={'error'}
                                className='!px-3 !py-1 -ml-1 rounded-2xl inter font-medium inline-block capitalize text-xs'
                            >
                                Fake Order
                            </Tag>
                        )}
                        {orderData?.orders?.order_status === 'cancelled' && (
                            <Popover
                                placement='bottom'
                                title='Why cancelled?'
                                content={<Tag variant='error'>{order_failure_reason}</Tag>}
                            >
                                <span className='text-xs text-blue-500'>why?</span>
                            </Popover>
                        )}
                    </Col>

                    {validatePermission(PermissionValues.orders.initiateRefund) && (
                        <Col className='flex justify-end gap-x-2' sm={2}>
                            <Tooltip title={refundBtnState.disabled && getRefundBtnTooltipMessage(refundBtnState)}>
                                <Button
                                    disabled={refundBtnState.disabled}
                                    variant='primary'
                                    onClick={() => setOpenRefundDrawer(true)}
                                >
                                    Initiate Refund
                                </Button>
                            </Tooltip>

                            {merchantPlatform === 'shopify' &&
                                merchantConfig?.enable_edit_order &&
                                validatePermission(PermissionValues?.orders.initiateRefund) &&
                                orderData?.orders.order_edit_status === 0 &&
                                (orderData?.orders.order_name || orderData?.orders.order_status === 'confirmed') &&
                                orderData?.orders.fulfilled === 'unfulfilled' && (
                                    <Tooltip title={'Edit Order'}>
                                        <Button
                                            onClick={() =>
                                                navigateToUrl(`/checkout/orders/edit/${orderData.orders.order_number}`)
                                            }
                                            variant='primary'
                                        >
                                            Edit
                                        </Button>
                                    </Tooltip>
                                )}

                            {isFakeOrder && (
                                <Button
                                    type='text'
                                    className='mr-2 !px-1 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs !outline-none'
                                    onClick={() => setShowFakeOrderReasons(!showFakeOrderReasons)}
                                >
                                    {showFakeOrderReasons ? (
                                        <div className='flex items-center'>
                                            <span className='flex flex-col items-center justify-center gap-0'>
                                                <DownOutlined className='text-[10px]' />
                                                <UpOutlined className='text-[10px]' />
                                            </span>
                                        </div>
                                    ) : (
                                        <div className='flex items-center'>
                                            <span className='flex flex-col items-center justify-center gap-0'>
                                                <UpOutlined className='text-[10px]' />
                                                <DownOutlined className='text-[10px]' />
                                            </span>
                                        </div>
                                    )}
                                </Button>
                            )}
                        </Col>
                    )}

                    {showFakeOrderReasons && isFakeOrder && (
                        <Row className='m-2 p-2 rounded bg-white w-full border border-solid border-gray-100'>
                            <Col sm={24} className='w-full p-2'>
                                <span className='font-semibold text-black block'>Reasons for fake order</span>
                                <div className='bg-white text-black rounded flex gap-x-4 mt-2 -ml-1 items-stretch'>
                                    <div className='flex flex-col justify-between basis-[60%] flex-shrink-0 flex-grow-0'>
                                        <div>
                                            <ul className='list-disc pl-5 my-4'>
                                                <li>{orderData?.orders?.rto_reason || 'No reason provided'}</li>
                                            </ul>
                                            <p className='flex items-center text-blue-500'>
                                                <InfoCircleFilled className='mr-2 ml-1' />
                                                Identified through Gokwik's Risk Intelligence model, which has an
                                                accuracy rate of ~85%.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='flex flex-col flex-1 justify-between pr-3 basis-[40%] flex-shrink-0 flex-grow-0'>
                                        <div className='bg-green-500 text-white font-semibold p-2 rounded-t-lg basis-[20%] flex-shrink-1 flex-grow-0'>
                                            <p className='flex items-center'>
                                                <CheckCircleFilled className='mr-2' />
                                                Recommendation against fake order
                                            </p>
                                        </div>
                                        <div className='bg-green-100 text-black text-xs p-2 rounded-b-lg flex-1'>
                                            <p>
                                                1. In the RTO actions, select "RTO Risk" under the conditions. <br />
                                                2. Choose the relevant risk reasons displayed, and select either "COD
                                                Blocking" or "Partial COD" as the action. Then, create the workflow.{' '}
                                                <br />
                                                3. Make sure this workflow is positioned first in the sequence of all
                                                workflows.
                                            </p>
                                            <div className='h-4'></div>
                                            <p
                                                onClick={() => navigateToUrl('/rto/actions/create-workflow')}
                                                className='text-blue-800 font-semibold cursor-pointer hover:underline'
                                            >
                                                <img
                                                    src={external_link}
                                                    alt='External Link'
                                                    className='text-blue-800'
                                                />
                                                Create Work Flow
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )}
                </Row>
                <Row gutter={16}>
                    <Col className='flex flex-col gap-y-4' sm={18}>
                        <Row
                            className='p-2 rounded bg-neutrals-15'
                            gutter={[0, 12]}
                            align={'middle'}
                            justify={'space-between'}
                        >
                            <Col sm={24}>
                                <span className='text-black text-base inter font-semibold m-0'>Details</span>
                            </Col>
                            <Col sm={24}>
                                <Row gutter={16} justify={'space-between'}>
                                    {keyInformation.map((itm, i) => (
                                        <React.Fragment key={JSON.stringify(itm)}>
                                            <Col className='flex flex-col gap-y-2' span={i === 0 ? 8 : 7}>
                                                {itm.map((key) => (
                                                    <p
                                                        title={
                                                            typeof key.value !== 'object'
                                                                ? `${key.value}`
                                                                : `${key.realValue}`
                                                        }
                                                        className='inter flex text-sm w-full '
                                                        key={key.label}
                                                    >
                                                        <span className='text-gray-600 whitespace-nowrap'>
                                                            {key.label}:
                                                        </span>
                                                        <span
                                                            className={`${
                                                                statusMappingForColors[key.value] === 1
                                                                    ? 'text-success-600'
                                                                    : statusMappingForColors[key.value] === 2
                                                                    ? 'text-warning-500'
                                                                    : statusMappingForColors[key.value] === 3
                                                                    ? 'text-error-700'
                                                                    : 'text-black'
                                                            } ${
                                                                !key.noCapitalize && 'capitalize'
                                                            } font-medium flex-grow ml-2 truncate`}
                                                        >
                                                            <Tooltip title={key.value || ''}>
                                                                {typeof key.value === 'object'
                                                                    ? key.value
                                                                    : key.value
                                                                    ? `${key.suffix || ''}${key.value}`
                                                                    : '-'}
                                                            </Tooltip>
                                                        </span>
                                                    </p>
                                                ))}
                                            </Col>
                                            {i !== keyInformation.length - 1 && (
                                                <Col span={1}>
                                                    <Divider type='vertical' className='h-full' />
                                                </Col>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                        <Row
                            className='p-2 rounded bg-neutrals-15'
                            gutter={[0, 12]}
                            align={'middle'}
                            justify={'space-between'}
                        >
                            <Col sm={24}>
                                <span className='text-black text-base inter font-semibold m-0'>Items</span>
                            </Col>
                            <Col className='rounded w-full overflow-x-auto' sm={24}>
                                <Table
                                    scroll={{ x: 1000, ...(orderData?.refund?.length > 3 && { y: 130 }) }}
                                    columns={lineItemColumns}
                                    pagination={false}
                                    dataSource={orderData?.Line_items}
                                />
                            </Col>
                        </Row>
                        <Row className='p-2 rounded bg-neutrals-15' align={'middle'} justify={'space-between'}>
                            <Col sm={24}>
                                <Tabs className='inter' items={tabs} />
                            </Col>
                        </Row>
                    </Col>
                    <Col className='flex flex-col gap-y-4' sm={6}>
                        <Row
                            gutter={[0, 16]}
                            className='p-2 w-full rounded bg-neutrals-15'
                            align={'middle'}
                            justify={'space-between'}
                        >
                            <Col span={24}>
                                <span className='text-black text-base inter font-semibold m-0'>Customer Details</span>
                            </Col>

                            <Col className='flex flex-col gap-y-2' span={24}>
                                {customerInformation.map((itm) => (
                                    <div
                                        className='flex items-center justify-between w-full text-sm inter font-normal'
                                        key={itm.label}
                                    >
                                        <div className='text-gray-600 flex'>
                                            <itm.icon className='mr-2' width={20} height={20} />
                                            <span>{itm.label}</span>
                                        </div>
                                        <Tooltip placement='topLeft' title={itm.value}>
                                            {/* {itm?.value === orderData?.orders?.customer_id ? (
                                                <Link
                                                    to={`/general/customers/${orderData?.orders?.customer_id}`}
                                                    onClick={() =>
                                                        updateBreadcrumbs((prev) =>
                                                            prev?.find((itm) => itm?.key === 'customer-details')
                                                                ? [prev[0]]
                                                                : [
                                                                      ...prev,

                                                                      {
                                                                          key: 'customer-details',
                                                                          href: `/general/customers/${itm?.value}`,
                                                                          text: String(itm?.value),
                                                                      },
                                                                  ],
                                                        )
                                                    }
                                                >
                                                    {itm?.value}
                                                </Link>
                                            ) : ( */}
                                            <span className='text-black font-medium max-w-[50%] truncate'>
                                                {itm.value}
                                            </span>
                                        </Tooltip>{' '}
                                    </div>
                                ))}
                            </Col>
                        </Row>
                        <Row
                            gutter={[0, 16]}
                            className='p-2 w-full rounded bg-neutrals-15'
                            align={'middle'}
                            justify={'space-between'}
                        >
                            <Col span={24}>
                                <span className='text-black text-base inter font-semibold m-0'>Billing Details</span>
                            </Col>

                            <Col className='flex flex-col gap-y-2 text-sm text-gray-600' span={24}>
                                <span className='text-black font-semibold'>{billingInformation.name}</span>
                                <span>{billingInformation.phone}</span>
                                <span>{billingInformation.address}</span>
                                <span>{billingInformation.state}</span>
                            </Col>
                        </Row>
                        <Row
                            gutter={[0, 16]}
                            className='p-2 w-full rounded bg-neutrals-15'
                            align={'middle'}
                            justify={'space-between'}
                        >
                            <Col span={24}>
                                <span className='text-black text-base inter font-semibold m-0'>Shipping Details</span>
                                {orderData?.address?.deliver_order_count > 0 && (
                                    <Tooltip title={`Past Deliveries = ${orderData?.address?.deliver_order_count}`}>
                                        <InfoCircleOutlined className='ml-2 text-base' />
                                    </Tooltip>
                                )}
                            </Col>

                            <Col className='flex flex-col gap-y-2 text-sm text-gray-600' span={24}>
                                <span className='text-black font-semibold'>
                                    {shippingInformation.name.trim()
                                        ? shippingInformation.name || '-'
                                        : billingInformation.name}
                                </span>
                                <span>{shippingInformation.phone || billingInformation.phone}</span>
                                <span>
                                    {/[a-zA-Z0-9]/.test(shippingInformation.address.trim())
                                        ? shippingInformation.address
                                        : billingInformation.address}
                                </span>
                                <span>
                                    {/[a-zA-Z0-9]/.test(shippingInformation.state.trim())
                                        ? shippingInformation.state
                                        : billingInformation.state}
                                </span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default OrderDetails
