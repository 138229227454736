import { formatAmount } from '@library/utilities/helpers/helper'
import {
    Button,
    Card,
    Col,
    DeleteOutlined,
    Divider,
    Drawer,
    Dropdown,
    EditOutlined,
    Form,
    Input,
    message,
    Modal,
    QuestionCircleOutlined,
    Radio,
    Row,
    Select,
    Table,
    Tooltip,
    WarningOutlined,
} from 'gokwik-ui-kit'
import React, { useEffect, useState } from 'react'
import threeDotsIcon from '@library/images/three-dots.svg'
import { ShippingRangeChecker } from './shippingBasedError'
import RenderField from '@library/utilities/helpers/renderFormItem'
import { validateWeightRangeDifference } from '@library/utilities/helpers/validations'

const WeightBaseShippingMethods = ({ ...props }) => {
    const [form] = Form.useForm()
    const [open, setOpen] = useState(false)
    const { shippingConfig, weightConfigs, saveConfig, config } = props
    const [isEditEnabled, setEditEnabled] = useState(false)
    const [weightConfig, setWeightConfig] = useState({
        weight_based_shipping_rates: [],
        custom_config: {
            wbs_application_level: config?.custom_config?.wbs_application_level,
        },
    })
    const [prevFormState, setPrevFormState] = useState(null)
    const [button, setButtonVisibilty] = useState(false)
    const [saveButtonVisibility, setSaveButtonVisibility] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)

    useEffect(() => {
        setWeightConfig((prev) => ({
            ...prev,
            weight_based_shipping_rates: weightConfigs,
        }))
    }, [weightConfigs])

    const fields: any = [
        {
            name: ['weight_based_shipping_rates', 'shipping_name'],
            label: 'Shipping Name',
            prefix: '',
            type: 'input',
            inputProps: {
                type: 'text',
                placeholder: 'Enter',
            },
            colon: false,
            labelCol: isEditEnabled ? { span: 24 } : '',
            wrapperCol: isEditEnabled ? { span: 24 } : '',
            required: true,
            rules: [
                {
                    required: true,
                    message: 'Field cannot be empty',
                },
                {
                    pattern: /^[a-zA-Z0-9 ]*$/,
                    message: 'Special characters are not allowed!',
                },
            ],
            span: 4,
        },
        {
            name: ['weight_based_shipping_rates', 'shipping_uuid'],
            label: (
                <>
                    Shipping ID &nbsp;
                    <Tooltip title='Shipping ID maps weights to shipping already defined for greater control.'>
                        <QuestionCircleOutlined />
                    </Tooltip>
                </>
            ),
            type: 'select',
            required: true,
            colon: false,
            labelCol: isEditEnabled ? { span: 24 } : '',
            wrapperCol: isEditEnabled ? { span: 24 } : '',
            rules: [
                {
                    required: true,
                    message: 'ID is not selected !!',
                },
            ],
            inputProps: {
                className: 'w-full',
                showSearch: true,
                options:
                    shippingConfig && shippingConfig.length > 0
                        ? [
                            { label: 'All', value: 'all' },
                            ...shippingConfig.map((item) => ({
                                label: item?.presentment_name,
                                value: item?.shipping_uuid,
                            })),
                        ]
                        : [],
                optionRender: (option) => (
                    <span key={option?.key} className='flex flex-row justify-between'>
                        <span className='capitalize'>{option?.label}</span>
                        <span>{option?.key?.toString() === 'all' ? '' : option?.key?.toString()}</span>
                    </span>
                ),
            },
            span: 4,
        },
        {
            name: ['weight_based_shipping_rates', 'min_weight'],
            label: 'Min Weight',
            placeholder: '',
            type: 'input',
            colon: false,
            labelCol: isEditEnabled ? { span: 24 } : '',
            wrapperCol: isEditEnabled ? { span: 24 } : '',
            rules: [
                {
                    required: true,
                    message: 'Field cannot be empty',
                },
                {
                    pattern: /^[0-9]*\.?[0-9]*$/,
                    message: 'Please enter a valid positive number',
                },
            ],
            inputProps: {
                type: 'number',
                suffix: 'KG',
            },
            span: 3,
        },
        {
            name: ['weight_based_shipping_rates', 'max_weight'],
            label: 'Max Weight',
            placeholder: '',
            type: 'input',
            colon: false,
            labelCol: isEditEnabled ? { span: 24 } : '',
            wrapperCol: isEditEnabled ? { span: 24 } : '',
            inputProps: {
                type: 'number',
                suffix: 'KG',
            },
            rules: [
                {
                    required: true,
                    message: 'Field cannot be empty',
                },
                {
                    pattern: /^[0-9]*\.?[0-9]*$/,
                    message: 'Please enter a valid positive number!',
                },
            ],
            span: 3,
        },
        {
            name: ['weight_based_shipping_rates', 'price'],
            label: 'Shipping Price',
            placeholder: '0',
            prefix: '',
            type: 'input',
            required: true,
            colon: false,
            labelCol: isEditEnabled ? { span: 24 } : {},
            wrapperCol: isEditEnabled ? { span: 24 } : {},
            inputProps: {
                type: 'number',
                prefix: '₹',
            },
            rules: [
                {
                    required: true,
                    message: 'Field cannot be empty',
                },
                {
                    pattern: /^[0-9]*\.?[0-9]*$/,
                    message: 'Please enter a valid positive number!',
                },
                {
                    validator: (_, value) => {
                        if (parseFloat(value) === 0) return Promise.reject(new Error('Price cannot be 0.'))
                        if (isEditEnabled) setButtonVisibilty(true)
                        return Promise.resolve()
                    },
                },
            ],
            span: 3,
        },
        {
            type: 'button',
            inputProps: {
                className: 'border-gray-500 mt-7',
                onClick: () => {
                    form.submit()
                },
                type: 'default',
                children: 'Add Shipping',
            },
            span: 3,
        },
        {
            type: 'button',
            inputProps: {
                className: 'mt-7',
                type: 'primary',
                disabled: !saveButtonVisibility,
                children: 'Save',
                onClick: () => {
                    saveConfig({
                        custom_config: {
                            ...config?.custom_config,
                            ...weightConfig?.custom_config,
                        },
                        weight_based_shipping_rates: weightConfig?.weight_based_shipping_rates,
                    })
                    setSaveButtonVisibility(false)
                },
            },
            span: 2,
        },
    ]

    const tableHeader: any = [
        {
            title: 'Shipping ID',
            dataIndex: 'shipping_uuid',
            key: 'shipping_uuid',
        },
        {
            title: 'Weight Based Shipping Name',
            dataIndex: 'shipping_name',
            key: 'shipping_name',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (t, r) => {
                return formatAmount(t, 2)
            },
        },
        {
            title: 'Min Weight (KG)',
            dataIndex: 'min_weight',
            key: 'min_weight',
            render: (t, r) => {
                return t + ' KG'
            },
        },
        {
            title: 'Max Weight (KG)',
            dataIndex: 'max_weight',
            key: 'max_weight',
            render: (t, r) => {
                return t + ' KG'
            },
        },
        {
            title: '',
            dataIndex: '',
            key: 'action',
            render: (text, record) => {
                return (
                    <Dropdown
                        placement='bottomLeft'
                        className='p-0'
                        menu={{
                            items: [
                                {
                                    key: '1',
                                    label: (
                                        <Button
                                            type='link'
                                            onClick={() => {
                                                setOpen(true)
                                                form.setFieldsValue({
                                                    weight_based_shipping_rates: record,
                                                })
                                                setPrevFormState(record)
                                                setEditEnabled(true)
                                            }}
                                            className='w-full p-0 flex gap-x-1 items-center hover:text-primary-500'
                                        >
                                            <EditOutlined className='p-0' /> Edit
                                        </Button>
                                    ),
                                },
                                {
                                    key: '2',
                                    label: (
                                        <Button
                                            danger
                                            type='link'
                                            className='p-0 flex gap-x-1 items-center'
                                            onClick={() => {
                                                const filteredArrayOfShipping =
                                                    weightConfig?.weight_based_shipping_rates?.filter(
                                                        (item) =>
                                                            item?.shipping_name?.toLowerCase().trim() !==
                                                            record?.shipping_name?.toLowerCase().trim(),
                                                    )
                                                setSaveButtonVisibility(true)
                                                setWeightConfig((prev) => ({
                                                    ...prev,
                                                    weight_based_shipping_rates: filteredArrayOfShipping,
                                                }))
                                            }}
                                        >
                                            <DeleteOutlined className='p-0' /> Delete
                                        </Button>
                                    ),
                                },
                            ],
                        }}
                    >
                        <img src={threeDotsIcon} alt='three dots' />
                    </Dropdown>
                )
            },
        },
    ]

    const getValesFromForm = () => {
        const formData = form.getFieldsValue(true)
        let error = false
        let requestObject =
            weightConfig?.weight_based_shipping_rates && weightConfig?.weight_based_shipping_rates?.length > 0
                ? [...weightConfig?.weight_based_shipping_rates]
                : []
        const price = parseFloat(formData['weight_based_shipping_rates']['price'])
        const min_weight = parseFloat(formData['weight_based_shipping_rates']['min_weight'])
        const max_weight = parseFloat(formData['weight_based_shipping_rates']['max_weight'])
        const previousMin = parseFloat(prevFormState?.min_weight)
        const previousMax = parseFloat(prevFormState?.max_weight)
        const shipping_uuid = formData['weight_based_shipping_rates']['shipping_uuid']
        const shipping_name = formData['weight_based_shipping_rates']['shipping_name']
        const prev_shipping_name = prevFormState?.shipping_name?.toLowerCase().trim()
        const applicationLevel = form.getFieldValue(['custom_config', 'wbs_application_level'])


        const updatedConfig = {
            ...formData['weight_based_shipping_rates'],
            max_weight,
            min_weight,
            price,
        }

        if (!isEditEnabled) {
            const minValidate = validateWeightRangeDifference(weightConfig, min_weight, shipping_uuid)
            const maxValidate = validateWeightRangeDifference(weightConfig, max_weight, shipping_uuid)
            const isNamePresent = weightConfig?.weight_based_shipping_rates?.some(
                (item) => item?.shipping_name?.toLowerCase().trim() === shipping_name?.toLowerCase().trim(),
            )
            if (isNamePresent) {
                message.error('Shipping name must be unique')
                error = true
            }

            if (maxValidate?.status || minValidate?.status) {
                message.error(maxValidate?.msg || minValidate.msg)
                error = true
            }
            requestObject.push(updatedConfig)
        }

        if (isEditEnabled) {
            let filteredConfig = {};
            filteredConfig['weight_based_shipping_rates'] = weightConfig?.weight_based_shipping_rates?.filter((item) => item?.shipping_name !== shipping_name);
            const minValidate = validateWeightRangeDifference(filteredConfig, min_weight, shipping_uuid)
            const maxValidate = validateWeightRangeDifference(filteredConfig, max_weight, shipping_uuid)

            const isNamePresent = weightConfig?.weight_based_shipping_rates?.some(
                (item) =>
                    item?.shipping_name?.toLowerCase().trim() === shipping_name?.toLowerCase().trim() &&
                    item?.shipping_name?.toLowerCase().trim() !== prev_shipping_name,
            )

            if (isNamePresent) {
                message.error('Shipping name must be unique')
                error = true
            }

            if (
                (prev_shipping_name !== shipping_name && (previousMin !== min_weight || previousMax !== max_weight)) ||
                (prev_shipping_name === shipping_name && (previousMin !== min_weight || previousMax !== max_weight))
            ) {
                if (maxValidate?.status || minValidate?.status) {
                    message.error(maxValidate?.msg || minValidate?.msg)
                    error = true
                }
            }

            const existingIndex = weightConfig?.weight_based_shipping_rates?.findIndex(
                (config) => config?.shipping_name.toLowerCase().trim() === prev_shipping_name,
            )
            if (existingIndex !== -1) {
                requestObject[existingIndex] = updatedConfig
            }
        }

        if (error) return
        setWeightConfig((prev) => ({
            ...prev,
            weight_based_shipping_rates: requestObject,
            custom_config: {
                ...prev.custom_config,
                wbs_application_level: applicationLevel,
            },
        }))

        setOpen(false)
        setEditEnabled(false)
        setSaveButtonVisibility(true)
        form.resetFields()
    }

    return (
        <Card size='small'>
            <Row>
                <Col span={24} className='mb-2'>
                    {weightConfig && weightConfig?.weight_based_shipping_rates.length > 0 && (
                        <ShippingRangeChecker
                            shippingConfig={weightConfig?.weight_based_shipping_rates}
                            type={['min_weight', 'max_weight']}
                            prefix={'KG'}
                        />
                    )}
                </Col>
            </Row>
            <Form
                className='w-full'
                form={form}
                layout='vertical'
                requiredMark='optional'
                onFinish={getValesFromForm}
                onValuesChange={() => {
                    if (isEditEnabled) {
                        setButtonVisibilty(true)
                    }
                }}
                initialValues={{
                    custom_config: {
                        wbs_application_level: weightConfig?.custom_config?.wbs_application_level,
                    },
                    weight_based_shipping_rates: {
                        shipping_uuid: '',
                        shipping_name: '',
                        price: '',
                        min_weight: '',
                        max_weight: '',
                    },
                }}
            >
                <Row gutter={[8, 16]} align='middle' justify='space-between'>
                    {fields &&
                        fields?.map((item, index) => (
                            <Col span={item?.span} key={index}>
                                <RenderField form={form} field={item} />
                            </Col>
                        ))}
                </Row>
                <Row>
                    <Drawer
                        open={open}
                        width={'35rem'}
                        onClose={() => {
                            setOpen(false)
                            setEditEnabled(false)
                            form.resetFields()
                        }}
                        bodyStyle={{ paddingTop: '5px' }}
                        title='Edit Shipping'
                        footer={
                            <span className='flex flex-row float-end gap-2'>
                                <Button
                                    children={'Discard'}
                                    onClick={() => {
                                        form.resetFields()
                                        setOpen(false)
                                    }}
                                />
                                <Button
                                    variant='primary'
                                    children='Save Changes'
                                    disabled={!button}
                                    onClick={() => {
                                        form.submit()
                                    }}
                                />
                            </span>
                        }
                        className='overflow-y-auto p-0 m-0'
                    >
                        <Row gutter={[16, 8]}>
                            {fields?.map((item, index) => (
                                <Col span={12} key={index}>
                                    {item?.type !== 'button' && <RenderField form={form} field={item} />}
                                </Col>
                            ))}
                        </Row>
                    </Drawer>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table
                            className='capitalize mt-5'
                            columns={tableHeader}
                            pagination={{ pageSize: 8, position: ['bottomRight'] }}
                            dataSource={weightConfig?.weight_based_shipping_rates}
                        />
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label='Weight based shipping application level?'
                            name={['custom_config', 'wbs_application_level']}
                            required={true}
                        >
                            <Col span={4}>
                                <Select
                                    options={[
                                        { value: 'cart_level', label: 'Cart Level' },
                                        { value: 'item_level', label: 'Item Level' },
                                    ]}
                                    onChange={(value) => {
                                        setIsModalVisible(true)
                                        form.setFieldValue(['custom_config', 'wbs_application_level'], value)
                                    }}
                                    value={weightConfig?.custom_config?.wbs_application_level}
                                />
                            </Col>
                        </Form.Item>
                        <Modal
                            title={
                                <div className='flex justify-center items-center text-lg'>
                                    <WarningOutlined className='text-orange-500 mr-2' />
                                    <p className='text-orange-500'>Warning</p>
                                </div>
                            }
                            visible={isModalVisible}
                            centered
                            width={'400px'}
                            closeIcon={false}
                            footer={
                                <div className='flex flex-row justify-center space-x-4'>
                                    <Button
                                        onClick={() => {
                                            setIsModalVisible(false)
                                            setWeightConfig((prev) => ({
                                                ...prev,
                                                custom_config: {
                                                    ...prev.custom_config,
                                                    wbs_application_level: weightConfig?.custom_config?.wbs_application_level,
                                                },
                                            }))
                                        }}
                                        children={'No'}
                                    />
                                    <Button
                                        type='primary'
                                        onClick={() => {
                                            setWeightConfig((prev) => ({
                                                ...prev,
                                                custom_config: {
                                                    ...prev.custom_config,
                                                    wbs_application_level: form.getFieldValue([
                                                        'custom_config',
                                                        'wbs_application_level',
                                                    ]),
                                                },
                                            }))
                                            setSaveButtonVisibility(true)
                                            setIsModalVisible(false)
                                        }}
                                        children={'Yes'}
                                    />
                                </div>
                            }
                        >
                            <p className='flex justify-center text-center items-center text-sm'>
                                Selected application level will be applicable to all weight based shipping methods. Do you want to continue?
                            </p>
                        </Modal>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}

export default WeightBaseShippingMethods
