import { updateBreadcrumbs } from '@gokwik/utilities'
import CustomiseTab from '@pages/settings/customize-ui/customize-sections/customizetab'
import { useEffect } from 'react'

const CustomizeUI = () => {
    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                text: 'Checkout Settings',
                key: 'settings',
                href: '/checkout/settings',
            },
            {
                key: 'customise-ui',
                text: 'Customise UI',
                href: '/checkout/settings/customise-ui',
            },
        ])
    }, [])
    return <CustomiseTab />
}

export default CustomizeUI
