import { useState, ReactNode, useMemo, useRef, useEffect } from 'react'
import { ArrowLeftOutlined, Button, ExportOutlined, FormOutlined, Layout, Menu, Select } from 'gokwik-ui-kit'
import { useNavigate, Routes, Route, useLocation } from 'react-router-dom'
import Pincode from '@pages/settings/pincode-settings'
import Shipping from '@pages/settings/shipping-settings'
import CheckoutConfigs from './checkout-configs'
import { useSelector } from 'react-redux'
import {
    getMerchantDetails,
    getUserConfig,
    getUserDetails,
    isMerchantSelector,
    getUserData,
} from '@store/user/selectors'
import OffersDiscount from './offers-discount'
import { DiscountConfigKeys } from '@library/utilities/constants/constants'
import { makeAPICall, titleCase } from '@gokwik/utilities'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'
import BulkDiscounts from './bulk-discounts'
import Integrations from './integration-settings'
import Discounts from './discounts'
import CreateManualDiscounts from './discounts/create-discounts/manual'
import CreateBulkDiscounts from './discounts/create-discounts/bulk'
import CreateAutomaticDiscounts from './discounts/create-discounts/automatic'
import CustomiseUI from './customize-ui'
import TaxAndOthers from './tax-settings'
import PaymentSettings from './payment'
import GokwikAgreement from './agreement-page/agreementPage'
import GreenTick from '@library/images/green-tick.svg'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { formatDateString } from '@library/utilities/helpers/helper'

const { Sider } = Layout

interface MenuItem {
    key: string
    label: ReactNode
    icon?: ReactNode
    children?: MenuItem[]
    type?: 'group'
    className?: string
}

function createMenuItem(
    label: ReactNode,
    key: string,
    className?: string,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        children,
        label,
        type,
        className,
    }
}
export default function () {
    const merchant_details = useSelector(getMerchantDetails)
    const config = useSelector(getUserConfig)
    const route = useLocation()
    const navigate = useNavigate()
    const previousOpenedItems = useRef({
        openedKey: '',
        selectedKey: '',
    })
    const isMerchantUser = useSelector(isMerchantSelector)
    const user_details = useSelector(getUserDetails)
    const userData = useSelector(getUserData)
    const [showAgreementPage, setShowAgreementPage] = useState(false)
    const [eSignData, setEsignData] = useState(null)
    const [isAgreementReady, setIsAgreementReady] = useState(false)
    const [checkoutPlatform, setCheckoutPlatform] = useState('checkout_1')
    const showDiscountConfigs = useMemo(() => Object.values(DiscountConfigKeys).some((key) => config[key]), [config])
    const checkoutPlatformKeys = useMemo(
        () =>
            Object.entries(config?.supported_checkout_configs || {}).reduce(
                (result: string[], item: [string, boolean]) =>
                    item[1] && item[0] !== 'custom' ? [...result, item[0]] : result,
                [],
            ),
        [config],
    )
    const pollingRef = useRef(null)

    const startPolling = () => {
        if (!pollingRef.current) {
            pollingRef.current = setInterval(fetchLatestAgreementStatusWithPolling, 3000)
        }
    }

    const stopPolling = () => {
        if (pollingRef.current) {
            clearInterval(pollingRef.current)
            pollingRef.current = null
        }
    }

    useEffect(() => {
        stopPolling()

        return () => stopPolling()
    }, [showAgreementPage])

    const fetchLatestAgreementStatusWithPolling = async () => {
        await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.userDetails,
            method: 'get',
            skipLoader: true,
        })
            .then((result) => {
                setIsAgreementReady(result?.data?.data?.kyc_details?.show_agreement_banner)

                if (!result?.data?.data?.kyc_details?.show_agreement_banner) {
                    setShowAgreementPage(false)
                }
            })
            .catch((err) => {
                console.warn(err)
            })
    }
    const settingsList = useMemo(
        () => [
            {
                title: 'PIN Codes Settings',
                path: '/pincode',
                component: () => <Pincode checkoutPlatform={checkoutPlatform} />,
            },
            {
                title: 'Shipping',
                path: '/shipping',
                component: Shipping,
            },
            {
                title: 'Customise UI',
                path: '/customise-ui',
                component: CustomiseUI,
            },
            ...((!isMerchantUser || showDiscountConfigs) && !config?.diy_enabled
                ? [
                      {
                          title: 'Discount Configs',
                          path: '/discount-configs',
                          component: CheckoutConfigs,
                      },
                  ]
                : []),
            ...(config?.diy_enabled
                ? [
                      {
                          title: 'Discounts',
                          path: '/discounts',
                          component: Discounts,
                      },
                      {
                          title: 'Manual Discounts',
                          path: '/discounts/create/manual',
                          component: CreateManualDiscounts,
                          hideInMenu: true,
                      },
                      {
                          title: 'Edit Manual Discounts',
                          path: '/discounts/edit/manual/:id',
                          component: CreateManualDiscounts,
                          hideInMenu: true,
                      },
                      {
                          title: 'Bulk Discounts',
                          path: '/discounts/create/bulk',
                          component: CreateBulkDiscounts,
                          hideInMenu: true,
                      },
                      {
                          title: 'Bulk Discounts',
                          path: '/discounts/edit/bulk/:id',
                          component: CreateBulkDiscounts,
                          hideInMenu: true,
                      },
                      {
                          title: 'Automatic Discounts',
                          path: '/discounts/create/automatic',
                          component: CreateAutomaticDiscounts,
                          hideInMenu: true,
                      },
                      {
                          title: 'Automatic Discounts',
                          path: '/discounts/edit/automatic/:id',
                          component: CreateAutomaticDiscounts,
                          hideInMenu: true,
                      },
                      {
                          title: 'Payments',
                          path: '/payments',
                          component: PaymentSettings,
                      },
                  ]
                : [
                      {
                          title: 'Bulk Discounts Setup',
                          path: '/bulk-discounts',
                          component: BulkDiscounts,
                      },
                  ]),
            ...(!config.diy_enabled
                ? [{ title: 'Offers Visibility Setup', path: '/offers-discounts', component: OffersDiscount }]
                : []),
            {
                title: 'Integrations',
                path: '/integrations',
                component: Integrations,
            },
            {
                title: 'Tax & Others',
                path: '/tax-and-others',
                component: TaxAndOthers,
            },
        ],
        [checkoutPlatform, showDiscountConfigs, config],
    )
    const menuItems = () => {
        const items = settingsList.reduce(
            (result, item) =>
                item.hideInMenu ? result : [...result, createMenuItem(item.title, `/checkout/settings${item.path}`)],
            [],
        )
        return items
    }

    useEffect(() => {
        setIsAgreementReady(userData?.kycData?.show_agreement_banner)

        if (userData?.kycData?.show_agreement_banner) {
            setLegalityData()
        }
    }, [])

    const setLegalityData = async () => {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.legality,
            })
            if (response?.data?.status_code === 200) {
                setEsignData(response?.data?.data)
            }
        } catch (error) {
            console.log('Error while fetching eSign Data', error)
        }
    }

    const openItems = useMemo(() => {
        const menu = menuItems()

        const openedKey = menu.find((item) => {
            if (item.key === route.pathname) {
                return true
            }
            if (item.children) {
                return item.children.some((child) => child.key === route.pathname)
            }
            return false
        })

        if (openedKey) {
            previousOpenedItems.current = {
                openedKey: openedKey.key,
                selectedKey: route.pathname,
            }
            return {
                openedKey: openedKey.key,
                selectedKey: route.pathname,
            }
        } else if (previousOpenedItems.current.openedKey) {
            return {
                ...previousOpenedItems.current,
            }
        } else {
            let similarSelectedKey: MenuItem | undefined
            const similarKey = menu.find(
                (item) =>
                    !!item.children?.find((child) => {
                        if (route.pathname.includes(child.key)) {
                            similarSelectedKey = child
                            return true
                        } else return false
                    }),
            )

            previousOpenedItems.current = {
                openedKey: similarKey?.key || '',
                selectedKey: similarSelectedKey?.key || '',
            }
            return {
                openedKey: similarKey?.key || '',
                selectedKey: similarSelectedKey?.key || '',
            }
        }
    }, [route.pathname, settingsList, checkoutPlatform, showDiscountConfigs])

    const eventMappings: Record<string, string> = {
        '/checkout/settings/pincode': 'pincode_serviceability_clicked',
        '/checkout/settings/shipping': 'shipping_serviceability_clicked',
    }

    const handleMenuSelection = ({ key }: any) => {
        const eventName = eventMappings[key]

        if (key === '/checkout/settings/discount-configs' || key === '/checkout/settings/bulk-discounts') {
            logEvent(
                eventName,
                'click',
                'pincode service',
                user_details.email,
                merchant_details.m_id,
                merchant_details.short_name,
                user_details.name,
            )
        }
        navigate(key)
    }

    useEffect(() => {
        if (route.pathname === '/checkout/settings') navigate('/checkout/settings' + settingsList[0].path)
    }, [route.pathname])

    return (
        <>
            {showAgreementPage ? (
                <div className='flex flex-col w-full'>
                    <div className='flex flex-row gap-2'>
                        <div
                            className='flex justify-center align-middle items-center border-2 border-solid border-gray-300  max-h-max p-4 rounded-md bg-gray-100 cursor-pointer'
                            onClick={() => {
                                setShowAgreementPage(false)
                            }}
                        >
                            <ArrowLeftOutlined />
                        </div>
                        <div className='flex flex-col'>
                            <p className='inter text-xl font-semibold'>GoKwik Agreement</p>
                            <p className='inter text-sm font-normal text-gray-400'>
                                Sign your merchant agreement with GoKwik
                            </p>
                        </div>
                    </div>
                    <div className='flex w-[800px] justify-center items-center mt-8 align-middle m-auto'>
                        <GokwikAgreement
                            eSignData={eSignData}
                            kycData={userData?.kycData}
                            startPolling={startPolling}
                        />
                    </div>
                </div>
            ) : (
                <div className='w-full'>
                    {isAgreementReady && (
                        <div className='flex gap-3 p-4 bg-[#F6FFED] mb-4 rounded-md px-2'>
                            <div className='flex justify-start items-start'>
                                <img src={GreenTick} width={48} />
                            </div>
                            <div className='flex justify-between items-center w-full'>
                                <div className='flex flex-col'>
                                    <span className='text-base font-semibold mr-2 text-[#135200]'>
                                        Hey {merchant_details.short_name}, your agreement is now ready for signing
                                    </span>
                                    <span className='text-[#0A5B39] text-xs font-medium'>
                                        Please review and sign the agreement by{' '}
                                        <span className='underline'>{formatDateString(eSignData?.expiry_date)}</span>
                                    </span>
                                </div>

                                <div className='flex gap-4 items-center text-sm inter font-normal cursor-pointer'>
                                    <Button
                                        onClick={() => {
                                            setShowAgreementPage(true)
                                        }}
                                        variant='primary'
                                    >
                                        Sign Agreement <FormOutlined />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}

                    <Layout className='h-full'>
                        <Sider
                            className='bg-white h-full mr-4 border-0 border-gray-100 border-r-2 fixed overflow-hidden'
                            style={{ background: 'white' }}
                        >
                            {' '}
                            {checkoutPlatformKeys.length > 1 && (
                                <Select
                                    className='w-full mb-4'
                                    value={checkoutPlatform}
                                    onChange={(value) => {
                                        localStorage.setItem('checkoutPlatform', value)
                                        setCheckoutPlatform(value)
                                        if (value === 'kwik_checkout' || value === 'cod_lite') navigate(`/shopify-app/settings`)
                                    }}
                                    options={checkoutPlatformKeys.map((item: string) => ({
                                        label: titleCase(item.split('_').join(' ')),
                                        value: item,
                                    }))}
                                />
                            )}
                            <div className='setting-menu-custom-css'>
                                <Menu
                                    onClick={handleMenuSelection}
                                    defaultOpenKeys={[openItems.openedKey]}
                                    defaultSelectedKeys={[openItems.selectedKey]}
                                    key={`${route.pathname}-${openItems.openedKey}-${openItems.selectedKey}`}
                                    items={menuItems()}
                                    inlineCollapsed={true}
                                    mode='inline'
                                />
                            </div>
                        </Sider>
                        <Layout className='overflow-y-auto overflow-x-hidden'>
                            <Routes>
                                {settingsList.map((item, index) => (
                                    <Route key={index} path={item.path} element={<item.component />} />
                                ))}
                            </Routes>
                        </Layout>
                    </Layout>
                </div>
            )}
        </>
    )
}
