import React, { FC, useEffect, useMemo, useState } from 'react'
import { CardLayout } from './cardLayout'
import {
    Modal,
    Select,
    Input,
    ColumnProps,
    DeleteOutlined,
    EditOutlined,
    Row,
    Tooltip,
    Button,
    Table,
    Col,
    FolderFilled,
    Form,
    message,
    CloseOutlined,
    FileImageOutlined,
    Tag,
    InputNumber,
    Divider,
} from 'gokwik-ui-kit'
import { ProductsOrCollectionsSearchModal } from './products&CollectionsSearchModal'

interface ProductQuantityFunctionProps {
    value?: Rule[]
    onChange?: (rules: Rule[]) => void
    setFormState?: any
    finalFormState?: any
    discountAction?: any
}

interface Condition {
    discount_type: string
    min_product_quantity: number
    product_attribute: string
    applicable_list: string[]
    product_name_mapping?: any
}

interface Action {
    discount_type: string
    applicability_type: string
    discount_method: string
    value: number
    max_discount?: number | string
}

interface Rule {
    priority?: number
    conditions: Condition[]
    actions: Action[]
    rule_id?: string
}

interface ModalState {
    isOpen: boolean
    formState?: Rule
    isEdit?: boolean
}

const Card: FC<ProductQuantityFunctionProps> = (props) => {
    const { setFormState, finalFormState, discountAction } = props
    const [modalState, setModalState] = useState<ModalState>({
        isOpen: false,
    })
    const [showProductSelectionModal, setShowProductSelectionModal] = useState(false)
    const [category, setCategory] = useState('specific_products') //to be changed later
    const [searchValue, setSearchValue] = useState('')
    const [modalProps, setModalProps] = useState({
        title: 'Choose from products',
        searchPlaceholder: 'Search products',
    })
    const [selectedProducts, setSelectedProducts] = useState(finalFormState?.rules[0]?.conditions[0]?.selected_products || [])
    const [selectedProductVariants, setSelectedProductVariants] = useState(finalFormState?.rules[0]?.conditions[0]?.selected_product_variants || [])
    const [renderProductMapping, setRenderProductMapping] = useState(finalFormState?.rules[0]?.conditions[0]?.product_name_mapping || []);
    const [showCheckedListTags, setShowCheckedListTags] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const columns: ColumnProps<any>[] = [
        {
            title: <span>Fixed or %</span>,
            dataIndex: ['actions', 0, 'discount_method'],
            width: 25,
            render: (text: string, record) => {
                return <>{text === 'flat' ? 'Fixed' : '%'}</>
            },
        },

        {
            title: <span>Quantity</span>,
            dataIndex: ['conditions', 0, 'min_product_quantity'],
            width: 15,
            render: (text: string) => <>{text}</>,
        },
        {
            title: <span>Discount Value</span>,
            dataIndex: ['actions', 0, 'value'],
            width: 15,
            render: (text: string) => <>{text}</>,
        },
        // {
        //     title: <span>Max Discount Value</span>,
        //     dataIndex: ['actions', 0, 'max_discount'],
        //     width: 30,
        //     render: (text: string, record) => <>{record?.actions[0]?.discount_method === 'flat' ? 'NA' : text}</>,
        // },
        {
            title: <span>Actions</span>,
            dataIndex: 'actions',
            align: 'center',
            fixed: 'right',
            width: 20,
            render: (_, record, index) => (
                <Row className='flex gap-3 m-0' justify={'center'}>
                    {discountAction !== 'view' && (
                        <>
                            <Tooltip className=' cursor-pointer' placement='topLeft' title={'Edit'}>
                                <EditOutlined
                                    onClick={() => {
                                        handleModalState({ isOpen: true, isEdit: true, formState: { ...record } })
                                    }}
                                />
                            </Tooltip>

                            <Tooltip className='cursor-pointer' placement='topLeft' title={finalFormState?.rules?.length === 1 ? 'Atleast one condition is required' : 'Delete'}>
                                <DeleteOutlined
                                    className={finalFormState?.rules?.length === 1 ? 'cursor-not-allowed text-gray-400': 'cursor-pointer'}
                                    onClick={() => {
                                        if (finalFormState?.rules?.length > 1) {
                                            const updatedData = [...props.value]
                                            updatedData.splice(index, 1)
                                            props?.onChange([...updatedData])
                                        }
                                    }}
                                />
                            </Tooltip>
                        </>
                    )}
                </Row>
            ),
        },
    ]

    const handleModalState = (state?: ModalState) => {
        setModalState({ ...state })
    }

    const onValuesChange = (formState: Rule) => {
        setErrorMessage('')
        if (formState?.actions[0]?.discount_method === 'flat') {
            formState.actions[0].max_discount = null
        }
        setModalState({ ...modalState, formState: formState })
    }

    const getInputConfigs = useMemo(() => {
        const isFixedDiscount = Boolean(
            finalFormState?.rules?.some((r) => r?.actions?.some((a) => a?.discount_method === 'flat')),
        )

        const isPercentageDiscount = Boolean(
            finalFormState?.rules?.some((r) => r?.actions?.some((a) => a?.discount_method === 'percentage')),
        )

        if (isFixedDiscount) {
            return {
                options: [{ label: 'Fixed', value: 'flat' }],
                defaultValue: 'flat',
                isDisabled: true,
            }
        } else if (isPercentageDiscount) {
            return {
                options: [{ label: 'Percentage', value: 'percentage' }],
                defaultValue: 'percentage',
                isDisabled: true,
            }
        } else {
            return {
                options: [
                    { label: 'Fixed', value: 'flat' },
                    { label: 'Percentage', value: 'percentage' },
                ],
                defaultValue: 'flat',
                isDisabled: false,
            }
        }
    }, [props, finalFormState])

    const handleInputBrowseInputChange = (e) => {
        if (!props?.value?.length) {
            message.error('Add conditions first, then select products')
            return
        }
        if (!category && e.target.value) {
            message.error('Please select a category of items from which to browse')
            return
        }
        if (category && e.target.value) {
            setSearchValue(e.target.value)
            setShowProductSelectionModal(true)
        }
    }

    const handleBrowseProduct = () => {
        if (!props?.value?.length) {
            message.error('Add conditions first, then select products')
            return
        }
        if (!category) return message.error('Please select a category of items from which to browse')
        setShowProductSelectionModal(true)
    }

    const removeProductFromList = (product_id) => {
        setSelectedProducts((prevProducts) => prevProducts?.filter((product) => product?.product_id !== product_id))
        setSelectedProductVariants((prevProducts) => prevProducts?.filter((item) => item?.product_id !== product_id))
        setRenderProductMapping((prevProducts) => prevProducts?.filter((product) => product?.product_id !== product_id))
        setFormState((prev) => {
            const temp = JSON.parse(JSON.stringify(prev))
            temp.rules[0].conditions[0].product_name_mapping = temp?.rules[0]?.conditions[0]?.product_name_mapping?.filter(
                (item) => item?.product_id !== product_id,
            )
            temp.rules[0].conditions[0].applicable_list = temp?.rules[0]?.conditions[0]?.applicable_list?.filter(
                (item) => item !== product_id,
            )
            temp.rules[0].conditions[0].product_variant_applicable_list = temp?.rules[0]?.conditions[0]?.product_variant_applicable_list?.filter(variant => {
                return temp?.rules[0]?.conditions[0]?.product_name_mapping?.some(product =>
                    product?.variant_ids?.some(selectedVariant => selectedVariant?.variant_id === variant)
                );
            });
            temp.rules[0].conditions[0].selected_products = temp?.rules[0]?.conditions[0]?.selected_products?.filter(
                (item) => item?.product_id !== product_id,
            )
            temp.rules[0].conditions[0].selected_product_variants = temp?.rules[0]?.conditions[0]?.selected_product_variants?.filter(
                (item) => item?.product_id !== product_id,
            )
            return temp
        })
    }

    const handleCategoryChange = (value) => {
        setCategory(value)
    }

    // if(formState?.rules) {

    // }

    useEffect(() => {
        if (
            finalFormState?.rules &&
            finalFormState?.rules?.length &&
            finalFormState?.rules[0]?.conditions[0]?.product_name_mapping?.length &&
            discountAction
        ) {
            setRenderProductMapping(finalFormState?.rules[0]?.conditions[0]?.product_name_mapping)
            setShowCheckedListTags(true)
        }
    }, [discountAction, finalFormState?.rules[0]?.conditions[0]?.product_name_mapping])

    useEffect(() => {
        if (
            finalFormState?.rules &&
            finalFormState?.rules?.length &&
            finalFormState?.rules[0]?.conditions[0]?.selected_products?.length
        ) {
            setSelectedProducts(finalFormState?.rules[0]?.conditions[0]?.selected_products)
            setSelectedProductVariants(finalFormState?.rules[0]?.conditions[0]?.selected_product_variants)
            setRenderProductMapping(finalFormState?.rules[0]?.conditions[0]?.product_name_mapping)
        }
    }, [finalFormState?.rules[0]?.conditions[0]?.selected_product_variants])
    

    // useEffect(() => {
    //     const products = finalFormState?.rules[0]?.conditions[0]?.product_name_mapping || []
    //     if(products.length > 0 ) {
    //         setSelectedProducts(products)
    //         setCategory('specific_products')
    //      }
    // }, [discountAction && finalFormState?.rules[0]?.conditions[0]?.product_name_mapping?.length])

    return (
        <>
            <CardLayout
                title='How should this discount function? Set product quantities and their corresponding discount values.'
                subtitle='Determine Discounts by Product Quantity: Set the specific quantities and corresponding discount values to offer more savings as customers buy more'
                baselineExplainer=''
            >
                {showProductSelectionModal && (
                    <ProductsOrCollectionsSearchModal
                        showProductSelectionModal={showProductSelectionModal}
                        setShowProductSelectionModal={setShowProductSelectionModal}
                        modalProps={modalProps}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        category={category}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        setRenderProductMapping={setRenderProductMapping}
                        selectedProductVariants={selectedProductVariants}
                        setSelectedProductVariants={setSelectedProductVariants}
                        setShowCheckedListTags={setShowCheckedListTags}
                        setFormState={setFormState}
                        formState={finalFormState}
                    />
                )}
                <Modal
                    title={modalState?.isEdit ? 'Edit condition' : 'Add new condition'}
                    width={555}
                    cancelText={'Cancel'}
                    okText={modalState?.isEdit ? 'Edit' : 'Add'}
                    centered
                    open={modalState?.isOpen}
                    onOk={() => {
                        if (modalState?.formState?.actions[0]?.discount_method) {
                            if (modalState?.formState?.actions[0]?.discount_method === 'flat') {
                                if (
                                    !modalState?.formState?.conditions[0]?.min_product_quantity ||
                                    !modalState?.formState?.actions[0]?.value
                                ) {
                                    setErrorMessage('All fields are required')
                                    return
                                }

                                if (modalState?.formState?.conditions[0]?.min_product_quantity < 1) {
                                    setErrorMessage('Quantity cannot be less than 1')
                                    return
                                }

                                if (modalState?.formState?.actions[0]?.value < 1) {
                                    setErrorMessage('Value cannot be less than 1')
                                    return
                                }
                            } else {
                                if (
                                    !modalState?.formState?.conditions[0]?.min_product_quantity ||
                                    !modalState?.formState?.actions[0]?.value
                                ) {
                                    setErrorMessage('All fields are required')
                                    return
                                }

                                if (modalState?.formState?.actions[0]?.value > 100) {
                                    setErrorMessage('Value cannot be greater than 100%')
                                    return
                                }
                            }

                            function checkIfQuantityExists(array, currentQuantityValue, isEdit, ruleId) {
                                for (const obj of array) {
                                    if (!isEdit || ruleId !== obj?.rule_id) {
                                        for (const condition of obj.conditions) {
                                            if (condition?.min_product_quantity === currentQuantityValue) {
                                                return true
                                            }
                                        }
                                    }
                                }
                                return false
                            }

                            if (
                                checkIfQuantityExists(
                                    finalFormState?.rules,
                                    modalState?.formState?.conditions[0]?.min_product_quantity,
                                    modalState?.isEdit,
                                    modalState?.formState?.rule_id,
                                )
                            ) {
                                setErrorMessage('Error: This product quantity is already added')
                                return
                            }

                            if (modalState?.isEdit) {
                                const updatedData = props?.value?.map((v) =>
                                    v?.rule_id === modalState?.formState?.rule_id
                                        ? { ...v, ...modalState?.formState }
                                        : { ...v },
                                )
                                props?.onChange([...updatedData])
                            } else {
                                props?.onChange([...props?.value, modalState?.formState])
                            }
                        } else {
                            setErrorMessage('All fields are required')
                            return
                        }

                        setErrorMessage('')
                        handleModalState({ isOpen: false })
                    }}
                    onCancel={() => {
                        setErrorMessage('')
                        handleModalState({ isOpen: false })
                    }}
                >
                    {errorMessage && (
                        <span className='flex justify-center'>
                            <Tag
                                variant={'error'}
                                className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                            >
                                {errorMessage}
                            </Tag>
                        </span>
                    )}
                    <Select
                        disabled={getInputConfigs?.isDisabled}
                        className='w-full'
                        label='Choose discount validation: Percentage or Fixed?'
                        options={getInputConfigs.options}
                        defaultValue={getInputConfigs.defaultValue}
                        // value={modalState?.formState?.actions[0]?.discount_method ?? undefined}
                        onChange={(value) =>
                            onValuesChange({
                                ...modalState?.formState,
                                actions: [
                                    {
                                        ...modalState?.formState?.actions[0],
                                        discount_method: value,
                                    },
                                ],
                            })
                        }
                    />
                    <InputNumber
                        className='w-full'
                        label='Quantity'
                        placeholder='Enter value'
                        type='number'
                        min={1}
                        value={`${
                            modalState?.formState?.conditions[0]?.min_product_quantity
                                ? modalState?.formState?.conditions[0]?.min_product_quantity
                                : ''
                        }`}
                        onChange={(value) =>
                            onValuesChange({
                                ...modalState?.formState,
                                conditions: [
                                    {
                                        ...modalState?.formState?.conditions[0],
                                        min_product_quantity: Number(value),
                                    },
                                ],
                            })
                        }
                    />
                    <InputNumber
                        className='w-full'
                        label='Discount Value'
                        placeholder='Enter value'
                        type='number'
                        min={1}
                        value={`${
                            modalState?.formState?.actions[0]?.value ? modalState?.formState?.actions[0]?.value : ''
                        }`}
                        onChange={(value) =>
                            onValuesChange({
                                ...modalState?.formState,
                                actions: [
                                    {
                                        ...modalState?.formState?.actions[0],
                                        value: Number(value),
                                    },
                                ],
                            })
                        }
                    />
                    {/* {modalState?.formState?.actions[0]?.discount_method !== 'flat' && (
                        <Input
                            disabled={modalState?.formState?.actions[0]?.discount_method === 'flat' ? true : false}
                            label='Max Discount Value'
                            prefix='₹'
                            placeholder='Enter value'
                            type='number'
                            min={1}
                            value={`${modalState?.formState?.actions[0]?.max_discount}` ?? undefined}
                            onChange={(e) =>
                                onValuesChange({
                                    ...modalState?.formState,
                                    actions: [
                                        {
                                            ...modalState?.formState?.actions[0],
                                            max_discount: Number(e?.target?.value),
                                        },
                                    ],
                                })
                            }
                        />
                    )} */}
                </Modal>
                <br />
                <>
                    <Button
                        variant='default'
                        onClick={() =>
                            handleModalState({
                                isOpen: true,
                                formState: {
                                    priority: props?.value?.length,
                                    conditions: [
                                        {
                                            discount_type: 'CartDiscount',
                                            min_product_quantity: null,
                                            product_attribute: 'ProductId',
                                            applicable_list: [],
                                        },
                                    ],
                                    actions: [
                                        {
                                            discount_type: 'CartDiscount',
                                            applicability_type: 'product_discount',
                                            discount_method: Boolean(
                                                finalFormState?.rules?.some((r) =>
                                                    r?.actions?.some((a) => a?.discount_method === 'percentage'),
                                                ),
                                            )
                                                ? 'percentage'
                                                : 'flat',
                                            value: null,
                                            max_discount: null, //in case of flat then send empty string
                                        },
                                    ],
                                },
                            })
                        }
                    >
                        Add new quantity based conditions
                    </Button>

                    <Table className='mt-4' columns={columns} dataSource={props?.value || []} />
                </>
                <br />
                <>
                    <div className='text-[#000000E0] text-sm font-semibold mb-4'>
                        What products should this discount be applied to?
                    </div>

                    {/* <Select
                        label='Any items from'
                        placeholder=''
                        allowClear
                        onChange={handleCategoryChange}
                        defaultValue={'specific_products'}
                    >
                        <Select.Option value='specific_products'>Specific Products</Select.Option>
                        <Select.Option value='specific_collections'>Specific Collections</Select.Option>
                    </Select> */}

                    <Row gutter={[12, 0]}>
                        <Col span={18}>
                            <Form.Item>
                                <Input
                                    value={searchValue}
                                    allowClear
                                    placeholder={modalProps?.searchPlaceholder}
                                    onChange={handleInputBrowseInputChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item>
                                <Button className='w-full' onClick={handleBrowseProduct}>
                                    <FolderFilled /> Browse
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>

                    {renderProductMapping && renderProductMapping?.length > 0 && (
                        <div className='flex gap-2 flex-wrap'>
                            {renderProductMapping?.map((item, index) => {
                                return (
                                    <div className='border border-solid border-[#00000026] rounded-lg' key={index}>
                                        <div className='flex justify-between gap-2 p-1 min-w-max items-center'>
                                            <div className='flex gap-1 items-center truncate'>
                                                <span className='bg-[#627F99] rounded-md'>
                                                    <FileImageOutlined className='text-sm text-white p-[6px]' />
                                                </span>
                                                <span className='text-sm font-semibold truncate'>
                                                    {item?.product_name}
                                                </span>
                                            </div>

                                            <div
                                                className={
                                                    discountAction === 'view' ? 'pointer-events-none' : 'cursor-pointer'
                                                }
                                                onClick={() => removeProductFromList(item?.product_id)}
                                            >
                                                <CloseOutlined className='text-[#00000073]' />
                                            </div>
                                        </div>
                                        <Divider className='my-1'/>
                                        <div>
                                            {item?.variant_ids?.length > 0 ? (
                                                <ul className='list-none m-0 p-0'>
                                                    {item.variant_ids.map((variant, vIndex) => (
                                                        <li key={vIndex} className='py-1 text-sm text-gray-500 flex items-center ml-1'>
                                                            <span className='bg-gray-200 rounded-full px-2 py-1 text-xs mr-1'>{vIndex + 1}</span>
                                                                {variant?.variant_name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p className='text-sm text-gray-500 mr-1 ml-1'>All variants selected</p>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </>
            </CardLayout>
        </>
    )
}

export const ProductQuantityFunctionCard = React.memo(Card)
