import { Button, Divider, ExportOutlined, Tag } from 'gokwik-ui-kit'
import MainIcon from '@library/images/gokwik-agreement.svg'
import { formatDateString } from '@library/utilities/helpers/helper'

const GokwikAgreement = ({ eSignData, kycData, startPolling }) => {
    const { aus_name, business_address, city, pincode, state, business_category, business_name, aus_email } = kycData

    const tableData = [
        { key: 'Business Name', value: business_name },
        { key: 'Address', value: `${business_address}, ${city}, ${state}, ${pincode}` },
        { key: 'Authorised Signatory Name', value: aus_name },
        { key: 'Business Category', value: business_category },
        { key: 'Email ID', value: aus_email },
    ]

    const signDocument = () => {
        window.open(eSignData?.sign_url, '_blank').focus()
        startPolling()
    }

    return (
        <div className='bg-white border border-solid w-full border-gray-300 rounded-md'>
            <div className='flex gap-3 px-4 pt-4'>
                <div className='flex justify-start items-start'>
                    <img src={MainIcon} width={24} />
                </div>
                <div className='flex justify-between items-center w-full mb-4'>
                    <div className='flex flex-col'>
                        <span className='text-base font-semibold mr-2'>
                            GoKwik Agreement
                            <span className='px-2'>
                                <Tag
                                    variant='warning'
                                    className={`p-0 px-1 rounded-sm inter font-light inline-block capitalize text-xs`}
                                >
                                    Pending
                                </Tag>
                            </span>
                        </span>
                        <span className='text-[#94380C] text-xs font-medium'>
                            Sign before: {formatDateString(eSignData?.expiry_date)}
                        </span>
                    </div>
                </div>
            </div>
            <Divider className='m-0' />
            <div className='px-4 pb-4'>
                <div className='text-sm font-normal inter my-2'>
                    We will be sharing the agreement with the following details.
                </div>
                <div className='overflow-x-auto'>
                    <table className='table-auto border border-collapse border-solid border-gray-300 w-full rounded-lg text-sm font-normal inter'>
                        <tbody>
                            {tableData?.map((item, index) => (
                                <tr key={index} className='border border-solid border-gray-300'>
                                    <td className='px-4 py-2 border border-solid border-gray-300 text-gray-700'>
                                        {item?.key}
                                    </td>
                                    <td className='px-4 py-2 border border-solid border-gray-300 text-gray-700 font-semibold'>
                                        {item?.value}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Divider className='m-0' />
            <div className='flex p-4 justify-end'>
                <div className='flex justify-center items-center'>
                    <Button variant='primary' onClick={() => signDocument()} className='font-semibold cursor-pointer'>
                        Sign Agreement <ExportOutlined />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default GokwikAgreement
