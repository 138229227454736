import React, { FC, useEffect, useState } from 'react'
import { DiscountFormState } from '../helpers/useCreateDiscount'
import { CardLayout } from './cardLayout'
import { Divider, Checkbox, Row, Col, Form, Radio, Space, Input, Button, FolderFilled, message, FileImageOutlined, CloseOutlined, Select } from 'gokwik-ui-kit'
import { CustomerAndSegmentsSearchModal } from './customerAndSegmentsSearchModal'
import { customerCategoryType, customerSegementTypes } from '@library/utilities/types'
import { useLocation } from 'react-router-dom'

interface DiscountUserEligibilityCardProps {
    formState?: DiscountFormState
    setFormState?: any
    discountAction?: any
}

const Card: FC<DiscountUserEligibilityCardProps> = (props) => {
    const { formState, setFormState, discountAction } = props
    const [searchValue, setSearchValue] = useState('')
    const [showCustomerSelectionModal, setShowCustomerSelectionModal] = useState(false)
    const [modalProps, setModalProps] = useState({ title: '', searchPlaceholder: 'Search customers' })
    const [customerSegmentType, setCustomerSegment] = useState(initCustomerSegmentType())
    const [selectedCustomers, setSelectedCustomers] = useState(formState?.eligible_customer.customers.mapping || [])
    const [selectedCustomerSegments, setSelectedCustomerSegments] = useState(formState?.eligible_customer.segments.mapping || [])
    const [category, setCategory] = useState('')
   
    function initCustomerSegmentType () {
        if(formState?.eligible_customer?.segments?.mapping?.length > 0) {
          return customerSegementTypes.CustomerSegment
        } else if(formState?.eligible_customer?.customers?.mapping?.length > 0) {
          return customerSegementTypes.SpecificCustomer
        } else {
            return customerSegementTypes.All
        }
    }

    const removeCheckedSegments = (id) => {
        setSelectedCustomerSegments((prevSegments) => prevSegments.filter((segment) => segment.id !== id))
        setFormState((prev) => {
            const temp = JSON.parse(JSON.stringify(prev))
            temp.eligible_customer.segments.mapping = temp.eligible_customer?.segments?.mapping?.filter(
                item => item?.id !== id
            )
            temp.eligible_customer.segments.add = temp.eligible_customer?.segments?.add?.filter((item) => item !== id)
            return temp
        })
    }
    const removeCheckedCustomers = (id) => {
        setSelectedCustomers((prevCustomers) => prevCustomers.filter((customer) => customer.id !== id))
        setFormState((prev) => {
            const temp = JSON.parse(JSON.stringify(prev))
            temp.eligible_customer.customers.mapping = temp.eligible_customer?.customers?.mapping?.filter(
                item => item?.id !== id
            )
            temp.eligible_customer.customers.add = temp.eligible_customer?.customers?.add?.filter((item) => item !== id)
            return temp
        })
    }

    const handleBrowseProduct = () => {
        if (!customerSegmentType) return message.error('Please select a category of items from which to browse')
        setShowCustomerSelectionModal(true)
    }
    // const handleCategory = (value) => {
    //     setCategory(value)
    // }

    const handleInputBrowseInputChange = (e) => {
        if (!customerSegmentType && e.target.value) {
            message.error('Please select a category of items from which to browse')
            return
        }
         if (customerSegmentType && e.target.value) {
            setSearchValue(e.target.value)
            setShowCustomerSelectionModal(true)
        }
    }
    const handleSegementSelection = (e) => {
        setCustomerSegment(e.target.value)
    }

    const goBack = () => {
        setCustomerSegment(customerSegementTypes.All)
        setCategory('')
    }

    const setModalProperties = () => {
        if (customerSegmentType === customerSegementTypes.CustomerSegment) {
            setModalProps({
                searchPlaceholder: 'Search customer segments',
                title: 'Add customer segments'
            })
        } else if (customerSegmentType === customerSegementTypes.SpecificCustomer) {
            setModalProps({
                searchPlaceholder: 'Search customers',
                title: 'Add customers'
            })
        }
    }


    const updateFormstate = () => {
        if (customerSegmentType === customerSegementTypes.All) {
             setFormState((prev) => {
                const temp = JSON.parse(JSON.stringify(prev))
                if(!temp.eligible_customer.all_customers) {
                    temp.eligible_customer.all_customers = true
                }
                if (temp.eligible_customer?.customers?.add?.length) {
                    temp.eligible_customer.customers.add = []
                }
                if (temp.eligible_customer?.customers.mapping?.length) {
                    temp.eligible_customer.customers.mapping = []
                }
                if (temp.eligible_customer?.segments?.add?.length) {
                    temp.eligible_customer.segments.add = []
                }
                if (temp.eligible_customer?.segments?.mapping?.length) {
                    temp.eligible_customer.segments.mapping = []
                }
                 return temp
            })
        }
        else if (customerSegmentType === customerSegementTypes.CustomerSegment) {
            setFormState((prev) => {
                const temp = JSON.parse(JSON.stringify(prev))
                temp.eligible_customer.segments.mapping = selectedCustomerSegments?.map((item) => ({
                    id: item.id,
                    name: item.name
                }))
                temp.eligible_customer.segments.add = selectedCustomerSegments?.map(item => item.id)
                if (temp.eligible_customer?.customers?.add?.length) {
                    temp.eligible_customer.customers.add = []
                }
                if (temp.eligible_customer?.customers.mapping?.length) {
                    temp.eligible_customer.customers.mapping = []
                }
                if(temp.eligible_customer?.all_customers){
                    temp.eligible_customer.all_customers = false
                }
                 return temp
            })
        } else if (customerSegmentType === customerSegementTypes.SpecificCustomer) {
            setFormState((prev) => {
                const temp = JSON.parse(JSON.stringify(prev))
                temp.eligible_customer.customers.mapping = selectedCustomers?.map((item) => ({
                    id: item.id,
                    name: item.name,
                    email: item.email,
                    phone: item.phone
                }))
                temp.eligible_customer.customers.add = selectedCustomers?.map(item => item.id)
                if (temp.eligible_customer?.segments?.add?.length) {
                    temp.eligible_customer.segments.add = []
                }
                if (temp.eligible_customer?.segments?.mapping?.length) {
                    temp.eligible_customer.segments.mapping = []
                }
                if(temp.eligible_customer?.all_customers){
                    temp.eligible_customer.all_customers = false
                }
                return temp
            })
        }
    }

    useEffect(() => {
        const segmentsMapping = formState?.eligible_customer?.segments?.mapping;
        if (segmentsMapping?.length) {
            setSelectedCustomerSegments(segmentsMapping);
         }
    }, [discountAction && formState?.eligible_customer?.segments?.mapping?.length]);

    useEffect(() => {
        const customers = formState?.eligible_customer?.customers?.mapping;
        if (customers?.length) {
            setSelectedCustomers(customers);
         }
    }, [discountAction && formState?.eligible_customer?.customers?.mapping?.length]);

    useEffect(() => {
        setModalProperties()
        updateFormstate()  
     }, [customerSegmentType])

  
    
    return (
        <>
            {showCustomerSelectionModal && (
                <CustomerAndSegmentsSearchModal
                    showCustomerSelectionModal={showCustomerSelectionModal}
                    modalProps={modalProps}
                    setShowCustomerSelectionModal={setShowCustomerSelectionModal}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    customerSegmentType={customerSegmentType}
                    selectedCustomers={selectedCustomers}
                    setSelectedCustomers={setSelectedCustomers}
                    selectedCustomerSegments={selectedCustomerSegments}
                    setSelectedCustomerSegments={setSelectedCustomerSegments}
                    setFormState={setFormState}
                />
            )}

            <CardLayout
                title='What kind of users should this discount run on? 
            Your customer segments'
            >
                <Form.Item>
                    <Radio.Group defaultValue={customerSegmentType} className='pt-3'>
                        <Space direction='horizontal' onChange={handleSegementSelection}>
                            <Radio value={customerSegementTypes.All}>All</Radio>
                            <Radio value={customerSegementTypes.CustomerSegment}>Specific customer segments</Radio>
                            <Radio value={customerSegementTypes.SpecificCustomer}>Specific customers</Radio>
                        </Space>
                    </Radio.Group>
                    {/* <Row>
                        <Select
                            label='Select customers from'
                            placeholder='Select'
                            // allowClear
                            defaultValue={category}
                            onChange={handleCategory}
                        >
                            <Select.Option value={customerCategoryType.Gokwik}>
                                Gokwik
                            </Select.Option>
                            <Select.Option value={customerCategoryType.Shopify}>Shopify</Select.Option>
                        </Select>
                    </Row> */}

                </Form.Item>

                {customerSegmentType !== customerSegementTypes.All && <div>
                    <Row gutter={[12, 0]}>
                        <Col span={18}>
                            <Form.Item>
                                <Input
                                    value={searchValue}
                                    allowClear
                                    placeholder={modalProps.searchPlaceholder}
                                    onChange={handleInputBrowseInputChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item>
                                <Button className='w-full' onClick={handleBrowseProduct}>
                                    <FolderFilled /> Browse
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>}

                {customerSegmentType === customerSegementTypes.CustomerSegment && selectedCustomerSegments.length > 0 && <div className='flex gap-2 flex-wrap'>
                    {
                        selectedCustomerSegments.map((segment) => {
                            return <div key={segment.id}>
                                <div className='border border-solid border-[#00000026] rounded-lg flex justify-between gap-2 p-1 min-w-max items-center'>
                                    <div className='flex gap-1 items-center truncate'>
                                        <span className='bg-[#627F99] rounded-md'>
                                            <FileImageOutlined className='text-sm text-white p-[6px]' />
                                        </span>
                                        <span className='text-sm font-semibold truncate'>
                                            {segment?.name}
                                        </span>
                                    </div>

                                    <div
                                        className="cursor-pointer"
                                        onClick={() => removeCheckedSegments(segment.id)}
                                    >
                                        <CloseOutlined className='text-[#00000073]' />
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>}

                {customerSegmentType === customerSegementTypes.SpecificCustomer && selectedCustomers.length > 0 && <div className='flex gap-2 flex-wrap'>
                    {
                        selectedCustomers.map((customer) => {
                            return <div key={customer.id}>
                                <div className='border border-solid border-[#00000026] rounded-lg flex justify-between gap-2 p-1 min-w-max items-center'>
                                    <div className='flex gap-1 items-center truncate'>
                                        <span className='bg-[#627F99] rounded-md'>
                                            <FileImageOutlined className='text-sm text-white p-[6px]' />
                                        </span>
                                        <span className='text-sm font-semibold truncate'>
                                            {customer?.name || customer?.phone}
                                        </span>
                                    </div>

                                    <div
                                        className="cursor-pointer"
                                        onClick={() => removeCheckedCustomers(customer.id)}
                                    >
                                        <CloseOutlined className='text-[#00000073]' />
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>}
                {category && <Row className='flex p-1' onClick={goBack}>
                    <Button>
                        Back
                    </Button>
                </Row>}
            </CardLayout>

        </>

    )
}

export const DiscountUserEligibilityCard = React.memo(Card)
