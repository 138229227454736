// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-hover {
    background: none !important;
  }
  
  .custom-hover:hover {
    background: #f5f5f5  !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/library/components/column-preferences/column.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;EAC7B;;EAEA;IACE,+BAA+B;EACjC","sourcesContent":[".custom-hover {\n    background: none !important;\n  }\n  \n  .custom-hover:hover {\n    background: #f5f5f5  !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
