

import { Checkbox, Col, Divider, Row } from 'gokwik-ui-kit'
import { useEffect } from 'react'

type props = {
    list: any
    checkedList: any
    onCustomerCheckChange: any
}

const CustomersList = (props: props) => {
    const { list, checkedList, onCustomerCheckChange } = props

    return (
        <ul className='pl-0 list-none scroll-auto max-h-[calc(100vh-500px)] overflow-y-auto'>
            {list?.map((item) => (
                <div key={item?.id}>
                    <li className='p-2'>
                        <Row align={'middle'}>
                            <Col span={2}>
                                <Checkbox
                                    checked={
                                        !!checkedList?.some((i) => {
                                            return i?.id === item?.id
                                        })
                                    }
                                    onChange={(e) => onCustomerCheckChange(e, item)}
                                >

                                </Checkbox>
                            </Col>
                            <Col>
                            <div className='flex items-center gap-2'>
                                <span className='text-[#000000E0] text-sm font-normal'>{item?.displayName}  { - item?.phone || ''}</span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <span className='text-[#00000080] text-sm font-normal'>{item?.email}</span>
                            </div>
                            </Col>
                        </Row>
                    </li>
                    <Divider className='my-1' />
                </div>
            ))}
        </ul>
    )
}

export { CustomersList }