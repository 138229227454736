import { useEffect, useMemo, useState } from 'react'
import { Button, Col, message, Row, Upload, UploadProps, Form, UploadOutlined, Card, ColorPicker } from 'gokwik-ui-kit'
import ColorPicketComponent from '@library/components/colorpicker'
import noImage from '@library/images/nopic.svg'
import BookDownload from '@library/images/book-download.svg'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { useAppDispatch } from '@library/utilities/hooks'
import { updatedLogoData } from '@store/user'

const BrandForm = ({ onChange }) => {
    const [form] = Form.useForm()
    const merchant_details = useSelector(getMerchantDetails)
    const [isVisible, setIsVisible] = useState<boolean>(true)
    const dispatch = useAppDispatch()
    const [imgUrl, setImgURL] = useState({
        imgUrl: '',
        imgPath: '',
    })
    const { config, loading, refetch, saveConfig } = useMerchantConfigApi({
        useCache: true,
    })
    const [brandData, setBrandData] = useState<any>({
        bgcolor: '',
        logo: '',
    })

    useEffect(() => {
        onChange(brandData)
    }, [brandData])

    const isLogoPresentForCurrentMerchant = async (logoURL: any) => {
        try {
            if (!logoURL) return ''
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.downloadLogo,
                payload: { type: 'logo', path: logoURL },
            })
            return response?.data?.data?.download_url || null
        } catch (e) {
            return ''
        }
    }

    const fetchUploadedLogoURL = async (imgPath: any) => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.presingedLogoUrl,
                payload: {
                    type: 'logo',
                    path: imgPath,
                },
            })
            return response.success ? response.data.data.url : ''
        } catch (e) {
            return ''
        }
    }

    useEffect(() => {
        if (merchant_details) {
            const logoURL = merchant_details.logo || ''
            if (logoURL !== '') {
                isLogoPresentForCurrentMerchant(logoURL)
                    .then((res) => {
                        setBrandData((prevData: any) => ({ ...prevData, logo: res }))
                        setImgURL((prev) => ({ ...prev, imgUrl: res }))
                    })
                    .catch((err) => console.log(err.data))
            }
        }
    }, [])

    useEffect(() => {
        if (config && config?.custom_config) {
            setBrandData((prevData: any) => ({
                ...prevData,
                bgcolor: config?.custom_config?.cssRules?.primaryColor,
            }))
            form.setFieldValue('primary_color', config?.custom_config?.cssRules?.primaryColor)
        }
    }, [config])

    const getBase64 = (img: File, callback: (url: string) => void) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => callback(reader.result as string))
        reader.readAsDataURL(img)
    }

    const validateUpload = (file: File) => {
        const fileTypes = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/svg']
        const isValidType = fileTypes.includes(file.type)
        const isValidSize = file.size / 1024 / 1024 < 2
        if (!isValidType) {
            message.error(`${file.type} is not supported. Please upload a supported file type.`)
        }
        if (!isValidSize) {
            message.error('File size must be less than 2MB')
        }

        return isValidType && isValidSize
    }

    const handleUpload: UploadProps['onChange'] = (info) => {
        if (info.file.status === 'uploading') {
            return
        }

        if (info.file.status === 'done') {
            const file = info.file.originFileObj
            if (file) {
                const timeStamp = Date.now()
                const imgPath = `merchant/${merchant_details.m_id}/logo${timeStamp}/${file.name.split('.').pop()}`
                setImgURL((prev) => ({ ...prev, imgPath: imgPath }))
                fetchUploadedLogoURL(imgPath).then((res) => {
                    if (res) {
                        fetch(res, {
                            method: 'PUT',
                            body: file,
                        })
                    }
                })
            }
            getBase64(file, (url) => {
                setBrandData((prevData: any) => ({
                    ...prevData,
                    logo: url,
                }))
            })
        }
    }

    const uploadProps: UploadProps = {
        maxCount: 1,
        showUploadList: false,
        beforeUpload: validateUpload,
        onChange: handleUpload,
        accept: '.jpg,.jpeg,.png,.svg,image/jpeg,image/png,image/svg+xml,image/svg',
        customRequest: ({ file, onSuccess }) => {
            if (file instanceof File && validateUpload(file)) {
                onSuccess?.(null)
            }
        },
    }

    const onFinish = () => {
        const url = process.env.REACT_APP_BASE_URL + APIEndPoints.updateMerchant(merchant_details.m_id)
        if (imgUrl.imgPath !== '') {
            makeAPICall({ method: 'post', url: url, payload: { logo: imgUrl.imgPath } }).then((res: any) => {
                if (res.status === 200) {
                    isLogoPresentForCurrentMerchant(imgUrl.imgPath).then((res) => {
                        if (res) {
                            dispatch(updatedLogoData(imgUrl.imgPath))
                            setBrandData((prevData: any) => ({
                                ...prevData,
                                logo: res,
                            }))
                        }
                    })
                }
            })
        }
        const formField = form.getFieldsValue()
        saveConfig({
            custom_config: {
                ...config.custom_config,
                cssRules: {
                    primaryColor: formField['primary_color'] || brandData?.bgcolor,
                },
                offers_fixed_positions: false,
            },
        })
        setIsVisible(true)
    }

    return (
        <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            className='gap-5 flex flex-col'
            onValuesChange={(values) => {
                if (values) {
                    setIsVisible(false)
                    setBrandData((prevData: any) => ({
                        ...prevData,
                        ...(values['primary_color'] !== undefined && { bgcolor: values['primary_color'] }),
                    }))
                }
            }}
        >
            <div className='flex justify-between align-center'>
                <div className='m-0 align-center'>
                    <span className='text-lg font-semibold'>Brand</span>
                    <p className='text-gray-500 gap-0'>Control Checkout UI to reflect your brand theme and styling.</p>
                </div>
                <div className='m-0'>
                    <Button type='primary' htmlType='submit' disabled={isVisible}>
                        Save Changes
                    </Button>
                </div>
            </div>

            <Card className='m-0' size='small'>
                <Row gutter={24}>
                    <Col span={16}>
                        <Form.Item
                            className='m-0'
                            name='logo'
                            label={<span className='font-medium text-base'>Logo</span>}
                        >
                            <div>
                                <p className='text-gray-400 text-sm mb-4'>
                                    Upload your brand logo to be visible on GoKwik checkout.{' '}
                                </p>
                                <Upload {...uploadProps}>
                                    <Button icon={<UploadOutlined />} type='primary'>
                                        Upload Logo
                                    </Button>
                                </Upload>
                            </div>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <div className='absolute bottom-0 right-4'>
                            <img
                                src={brandData?.logo || noImage}
                                alt='Upload logo'
                                className='border-solid border-gray-100 rounded-lg w-20 h-20'
                            />
                        </div>
                    </Col>
                </Row>
            </Card>
            <Card size='small'>
                <ColorPicketComponent
                    label={'Theme'}
                    primary={'primary'}
                    defaultValues={{ firstColor: brandData?.bgcolor }}
                    form={form}
                    description='Control checkout theme colour and font colour on main CTAs to match your brand style.'
                />
            </Card>
        </Form>
    )
}

export default BrandForm
