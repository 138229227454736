import { Button, Col, DownloadOutlined, Form, InboxOutlined, Row, Upload, UploadOutlined, message } from 'gokwik-ui-kit'
import DiscountsSample from '@public/assets/gokwik_sample_bulk_discount_codes.zip'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import { useState } from 'react'
import { downloadFromURL } from '@gokwik/utilities'

type Props = {
    uploadDiscountCSV?: (originFileObj: any, callBack: any) => void
    removeDiscountCSV?: () => void
}

export const FileUploader = (props: Props) => {
    const { uploadDiscountCSV } = props
    const merchant_details = useSelector(getMerchantDetails)
    const [fileList, setFileList] = useState([])

    const downloadSample = () => {
        downloadFromURL(DiscountsSample, 'sample_discount.zip')
        message.success('Sample downloaded successfully!')
    }

    return (
        <div>
            <Form.Item
                label='Upload ZIP'
                required
                tooltip={
                    <>
                        <p className=' font-semibold'>Instructions</p>
                        <ul className='text-sm pl-4 mt-2 mb-0'>
                            <li>Upload a Zip of CSV(s) with a single column of discount codes</li>
                            <li>
                                Each code must be between 3 and 20 characters, using only letters and numbers—no special
                                characters
                            </li>
                            <li>Ensure no duplicate codes are present in the file</li>
                            <li>All codes should be uppercase (ALL CAPS)</li>
                        </ul>
                    </>
                }
            >
                <Upload
                    accept='.zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed'
                    name='file'
                    maxCount={1}
                    multiple={false}
                    fileList={fileList}
                    onRemove={() => {
                        props.removeDiscountCSV()
                        setFileList([])
                    }}
                    beforeUpload={async (file, fileList) => {
                        if (file.size / 1024 ** 2 > 200) {
                            message.error('File size should not exceed 200 MB')
                            setFileList([])
                            return false
                        }
                        if (file?.name?.endsWith('.zip') === false) {
                            message.error('Invalid file format. Please upload a ZIP file')
                            setFileList([])
                            return false
                        }

                        try {
                            uploadDiscountCSV(file, () => setFileList([file]))
                            return true
                        } catch (error) {
                            message.error('Failed to compress the file')
                        }
                        setFileList([])

                        return false // Prevent automatic upload
                    }}
                >
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
            </Form.Item>
            <Row
                align={'middle'}
                justify={'space-between'}
                wrap={false}
                className='border-solid my-4 rounded-md border-primary-400 p-2 bg-primary-25'
            >
                <Col flex={'auto'}>
                    <span className='text-xs text-gray-600'>
                        Please upload file in the suggested sample format. Please also ensure that the file contains
                        correct Prefix/Suffix as defined above.
                    </span>
                </Col>
                <Col flex='none'>
                    <Button onClick={downloadSample} type='link' variant='default'>
                        <DownloadOutlined />
                        Download Sample
                    </Button>
                </Col>
            </Row>

            <Row className='text-sm mb-4' justify={'space-between'}>
                <Col>
                    <span>Supported Formats: ZIP</span>
                </Col>
                <Col>
                    <span className='text-[#00000073]'>Maximum file size: 200 MB</span>
                </Col>
            </Row>
        </div>
    )
}
