import { titleCase } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { validateBuyXatYDiscount } from '@library/utilities/helpers/validations'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { BuyXAtYDiscount, BuyXAtYDiscountFlattened } from '@library/utilities/interface'
import {
    Button,
    Col,
    ColumnsType,
    DeleteOutlined,
    Input,
    InputNumber,
    PlusOutlined,
    Row,
    Select,
    Table,
    message,
} from 'gokwik-ui-kit'
import { useCallback, useEffect, useState } from 'react'

const assignMatchType = (key: string | number) => {
    switch (key) {
        case 'starts with':
            return 2
        case 'ends with':
            return 3
        case 'exact match':
            return 1
        case 'contains':
            return 4
        default:
            break
    }
}

const Inputs = [
    {
        label: 'Discount Code*',
        placeholder: 'Enter Shopify discount code',
        key: 'discount_name',
        component: Input,
    },
    {
        label: 'Match Type*',
        placeholder: 'Select option',
        key: 'match_type',
        component: Select,
        options: ['starts with', 'ends with', 'exact match', 'contains'].map((option) => ({
            label: titleCase(option),
            value: assignMatchType(option.toLowerCase()),
        })),
    },
    {
        label: `Product Quantity*`,
        placeholder: 'Enter integer',
        key: 'product_quantity',
        component: InputNumber,
        min: 1,
        max: Number.MAX_SAFE_INTEGER,
    },
    {
        label: 'Capped Value*',
        placeholder: 'Enter integer',
        key: 'capped_value',
        component: InputNumber,
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
    },
]

const formatBuyXatYObject = (discounts: BuyXAtYDiscount[]): BuyXAtYDiscountFlattened[] => {
    if (!discounts || ['{}', '[]'].includes(JSON.stringify(discounts))) return []
    const tempDiscounts = JSON.parse(JSON.stringify(discounts)).map((discount: BuyXAtYDiscount) => ({
        discount_name: discount.discount_name,
        capped_value: discount.discount_info.capped_value,
        match_type: discount.discount_info.match_type,
        product_quantity: discount.discount_info.product_quantity,
    }))
    return [...tempDiscounts]
}

const formatBuyXatYArray = (discounts: BuyXAtYDiscountFlattened[]): BuyXAtYDiscount[] =>
    discounts.map(({ discount_name, ...rest }) => ({ discount_name, discount_info: { ...rest } }))

const BuyXAtYDiscounts = () => {
    const [isUpdated, setIsUpdated] = useState(false)
    const { config, loading, refetch, saveConfig } = useMerchantConfigApi({
        useCache: true,
        configSaveUrl: APIEndPoints.updateDiscountConfig,
    })
    const [discountData, setDiscountData] = useState<BuyXAtYDiscountFlattened[]>([])
    const [buyXatYDiscount, setBuyXatYDiscount] = useState<BuyXAtYDiscountFlattened>({
        discount_name: '',
        product_quantity: null,
        capped_value: null,
        match_type: null,
    })

    const buyXatYAddition = useCallback(() => {
        const submit = validateBuyXatYDiscount(buyXatYDiscount, discountData || [])
        if (!submit.status) return message.error(submit.msg)
        const tempDiscounts = JSON.parse(JSON.stringify(discountData))
        setDiscountData((prev) => [
            ...tempDiscounts,
            {
                ...buyXatYDiscount,
            },
        ])
        setBuyXatYDiscount({ discount_name: '', product_quantity: null, capped_value: null, match_type: null })
        setIsUpdated(true)
    }, [buyXatYDiscount, discountData])

    const buyXatYDeletion = useCallback(
        (deletedDiscount, index) => {
            const { discount_name, ...rest } = deletedDiscount
            const tempDiscounts = JSON.parse(JSON.stringify(discountData)).filter(
                (discount: BuyXAtYDiscountFlattened, i: number) => i !== index,
            )
            setIsUpdated(true)

            setDiscountData(tempDiscounts)
        },
        [discountData],
    )

    useEffect(() => {
        setDiscountData(formatBuyXatYObject(config?.buyxaty_discounts))
    }, [config])

    const columns: ColumnsType<any> = [
        {
            title: 'Discount Code',
            dataIndex: 'discount_name',
            key: 'discount_name',
        },
        {
            title: 'Match Type',
            dataIndex: 'match_type',
            key: 'match_type',
            render: (matchType) => {
                switch (matchType) {
                    case 1:
                        return 'Exact Match'
                    case 2:
                        return 'Starts With'
                    case 3:
                        return 'Ends With'
                    case 4:
                        return 'Contains'
                    default:
                        return ''
                }
            },
        },
        {
            title: 'Product Quantity',
            dataIndex: 'product_quantity',
            key: 'product_quantity',
            align: 'right',
        },
        {
            title: 'Capped Value',
            dataIndex: 'capped_value',
            key: 'capped_value',
            align: 'right',
        },
        {
            title: '',
            key: 'action',
            render: (text, record, index) => (
                <DeleteOutlined className='text-error-500' onClick={() => buyXatYDeletion(record, index)} />
            ),
        },
    ]

    return (
        <Col className='bg-white' span={24}>
            <Row gutter={[16, 24]} align={'bottom'}>
                {Inputs.map((input, index) => (
                    <Col span={4} key={index}>
                        <input.component
                            className='w-full'
                            placeholder={input.placeholder}
                            label={input.label}
                            value={buyXatYDiscount[input.key]}
                            options={input.options}
                            max={input.max}
                            min={input.min}
                            onChange={(e: any) => {
                                setBuyXatYDiscount((prev) => ({
                                    ...prev,
                                    [input.key]: typeof e === 'object' ? e?.target.value : e,
                                }))
                            }}
                        />
                    </Col>
                ))}
                <Col span={4}>
                    <Button onClick={buyXatYAddition}>
                        <PlusOutlined /> Add New Discount
                    </Button>
                </Col>
                <Col span={24}>
                    {discountData && <Table dataSource={discountData} columns={columns} pagination={false} />}
                </Col>
                <Col>
                    <Button
                        variant='primary'
                        disabled={!isUpdated}
                        onClick={() =>
                            saveConfig({ buyxaty_discounts: formatBuyXatYArray(discountData) }, () =>
                                setIsUpdated(false),
                            )
                        }
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        </Col>
    )
}

export default BuyXAtYDiscounts
