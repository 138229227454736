import React from 'react'
import { CloseOutlined, Divider, FileImageOutlined } from 'gokwik-ui-kit'

// Define the types for your props
interface ProductsAndCollectionListProps {
    discountAction: string
    category: string
    renderProductMapping?: any
    removeProductFromList?: (productId: string) => void
    selectedCollections?: any
    removeCollectionFromList?: (collectionId: string) => void
}

const SelectedProductsAndCollectionsList: React.FC<ProductsAndCollectionListProps> = (props) => {
    const {
        discountAction,
        category,
        renderProductMapping,
        removeProductFromList,
        selectedCollections,
        removeCollectionFromList,
    } = props

    if (category === 'specific_products' && renderProductMapping?.length > 0) {
        return (
            <div className='flex gap-2 flex-wrap'>
                {renderProductMapping?.map((item, index) => (
                    <div className='border border-solid border-[#00000026] rounded-lg' key={index}>
                        <div className='flex justify-between gap-2 p-1 min-w-max items-center'>
                            <div className='flex gap-1 items-center truncate'>
                                <span className='bg-[#627F99] rounded-md'>
                                    <FileImageOutlined className='text-sm text-white p-[6px]' />
                                </span>
                                <span className='text-sm font-semibold truncate'>{item?.product_name}</span>
                            </div>
                            <div
                                className={discountAction === 'view' ? 'pointer-events-none' : 'cursor-pointer'}
                                onClick={() => removeProductFromList(item?.product_id)}
                            >
                                <CloseOutlined className='text-[#00000073]' />
                            </div>
                        </div>
                        <Divider className='my-1'/>
                        <div>
                            {item?.variant_ids?.length > 0 ? (
                                <ul className='list-none m-0 p-0'>
                                    {item.variant_ids.map((variant, vIndex) => (
                                        <li key={vIndex} className='py-1 text-sm text-gray-500 flex items-center ml-1'>
                                            <span className='bg-gray-200 rounded-full px-2 py-1 text-xs mr-1'>{vIndex + 1}</span>
                                            {variant?.variant_name}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className='text-sm text-gray-500 mr-1 ml-1'>All variants selected</p>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        )
    } else if (category === 'specific_collections' && selectedCollections?.length > 0) {
        return (
            <div className='flex gap-2 flex-wrap'>
                {selectedCollections?.map((item, index) => (
                    <div key={index}>
                        <div className='border border-solid border-[#00000026] rounded-lg flex justify-between gap-2 p-1 min-w-max items-center'>
                            <div className='flex gap-1 items-center truncate'>
                                <span className='bg-[#627F99] rounded-md'>
                                    <FileImageOutlined className='text-sm text-white p-[6px]' />
                                </span>
                                <span className='text-sm font-semibold truncate'>{item?.collection_name}</span>
                            </div>
                            <div
                                className={discountAction === 'view' ? 'pointer-events-none' : 'cursor-pointer'}
                                onClick={() => removeCollectionFromList(item?.collection_id)}
                            >
                                <CloseOutlined className='text-[#00000073]' />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    } else return null
}

export default SelectedProductsAndCollectionsList
