import { dashboardStore } from '@gokwik/utilities'
import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
    name: 'userSlice',
    initialState: {
        config: dashboardStore.getState().userData?.config || {},
        merchantDetails: dashboardStore.getState().userData?.merchant_details || {},
        userDetails: dashboardStore.getState().userData?.user_details || {},
        kycData: dashboardStore.getState().kycData || {},
        mode: '',
    },
    reducers: {
        setUserData: (state, action) => {
            state.userDetails = action.payload.user_details || {}
            state.config = action.payload.config || {}
            state.merchantDetails = action.payload.merchant_details || {}
            state.kycData = action.payload.kyc_details || {}
            state.mode = action.payload.mode
        },
    },
})

export const { setUserData } = userSlice.actions

export default userSlice.reducer
