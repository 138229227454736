import React, { useEffect, useRef, useState } from 'react'
import {
    Row,
    Col,
    Tag,
    Tooltip,
    Button,
    Input,
    Table,
    Space,
    ColumnProps,
    DeleteFilled,
    MinusCircleOutlined,
    PlusCircleOutlined,
    Modal,
    CloseCircleOutlined,
    message,
} from 'gokwik-ui-kit'
import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import { useSelector } from 'react-redux'
import { getMerchantDetails, isMerchantSelector } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import SearchProduct from './search-product'
import { useLocation } from 'react-router'
import { navigateToUrl } from 'single-spa'

export default function ({}) {
    const [lineItems, setLineItems] = useState([])
    const [modalData, setModalData] = useState({ show: false, item: null })
    const [submitBtnEnable, setSubmitBtnEnable] = useState(true) // Assuming you have logic to enable/disable the submit button

    const [editOrderDetails, setEditOrderDetails] = useState(null)
    const [editError, setEditError] = useState('')
    const [errorItems, setErrorItems] = useState([])
    const config = useSelector(getMerchantDetails)
    const isMerchantUser = useSelector(isMerchantSelector)
    const route = useLocation()
    const mainContentRef = useRef()
    const location = useLocation()
    const { pathname } = location
    const orderNumber = pathname.split('/').pop()

    const columns: ColumnProps<any>[] = [
        { title: 'S No.', dataIndex: 'index', key: 'index', width: 50, align: 'left' },
        {
            title: 'Product',
            dataIndex: 'product',
            key: 'product',
            width: 200,
            align: 'left',
            render: (_, record) => {
                return (
                    <Space direction='horizontal'>
                        <p className='w-[200px]'>{_}</p>

                        <img className='w-8 rounded' src={record.image} />
                    </Space>
                )
            },
        },
        { title: 'SKU ID', dataIndex: 'skuId', key: 'skuId', width: 150, align: 'left' },
        { title: 'Product ID', dataIndex: 'productId', key: 'productId', width: 200, align: 'left' },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center',
            width: 100,
            render: (_, record) => (
                <div className='justify-center mt-2'>
                    <Space>
                        <div className='border-solid border-[0.4px] border-gray-500 rounded-md px-3 py-2 gap-x-3 flex items-center  '>
                            <MinusCircleOutlined
                                onClick={() => {
                                    record.quantity > 1
                                        ? modifyQty(record.variant_id, record.quantity - 1)
                                        : showDelete(record)
                                    // modifyQty(record.variant_id, record.quantity - 1)
                                }}
                                className='cursor-pointer'
                            />
                            {_}
                            <PlusCircleOutlined
                                onClick={() => modifyQty(record.variant_id, record.quantity + 1)}
                                className='cursor-pointer'
                            />
                        </div>
                    </Space>
                    <p className='mt-1 text-xs text-gray-400'>Each @ ₹{(+record.price).toFixed(2)}</p>
                </div>
            ),
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'center',
            width: 150,
            render: (_, record) => (
                <p className='text-center width-10'>
                    {(record.quantity * +record.price).toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR',
                    })}
                </p>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: 100,
            render: (_, record) => (
                <Space>
                    <DeleteFilled
                        onClick={() => deleteItem(record.variant_id)}
                        className='w-5 text-red-400 cursor-pointer'
                    />
                </Space>
            ),
        },
    ]

    const data = lineItems?.map((item, i) => ({
        key: i,
        index: i + 1,
        product: item.p_name || `${item.product_name}-${item.variant_name}`,
        skuId: item.sku,
        productId: item.product_id,
        quantity: item.quantity,
        total: item.quantity * item.price,
        price: item.price,
        id: item.id,
        image: item.product_thumbnail_url,
        variant_id: item.variant_id,
    }))

    const showDelete = (item) => {
        setModalData({ show: true, item: item })
    }

    useEffect(() => {
        updateBreadcrumbs((prev) =>
            prev[prev?.length - 1]?.text === String(orderNumber)
                ? prev
                : [
                      prev[0],

                      {
                          key: 'orders',
                          href: '/checkout/orders',
                          text: 'Orders',
                      },
                      {
                          key: 'edit',
                          href: `/checkout/orders/edit/${orderNumber}`,
                          text: 'Edit',
                      },
                      {
                          key: 'order-details',
                          href: `/checkout/orders/${orderNumber}`,
                          text: String(orderNumber),
                      },
                  ],
        )
    }, [])

    const fetchOrderDetails = async () => {
        await makeAPICall({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.getEditOrder}/${orderNumber}`,
        })
            .then((res) => {
                const orderData = { ...res.data.data.order }
                if (orderData.order_edit_status === 1) return setEditError('orderEdited')
                else if (!orderData.order_name || orderData.order_status !== 'confirmed')
                    return setEditError('notConfirmed')
                else if (orderData.fulfilled === 'fulfilled') return setEditError('orderFulfilled')
                else {
                    setEditOrderDetails({ ...res.data.data })
                    setLineItems([...res.data.data.line_items])
                }
            })
            .catch((err) => message.error(err.response?.data?.message || 'Unable to fetch order'))
    }

    const modifyQty = (id, qty) => {
        const tempLineItems = [...lineItems].map((item) =>
            item.variant_id === id
                ? {
                      ...item,
                      quantity: qty,
                  }
                : { ...item },
        )
        setLineItems([...tempLineItems])
    }

    const deleteItem = (id) => {
        setModalData({
            show: false,
            item: null,
        })
        if (lineItems.length === 1) return message.warning('At least one line item is required')
        const tempLineItems = [...lineItems].filter((item) => item.variant_id !== id)
        setLineItems([...tempLineItems])
    }

    const submitEditOrder = async () => {
        console.log(editOrderDetails)
        const existingLineItems = editOrderDetails?.line_items.reduce(
            (acc, item) => ({ ...acc, [item.variant_id]: { ...item } }),
            {},
        )
        const currentLineItems = lineItems.reduce((acc, item) => ({ ...acc, [item.variant_id]: { ...item } }), {})
        const changedItems = editOrderDetails?.line_items.reduce((acc, existingItem) => {
            if (
                currentLineItems[existingItem.variant_id] &&
                existingItem.quantity !== currentLineItems[existingItem.variant_id].quantity
            ) {
                return [
                    ...acc,
                    {
                        type: 'adjust',
                        lineItemId: existingItem.line_item_id,
                        variantId: existingItem.variant_id,
                        productId: existingItem.product_id,
                        quantity: currentLineItems[existingItem.variant_id].quantity - existingItem.quantity,
                    },
                ]
            } else if (!currentLineItems[existingItem.variant_id])
                return [
                    ...acc,
                    {
                        type: 'remove',
                        lineItemId: existingItem.line_item_id,
                        variantId: existingItem.variant_id,
                        productId: existingItem.product_id,
                    },
                ]
            else return acc
        }, []) // array having current line items compared with exisiting line items if removed or adjusted

        const newItems = lineItems.reduce((acc, currentLineItem) => {
            if (!existingLineItems[currentLineItem.variant_id])
                return [
                    ...acc,
                    {
                        type: 'add',
                        variantId: currentLineItem.variant_id,
                        productId: currentLineItem.product_id,
                        quantity: currentLineItem.quantity,
                    },
                ]
            else return acc
        }, []) // array having existing line items compared with current line items if any new items were added

        console.log([...changedItems, ...newItems])
        await makeAPICall({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.saveEditOrder}`,
            payload: {
                moid: editOrderDetails.order.moid,
                line_items: [...changedItems, ...newItems],
            },
        })
            .then((res) => {
                if (res.data.data.error) {
                    message.error(res.data?.data?.data?.message || 'Order Update Failed')
                } else {
                    message.success('Order Updated Successfully')
                    setTimeout(() => {
                        navigateToUrl(`/checkout/orders/${orderNumber}`)
                    }, 1000)
                }
                errorItems.length && setErrorItems([])
            })
            .catch((err) => {
                const data = { ...err.response?.data }
                if (data?.data?.data && data.data.error) {
                    const errIds = data.data.data.reduce((ids, item) => {
                        return item.message ? [...ids, item.variantId] : ids
                    }, [])
                    setErrorItems([...errIds])
                    message.error(data.data.message || 'Unable to update order'),
                        {
                            position: 'top-center',
                            autoClose: 1500,
                        }
                } else {
                    message.error(err.response?.data?.message || 'Unable to update order'),
                        {
                            position: 'top-center',
                            autoClose: 1500,
                        }
                }
            })
    }

    useEffect(() => {
        if (!config.platform) return
        if (config.platform !== 'shopify') return setEditError('nonShopify')
        else fetchOrderDetails()
    }, [config.platform])

    useEffect(() => {
        if (!lineItems.length && !editOrderDetails?.line_items?.length) return
        if (editOrderDetails?.line_items.length !== lineItems.length) setSubmitBtnEnable(true)
        else {
            const currentLineItems = lineItems.reduce((acc, item) => ({ ...acc, [item.variant_id]: { ...item } }), {})
            const itemsChanged = editOrderDetails.line_items.reduce(
                (acc, item) => acc || currentLineItems[item.variant_id]?.quantity !== item.quantity,
                false,
            )
            setSubmitBtnEnable(itemsChanged)
        }
    }, [lineItems])

    if (!config?.enable_edit_order && !isMerchantUser) {
        return <h1 className=' text-center text-lg font-bold'>This feature is disabled for your store.</h1>
    }
    if (editError) {
        return (
            <div className='w-full h-full flex flex-col items-center'>
                {editError === 'nonShopify' ? (
                    <h4 className='text-center font-semibold'>This feature is for Shopify merchants only.</h4>
                ) : editError === 'orderEdited' ? (
                    <h4 className='text-center font-semibold'>
                        Order No.:{' '}
                        <a href={`/checkout/orders/${orderNumber}`} className='text-primary cursor-pointer'>
                            {orderNumber}
                        </a>
                        , has already been edited once.
                    </h4>
                ) : editError === 'notConfirmed' ? (
                    <h4 className='text-center font-semibold'>
                        Order No.:{' '}
                        <a href={`/checkout/orders/${orderNumber}`} className='text-primary cursor-pointer'>
                            {orderNumber}
                        </a>
                        , is not confirmed yet, thus cannot be edited.
                    </h4>
                ) : (
                    editError === 'orderFulfilled' && (
                        <h4 className='text-center font-semibold'>
                            Order No.:{' '}
                            <a href={`/checkout/orders/${orderNumber}`} className='text-primary cursor-pointer'>
                                {orderNumber}
                            </a>
                            , is fulfilled.
                        </h4>
                    )
                )}
            </div>
        )
    }

    return (
        <>
            <div className='order-details-page w-full  flex flex-col gap-y-4 inter'>
                <Row className='p-2 rounded bg-neutrals-15' align={'middle'} justify={'space-between'}>
                    <Col sm={12} className='flex items-center gap-x-2'>
                        <span className='text-black text-base inter font-semibold m-0 tracking-wide'>
                            Edit Order <span className='text-blue-500'> #{orderNumber}</span>
                        </span>
                    </Col>
                </Row>
                <Row className='p-2 rounded bg-neutrals-15' align={'middle'} justify={'space-between'}>
                    <Col span={6}>
                        <p className='text-base'>Add products</p>
                        <p className='text-sm'>(Please enter at least three characters)</p>
                    </Col>
                    <Col span={8} className='flex  gap-x-2'>
                        {/* <Input type='text' className='w-[400px]' placeholder='Enter product id or name' /> */}
                        <SearchProduct lineItems={lineItems} setLineItems={setLineItems} />
                    </Col>
                    <Col span={8}>
                        <Row className='gap-2 items-center justify-end'>
                            <p className=''>
                                Grand Total:{' '}
                                <span className='font-bold'>
                                    {(+lineItems.reduce(
                                        (sum, item) => sum + item.quantity * +item.price,
                                        0,
                                    )).toLocaleString('en-IN', {
                                        maximumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'INR',
                                    })}{' '}
                                </span>
                            </p>
                            <Button
                                disabled={!submitBtnEnable}
                                onClick={submitEditOrder}
                                className='w-[5rem]'
                                variant='primary'
                            >
                                Save
                            </Button>
                        </Row>
                    </Col>
                </Row>
                <div className='p-2 rounded bg-neutrals-15'>
                    <div className='flex flex-col'>
                        <p className='text-lg font-bold mb-1 '>Line Items</p>
                        {lineItems.length && (
                            <p className='text-blue-500 text-sm font-semibold mb-1'>Total Items: {lineItems.length}</p>
                        )}
                    </div>
                    <Table columns={columns} dataSource={data} />
                </div>
            </div>
            <Modal
                cancelText={'Cancel'}
                okText={'Delete'}
                okType='danger'
                centered
                // className='w-1/2'
                width={'30vw'}
                open={modalData.show}
                onOk={() => {
                    deleteItem(modalData.item.variant_id)
                }}
                onCancel={() => {
                    setModalData({
                        show: false,
                        item: null,
                    })
                }}
            >
                <p className='font-semibold '>Are you sure ?</p>
                <p className='text-normal  '>
                    Do your want to remove product <span className='font-semibold'>{modalData?.item?.product}</span>
                </p>
            </Modal>
        </>
    )
}
