import { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import dayjs from 'dayjs'
import {
    addBreadcrumb,
    convertIsoToLocaleString,
    deepEqualAndDiff,
    filterDateFormatter,
    makeAPICall,
    setBreadcrumb,
    updateBreadcrumbs,
} from '@gokwik/utilities'
import { Link } from 'react-router-dom'
import {
    Table,
    ColumnProps,
    Popover,
    Tooltip,
    message,
    LinkOutlined,
    Button,
    Checkbox,
    Dropdown,
    FilterOutlined,
    Input,
    Menu,
} from 'gokwik-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { previousAbcFilters } from '@store/filters/selectors'
import { saveFiltersData } from '@store/filters'
import RenderSearchFilters from '@library/components/search-filter'
import { getUserConfig, isMerchantSelector, getMerchantDetails, getUserDetails } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { UtmListParams } from '@library/utilities/interface'
import { fetchUtmList } from '@library/utilities/helpers/fetchUtmList'
import { handleError } from '@library/utilities/helpers/handleError'
import { formatAmount } from '@library/utilities/helpers/helper'
import RenderColumnPreferences from '@library/components/column-preferences'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'
import './abc.css'

const today = dayjs()
const renderAmount = (val) => <span className='font-medium'>₹ {(+(val || 0)).toFixed(2)}</span>
const formattedDate = filterDateFormatter(today)

export default function () {
    const prevFilters = useSelector(previousAbcFilters)
    const [total, setTotal] = useState(0)
    const [abcData, setAbcData] = useState([])
    const dispatch = useDispatch()
    const isMerchantUser = useSelector(isMerchantSelector)
    const merchantConfig = useSelector(getUserConfig)
    const user_details = useSelector(getUserDetails)
    const merchant_details = useSelector(getMerchantDetails)
    const [utmList, setUtmList] = useState<any>({})

    const [parameters, setParameters] = useState({
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
        start_dt: formattedDate,
        end_dt: formattedDate,
        ...prevFilters,
    })
    const columns: ColumnProps<any>[] = [
        {
            title: 'Cart ID',
            dataIndex: 'id',

            ellipsis: true,
            render: (text: string) => (
                <Link
                    to={`/checkout/abandoned-cart/${text}`}
                    onClick={() =>
                        addBreadcrumb({
                            key: 'abandoned_cart',
                            href: `/checkout/abandoned-cart/${text}`,
                            text,
                        })
                    }
                >
                    <a style={{ width: 'min-content' }}>
                        <Tooltip placement='topLeft' title={text}>
                            {text}
                        </Tooltip>
                    </a>
                </Link>
            ),
            fixed: 'left',
        },
        ...(!isMerchantUser
            ? [
                  {
                      title: 'Merchant Name',
                      dataIndex: 'Merchant.short_name',
                  },
              ]
            : []),

        {
            title: 'Customer Name',
            dataIndex: 'Customer.firstname',

            render: (t, record) => (
                <p>
                    {t} {record['Customer.lastname']}
                </p>
            ),
            width: 150,
        },
        {
            title: 'Kwik Link',
            dataIndex: 'abc_url',
            ellipsis: true,
            render: (link, record) =>
                link ? (
                    <a href={link} target='_blank' rel='noopener noreferrer' style={{ width: 'min-content' }}>
                        <Tooltip title={link}>
                            <p className='text-center'>
                                <LinkOutlined className='text-blue-500 cursor-pointer' />{' '}
                            </p>
                        </Tooltip>
                    </a>
                ) : (
                    ''
                ),
            width: 30,
            align: 'left',
        },

        {
            title: 'Coupon Code',
            dataIndex: 'discount_title',
        },

        {
            title: 'Total Discount',
            dataIndex: 'total_discount',

            align: 'left',
            render: (t, record) => <p>{formatAmount(Number(record?.discount?.total_discount || 0), 2)}</p>,
        },
        {
            title: 'Line Items',
            dataIndex: 'abandoned_cart_line_items',
            width: 220,
            render: (t, record) => <p className=''>{abandonedCartLineItems(record)}</p>,
        },
        {
            title: 'Drop Off Stage',
            dataIndex: 'drop_stage',
        },
        {
            title: 'Amount',
            dataIndex: 'total_price',

            align: 'left',
            render: (t, record) => <p>{formatAmount(t, 2)}</p>,
        },
        {
            title: 'Comment',
            dataIndex: 'showComment',
            width: 300,
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',

            ellipsis: true,
            render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
            sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Updated At',
            dataIndex: 'updated_at',

            ellipsis: true,
            render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
            sorter: (a, b) => (dayjs(a.updated_at).isBefore(dayjs(b.updated_at)) ? -1 : 1),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'UTM Source',
            dataIndex: 'mkt_source',
        },

        {
            title: 'UTM Medium',
            dataIndex: 'mkt_medium',
        },
        {
            title: 'UTM Campaign',
            dataIndex: 'mkt_campaign',
        },
        {
            title: 'Remarks',
            dataIndex: 'textbox',
        },
    ]

    const formatComment = (comment) => {
        if (!comment || !comment?.length) return ''
        let n = comment.length
        let data = comment[n - 1].comment
        if (!data) return ''
        if (data.length > 60) data = data.slice(0, 60) + '...' // not include 60
        return data
    }

    const getValidJsonStr = (str) => {
        if (typeof str === 'string') {
            //@ts-ignore
            str = str?.replaceAll('True', 'true')
            str = str.replaceAll('False', 'false')
            str = str.replaceAll('None', 'null')
            const regex = /('(?=(,\s*')))|('(?=:))|((?<=([:,]\s*))')|((?<={)')|('(?=}))/g
            str = str.replace(regex, '"')
            return JSON.parse(str)
        } else {
            return str
        }
    }
    const abandonedCartLineItems = (data) => {
        if (typeof data.items === 'string') {
            data.items = data.items.replaceAll('True', 'true')
            data.items = data.items.replaceAll('False', 'false')
            data.items = data.items.replaceAll('None', 'null')
            // data.items = data.items.replaceAll("'", '"')
            const regex = /('(?=(,\s*')))|('(?=:))|((?<=([:,]\s*))')|((?<={)')|('(?=}))/g
            data.items = data.items.replace(regex, '"')
        }
        try {
            let items = typeof data.items === 'string' ? JSON.parse(data.items) : [...data.items]
            let lineItems = []
            for (let k = 0; k < items.length; k++) {
                let current = items[k]
                let temp
                let ptitle = current.product_title != null ? current.product_title : ''
                let vtitle = current.variant_title != null ? current.variant_title : ''
                let quantity = current.quantity != null ? current.quantity : ''
                temp = '#' + ptitle + (vtitle ? '(' + vtitle + ')' : '') + '*' + quantity
                lineItems.push(temp)
            }
            return (
                <Popover
                    placement='topLeft'
                    title={'Line Items'}
                    content={lineItems.map((each) => (
                        <p key={each}>{each}</p>
                    ))}
                >
                    <p>
                        {lineItems.length > 1 ? (
                            <p>
                                {lineItems[0]} ${<span className='text-blue fs12'> + {lineItems.length - 1} more</span>}
                            </p>
                        ) : (
                            lineItems[0]
                        )}
                    </p>
                </Popover>
            )
        } catch (error) {
            console.log(error)
        }
    }

    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
        makeAbcCall({ ...parameters, page: current, pageSize })
    }

    useEffect(() => {
        if (Object.keys(prevFilters).length && JSON.stringify(prevFilters) !== JSON.stringify(parameters)) {
            setParameters({ ...prevFilters })
        }
        logEvent(
            'kwik_checkout_abandoned_cart_clicked',
            'click',
            'Abandoned Cart',
            user_details?.email,
            merchant_details?.m_id,
            merchant_details?.short_name,
            user_details?.name,
            parameters,
        )
    }, [prevFilters])
    const getUtmOptions = async (data: Record<string, string>) => {
        const keys = Object.keys(data)
        if (!['searchUtmCampaign', 'searchUtmSource', 'searchUtmMedium'].find((searchKey) => keys.includes(searchKey)))
            return
        const params: UtmListParams = {
            start_dt: data.start_dt || parameters.start_dt,
            end_dt: data.end_dt || parameters.end_dt,
            table: 'cart',
            searchUtmCampaign:
                ('searchUtmCampaign' in data ? data.searchUtmCampaign : parameters.searchUtmCampaign) || '',
            searchUtmSource: ('searchUtmSource' in data ? data.searchUtmSource : parameters.searchUtmSource) || '',
            searchUtmMedium: ('searchUtmMedium' in data ? data.searchUtmMedium : parameters.searchUtmMedium) || '',
        }
        if (deepEqualAndDiff(utmList.params, params).isEqual) return

        const res: {
            campaigns: any
            mediums: any
            sources: any
        } = await fetchUtmList(params)

        if (res) {
            setUtmList({
                searchUtmCampaign: res.campaigns.map((item) => ({
                    text: item.mkt_campaign,
                    value: item.mkt_campaign,
                })),
                searchUtmMedium: res.mediums.map((item) => ({
                    text: item.mkt_medium,
                    value: item.mkt_medium,
                })),
                searchUtmSource: res.sources.map((item) => ({
                    text: item.mkt_source,
                    value: item.mkt_source,
                })),
                params,
            })
        } else {
            setUtmList((prev) => ({ ...prev, params }))
        }
    }

    const reset = (e) => {
        e.preventDefault()
        const newParams = {
            page: 1,
            sortKey: 'created_at',
            sortOrder: -1,
            pageSize: 25,
            start_dt: formattedDate,
            end_dt: formattedDate,
        }
        setParameters(newParams)
        makeAbcCall(newParams)
    }

    async function getCount(paramsForCount) {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.getAbcCount,
            params: {
                ...paramsForCount,
                start_dt: paramsForCount.start_dt,
                end_dt: paramsForCount.end_dt,
            },
            skipLoader: true,
        })
        const newTotal = response?.data?.data?.count
        setTotal(newTotal)
    }

    async function makeAbcCall(parameters: any) {
        if (!parameters.page) return
        try {
            // let response = dummyOrders?.data;
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getAbandonedCarts,
                params: {
                    ...parameters,
                    start_dt: parameters.start_dt,
                    end_dt: parameters.end_dt,
                },
            })

            if (response.data?.data?.response?.length > 0) {
                const abcData = response.data.data?.response?.map((abc) => ({
                    ...abc,
                    // dateSlot: "Today",
                    showComment: formatComment(abc.comment),
                    discount_title: abc?.discount?.title,
                    discount_total: abc?.discount?.total_discount,
                    ...('merchant.business_name' in abc && {
                        'Address.address': abc['address.address'],
                        'Address.address1': abc['address.address1'],
                        'Address.address2': abc['address.address2'],
                        'Address.city': abc['address.city'],
                        'Address.pincode': abc['address.pincode'],
                        'Address.state': abc['address.state'],
                        'Customer.email': abc['customer.email'],
                        'Address.email': abc['address.email'],
                        'Address.firstname': abc['address.firstname'],
                        'Address.lastname': abc['address.lastname'],
                        'Customer.firstname': abc['customer.firstname'],
                        'Customer.lastname': abc['customer.lastname'],
                        'Customer.phone': abc['customer.phone'],
                        'Merchant.business_name': abc['merchant.business_name'],
                        'Merchant.short_name': abc['merchant.short_name'],
                        ...(abc.shipping && { shipping: getValidJsonStr(abc.shipping) }),
                    }), // Read DB keys mapped from data returned from Redshift
                }))
                setAbcData(abcData || [])
                setTotal(response.data?.data?.totalCount)
                // if (parameters?.page === 1) getCount(parameters)
            } else {
                setAbcData([])
                setTotal(0)
            }
            dispatch<any>(saveFiltersData('abandoned_carts', { ...parameters }))
        } catch (error) {
            handleError(error)
        }
    }

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'abandoned-cart',
                href: '/checkout/abandoned-cart',
                text: 'Abandoned Cart',
            },
        ])
        // if (!parameters.merchant_id && !isMerchantUser) return
        if (parameters.page >= 1) {
            makeAbcCall({ ...parameters })
        }
    }, [])

    const handleSearchClick = (e) => {
        e?.preventDefault()

        //Phone number validation
        if (parameters?.['phone'] && parameters?.['phone']?.toString()?.length < 10) {
            message.error('Phone number should be 10-digits.')
            return
        }
        setParameters((prev) => ({ ...prev, page: 1 }))
        makeAbcCall({ ...parameters, page: 1 })
    }

    const [filteredColumns, setFilteredColumns] = useState([])
    useEffect(() => {
        const insertReactComponent = () => {
            const ulElement = document.querySelector('.ant-pagination') as HTMLElement
            if (ulElement) {
                ulElement.style.width = '97%'
            }
        }
        insertReactComponent()
    }, [abcData?.length])

    return (
        <div className='w-full'>
            <div className='abc-page'>
                <div className='bg-white rounded'>
                    <RenderSearchFilters
                        values={parameters}
                        setValues={(data, reset) => {
                            setParameters((prev) => (reset ? { ...data } : { ...prev, ...data }))
                            getUtmOptions(data)
                        }}
                        page='abandoned_carts'
                        onSearch={handleSearchClick}
                        onReset={reset}
                        customFilterOnSelect={(key) => {
                            getUtmOptions({ [key]: '' })
                        }}
                        customFilterOptions={utmList}
                        customFilterCallback={(filter) =>
                            filter.key === 'recovery_status' ? merchantConfig.show_recovered_abc_orders : true
                        }
                        customFilterOnClick={(key) => {
                            if (key.includes('searchUtm')) {
                                getUtmOptions({ [key]: parameters[key] || '' })
                            }
                        }}
                    />
                </div>
                <div className='mt-5 bg-white rounded overflow-clip relative abc-table'>
                    {!!abcData?.length && (
                        <RenderColumnPreferences
                            columns={columns}
                            page={'abandoned_cart'}
                            setFilteredColumns={setFilteredColumns}
                            isMerchantUser={isMerchantUser}
                        />
                    )}
                    <Table
                        columns={filteredColumns}
                        dataSource={abcData || []}
                        pagination={{ simple: true, pageSize: 25 }}
                        scroll={{ x: 'max-content' }}
                    />

                    <div
                        className={`custom-pagination px-4 py-2 absolute top-0 right-6 z-0 
                            ${abcData?.length === 0 && parameters?.page === 1 && 'invisible'}
                            `}
                    >
                        <a
                            className={`text-[#1677ff] ${parameters?.page === 1 && 'text-gray-300 cursor-not-allowed'}`}
                            href='#'
                            onClick={(e) => {
                                e.preventDefault()
                                if (parameters?.page > 1) handlePaginationChange(parameters?.page - 1, 25)
                            }}
                        >
                            Prev
                        </a>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a
                            className={`text-[#1677ff] ${abcData?.length < 1 && 'text-gray-300 cursor-not-allowed'}`}
                            href='#'
                            onClick={(e) => {
                                e.preventDefault()
                                abcData?.length > 0 && handlePaginationChange(parameters?.page + 1, 25)
                            }}
                        >
                            Next
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
