import { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import dayjs from 'dayjs'
import {
    addBreadcrumb,
    convertIsoToLocaleString,
    deepEqualAndDiff,
    filterDateFormatter,
    makeAPICall,
    setBreadcrumb,
    updateBreadcrumbs,
} from '@gokwik/utilities'
import { Link } from 'react-router-dom'
import { Table, Tag, Tooltip, ColumnProps, message } from 'gokwik-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { previoursMarketingAnalyticsFilters } from '@store/filters/selectors'
import { saveFiltersData } from '@store/filters'
import RenderSearchFilters from '@library/components/search-filter'
import { getMerchantPlatform, isMerchantSelector } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { marketingAnalytics } from '../orders/columnsData'
import { fetchUtmList } from '@library/utilities/helpers/fetchUtmList'
import { DistinctCampaignData, UtmListParams } from '@library/utilities/interface'
import { fetchDistinctCampignData } from '@library/utilities/helpers/fetchDistinctCampignData'
import { handleError } from '@library/utilities/helpers/handleError'
import RenderColumnPreferences from '@library/components/column-preferences'

const today = dayjs()
const formattedDate = filterDateFormatter(today)

export default function () {
    const prevFilters = useSelector(previoursMarketingAnalyticsFilters)
    const [total, setTotal] = useState(0)
    const [marketingData, setMarketingData] = useState([])
    const dispatch = useDispatch()
    const isMerchantUser = useSelector(isMerchantSelector)
    const merchantPlatform = useSelector(getMerchantPlatform)
    const [utmList, setUtmList] = useState<any>({})
    const [parameters, setParameters] = useState({
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
        start_dt: formattedDate,
        end_dt: formattedDate,
        ...prevFilters,
    })
    const merchantColumns = useMemo(() => {
        const temp = [...marketingAnalytics]
        return temp
    }, [merchantPlatform])
    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
    }

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'marketing_analytics',
                href: '/checkout/marketing-analytics',
                text: 'Marketing Analytics',
            },
        ])
    }, [])
    useEffect(() => {
        if (Object.keys(prevFilters).length && JSON.stringify(prevFilters) !== JSON.stringify(parameters))
            setParameters({ ...prevFilters })
    }, [prevFilters])

    const reset = (e) => {
        e.preventDefault()
        setParameters({
            page: 1,
            sortKey: 'created_at',
            sortOrder: -1,
            pageSize: 25,
            start_dt: formattedDate,
            end_dt: formattedDate,
        })
        setMarketingData([])
        dispatch<any>(saveFiltersData('marketing_analytics', {}))
        setTotal(0)
    }

    async function getCampignData(parameters: any) {
        if (!parameters.page) return
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.marketingAnalytics,
                params: {
                    ...parameters,
                    campaign: parameters?.campaign?.length ? parameters?.campaign.join(',') : null,
                    source: parameters?.source?.length ? parameters?.source.join(',') : null,
                    medium: parameters?.medium?.length ? parameters?.medium.join(',') : null,
                    start_dt: parameters.start_dt,
                    end_dt: parameters.end_dt,
                },
            })

            if (response.data?.data?.campaign_data?.length > 0) {
                setMarketingData(response.data?.data?.campaign_data)
                setTotal(response.data?.data?.totalCount)
            } else {
                setMarketingData([])
                setTotal(0)
            }
            dispatch<any>(saveFiltersData('marketing_analytics', { ...parameters }))
        } catch (error) {
            handleError(error)
        }
    }

    const getUtmOptions = async (data: Record<string, string>) => {
        const keys = Object.keys(data)
        console.log(data)
        const params: DistinctCampaignData = {
            start_dt: data.start_dt || parameters.start_dt,
            end_dt: data.end_dt || parameters.end_dt,
        }
        console.log(data)
        if (deepEqualAndDiff(utmList.params, params).isEqual) return

        const res = await fetchDistinctCampignData(params)
        if (res) {
            setUtmList({
                source: res.mkt_source?.filter((val) => val) || [],
                medium: res.mkt_medium?.filter((val) => val) || [],
                campaign: res.mkt_campaign?.filter((val) => val) || [],
            })
        } else {
            setUtmList((prev) => ({ ...prev, params }))
        }
    }
    useEffect(() => {
        // if (!parameters.search_merchant_id && !isMerchantUser) return
        if (parameters.page >= 1) {
            getCampignData({ ...parameters })
            fetchDistinctCampignData(parameters)
        }
    }, [parameters.page])

    const handleSearchClick = (e) => {
        e?.preventDefault()
        setParameters((prev) => ({ ...prev, page: 1 }))
        getCampignData({ ...parameters, page: 1 })
        //A merchant name should be selected to make api call
        // if (isMerchantUser || parameters?.['search_merchant_id']) {
        //     setParameters((prev) => ({ ...prev, page: 1 }))
        //     getCampignData({ ...parameters, page: 1 })
        // } else {
        //     message.error('Merchant name is required')
        // }
    }
    return (
        <div className='w-full'>
            <div className='marketingData-page overflow-auto'>
                <div className='bg-white rounded'>
                    <RenderSearchFilters
                        values={parameters}
                        setValues={(data, reset) => {
                            setParameters((prev) => (reset ? { ...data } : { ...prev, ...data }))
                            getUtmOptions(data)
                        }}
                        page='marketing_analytics'
                        onSearch={handleSearchClick}
                        onReset={reset}
                        customFilterOnSelect={(key) => {
                            getUtmOptions({ [key]: '' })
                        }}
                        customFilterOptions={utmList}
                    />
                </div>
                <div className='mt-5 bg-white rounded overflow-clip'>
                    <Table
                        columns={merchantColumns}
                        dataSource={marketingData || []}
                        pagination={{
                            current: parameters.page, // Current page number
                            pageSize: parameters.pageSize, // Number of items to display per page
                            total: total, // Total number of items in the data array
                            showSizeChanger: false,
                            onChange: handlePaginationChange,

                            position: ['topLeft', 'bottomLeft'],
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                        }}
                        scroll={{ x: 3500 }}
                    />
                </div>
            </div>
        </div>
    )
}
