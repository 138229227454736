const PastPrepaidCustomerIcon = () => (
  <span>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height="19px"
    width="19px"
    className="text-gray-600 mr-1"
    viewBox="0 0 24 24"
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      clipPath="url(#a)"
    >
      <path d="M11.5 21H8.574a3 3 0 0 1-2.965-2.544l-1.255-8.152A2 2 0 0 1 6.33 8H17.67a2 2 0 0 1 1.977 2.304L19.386 12" />
      <path d="M9 11V6a3 3 0 1 1 6 0v5M20 18h-6M17 15l-3 3 3 3" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
  </span>
);

export default PastPrepaidCustomerIcon;
