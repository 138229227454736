import React, { FC, useState } from 'react'
import {
    Row,
    Col,
    Steps,
    TagOutlined,
    InfoCircleOutlined,
    Modal,
    Tooltip,
} from 'gokwik-ui-kit'
import { useNavigate } from 'react-router-dom'
import { SummaryCard } from './summaryCard'
import { ICommonEvents } from '@library/utilities/helpers/eventsHelper'

type Props = {
    children: any
    stepsItems: any
    currentStep?: any
    headerTitle?: string
    subtitle: string
    formState?: any
    discountType?: any
    events: ICommonEvents
}

const Layout: FC<Props> = (props) => {
    const { children, stepsItems, currentStep, headerTitle, subtitle, formState, discountType, events } = props || {}
    const navigate = useNavigate()
    const [showPopup, setShowPopup] = useState(false)

    const { configsUpsert } = events;

    const getDiscountName = (discountType) => {
        if (discountType === 'buy_x_at_y') {
            return 'Buy X, At Y'
        } else if (discountType === 'capping') {
            return 'Capping'
        } else if (discountType === 'quantity') {
            return 'Product Quantity'
        } else if (discountType === 'dynamic') {
            return 'Order Value'
        } else if (discountType === 'gift_with_product') {
            return 'Gift with Products'
        }
    }

    return (
        <>
            <Modal
                cancelText={'Stay on this page'}
                okText={'Leave'}
                centered
                title={
                    <Row className='gap-2'>
                        <InfoCircleOutlined className='text-[#FAAD14] ' />
                        <p>Warning</p>
                    </Row>
                }
                open={showPopup}
                onOk={() => {
                    setShowPopup(false)
                    navigate('/checkout/settings/discount')
                }}
                onCancel={() => setShowPopup(false)}
            >
                <p className='ml-6 my-4'>Changes you have made may not be saved</p>
            </Modal>
            <div className='px-[104px] overflow-auto flex flex-col gap-3'>
                {/* had to use flex here because gutter vertical was not working */}
                <Row gutter={[0, 8]} className='p-4 rounded bg-neutrals-15' align={'middle'} justify={'space-between'}>
                    <Col span={12}>
                        <div className='flex gap-2 text-lg'>
                            <div>
                                <TagOutlined className='text-blue-500 border border-solid border-blue-500 rounded-full p-1' />
                                &nbsp;<span className=' font-semibold'>{headerTitle}</span>
                                <br />
                                <span className='text-sm font-normal text-[#00000073]'>{subtitle}</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row gutter={[0, 8]} align={'middle'} className='p-4 rounded bg-neutrals-15'>
                    <Col span={24}>
                        <Steps direction='horizontal' current={currentStep} items={stepsItems} />
                    </Col>
                </Row>
                <Row gutter={[12, 8]}>
                    <Col span={16}>
                        {stepsItems[currentStep]?.content}
                        <div>{children}</div>
                    </Col>

                    <Col span={8}>
                        <div className='bg-white w-full p-2 rounded-md '>
                            <p className='text-base text-blue-900 font-semibold'>Discount Summary</p>

                            <Row
                                className='py-4 border-solid border-t-0 border-l-0 border-r-0 border-b-gray-300 border-b-[0.5px] '
                                justify='space-between'
                            >
                                <p className='text-gray-400'>Discount Code</p>
                                <Tooltip title={formState?.code} className='font-medium truncate'>
                                    {formState?.code}
                                </Tooltip>
                            </Row>
                            <Row
                                className='py-4 border-solid border-t-0 border-l-0 border-r-0 border-b-gray-300 border-b-[0.5px] '
                                justify='space-between'
                            >
                                <p className='text-gray-400'>Type</p>
                                <p className='text-[#000000E0] text-sm font-semibold capitalize'>
                                    {getDiscountName(discountType) || ''} Discount
                                </p>
                            </Row>
                            <Row gutter={[12, 12]} justify={'center'} className='py-4'>
                                <Col span={24} className='text-gray-400'>
                                    Details
                                </Col>

                                <Col span={24}>
                                    <div className='rounded-lg p-2 bg-[#fafafa] border-[0.8px] border-solid border-gray-300'>
                                        <SummaryCard formState={formState} discountType={discountType} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export const CreateDiscountLayout = React.memo(Layout)
