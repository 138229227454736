import { Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getMode, getUserDetails } from '@store/user/selectors'
import Orders from '@pages/orders/orders'
import OrderDetails from '@pages/orders/order-details'
import AbandonedCart from '@pages/abandoned-cart'
import Settings from '@pages/settings'
import ShippingSettings from '@pages/settings/shipping-settings'
import PincodeSettings from '@pages/settings/pincode-settings'
import DiscountSettings from '@pages/settings/discount-settings/index'
import { CreateDiscountContainer } from '@pages/settings/discount-settings/create-discount/index'
import AbcDetails from '@pages/abandoned-cart/abandoned-cart-details/abcDetails'
import { validatePermission } from '@gokwik/utilities'
import { PermissionValues } from '@library/utilities/constants/constants'
import CheckoutAnalytics from '@pages/analytics'
import EditOrders from '@pages/orders/edit-orders'
import Marketing from '@pages/marketing-analytics'
import OrderAnalytics from '@pages/order-analytics'
import HealthServices from '@pages/health/health'
import { isMerchantSelector } from '@store/user/selectors'
import { NotFound } from '@pages/notFound'

const AppRoutes = () => {
    const userDetails = useSelector(getUserDetails)
    const merchantDetails = useSelector(getMerchantDetails)
    const isMerchantUser = useSelector(isMerchantSelector)
    const mode = useSelector(getMode)
    if (!Object.keys(userDetails || {}).length) return <></>
    return (
        <Routes key={`${mode}-${JSON.stringify(merchantDetails)}`}>
            <Route
                path='/checkout/health'
                element={
                    userDetails.role.name === 'super_admin' && !isMerchantUser ? (
                        <HealthServices />
                    ) : (
                        <NotFound hasPermission={false} />
                    )
                }
            />

            <Route
                path='/checkout/orders'
                element={
                    validatePermission(PermissionValues.orders.view) ? <Orders /> : <NotFound hasPermission={false} />
                }
            />

            <Route
                path='/checkout/orders/:id'
                element={
                    validatePermission(PermissionValues.orders.view) ? (
                        <OrderDetails />
                    ) : (
                        <NotFound hasPermission={false} />
                    )
                }
            />

            <Route
                path='/checkout/orders/edit/:id'
                element={
                    validatePermission(PermissionValues.orders.view) ? (
                        <EditOrders />
                    ) : (
                        <NotFound hasPermission={false} />
                    )
                }
            />

            <Route
                path='/checkout/analytics'
                element={
                    validatePermission(PermissionValues.checkoutAnalytics.view) ? (
                        <CheckoutAnalytics />
                    ) : (
                        <NotFound hasPermission={false} />
                    )
                }
            />

            <Route
                path='/checkout/settings/*'
                element={
                    validatePermission(PermissionValues.settings.view) ? (
                        <Settings />
                    ) : (
                        <NotFound hasPermission={false} />
                    )
                }
            />

            <Route
                path='/checkout/abandoned-cart'
                element={
                    validatePermission(PermissionValues.abandonedCarts.view) ? (
                        <AbandonedCart />
                    ) : (
                        <NotFound hasPermission={false} />
                    )
                }
            />

            <Route
                path='/checkout/abandoned-cart/:id'
                element={
                    validatePermission(PermissionValues.abandonedCarts.view) ? (
                        <AbcDetails />
                    ) : (
                        <NotFound hasPermission={false} />
                    )
                }
            />
            <Route path='/checkout/marketing-analytics' Component={Marketing} />

            <Route
                path='/checkout/order-analytics'
                element={userDetails?.is_internal_user ? <OrderAnalytics /> : <NotFound hasPermission={false} />}
            />

            <Route path='/checkout/*' element={<NotFound />} />
        </Routes>
    )
}

export default AppRoutes
