import { formatAmount } from '@library/utilities/helpers/helper';
import { Form, Card, Row, Switch, Col, Input, Select, QuestionCircleOutlined, Tooltip, message, Button, Drawer, Table, Dropdown, EditOutlined, DeleteOutlined, InfoCircleOutlined } from 'gokwik-ui-kit'
import React, { useEffect, useState } from 'react'
import threeDotsIcon from '@library/images/three-dots.svg'
import RenderField from '@library/utilities/helpers/renderFormItem';



const DiscountBaseShipping = ({ ...props }) => {
    const [form] = Form.useForm()
    const { discountConfigObject, saveConfig } = props;
    const [open, setOpen] = useState(false)
    const [enable, setEnable] = useState(discountConfigObject?.enabled)
    const [discountConfig, setDiscountConfig] = useState(discountConfigObject)
    const [prevFormState, setPrevFormState] = useState(null);
    const [isEditEnabled, setIsEditEnabled] = useState(false);
    const [button, setButtonVisibilty] = useState(false);
    const [saveButtonVisibility, setSaveButtonVisibility] = useState(false);


    useEffect(() => {
        setDiscountConfig((prev) => ({
            ...prev,
            ...discountConfigObject
        }))
    }, [discountConfigObject])



    const tableHeader: any = [
        {
            title: 'Discount Code',
            dataIndex: 'discount_name',
            key: 'discount_name',
        },
        {
            title: 'Match Type',
            dataIndex: 'match_type',
            key: 'match_type',
            render: (t, r) => {
                return <p className='uppercase'>{t === 1 ? "exact match" : t === 2 ? "starts with" : t === 3 ? "ends with" : t === 4 ? 'contains' : 'NA'}</p>
            }
        },
        {
            title: 'Shipping Charge',
            dataIndex: 'shipping_charge',
            key: 'shipping_charge',
            render: (t, r) => {
                return formatAmount(parseFloat(t) || 0, 2)
            }
        },
        {
            title: '',
            dataIndex: '',
            key: 'action',
            render: (text, record) => {
                return (
                    <Dropdown
                        placement='bottomLeft'
                        className='p-0'
                        menu={{
                            items: [
                                {
                                    key: '1',
                                    label: (
                                        <Button
                                            type='link'
                                            onClick={() => {
                                                setOpen(true)
                                                form.setFieldsValue({
                                                    overwrite_shipping_charge: { shipping_charges: record },
                                                })
                                                setPrevFormState((prev) => ({
                                                    ...prev,
                                                    shipping_charges: record
                                                }));
                                                setIsEditEnabled(true)

                                            }}
                                            className='w-full p-0 flex gap-x-1 items-center hover:text-primary-500'
                                        >
                                            <EditOutlined className='p-0' /> Edit
                                        </Button>
                                    ),
                                },
                                {
                                    key: '2',
                                    label: (
                                        <Button
                                            danger
                                            type='link'
                                            className='p-0 flex gap-x-1 items-center'
                                            onClick={() => {
                                                const filteredArrayOfShipping = discountConfig?.shipping_charges?.filter((item) => item?.discount_name?.toLowerCase().trim() !== record?.discount_name?.toLowerCase().trim());
                                                setSaveButtonVisibility(true);
                                                setDiscountConfig((prev) => ({
                                                    ...prev,
                                                    shipping_charges: filteredArrayOfShipping
                                                }));
                                            }}
                                        >
                                            <DeleteOutlined className='p-0' /> Delete
                                        </Button>
                                    ),
                                },
                            ],
                        }}
                    >
                        <img src={threeDotsIcon} alt='three dots' />
                    </Dropdown>
                )
            },
        },

    ]

    const fields: any = [
        {
            name: ["overwrite_shipping_charge", "enabled"],
            type: "switch",
            label: "Enable Discount Based Shipping",
            span: 24,
            inputProps: {
                checked: enable,
                onChange: (checked) => {
                    setEnable(checked)
                    discountConfig['enabled'] = checked
                    saveConfig({
                        overwrite_shipping_charge: discountConfig
                    })
                }

            },
            key: 1
        },
        {
            name: ['overwrite_shipping_charge', 'shipping_charges', 'discount_name'],
            label: (
                <>
                    Discount Code&nbsp;
                    <Tooltip title='Enter Discount Code generated on Shopify or GoKwik Dashboard'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </>
            ),
            type: 'input',
            colon:false,
            inputProps: {
                type: 'text'
            },
            required: true,
            rules: [
                {
                   required:true,
                   message:'Field cannot be empty' 
                },
                {
                    validator: (_, value) => {
                        const shippingLength = discountConfig?.shipping_charges?.length;
                        const prevFormDiscountName = prevFormState?.shipping_charges?.discount_name

                        if (value !== '' && !isEditEnabled && shippingLength > 0) {
                            const isNamePresent = discountConfig?.shipping_charges?.some((item) => item?.discount_name?.toLowerCase().trim() === value?.toLowerCase().trim());
                            if (isNamePresent) {
                                return Promise.reject(new Error("Discount code already used !!"))
                            }
                        }

                        if (isEditEnabled && value !== '' && value.toLowerCase().trim() !== prevFormDiscountName?.toLowerCase().trim()) {
                            const isNamePresent = discountConfig?.shipping_charges?.some((item) => item?.discount_name?.toLowerCase().trim() === value?.toLowerCase().trim());
                            if (isNamePresent) {
                                setButtonVisibilty(false);
                                return Promise.reject(new Error("Discount code already used !!"))
                            }
                            setButtonVisibilty(true);
                        }

                        return Promise.resolve()
                    }

                }
            ],
            span: 8,
            key: 2
        },
        {
            name: ['overwrite_shipping_charge', 'shipping_charges', 'match_type'],
            label: (
                <>
                    Match Type&nbsp;
                    <Tooltip title='Match type refers to how a discount code is applied based on the input provided by the customer.'>
                        <QuestionCircleOutlined />
                    </Tooltip>
                </>
            ),
            type: 'select',
            colon:false,
            inputProps: {
                options: [{ label: 'EXACT MATCH', value: 1 }, { label: 'STARTS WITH', value: 2 }, { label: 'ENDS WITH', value: 3 }, { label: 'CONTAINS', value: 4 }],
            },
            rules: [
                {
                    required: true,
                    message: 'Please select a type'
                },
            ],
            span: 4,
            key: 3
        },
        {
            name: ['overwrite_shipping_charge', 'shipping_charges', 'shipping_charge'],
            label: 'Shipping Charge',
            type: 'input',
            colon:false,
            required:false,
            inputProps: {
                type: 'number'
            },
            rules: [
                {
                    pattern: /^[0-9]*\.?[0-9]*$/,
                    message: 'Please enter a valid positive number',
                },
            ],
            span: 4,
            key: 4
        }, {
            inputProps: {
                className: 'border-gray-500 mt-7',
                onClick: () => {
                    form.submit()
                },
                type: 'default',
                children: 'Add Shipping'
            },
            type: 'button',
            span: 3,
            key: 5
        },
        {
            inputProps: {
                className: 'mt-7',
                onClick: () => {
                    discountConfig['enabled'] = discountConfig['shipping_charges']?.length > 0 ? true : false;
                    saveConfig({
                        overwrite_shipping_charge: discountConfig
                    })
                    setSaveButtonVisibility(false);
                },
                type: 'primary',
                children: 'Save',
                disabled: !saveButtonVisibility,
            },
            type: 'button',
            span: 2,
            key: 6
        },

    ]

    const getValuesFromForm = () => {
        const formData = form.getFieldsValue(true);
        const newShippingCharge = formData?.overwrite_shipping_charge?.shipping_charges;
        const discountName = prevFormState?.shipping_charges?.discount_name?.toLowerCase().trim()
        const discountArrayLength = discountConfig?.shipping_charges?.length;
        let requestObject = discountConfig?.shipping_charges && discountArrayLength > 0 ? [...discountConfig.shipping_charges] : [];

        if (!isEditEnabled) {
            requestObject.push(newShippingCharge);
        }

        if (isEditEnabled) {
            const findIndexOfArray = discountConfig?.shipping_charges?.findIndex((item) => {
                return item?.discount_name?.toLowerCase().trim() === discountName
            })

            if (findIndexOfArray !== -1) {
                requestObject[findIndexOfArray] = newShippingCharge
            }
        }

        setDiscountConfig((prevConfig) => ({
            ...prevConfig,
            shipping_charges: requestObject,
            enabled: formData?.overwrite_shipping_charge?.enabled
        }));

        form.resetFields(["overwrite_shipping_charge", "shipping_charges"]);
        setSaveButtonVisibility(true);
    };


    return (
        <Card size='small'>
            <Form
                className='w-full'
                form={form}
                layout='vertical'
                onFinish={getValuesFromForm}
                requiredMark={false}
                initialValues={{
                    overwrite_shipping_charge: {
                        enabled: discountConfigObject?.enabled
                    }
                }}
            >
                <Row gutter={[12, 24]} align='middle'>
                    {fields && fields.map(item => (
                        <Col span={item?.span} key={item?.key} className='items-center'>
                            {item.type === 'switch' && (
                                <RenderField form={form} field={item} />
                            )}
                            {item.type !== 'switch' && enable && (
                                <RenderField form={form} field={item} />
                            )}
                        </Col>
                    ))}
                </Row>
                <Drawer
                    open={open}
                    width={'35rem'}
                    onClose={() => {
                        setOpen(false)
                        setIsEditEnabled(false)
                        form.resetFields(["overwrite_shipping_charge", "shipping_charges"]);
                    }}
                    bodyStyle={{ paddingTop: '5px' }}
                    title='Edit Shipping'
                    footer={
                        <span className='flex flex-row float-end gap-2'>
                            <Button children={'Discard'} onClick={() => {
                                form.resetFields(["overwrite_shipping_charge", "shipping_charges"]);
                                setOpen(false)
                            }} />
                            <Button
                                variant='primary'
                                children='Save Changes'
                                disabled={!button}
                                onClick={() => {
                                    form.submit()
                                    setOpen(false)
                                }}
                            />
                        </span>
                    }
                    className='overflow-y-auto p-0 m-0'
                >
                    <Row gutter={[12, 12]} align='middle'>
                        {fields && fields.slice(1, 4).map(item => (
                            <Col span={8} key={item?.key}>
                                {item.type !== 'switch' && 'button' && enable && (
                                    <RenderField form={form} field={item} />
                                )}
                            </Col>
                        ))}
                    </Row>
                </Drawer>
            </Form>
            {enable && (
                <Row>
                    <Col span={24} className='mt-5'>
                        <Table className='capitalize' columns={tableHeader} pagination={{ pageSize: 8, position: ['bottomRight'] }} dataSource={discountConfig?.shipping_charges} />
                    </Col>
                </Row>
            )}

        </Card>
    )
}

export default DiscountBaseShipping