import { makeAPICall, titleCase } from '@gokwik/utilities'
import SearchProducts from '@library/components/searchProducts'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { validateGWP } from '@library/utilities/helpers/validations'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { GwpDiscount, MerchantConfig, SearchedProduct, SelectedProduct } from '@library/utilities/interface'
import {
    Button,
    Checkbox,
    Col,
    ColumnsType,
    DeleteOutlined,
    Input,
    MinusCircleOutlined,
    Modal,
    PlusCircleOutlined,
    PlusOutlined,
    Popover,
    Row,
    Select,
    Skeleton,
    Switch,
    Table,
    message,
} from 'gokwik-ui-kit'
import { useEffect, useState, useMemo, useCallback, useRef } from 'react'

const matchTypesValues = {
    'exact match': 1,
    'starts with': 2,
    'ends with': 3,
    contains: 4,
}

const matchTypes = Object.entries(matchTypesValues).reduce(
    (acc, [label, value]) => ({
        ...acc,
        [value]: titleCase(label),
    }),
    {},
)

const GwpWithAnyDiscount = () => {
    const [showModal, setShowModal] = useState(false)
    const { config, loading, refetch, saveConfig } = useMerchantConfigApi({
        useCache: true,
        configSaveUrl: APIEndPoints.updateDiscountConfig,
    })

    const [selectedProduct, setSelectedProduct] = useState<SelectedProduct[]>([])
    const [isUpdated, setIsUpdated] = useState(false)

    const [discountData, setDiscountData] = useState({
        gokwik_gwp_enabled: false,
        gwp_discount_config_data: [],
    })
    const [updatedDiscountData, setUpdatedDiscountData] = useState<Partial<MerchantConfig>>({})
    const [gwpDiscount, setGwpDiscount] = useState({
        eligibility_rule: {
            match_type: null,
        },
        discount_code: '',
        discount_rule: {
            product: [],
        },
        is_active: true,
    })

    useEffect(() => {
        setDiscountData({
            gokwik_gwp_enabled: config?.gokwik_gwp_enabled || false,
            gwp_discount_config_data: config?.gwp_discount_config_data || [],
        })
        setUpdatedDiscountData({})
    }, [config?.gwp_discount_config_data, config?.gokwik_gwp_enabled])

    const changedConfig = useMemo(
        () => ({
            gwpDiscountsChanged:
                JSON.stringify(discountData.gwp_discount_config_data) !==
                JSON.stringify(config?.gwp_discount_config_data || []),
            gwpDiscountsToggleChanged:
                JSON.stringify(discountData.gokwik_gwp_enabled) !== JSON.stringify(config?.gokwik_gwp_enabled || []),
        }),
        [
            discountData?.gwp_discount_config_data,
            discountData?.gokwik_gwp_enabled,
            config?.gwp_discount_config_data,
            config?.gokwik_gwp_enabled,
        ],
    )

    const handleSaveConfig = () => {
        let updatedFields = {}

        if (changedConfig.gwpDiscountsToggleChanged || changedConfig.gwpDiscountsChanged) {
            updatedFields = { ...updatedFields, gokwik_gwp_enabled: discountData.gokwik_gwp_enabled }
        }
        if (changedConfig.gwpDiscountsChanged) {
            updatedFields = { ...updatedFields, gwp_discount_config_data: updatedDiscountData.gwp_discount_config_data }
        }
        saveConfig(updatedFields, () => setIsUpdated(false))
    }

    const onProductListOpen = () => {
        setShowModal(true)
    }

    const onProductListClose = () => {
        setShowModal(false)
    }

    const gwpAddition = () => {
        const submit = validateGWP(gwpDiscount, discountData?.gwp_discount_config_data || [])
        if (!submit.status) return message.error(submit.msg)
        ;[setDiscountData, setUpdatedDiscountData].forEach((func) =>
            func((prev) => ({
                ...prev,
                gwp_discount_config_data: [
                    ...(prev?.gwp_discount_config_data || []),
                    {
                        ...gwpDiscount,
                        eligibility_rule: {
                            match_type: gwpDiscount?.eligibility_rule?.match_type,
                        },
                        is_active: true,
                    },
                ],
            })),
        )
        setSelectedProduct([])
        setIsUpdated(true)
        setGwpDiscount({
            discount_code: '',
            eligibility_rule: { match_type: null },
            discount_rule: { product: [] },
            is_active: false,
        })
    }

    const gwpDeletion = (deletedDiscount) => {
        const { discount_code, ...rest } = deletedDiscount

        const isOldDiscount = config?.gwp_discount_config_data.filter((itm) => itm.discount_code === discount_code)
        const discountsExceptCurrent = discountData?.gwp_discount_config_data?.filter(
            (itm) => itm.discount_code !== discount_code,
        )

        if (isOldDiscount.length) {
            setDiscountData((prev) => ({
                ...prev,
                gwp_discount_config_data: [...discountsExceptCurrent, { ...deletedDiscount, is_active: false }],
            }))
            setUpdatedDiscountData((prev) => ({
                ...prev,
                gwp_discount_config_data: [
                    ...(prev?.gwp_discount_config_data || []),
                    { ...deletedDiscount, is_active: false },
                ],
            }))
        } else {
            setDiscountData((prev) => ({
                ...prev,
                gwp_discount_config_data: discountsExceptCurrent,
            }))
            const updatedDiscountsExceptCurrent = updatedDiscountData?.gwp_discount_config_data?.filter(
                (itm) => itm.discount_code !== discount_code,
            )
            setUpdatedDiscountData((prev) => ({
                ...prev,
                gwp_discount_config_data: updatedDiscountsExceptCurrent,
            }))
        }
        setIsUpdated(true)
    }

    const columns: ColumnsType<any> = [
        {
            title: 'Match Type',
            key: 'match_type',
            render: (text, record, index) => {
                return matchTypes[record.eligibility_rule.match_type]
            },
        },
        {
            title: 'Code',
            dataIndex: 'discount_code',
            key: 'discount_code',
        },
        {
            title: 'Free Gifts',
            dataIndex: 'discount_rule.product',
            key: 'discount_rule.product',
            render: (text, record, index) => {
                return (
                    <Popover
                        content={
                            <div className='flex flex-col gap-y-4'>
                                {record.discount_rule.product.map((itm) => (
                                    <div key={itm.product_name} className='flex items-center gap-x-4'>
                                        <img
                                            src={itm.product_thumbnail_url}
                                            alt={itm.product_name}
                                            className='h-20 w-auto max-w-[60px]'
                                        />
                                        <div className='flex flex-col gap-y-2'>
                                            <p>{itm.product_name}</p>
                                            <div className='flex gap-x-4 w-full '>
                                                <span>
                                                    <strong className='font-semibold'>₹</strong>
                                                    {itm.price}
                                                </span>
                                                <span>
                                                    <strong className='font-semibold'>Qty</strong> {itm.quantity}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    >
                        <span className='underline'>{`${record.discount_rule.product.length} Items Selected`}</span>
                    </Popover>
                )
            },
        },
        ...(discountData.gokwik_gwp_enabled
            ? [
                  {
                      title: '',
                      key: 'action',
                      render: (text, record, index) => {
                          return <DeleteOutlined className='text-error-500' onClick={() => gwpDeletion(record)} />
                      },
                  },
              ]
            : []),
    ]

    console.log({ gwpDiscount })

    return (
        <Row gutter={[16, 24]} align={'bottom'}>
            <Col span={24}>
                <span className='mr-1'>{discountData.gokwik_gwp_enabled ? 'Disable' : 'Enable'} GWP Discount</span>{' '}
                <Switch
                    checked={discountData.gokwik_gwp_enabled}
                    onChange={(checked) => {
                        setIsUpdated(true)
                        setDiscountData((prev) => ({
                            ...prev,
                            gokwik_gwp_enabled: checked,
                        }))
                    }}
                />
            </Col>
            <Col span={4}>
                <Select
                    label='Match Type*'
                    value={gwpDiscount.eligibility_rule.match_type}
                    onChange={(val) =>
                        setGwpDiscount((prev) => ({
                            ...prev,
                            eligibility_rule: {
                                ...prev.eligibility_rule,
                                match_type: val,
                            },
                        }))
                    }
                    className='w-full'
                    placeholder='Select type'
                    disabled={!discountData.gokwik_gwp_enabled}
                    options={Object.entries(matchTypesValues).map(([label, value]) => ({
                        label: titleCase(label),
                        value,
                    }))}
                />
            </Col>
            <Col span={4}>
                <Input
                    placeholder={'Enter Shopify discount code'}
                    label={'Discount Code*'}
                    value={gwpDiscount.discount_code}
                    onChange={(e) =>
                        setGwpDiscount((prev) => ({
                            ...prev,
                            discount_code: e.target.value,
                        }))
                    }
                    disabled={!discountData.gokwik_gwp_enabled}
                />
            </Col>
            <Col span={4}>
                <Input
                    label='Free Gifts'
                    placeholder=''
                    value={
                        gwpDiscount?.discount_rule?.product?.length >= 1
                            ? `${gwpDiscount?.discount_rule?.product?.length} Items Selected`
                            : 'Select free gifts'
                    }
                    onClick={onProductListOpen}
                    disabled={!discountData.gokwik_gwp_enabled}
                />
            </Col>
            <Col span={4}>
                <Button onClick={gwpAddition} disabled={!discountData.gokwik_gwp_enabled}>
                    <PlusOutlined /> Add New Discount
                </Button>
            </Col>

            <Col span={24}>
                <Table
                    columns={columns}
                    dataSource={(discountData.gwp_discount_config_data || []).filter(
                        (discount: GwpDiscount) => discount.is_active,
                    )}
                    pagination={false}
                />
            </Col>

            <Col>
                <Button disabled={!isUpdated} variant='primary' onClick={handleSaveConfig}>
                    Save
                </Button>
            </Col>

            <SearchProducts
                onClose={onProductListClose}
                onConfirm={(selectedProducts) => {
                    const temp = selectedProducts.map((item) => ({
                        product_id: item?.product_id,
                        variant_id: item?.variant_id,
                        quantity: item?.quantity,
                        product_name: item?.product_name,
                        product_thumbnail_url: item?.product_thumbnail_url,
                        price: item?.price,
                    }))
                    setSelectedProduct(selectedProducts)
                    setGwpDiscount((prev) => ({
                        ...prev,
                        discount_rule: {
                            product: temp,
                        },
                        is_active: false,
                    }))
                    onProductListClose()
                }}
                showModal={showModal}
                alreadySelected={selectedProduct}
            />
        </Row>
    )
}

export default GwpWithAnyDiscount
