import { useState, useEffect } from 'react'
import {
    Col,
    ColorPicker,
    Input,
    Row,
    Switch,
    Button,
    Tooltip,
    QuestionCircleOutlined,
    message,
    Select,
    DeleteOutlined,
} from 'gokwik-ui-kit'
import { IShopifyNewCheckoutUIConfig } from '@library/utilities/interface'
import LoginPagePreview from './loginPagePreview'
import { defaultShopifyNewCheckoutConfig, loginPageTooltips } from './constants'

const bannerOptions = [
    { value: 'banner1', label: 'Banner 1' },
    { value: 'banner2', label: 'Banner 2' },
    { value: 'banner3', label: 'Banner 3' },
]

const splittedTitle = (title: string) => {
    return title?.split('|')
}

const LoginPageCustomisation = ({ config, saveConfig, fireDiscardSaveEvent }) => {
    const [isUpdated, setIsUpdated] = useState<boolean>(false)
    const [updatedCheckoutUIConfig, setUpdatedCheckoutUIConfig] = useState<IShopifyNewCheckoutUIConfig>(
        defaultShopifyNewCheckoutConfig,
    )
    const [bannerTitle, setBannerTitle] = useState<string>('')
    const [bannerType, setBannerType] = useState<string>('')
    const initialBanners = updatedCheckoutUIConfig?.textConfig?.otherSections?.brandBanner || []

    initialBanners?.map((banner, index) => {
        if (!banner.includes('|')) {
            initialBanners[index] = `banner1|${banner}`
        }
    })

    const availableBannerOptions = bannerOptions?.filter((option) => {
        return !initialBanners?.some((banner) => splittedTitle(banner)[0] === option?.value)
    })
    // Use useEffect to update bannerType whenever bannerOpt changes
    const handleAddBanner = () => {
        if (bannerTitle?.trim()) {
            const newBanner = `${bannerType}|${bannerTitle}`
            // Update the brandBanner in updatedCheckoutUIConfig
            setUpdatedCheckoutUIConfig((prev) => ({
                ...prev,
                textConfig: {
                    ...prev?.textConfig,
                    otherSections: {
                        ...prev?.textConfig?.otherSections,
                        brandBanner: [...prev.textConfig?.otherSections?.brandBanner, newBanner],
                    },
                },
            }))
            setBannerTitle('')
            setIsUpdated(true)
        }
    }

    const getOrderedBanners = (initialBanners, bannerOptions) => {
        return bannerOptions
            .map((option) => {
                return initialBanners?.find((banner) => splittedTitle(banner)[0] === option?.value)
            })
            .filter(Boolean) // .filter(Boolean) removes any undefined or falsy values, ensuring only valid banners remain.
    }

    const handleDeleteBanner = (index: number) => {
        setUpdatedCheckoutUIConfig((prev) => ({
            ...prev,
            textConfig: {
                ...prev?.textConfig,
                otherSections: {
                    ...prev.textConfig?.otherSections,
                    brandBanner: prev?.textConfig?.otherSections?.brandBanner?.filter((_, i) => i !== index),
                },
            },
        }))
        setIsUpdated(true)
    }

    useEffect(() => {
        if (availableBannerOptions?.length > 0) {
            setBannerType(availableBannerOptions[0]?.value)
        }
    }, [availableBannerOptions])

    useEffect(() => {
        if (config?.newCheckoutUIConfig) {
            setUpdatedCheckoutUIConfig(config.newCheckoutUIConfig)
        }
    }, [config?.newCheckoutUIConfig])

    const validateLoginPageConfig = () => {
        if (
            updatedCheckoutUIConfig?.uiConfig?.showBrandBanner &&
            updatedCheckoutUIConfig?.textConfig?.otherSections?.brandBanner?.length < 1
        ) {
            return {
                msg: 'Please add banner text',
                status: false,
            }
        }
        if (updatedCheckoutUIConfig?.textConfig?.login?.cta?.trim()?.length < 1) {
            return {
                msg: 'Please add CTA button text',
                status: false,
            }
        }
        if (updatedCheckoutUIConfig?.textConfig?.login?.checkboxText?.trim()?.length < 1) {
            return {
                msg: 'Please add consent box text',
                status: false,
            }
        }
        return {
            msg: 'newUiLoginPageConfig validation success',
            status: true,
        }
    }

    return (
        <div>
            <Row gutter={16}>
                <Col span={16}>
                    <div className='bg-gray-100'>
                        {/* Banner Section */}
                        <div className='mb-6 bg-white p-4 rounded-lg shadow-md mx-auto'>
                            <div className='flex items-center justify-between mb-2'>
                                <p className='mb-2 text-base font-semibold'>
                                    Banner Configuration{' '}
                                    <Tooltip title={<>{loginPageTooltips.CtaButton}</>}>
                                        <QuestionCircleOutlined className='opacity-40' />
                                    </Tooltip>
                                </p>
                                <div className='flex gap-2'>
                                    <Switch
                                        checked={updatedCheckoutUIConfig?.uiConfig?.showBrandBanner}
                                        onChange={(checked) => {
                                            setUpdatedCheckoutUIConfig((prev) => ({
                                                ...prev,
                                                uiConfig: { ...prev.uiConfig, showBrandBanner: checked },
                                            }))
                                            setIsUpdated(true)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='flex gap-4'>
                                <div className='flex flex-col'>
                                    <div>
                                        <label className='block mb-2 text-sm font-medium text-gray-700'>
                                            Banner Type
                                        </label>
                                        <Select
                                            placeholder='Select type'
                                            className='w-full mb-2'
                                            value={bannerType}
                                            options={availableBannerOptions}
                                            optionRender={(option) => option?.label}
                                            onChange={(value) => setBannerType(value)}
                                            disabled={availableBannerOptions?.length === 0}
                                        />
                                    </div>
                                    {initialBanners.length > 0 &&
                                        getOrderedBanners(initialBanners, bannerOptions)?.map(
                                            (matchedBanner, index) => (
                                                <div className='font-normal px-2 mb-1' key={index}>
                                                    {
                                                        bannerOptions?.find(
                                                            (option) =>
                                                                option?.value === splittedTitle(matchedBanner)[0],
                                                        )?.label
                                                    }
                                                </div>
                                            ),
                                        )}
                                </div>

                                <div className='w-1/2'>
                                    <div className='flex flex-col'>
                                        <div>
                                            <div className='flex justify-between'>
                                                <label className='block mb-2 text-sm font-medium text-gray-700'>
                                                    Banner Title
                                                </label>
                                                <Button
                                                    onClick={handleAddBanner}
                                                    size='small'
                                                    variant='primary'
                                                    className='text-white rounded-md'
                                                    disabled={!availableBannerOptions?.length}
                                                >
                                                    Add
                                                </Button>
                                            </div>
                                            <Input
                                                maxLength={40}
                                                showCount
                                                type='text'
                                                value={bannerTitle}
                                                onChange={(e) => setBannerTitle(e.target.value)}
                                                placeholder='Enter Banner Title'
                                                className='w-full border mb-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400'
                                            />
                                        </div>
                                        {initialBanners.length > 0 &&
                                            getOrderedBanners(initialBanners, bannerOptions)?.map(
                                                (matchedBanner, index: number) => (
                                                    <div className='flex justify-between w-full mb-1' key={index}>
                                                        <div className='font-normal pl-2'>
                                                            {splittedTitle(matchedBanner)[1]}
                                                        </div>
                                                        <DeleteOutlined
                                                            className='text-error-500 ml-2 cursor-pointer'
                                                            onClick={() =>
                                                                handleDeleteBanner(
                                                                    initialBanners?.indexOf(matchedBanner),
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                ),
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* CTA Button Section */}
                        <div className='mb-6 bg-white p-4 rounded-lg shadow-md max-w-4xl mx-auto'>
                            <p className='mb-2 text-base font-semibold'>
                                CTA Button{' '}
                                <Tooltip title={<>{loginPageTooltips.CtaButton}</>}>
                                    <QuestionCircleOutlined className='opacity-40' />
                                </Tooltip>
                            </p>
                            <div>
                                <label className='block mb-1 text-sm font-medium text-gray-700'>Button Text</label>
                                <Input
                                    value={updatedCheckoutUIConfig?.textConfig?.login?.cta}
                                    placeholder='Eg: Proceed'
                                    maxLength={18}
                                    onChange={(e) => {
                                        const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '')
                                        setUpdatedCheckoutUIConfig((prev) => ({
                                            ...prev,
                                            textConfig: {
                                                ...prev.textConfig,
                                                login: {
                                                    ...prev.textConfig.login,
                                                    cta: sanitizedValue,
                                                },
                                            },
                                        }))
                                        setIsUpdated(true)
                                    }}
                                    showCount
                                />
                            </div>
                        </div>

                        {/* Guest Checkout Section */}
                        <div className='mb-6 bg-white p-4 rounded-lg shadow-md max-w-4xl mx-auto'>
                            <div className='flex items-center justify-between'>
                                <p className='text-base font-semibold'>
                                    Guest Checkout{' '}
                                    <Tooltip title={<>{loginPageTooltips.GuestCheckout}</>}>
                                        <QuestionCircleOutlined className='opacity-40' />
                                    </Tooltip>
                                </p>
                                <Switch
                                    checked={updatedCheckoutUIConfig?.uiConfig?.showGuestCheckoutOption}
                                    onChange={(checked) => {
                                        setUpdatedCheckoutUIConfig((prev) => ({
                                            ...prev,
                                            uiConfig: {
                                                ...prev.uiConfig,
                                                showGuestCheckoutOption: checked,
                                            },
                                        }))
                                        setIsUpdated(true)
                                    }}
                                />
                            </div>
                        </div>

                        {/* Consent Box Text Section */}
                        <div className='bg-white p-4 rounded-lg shadow-md max-w-4xl mx-auto'>
                            <p className='mb-2 text-base font-semibold'>
                                Consent Box Text{' '}
                                <Tooltip title={<>{loginPageTooltips.ConsentBox}</>}>
                                    <QuestionCircleOutlined className='opacity-40' />
                                </Tooltip>
                            </p>

                            <div>
                                <label className='block mb-1 text-sm font-medium text-gray-700'>Button Text</label>
                                <Input
                                    placeholder='Eg: Keep me posted about new deals'
                                    maxLength={40}
                                    value={updatedCheckoutUIConfig?.textConfig?.login?.checkboxText}
                                    onChange={(e) => {
                                        const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '')
                                        setUpdatedCheckoutUIConfig((prev) => ({
                                            ...prev,
                                            textConfig: {
                                                ...prev.textConfig,
                                                login: {
                                                    ...prev.textConfig.login,
                                                    checkboxText: sanitizedValue,
                                                },
                                            },
                                        }))
                                        setIsUpdated(true)
                                    }}
                                    showCount
                                />
                            </div>
                        </div>
                        {isUpdated && (
                            <div className='mt-4 justify-end flex items-center gap-x-3'>
                                <Button
                                    disabled={!isUpdated}
                                    onClick={() => {
                                        //reset
                                        setUpdatedCheckoutUIConfig(
                                            config?.newCheckoutUIConfig ?? defaultShopifyNewCheckoutConfig,
                                        )
                                        setIsUpdated(false)
                                        fireDiscardSaveEvent();
                                    }}
                                >
                                    Discard
                                </Button>
                                <Button
                                    type='primary'
                                    disabled={!isUpdated}
                                    onClick={() => {
                                        const validation = validateLoginPageConfig()
                                        if (validation.status) {
                                            // Trim values of updated config
                                            const trimmedConfig = { ...updatedCheckoutUIConfig }
                                            if (trimmedConfig.textConfig?.login?.cta) {
                                                trimmedConfig.textConfig.login.cta =
                                                    trimmedConfig.textConfig.login.cta.trim()
                                            }
                                            if (trimmedConfig.textConfig?.login?.checkboxText) {
                                                trimmedConfig.textConfig.login.checkboxText =
                                                    trimmedConfig.textConfig.login.checkboxText.trim()
                                            }

                                            //update config
                                            saveConfig(
                                                {
                                                    newCheckoutUIConfig: trimmedConfig,
                                                },
                                                () => setIsUpdated(false),
                                            )
                                        } else {
                                            message.error(validation.msg)
                                        }
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                        )}
                    </div>
                </Col>
                <Col span={8}>
                    <LoginPagePreview newCheckoutUIConfig={updatedCheckoutUIConfig} config={config} />{' '}
                </Col>
            </Row>
        </div>
    )
}

export default LoginPageCustomisation
