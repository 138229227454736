import {
    Button,
    Card,
    Col,
    DeleteOutlined,
    Drawer,
    Form,
    InfoCircleOutlined,
    Input,
    message,
    Radio,
    Row,
    Switch,
    Tooltip,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import BookDownload from '@library/images/book-download.svg'
import Shopify from '@library/images/PixelIcons/reMarketingTools/shopify.svg'
import Klaviyo from '@library/images/PixelIcons/reMarketingTools/klaviyo.svg'
import Netcore from '@library/images/PixelIcons/reMarketingTools/netcore.svg'
import Clevertap from '@library/images/PixelIcons/reMarketingTools/clevertap.svg'
import Contlo from '@library/images/PixelIcons/reMarketingTools/contlo.svg'
import MoEngage from '@library/images/PixelIcons/reMarketingTools/moEngage.svg'
import WebEngage from '@library/images/PixelIcons/reMarketingTools/webEngage.svg'
import Wigzo from '@library/images/PixelIcons/reMarketingTools/wigzo.svg'
import Growlytics from '@library/images/PixelIcons/reMarketingTools/growlytics.svg'
import Omnisend from '@library/images/PixelIcons/reMarketingTools/omnisend.svg'
import Bik from '@library/images/PixelIcons/reMarketingTools/bik.svg'
import AbcWebhooks from '@library/images/PixelIcons/reMarketingTools/abcWebhook.svg'
import RenderForm from '@library/components/integrations/formRender'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { useAppDispatch } from '@library/utilities/hooks'
import { saveCreds } from '@store/integrations'
import { selectMerchantCreds } from '@store/integrations/selectors'
import { useSelector } from 'react-redux'
const ReMarketingTools = () => {
    const [drawer, setDrawer] = useState(false)
    const [title, setTitle] = useState('')
    const [selectedKey, setSelectedKey] = useState('')
    const [form] = Form.useForm()
    const { config, refetch, saveConfig } = useMerchantConfigApi()
    const customConfig = config?.custom_config
    const dispatch = useAppDispatch()
    const merchantCredentials = useSelector(selectMerchantCreds)

    const handleSwitchChange = async (key, checked, item) => {
        if (!checked) {
            let payload = {}
            if (key === 'abc_webhook_config') {
                await dispatch(saveCreds({ [key.toLowerCase()]: null }, true))
            } else {
                if (key === 'shopify') {
                    payload = {
                        enable_shopify_abc: checked,
                    }
                } else {
                    payload = {
                        custom_config: {
                            ...config.custom_config,
                            pixels: config.custom_config.pixels?.filter((pixel) => pixel !== key),
                        },
                    }
                }
                saveConfig(payload)
            }
        }
        if (checked && item.customize) {
            setSelectedKey(key)
            setDrawer(true)
            setTitle(item.title)
        } else if (checked) {
            if (key === 'shopify') {
                saveConfig({
                    enable_shopify_abc: checked,
                })
            } else if (key !== 'abc_webhook_config') {
                saveConfig({
                    custom_config: {
                        ...config.custom_config,
                        pixels: [...(config.custom_config.pixels || []), key],
                    },
                })
            }
        }
    }

    const handleCustomizeClick = (key, title) => {
        setSelectedKey(key)
        setTitle(title)
        setDrawer(true)
    }
    const data = [
        {
            key: 'shopify',
            title: 'Send Abandoned checkouts to Shopify',
            icon: Shopify,
            checked: config?.enable_shopify_abc,
            customize: false,
        },
        {
            key: '_learnq',
            title: 'Klaviyo',
            checked: config?.custom_config.pixels?.includes('_learnq'),
            icon: Klaviyo,
            customize: false,
        },
        {
            key: 'netcore',
            title: 'Netcore',
            checked: config?.custom_config.pixels?.includes('netcore'),
            icon: Netcore,
            customize: true,
        },
        {
            key: 'clevertap',
            title: 'Clevertap',
            checked: config?.custom_config.pixels?.includes('clevertap'),
            icon: Clevertap,
            customize: true,
        },
        {
            key: 'contlo',
            title: 'Contlo',
            checked: config?.custom_config.pixels?.includes('contlo'),
            icon: Contlo,
            customize: false,
        },
        {
            key: 'Moengage',
            title: 'MoEngage',
            checked: config?.custom_config.pixels?.includes('Moengage'),
            icon: MoEngage,
            customize: true,
        },
        {
            key: 'webengage',
            title: 'WebEngage',
            checked: config?.custom_config.pixels?.includes('webengage'),
            icon: WebEngage,
            customize: true,
        },
        {
            key: 'wigzo',
            title: 'Wigzo',
            checked: config?.custom_config.pixels?.includes('wigzo'),
            icon: Wigzo,
            customize: false,
        },
        {
            key: 'growlytics',
            title: 'Growlytics',
            checked: config?.custom_config.pixels?.includes('growlytics'),
            icon: Growlytics,
            customize: true,
        },
        {
            key: 'omnisend',
            title: 'Omnisend',
            checked: config?.custom_config.pixels?.includes('omnisend'),
            icon: Omnisend,
            customize: true,
        },
        {
            key: 'bik',
            title: 'Bik',
            checked: config?.custom_config.pixels?.includes('bik'),
            icon: Bik,
            customize: true,
        },
        {
            key: 'abc_webhook_config',
            title: 'Abandoned Cart Configure Webhooks',
            checked: !!merchantCredentials?.abc_webhook_config,
            icon: AbcWebhooks,
            customize: true,
        },
    ]
    const handleSave = async () => {
        try {
            await form.validateFields()

            const { custom_config = {}, ...rest } = form.getFieldsValue()

            if (custom_config.analytics_pixel_data) {
                custom_config.analytics_pixel_data = {
                    ...(customConfig.analytics_pixel_data || {}),
                    [selectedKey.toLowerCase()]: {
                        ...custom_config.analytics_pixel_data?.[selectedKey.toLowerCase()],
                    },
                }
                custom_config.analytics_pixel = {
                    ...(customConfig.analytics_pixel || {}),
                    [selectedKey.toLowerCase()]: custom_config.analytics_pixel_data?.[selectedKey.toLowerCase()].value,
                }
            }
            if (
                !['shopify', 'abc_webhook_config'].includes(selectedKey) &&
                !customConfig.pixels.includes(selectedKey)
            ) {
                custom_config.pixels = [...(customConfig.pixels || []), selectedKey]
            }
            selectedKey !== 'abc_webhook_config' &&
                saveConfig({
                    custom_config: {
                        ...customConfig,
                        ...custom_config,
                    },
                })
            let payload = rest[selectedKey.toLocaleLowerCase()]
            if (selectedKey === 'abc_webhook_config') {
                payload = {
                    url: payload.url,
                    ...(payload.kwikchat_enabled && { provider: 'kwikchat', multiple_abc_webhooks: 'true' }),
                }
            } else if (selectedKey === 'bik') {
                payload = {
                    api_secret: `Basic ${payload.api_secret}`,
                }
            }
            if (payload) {
                const res = await dispatch(saveCreds({ [selectedKey.toLowerCase()]: payload }, true))
                if (res) {
                    setDrawer(false)
                } else {
                    message.error('Failed to save credentials')
                }
            } else {
                setDrawer(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const populateForm = () => {
        if (merchantCredentials) {
            form.setFieldsValue(
                data.reduce((acc, item) => {
                    acc[item.key.toLowerCase()] = merchantCredentials[item.key]
                    if (item.key === 'abc_webhook_config' && merchantCredentials?.[item.key]?.provider === 'kwikchat') {
                        acc[item.key.toLowerCase()] = {
                            ...merchantCredentials[item.key],
                            kwikchat_enabled: true,
                        }
                    } else if (item.key === 'bik')
                        acc[item.key.toLowerCase()] = {
                            api_secret: merchantCredentials[item.key]?.api_secret?.replace('Basic ', ''),
                        }
                    return acc
                }, {}),
            )
        }
        form.setFieldsValue({
            custom_config: {
                analytics_pixel_data: customConfig?.analytics_pixel_data,
                is_clevertap_s2s_enabled: customConfig?.is_clevertap_s2s_enabled,
            },
        })
    }
    useEffect(() => {
        populateForm()
    }, [config, merchantCredentials])
    return (
        <>
            <Row gutter={[16, 16]} className='mb-20'>
                <Col span={18}>
                    <div className='flex items-center text-lg leading-7'>
                        <p className='text-lg font-semibold'>Re-Marketing Tools</p>
                    </div>
                </Col>
                {data.map((item) => (
                    <Col span={24} key={item.key}>
                        <Card className='custom-card'>
                            <Row align='middle'>
                                <Col flex='40px'>
                                    <img src={item.icon} alt={item.title} />
                                </Col>
                                <Col flex='auto'>
                                    <p className='font-semibold'>{item.title}</p>
                                </Col>
                                <Col>
                                    {item.customize === true && item.checked && (
                                        <Button
                                            className='mr-4 focus:outline-none'
                                            onClick={() => handleCustomizeClick(item.key, item.title)}
                                        >
                                            Customise
                                        </Button>
                                    )}
                                    <Switch
                                        className='focus:outline-none'
                                        checked={item.checked}
                                        onChange={(checked) => handleSwitchChange(item.key, checked, item)}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Drawer
                title={title}
                width={500}
                open={drawer}
                onClose={() => setDrawer(false)}
                footer={
                    <div className='flex justify-end space-x-4'>
                        <Button
                            type='default'
                            onClick={() => {
                                populateForm()
                                setDrawer(false)
                            }}
                        >
                            Discard
                        </Button>
                        <Button type='primary' onClick={handleSave}>
                            Save Changes
                        </Button>
                    </div>
                }
            >
                <RenderForm fieldKey={selectedKey} form={form} />
            </Drawer>
        </>
    )
}

export default ReMarketingTools
