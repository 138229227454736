import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { manualDiscountInitialValues } from '@library/utilities/constants/constants'
import dayjs from 'dayjs'
import { InsertRowRightOutlined, message } from 'gokwik-ui-kit'
import { fetchShopifyCollectionsOrProducts } from '.'
import { SearchedProduct } from '@library/utilities/interface'

const customerEligibility = {
    all: 'all',
    newCustomers: 'new',
    existingCustomers: 'repeat',
}

const mapCollections = (collections: any) => ({
    collectionData: collections.data,

    add: collections.add,
    remove: collections.remove,
})

const fetchAllProductsByIds = async (products: any) => {
    const productsArray = await Promise.all(
        products.map(
            async (product) =>
                await fetchShopifyCollectionsOrProducts({
                    searchType: 'products',
                    title: product.id || product.name.trim(),
                }).then((response) => response.data.data),
        ),
    )
    return products.map((product, i) => ({
        product_id: product.id || product.name.trim(),
        title: product.name,
        ...productsArray[i][0],
        id: productsArray[i][0] ? productsArray[i][0].product_id : product.id || product.name.trim(),
        variants: product.type === 'product' ? productsArray[i].map((variant) => variant.id) : product.ids,
        totalVariants: productsArray[i].length,
        minPrice: productsArray[i].reduce((min, p) => (p?.price < min ? p?.price : min), productsArray[i][0]?.price),
    }))
}
const responseMapProducts = async (items: any, isShopify = false) => {
    let productsToSearch = []
    if (isShopify) {
        const productIds = items?.products?.edges.map((product: any) => {
            product = product.node
            return { id: product.id.split('Product/')[1], type: 'product' }
        })
        productsToSearch = [...(productIds || [])]
        const variants = items?.productVariants?.edges.reduce((result, product: any) => {
            product = product.node
            const name = product.displayName.split('-')[0]
            return {
                ...result,
                [name]: {
                    ids: [...(result[name]?.ids || []), product.id.split('ProductVariant/')[1]],
                    type: 'variant',
                    name: name,
                },
            }
        }, {})
        productsToSearch = [...productsToSearch, ...Object.values(variants || {})]
    } else {
        const productIds = items?.products.productData?.map((product: any) => {
            return { id: product.id.split('Product/')[1], type: 'product' }
        })
        productsToSearch = [...(productIds || [])]
        const variants = items?.products.variantData?.reduce((result, product: any) => {
            const name = product.title.split('-')[0]
            return {
                ...result,
                [name]: {
                    ids: [...(result[name]?.ids || []), product.id.split('ProductVariant/')[1]],
                    type: 'variant',
                    name: name,
                },
            }
        }, {})
        productsToSearch = [...productsToSearch, ...Object.values(variants || {})]
    }
    const res = await fetchAllProductsByIds(productsToSearch)
    return {
        products: {
            data: res,
        },
    }
}

const mapProducts = (products: any, existingProducts: any) => {
    function compareProductArrays(array1, array2 = []) {
        const result = {
            productsToAdd: [],
            productsToRemove: [],
            variantData: [],
            productVariantsToAdd: [],
            productVariantsToRemove: [],
            productData: [],
        }

        const array2Map = new Map(array2.map((item) => [item.product_id, item]))

        array1.forEach((product) => {
            const matchingProduct = array2Map.get(product.product_id)
            const productId = `gid://shopify/Product/${product.product_id}`

            if (!matchingProduct) {
                // Product in array1 but not in array2
                if (product.variants.length === product.totalVariants) {
                    result.productsToAdd.push(productId)
                    result.productData.push({
                        id: productId,
                        title: product.product_name,
                        url: product.product_thumbnail_url,
                    })
                } else {
                    handleVariantMismatch(product, null, result)
                }
            } else if (
                product.variants.length === matchingProduct.variants.length &&
                product.variants.length === product.totalVariants
            ) {
                // Product exists in both arrays with matching variant counts
                result.productData.push({
                    id: productId,
                    title: product.product_name,
                    url: product.product_thumbnail_url,
                })
            } else {
                // Variant count mismatch
                handleVariantMismatch(product, matchingProduct, result)
            }

            array2Map.delete(product.id)
        })

        // Process remaining products in array2 (to be removed)
        array2Map.forEach((product) => {
            const productId = `gid://shopify/Product/${product.product_id}`
            result.productsToRemove.push(productId)
            result.productVariantsToRemove.push(...product.variants.map((v) => `gid://shopify/ProductVariant/${v}`))
        })

        return result
    }

    function handleVariantMismatch(product1, product2, result) {
        const productId = `gid://shopify/Product/${product1.product_id}`
        product2 && product2.variants.length === product2.totalVariants && result.productsToRemove.push(productId)

        const variantSet2 = new Set(product2 ? product2.variants : [])

        product1.variants.forEach((variant) => {
            const variantId = `gid://shopify/ProductVariant/${variant}`
            if (!variantSet2.has(variant)) {
                result.productVariantsToAdd.push(variantId)
            }
            result.variantData.push({
                id: variantId,
                title: `${product1.product_name} - ${product1.variant_name}`,
                url: product1.product_thumbnail_url,
            })
        })

        if (product2) {
            product2.variants.forEach((variant) => {
                if (!product1.variants.includes(variant)) {
                    result.productVariantsToRemove.push(`gid://shopify/ProductVariant/${variant}`)
                }
            })
        }
    }
    return compareProductArrays(products.data, existingProducts?.data)
}

export const requestMapDiscount = {
    setup: async (data: any, previous: any) => {
        try {
            let result: {
                [key: string]: any
            } = {
                title: data.discountCode,
                discount_method: 'manual',
                sub_method: data.subMethod,
                status: data.status || (data.shopifyDiscountId && 'ACTIVE') || 'DRAFT',
                is_imported: data.isImported || false,
                page_type: 'SETUP_PAGE',
                shopify_discount_id: data.shopifyDiscountId,
            }
            if (data.isImported) {
                result.shopify_discount_id = data.shopifyDiscountId
            }
            if (['cartItemsDiscount', 'bundleDiscount', 'tieredDiscount'].includes(data.discountType)) {
                result.discount_type = 'DiscountCodeBasic'
            } else if (data.discountType === 'discountedCartItems') {
                result.discount_type = 'DiscountCodeBxgy'
            } else if (data.discountType === 'shippingDiscount') {
                result.discount_type = 'DiscountCodeFreeShipping'
            }
            if (data.discountType !== 'discountedCartItems' && data.minimumRequirements !== 'noMinimum') {
                if (data.minimumRequirements === 'minimumAmount') {
                    result.minimum_requirement = {
                        subtotal: { greaterThanOrEqualToSubtotal: '' + data.discountValue },
                    }
                } else {
                    result.minimum_requirement = {
                        quantity: {
                            greaterThanOrEqualToQuantity: '' + data.discountValue,
                        },
                    }
                }
            } else result.minimum_requirement = null
            if (data.discountType === 'discountedCartItems') {
                const existingProductsTeRemove = previous.discountApplicableOnItems?.products?.data?.length
                    ? mapProducts(previous.discountApplicableOnItems?.products, [])
                    : null
                result.customer_buys = {
                    items: {
                        ...(data.discountApplicableOn === 'specificProducts'
                            ? {
                                  products: mapProducts(
                                      data.discountApplicableOnItems.products,
                                      previous.discountApplicableOnItems?.products || [],
                                  ),
                                  ...(previous.discountApplicableOnItems?.collections?.data?.length && {
                                      collections: {
                                          remove: previous.discountApplicableOnItems?.collections?.data?.map(
                                              (collection) => collection.id,
                                          ),
                                      },
                                  }),
                              }
                            : data.discountApplicableOn === 'specificCollections'
                            ? {
                                  collections: data.discountApplicableOnItems.collections?.data?.length
                                      ? mapCollections(data.discountApplicableOnItems.collections)
                                      : null,
                                  ...(existingProductsTeRemove && {
                                      products: {
                                          productsToRemove: existingProductsTeRemove.productsToAdd,
                                          productVariantsToRemove: existingProductsTeRemove.productVariantsToAdd,
                                      },
                                  }),
                              }
                            : {}),
                    },

                    ...(data.minimumRequirements !== 'noMinimum' && {
                        value: {
                            ...(data.minimumRequirements === 'minimumAmount'
                                ? { amount: '' + data.discountValue }
                                : { quantity: '' + data.discountValue }),
                        },
                    }),
                }
                const existingDiscountItemProductsTeRemove = previous.discountedCartDiscountItems?.products?.data
                    ?.length
                    ? mapProducts(previous.discountedCartDiscountItems?.products, [])
                    : null
                result.customer_gets = {
                    items: {
                        ...(data.discountedCartItemsType === 'specificProducts'
                            ? {
                                  products: mapProducts(
                                      data.discountedCartDiscountItems.products,
                                      previous.discountedCartDiscountItems?.products || [],
                                  ),
                                  ...(previous.discountedCartDiscountItems?.collections?.data?.length && {
                                      collections: {
                                          remove: previous.discountedCartDiscountItems?.collections?.data?.map(
                                              (collection) => collection.id,
                                          ),
                                      },
                                  }),
                              }
                            : {
                                  collections: mapCollections(data.discountedCartDiscountItems.collections),
                                  ...(existingDiscountItemProductsTeRemove && {
                                      products: {
                                          productsToRemove: existingDiscountItemProductsTeRemove.productsToAdd,
                                          productVariantsToRemove:
                                              existingDiscountItemProductsTeRemove.productVariantsToAdd,
                                      },
                                  }),
                              }),
                    },
                    value: {
                        discountOnQuantity: {
                            quantity: '' + data.discountedCartItemsQuantity,
                            effect: {
                                ...(data.discountedCartItemsDiscountType === 'percentage'
                                    ? {
                                          percentage: parseFloat(
                                              (+data.discountedCartItemsDiscountValue / 100).toFixed(2),
                                          ),
                                      }
                                    : { amount: '' + data.discountedCartItemsDiscountValue }),
                            },
                        },
                    },
                }
                result.uses_per_order_limit = data.discountedCartItemsUsesOrderLimit
                result.is_gwp = true
            } else if (data.discountType !== 'shippingDiscount') {
                const existingProductsTeRemove = previous.discountApplicableOnItems?.products?.data?.length
                    ? mapProducts(previous.discountApplicableOnItems?.products, [])
                    : null
                result.customer_gets = {
                    items: {
                        ...(data.discountApplicableOn === 'allProducts'
                            ? { all: true }
                            : data.discountApplicableOn === 'specificProducts'
                            ? {
                                  products: mapProducts(
                                      data.discountApplicableOnItems.products,
                                      previous.discountApplicableOnItems?.products || [],
                                  ),
                                  ...(previous.discountApplicableOnItems?.collections?.data?.length && {
                                      collections: {
                                          remove: previous.discountApplicableOnItems?.collections?.data?.map(
                                              (collection) => collection.id,
                                          ),
                                      },
                                  }),
                              }
                            : data.discountApplicableOn === 'specificCollections'
                            ? {
                                  collections: mapCollections(data.discountApplicableOnItems.collections),
                                  ...(existingProductsTeRemove && {
                                      products: {
                                          productsToRemove: existingProductsTeRemove.productsToAdd,
                                          productVariantsToRemove: existingProductsTeRemove.productVariantsToAdd,
                                      },
                                  }),
                              }
                            : {}),
                    },
                    ...(data.discountType === 'cartItemsDiscount'
                        ? {
                              value: {
                                  ...(data.discountCartItemType === 'percentage'
                                      ? {
                                            percentage: parseFloat(
                                                (+data.discountCartItemsDiscountValue / 100).toFixed(2),
                                            ),
                                        }
                                      : {
                                            discountAmount: {
                                                appliesOnEachItem: !data.discountCartItemsOncePerOrder,
                                                amount: '' + data.discountCartItemsDiscountValue,
                                            },
                                        }),
                              },
                          }
                        : {
                              value: {
                                  discountAmount: {
                                      amount: '' + 0,
                                  },
                              },
                          }),
                }
            }

            if (data.discountType === 'cartItemsDiscount') {
                data.discountCartItemsCapping &&
                    data.discountCartItemType === 'percentage' &&
                    (result.discount_capping = {
                        capping_limit: data.discountCartItemsCappingValue,
                        match_type: 1,
                    })
            } else if (data.discountType === 'bundleDiscount') {
                result.buyxaty_discount = data.buyxatyDiscount.map((item: any) => ({
                    discount_info: item,
                }))
            } else if (data.discountType === 'tieredDiscount') {
                result.dynamic_discount = [
                    {
                        discount_category: data.tieredDiscountCategory,
                        discount_info: data.tieredDiscountTiers,
                    },
                ]
            } else if (data.discountType === 'shippingDiscount') {
                data.enableShippingExclusion &&
                    (result.maximum_shipping_price = (data.shippingDiscountAmount || 0).toFixed(2))
                result.destination = {
                    all: true,
                }
            }

            if (data.topUpGifts) {
                result.gk_gwp_discount = {
                    match_type: 1,
                    discount_rule: {
                        product: data.topUpGiftsData.products.data,
                    },
                }
            }
            if (data.isImported) {
                const importedConditions = requestMapDiscount.conditions(data)
                const importedCustomerEligibility = await requestMapDiscount.customerEligibility(data)
                result = {
                    ...importedConditions,
                    ...importedCustomerEligibility,
                    ...result,
                }
            }
            return result
        } catch (error) {
            console.log(error)
        }
    },
    conditions: (data: any) => {
        let result: {
            [key: string]: any
        } = {
            title: data.discountCode,
            discount_method: 'manual',
            status: data.status || 'DRAFT',
            page_type: 'CONDITIONS_PAGE',
            sub_method: data.subMethod,
        }
        if (!data.isBulkDiscount) {
            data.enableLimitTotalUsage && (result.usage_limit = +data.limitDiscountTotalUsageValue)
            data.enableLimitOneUsagePerCustomer && (result.applies_once_per_customer = true)
            result.is_cod_blocked = !!data.disableCod
            result.is_prepaid_discount_blocked = !!data.disablePrepaidDiscounts
        } else {
            result.usage_limit = 1
            result.applies_once_per_customer = false
        }

        result.starts_at = data.startDate?.toISOString()
        data.endDateRequired && (result.ends_at = data.endDate?.toISOString())
        return result
    },
    combinations: (data: any) => {
        let result: {
            [key: string]: any
        } = {
            title: data.discountCode,
            discount_method: data.discountMethod,
            status: data.status || 'DRAFT',
            page_type: 'COMBINATIONS_PAGE',
            sub_method: data.subMethod,
        }

        if (data.discountMethod === 'manual') {
            if ('enableCombineManualDiscounts' in data) {
                result.is_stackable = data.enableCombineManualDiscounts
            }
            if (data.enableCombineAutomaticDiscounts) {
                const [overWrite, combine] = data.combineOverwriteAutoDiscounts.reduce(
                    (acc: any, val: any) => {
                        acc[val.behavior === 'overwrite' ? 0 : 1].push({
                            automatic_code: val.name,
                            allowed_manual_code: data.discountCode,
                            automatic_code_match_type: 1,
                            allowed_manual_code_match_type: 1,
                            automatic_code_clubs_with_discount: 'SPECIFIC',
                            automatic_code_clubs_with_loyalty: val.behavior !== 'overwrite',
                        })

                        return acc
                    },
                    [[], []],
                )
                result.overwrite_automatic_discount = overWrite
                result.extra_automatic_discount = combine
            }

            if (data.update_codes) {
                result.update_codes = data.update_codes
            } else if (
                !data.update_codes &&
                !data.enableCombineAutomaticDiscounts &&
                data.combineOverwriteAutoDiscounts?.length
            ) {
                result.update_codes = {
                    extra: {
                        remove: data.combineOverwriteAutoDiscounts
                            .filter((val) => val.behavior !== 'overwrite')
                            .map((val: any) => val.name),
                    },
                    overwrite: {
                        remove: data.combineOverwriteAutoDiscounts
                            .filter((val) => val.behavior === 'overwrite')
                            .map((val: any) => val.name),
                    },
                }
                result.extra_automatic_discount = []
                result.overwrite_automatic_discount = []
            }

            if ('enableCombineManualDiscounts' in data && !data.update_codes) {
                result.extra_automatic_discount = []
                result.overwrite_automatic_discount = []
                result.update_codes = {}
            }
        }
        if (!data.update_codes && !data.combineOverwriteAutoDiscounts && !('is_stackable' in result)) {
            result.skipApiCall = true
        }

        return result
    },
    customerEligibility: async (data: any) => {
        try {
            let result: {
                [key: string]: any
            } = {
                title: data.discountCode,
                discount_method: 'manual',
                status: 'ACTIVE',
                page_type: 'CUSTOMER_ELIGIBILITY_PAGE',
                sub_method: data.subMethod,
            }
            const getSignedUrl = async (file_name: string, merchant_id: string) => {
                const response = await makeAPICall({
                    method: 'get',
                    url: process.env.REACT_APP_BASE_URL + APIEndPoints.getCohortPresignedUrl,
                    params: { file_name },
                })
                if (response.success) {
                    return response.data.data.url
                }
            }
            const checkAndUploadPincodes = async () => {
                const file = data.customerSegmentCSV?.[0]
                const uploadedCsvFileName = file.name || 'PhoneNumbers.csv'
                const url = await getSignedUrl(uploadedCsvFileName, data.merchantId)
                const file_name = await uploadCsvData(url)
                if (!file_name) {
                    message.error('CSV File can not be uploaded, please try again!')
                    return false
                }
                return file_name
            }

            async function uploadCsvData(url: string) {
                const file = data.customerSegmentCSV?.[0]
                try {
                    const awsResponse = await fetch(url, {
                        method: 'PUT',
                        body: file,
                    })
                    if (awsResponse.status == 200) {
                        const parsedUrl = new URL(url)
                        const parts = parsedUrl.pathname.split('/')
                        const nonEmptyParts = parts.filter((part) => part !== '')
                        const filename = decodeURIComponent(nonEmptyParts.pop())
                        return filename
                    }
                    return false
                } catch (error) {
                    return false
                }
            }
            if (data.customerEligibility !== 'all') {
                result.user_type_discount = [
                    {
                        discount_code: data.discountCode?.toLowerCase(),
                        is_active: true,
                        eligibility_rule: {
                            match_type: 1,
                            user_type:
                                data.customerEligibility === 'newCustomers'
                                    ? 'new'
                                    : data.customerEligibility === 'existingCustomers'
                                    ? 'repeat'
                                    : 'cohort',
                        },
                    },
                ]
                if (data.customerEligibility === 'newCustomers' || data.customerEligibility === 'existingCustomers') {
                    result.user_type_discount[0].identifier = data.customerSegmentSource
                    result.user_type_discount[0].cohort_details = null
                } else {
                    const isCsv = data.customerEligibility === 'specificCustomers'
                    const file_name =
                        data.customerEligibility === 'specificCustomers' ? await checkAndUploadPincodes() : ''
                    result.user_type_discount[0].cohort_details = {
                        cohort_source: data.customerEligibility === 'shopifySegments' ? 'shopify' : 'uploaded_csv',
                        ...(isCsv && { included: data.specificCustomersType === 'include', file_name }),
                    }
                    result.user_type_discount[0].identifier = null
                }
            } else {
                result.user_type_discount = null
            }
            if (data.customerEligibility === 'shopifySegments') {
                result.customer_selection = {
                    customerSegments: data.selectedSegments,
                }
            } else {
                result.customer_selection = {
                    all: true,
                }
            }

            if (!(data.salesChannelWebsite && data.salesChannelMobileApplication)) {
                result.channel_level_discount = [
                    {
                        channel: data.salesChannelMobileApplication ? 'app' : 'web',
                        match_type: 'exact_match',
                    },
                ]
            }
            if (data.enableUtmParameters) {
                result.utm_discount = data.utmParameters
            } else {
                result.utm_discount = null
            }

            return result
        } catch (error) {
            console.log(error)
        }
    },

    visibility: (data: any) => {
        let result: {
            [key: string]: any
        } = {
            title: data.discountCode,
            discount_method: 'manual',
            status: 'ACTIVE',
            is_visible: data.discountCodeVisibilityEnabled,
            page_type: 'VISIBILITY_PAGE',
            sub_method: data.subMethod,
        }
        if (data.discountCodeVisibilityEnabled) {
            result.view_offer_details = {
                discount_detail: data.discountCodeDescription,
                additional_details: {
                    bullet_points: data.discountCodeDetails?.split(',').map((value: string) => value.trim()),
                },
                visibility: data.discountCodeVisibility,
                user_type:
                    customerEligibility[data.customerEligibility] ||
                    (data.customerSegmentCSV ? 'csv_based_cohort' : 'shopify_based_cohort'),
                is_cohort_based: !customerEligibility[data.customerEligibility],
                min_quantity: data.discountCodeVisibilityMinQty,
                max_quantity: data.discountCodeVisibilityMaxQty,
                min_price: data.discountCodeVisibilityMinPrice,
                max_price: data.discountCodeVisibilityMaxPrice,
            }
        }
        return result
    },
    generateCodes: async (data: any) => {
        let result: {
            [key: string]: any
        } = {
            title: data.discountCode,
            discount_method: 'manual',
            sub_method: data.subMethod,
            status: 'ACTIVE',
            page_type: 'BULK_DISCOUNT_PAGE',
        }
        const codeGenerationMethod = data.generateDiscountCodeMethod
        const uploadDiscountCSV = async () => {
            const fileName = `discount_codes_${dayjs().unix()}.zip`
            const preSignedURLResponse = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BULK_DISCOUNT_URL + APIEndPoints.bulkDiscounts.presignedUrl,
                payload: {
                    url: fileName,
                    merchant_id: data.merchantId,
                    operation_type: 'UPLOAD',
                },
            })

            if (preSignedURLResponse?.success && preSignedURLResponse.data?.data?.presignedUrl) {
                const preSignedURL = preSignedURLResponse.data?.data?.presignedUrl
                if (preSignedURL) {
                    const csvFile = data.bulkDiscountCSV?.[0].originFileObj

                    const awsResponse = await fetch(preSignedURL, {
                        method: 'PUT',
                        body: csvFile,
                    })
                    if (awsResponse.status === 200) {
                        return fileName
                    } else {
                        message.error('Upload Failed: Presigned URL error')
                        return
                    }
                }
            } else {
                message.error('Upload Failed: Presigned URL error')
                return
            }
        }

        const generateDiscountCode = async () => {
            let payload = {}

            if (codeGenerationMethod !== 'random') {
                const fileName = await uploadDiscountCSV()
                payload = { discount_codes_url: fileName }
            }

            try {
                const response = await makeAPICall({
                    method: 'post',
                    url: process.env.REACT_APP_BULK_DISCOUNT_URL + APIEndPoints.bulkDiscounts.createDiscount,
                    payload: {
                        ...payload,
                        title: data.discountCode,
                        merchant_id: data.merchantId,
                        ends_at: dayjs(data.expiry).toISOString(),
                        is_active: true,
                        email: data.email,
                        total_codes: data.numberOfCodes,
                        campaign_meta_id: +data.priceRuleId,
                        charset_type: 'both',
                        length: data.codeLength,
                        ...(data.codeIdentifier === 'Prefix'
                            ? { prefix: data.prefix }
                            : {
                                  suffix: data.suffix,
                              }),
                    },
                })

                if (response.success) {
                    message.success(
                        `Your coupon generation is successfully processed. You will receive the same on ${data.email} within 15 minutes`,
                    )
                    return response
                } else {
                    message.error(response.response?.data?.data || 'Unable to generate discount code')
                }
            } catch (error) {
                console.error('Error generating discount code:', error)
            }
        }

        const response = await generateDiscountCode()
        if (response.data.data) {
            const campaignData = response.data.data
            result.bulk_campaign_id = campaignData.id
            result.match_type = data.codeIdentifier === 'Prefix' ? 'prefix' : 'suffix'
            result.code_to_be_matched = data.codeIdentifier === 'Prefix' ? data.prefix : data.suffix
        }

        return result
    },
    automatic: (data: any) => {
        let result: {
            [key: string]: any
        } = {
            title: data.discountCode,
            discount_method: 'automatic',
            sub_method: 'automatic',
            status: 'ACTIVE',
            page_type: data.id ? 'COMBINATIONS_PAGE' : 'SETUP_PAGE',
        }
        if (data.enableCombineManualDiscounts === 'specific') {
            result.extra_automatic_discount = data.combineManualDiscounts.map((item: any) => ({
                automatic_code: data.discountCode,
                allowed_manual_code: item.discount_code,
                automatic_code_match_type: data.matchType,
                allowed_manual_code_match_type: item.match_type,
                automatic_code_clubs_with_discount: 'SPECIFIC',
                automatic_code_clubs_with_loyalty: item.club_with_loyalty_points,
            }))
        } else {
            result.extra_automatic_discount = [
                {
                    automatic_code: data.discountCode,
                    automatic_code_match_type: data.matchType,
                    automatic_code_clubs_with_discount: data.enableCombineManualDiscounts ? 'ALL' : 'NONE',
                    automatic_code_clubs_with_loyalty: !!data.clubLoyaltyCombineManualDiscounts,
                },
            ]
        }

        if (data.enableOverwriteManualDiscounts === 'specific') {
            result.overwrite_automatic_discount = data.overwriteManualDiscounts.map((item: any) => ({
                automatic_code: data.discountCode,
                allowed_manual_code: item.discount_code,
                automatic_code_match_type: data.matchType,
                allowed_manual_code_match_type: item.match_type,
                automatic_code_clubs_with_discount: 'SPECIFIC',
                automatic_code_clubs_with_loyalty: item.club_with_loyalty_points,
            }))
        } else {
            result.overwrite_automatic_discount = [
                {
                    automatic_code: data.discountCode,
                    automatic_code_match_type: data.matchType,
                    automatic_code_clubs_with_discount: data.enableOverwriteManualDiscounts ? 'ALL' : 'NONE',
                    automatic_code_clubs_with_loyalty: !!data.replaceLoyaltyOverwriteManualDiscounts,
                },
            ]
        }

        return result
    },
    listing: (data: any) => {
        let result: {
            [key: string]: any
        } = {
            title: data.discountCode,
            discount_method: data.discountMethod,
            status: data.status,
            is_visible: data.is_visible,
            page_type: 'LISTING_PAGE',
            sub_method: data.subMethod,
        }
        return result
    },
}

export const responseMapDiscount = async (response: any) => {
    try {
        let data: { [key: string]: any } = {
            ...manualDiscountInitialValues,
            discountCode: response.title,
            title: response.title,
            status: response.status,
            isImported: response.is_imported,
        }
        if (response.shopify_discount_id) {
            data.shopifyDiscountId = response.shopify_discount_id
            data.priceRuleId = response.shopify_discount_id.split('/DiscountCodeNode/')[1]
        }
        if (response.discount_method === 'automatic') {
            data.subMethod = 'automatic'
            data.matchType = (
                response.extra_automatic_discount?.[0] || response.overwrite_automatic_discount?.[0]
            )?.automatic_code_match_type
            data.enableCombineManualDiscounts =
                !!response.extra_automatic_discount?.[0]?.automatic_code_clubs_with_discount &&
                response.extra_automatic_discount?.[0]?.automatic_code_clubs_with_discount !== 'NONE'
            data.enableOverwriteManualDiscounts =
                !!response.overwrite_automatic_discount?.[0]?.automatic_code_clubs_with_discount &&
                response.overwrite_automatic_discount?.[0]?.automatic_code_clubs_with_discount !== 'NONE'
            if (data.enableCombineManualDiscounts) {
                const combineManualDiscounts = response.extra_automatic_discount
                if (combineManualDiscounts && combineManualDiscounts[0].automatic_code_clubs_with_discount !== 'ALL') {
                    data.enableCombineManualDiscounts = 'specific'
                    data.combineManualDiscounts = combineManualDiscounts.map((item: any) => ({
                        discount_code: item.allowed_manual_code,
                        club_with_loyalty_points: item.automatic_code_clubs_with_loyalty,
                        match_type: item.allowed_manual_code_match_type,
                    }))
                }
                if (combineManualDiscounts && combineManualDiscounts[0].automatic_code_clubs_with_discount === 'ALL') {
                    data.clubLoyaltyCombineManualDiscounts = combineManualDiscounts[0].automatic_code_clubs_with_loyalty
                }
            }
            if (data.enableOverwriteManualDiscounts) {
                const overwriteManualDiscounts = response.overwrite_automatic_discount
                if (
                    overwriteManualDiscounts &&
                    overwriteManualDiscounts?.[0].automatic_code_clubs_with_discount !== 'ALL'
                ) {
                    data.enableOverwriteManualDiscounts = 'specific'
                    data.overwriteManualDiscounts = overwriteManualDiscounts.map((item: any) => ({
                        discount_code: item.allowed_manual_code,
                        club_with_loyalty_points: item.automatic_code_clubs_with_loyalty,
                        match_type: item.allowed_manual_code_match_type,
                    }))
                } else if (
                    overwriteManualDiscounts &&
                    overwriteManualDiscounts?.[0].automatic_code_clubs_with_discount === 'ALL'
                ) {
                    data.replaceLoyaltyOverwriteManualDiscounts =
                        overwriteManualDiscounts?.[0].automatic_code_clubs_with_loyalty
                }
            }
            return data
        }
        if (response.discount_type === 'DiscountCodeBasic') {
            data.discountType = ['cartItemsDiscount', 'bundleDiscount', 'tieredDiscount'].find(
                (type) => type === response.discount_type,
            )
        } else if (response.discount_type === 'DiscountCodeBxgy') {
            data.discountType = 'discountedCartItems'
        } else if (response.discount_type === 'DiscountCodeFreeShipping') {
            data.discountType = 'shippingDiscount'
            data.enableShippingExclusion = !!response.maximum_shipping_price
            data.shippingDiscountAmount = +response.maximum_shipping_price
        }
        if (response.minimum_requirement) {
            if (response.minimum_requirement.subtotal) {
                data.minimumRequirements = 'minimumAmount'
                data.discountValue = response.minimum_requirement.subtotal.greaterThanOrEqualToSubtotal
            } else if (response.minimum_requirement.quantity) {
                data.minimumRequirements = 'minimumQuantity'
                data.discountValue = response.minimum_requirement.quantity.greaterThanOrEqualToQuantity
            }
        } else {
            data.minimumRequirements = 'noMinimum'
        }
        if (response.discount_type === 'DiscountCodeBxgy') {
            data.discountApplicableOn = response.customer_buys.items.products
                ? 'specificProducts'
                : 'specificCollections'
            data.discountApplicableOnItems = {
                products: response.customer_buys.items.products
                    ? (await responseMapProducts(response.customer_buys.items)).products
                    : {},
                collections: response.customer_buys.items.collections
                    ? { data: response.customer_buys.items.collections.collectionData }
                    : {},
            }
            data.minimumRequirements = response.customer_buys.value.amount ? 'minimumAmount' : 'minimumQuantity'
            data.discountValue = response.customer_buys.value.amount || response.customer_buys.value.quantity
            data.discountedCartItemsType = response.customer_gets.items.products
                ? 'specificProducts'
                : 'specificCollections'
            data.discountedCartDiscountItems = {
                products: response.customer_gets.items.products
                    ? (await responseMapProducts(response.customer_gets.items)).products
                    : {},
                collections: response.customer_gets.items.collections
                    ? { data: response.customer_gets.items.collections.collectionData }
                    : {},
            }
            data.discountedCartItemsQuantity = response.customer_gets.value.discountOnQuantity.quantity
            data.discountedCartItemsDiscountType = response.customer_gets.value.discountOnQuantity.effect.percentage
                ? 'percentage'
                : 'amount'
            data.discountedCartItemsDiscountValue =
                response.customer_gets.value.discountOnQuantity.effect.percentage * 100 ||
                response.customer_gets.value.discountOnQuantity.effect.amount
            if (response.uses_per_order_limit) {
                data.discountedCartItemsEnableUsesOrderLimit = true
                data.discountedCartItemsUsesOrderLimit = response.uses_per_order_limit
            }
            data.discountedCartItemsEnableUsesOrderLimit = response.uses_per_order_limit
        } else if (response.customer_gets) {
            if (response.customer_gets.items.all) {
                data.discountApplicableOn = 'allProducts'
            } else {
                data.discountApplicableOn = response.customer_gets.items.products
                    ? 'specificProducts'
                    : 'specificCollections'
                data.discountApplicableOnItems = {
                    products: response.customer_gets.items.products
                        ? (await responseMapProducts(response.customer_gets.items)).products
                        : {},
                    collections: response.customer_gets.items.collections
                        ? { data: response.customer_gets.items.collections.collectionData }
                        : {},
                }
            }
            if (response.customer_gets.value) {
                data.discountType = 'cartItemsDiscount'
            }
            if (response.customer_gets.value.discountAmount) {
                data.discountCartItemType = 'fixed'
                data.discountCartItemsDiscountValue = response.customer_gets.value.discountAmount.amount
                data.discountCartItemsOncePerOrder = !response.customer_gets.value.discountAmount.appliesOnEachItem
            } else if (response.customer_gets.value.percentage) {
                data.discountCartItemType = 'percentage'
                data.discountCartItemsDiscountValue = response.customer_gets.value.percentage * 100
            }

            data.isGwp = response.is_gwp
        }
        if (response.discount_capping) {
            data.discountCartItemsCapping = true
            data.discountCartItemsCappingValue = response.discount_capping.capping_limit
        }
        if (response.buyxaty_discount) {
            data.buyxatyDiscount = response.buyxaty_discount.map((item: any) => item.discount_info)
            data.discountType = 'bundleDiscount'
        }
        if (response.dynamic_discount) {
            data.tieredDiscountTiers = response.dynamic_discount[0].discount_info
            data.tieredDiscountCategory = response.dynamic_discount[0].discount_category
            data.discountType = 'tieredDiscount'
        }

        if (response.usage_limit) {
            data.enableLimitTotalUsage = true
            data.limitDiscountTotalUsageValue = response.usage_limit
        } else {
            data.enableLimitTotalUsage = false
        }

        data.topUpGifts = !!response.gk_gwp_discount
        if (response.gk_gwp_discount) {
            data.topUpGiftsData = {
                products: {
                    data: response.gk_gwp_discount.discount_rule.product,
                },
            }
        }

        if (response.applies_once_per_customer) {
            data.enableLimitOneUsagePerCustomer = true
        } else {
            data.enableLimitOneUsagePerCustomer = false
        }

        if (response.starts_at) {
            data.startDate = dayjs(response.starts_at)
        }

        if (response.ends_at) {
            data.endDateRequired = true
            data.endDate = dayjs(response.ends_at)
        } else {
            data.endDateRequired = false
        }

        data.disableCod = !!response.is_cod_blocked
        data.disablePrepaidDiscounts = !!response.is_prepaid_discount_blocked

        if (response.is_stackable) {
            data.enableCombineManualDiscounts = true
        }

        if (response.overwrite_automatic_discount?.length || response.extra_automatic_discount?.length) {
            data.enableCombineAutomaticDiscounts = true
            data.combineOverwriteAutoDiscounts = [
                ...(response.overwrite_automatic_discount || []).map((item: any) => ({
                    name: item.automatic_code,
                    behavior: 'overwrite',
                })),
                ...(response.extra_automatic_discount || []).map((item: any) => ({
                    name: item.automatic_code,
                    behavior: 'combine',
                })),
            ]
        } else {
            data.enableCombineAutomaticDiscounts = false
        }
        if (response.user_type_discount?.length) {
            const userTypeDiscount = response.user_type_discount[0]
            data.discountCode = userTypeDiscount.discount_code
            data.customerEligibility =
                userTypeDiscount.eligibility_rule?.user_type === 'new'
                    ? 'newCustomers'
                    : userTypeDiscount.eligibility_rule?.user_type === 'repeat'
                    ? 'existingCustomers'
                    : 'specificCustomers'

            if (data.customerEligibility === 'newCustomers' || data.customerEligibility === 'existingCustomers') {
                data.customerSegmentSource = userTypeDiscount.identifier
            } else {
                data.customerSegmentSource = userTypeDiscount.cohort_details?.cohort_source
                data.customerSegmentCSV = userTypeDiscount?.cohort_details?.file_name
                    ? [{ name: userTypeDiscount.cohort_details.file_name, alreadyUploaded: true }]
                    : []
                data.specificCustomersType = userTypeDiscount?.cohort_details?.included ? 'include' : 'exclude'
            }
        } else {
            data.customerEligibility = 'all'
        }

        if (response.customer_selection) {
            if (response.customer_selection.customerSegments) {
                data.customerEligibility = 'shopifySegments'
                data.selectedSegments = response.customer_selection.customerSegments
            } else {
                data.selectedSegments = {}
            }
        }

        if (response.channel_level_discount) {
            data.salesChannelWebsite = response.channel_level_discount[0].channel === 'web'
            data.salesChannelMobileApplication = response.channel_level_discount[0].channel === 'app'
        } else {
            data.salesChannelWebsite = true
            data.salesChannelMobileApplication = true
        }

        if (response.utm_discount) {
            data.enableUtmParameters = true
            data.utmParameters = response.utm_discount
        } else {
            data.enableUtmParameters = false
        }

        data.discountCodeVisibilityEnabled = response.is_visible
        if (response.view_offer_details) {
            data.discountCodeDescription = response.view_offer_details.discount_detail
            data.discountCodeDetails = response.view_offer_details.additional_details?.bullet_points?.join(',')
            data.discountCodeVisibility = response.view_offer_details.visibility
            data.discountCodeVisibilityMinQty = response.view_offer_details.min_quantity
            data.discountCodeVisibilityMaxQty = response.view_offer_details.max_quantity
            data.discountCodeVisibilityMinPrice = response.view_offer_details.min_price
            data.discountCodeVisibilityMaxPrice = response.view_offer_details.max_price
        }
        if (response.bulk_campaign_id && response.bulk_campaign) {
            const bulkCampaignData = response.bulk_campaign
            data.bulkDisabled = true
            data.expiry = dayjs(bulkCampaignData.ends_at)
            data.generateDiscountCodeMethod = bulkCampaignData.discount_codes_url ? 'upload' : 'random'
            data.numberOfCodes = bulkCampaignData.total_codes
            data.codeLength = bulkCampaignData.length
            data.bulkDiscountCSV = [
                {
                    campaign_id: bulkCampaignData.bulk_campaign_id,
                    url: bulkCampaignData.discount_codes_url,
                    alreadyUploaded: true,
                    name: 'Discount Codes',
                },
            ]
            data.codeIdentifier = bulkCampaignData.prefix ? 'Prefix' : 'Suffix'
            data.prefix = bulkCampaignData.prefix
            data.suffix = bulkCampaignData.suffix
        }

        return data
    } catch (error) {
        console.log(error)
    }
}

export const shopifyResponseMap = async (response: any) => {
    try {
        const data: { [key: string]: any } = {}

        data.discountCode = response.title
        data.enableLimitOneUsagePerCustomer = !!response.appliesOncePerCustomer
        data.asyncUsageCount = response.asyncUsageCount
        data.startDate = dayjs(response.startsAt)
        data.endDate = response.endsAt ? dayjs(response.endsAt) : null
        data.endDateRequired = !!response.endsAt
        data.status = response.status
        data.usesPerOrderLimit = response.usesPerOrderLimit

        if (response.usageLimit) {
            data.enableLimitTotalUsage = true
            data.limitDiscountTotalUsageValue = response.usageLimit
        }

        // Customer Selection
        if (response.customerSelection) {
            data.customerSelectionType = response.customerSelection.__typename
            if (response.customerSelection.__typename === 'DiscountCustomerSegments') {
                data.customerEligibility = 'includesSpecificCustomers'
                data.customerSegmentSource = 'shopify'
                data.selectedSegments = {
                    segmentData: response.customerSelection.segments.map((edge: any) => edge),
                }
            } else if (response.customerSelection.__typename === 'DiscountCustomerAll') {
                data.customerEligibility = 'all'
                data.allCustomers = response.customerSelection.allCustomers
            }
        }
        if (response.__typename === 'DiscountCodeFreeShipping') {
            data.discountType = 'shippingDiscount'

            data.enableShippingExclusion = !!response.maximumShippingPrice?.amount
            data.shippingDiscountAmount = +response.maximumShippingPrice?.amount
        }

        // Customer Buys
        if (response.customerBuys) {
            data.discountType = 'discountedCartItems'
            const customerBuys = response.customerBuys
            if (customerBuys.items) {
                const items = customerBuys.items

                // Products
                data.discountApplicableOn = response.customerBuys.items.products
                    ? 'specificProducts'
                    : 'specificCollections'
                if (items.products) {
                    data.discountApplicableOnItems = await responseMapProducts(items, true)
                } else {
                    data.discountApplicableOnItems = {
                        collections: {
                            data: items.collections.edges.map(({ node }: any) => node),
                        },
                    }
                }

                // Value
                if (customerBuys.value) {
                    data.minimumRequirements = customerBuys.value.amount ? 'minimumAmount' : 'minimumQuantity'
                    if (customerBuys.value.__typename === 'DiscountQuantity') {
                        data.discountValue = customerBuys.value.quantity
                    } else if (customerBuys.value.__typename === 'DiscountPurchaseAmount') {
                        data.discountValue = customerBuys.value.amount
                    }
                }
                if (response.usesPerOrderLimit) {
                    data.discountedCartItemsEnableUsesOrderLimit = true
                    data.discountedCartItemsUsesOrderLimit = data.usesPerOrderLimit
                }
            }
        } else if (response.minimumRequirement) {
            const type = response.minimumRequirement.__typename
            if (type === 'DiscountMinimumQuantity') {
                data.minimumRequirements = 'minimumQuantity'
                data.discountValue = response.minimumRequirement.greaterThanOrEqualToQuantity
            } else if (type === 'DiscountMinimumSubtotal') {
                data.minimumRequirements = 'minimumAmount'
                data.discountValue = response.minimumRequirement.greaterThanOrEqualToSubtotal.amount
            }
        }
        if (response.customerGets) {
            // Customer Gets
            const customerGets = response.customerGets
            if (customerGets.items) {
                const items = customerGets.items
                const keyname =
                    response.__typename === 'DiscountCodeBxgy'
                        ? 'discountedCartDiscountItems'
                        : 'discountApplicableOnItems'

                const typeKeyName =
                    response.__typename === 'DiscountCodeBxgy' ? 'discountedCartItemsType' : 'discountApplicableOn'

                data[typeKeyName] = customerGets.items.allItems
                    ? 'allProducts'
                    : customerGets.items.collections
                    ? 'specificCollections'
                    : 'specificProducts'

                // Products
                if (items.products) {
                    data[keyname] = await responseMapProducts(items, true)
                }

                if (items.collections && items.collections.edges.length > 0) {
                    data[keyname] = {
                        ...(data[keyname] || {}),
                        collections: {
                            data: items.collections.edges.map(({ node }: any) => node),
                        },
                    }
                }
            }
            // Value
            if (customerGets.value) {
                if (customerGets.value.__typename === 'DiscountOnQuantity') {
                    data.discountedCartItemsQuantity = customerGets.value.quantity.quantity

                    // Effect
                    const effect = customerGets.value.effect
                    if (effect) {
                        if (effect.__typename === 'DiscountPercentage') {
                            data.discountedCartItemsDiscountType = 'percentage'
                            data.discountedCartItemsDiscountValue = effect.percentage * 100
                        } else if (effect.__typename === 'DiscountAmount') {
                            data.discountedCartItemsDiscountType = 'amount'
                            data.discountedCartItemsDiscountValue = effect.amount
                        }
                    }
                } else if (customerGets.value.amount) {
                    data.discountCartItemType = 'fixed'
                    data.discountCartItemsDiscountValue = customerGets.value.amount.amount
                    data.discountCartItemsOncePerOrder = !customerGets.value.appliesOnEachItem
                } else if (customerGets.value.percentage) {
                    data.discountCartItemType = 'percentage'
                    data.discountCartItemsDiscountValue = customerGets.value.percentage * 100
                }
            }
        }

        return data
    } catch (error) {
        console.log(error)
    }
}
