import {
    Alert,
    Button,
    Card,
    Col,
    DeleteOutlined,
    Divider,
    Drawer,
    EditOutlined,
    Form,
    Input,
    message,
    Popconfirm,
    Radio,
    Row,
    Select,
    Switch,
    Table,
    TextArea,
    Tooltip,
} from 'gokwik-ui-kit'
import React, { useEffect, useState } from 'react'
import BookDownload from '@library/images/book-download.svg'
import { useNavigate } from 'react-router-dom'
import OfferSortOrder from '@pages/settings/offers-discount/offersOrderControl'
import { DiyDiscountListing, OfferAndDiscount, RecommendedOffer } from '@library/utilities/interface'
import ChangeDiscountDescription from '@library/components/discounts/changeDescription'
import { fetchManualDiscountsDataAsync } from '@store/discounts'
import { useAppDispatch } from '@library/utilities/hooks'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import Sparkle from '@library/images/PixelIcons/sparkle.svg'
interface DiscountsResponse {
    res: {
        discounts: DiyDiscountListing[]
    }
}

const coupons = ({ onChange }) => {
    const [loading, setLoading] = useState(true)
    const [selectedCoupon, setSelectedCoupon] = useState(null)
    const [saved, setSaved] = useState(false)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [changeReordering, setChangeReordering] = useState({
        open: false,
        discounts: [],
    })
    const [parameters, setParameters] = useState({
        page: 1,
        pageSize: 150,
        bulkDiscount: false,
        searchVisibility: true,
        searchStatus: 'ACTIVE',
    })

    const [offersData, setOffersData] = useState([])
    const [changeDescriptionDiscount, setChangeDescriptionDiscount] = useState<DiyDiscountListing>(null)
    const { config, refetch, saveConfig } = useMerchantConfigApi({
        useCache: true,
    })

    const offer: Partial<RecommendedOffer> = config?.custom_config?.one_click_offer || {}
    const [inputs, setInputs] = useState<RecommendedOffer>({
        is_active: offer?.is_active ?? null,
        auto_apply: offer?.auto_apply ?? null,
        type: offer.stacking_code_list?.length ? 'exact_discount_multiple' : offer?.type ?? null,
        code: offer?.code ?? null,
        stacking_code_list: offer?.stacking_code_list ?? [],
    })
    const isBestOfferTypeMultiple = inputs.type === 'exact_discount_multiple'

    const [couponData, setCouponData] = useState({
        isOfferVisible: false,
        couponList: [],
        isSortClicked: false,
    })

    useEffect(() => {
        onChange(couponData)
    }, [couponData])

    useEffect(() => {
        setCouponData((prev) => ({ ...prev, isOfferVisible: inputs?.is_active }))
    }, [inputs, offersData, config])
    const fetchDiscounts = async (params) => {
        try {
            const result = await dispatch(
                fetchManualDiscountsDataAsync({
                    params: { ...params },
                }),
            )
            const payload = result.payload as DiscountsResponse
            const discounts = payload.res?.discounts
            if (Array.isArray(discounts)) {
                const activeDiscounts = discounts
                    .filter((discount) => discount?.status === 'ACTIVE')
                    .sort((a, b) => (a.view_offer_details?.sort_order || 0) - (b.view_offer_details?.sort_order || 0))
                    .map((discount) => ({
                        ...discount,
                        discount_code: discount.title,
                        discount_detail: discount?.view_offer_details?.discount_detail,
                        additional_details: discount?.view_offer_details?.additional_details?.bullet_points,
                    }))
                setOffersData(activeDiscounts || [])
                setLoading(false)
            }
        } catch (error) {
            console.error('Error fetching discounts:', error)
        }
    }
    const updateAllDiscountCodes = async (discountCodesList: OfferAndDiscount[]) => {
        const response = await makeAPICall({
            method: 'patch',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateSortedDiscounts,
            payload: {
                m_id: config?.id,
                discounts: discountCodesList,
            },
        })
        if (response.success) {
            fetchDiscounts({ ...parameters })
            message.success(response.data.data || 'Discounts updated successfully')
            setChangeReordering({
                open: false,
                discounts: [],
            })
        }
        if (response.error) {
            message.error(response.response.data.data || 'Unable to update discount')
            return
        }
    }
    const handleSave = () => {
        if (inputs.type === 'exact_discount' && inputs.code === '') {
            message.error('Please select an exact discount code')
            return
        }
        if (inputs.type === 'exact_discount_multiple' && !inputs.stacking_code_list?.length) {
            message.error('Please select at least one discount code')
            return
        }
        const payload = { ...inputs }
        if (inputs.type === 'exact_discount_multiple') {
            payload.code = null
            payload.type = 'exact_discount'
        } else {
            payload.stacking_code_list = null
        }
        saveConfig({
            custom_config: {
                ...config.custom_config,
                one_click_offer: payload,
            },
        })
        setSaved(false)
    }

    useEffect(() => {
        fetchDiscounts(parameters)
        if (config && config?.custom_config?.one_click_offer) {
            const list = config?.custom_config?.one_click_offer?.stacking_code_list
            setInputs({
                ...config?.custom_config?.one_click_offer,
                type: list ? 'exact_discount_multiple' : config?.custom_config?.one_click_offer?.type,
            })
        }
    }, [parameters, config])

    const offersColumns: any = [
        {
            title: 'Code',
            dataIndex: 'discount_code',
            key: 'discount_code',
        },
        {
            title: 'Description',
            dataIndex: 'discount_detail',
            key: 'discount_detail',
        },
        {
            title: 'Details',
            dataIndex: 'additional_details',
            key: 'additional_details',
        },
        {
            title: '',
            dataIndex: 'actions',
            align: 'center',
            render: (text, record) => {
                return (
                    <div className='flex gap-x-4'>
                        <EditOutlined
                            onClick={() => {
                                setSelectedCoupon(record)
                                setChangeDescriptionDiscount(record)
                            }}
                        />
                    </div>
                )
            },
            key: 'actions',
        },
    ]
    if (loading) {
        return null
    }
    return (
        <>
            {!offersData.length ? (
                <div className='flex items-center justify-center h-screen'>
                    <div className='text-center'>
                        <h3 className='text-2xl font-bold mb-2'>Ooops!</h3>
                        <p className='mb-4'>You have not configured any discounts on checkout to be visible yet.</p>
                        <Button type='primary' onClick={() => navigate('/checkout/settings/discounts')}>
                            Configure Now
                        </Button>
                    </div>
                </div>
            ) : (
                <Form
                    layout='vertical'
                    className='space-y-6'
                    initialValues={{
                        discount_code: selectedCoupon?.discount_code || '',
                        discount_detail: selectedCoupon?.discount_detail || '',
                        additional_details: selectedCoupon?.additional_details || '',
                    }}
                >
                    <div className='flex justify-between align-center'>
                        <div className='m-0 align-center'>
                            <span className='text-lg font-semibold'>Coupons</span>
                            <p className='text-gray-500 gap-0'>
                                Control UI and experience for all active and visible manual coupon codes on checkout.
                            </p>
                        </div>
                        {!changeReordering.open && (
                            <Button
                                type='primary'
                                htmlType='submit'
                                className='ml-auto'
                                disabled={!saved}
                                onClick={handleSave}
                            >
                                Save Changes
                            </Button>
                        )}
                    </div>

                    {changeReordering.open ? (
                        <>
                            <div className='flex items-center justify-between'>
                                <Col>
                                    <p className='text-base font-semibold'>Update Sorting of list</p>
                                </Col>
                                <Col>
                                    <div className='flex items-center gap-x-4'>
                                        <Button
                                            type='primary'
                                            onClick={() => {
                                                const filteredDiscounts = changeReordering.discounts.map(
                                                    (discount) => ({
                                                        code: discount.discount_code,
                                                        description: discount.discount_detail,
                                                        additional: discount.additional_details,
                                                    }),
                                                )
                                                updateAllDiscountCodes(changeReordering.discounts)
                                                setCouponData((prev) => ({
                                                    ...prev,
                                                    isSortClicked: false,
                                                    couponList: filteredDiscounts,
                                                }))
                                                setChangeReordering({
                                                    open: false,
                                                    discounts: [],
                                                })
                                            }}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setChangeReordering({
                                                    open: false,
                                                    discounts: [],
                                                })
                                                setCouponData((prev) => ({
                                                    ...prev,
                                                    isSortClicked: false,
                                                    couponList: [],
                                                }))
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Col>
                            </div>
                            <OfferSortOrder
                                discounts={changeReordering.discounts}
                                setDiscountOrder={(discounts) => {
                                    const filteredDiscounts = discounts.map((discount) => ({
                                        code: discount.discount_code,
                                        description: discount.discount_detail,
                                        additional: discount.additional_details,
                                    }))
                                    setChangeReordering((prev) => ({ ...prev, discounts }))
                                    setCouponData((prev) => ({ ...prev, couponList: filteredDiscounts }))
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <Card size='small'>
                                <div className='flex w-full flex-col gap-y-2'>
                                    <div className='flex justify-between items-center'>
                                        <div>
                                            <p className='text-base font-medium flex items-center'>
                                                Show Recommended Offer
                                                <span className='ml-2 px-2 py-1 bg-blue-100 text-blue-500 text-xs font-medium rounded inline-flex items-center'>
                                                    <img
                                                        src={Sparkle}
                                                        alt='Sparkle'
                                                        className='w-[15px] h-[15px] mr-1'
                                                    />
                                                    Recommended
                                                </span>
                                            </p>
                                            <p className='text-gray-500 text-sm mt-1'>
                                                Enabling this feature lets customers discover the best available manual
                                                discount for them and apply it within one click on Checkout.
                                            </p>
                                        </div>
                                        <Switch
                                            checked={inputs?.is_active}
                                            onChange={(value) => {
                                                setInputs((prev) => ({ ...prev, is_active: value }))
                                                const configChanged = value !== inputs.is_active
                                                if (value === config?.custom_config?.one_click_offer?.is_active) {
                                                    setSaved(false)
                                                } else {
                                                    setSaved(configChanged)
                                                }
                                            }}
                                        />
                                    </div>

                                    {inputs?.is_active && (
                                        <>
                                            <Form.Item className='mt-2'>
                                                <p className='text-sm font-medium'>Type</p>
                                                <Radio.Group
                                                    className='mt-2'
                                                    value={inputs?.type}
                                                    onChange={(e) => {
                                                        setInputs((prev) => ({
                                                            ...prev,
                                                            type: e.target.value,
                                                            auto_apply:
                                                                e.target.value === 'exact_discount_multiple'
                                                                    ? true
                                                                    : inputs.auto_apply,
                                                        }))
                                                        const configChanged = e.target.value !== inputs.type
                                                        if (
                                                            e.target.value ===
                                                            config?.custom_config?.one_click_offer?.type
                                                        ) {
                                                            setSaved(false)
                                                        }
                                                        if (
                                                            (e.target.value === 'exact_discount_multiple' &&
                                                                !inputs.stacking_code_list.length) ||
                                                            (e.target.value === 'exact_discount' && !inputs.code)
                                                        ) {
                                                            setSaved(false)
                                                        } else {
                                                            setSaved(configChanged)
                                                        }
                                                    }}
                                                >
                                                    <Radio value='max_discount' className='text-gray-700'>
                                                        Max Discount First{' '}
                                                        <span className='ml-2 px-2 py-1 bg-blue-100 text-blue-500 text-xs font-medium rounded'>
                                                            Recommended
                                                        </span>
                                                    </Radio>
                                                    <Radio value='min_discount' className='text-gray-700'>
                                                        Min Discount First
                                                    </Radio>
                                                    <Radio value='exact_discount' className='text-gray-700'>
                                                        Exact Code
                                                    </Radio>
                                                    <Radio value='exact_discount_multiple' className='text-gray-700'>
                                                        Multiple Codes
                                                    </Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                            {inputs.type?.includes('exact_discount') && (
                                                <>
                                                    <Select
                                                        placeholder={
                                                            isBestOfferTypeMultiple
                                                                ? 'Select upto five codes'
                                                                : 'Select code'
                                                        }
                                                        label={
                                                            isBestOfferTypeMultiple
                                                                ? 'Select Multiple Codes'
                                                                : 'Select an Exact Code'
                                                        }
                                                        className='w-full'
                                                        options={(isBestOfferTypeMultiple
                                                            ? offersData.filter(
                                                                  (offer) =>
                                                                      !(
                                                                          offer.discount_type === 'DiscountCodeBxgy' ||
                                                                          !offer.is_stackable
                                                                      ),
                                                              )
                                                            : offersData
                                                        ).map((offer) => ({
                                                            label: offer.discount_code,
                                                            value: offer.discount_code,
                                                        }))}
                                                        value={
                                                            isBestOfferTypeMultiple
                                                                ? inputs.stacking_code_list
                                                                : inputs.code
                                                        }
                                                        mode={isBestOfferTypeMultiple ? 'multiple' : undefined}
                                                        onChange={(value) => {
                                                            if (isBestOfferTypeMultiple && value.length > 5) {
                                                                message.error('You can select a maximum of 5 codes')
                                                                return
                                                            }
                                                            const keyToSet = isBestOfferTypeMultiple
                                                                ? 'stacking_code_list'
                                                                : 'code'

                                                            setInputs((prev) => ({ ...prev, [keyToSet]: value }))
                                                            const configChanged = isBestOfferTypeMultiple
                                                                ? value.length !== inputs.stacking_code_list.length
                                                                : value !== inputs.code

                                                            setSaved(configChanged)
                                                        }}
                                                    />
                                                    {isBestOfferTypeMultiple && (
                                                        <Alert
                                                            type='info'
                                                            showIcon
                                                            message='BXGY and non combinable manual discounts are not available for selection.'
                                                        />
                                                    )}
                                                </>
                                            )}
                                            <Divider className='my-0' />
                                            <div className='flex justify-between items-center space-y-4 mt-2'>
                                                <div className='w-full'>
                                                    <p className='font-medium'>
                                                        Auto Apply Recommended Offer{' '}
                                                        <span className='ml-2 px-2 py-1 bg-blue-100 text-blue-500 text-xs font-medium rounded'>
                                                            <img
                                                                src={Sparkle}
                                                                style={{ width: '15px', height: '15px' }}
                                                            ></img>
                                                            Recommended
                                                        </span>
                                                    </p>
                                                    <p className='text-sm text-gray-600'>
                                                        Enabling this feature auto applies the best available discount
                                                        on checkout for customers on Checkout.
                                                    </p>
                                                </div>
                                                <div>
                                                    <Tooltip
                                                        title={
                                                            inputs.type === 'exact_discount_multiple'
                                                                ? 'Auto apply will be enabled by default for multiple codes'
                                                                : ''
                                                        }
                                                    >
                                                        <Switch
                                                            checked={inputs?.auto_apply}
                                                            disabled={inputs.type === 'exact_discount_multiple'}
                                                            onChange={(value) => {
                                                                setInputs((prev) => ({ ...prev, auto_apply: value }))
                                                                const configChanged = value !== inputs.auto_apply
                                                                if (
                                                                    value ===
                                                                    config?.custom_config?.one_click_offer?.auto_apply
                                                                ) {
                                                                    setSaved(false)
                                                                } else {
                                                                    setSaved(configChanged)
                                                                }
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Card>

                            <div className='mt-4'>
                                <div className='flex justify-between items-center pb-2'>
                                    <p className='text-base font-medium'>List of Coupons</p>
                                    <Button
                                        onClick={() => {
                                            const filteredDiscounts = offersData.map((discount) => ({
                                                code: discount.discount_code,
                                                description: discount.discount_detail,
                                                additional: discount.additional_details,
                                            }))
                                            setChangeReordering({ open: true, discounts: offersData })
                                            setCouponData((prev) => ({
                                                ...prev,
                                                isSortClicked: true,
                                                couponList: filteredDiscounts,
                                            }))
                                        }}
                                    >
                                        Sort Coupons
                                    </Button>
                                </div>
                                <Table
                                    columns={offersColumns}
                                    dataSource={offersData}
                                    pagination={{ pageSize: 20 }}
                                ></Table>
                            </div>
                        </>
                    )}
                </Form>
            )}
            {changeDescriptionDiscount && (
                <ChangeDiscountDescription
                    changeDescriptionDiscount={changeDescriptionDiscount}
                    onClose={(refetch?: boolean) => {
                        refetch && fetchDiscounts({ ...parameters })
                        setChangeDescriptionDiscount(null)
                    }}
                />
            )}
        </>
    )
}

export default coupons
