import { IShopifyAppPPCODConfig, IShopifyAppPPCODTieredAmount } from '@library/utilities/interface'
import { ShopifyAppPPCODAdditionalFeatures, ShopifyAppPPCODTypeEnum } from '@library/utilities/types'
import { Alert, Button, DeleteOutlined, InfoCircleOutlined, InputNumber, Select, Switch, Tooltip } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { maxTiers, ppcodTooltips, ppcodTypes, tierDefaultValues } from './constants'
import { ICommonEvents } from '@library/utilities/helpers/eventsHelper'

export const validatePppcodConfig = (config: IShopifyAppPPCODConfig): { msg: string; status: boolean } => {
    if (config.additionalFeatureFlags?.includes(ShopifyAppPPCODAdditionalFeatures.risk) && !config.riskFlags?.length) {
        return { msg: 'At least one risk flag is required for risk-based PPCOD', status: false }
    }

    if (!config.tieredAmounts?.length) {
        return { msg: 'Tiered amounts are required', status: false }
    }

    const tierErrors = config.tieredAmounts.reduce((errors, tier) => {
        if (tier.lowerLimit === null || tier.lowerLimit === undefined) {
            errors.push('Lower limit is required')
            return errors
        }

        if (tier.upperLimit && tier.upperLimit <= tier.lowerLimit) {
            errors.push('Upper limit should be greater than lower limit')
            return errors
        }

        if (!tier.amount) {
            errors.push('Price value is required')
            return errors
        }

        if (tier.type === ShopifyAppPPCODTypeEnum.percentage && tier.amount >= 100) {
            errors.push('Percentage should be less than 100')
            return errors
        }

        if (
            tier.type === ShopifyAppPPCODTypeEnum.percentage &&
            tier.maxDeduction &&
            tier.maxDeduction <= tier.minDeduction
        ) {
            errors.push('Maximum charges should be greater than minimum charges')
            return errors
        }

        return errors
    }, [] as string[])

    if (tierErrors.length) {
        return { msg: tierErrors.join(', '), status: false }
    }

    const sortedTiers = [...config.tieredAmounts].sort((a, b) => a.lowerLimit - b.lowerLimit)

    for (let i = 0; i < sortedTiers.length - 1; i++) {
        const tier1 = sortedTiers[i]
        const tier2 = sortedTiers[i + 1]

        if (!tier1.upperLimit || tier1.upperLimit >= tier2.lowerLimit) {
            return { msg: 'Please avoid ovelapping tiers', status: false }
        }
    }

    return { msg: 'Validation successful', status: true }
}

export const formatPpcodConfig = (ppcodConfig: IShopifyAppPPCODConfig) => {
    ppcodConfig?.tieredAmounts?.forEach((tier) => {
        for (const key in tier) {
            if (tier[key] === null || tier[key] === undefined) delete tier[key]
        }

        if (tier.type !== ShopifyAppPPCODTypeEnum.percentage) {
            delete tier.minDeduction
            delete tier.maxDeduction
        }
    })

    ppcodConfig.additionalFeatureFlags = ppcodConfig.additionalFeatureFlags.filter(
        (flag) => flag !== ShopifyAppPPCODAdditionalFeatures.standard,
    )

    return ppcodConfig
}

interface Props {
    config: any
    ppcodConfig: IShopifyAppPPCODConfig
    setPpcodConfig: any
    setIsUpdated: any
    tourInProgress: boolean
    ppcodEvents: ICommonEvents
}

const PpcodConfigForm = ({ config, ppcodConfig, setPpcodConfig, setIsUpdated, tourInProgress = false, ppcodEvents }: Props) => {
    const [addRangeButtonEnabled, setAddRangeButtonEnabled] = useState<boolean>(false)
    const [ppcodTypeOptions, setPpcodTypeOptions] = useState(ppcodTypes)

    const handleTogglePpcod = (checked: boolean) => {
        if (!checked) {
            setPpcodConfig(JSON.parse(JSON.stringify({ ...(config?.ppcodConfig || {}), baseCaseEnabled: false })))
        } else {
            const tiers =
                ppcodConfig?.tieredAmounts?.length > 0 ? ppcodConfig?.tieredAmounts : [{ ...tierDefaultValues }]
            setPpcodConfig({
                ...ppcodConfig,
                baseCaseEnabled: true,
                tieredAmounts: tiers,
            })
        }
        setIsUpdated(true)
        ppcodEvents.configsUpsert.edit.fireClickedEvent({
            prevPpcodFlag:  ppcodConfig.baseCaseEnabled,
            currentPpcodFlag: checked
        })
    }

    const handleAddTier = () => {
        if (ppcodConfig?.tieredAmounts?.length < maxTiers) {
            const tiers = [...ppcodConfig?.tieredAmounts]
            tiers.push({
                lowerLimit: tiers[tiers.length - 1].upperLimit + 0.01,
                upperLimit: null,
                type: ShopifyAppPPCODTypeEnum.fixed,
                amount: null,
                minDeduction: null,
                maxDeduction: null,
            } as IShopifyAppPPCODTieredAmount)
            setPpcodConfig({ ...ppcodConfig, tieredAmounts: tiers })
            setIsUpdated(true)
            ppcodEvents.configsUpsert.add.fireClickedEvent({
                tierAdded: {
                    lowerLimit: tiers[tiers.length - 1].upperLimit + 0.01,
                    upperLimit: null,
                    type: ShopifyAppPPCODTypeEnum.fixed,
                    amount: null,
                    minDeduction: null,
                    maxDeduction: null,
                }
            })
        }
    }

    const handleRemoveTier = (index) => {
        ppcodEvents.configsUpsert.delete.fireClickedEvent({
            tierRemoved: ppcodConfig?.tieredAmounts?.find((_, i) => i === index)
        });
        const updatedTiers = ppcodConfig?.tieredAmounts?.filter((_, i) => i !== index)
        setPpcodConfig({ ...ppcodConfig, tieredAmounts: updatedTiers })
        setIsUpdated(true)
    }

    const handleKeyDown = (e) => {
        if (e.key === '-' || e.key === '+' || e.key === 'e') {
            e.preventDefault()
        }
    }

    useEffect(() => {
        if (!config?.rtoEnabled) {
            const updatedOptions = ppcodTypes?.filter((type) => type.value !== 'risk')
            setPpcodTypeOptions(updatedOptions)
        } else setPpcodTypeOptions(ppcodTypes)
        ppcodEvents.fireClickedEvent({
            rtoEnabled: config?.rtoEnabled
        });
    }, [config?.rtoEnabled])

    useEffect(() => {
        if (ppcodConfig?.tieredAmounts?.length > 0) {
            const lastTier = ppcodConfig?.tieredAmounts?.[ppcodConfig?.tieredAmounts?.length - 1]

            if (
                lastTier?.lowerLimit >= 0 &&
                lastTier?.upperLimit &&
                lastTier?.upperLimit !== Number.MAX_SAFE_INTEGER &&
                lastTier?.upperLimit > lastTier?.lowerLimit &&
                lastTier?.amount > 0 &&
                ppcodConfig?.tieredAmounts?.length < maxTiers
            ) {
                setAddRangeButtonEnabled(true)
            } else {
                setAddRangeButtonEnabled(false)
            }
        }
    }, [ppcodConfig?.tieredAmounts])

    useEffect(() => {
        if (!ppcodConfig?.tieredAmounts?.length) {
            setPpcodConfig({ ...ppcodConfig, tieredAmounts: [{ ...tierDefaultValues }] })
        }
        if (!ppcodConfig?.additionalFeatureFlags?.length) {
            setPpcodConfig({ ...ppcodConfig, additionalFeatureFlags: ['standard'] })
        }
    }, [ppcodConfig])

    return (
        <div className='mx-auto w-full flex p-6 bg-white shadow rounded-md space-y-4 justify-between'>
            <div className='space-y-4'>
                <div className='flex max-w-3xl items-center mb-4 space-x-5'>
                    <div className=''>
                        <label className='block text-sm font-medium text-gray-700'>
                            PPCOD Type
                            <Tooltip title={ppcodTooltips.ppcodType}>
                                <InfoCircleOutlined className='ml-1 opacity-40' />
                            </Tooltip>
                        </label>
                        <div className='flex space-x-2 mt-1 select-ppcod-type-tour'>
                            <Select
                                style={{ minWidth: '150px', width: '100%' }}
                                placeholder='Standard'
                                options={ppcodTypeOptions}
                                value={
                                    ppcodConfig?.additionalFeatureFlags?.length > 0
                                        ? ppcodConfig?.additionalFeatureFlags[0]
                                        : 'standard'
                                }
                                onChange={(value) => {
                                    if (tourInProgress) return
                                    setPpcodConfig({ ...ppcodConfig, additionalFeatureFlags: [value] })
                                    setIsUpdated(true)
                                    ppcodEvents.configsUpsert.edit.fireClickedEvent({
                                        prevAdditionalFeatureFlags: ppcodConfig.additionalFeatureFlags,
                                        currentAdditionalFeatureFlags: [...(ppcodConfig?.additionalFeatureFlags || []), value]
                                    })
                                }}
                                disabled={!ppcodConfig?.baseCaseEnabled && !tourInProgress}
                            />
                        </div>
                    </div>
                    <div className='ml-4'>
                        {(ppcodConfig?.additionalFeatureFlags?.includes(ShopifyAppPPCODAdditionalFeatures.risk) ||
                            tourInProgress) && (
                            <div>
                                <label className='block text-sm font-medium text-gray-700'>
                                    Risk Flags
                                    <Tooltip title={ppcodTooltips.riskFlags}>
                                        <InfoCircleOutlined className='ml-1 opacity-40' />
                                    </Tooltip>
                                </label>
                                <div className='flex space-x-2 mt-1 select-ppcod-risk-flags-tour'>
                                    <Select
                                        placeholder='Risk Flags'
                                        style={{ minWidth: '150px', maxWidth: '100%' }}
                                        options={['High Risk', 'Medium Risk', 'Low Risk'].map((option) => ({
                                            label: option,
                                            value: option,
                                        }))}
                                        mode='multiple'
                                        value={ppcodConfig?.riskFlags || []}
                                        onChange={(value) => {
                                            if (tourInProgress) return
                                            setPpcodConfig({ ...ppcodConfig, riskFlags: value })
                                            setIsUpdated(true)
                                            ppcodEvents.configsUpsert.edit.fireClickedEvent({
                                                prevRiskFlags: ppcodConfig.riskFlags,
                                                currentRiskFlags: value
                                            })
                                        }}
                                        disabled={!ppcodConfig?.baseCaseEnabled && !tourInProgress}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {!ppcodConfig?.baseCaseEnabled && !tourInProgress ? (
                    <Alert message='Please enable ppcod to edit' type='warning' showIcon />
                ) : ppcodConfig?.additionalFeatureFlags?.includes(ShopifyAppPPCODAdditionalFeatures.tag) ? (
                    <Alert
                        message="Please add a tag named 'ppcod' on products where you want to give ppcod"
                        type='info'
                        showIcon
                    />
                ) : null}

                {/* This component is for tour purpose only */}
                {tourInProgress && (
                    <>
                        <div className='flex flex-1 mb-4 space-x-4 items-center percentage-ppcod-tour'>
                            <div className='mr-4'>
                                <label className='block text-sm font-medium text-gray-700'>
                                    Cart Range
                                    <Tooltip title={ppcodTooltips.cartRange}>
                                        <InfoCircleOutlined className='ml-1 opacity-40' />
                                    </Tooltip>
                                </label>
                                <div className='flex space-x-2 mt-1 ppcod-cart-range-tour'>
                                    <InputNumber
                                        required
                                        type='number'
                                        prefix={'₹'}
                                        min={0}
                                        max={Number.MAX_SAFE_INTEGER}
                                        defaultValue={0.0}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <span className='self-center'>-</span>
                                    <InputNumber
                                        type='number'
                                        prefix={'₹'}
                                        min={1}
                                        max={Number.MAX_SAFE_INTEGER}
                                        placeholder='No Limit'
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                            </div>

                            <div className='mr-4'>
                                <label className='block text-sm font-medium text-gray-700'>
                                    Price
                                    <Tooltip title={ppcodTooltips.price}>
                                        <InfoCircleOutlined className='ml-1 opacity-40' />
                                    </Tooltip>
                                </label>
                                <div className='flex space-x-2 mt-1 mr-4 ppcod-price-tour'>
                                    <Select
                                        style={{ minWidth: '120px', maxWidth: '100%' }}
                                        defaultValue={'percentage'}
                                        value={'percentage'}
                                    >
                                        <Select.Option value='fixed'>Fixed Amount</Select.Option>
                                        <Select.Option value='percentage'>Percentage</Select.Option>
                                    </Select>
                                    <InputNumber
                                        type='number'
                                        prefix={'%'}
                                        min={1}
                                        max={99.99}
                                        defaultValue={5.0}
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                            </div>
                            <div className='mr-4'>
                                <label className='block text-sm font-medium text-gray-700 flex items-center'>
                                    Min Charges
                                    <Tooltip title={ppcodTooltips.minCharge}>
                                        <InfoCircleOutlined className='ml-1 opacity-40' />
                                    </Tooltip>
                                </label>
                                <div className='space-x-2 mt-1 ppcod-min-charges-tour'>
                                    <InputNumber
                                        type='number'
                                        prefix={'₹'}
                                        min={0}
                                        max={Number.MAX_SAFE_INTEGER}
                                        defaultValue={100}
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                            </div>
                            <div className='mr-4 min-w-120px'>
                                <label className='block text-sm font-medium text-gray-700'>
                                    Max Charges
                                    <Tooltip title={ppcodTooltips.maxCharge}>
                                        <InfoCircleOutlined className='ml-1 opacity-40' />
                                    </Tooltip>
                                </label>
                                <div className='flex space-x-2 mt-1 ppcod-max-charges-tour'>
                                    <InputNumber
                                        type='number'
                                        prefix={'₹'}
                                        min={1}
                                        max={Number.MAX_SAFE_INTEGER}
                                        defaultValue={500}
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-1 mb-4 space-x-4 items-center fixed-ppcod-tour'>
                            <div className='mr-4'>
                                <label className='block text-sm font-medium text-gray-700'>
                                    Cart Range
                                    <Tooltip title={ppcodTooltips.cartRange}>
                                        <InfoCircleOutlined className='ml-1 opacity-40' />
                                    </Tooltip>
                                </label>
                                <div className='flex space-x-2 mt-1 ppcod-cart-range-tour'>
                                    <InputNumber
                                        required
                                        type='number'
                                        prefix={'₹'}
                                        min={0}
                                        max={Number.MAX_SAFE_INTEGER}
                                        defaultValue={0.0}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <span className='self-center'>-</span>
                                    <InputNumber
                                        type='number'
                                        prefix={'₹'}
                                        min={1}
                                        max={Number.MAX_SAFE_INTEGER}
                                        placeholder='No Limit'
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                            </div>

                            <div className='mr-4'>
                                <label className='block text-sm font-medium text-gray-700'>
                                    Price
                                    <Tooltip title={ppcodTooltips.price}>
                                        <InfoCircleOutlined className='ml-1 opacity-40' />
                                    </Tooltip>
                                </label>
                                <div className='flex space-x-2 mt-1 mr-4 ppcod-price-tour'>
                                    <Select
                                        style={{ minWidth: '120px', maxWidth: '100%' }}
                                        defaultValue={'fixed'}
                                        value={'fixed'}
                                    >
                                        <Select.Option value='fixed'>Fixed Amount</Select.Option>
                                        <Select.Option value='percentage'>Percentage</Select.Option>
                                    </Select>
                                    <InputNumber
                                        type='number'
                                        prefix={'₹'}
                                        min={1}
                                        max={Number.MAX_SAFE_INTEGER}
                                        defaultValue={100}
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {!tourInProgress &&
                    ppcodConfig?.tieredAmounts?.map((tier, index) => (
                        <div key={index} className='flex flex-1 mb-4 space-x-4 items-center'>
                            <div className='mr-4'>
                                <label className='block text-sm font-medium text-gray-700'>
                                    Cart Range
                                    <Tooltip title={ppcodTooltips.cartRange}>
                                        <InfoCircleOutlined className='ml-1 opacity-40' />
                                    </Tooltip>
                                </label>
                                <div className='flex space-x-2 mt-1 ppcod-cart-range-tour'>
                                    <InputNumber
                                        required
                                        type='number'
                                        prefix={'₹'}
                                        min={0}
                                        max={Number.MAX_SAFE_INTEGER}
                                        value={tier?.lowerLimit}
                                        onKeyDown={handleKeyDown}
                                        onChange={(value: number) => {
                                            const tiers = JSON.parse(JSON.stringify([...ppcodConfig?.tieredAmounts]));
                                            tiers[index].lowerLimit = value
                                            ppcodEvents.configsUpsert.edit.fireClickedEvent({
                                                previousTiers: ppcodConfig.tieredAmounts[index],
                                                currentTiers: tiers[index]
                                            })
                                            setPpcodConfig({ ...ppcodConfig, tieredAmounts: tiers })
                                            setIsUpdated(true)
                                        }}
                                        disabled={!ppcodConfig?.baseCaseEnabled}
                                    />
                                    <span className='self-center'>-</span>
                                    <InputNumber
                                        type='number'
                                        prefix={'₹'}
                                        min={1}
                                        max={Number.MAX_SAFE_INTEGER}
                                        placeholder='No Limit'
                                        value={tier?.upperLimit === Number.MAX_SAFE_INTEGER ? null : tier?.upperLimit}
                                        onKeyDown={handleKeyDown}
                                        onChange={(value: number) => {
                                            const tiers = JSON.parse(JSON.stringify([...ppcodConfig?.tieredAmounts]));
                                            tiers[index].upperLimit = value
                                            ppcodEvents.configsUpsert.edit.fireClickedEvent({
                                                previousTiers: ppcodConfig.tieredAmounts[index],
                                                currentTiers: tiers[index]
                                            })
                                            setPpcodConfig({ ...ppcodConfig, tieredAmounts: tiers })
                                            setIsUpdated(true)
                                        }}
                                        disabled={!ppcodConfig?.baseCaseEnabled}
                                    />
                                </div>
                            </div>

                            <div className='mr-4'>
                                <label className='block text-sm font-medium text-gray-700'>
                                    Price
                                    <Tooltip title={ppcodTooltips.price}>
                                        <InfoCircleOutlined className='ml-1 opacity-40' />
                                    </Tooltip>
                                </label>
                                <div className='flex space-x-2 mt-1 mr-4 ppcod-price-tour'>
                                    <Select
                                        style={{ minWidth: '120px', maxWidth: '100%' }}
                                        value={tier?.type}
                                        onChange={(value) => {
                                            const tiers = JSON.parse(JSON.stringify([...ppcodConfig?.tieredAmounts]));
                                            tiers[index].type = value
                                            ppcodEvents.configsUpsert.edit.fireClickedEvent({
                                                previousTiers: ppcodConfig.tieredAmounts[index],
                                                currentTiers: tiers[index]
                                            })
                                            setPpcodConfig({ ...ppcodConfig, tieredAmounts: tiers })
                                            setIsUpdated(true)
                                        }}
                                        defaultValue={'fixed'}
                                        disabled={!ppcodConfig?.baseCaseEnabled && !tourInProgress}
                                    >
                                        <Select.Option value='fixed'>Fixed Amount</Select.Option>
                                        <Select.Option value='percentage'>Percentage</Select.Option>
                                    </Select>
                                    <InputNumber
                                        type='number'
                                        prefix={tier.type === ShopifyAppPPCODTypeEnum.fixed ? '₹' : '%'}
                                        min={1}
                                        max={
                                            tier.type === ShopifyAppPPCODTypeEnum.fixed
                                                ? Number.MAX_SAFE_INTEGER
                                                : 99.99
                                        }
                                        value={tier?.amount}
                                        onKeyDown={handleKeyDown}
                                        onChange={(value: number) => {
                                            const tiers = JSON.parse(JSON.stringify([...ppcodConfig?.tieredAmounts]));
                                            tiers[index].amount = value
                                            ppcodEvents.configsUpsert.edit.fireClickedEvent({
                                                previousTiers: ppcodConfig.tieredAmounts[index],
                                                currentTiers: tiers[index]
                                            })
                                            setPpcodConfig({ ...ppcodConfig, tieredAmounts: tiers })
                                            setIsUpdated(true)
                                        }}
                                        disabled={!ppcodConfig?.baseCaseEnabled}
                                    />
                                </div>
                            </div>

                            {tier.type === ShopifyAppPPCODTypeEnum.percentage && (
                                <>
                                    <div className='mr-4'>
                                        <label className='block text-sm font-medium text-gray-700 flex items-center'>
                                            Min Charges
                                            <Tooltip title={ppcodTooltips.minCharge}>
                                                <InfoCircleOutlined className='ml-1 opacity-40' />
                                            </Tooltip>
                                        </label>
                                        <div className='space-x-2 mt-1 ppcod-min-charges-tour'>
                                            <InputNumber
                                                type='number'
                                                prefix={'₹'}
                                                min={0}
                                                max={Number.MAX_SAFE_INTEGER}
                                                value={tier?.minDeduction}
                                                onKeyDown={handleKeyDown}
                                                onChange={(value: number) => {
                                                    const tiers = JSON.parse(JSON.stringify([...ppcodConfig?.tieredAmounts]));
                                                    tiers[index].minDeduction = value
                                                    ppcodEvents.configsUpsert.edit.fireClickedEvent({
                                                        previousTiers: ppcodConfig.tieredAmounts[index],
                                                        currentTiers: tiers[index]
                                                    })
                                                    setPpcodConfig({ ...ppcodConfig, tieredAmounts: tiers })
                                                    setIsUpdated(true)
                                                }}
                                                disabled={!ppcodConfig?.baseCaseEnabled}
                                            />
                                        </div>
                                    </div>
                                    <div className='mr-4 min-w-120px'>
                                        <label className='block text-sm font-medium text-gray-700'>
                                            Max Charges
                                            <Tooltip title={ppcodTooltips.maxCharge}>
                                                <InfoCircleOutlined className='ml-1 opacity-40' />
                                            </Tooltip>
                                        </label>
                                        <div className='flex space-x-2 mt-1 ppcod-max-charges-tour'>
                                            <InputNumber
                                                type='number'
                                                prefix={'₹'}
                                                min={1}
                                                max={Number.MAX_SAFE_INTEGER}
                                                value={
                                                    tier?.maxDeduction === Number.MAX_SAFE_INTEGER
                                                        ? null
                                                        : tier?.maxDeduction
                                                }
                                                onKeyDown={handleKeyDown}
                                                onChange={(value: number) => {
                                                    const tiers = JSON.parse(JSON.stringify([...ppcodConfig?.tieredAmounts]));
                                                    tiers[index].maxDeduction = value
                                                    ppcodEvents.configsUpsert.edit.fireClickedEvent({
                                                        previousTiers: ppcodConfig.tieredAmounts[index],
                                                        currentTiers: tiers[index]
                                                    })
                                                    setPpcodConfig({ ...ppcodConfig, tieredAmounts: tiers })
                                                    setIsUpdated(true)
                                                }}
                                                disabled={!ppcodConfig?.baseCaseEnabled}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {index > 0 && (
                                <div>
                                    <label></label>
                                    <div className='flex space-x-2 mt-1'>
                                        <Button
                                            onClick={() => handleRemoveTier(index)}
                                            disabled={!ppcodConfig?.baseCaseEnabled}
                                            danger
                                        >
                                            <DeleteOutlined />
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                {(addRangeButtonEnabled || tourInProgress) && (
                    <Button
                        id='add-ppcod-range-tour'
                        disabled={!ppcodConfig?.baseCaseEnabled}
                        onClick={handleAddTier}
                        variant='primary'
                    >
                        <span className='mr-2'>+</span> Add Range
                    </Button>
                )}
                {ppcodConfig?.tieredAmounts?.length >= maxTiers && (
                    <Alert message={`You're limited to configure upto ${maxTiers} cart ranges`} type='info' showIcon />
                )}
            </div>
            <div className='flex justify-end mb-4'>
                <span className='block mr-2 text-sm font-medium text-gray-700'>
                    {ppcodConfig?.baseCaseEnabled ? 'Disable' : 'Enable'}
                    <Tooltip title={ppcodTooltips.baseCaseFlag}>
                        <InfoCircleOutlined className='ml-1 opacity-40' />
                    </Tooltip>
                </span>
                <Switch
                    className='toggle-ppcod-button'
                    checked={ppcodConfig?.baseCaseEnabled}
                    onChange={handleTogglePpcod}
                    disabled={tourInProgress}
                />
            </div>
        </div>
    )
}

export default PpcodConfigForm
