import {
    AnalyticsCard,
    AnalyticsDateRangePicker,
    Col,
    DownloadOutlined,
    LockOutlined,
    Popconfirm,
    Row,
    Select,
    StatsCards,
    SummaryBlocks,
    Tooltip,
    message,
    Table,
} from 'gokwik-ui-kit'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
// import AnalyticsCard from '@library/components/analytics-card'
import { AnalyticsDate } from '@library/utilities/interface'
import FunnelChart from '@library/components/funnel-chart'
import Chart from '@pages/analytics/chart'
import { ChartRows, SummaryBlocksData, SummaryBlocksDataSources, checkoutFunnelFilters } from './chartsData'
import { useAppDispatch } from '@library/utilities/hooks'
import { fetchAllAnalyticsData, fetchAnalyticsDataAsync } from '@store/analytics'
import { useSelector } from 'react-redux'
import { navigateToUrl } from 'single-spa'
import { getAnalyticsData } from '@store/analytics/selectors'
import { addBreadcrumb, makeAPICall, titleCase, updateBreadcrumbs } from '@gokwik/utilities'
import { analyticsAPIs } from '@store/analytics/api'
import { getMerchantDetails, getUserConfig, getUserDetails } from '@store/user/selectors'
import { getCookie } from '@gokwik/utilities'
import { isShopifyAppCheckoutEnabled } from '@store/shopify-app/selectors'
import { fetchShopifyAppMerchantConfig } from '@store/shopify-app'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'
import { useNavigate } from 'react-router-dom'

const salesChannelOptions = {
    all: 'All',
    web: 'Web',
    app: 'App',
}

const triggerDownload = async (apiKey: string, params: Record<string, string> = {}) => {
    const api = analyticsAPIs[apiKey].url
    try {
        const mode = localStorage.getItem('mode')
        const token = localStorage.getItem('token') || getCookie('token')

        params = {
            ...params,
            mode,
            response_mode: 'download',
        }
        const apiUrl = new URL(process.env.REACT_APP_BASE_URL + api)
        Object.keys(params).forEach((key) => apiUrl.searchParams.append(key, params[key]))
        const res = await makeAPICall({
            method: 'get',
            url: apiUrl.toString(),
        })
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${res.headers['content-disposition'].split('filename=')[1].trim()}`)
        link.click()

        message.success('Download Initiated')
        return { success: true }
    } catch (error) {
        console.error('Error while triggering download:', error)
        return { success: false, error: error.message }
    }
}

const redirectToReport = (reportName, startDate, endDate) => {
    navigateToUrl(`/general/reports?from=${reportName}&start=${startDate.valueOf()}&end=${endDate.valueOf()}`)
}

const formatNumber = (val: number) => Intl.NumberFormat('en-IN').format(val)

const format = 'YYYY-MM-DD'
const today = dayjs()
const yesterday = today.subtract(1, 'day')
const dayBeforeYesterday = today.subtract(2, 'day')

const CheckoutAnalytics = () => {
    const navigate = useNavigate()
    const merchantDetails = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const config = useSelector(getUserConfig)
    const platformShopify = merchantDetails?.platform === 'shopify'
    const isGKP = merchantDetails?.platform === 'custom' || merchantDetails?.platform === 'woocommerce'
    const [globalFilters, setGlobalFilters] = useState<{
        salesChannel: 'all' | 'web' | 'app'
    }>({
        salesChannel: 'all',
    })
    const [funnelFilters, setFunnelFilters] = useState({
        checkoutConversion: {
            trend: 'funnel',
            metrics: 'SuccessfulOrders',
        },
    })
    const shopifyAppCheckoutEnabled = useSelector(isShopifyAppCheckoutEnabled)
    const [dates, setDates] = useState<{ start: AnalyticsDate; end: AnalyticsDate }>({
        start: {
            from: yesterday,
            to: yesterday,
            label: yesterday.format('DD MMM YYYY'),
        },
        end: {
            from: dayBeforeYesterday,
            to: dayBeforeYesterday,
            label: dayBeforeYesterday.format('DD MMM YYYY'),
        },
    })
    const formattedDates = useMemo(() => {
        return {
            current_datetime_range: `${dates.start.from.format(format)},${dates.start.to.format(format)}`,
            ...(dates.start.label !== dates.end.label && {
                compared_datetime_range: `${dates.end.from.format(format)},${dates.end.to.format(format)}`,
            }),
        }
    }, [dates])

    const checkoutPlatformKeys = useMemo(
        () =>
            Object.entries(config?.supported_checkout_configs || {}).reduce(
                (result: string[], item: [string, boolean]) => (item[1] ? [...result, item[0]] : result),
                [],
            ),
        [config],
    )

    const [checkoutPlatform, setCheckoutPlatform] = useState('checkout_1')

    const analyticsData = useSelector(getAnalyticsData)
    const updateDates = useCallback((values, type) => {
        setDates((prev) => ({
            ...prev,
            [type]: {
                from: values.values[0],
                to: values.values[1],
                label: values.label,
            },
        }))
    }, [])
    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'checkout-analytics',
                href: '/checkout/analytics',
                text: 'Checkout Analytics',
            },
        ])
    }, [])
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (merchantDetails) {
            let ignoreKeys = []
            if (dates.start.to.diff(dates.start.from, 'days') > 3) {
                funnelFilters.checkoutConversion.trend === 'funnelTrendMetrics' &&
                    setFunnelFilters((prev) => ({
                        ...prev,
                        checkoutConversion: { ...prev.checkoutConversion, trend: 'funnel' },
                    }))
                ignoreKeys.push('funnelTrendMetrics')
            }
            dispatch(
                fetchAllAnalyticsData({
                    params: {
                        ...formattedDates,
                        m_id: merchantDetails?.id,
                        sales_channel: globalFilters.salesChannel,
                    },
                    ignoreKeys,
                }),
            )
        }
    }, [dates, globalFilters])

    useEffect(() => {
        if (config?.enhancer_app) {
            dispatch(fetchShopifyAppMerchantConfig())
        }
        logEvent(
            'kwik_checkout_analytics_clicked',
            'click',
            'Checkout Analytics',
            user_details?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            user_details?.name,
        )
    }, [])

    const DownloadIcon = ({ apiKey, utm }: { apiKey: string; utm?: string }) => (
        <DownloadOutlined
            className='cursor-pointer ml-3 text-lg '
            onClick={() =>
                triggerDownload(apiKey, {
                    ...formattedDates,
                })
            }
        />
    )

    const disabled90DaysDate: any = (current, { from, type }) => {
        const getYearMonth = (date: Dayjs) => date.year() * 12 + date.month();
        if (from) {
          const minDate = from.add(-90, 'days');
          const maxDate = from.add(90, 'days').isAfter(today) ? today : from.add(965, 'days');
      
          switch (type) {
            case 'year':
              return current.year() < minDate.year() || current.year() > maxDate.year();
      
            case 'month':
              return (
                getYearMonth(current) < getYearMonth(minDate) ||
                getYearMonth(current) > getYearMonth(maxDate)
              );
      
            default:
              return Math.abs(current.diff(from, 'days')) >= 90;
          }
        }
      
        return false;
    };

    const showKwikCodLock =
        config.enhancer_app &&
        checkoutPlatformKeys?.length === 1 &&
        checkoutPlatformKeys[0] === 'kwik_checkout' &&
        !shopifyAppCheckoutEnabled

    const blocksData = useMemo(() => {
        return SummaryBlocksData.map((block) => {
            const data = analyticsData[block.dataKey]
            return {
                title: block.heading,
                tooltipText: block.tooltipText,
                heroValue:
                    (showKwikCodLock && block.kwikCodLock ? (
                        <Tooltip title='Please complete KYC / contact Gokwik Support team to enable Kwik Checkout and unlock this'>
                            <LockOutlined className='text-2xl text-blue-500' />
                        </Tooltip>
                    ) : block.nestedKey ? (
                        typeof block.nestedKey === 'string' ? (
                            analyticsData[block.dataKey]?.[block.nestedKey]
                        ) : (
                            block.nestedKey.reduce((result, curKey) => result?.[curKey], analyticsData[block.dataKey])
                        )
                    ) : (
                        data.data
                    )) || '-',
                heroValueTransform: block.heroValueTransform,
                loading: data?.status === 'loading' || data?.[block.nestedKey[0]]?.status === 'loading',
            }
        })
    }, [analyticsData, showKwikCodLock])
    const blocksDataFromSources = useMemo(() => {
        return SummaryBlocksDataSources.map((block) => {
            const data = analyticsData[block.dataKey]
            return {
                title: block.heading,
                tooltipText: block.tooltipText,
                heroValue:
                    (block.nestedKey
                        ? typeof block.nestedKey === 'string'
                            ? analyticsData[block.dataKey]?.[block.nestedKey]
                            : block.nestedKey.reduce((result, curKey) => result?.[curKey], analyticsData[block.dataKey])
                        : data.data) || '-',
                heroValueTransform: block.heroValueTransform,
                loading: data?.status === 'loading' || data?.[block.nestedKey[0]]?.status === 'loading',
            }
        })
    }, [analyticsData])

    const checkKeys = (index) => {
        const keysToCheck = config?.selected_checkout_charts
        let exist = false
        for (const itm of ChartRows[index]?.charts ?? []) {
            if (!itm.permissionKey || keysToCheck.includes(itm.permissionKey)) {
                exist = true
                break
            }
        }
        return exist
    }

    const KwikCodLock = () => {
        return (
            <div className='text-center'>
                <LockOutlined className='text-8xl text-blue-500' />
                <p>Please complete KYC / contact Gokwik Support team to enable Kwik Checkout and unlock this graph</p>
            </div>
        )
    }

    return (
        <Row className='checkout-analytics h-max pb-4' gutter={[0, 24]}>
            <Col span={8} className='flex items-end'>
                {checkoutPlatformKeys.length > 1 && (
                    <Select
                        className='w-full mb-4'
                        value={checkoutPlatform}
                        onChange={(value) => {
                            localStorage.setItem('checkoutPlatform', value)
                            setCheckoutPlatform(value)
                            if (value === 'kwik_checkout') navigate(`/shopify-app/analytics`)
                        }}
                        options={checkoutPlatformKeys.map((item: string) => ({
                            label: titleCase(item.split('_').join(' ')),
                            value: item,
                        }))}
                    />
                )}
            </Col>
            <Col className='h-max' span={16}>
                <Row justify={'end'} gutter={8}>
                    { !config.enhancer_app && (
                        <Col>
                            <Select
                                optionRender={(option) => option.label.toString().split(':')[1]}
                                value={globalFilters.salesChannel}
                                options={Object.entries(salesChannelOptions).map(([value, label]) => ({
                                    label: `Sales Channel: ${label}`,
                                    value,
                                }))}
                                onChange={(value) => setGlobalFilters((prev) => ({ ...prev, salesChannel: value }))}
                            />
                        </Col>
                    )}
                    <Col>
                        <AnalyticsDateRangePicker
                            values={[dates.start.from, dates.start.to]}
                            onApply={(values, label) => {
                                updateDates({ values, label }, 'start')
                                logEvent(
                                    'kwik_checkout_analytics_date_selected_clicked',
                                    'click',
                                    'Checkout Analytics',
                                    user_details?.email,
                                    merchantDetails?.m_id,
                                    merchantDetails?.short_name,
                                    user_details?.name,
                                    { label, values },
                                )
                            }}
                            disabledDate={disabled90DaysDate}
                            secondaryValues={[dates.end.from, dates.end.to]}
                            onSecondaryApply={(values, label) => {
                                updateDates({ values, label }, 'end')
                                logEvent(
                                    'kwik_checkout_analytics_comparison_date_selected_clicked',
                                    'click',
                                    'Checkout Analytics',
                                    user_details?.email,
                                    merchantDetails?.m_id,
                                    merchantDetails?.short_name,
                                    user_details?.name,
                                    { label, values },
                                )
                            }}
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <SummaryBlocks data={blocksData} />
            </Col>
            <Col className='non-gk-abc' span={24}>
                {platformShopify && !config.enhancer_app && <SummaryBlocks data={blocksDataFromSources} />}
            </Col>
            <Col span={24}>
                <Row gutter={[16, 16]}>
                    {config?.selected_checkout_charts?.includes('checkoutConversionRate') && (
                        <Col span={16}>
                            <AnalyticsCard
                                title='Checkout Conversion Rate'
                                heroValue={analyticsData.funnel?.data?.conversion || 0}
                                valueDelta={analyticsData.funnel?.data?.percentageChange || 0}
                                loading={analyticsData.funnel?.status === 'loading'}
                                subHeading='ORDER VALUE OVER TIME'
                                subHeadingTooltip='Step level conversions of GoKwik checkout'
                                noData={!analyticsData.funnel?.data?.conversionFunnel}
                                lastUpdatedAt={analyticsData.funnel?.data?.lastUpdatedAt}
                                heroValueTransform='percentage'
                                {...
                                    !config.enhancer_app && {
                                        filters: checkoutFunnelFilters(
                                            funnelFilters.checkoutConversion.trend,
                                            dates.start.to.diff(dates.start.from, 'days'),
                                        ),
                                        filterValues: funnelFilters.checkoutConversion,
                                        filterOnChange: (key, value) => {
                                            setFunnelFilters((prev) => ({
                                                ...prev,
                                                checkoutConversion: { ...prev.checkoutConversion, [key]: value },
                                            }))
                                        },
                                    }}
                                titleTooltip={`Step wise GoKwik checkout conversion funnel (on shopify session basis)`}
                                customHeaderActions={
                                    <Popconfirm
                                        title={<>Please Select Report Type</>} //bold
                                        onConfirm={() =>
                                            redirectToReport(
                                                'checkoutAnalyticsFunnelWithMarketingParams',
                                                dates.start.from,
                                                dates.start.to,
                                            )
                                        }
                                        onCancel={() =>
                                            redirectToReport(
                                                'checkoutAnalyticsFunnel',
                                                dates.start.from,
                                                dates.start.to,
                                            )
                                        }
                                        okButtonProps={{
                                            className: `bg-success-500 border-none text-white hover:!bg-success-500 hover:!text-white`,
                                        }} // green
                                        cancelButtonProps={{
                                            className: `bg-success-500 border-none text-white hover:!bg-success-500 hover:!text-white`,
                                        }} // green
                                        okText='With Marketing Parameters (refreshes every 6 hours)'
                                        cancelText='Without Marketing Parameters'
                                        icon
                                        placement='topRight'
                                    >
                                        <DownloadOutlined className='cursor-pointer ml-3 text-lg' />
                                    </Popconfirm>
                                }
                                noDataContent={showKwikCodLock && <KwikCodLock />}
                            >
                                {funnelFilters.checkoutConversion.trend === 'funnel' ? (
                                    <FunnelChart dates={dates} data={analyticsData.funnel?.data || {}} />
                                ) : (
                                    <Chart
                                        wrapperClassName='min-h-[288px] h-full'
                                        chartType='line'
                                        isAmount={false}
                                        dates={dates}
                                        data={analyticsData.funnelTrendMetrics?.data}
                                        valueKey={funnelFilters.checkoutConversion.metrics}
                                    />
                                )}
                            </AnalyticsCard>
                        </Col>
                    )}
                    {config?.selected_checkout_charts?.includes('sessionsWithPrefilledAddresses') && (
                        <Col span={8}>
                            <AnalyticsCard
                                title='Sessions With Prefilled Addresses'
                                heroValue={analyticsData.addressPrefillTrendMetrics?.data?.conversion || 0}
                                valueDelta={analyticsData.addressPrefillTrendMetrics?.data?.percentageChange || 0}
                                loading={analyticsData.addressPrefillTrendMetrics?.status === 'loading'}
                                noData={!analyticsData.addressPrefillTrendMetrics?.data?.conversionFunnel}
                                lastUpdatedAt={analyticsData.addressPrefillTrendMetrics?.data?.lastUpdatedAt}
                                titleTooltip='% Customers who got a repeat user experience with pre-filled addresses'
                                subHeading={`${formatNumber(
                                    analyticsData.addressPrefillTrendMetrics?.data?.conversionFunnel?.[1].primaryValue,
                                )} OUT OF ${formatNumber(
                                    analyticsData.addressPrefillTrendMetrics?.data?.conversionFunnel?.[0].primaryValue,
                                )} PREFILLED`}
                                subHeadingTooltip='Step level conversions of GoKwik checkout'
                                heroValueTransform='percentage'
                                customHeaderActions={<DownloadIcon apiKey='addressPrefillTrendMetrics' />}
                                noDataContent={showKwikCodLock && <KwikCodLock />}
                            >
                                <FunnelChart
                                    dates={dates}
                                    data={analyticsData.addressPrefillTrendMetrics?.data || {}}
                                />
                            </AnalyticsCard>
                        </Col>
                    )}
                </Row>
            </Col>
            {ChartRows.map((row, index) => (
                <Col span={24} key={row.title}>
                    <Row gutter={[16, 16]}>
                        {checkKeys(index) && (
                            <Col span={24}>
                                <span className='font-semibold text-xl'>{row.title}</span>
                            </Col>
                        )}

                        {row.charts.map((chart) => {
                            if (
                                chart.permissionKey &&
                                !config?.selected_checkout_charts?.includes(chart?.permissionKey)
                            ) {
                                return null
                            }
                            if(chart.validForGkp === false && isGKP ){
                                return null
                            }

                            let data
                            if (chart.nestedKey) {
                                if (Array.isArray(chart.nestedKey)) {
                                    data = chart.nestedKey.reduce((acc, key) => {
                                        return acc && acc[key] !== undefined ? acc[key] : undefined
                                    }, analyticsData[chart.dataKey])
                                } else {
                                    data = analyticsData[chart.dataKey]?.[chart.nestedKey]
                                }
                            } else {
                                data = analyticsData[chart.dataKey]
                            }

                            const [filterValues, setFilterValues] = useState(
                                chart.filters?.reduce((acc, filter) => ({ ...acc, [filter.key]: filter.value }), {}) ||
                                    {},
                            )
                            const statFormatter =
                                filterValues.sales_by === 'count' || !filterValues.sales_by ? 'number' : 'amount'
                            return (
                                <Col key={chart.dataKey} span={chart.span || 12}>
                                    <AnalyticsCard
                                        title={chart.heading}
                                        subHeading={chart.subHeading}
                                        titleTooltip={chart.tooltipText}
                                        subHeadingTooltip={chart.subHeadingTooltip}
                                        loading={data?.status === 'loading'}
                                        noData={!data?.data?.primaryBreakdown && !data?.data?.stats && !data?.length}
                                        {...(chart.chartProps && {
                                            heroValue: data?.data?.total || data?.data?.value,
                                            valueDelta: +data?.data?.percentageChange,
                                            heroValueTransform: chart.heroValueTransform,
                                        })}
                                        filters={chart.filters}
                                        filterValues={filterValues}
                                        filterOnChange={(key, value) => {
                                            setFilterValues((prev) => ({ ...prev, [key]: value }))
                                            dispatch(
                                                fetchAnalyticsDataAsync({
                                                    params: {
                                                        ...formattedDates,
                                                        ...filterValues,
                                                        [key]: value,
                                                    },
                                                    key: chart.dataKey,
                                                    nestedKey: chart.nestedKey,
                                                }),
                                            )
                                        }}
                                        lastUpdatedAt={data?.data?.lastUpdatedAt}
                                        {...(chart.showDownload && {
                                            customHeaderActions: <DownloadIcon apiKey={chart.dataKey} />,
                                        })}
                                        noDataContent={
                                            showKwikCodLock &&
                                            filterValues.payment_method_filter === 'prepaid' && <KwikCodLock />
                                        }
                                    >
                                        {chart.chartProps ? (
                                            <Chart
                                                wrapperClassName='min-h-[288px] h-full'
                                                chartType={chart.chartProps.chartType as any}
                                                isAmount={chart.chartProps.labelType === 'amount'}
                                                dates={dates}
                                                data={data?.data}
                                            />
                                        ) : chart.isTable ? (
                                            <>
                                                <Table
                                                    dataSource={data}
                                                    columns={chart.tableData}
                                                    pagination={false}
                                                    scroll={{ y: '350px' }}
                                                />
                                            </>
                                        ) : (
                                            <StatsCards data={data?.data?.stats} primaryFormatValue={statFormatter} />
                                        )}
                                    </AnalyticsCard>
                                </Col>
                            )
                        })}
                    </Row>
                </Col>
            ))}
        </Row>
    )
}

export default CheckoutAnalytics
