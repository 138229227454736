 

import { Checkbox, Divider } from 'gokwik-ui-kit'
import { useEffect } from 'react'

type props = {
    list: any
    checkedList: any
    onCustomerSegmentCheckChange: any
}

const CustomerSegmentsList = (props: props) => {
    const { list, checkedList, onCustomerSegmentCheckChange } = props
 
    return (
        <ul className='pl-0 list-none scroll-auto max-h-[calc(100vh-500px)] overflow-y-auto'>
             {list?.map((item) => (
                <div key={item?.id}>
                    <li className='p-2'>
                        <Checkbox
                            checked={
                                !!checkedList?.some((i) => {
                                    return i?.id === item?.id
                                })
                            }
                            onChange={(e) => onCustomerSegmentCheckChange(e, item)}
                        >
                            <div className='flex items-center gap-2'>
                                <span className='text-[#000000E0] text-sm font-normal'>{item?.name}</span>
                            </div>
                        </Checkbox>
                    </li>
                    <Divider className='my-1' />
                </div>
            ))}
        </ul>
    )
}

export {CustomerSegmentsList}