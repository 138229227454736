import {
    Button,
    Col,
    ColumnProps,
    Divider,
    Modal,
    Row,
    Table,
    Tag,
    Tooltip,
    message,
    notification,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    RightOutlined,
    TagOutlined,
} from 'gokwik-ui-kit'
import React, { useEffect, useState } from 'react'
import RenderSearchFilters from '@library/components/search-filter'
import { useDispatch, useSelector } from 'react-redux'
import { DiscountType, DiscountTypeEnum } from '@library/utilities/types'
import { useLocation, useNavigate } from 'react-router-dom'
import { previousDiscountFilters } from '@store/filters/selectors'
import { saveFiltersData } from '@store/filters'
import { getMerchantDetails } from '@store/user/selectors'
import { makeAPICall, titleCase, updateBreadcrumbs } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import BookDownload from '@library/images/book-download.svg'
import dayjs from 'dayjs'
import { getCookie, setCookie } from '@library/utilities/helpers/cookieHelper'
import { FloatingTourIcon, RenderTour } from '../tour/tour'
import { allDiscountsTourSteps } from '../tour/tourSteps'
import { ICommonEvents } from '@library/utilities/helpers/eventsHelper'

export default function index(props: {events: ICommonEvents}) {
    const navigate = useNavigate()
    const { tourStepsEvents, configsUpsert } = props.events;
    const [showDiscountSelectionModal, setShowDiscountSelectionModal] = useState(false)
    const dispatch = useDispatch()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [discountToDelete, setDiscountToDelete] = useState({ discountCode: '', discount_id: '' })
    const [total, setTotal] = useState(0)
    const [discounts, setDiscounts] = useState([])
    const merchant_details = useSelector(getMerchantDetails)
    const [pageSize, setPageSize] = useState(25)
    const [page, setPage] = useState(1)
    const prevFilters = useSelector(previousDiscountFilters)
    const [parameters, setParameters] = useState<any>({
        merchant_id: String(merchant_details?.m_id),
        page: page,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
        ...prevFilters,
    })
    const location = useLocation()
    const [api, contextHolder] = notification.useNotification()

    const [showTour, setShowTour] = useState(false)

    useEffect(() => {
        const checkCookies = () => {
            const isTourVisited = getCookie('allDiscountsTour')
            if (!isTourVisited) {
                setShowTour(true)
            }
        }
        setTimeout(checkCookies, 100)
    }, [])

    const reset = (e) => {
        e.preventDefault()
        const newParams = {
            merchant_id: String(merchant_details?.m_id),
            page: 1,
            sortKey: 'created_at',
            sortOrder: -1,
            pageSize: pageSize,
            status: '',
            method: '',
            code: '',
        }
        setParameters({ ...newParams })
        makeDiscountsApiCall(newParams)
    }

    const getDiscountName = (discountType) => {
        if (discountType === 'buy_x_at_y') {
            return 'Buy X, At Y'
        } else if (discountType === 'capping') {
            return 'Capping'
        } else if (discountType === 'quantity') {
            return 'Product Quantity'
        } else if (discountType === 'dynamic') {
            return 'Order Value'
        } else if (discountType === 'gift_with_product') {
            return 'Gift with Products'
        } else {
            return ''
        }
    }

    const discountTableColumns: ColumnProps<any>[] = [
        {
            title: <span>Code/Title</span>,
            dataIndex: 'code',
            ellipsis: true,
            width: 18,
            render: (text: string) => (
                <Tooltip placement='topRight' title={text}>
                    {text?.toUpperCase()}
                </Tooltip>
            ),
        },

        {
            title: <span>Status</span>,
            dataIndex: 'status',
            ellipsis: true,
            width: 15,
            align: 'center',
            render: (text: string) => (
                <div>
                    <>
                        {text === 'draft' && (
                            <Tooltip placement='topLeft' title={text}>
                                <Tag className=' rounded' variant='warning'>
                                    {titleCase(text)}
                                </Tag>
                            </Tooltip>
                        )}
                    </>

                    <>
                        {text === 'active' && (
                            <Tooltip placement='topLeft' title={text}>
                                <Tag className=' rounded' variant='success'>
                                    {titleCase(text)}
                                </Tag>
                            </Tooltip>
                        )}
                    </>

                    <>
                        {text === 'expired' && (
                            <Tooltip placement='topLeft' title={text}>
                                <Tag className=' rounded' variant='error'>
                                    {titleCase(text)}
                                </Tag>
                            </Tooltip>
                        )}
                    </>

                    <>
                        {text === 'scheduled' && (
                            <Tooltip placement='topLeft' title={text}>
                                <Tag className=' rounded' variant='default'>
                                    {titleCase(text)}
                                </Tag>
                            </Tooltip>
                        )}
                    </>
                </div>
            ),
        },

        {
            title: <span>Method</span>,
            dataIndex: 'method',
            ellipsis: true,
            width: 12,
            render: (text: string) => (
                <Tooltip placement='topLeft' title={text} className='capitalize'>
                    {text}
                </Tooltip>
            ),
        },

        {
            title: <span>Type</span>,
            ellipsis: true,
            width: 20,
            render: (_, record) => (
                <Tooltip placement='topLeft' title={record?.discount_type} className='flex flex-col gap-1'>
                    <span className='text-sm font-semibold capitalize'>{getDiscountName(record?.discount_type)}</span>
                    <span className='text-[#00000073] text-sm font-normal'>Product Discount</span>
                </Tooltip>
            ),
        },

        {
            title: <span>Combinations</span>,
            dataIndex: 'combination',
            ellipsis: true,
            width: 20,
            render: (_, record) => (
                //hardcoded, need data from backend
                <Tooltip placement='topLeft' className='flex flex-col gap-1'>
                    <div className='flex flex-col'>
                        {record?.combination &&
                            record?.combination?.length &&
                            record?.combination?.map((combination, i) => (
                                <span key={i} className='text-[#000000E0] text-sm font-medium capitalize'>
                                    {combination} discount
                                </span>
                            ))}
                    </div>
                </Tooltip>
            ),
        },

        {
            title: <span>Start Date</span>,
            dataIndex: 'start_date',
            width: 20,
            ellipsis: true,
            render: (_, record) => (
                <Tooltip
                    placement='topLeft'
                    title={record?.start_date && dayjs(record?.start_date).format('DD/MM/YYYY hh:mm A')}
                >
                    {record?.start_date && dayjs(record?.start_date).format('DD/MM/YYYY hh:mm A')}
                </Tooltip>
            ),
            sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: <span>End Date</span>,
            dataIndex: 'end_date',
            width: 20,
            ellipsis: true,
            render: (_, record) => (
                <Tooltip
                    placement='topLeft'
                    title={record?.end_date && dayjs(record?.end_date).format('DD/MM/YYYY hh:mm A')}
                >
                    {record?.end_date && dayjs(record?.end_date).format('DD/MM/YYYY hh:mm A')}
                </Tooltip>
            ),
            sorter: (a, b) => (dayjs(a.updated_at).isBefore(dayjs(b.updated_at)) ? -1 : 1),
            sortDirections: ['ascend', 'descend'],
        },

        {
            title: <span>Actions</span>,
            dataIndex: 'actions',
            ellipsis: true,
            align: 'center',
            fixed: 'right',
            width: 15,
            render: (_, record) => (
                <Row className='flex gap-3 m-0' justify={'center'}>
                    <Tooltip className=' cursor-pointer' placement='topLeft' title={'View Discount'}>
                        <EyeOutlined onClick={() => handleViewClick(record?.discount_id, record?.discount_type)} />
                    </Tooltip>

                    <Tooltip className=' cursor-pointer' placement='topLeft' title={'Edit Discount'}>
                        <EditOutlined onClick={() => handleEditClick(record?.discount_id, record?.discount_type)} />
                    </Tooltip>

                    <Tooltip className=' cursor-pointer' placement='topLeft' title={'Delete Discount'}>
                        <DeleteOutlined onClick={() => handleDeleteDiscount(record)} className=' text-red-500' />
                    </Tooltip>
                </Row>
            ),
        },
    ]

    const handleEditClick = async (discount_id: string, discount_type: string) => {
        configsUpsert.edit.fireClickedEvent({
            discountData:{
                id: discount_id,
                discountType: discount_type
            }
        })
        navigate(`/checkout/settings/edit-discount/${discount_id}`, {
            state: {
                type: DiscountTypeEnum[discount_type],
                action: 'edit',
                discount_id: discount_id,
            },
        })
    }

    const handleViewClick = async (discount_id: string, discount_type: string) => {
        navigate('/checkout/settings/create-discount', {
            state: {
                type: DiscountTypeEnum[discount_type],
                action: 'view',
                discount_id: discount_id,
            },
        })
    }

    const deleteDiscount = async (discountToDelete) => {
        if (!discountToDelete.discount_id) {
            message.error('Delete Error: No discount id')
            setShowDeleteModal(false)
            return
        }
        try {
            configsUpsert.delete.fireClickedEvent({
                discountData: discountToDelete
            })
            let response = await makeAPICall({
                method: 'delete',
                url:
                    process.env.DISCOUNT_CONFIG_BASE_URL +
                    APIEndPoints.discountConfig.deleteDiscount(discountToDelete?.discount_id),
            })
            makeDiscountsApiCall({ ...parameters })
            setShowDeleteModal(false)
            if (response?.data?.status_code === 200) {
                message.success('Discount deleted successfully')
                configsUpsert.delete.fireSuccessEvent({
                    discountData: discountToDelete
                })
            } else {
                message.error('Delete Error: Something went wrong')
                configsUpsert.delete.fireFailureEvent({
                    discountData: discountToDelete,
                    apiStatus: response?.data?.status_code
                })
            }
        } catch (err) {
            console.warn(err)
            configsUpsert.delete.fireFailureEvent({
                discountData: discountToDelete,
                errorMessage: err?.message || "Something Went Wrong!"
            })
        }
    }

    const handleDeleteDiscount = async (discount) => {
        setDiscountToDelete({ discountCode: discount?.code, discount_id: discount.discount_id })
        setShowDeleteModal(true)
    }

    const discountTypes = () => {
        return [
            {
                id: 1,
                name: 'Capping discount',
                description: 'Ex: 50% off upto Rs. 199.',
                discount_type: DiscountTypeEnum.capping,
            },

            {
                id: 2,
                name: 'Buy X, at Y discount',
                description: 'Ex: Buy 3 at Rs. 399, Buy 4 at Rs. 499',
                discount_type: DiscountTypeEnum.buy_x_at_y,
            },

            {
                id: 3,
                name: 'Order Value Discount',
                description: 'Ex: 20% off on order value between 600-900, Rs.49 off on order value 900-1200',
                discount_type: DiscountTypeEnum.dynamic,
            },

            {
                id: 4,
                name: 'Product Quantity Discount',
                description: 'Ex: 10% off for 5 specified products, Rs.99 off for 3 specified products.',
                discount_type: DiscountTypeEnum.quantity,
            },

            {
                id: 5,
                name: 'Gift with products',
                description: 'Ex: 1 product free gift on purchase of 3 specified products',
                discount_type: DiscountTypeEnum.gift_with_product,
            },
        ]
    }

    const makeDiscountsApiCall = async (parameters: any) => {
        if (!parameters.page) return

        //above code needs to be removed
        try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.DISCOUNT_CONFIG_BASE_URL + APIEndPoints.discountConfig.getDiscountsList,
                payload: parameters,
            })

            if (response?.data?.status_code === 200) {
                setDiscounts(response?.data?.data)
                setTotal(response?.data?.data?.length)
            } else if (response?.response?.data?.status_code == 404)  {
                message.error(response?.response?.data?.data)
                parameters.code = ""
                setParameters(parameters);
            } else {
                message.error('Internal Server Error!')
                parameters.code = ""
                setParameters(parameters);
            }

            dispatch<any>(saveFiltersData('discount', { ...parameters }))
        } catch (err) {
            console.warn(err)
        }
    }

    //update parameters on merchant change
    useEffect(() => {
        setParameters((prev) => ({ ...prev, page: 1, merchant_id: String(merchant_details?.m_id) }))
    }, [merchant_details?.m_id])

    useEffect(() => {
        if (parameters.page >= 1) {
            makeDiscountsApiCall({ ...parameters, merchant_id: String(merchant_details?.m_id) })
        }
    }, [parameters.page])

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'checkout_settings',
                href: '/checkout/settings/discount',
                text: 'Checkout Settings',
            },
            {
                key: 'discount',
                href: '/checkout/settings/discount',
                text: 'Discounts',
            },
        ])
    }, [])

    const handleSearchDiscount = (e) => {
        e?.preventDefault()

        if (parameters?.status || parameters?.code || parameters?.method) {
            makeDiscountsApiCall(parameters)
        } else {
            message.error('Please enter a search parameter')
        }
    }

    const handleCreateDiscount = (discountType: DiscountType) => {
        configsUpsert.add.fireClickedEvent({
            step: 'selectDiscountType',
            discountType
        })
        navigate('/checkout/settings/create-discount', {
            state: {
                type: discountType,
            },
        })
    }
    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
    }

    const openNotificationWithIcon = (data) => {
        api[data?.type]({
            message: data?.message,
            description: data?.description,
            duration: data?.duration,
        })
    }
  
    const createNewDiscount = () => {
        configsUpsert.add.fireClickedEvent({
            step: 'createDiscountButtonClicked'
        });
        setShowDiscountSelectionModal(true)
    }

    useEffect(() => {
        if (location?.state?.notificationConfig && location?.state?.notificationConfig?.show) {
            const notificationConfig = location?.state?.notificationConfig
            const data = {
                type: notificationConfig.type,
                message: notificationConfig?.title,
                description: notificationConfig?.description,
                duration: notificationConfig?.duration,
            }

            openNotificationWithIcon(data)
        }

        navigate('/checkout/settings/discount', {
            replace: true,
        })
    }, [location?.state])

    function startTour() {
        setShowTour(true);
        tourStepsEvents.fireStartedEvent();
    }

    const openGuide = () => {
        tourStepsEvents.fireGuideOpenedEvent();
    }

    const nextStep = (step) => {
        const currentStepTitle = allDiscountsTourSteps[step].title
        tourStepsEvents.fireNextStepEvent({
          stepTitle: currentStepTitle
        })
    }

    const closeTour = () => {
        setShowTour(false)
        setCookie('allDiscountsTour', true, 365)
        tourStepsEvents.fireSkippedEvent();
    }

    const finishTour = () => {
        setShowTour(false)
        setCookie('allDiscountsTour', true, 365)
        tourStepsEvents.fireCompletedEvent();
    }

    return (
        <>
            {contextHolder}
            <Modal
                open={showDeleteModal}
                onCancel={() => {
                    setDiscountToDelete({ discountCode: '', discount_id: '' })
                    setShowDeleteModal(false)
                }}
                okText='Yes'
                cancelText='Cancel'
                title={'Are you sure you want to delete this discount ?'}
                centered
                onOk={() => {
                    deleteDiscount(discountToDelete)
                }}
            >
                <div className='flex gap-2'>
                    <span>Code/Title :</span> <div className=' font-semibold'>{discountToDelete?.discountCode}</div>
                </div>
            </Modal>
            {
                <Modal
                    width={666}
                    className='w-full'
                    open={showDiscountSelectionModal}
                    onCancel={() => setShowDiscountSelectionModal(false)}
                    footer={null}
                    title={'Select discount type'}
                    centered
                >
                    <ul className='list-none p-0'>
                        {discountTypes()?.map((item, index) => {
                            return (
                                <li
                                    className='cursor-pointer'
                                    key={index}
                                    onClick={() => handleCreateDiscount(item?.discount_type)}
                                >
                                    <Row>
                                        <Col span={17}>
                                            <div className='flex flex-col gap-2 justify-center'>
                                                <span className=' text-[#000000E0] text-base font-semibold'>
                                                    {item?.name}
                                                </span>
                                                <span className='text-[#00000073] text-xs font-normal'>
                                                    {item?.description}
                                                </span>
                                            </div>
                                        </Col>

                                        <Col span={6}>
                                            <div className='flex gap-1 items-center'>
                                                <Button variant='default'>
                                                    <TagOutlined />
                                                    Product Discount
                                                </Button>

                                                <RightOutlined />
                                            </div>
                                        </Col>
                                    </Row>
                                    {index !== discountTypes()?.length - 1 && <Divider />}
                                </li>
                            )
                        })}
                    </ul>
                </Modal>
            }
            <div className='w-full flex flex-col gap-4'>
                <RenderTour showTour={showTour} steps={allDiscountsTourSteps} onClose={closeTour} onChange={nextStep} onFinish={finishTour} />
                <Row className='p-2 rounded bg-neutrals-15' align={'middle'} justify={'space-between'}>
                    <Col span={12}>
                        <div className=' text-lg'>
                            <TagOutlined className='text-blue-500 border border-solid border-blue-500 rounded-full p-1' />
                            &nbsp;<span className=' font-semibold'>All discounts</span>
                            <a
                                className='ml-3 text-sm font-normal'
                                id='all-discounts-guide'
                                href='https://scribehow.com/page/Guide_to_Setting_Up_GoKwik_Discounts_on_the_GoKwik_Dashboard__ISwEqNcLRaCqpGv1sfvPTQ'
                                target='_blank'
                                rel='noopener noreferrer'
                                onClick={openGuide}
                            >
                                <img className='mr-1 mb-1' src={BookDownload} height={'18'} width={'18'} />
                                Open Guide
                            </a>
                            <br />
                            <span className='text-[#00000080] font-normal text-sm pt-1'>
                                This section allows you to create discount types that are in addition to Shopify discounts. The discounts created here will also be synced with Shopify, enabling users to apply them just like regular Shopify discounts.
                            </span>
                        </div>
                    </Col>
                    <Col>
                        <Button id='create-discount' onClick={createNewDiscount} variant='primary'>
                            <TagOutlined />
                            Create Discount
                        </Button>
                    </Col>
                </Row>

                {/* <Row>
                    <Col span={24}>
                        <div className='flex flex-col justify-between gap-4 items-center align-middle py-10 px-4 bg-white border border-solid border-[#CBD2DE] rounded-lg'>
                            <NoDiscountIcon />
                            <div className='text-[#000000E0] text-lg font-semibold'>You have no discount created.</div>
                            <div className='text-[#00000080] text-sm font-normal w-1/2 text-center'>
                                Create discount codes and automatic discounts that apply at checkout. You can start
                                setting up discounts by clicking on the button “Create Discount” below.
                            </div>

                            <div>
                                <Button variant='primary'>Create Discount</Button>
                            </div>
                        </div>
                    </Col>
                </Row> */}

                <Row>
                    <Col span={24}>
                        {
                            discounts?.length > 0 && 
                            <div className='bg-white rounded'>
                                <RenderSearchFilters
                                    className='!border-b-0'
                                    values={parameters}
                                    setValues={(data, reset) =>
                                        setParameters((prev) => (reset ? { ...data } : { ...prev, ...data }))
                                    }
                                    page='discount'
                                    onSearch={handleSearchDiscount}
                                    onReset={reset}
                                />
                            </div>
                        }
                        <div className=' bg-white rounded overflow-clip'>
                            <Table
                                columns={discountTableColumns}
                                dataSource={discounts}
                                pagination={{
                                    current: parameters.page, // Current page number
                                    pageSize: parameters.pageSize, // Number of items to display per page
                                    total: total, // Total number of items in the data array
                                    showSizeChanger: false,
                                    onChange: handlePaginationChange,

                                    position: ['topLeft', 'bottomLeft'],
                                    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                                }}
                                // scroll={{ x: 350 }}
                            />
                        </div>
                    </Col>
                </Row>
                <FloatingTourIcon onClick={startTour}/>
            </div>
        </>
    )
}
