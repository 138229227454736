type DiscountType = 'capping' | 'buy_x_at_y' | 'gift' | 'dynamic' | 'quantity' | 'gift_with_product'
enum DiscountTypeEnum {
    capping = 'capping',
    buy_x_at_y = 'buy_x_at_y',
    gift = 'gift',
    dynamic = 'dynamic',
    quantity = 'quantity',
    gift_with_product = 'gift_with_product',
}

enum customerSegementTypes {
    All = 'allCustomers',
    CustomerSegment = 'segments',
    SpecificCustomer = 'customers',
}
enum customerCategoryType {
    Gokwik = 'gokwik',
    Shopify = 'shopify',
}

enum ShopifyAppDiscountOffersSortKeyEnum {
    SORT_INDEX = 'SORT_INDEX',
    AMOUNT_SAVED = 'AMOUNT_SAVED',
}

enum ShopifyAppPPCODTypeEnum {
    percentage = 'percentage',
    fixed = 'fixed',
}

enum ShopifyAppPPCODAdditionalFeatures {
    standard = 'standard',
    risk = 'risk',
    tag = 'tag',
}

export {
    DiscountType,
    DiscountTypeEnum,
    customerSegementTypes,
    customerCategoryType,
    ShopifyAppDiscountOffersSortKeyEnum,
    ShopifyAppPPCODTypeEnum,
    ShopifyAppPPCODAdditionalFeatures,
}
