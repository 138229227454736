import { ColorPicker, Form } from 'gokwik-ui-kit'

const ColorPicketComponent = ({ ...props }) => {
    const { label, primary, secondary, customName, address, description, defaultValues } = props

    const processColor = (color: any) => {
        if (!color || typeof color !== 'string') return undefined
        const formattedColor = color?.startsWith('#') ? color : `#${color}`
        if (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(formattedColor)) {
            return formattedColor
        }
        return undefined
    }

    return (
        <span>
            <div className='font-medium text-base mb-2'>{label}</div>
            <p className='text-gray-400 text-sm mb-2'>{description}</p>
            <div className='flex md:flex-col lg:flex-row gap-5 items-center'>
                <Form.Item
                    name={primary + '_color'}
                    label={<span className='capitalize'>{primary + ' color'}</span>}
                    className='flex-1 w-full m-0'
                    getValueFromEvent={(ev) => {
                        return '#' + ev.toHex()
                    }}
                >
                    <ColorPicker format='hex' showText />
                </Form.Item>
                {secondary != undefined && (
                    <Form.Item
                        name={secondary + '_color'}
                        label={
                            <span className='capitalize'>{address ? customName + ' color' : secondary + ' color'}</span>
                        }
                        className='flex-1 w-full m-0'
                        getValueFromEvent={(ev) => {
                            return '#' + ev.toHex()
                        }}
                    >
                        <ColorPicker format='hex' showText />
                    </Form.Item>
                )}
            </div>
        </span>
    )
}

export default ColorPicketComponent
