import { addBreadcrumb, convertIsoToLocaleString, titleCase } from '@gokwik/utilities'
import dayjs from 'dayjs'
import { ColumnProps, Tag, Tooltip, InfoCircleFilled, WarningFilled } from 'gokwik-ui-kit'
import { Link } from 'react-router-dom'
import High from '@library/images/high.svg'
import Mid from '@library/images/medium.svg'
import Low from '@library/images/low.svg'
import Controlled from '@library/images/controlled.svg'
import { formatAmount } from '@library/utilities/helpers/helper'
import { RTO_FAKE_ORDER_REASONS } from '@library/utilities/constants/constants'
import { useSelector } from 'react-redux'
import { getUserConfig } from '@store/user/selectors'

const paymentModes = {
    All: 'All',
    UPIIntent: 'UPI',
    COD: 'COD',
    cod: 'cod',
    cc: 'Credit Card',
    dc: 'Debit Card',
    wallet: 'Wallet',
    gift_card: 'Gift Card',
    bnpl: 'BNPL',
    emi: 'EMI',
    'emi-cc': 'EMI(Credit Card)',
    'emi-dc': 'EMI(Debit Card)',
    credpay: 'Credpay',
    netbanking: 'Netbanking',
}

const ShowRTOActions = () => {
    const merchantConfig = useSelector(getUserConfig) || {};
    const { selected_products, show_kwik_ai, show_kwik_ai_risk_navigation } = merchantConfig;
  
    const showRTOActions = show_kwik_ai && show_kwik_ai_risk_navigation && selected_products?.rto;
  
    return showRTOActions ? (
        <Tooltip
            title={
                <div className='text-black'>
                    <WarningFilled className='mr-2 p-1 bg-red-50 text-error-500 rounded' />
                    <span className='font-medium'>Fake Order</span>
                    <div className='my-2' />
                    <span className='font-light'>
                        Caution! We have identified this order as potentially fake. Click on the Order ID to
                        view more details.
                    </span>
                </div>
            }
            overlayClassName='fake-order-tooltip-inner'
        >
            <WarningFilled className='text-error-500 p-1 bg-red-50 ml-[2px] rounded' />
        </Tooltip>
    ) : null
}

export const internalUserColumns: ColumnProps<any>[] = [
    {
        title: 'GoKwik Order Number',
        dataIndex: 'order_number',
        width: 200,
        ellipsis: true,
        render: (text: string) => (
            <Link
                to={`/checkout/orders/${text}`}
                onClick={() =>
                    addBreadcrumb({
                        key: 'order-details',
                        href: `/checkout/orders/${text}`,
                        text,
                    })
                }
            >
                <a className='w-min text-blue-400'>
                    <Tooltip placement='topLeft' title={text}>
                        {text?.toUpperCase()}
                    </Tooltip>
                </a>
            </Link>
        ),
        // fixed: 'left',
    },
    {
        title: 'Order Status',
        dataIndex: 'order_status',
        width: 140,
        render: (text: string, record: any) => (
            <div>
                <>
                    {' '}
                    {text === 'confirmed' && (
                        <Tag
                            variant={'success'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Confirmed
                        </Tag>
                    )}
                </>
                <>
                    {text === 'pending' && (
                        <Tag
                            variant={'warning'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Pending
                        </Tag>
                    )}
                </>
                <>
                    {text === 'cancelled' && (
                        <Tag
                            variant={'error'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Cancelled
                        </Tag>
                    )}
                </>

                <>
                    {text === 'verified' && (
                        <Tag
                            variant={'default'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Verified
                        </Tag>
                    )}
                </>
                {RTO_FAKE_ORDER_REASONS.includes(record.rto_reason) && <ShowRTOActions />}
            </div>
        ),
        align: 'left',
    },
    {
        title: 'Merchant Order #',
        dataIndex: 'moid',
        width: 140,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
    },

    {
        title: 'Merchant Order Status',
        dataIndex: 'm_order_status',

        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                <span>{`${titleCase(text)}`}</span>
            </Tooltip>
        ),
        align: 'left',
    },
    {
        title: 'Platform Order #',
        dataIndex: 'order_name',

        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                <span>{`${titleCase(text)}`}</span>
            </Tooltip>
        ),
    },
    {
        title: 'Created At',
        dataIndex: 'created_at',
        width: 140,
        ellipsis: true,
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
        sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: 'Updated At',
        dataIndex: 'updated_at',
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
        sorter: (a, b) => (dayjs(a.updated_at).isBefore(dayjs(b.updated_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
    },

    {
        title: 'RTO Remark',
        dataIndex: 'rto_reason',

        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
    },

    {
        title: 'RTO Score',
        dataIndex: 'rto_score',
        align: 'left',
    },
    {
        title: 'Customer Phone #',
        dataIndex: 'billing_phone',
    },
    {
        title: 'Payment Mode',
        dataIndex: 'payment_method',
        width: 140,
        render: (text: string) => <span>{`${paymentModes[text] || text}`}</span>,
    },
    {
        title: 'Payment Status',
        dataIndex: 'payment_status',
        render: (text: string) => <span>{`${text ? 'Paid' : 'Pending'}`}</span>,
        width: 140,
        align: 'left',
    },
    {
        title: 'Payment At',
        dataIndex: 'payment_at',
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
    },
    {
        title: 'Grand Total',
        dataIndex: 'grand_total',
        align: 'left',
        width: 140,
        render: (t, record) => <p>{formatAmount(t, 2)}</p>,
    },
    {
        title: 'RTO Risk',
        dataIndex: 'rto_risk_flag',
        width: 125,
        ellipsis: true,
        render: (text: string) => (
            <div>
                <>
                    {text?.toLowerCase()?.includes('high') && (
                        <span>
                            <span>
                                <img src={High} alt='high' /> &nbsp; High
                            </span>
                        </span>
                    )}
                </>
                <>
                    {text?.toLowerCase()?.includes('low') && (
                        <span>
                            <span>
                                <img src={Low} alt='low' /> &nbsp; Low
                            </span>
                        </span>
                    )}
                </>
                <>
                    {text?.toLowerCase()?.includes('medium') && (
                        <span>
                            <span>
                                <img src={Mid} alt='mid' /> &nbsp; Mid
                            </span>
                        </span>
                    )}
                </>
                <>
                    {text?.toLowerCase()?.includes('control') && (
                        <span>
                            <img src={Controlled} alt='control' /> &nbsp; Control
                        </span>
                    )}
                </>
            </div>
        ),
    },
    {
        title: 'Is ABC Recovered',
        dataIndex: 'is_abc',

        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                <span>{`${text ? 'Yes' : 'No'}`}</span>
            </Tooltip>
        ),
    },

    {
        title: 'C2P Done',
        dataIndex: 'c2p_converted',

        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                <span>{`${text ? 'Yes' : 'No'}`}</span>
            </Tooltip>
        ),
    },

    {
        title: 'UTM Source',
        dataIndex: 'mkt_source',

        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {titleCase(text)}
            </Tooltip>
        ),
    },
    {
        title: 'UTM Medium',
        dataIndex: 'mkt_medium',

        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
    },
    {
        title: 'UTM Campaign',
        dataIndex: 'mkt_campaign',

        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
    },
]

export const merchantUserColumns: ColumnProps<any>[] = [
    {
        title: 'GoKwik Order Number',
        dataIndex: 'order_number',
        width: 200,
        ellipsis: true,
        render: (text: string) => (
            <Link
                to={`/checkout/orders/${text}`}
                onClick={() =>
                    addBreadcrumb({
                        key: 'order-details',
                        href: `/checkout/orders/${text}`,
                        text,
                    })
                }
            >
                <a style={{ width: 'min-content' }}>
                    <Tooltip placement='topLeft' title={text}>
                        {text?.toUpperCase()}
                    </Tooltip>
                </a>
            </Link>
        ),
        // fixed: 'left',
    },
    {
        title: 'Order Status',
        dataIndex: 'order_status',
        width: 140,
        render: (text: string, record: any) => (
            <div>
                <Tag
                    variant={
                        text === 'confirmed'
                            ? 'success'
                            : text === 'pending'
                            ? 'warning'
                            : text === 'cancelled'
                            ? 'error'
                            : 'default'
                    }
                    className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                >
                    {text}
                </Tag>
                {RTO_FAKE_ORDER_REASONS.includes(record.rto_reason) && <ShowRTOActions />}
            </div>
        ),
        align: 'left',
    },
    {
        title: 'Created At',
        dataIndex: 'created_at',
        width: 140,
        ellipsis: true,
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
        sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
    },

    {
        title: 'Customer Phone #',
        dataIndex: 'billing_phone',
    },

    // {
    // title: <span>RTO Score</span>,
    // title: '',
    //     dataIndex: 'rto_score',
    //     align: 'right',
    //
    // },

    {
        title: 'RTO Remark',
        dataIndex: 'rto_reason',

        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
    },
    {
        title: 'Payment Mode',
        dataIndex: 'payment_method',
        width: 140,
        render: (text: string) => <span>{`${paymentModes[text] || text}`}</span>,
    },
    {
        title: 'Payment Status',
        dataIndex: 'payment_status',
        render: (text: string) => <span>{`${text ? 'Paid' : 'Pending'}`}</span>,
        width: 140,
        align: 'left',
    },
    {
        title: 'Grand Total',
        dataIndex: 'grand_total',
        align: 'left',
        width: 140,
        render: (t, record) => <p>{formatAmount(t, 2)}</p>,
    },
    {
        title: 'RTO Risk',
        dataIndex: 'rto_risk_flag',
        width: 125,
        ellipsis: true,
        render: (text: string) => (
            <div>
                <>
                    {text?.toLowerCase()?.includes('high') && (
                        <span>
                            <span>
                                <img src={High} alt='high' /> &nbsp; High
                            </span>
                        </span>
                    )}
                </>
                <>
                    {text?.toLowerCase()?.includes('low') && (
                        <span>
                            <span>
                                <img src={Low} alt='low' /> &nbsp; Low
                            </span>
                        </span>
                    )}
                </>
                <>
                    {text?.toLowerCase()?.includes('medium') && (
                        <span>
                            <span>
                                <img src={Mid} alt='mid' /> &nbsp; Mid
                            </span>
                        </span>
                    )}
                </>
                <>
                    {text?.toLowerCase()?.includes('control') && (
                        <span>
                            <img src={Controlled} alt='control' /> &nbsp; Control
                        </span>
                    )}
                </>
            </div>
        ),
    },
    {
        title: 'Updated At',
        dataIndex: 'updated_at',
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
        sorter: (a, b) => (dayjs(a.updated_at).isBefore(dayjs(b.updated_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: 'Is ABC Recovered',
        dataIndex: 'is_abc',

        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                <span>{`${text ? 'Yes' : 'No'}`}</span>
            </Tooltip>
        ),
    },

    {
        title: 'C2P Done',
        dataIndex: 'c2p_converted',

        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                <span>{`${text ? 'Yes' : 'No'}`}</span>
            </Tooltip>
        ),
    },

    {
        title: 'UTM Source',
        dataIndex: 'mkt_source',

        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {titleCase(text)}
            </Tooltip>
        ),
    },
    {
        title: 'UTM Medium',
        dataIndex: 'mkt_medium',

        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
    },
    {
        title: 'UTM Campaign',
        dataIndex: 'mkt_campaign',

        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
    },
]

export const editOrderColumns: ColumnProps<any>[] = [
    {
        title: 'S No.',
        dataIndex: '',

        ellipsis: true,
    },
    {
        title: 'Product',
        dataIndex: 'product',

        render: (text: string) => text,
        align: 'center',
    },
    {
        title: 'SKU ID',
        dataIndex: 'sku_id',

        ellipsis: true,
        align: 'left',
    },
    {
        title: 'Product ID',
        dataIndex: 'sku_id',

        ellipsis: true,
        align: 'left',
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',

        ellipsis: true,
        align: 'left',
    },
    {
        title: 'Total',
        dataIndex: 'total',

        ellipsis: true,
        align: 'left',
    },
    {
        title: 'Action',
        dataIndex: 'action',

        ellipsis: true,
        align: 'left',
    },
]

export const marketingAnalytics: ColumnProps<any>[] = [
    {
        title: 'S No.',
        dataIndex: '',
        ellipsis: true,
        render: (_, __, index) => index + 1,
    },
    {
        title: 'Source',
        dataIndex: 'mkt_source',

        ellipsis: true,
    },
    {
        title: 'Campaign',
        dataIndex: 'mkt_campaign',

        ellipsis: true,
    },
    {
        title: 'Medium',
        dataIndex: 'mkt_medium',

        ellipsis: true,
    },
    {
        title: 'Users',
        dataIndex: 'users_count',

        ellipsis: true,
    },
    {
        title: 'Cart',
        dataIndex: 'carts_count',

        ellipsis: true,
    },
    {
        title: 'Order',
        dataIndex: 'orders_count',

        ellipsis: true,
    },
    {
        title: 'Total Sales',
        dataIndex: 'total_sales',

        ellipsis: true,
        render: (v) => <span>₹ {v}</span>,
    },

    {
        title: (
            <Tooltip title='Conversion Rate = (Orders / Users) x 100'>
                <span>
                    Market Conversion Rate <InfoCircleFilled />
                </span>
            </Tooltip>
        ),
        dataIndex: 'mkt_conversion_rate',

        ellipsis: true,
        render: (_, u, __) => {
            let temp = (parseInt(u?.orders_count) / parseInt(u?.users_count) || 0) * 100
            return <span>{temp.toFixed(2)}</span>
        },
    },
]
