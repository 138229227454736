import { Col, Input, Modal, Row, message } from 'gokwik-ui-kit'
import React, { useEffect, useState } from 'react'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import { debounce } from 'lodash'
import { ProductListWithVariants } from './productsListWithVariants'

type Props = {
    showProductSelectionModal?: any
    setShowProductSelectionModal?: any
    modalProps?: any
    searchValue?: any
    setSearchValue?: any
    setShowCheckedListTags?: any
    setFormState?: any
    selectedProductVariants?: any
    setSelectedProductVariants?: any
    limitToOneVariant?: boolean
}

const ProductWithVariantsSearchModal = (props: Props) => {
    const {
        showProductSelectionModal,
        setShowProductSelectionModal,
        searchValue,
        setSearchValue,
        setShowCheckedListTags,
        setFormState,
        selectedProductVariants,
        setSelectedProductVariants,
        limitToOneVariant = false,
    } = props
    const { title, searchPlaceholder } = props.modalProps
    const merchant_details = useSelector(getMerchantDetails)
    const [list, setList] = useState([])
    const [checkedList, setCheckedList] = useState([])
    const [checkedVariantsList, setCheckedVariantsList] = useState([...selectedProductVariants])

    const onProductCheckChange = (e, product) => {
        const isProductSelected = checkedList?.some((item) => item?.product_id === product?.product_id)
        if (e?.target?.checked && !isProductSelected) {
            product.variants.forEach((variant) => {
                if (variant.name === 'Default Title') {
                    variant.variant_name = product.product_name
                } else variant.variant_name = `${product.product_name} ${variant.name}`
            })

            if (limitToOneVariant) {
                setCheckedList([product])
                setCheckedVariantsList([product?.variants[0]])
            } else {
                setCheckedList((prev) => [...prev, product])
                setCheckedVariantsList((prev) => [...prev, ...product?.variants])
            }
        } else {
            setCheckedList(checkedList.filter((item) => item?.product_id !== product?.product_id))
            setCheckedVariantsList(checkedVariantsList.filter((variant) => variant?.product_id !== product?.product_id))
        }
    }

    const onProductVariantsCheckChange = (e, product, variant) => {
        const isVariantSelected = checkedVariantsList?.some((item) => item?.variant_id === variant?.variant_id)
        if (e?.target?.checked && !isVariantSelected) {
            if (variant.name === 'Default Title') {
                variant.variant_name = product.product_name
            } else variant.variant_name = `${product.product_name} ${variant.name}`
            if (limitToOneVariant) {
                setCheckedVariantsList([variant])
            } else {
                setCheckedVariantsList((prev) => [...prev, variant])
            }
        } else {
            if (checkedVariantsList?.filter((item) => item.product_id === product?.product_id)?.length === 1) {
                setCheckedList(checkedList.filter((item) => item?.product_id !== product?.product_id))
            }
            setCheckedVariantsList(checkedVariantsList.filter((item) => item?.variant_id !== variant?.variant_id))
        }
    }

    const handleSearchChange = (e) => {
        const value = e.target.value
        setSearchValue(value)
    }

    const fetchProductsListDebounced = debounce(async (search_text) => {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.DISCOUNT_CONFIG_BASE_URL + APIEndPoints.discountConfig.getProductsList,
                params: { search_text: search_text, merchant_id: merchant_details?.m_id },
            })
            if (response?.data?.status_code === 200) {
                setList(response?.data?.data)
            } else {
                message.error(`Error fetching products list`)
                setList([])
            }
        } catch (error) {
            console.error('Error fetching products:', error)
            setList([])
        }
    }, 3000)

    const saveDetails = () => {
        setSearchValue('')
        setShowProductSelectionModal(false)
        setShowCheckedListTags(true)
        setSelectedProductVariants(checkedVariantsList)
        setFormState((prev) => {
            const temp = JSON.parse(JSON.stringify(prev))
            temp.rules[0].actions[0].applicable_list = checkedVariantsList?.map((item) => item?.variant_id)
            return temp
        })
    }

    useEffect(() => {
        fetchProductsListDebounced(searchValue)
    }, [searchValue])

    useEffect(() => {
        setFormState((prev) => {
            const temp = JSON.parse(JSON.stringify(prev))

            temp.rules[0].actions[0].free_product_name_mapping = checkedVariantsList?.map((item) => ({
                variant_name: item?.variant_name,
                variant_id: item?.variant_id,
                product_id: item?.product_id,
            }))

            return temp
        })
    }, [checkedVariantsList])

    return (
        <Modal
            title={title}
            width={555}
            cancelText={'Cancel'}
            okText={'Add'}
            centered
            open={showProductSelectionModal}
            onOk={() => saveDetails()}
            onCancel={() => {
                setSearchValue('')
                setShowProductSelectionModal(false)
                setCheckedList([])
                setCheckedVariantsList([])
            }}
        >
            <div>
                <Row>
                    {' '}
                    <Col span={24}>
                        <Input
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder={searchPlaceholder}
                            allowClear
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <ProductListWithVariants
                            list={list}
                            onProductCheckChange={onProductCheckChange}
                            onProductVariantsCheckChange={onProductVariantsCheckChange}
                            checkedList={checkedList}
                            checkedVariantsList={checkedVariantsList}
                        />
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export { ProductWithVariantsSearchModal }
