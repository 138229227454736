import { Button, Col, Row, Tabs } from 'gokwik-ui-kit'
import React, { useEffect, useState } from 'react'
import Brand from '@pages/settings/customize-ui/customize-sections/brand'
import CheckoutElements from '@pages/settings/customize-ui/customize-sections/checkoutelement'
import { useLocation } from 'react-router-dom'
import Login from '@pages/settings/customize-ui/customize-sections/login'
import Address from '@pages/settings/customize-ui/customize-sections/address'
import Coupons from '@pages/settings/customize-ui/customize-sections/coupons'
import Payments from '@pages/settings/customize-ui/customize-sections/payments'
import MobileStepUI from '@pages/settings/customize-ui/customize-sections/mobile'
import { useSelector } from 'react-redux'
import { getUserConfig } from '@store/user/selectors'

const CustomizeTab = () => {
    const config = useSelector(getUserConfig)
    const location = useLocation()
    const [activeTab, setActiveTab] = useState('brand')
    const [brandData, setBrandData] = useState<any>({})
    const [loginData, setLoginData] = useState<any>({})
    const [paymentData, setPaymentData] = useState<any>({})
    const [addressData, setAddressData] = useState<any>({})
    const [couponsData, setCouponsData] = useState<any>({})

    const handleChanges = (value: any) => {
        if (activeTab === 'brand') {
            setBrandData({ ...value })
        }
        if (activeTab === 'login') {
            setLoginData({ ...value })
        }
        if (activeTab === 'payments') {
            setPaymentData({ ...value })
        }
        if (activeTab === 'address') {
            setAddressData({ ...value })
        }
        if (activeTab === 'coupons') {
            setCouponsData({ ...value })
        }
    }
    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1))
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }, [location])

    return (
        <Row gutter={[16, 16]} className='bg-white rounded-md h-full '>
            <Col lg={24} xl={18} flex='1 1 200px' className='overflow-y-auto h-full'>
                <div className='flex justify-between align-center px-3 pt-3'>
                    <div className='m-0 align-center'>
                        <span className='text-lg font-semibold'>Customise UI</span>
                        <p className='text-gray-500 gap-0'>
                            This section enables you to customise UI for checkout, add banners, enable step specific
                            features and more.
                        </p>
                    </div>
                </div>
                <Tabs
                    className='p-3'
                    defaultActiveKey={location.state?.activeTab || 'brand'}
                    items={[
                        {
                            key: 'brand',
                            label: <span className='flex items-center gap-x-1.5'>Brand</span>,
                            children: <Brand onChange={handleChanges} />,
                        },
                        {
                            key: 'checkout-element',
                            label: <span className='flex items-center gap-x-1.5'>Checkout Elements</span>,
                            children: <CheckoutElements />,
                        },
                        {
                            key: 'login',
                            label: <span className='flex items-center gap-x-1.5'>Login</span>,
                            children: <Login onChange={handleChanges} />,
                        },
                        {
                            key: 'address',
                            label: <span className='flex items-center gap-x-1.5'>Address</span>,
                            children: <Address onChange={handleChanges} />,
                        },
                        ...(config.diy_enabled
                            ? [
                                  {
                                      key: 'coupons',
                                      label: <span className='flex items-center gap-x-1.5'>Coupons</span>,
                                      children: <Coupons onChange={handleChanges} />,
                                  },
                              ]
                            : []),
                        {
                            key: 'payments',
                            label: <span className='flex items-center gap-x-1.5'>Payments</span>,
                            children: <Payments onChange={handleChanges} />,
                        },
                    ]}
                    onTabClick={(key) => {
                        setActiveTab(key)
                    }}
                />
            </Col>
            <Col flex='0 1 300px' className='overflow-y-auto h-full'>
                <MobileStepUI
                    tabs={activeTab}
                    brandData={brandData}
                    loginData={loginData}
                    paymentData={paymentData}
                    addressData={addressData}
                    couponsData={couponsData}
                />
            </Col>
        </Row>
    )
}

export default CustomizeTab
