import { convertIsoToLocaleString, makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import {
    Button,
    Col,
    Row,
    Table,
    Tag,
    Divider,
    TextArea,
    Tooltip,
    SolutionOutlined,
    UserOutlined,
    LinkOutlined,
    PhoneOutlined,
    MailOutlined,
    LaptopOutlined,
    AimOutlined,
    message,
} from 'gokwik-ui-kit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { abcDetailsItemsColumn } from './data'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { AbandonedCart } from '@library/utilities/interface'
import { useSelector } from 'react-redux'
import { getUserConfig, isMerchantSelector } from '@store/user/selectors'
import { formatAmount } from '@library/utilities/helpers/helper'
import { kwikflowActionMapping } from '@library/utilities/constants/constants'

const AbcDetails = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [abcDetailsData, setAbcDetailsData] = useState<AbandonedCart>(null)
    const [lineItems, setLineItems] = useState([])
    const merchantConfig = useSelector(getUserConfig)
    const [comment, setComment] = useState('')
    const isMerchantUser = useSelector(isMerchantSelector)

    useEffect(() => {
        updateBreadcrumbs((prev) =>
            prev[prev?.length - 1]?.text === String(id)
                ? prev
                : [
                      prev[0],

                      {
                          key: 'abc',
                          href: '/checkout/abandoned-cart',
                          text: 'Abandoned Cart',
                      },
                      {
                          key: 'abc-details',
                          href: `/checkout/abandoned-cart/${id}`,
                          text: String(id),
                      },
                  ],
        )
    }, [])

    const fetchAbcDetails = useCallback(async () => {
        const response = await makeAPICall({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.getAbandonedCarts}/${id}`,
        })

        if (response?.data?.status_code === 200 && Object.keys(response?.data?.data).length) {
            setAbcDetailsData(response.data?.data[0])
            let lineItems = response.data.data[0].items.map((u, i) => {
                u['final_line_price'] = +u['final_line_price'] / 100
                u['final_price'] = +u['final_price'] / 100
                u['total_discount'] = +u['total_discount'] / 100
                return u
            })
            setLineItems(lineItems)
        } else {
            setAbcDetailsData(null)
        }
        if (response.error) {
            message.error('Cart not found')
            navigate('/checkout/abandoned-cart')
        }
    }, [id])

    useEffect(() => {
        fetchAbcDetails()
    }, [id])

    const customerInformation = useMemo(() => {
        const abc_link =
            (merchantConfig?.skip_otp_abc || merchantConfig?.skip_otp_abc_for_cod_rto) &&
            abcDetailsData?.['abc_url'] &&
            abcDetailsData?.['abc_cart_key']
                ? abcDetailsData?.['abc_url'] + '&kwik=' + abcDetailsData['abc_cart_key']
                : abcDetailsData?.['abc_url'] || ''
                let additionalInfo = [];
                if (abcDetailsData?.kwikflow_details) {
                    if (
                        abcDetailsData.kwikflow_details.ab_test_flag_id?.includes('Treatment') &&
                        abcDetailsData.kwikflow_details.primary_action &&
                        abcDetailsData.kwikflow_details.secondary_action
                    ) {
                        additionalInfo = [
                            {
                                icon: LaptopOutlined,
                                value: kwikflowActionMapping[abcDetailsData.kwikflow_details.primary_action],
                                label: 'Kwikflow Action (A)',
                            },
                            {
                                icon: LaptopOutlined,
                                value: kwikflowActionMapping[abcDetailsData.kwikflow_details.secondary_action],
                                label: 'Secondary Action',
                            },
                        ];
                    } else if (abcDetailsData.kwikflow_details.kwik_ai_action) {
                        additionalInfo = [
                            {
                                icon: LaptopOutlined,
                                value: kwikflowActionMapping[abcDetailsData.kwikflow_details.kwik_ai_action],
                                label: `Kwikflow Action${
                                    abcDetailsData.kwikflow_details.ab_test_flag_id !== 'NA'
                                        ? abcDetailsData.kwikflow_details.ab_test_flag_id.includes('Treatment')
                                            ? '(A)'
                                            : '(B)'
                                        : ''
                                }`,
                            },
                        ];
                    }
                }
        return [
            { icon: SolutionOutlined, value: abcDetailsData?.id, label: 'ID' },
            {
                icon: UserOutlined,
                value: abcDetailsData?.['Customer.firstname'] + ' ' + abcDetailsData?.['Customer.lastname'],
                label: 'Name',
            },
            { icon: PhoneOutlined, value: abcDetailsData?.['Customer.phone'], label: 'Phone' },
            { icon: MailOutlined, value: abcDetailsData?.['Customer.email'], label: 'Email Address' },
            {
                icon: LinkOutlined,
                value: (
                    <a href={abc_link.startsWith('http') ? abc_link : 'https://' + abc_link} target='_blank'>
                        {abc_link}
                    </a>
                ),
                element: (
                    <a href={abc_link.startsWith('http') ? abc_link : 'https://' + abc_link} target='_blank'>
                        <LinkOutlined />
                    </a>
                ),
                label: 'ABC Link',
            },
            {
                icon: AimOutlined,
                value: `${abcDetailsData?.['Address.address']}, ${abcDetailsData?.['Address.city']}, ${abcDetailsData?.['Address.state']}, ${abcDetailsData?.['Address.pincode']}, ${abcDetailsData?.['Address.country']}`,
                label: 'Address',
            },
            {
                icon: LaptopOutlined,
                value: abcDetailsData?.['request_id'],
                label: 'Request ID',
                created_at: abcDetailsData?.created_at,
                updated_at: abcDetailsData?.updated_at,
            },
            { icon: LaptopOutlined, value: abcDetailsData?.['mkt_source'], label: 'UTM Source' },
            { icon: LaptopOutlined, value: abcDetailsData?.['mkt_campaign'], label: 'UTM Campaign' },
            { icon: LaptopOutlined, value: abcDetailsData?.['mkt_medium'], label: 'UTM Medium' },
            { icon: LaptopOutlined, value: abcDetailsData?.['store_source'], label: 'Store Source' },
            { icon: LaptopOutlined, value: abcDetailsData?.['store_platform'], label: 'Store Platform' },
            ...(abcDetailsData?.['kwikflow_details']?.rule_name &&
            abcDetailsData?.['kwikflow_details']?.rule_name !== 'NA'
                ? [
                      {
                          icon: LaptopOutlined,
                          value: abcDetailsData?.['kwikflow_details']?.rule_name,
                          label: 'Kwikflow Name',
                      },
                  ]
                : []),
            ...additionalInfo,
        ]
    }, [abcDetailsData])

    const pricingDetails = useMemo(
        () => [
            {
                icon: PhoneOutlined,
                value: formatAmount(+(abcDetailsData?.['original_total_price'] || 0), 2),
                label: 'Total Original Price',
            },
            {
                icon: PhoneOutlined,
                value: formatAmount(+(abcDetailsData?.['total_price'] || 0), 2),
                label: 'Total Price',
            },
            {
                icon: PhoneOutlined,
                value: formatAmount(+(abcDetailsData?.['discount']?.['total_discount'] || 0), 2),
                label: 'Discount Amount',
            },
            {
                icon: PhoneOutlined,
                value: abcDetailsData?.['discount']?.['title'] || '',
                label: 'Discount Coupon Code',
            },
            {
                icon: PhoneOutlined,
                value: formatAmount(+(abcDetailsData?.['shipping']?.['price'] || 0), 2),
                label: 'Shipping Charges',
            },
        ],
        [abcDetailsData],
    )

    const addComment = async (value) => {
        if (!value) {
            message.error('Please Enter Comment')
            return
        }
        try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.addCommentAbandonedCart(id),
                payload: { comment },
            })
            if (response.success) {
                message.success('Comment Added')
                setComment('')
                fetchAbcDetails()
            }
        } catch (err) {
            message.error(err.response?.data?.message || 'Unable to add comment.')
        }
    }

    const merchantId = abcDetailsData?.merchant_id
    const reqId = abcDetailsData?.request_id

    const kwikflowDetails = useCallback(async () => {
        if (!merchantId || !reqId) {
            return
        }
        const response = await makeAPICall({
            method: 'get',
            url: `${process.env.RTO_DASHBOARD_SERVICE_URL}${APIEndPoints.getKwikflowDetails}`,
            skipLoader: true,
            headers: {
                Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
            },
            params: {
                merchant_id: merchantId,
                request_id: reqId,
            },
        })
        if (response.success) {
            setAbcDetailsData((prevAbcData) => ({
                ...prevAbcData,
                    kwikflow_details: response?.data?.data,
            }))
        } else {
            console.error('Failed to fetch kwikflow details :', response.error)
        }
    }, [id, reqId, merchantId])

    useEffect(() => {
        kwikflowDetails()
    }, [id, reqId, merchantId])

    return (
        <div className='abc-details-page w-full  flex flex-col gap-y-4 inter'>
            <Row className='p-2 rounded bg-neutrals-15' align={'middle'} justify={'space-between'}>
                <Col sm={12} className='flex items-center gap-x-2'>
                    <span className='text-black text-base inter font-semibold m-0 tracking-wide'>
                        Abandoned Cart #{abcDetailsData?.id}
                    </span>
                </Col>
            </Row>

            <Row className='rounded w-full bg-neutrals-15 p-2' justify={'space-between'}>
                <Col span={15}>
                    <Row gutter={[0, 12]}>
                        <Col span={24}>
                            <span className='text-black text-base inter font-semibold m-0'>Basic Details</span>
                        </Col>

                        <Col className='flex flex-col gap-y-2' span={12}>
                            {customerInformation.slice(0, 6).map((itm) => (
                                <div
                                    className='flex items-center gap-2 w-full  text-sm inter font-normal'
                                    key={itm.label}
                                >
                                    <div className='text-gray-600 nowrap w-max flex'>
                                        <itm.icon className='mr-2' width={20} height={20} />
                                        <span className='nowrap no-wrap'>{itm.label}: </span>
                                    </div>
                                    <span className='text-black font-medium truncate flex-grow'>
                                        <Tooltip title={itm.value}>{itm.element || itm.value || ''}</Tooltip>
                                    </span>{' '}
                                </div>
                            ))}
                        </Col>
                        <Col className='rounded flex flex-col gap-y-2' span={12}>
                            {customerInformation.slice(6).map((itm) => (
                                <div
                                    className='flex items-center w-full gap-2 text-sm inter font-normal'
                                    key={itm.label}
                                >
                                    <div className='text-gray-600 no-wrap'>
                                        <itm.icon className='mr-2' width={20} height={20} />
                                        <span>{itm.label}: </span>
                                    </div>
                                    <span className='text-black font-medium truncate'>
                                        {itm?.label === 'Request ID' ? (
                                            isMerchantUser ? (
                                                <Tooltip title={itm.value}>{itm.value}</Tooltip>
                                            ) : (
                                                <a className='text-primary'>
                                                    <Link
                                                        to={`/checkout/order-analytics?request_id=${itm.value}&created_at=${itm.created_at}&updated_at=${itm.updated_at}`}
                                                        state={{
                                                            path: `/checkout/abandoned-cart/${id}`,
                                                            pageName: 'Abandoned Cart',
                                                            cart_id: id,
                                                        }}
                                                    >
                                                        <Tooltip title={itm.value}>{itm.value}</Tooltip>
                                                    </Link>
                                                </a>
                                            )
                                        ) : (
                                            <>
                                                <Tooltip title={itm.value}>{itm.element || itm.value || ''}</Tooltip>
                                            </>
                                        )}
                                    </span>{' '}
                                </div>
                            ))}
                        </Col>
                    </Row>
                </Col>
                <Col span={1}>
                    <Divider type='vertical' className='h-[90%]' />
                </Col>
                <Col span={8}>
                    <Row gutter={[0, 12]}>
                        <Col span={24}>
                            <span className='text-black text-base inter font-semibold m-0'>Pricing Details</span>
                        </Col>
                        <Col className='flex flex-col gap-y-2' span={24}>
                            {pricingDetails.map((itm) => (
                                <div
                                    className='flex items-center w-full gap-2 text-sm inter font-normal'
                                    key={itm.label}
                                >
                                    <div className='text-gray-600'>
                                        {/* <itm.icon className='mr-2' width={20} height={20} /> */}
                                        <span>{itm.label}: </span>
                                    </div>
                                    <span className='text-black font-medium max-w-[50%] truncate'>
                                        {itm.value || '-'}
                                    </span>{' '}
                                </div>
                            ))}
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row gutter={[0, 12]} className='bg-white rounded p-2'>
                <Col span={24}>
                    <span className='text-black text-base inter font-semibold'>Line Items</span>
                </Col>
                <Col sm={24}>
                    <Table
                        scroll={{ x: 800, y: 300 }}
                        columns={abcDetailsItemsColumn}
                        pagination={false}
                        dataSource={lineItems || []}
                    />
                </Col>
            </Row>
            {isMerchantUser && (
                <Row gutter={[0, 12]} className='bg-white rounded p-2'>
                    <Col span={24}>
                        <span className='text-black text-base inter font-semibold'>Comment</span>
                    </Col>
                    {!!abcDetailsData?.comment?.length && (
                        <Col span={24}>
                            <Row gutter={[0, 8]}>
                                {abcDetailsData.comment.map((comment) => (
                                    <Col key={comment.created_at} span={24}>
                                        <Row className='border border-solid border-gray-200 px-2 py-1 rounded-lg'>
                                            <Col span={24}>
                                                <Row align={'middle'} justify={'space-between'}>
                                                    <span className='font-semibold text-base'>{comment.userName}</span>
                                                    <span className='text-xs'>
                                                        {convertIsoToLocaleString(comment.created_at)}
                                                    </span>
                                                </Row>
                                            </Col>
                                            <Col span={24}>
                                                <p className='text-base'>{comment.comment}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    )}

                    <>
                        <Col span={24}>
                            <TextArea
                                label=''
                                size={'large'}
                                className='w-full'
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                placeholder='Add your comment here.'
                            />
                        </Col>
                        <Col span={24} className='flex flex-row-reverse'>
                            <Button onClick={addComment} variant='primary'>
                                Submit
                            </Button>
                        </Col>
                    </>
                </Row>
            )}
        </div>
    )
}

export default AbcDetails
