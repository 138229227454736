import { Button, ChipInput, Form, Input, InputNumber, Radio, Select, Switch } from 'gokwik-ui-kit'

interface Field {
    required?: boolean
    colon?: boolean
    type: string
    label: string
    name: string | string[]
    rules?: any[]
    description?: string | JSX.Element
    knowMoreLink?: string
    inputProps?: any
    labelCol?: any
    wrapperCol?: any
    labelWithDescription?: boolean
    otherProps?: {
        labelBold?: boolean
    }
}
interface CustomField {
    type: 'custom'
    render: () => JSX.Element
    hidden: boolean
    span: number
    label: string
    name?: string
}

const RenderField = ({ field, form }: { field: Field | CustomField; form: any }) => {
    if (field.type === 'custom') {
        field = field as CustomField
        return field.render ? field.render() : null
    }
    field = field as Field
    const label = field.labelWithDescription ? (
        <div className='flex flex-col gap-y-2'>
            <span className='font-semibold'>{field.label}</span>
            {field.description && (
                <p className='text-gray-500 text-sm'>
                    {field.description}
                    {field.knowMoreLink && (
                        <a className='text-sm font-normal ' href={field.knowMoreLink} target='_blank'>
                            {' '}
                            Know More.
                        </a>
                    )}
                </p>
            )}
        </div>
    ) : (
        field.label
    )

    return (
        <>
            {field.type === 'switch' ? (
                <div className='flex justify-between items-center w-full'>
                    <div className='flex-1'>
                        <span className='mr-2 font-semibold'>{field.label}</span>
                        {field.description && (
                            <p className='text-gray-500 text-sm mt-1'>
                                {field.description}
                                {field.knowMoreLink && (
                                    <a className='text-sm font-normal ' href={field.knowMoreLink} target='_blank'>
                                        {' '}
                                        Know More.
                                    </a>
                                )}
                            </p>
                        )}
                    </div>

                    <Form.Item name={field.name} valuePropName='checked' noStyle>
                        <Switch className='mr-2' {...field.inputProps} />
                    </Form.Item>
                </div>
            ) : (
                <Form.Item
                    label={label}
                    name={field.name}
                    required={field.required}
                    colon={field?.colon}
                    className='my-0'
                    wrapperCol={field?.wrapperCol}
                    labelCol={
                        field.otherProps?.labelBold
                            ? {
                                  className: 'text-sm font-semibold',
                              }
                            : field?.labelCol
                            ? field?.labelCol
                            : undefined
                    }
                    rules={field.rules}
                    extra={
                        field.description &&
                        !field.labelWithDescription && <p className='text-sm text-gray-500 mt-1'>{field.description}</p>
                    }
                >
                    {field.type === 'input' ? (
                        <Input
                            onBlur={() => {
                                const value = form.getFieldValue(field?.name)
                                form.setFieldValue(field?.name, value?.trim() || '')
                            }}
                            {...field.inputProps}
                        />
                    ) : field.type === 'select' ? (
                        <Select className='w-full' {...field.inputProps} />
                    ) : field.type === 'inputNumber' ? (
                        <InputNumber
                            onBlur={() => {
                                const value = form.getFieldValue(field?.name)
                                form.setFieldValue(field?.name, value?.trim() || '')
                            }}
                            className='w-full'
                            {...field.inputProps}
                        />
                    ) : field.type === 'radio' ? (
                        <Radio.Group {...field.inputProps} />
                    ) : field.type === 'button' ? (
                        <Button {...field.inputProps} />
                    ) : (
                        field.type === 'chip' && (
                            <div>
                                <ChipInput
                                    chips={form.getFieldValue(field.name) || []}
                                    onAddChip={(chip) => {
                                        if (!chip.trim()) return
                                        const updatedChips = [...(form.getFieldValue(field.name) || []), chip.trim()]
                                        form.setFieldValue(field.name, updatedChips)
                                    }}
                                    onRemoveChip={(value) => {
                                        const updatedChips = form
                                            .getFieldValue(field.name)
                                            ?.filter((chip) => chip !== value)
                                        form.setFieldValue(field.name, updatedChips)
                                    }}
                                    {...field.inputProps}
                                />
                            </div>
                        )
                    )}
                </Form.Item>
            )}
        </>
    )
}

export default RenderField
