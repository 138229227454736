import React, { useState } from 'react'
import { Button, Col, Divider, Input, Radio, Row } from 'gokwik-ui-kit'

const DiscountSuggestionsPreview = ({ previewPage, listItems }) => {
    const [selectedCoupon, setSelectedCoupon] = useState('')

    return (
        <div className='bg-gray-50 rounded-xl p-2 mt-4'>
            {previewPage === 'checkout' ? (
                <div className='p-4 bg-white rounded-lg shadow-lg max-w-md mx-auto'>
                    <div className='flex flex-col gap-3'>
                        <div
                            style={{ boxShadow: 'box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02)' }}
                            className=' pl-2 pr-3 py-2 border border-solid border-[#004B8D26] bg-[#004B8D0D] rounded-md'
                        ></div>
                        <div
                            style={{ boxShadow: 'box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02)' }}
                            className=' pl-2 pr-3 py-2 border border-solid border-[#004B8D26] bg-[#004B8D0D] rounded-md'
                        ></div>
                    </div>
                    <Divider />
                    <div className='flex flex-col gap-3'>
                        <div className='text-md font-semibold'>Available Coupons</div>
                        <Row justify={'space-between'}>
                            <Col span={18}>
                                <Input placeholder='Discount code or gift card' disabled />
                            </Col>
                            <Col>
                                <Button className='border-none' disabled>
                                    Apply
                                </Button>{' '}
                            </Col>
                        </Row>

                        <div className='max-h-60 overflow-y-auto'>
                            {listItems?.map((discount) => {
                                if (discount?.is_active)
                                    return (
                                        <div
                                            key={discount?.id}
                                            className='mb-4 border border-solid border-[#004B8D26] rounded-lg'
                                        >
                                            <div className='flex'>
                                                <div className=' text-white p-2 flex flex-col justify-center items-center rounded-l-lg'>
                                                    <Radio
                                                        key={discount?.id}
                                                        value={discount?.id}
                                                        checked={discount?.id === selectedCoupon}
                                                        onChange={(e) => setSelectedCoupon(e.target.value)}
                                                    />
                                                </div>
                                                <div className='flex-1 py-2'>
                                                    <div className='font-semibold px-2 py-1'>
                                                        {discount?.discount_code}
                                                    </div>
                                                    <div className='text-sm px-2 py-1'>{discount?.discount_detail}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                            })}
                        </div>
                    </div>
                    <Divider />
                    <div className='flex flex-col gap-3'>
                        <div
                            style={{ boxShadow: 'box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02)' }}
                            className=' pl-2 pr-3 py-2 border border-solid border-[#004B8D26] bg-[#004B8D0D] rounded-md'
                        ></div>
                        <div
                            style={{ boxShadow: 'box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02)' }}
                            className=' pl-2 pr-3 py-2 border border-solid border-[#004B8D26] bg-[#004B8D0D] rounded-md'
                        ></div>
                    </div>
                </div>
            ) : (
                <div className='p-4 bg-white rounded-lg shadow-lg max-w-md mx-auto'>
                    <h2 className='text-lg font-semibold mb-4'>Coupons</h2>
                    <div className='mb-4'>
                        <div className='block text-sm font-normal mb-1'>Have a discount code?</div>
                        <Row justify={'space-between'}>
                            <Col span={18}>
                                <Input placeholder='Enter discount code' disabled />
                            </Col>
                            <Col>
                                <Button className='border-none' type='primary' disabled>
                                    Apply
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <Divider />

                    <div className='max-h-80 overflow-y-auto'>
                        {listItems?.map((discount) => {
                            if (discount?.is_active)
                                return (
                                    <div
                                        key={discount?.id}
                                        className='mb-4 border border-solid border-[#004B8D26] rounded-lg'
                                    >
                                        <div className='flex'>
                                            <div className='bg-purple-500 text-white flex flex-col justify-center items-center rounded-l-lg'>
                                                <div className='rotate-[270deg] text-sm'>₹50 OFF </div>
                                            </div>
                                            <div className='flex-1'>
                                                <div className='font-semibold p-2 '>{discount?.discount_detail} 🎉</div>
                                                <div className='border-dashed border-t border-w-0.5 border-gray-100 ml-2 mr-2'></div>
                                                <div className='text-sm p-2'>
                                                    <span className='px-2 py-1 border border-solid rounded-lg'>
                                                        {discount?.discount_code}
                                                    </span>
                                                </div>
                                                <div className='bg-blue-100 mt-2 w-full items-center'>
                                                    <Button type='link' className='w-full text-center' disabled>
                                                        Tap to Apply
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}

export default DiscountSuggestionsPreview
