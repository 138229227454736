import { Button, Card, Col, Drawer, Form, InfoCircleFilled, Input, message, Row, Switch } from 'gokwik-ui-kit'
import BookDownload from '@library/images/book-download.svg'
import { useEffect, useState } from 'react'
import PopCoins from '@library/images/PixelIcons/loyaltyPoints/popcoins.svg'
import Nector from '@library/images/PixelIcons/loyaltyPoints/nector.svg'
import Flits from '@library/images/PixelIcons/loyaltyPoints/flits.svg'
import Yotpo from '@library/images/PixelIcons/loyaltyPoints/yotpo.svg'
import CreditYard from '@library/images/PixelIcons/loyaltyPoints/creditYard.svg'
import EasyRewards from '@library/images/PixelIcons/loyaltyPoints/easyRewards.svg'
import Xeno from '@library/images/PixelIcons/loyaltyPoints/xeno.svg'
import RenderForm from '@library/components/integrations/formRender'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { useSelector } from 'react-redux'
import { selectMerchantCreds } from '@store/integrations/selectors'
import { saveCreds } from '@store/integrations'
import { useAppDispatch } from '@library/utilities/hooks'
import { deepEqualAndDiff } from '@gokwik/utilities'

const LoyaltyPoints = () => {
    const [drawer, setDrawer] = useState(false)

    const [title, setTitle] = useState('')
    const [selectedKey, setSelectedKey] = useState('')
    const [form] = Form.useForm()
    const { config, refetch, saveConfig } = useMerchantConfigApi()
    const customConfig = config?.custom_config
    const merchantCredentials = useSelector(selectMerchantCreds)
    const enabledProvider = config?.loyalty_app === 'flitz' ? 'flits' : config?.loyalty_app
    const dispatch = useAppDispatch()
    const handleSwitchChange = (key, checked, item) => {
        if (checked && enabledProvider && enabledProvider !== key) {
            message.error('Only one loyalty provider can be active at a time.')
            return
        }

        if (checked && item.customize) {
            setSelectedKey(key)
            setDrawer(true)
            setTitle(item.title)
        } else if (!checked) {
            saveConfig({ loyalty_app: null, use_store_credit: false })
        }
    }

    const handleCustomizeClick = (key, title) => {
        setSelectedKey(key)
        setTitle(title)
        setDrawer(true)
    }
    const data = [
        {
            key: 'pop_coins',
            title: 'Pop Coins',
            icon: PopCoins,
            customize: true,
        },
        {
            key: 'nector',
            title: 'Nector',
            icon: Nector,
            customize: true,
        },
        {
            key: 'flits',
            title: 'Flits',
            icon: Flits,
            customize: true,
        },
        {
            key: 'yotpo',
            title: 'Yotpo',
            icon: Yotpo,
            customize: true,
        },
        {
            key: 'credit_yard',
            title: 'Credit Yard',
            icon: CreditYard,
            customize: true,
        },
        {
            key: 'easy_rewardz',
            title: 'Easy Rewards',
            icon: EasyRewards,
            customize: true,
        },
        {
            key: 'xeno',
            title: 'Xeno',
            icon: Xeno,
            customize: true,
        },
    ]
    const handleSave = async () => {
        try {
            await form.validateFields()
            const { custom_config = {}, merchant_config = {}, ...rest } = form.getFieldsValue()

            if (custom_config) {
                if (selectedKey !== 'easy_rewardz') {
                    if (custom_config.hasOwnProperty('enable_auto_apply_credits')) {
                        custom_config.disable_auto_apply_credits = !custom_config.enable_auto_apply_credits
                        delete custom_config.enable_auto_apply_credits
                    }
                    if (custom_config.hasOwnProperty('enable_discount_with_credits')) {
                        custom_config.disable_discount_with_credits = !custom_config.enable_discount_with_credits
                        delete custom_config.enable_discount_with_credits
                    }
                    if (custom_config.hasOwnProperty('enable_cod_with_loyalty')) {
                        custom_config.disable_cod_with_loyalty = !custom_config.enable_cod_with_loyalty
                        delete custom_config.enable_cod_with_loyalty
                    }
                } else if (custom_config.hasOwnProperty('enable_discount_with_giftcard')) {
                    custom_config.disable_discount_with_giftcard = !custom_config.enable_discount_with_giftcard
                    delete custom_config.enable_discount_with_giftcard
                }
            }
            if (config.loyalty_app !== selectedKey) {
                merchant_config.loyalty_app = selectedKey === 'flits' ? 'flitz' : selectedKey
            }
            if (!config.use_store_credit) {
                merchant_config.use_store_credit = true
            }

            let payload = rest[selectedKey.toLocaleLowerCase()]
            if (payload?.hasOwnProperty('max_redeemable_amount')) {
                payload.max_redeemable_amount = payload.max_redeemable_amount
                    ? '' + payload.max_redeemable_amount
                    : null
            }
            if (selectedKey === 'flits' && payload?.hasOwnProperty('user_id')) {
                payload.user_id = +payload.user_id
            }
            if (payload) {
                const res = await dispatch(saveCreds({ [selectedKey.toLowerCase()]: payload }))
                if (res) {
                    const configPayload = deepEqualAndDiff(config, {
                        custom_config: {
                            ...customConfig,
                            ...custom_config,
                        },
                        ...merchant_config,
                    })
                    if (configPayload.isEqual) {
                        setDrawer(false)
                        return
                    }
                    await saveConfig(configPayload.diff, () => {
                        setDrawer(false)
                    })
                } else {
                    message.error('Failed to save credentials')
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    const populateForm = () => {
        if (merchantCredentials) {
            form.setFieldsValue(
                data.reduce((acc, item) => {
                    merchantCredentials[item.key] && (acc[item.key.toLowerCase()] = merchantCredentials[item.key])

                    return acc
                }, {}),
            )
        }
        const disableKeys = [
            'disable_auto_apply_credits',
            'disable_discount_with_credits',
            'disable_cod_with_loyalty',
            'disable_discount_with_giftcard',
            'disable_auto_apply_credits',
        ]
        if (!config) return

        form.setFieldsValue({
            custom_config: {
                ...Object.keys(customConfig).reduce((acc, key) => {
                    if (disableKeys.includes(key)) {
                        acc[`enable_${key.replace('disable_', '')}`] = !customConfig[key]
                    }
                    return acc
                }, {}),
                enable_prepaid_discount_with_gift_card: customConfig.enable_prepaid_discount_with_gift_card,
                loyalty_display_name: customConfig.loyalty_display_name,
            },
            merchant_config: {
                ...config,
            },
        })
    }
    useEffect(() => {
        populateForm()
    }, [config, merchantCredentials])
    return (
        <>
            <Row gutter={[16, 16]} className='mb-20'>
                <Col span={18}>
                    <div className='flex items-center text-lg leading-7'>
                        <p className='text-lg font-semibold'>Loyalty Points</p>
                    </div>
                </Col>
                <Col span={24}>
                    <p className='bg-blue-50 w-full px-4 py-1 rounded-md flex items-center'>
                        <InfoCircleFilled className='text-blue-500 mr-2' />
                        Only one loyalty provider can be active at a time.
                    </p>
                </Col>
                {data.map((item) => (
                    <Col span={24} key={item.key}>
                        <Card className='custom-card'>
                            <Row align='middle'>
                                <Col flex='40px'>
                                    <img src={item.icon} alt={item.title} />
                                </Col>
                                <Col flex='auto'>
                                    <p className='font-semibold'>{item.title}</p>
                                </Col>
                                <Col>
                                    {item.customize && enabledProvider === item.key && (
                                        <Button
                                            className='mr-4 focus:outline-none'
                                            onClick={() => handleCustomizeClick(item.key, item.title)}
                                        >
                                            Customise
                                        </Button>
                                    )}
                                    <Switch
                                        className='focus:outline-none'
                                        checked={enabledProvider === item.key}
                                        onChange={(checked) => handleSwitchChange(item.key, checked, item)}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Drawer
                title={title}
                width={500}
                open={drawer}
                onClose={() => setDrawer(false)}
                footer={
                    <div className='flex justify-end space-x-4'>
                        <Button
                            type='default'
                            onClick={() => {
                                populateForm()
                                setDrawer(false)
                            }}
                        >
                            Discard
                        </Button>
                        <Button type='primary' onClick={handleSave}>
                            Save Changes
                        </Button>
                    </div>
                }
            >
                <RenderForm fieldKey={selectedKey} form={form} />
            </Drawer>
        </>
    )
}

export default LoyaltyPoints
