import { useAppDispatch } from '@library/utilities/hooks'
import { Col, Row, Tabs } from 'gokwik-ui-kit'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import TaxConfig from './taxConfigs'
import OtherConfigs from './otherConfigs'
import { updateBreadcrumbs } from '@gokwik/utilities'

const TaxAndOthers = () => {
    const location = useLocation()
    const [activeTab, setActiveTab] = useState('tax-settings')
    const dispatch = useAppDispatch()
    const tabs = useMemo(
        () => [
            {
                key: 'tax-settings',
                label: 'Tax',
                children: <TaxConfig />,
            },
            {
                key: 'other-settings',
                label: 'Others',
                children: <OtherConfigs />,
            },
        ],
        [],
    )
    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'checkout-settings',
                href: '/checkout/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'tax-and-others',
                href: '/checkout/settings/tax-and-others',
                text: 'Tax & Others',
            },
        ])
    }, [])
    useEffect(() => {
        if (location.state?.activeTab) {
            setActiveTab(location.state.activeTab)
        }
    }, [location.state])

    return (
        <>
            <Row className='p-2 rounded bg-neutrals-15 mb-12' align='middle' justify='space-between'>
                <Col sm={24}>
                    <Tabs
                        className='inter'
                        items={tabs}
                        activeKey={activeTab}
                        onChange={(key) => {
                            setActiveTab(key)
                        }}
                    />
                </Col>
            </Row>
        </>
    )
}

export default TaxAndOthers
