import {
    ArrowRightOutlined,
    Button,
    Checkbox,
    CheckOutlined,
    Col,
    Divider,
    Input,
    Layout,
    Row,
    Skeleton,
    TruckFilled,
} from 'gokwik-ui-kit'
import footerImg from '@library/images/footerimg.svg'
import { useEffect, useState } from 'react'
import discountSvg from '@library/images/discount.svg'
import noImage from '@library/images/nopic.svg'
import discountIcon from '@library/images/removebg.svg'

const { Content, Footer } = Layout

const MobileStepUI = ({ ...props }) => {
    const { tabs, brandData, loginData, paymentData, addressData, couponsData } = props
    const [activeTab, setActiveTab] = useState<any>({
        brand: false,
        login: false,
        payments: false,
        checkoutElement: false,
        address: false,
        coupons: false,
    })
    const [recommendedOffers, setRecommendedOffer] = useState(null)
    const [couponScreen, setCouponScreen] = useState(null)
    const [discountArray, setDiscountArray] = useState([])

    useEffect(() => {
        setRecommendedOffer(couponsData?.isOfferVisible)
        setCouponScreen(couponsData?.isSortClicked)

        if (couponsData?.couponList && couponsData?.couponList.length >= 5) {
            setDiscountArray(couponsData?.couponList.slice(0, 5))
        } else {
            setDiscountArray(couponsData?.couponList)
        }
    }, [couponsData])

    if (!tabs) {
        setActiveTab((prevState: any) => ({
            ...prevState,
            brand: true,
            login: false,
            payments: false,
            checkoutElement: false,
            address: false,
            coupons: false,
        }))
    }

    useEffect(() => {
        switch (tabs) {
            case 'brand':
                setActiveTab((prevState) => ({
                    ...prevState,
                    brand: true,
                    login: false,
                    payments: false,
                    checkoutElement: false,
                    address: false,
                    coupons: false,
                }))
                break
            case 'login':
                setActiveTab((prevState) => ({
                    ...prevState,
                    login: true,
                    brand: false,
                    payments: false,
                    checkoutElement: false,
                    address: false,
                    coupons: false,
                }))
                break
            case 'checkout-element':
                setActiveTab((prevState) => ({
                    ...prevState,
                    payments: false,
                    brand: false,
                    login: false,
                    checkoutElement: true,
                    address: false,
                    coupons: false,
                }))
                break
            case 'payments':
                setActiveTab((prevState) => ({
                    ...prevState,
                    payments: true,
                    brand: false,
                    login: false,
                    checkoutElement: false,
                    address: false,
                    coupons: false,
                }))
                break
            case 'address':
                setActiveTab((prevState) => ({
                    ...prevState,
                    payments: false,
                    brand: false,
                    login: false,
                    checkoutElement: false,
                    address: true,
                    coupons: false,
                }))
                break
            case 'coupons':
                setActiveTab((prevState) => ({
                    ...prevState,
                    payments: false,
                    brand: false,
                    login: false,
                    checkoutElement: false,
                    address: false,
                    coupons: true,
                }))
                break
            default:
                setActiveTab((prevState) => ({
                    ...prevState,
                    brand: true,
                    login: false,
                    payments: false,
                    checkoutElement: false,
                    address: false,
                    coupons: false,
                }))
                break
        }
    }, [tabs])
    return (
        <div className='w-full h-full p-1'>
            <Row>
                <span className='text-lg font-semibold'>Preview</span>
            </Row>
            {couponScreen && activeTab.coupons ? (
                <Layout className='p-1  border-4 rounded-3xl border-solid bg-white border-gray-200'>
                    <Row className='p-2 w-full'>
                        <Col flex='1 1 200px' className=' text-black text-sm font-semibold'>
                            Offers & Benefits
                        </Col>
                        <Col flex='0 1 auto'>x</Col>
                        <Col className='w-full pt-2 pb-2'>
                            <Input size='middle' className='rounded-md' placeholder='Enter Coupon Code' />
                        </Col>
                    </Row>
                    <Row className='p-2 w-full'>
                        <Col flex='1 1 200px' className=' text-black text-sm font-semibold'>
                            Available Offers
                        </Col>
                        {discountArray && discountArray?.length > 0 ? (
                            discountArray.map((item, index) => {
                                return (
                                    <Col key={index} className='w-full pt-2 pb-2'>
                                        <span className='flex flex-row gap-2 items-center'>
                                            <span className='bg-gray-200 rounded-full flex items-center justify-center h-6 w-6'>
                                                <img src={discountIcon} alt='discount' className='h-3 w-3' />
                                            </span>
                                            <p className='text-sm font-semibold p-1 border-dotted border-gray-200 rounded-md'>
                                                {item.code}
                                            </p>
                                        </span>
                                        <p className='text-xs p-1'>{item.description}</p>
                                        <Divider className='m-1' />
                                    </Col>
                                )
                            })
                        ) : (
                            <>
                                <Col key={''} className='w-full pt-2 pb-2'>
                                    <span className='flex flex-row gap-2 items-center'>
                                        <span className='bg-gray-200 rounded-full flex items-center justify-center h-6 w-6'>
                                            <img src={discountIcon} alt='discount' className='h-3 w-3' />
                                        </span>
                                        <Skeleton />
                                    </span>
                                    <Divider className='m-1' />
                                </Col>
                                <Col key={''} className='w-full pt-2 pb-2'>
                                    <span className='flex flex-row gap-2 items-center'>
                                        <span className='bg-gray-200 rounded-full flex items-center justify-center h-6 w-6'>
                                            <img src={discountIcon} alt='discount' className='h-3 w-3' />
                                        </span>
                                        <Skeleton />
                                    </span>
                                    <Divider className='m-1' />
                                    <Skeleton title paragraph={false} className='w-40 m-auto' />
                                </Col>
                            </>
                        )}
                    </Row>
                </Layout>
            ) : (
                <Layout className='p-1  border-4 rounded-3xl border-solid border-gray-200'>
                    <Row className='justify-between items-center bg-white p-1 pl-2 pr-2 border border-none rounded-tl-2xl rounded-tr-2xl'>
                        <Col>
                            <img
                                src={brandData?.logo || noImage}
                                alt='Upload logo'
                                className='h-8 w-8 object-cover border-none rounded-lg'
                            />
                        </Col>
                        <Col>
                            <span className='text-[#888] text-[12px]'>
                                {activeTab.coupons && <span> Mobile - Address - Pay</span>}
                                {(activeTab.brand || activeTab.login || activeTab.checkoutElement) && (
                                    <span>
                                        <b className='text-[#00aa00]'>✔ Mobile</b> - Address - Pay
                                    </span>
                                )}
                                {activeTab.address && (
                                    <span>
                                        <b className='text-[#00aa00]'>✔ Mobile ✔Address</b> - Pay
                                    </span>
                                )}
                                {activeTab.payments && (
                                    <span>
                                        <b className='text-[#00aa00]'>✔ Mobile ✔Address ✔Pay</b>
                                    </span>
                                )}
                            </span>
                        </Col>
                    </Row>
                    {(activeTab.login || activeTab.payments || activeTab.address) && (
                        <Row
                            style={{
                                backgroundColor: activeTab.login
                                    ? loginData?.background_color
                                    : activeTab.address
                                    ? addressData?.background_color
                                    : activeTab.payments
                                    ? paymentData?.background_color
                                    : 'black',
                            }}
                            className='mb-1 p-1'
                        >
                            <Col
                                style={{
                                    color: activeTab.login
                                        ? loginData?.text_color
                                        : activeTab.address
                                        ? addressData?.text_color
                                        : activeTab.payments
                                        ? paymentData?.text_color
                                        : 'white',
                                }}
                                className='m-auto'
                            >
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: activeTab.login
                                            ? loginData?.banner_content?.htmlString || 'Banner Text To Be Updated'
                                            : activeTab.address
                                            ? addressData?.banner_content?.htmlString || 'Banner Text To Be Updated'
                                            : activeTab.payments
                                            ? paymentData?.banner_content?.htmlString || 'Banner Text To Be Updated'
                                            : 'Banner Text To Be Updated',
                                    }}
                                ></p>
                            </Col>
                        </Row>
                    )}
                    <Content className='mt-1'>
                        {/* Input Boxes */}
                        <Row className='justify-center gap-2'>
                            {!activeTab.address && (
                                <Col span={24}>
                                    <div className='bg-[#fff] p-[24px] rounded-2xl'>
                                        <Row gutter={[16, 16]}>
                                            <Col span={24}></Col>
                                        </Row>
                                    </div>
                                </Col>
                            )}

                            <Col span={24}>
                                {activeTab.coupons ? (
                                    <div className='bg-white shadow-sm p-2 rounded-2xl'>
                                        <div className='border border-gray-8 rounded-full p-1 mb-2'>
                                            <div className='flex p-2 justify-between rounded-xl border-solid border-gray-100 items-center'>
                                                <p className='flex-1 align-center text-gray-600 text-sm focus:ring-0 focus:outline-none'>
                                                    <img src={discountSvg} className='w-4 h-4' alt='img' />{' '}
                                                    {recommendedOffers ? (
                                                        <>
                                                            <span className='text-[10px] text-black'>HAIRBOX</span>
                                                            <span className='ml-2 px-2 py-1 bg-green-100 text-green-500 text-[10px] font-thin rounded'>
                                                                You Save ₹100
                                                            </span>
                                                        </>
                                                    ) : (
                                                        ' Enter Coupon Code'
                                                    )}
                                                </p>
                                                <Button
                                                    size='small'
                                                    className='p-0 border-none text-xs text-[#004b8d] bg-transparent'
                                                >
                                                    Apply
                                                </Button>
                                            </div>
                                        </div>
                                        <div className='flex justify-between items-center px-1'>
                                            <span className='text-gray-500 text-xs'>5 coupons available</span>
                                            <p className='text-blue-600 hover:text-blue-700 flex items-center text-xs'>
                                                View Coupons
                                                <span className='ml-1'>›</span>
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='bg-[#fff] p-[24px] rounded-2xl'>
                                        <Row gutter={[16, 16]}>
                                            <Col span={24}></Col>
                                        </Row>
                                    </div>
                                )}
                            </Col>

                            {activeTab.payments && (
                                <>
                                    <span className='flex w-full flex-start'>
                                        {' '}
                                        <p className='text-sm text-gray-500'>PAYMENT OPTIONS</p>{' '}
                                    </span>
                                    <Col span={24}>
                                        <div className='bg-[#c8c7c7] p-[24px] rounded-2xl'>
                                            <Row gutter={[16, 16]}>
                                                <Col span={24}></Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className='bg-[#c8c7c7] p-[24px] rounded-2xl'>
                                            <Row gutter={[16, 16]}>
                                                <Col span={24}></Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className='bg-[#c8c7c7] p-[24px] rounded-2xl'>
                                            <Row gutter={[16, 16]}>
                                                <Col span={24}></Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className='bg-[#c8c7c7] p-[24px] rounded-2xl'>
                                            <Row gutter={[16, 16]}>
                                                <Col span={24}></Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </>
                            )}
                            {activeTab.address && (
                                <Col span={24}>
                                    <p className='text-sm'>Shipping Address</p>
                                    <div className='bg-[#fff] rounded-2xl p-1 mb-2 border !border-black'>
                                        <Skeleton title={false} paragraph={{ rows: 3, width: '60%' }} />
                                    </div>

                                    <div className='bg-[#fff] rounded-2xl p-1 '>
                                        <Skeleton title={false} paragraph={{ rows: 3, width: '60%' }}></Skeleton>
                                    </div>
                                    <div className='flex justify-center w-full py-2 bg-blue-100 rounded-md mt-2'>
                                        <p className='cursor-pointer text-blue-900'>+Add Address</p>
                                    </div>
                                    <div className='mt-4'>
                                        <p className='text-base font-semibold'>Shipping methods</p>

                                        <div className='flex items-center gap-4 border-2 border-black rounded-xl p-1 mt-2 w-fit'>
                                            {/* Free Shipping Option */}
                                            <div className='flex items-center space-x-2 border border-black rounded-lg px-2 py-1 bg-gray-200'>
                                                <div className='text-black text-2xl'>
                                                    <TruckFilled />
                                                </div>
                                                <p className='text-sm font-medium'>free</p>
                                                <p className='text-sm font-medium'>Free</p>
                                            </div>

                                            {/* Paid Shipping Option */}
                                            <div className='flex items-center space-x-2 border border-black rounded-lg px-2 py-1 bg-gray-200'>
                                                <div className='text-black text-2xl'>
                                                    <TruckFilled />
                                                </div>
                                                <p className='text-sm font-medium'>paid</p>
                                                <p className='text-sm font-medium'>₹10</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )}

                            {(activeTab.brand || activeTab.login || activeTab.checkoutElement || activeTab.coupons) && (
                                <Col span={24}>
                                    <div className='bg-[#fff] rounded-2xl p-1'>
                                        <Skeleton title={true} paragraph={false} className='w-[10rem] m-auto' />
                                        <Row gutter={[16, 16]} className='flex justify-center flex-col'>
                                            <Col span={24} className='text-center p-5'>
                                                <Input placeholder='' size='middle' className='p-2 w-[10rem] m-auto' />
                                                {activeTab.checkoutElement ||
                                                    (activeTab.brand && (
                                                        <Skeleton
                                                            title={true}
                                                            paragraph={false}
                                                            className='w-full m-auto'
                                                        />
                                                    ))}
                                                {activeTab.login && (
                                                    <Checkbox checked>
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    loginData?.consent_text?.htmlString ||
                                                                    'Lorem ipsum dolor sit amet.',
                                                            }}
                                                        ></span>
                                                    </Checkbox>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Content>

                    <Footer className='text-center text-sm text-color-[#888] bg-white mt-5 p-1 border rounded-bl-2xl rounded-br-2xl'>
                        {(activeTab.login || activeTab.address || activeTab.brand || activeTab.coupons) && (
                            <>
                                <Row className='justify-center h-[4rem]'>
                                    <Col span={24} className='justify-center m-0'>
                                        {activeTab.coupons ? (
                                            <Skeleton.Button block={true} size='large' className='w-full m-auto p-1' />
                                        ) : (
                                            <>
                                                <Button
                                                    type='primary'
                                                    block
                                                    size='large'
                                                    className='w-full'
                                                    style={{
                                                        backgroundColor: activeTab.brand
                                                            ? brandData?.bgcolor
                                                            : activeTab.address
                                                            ? addressData?.primary_color
                                                            : 'black',
                                                        color: activeTab.brand
                                                            ? brandData?.textcolor
                                                            : activeTab.address
                                                            ? addressData?.primary_text_color
                                                            : '',
                                                    }}
                                                >
                                                    {activeTab.login
                                                        ? loginData?.primary_button_text
                                                        : activeTab.address
                                                        ? addressData?.primary_button_text
                                                        : 'Continue'}
                                                    <ArrowRightOutlined />
                                                </Button>
                                                <Skeleton title paragraph={false} className='w-full m-auto p-1' />
                                            </>
                                        )}
                                    </Col>
                                </Row>
                                {!activeTab.address && (
                                    <Row justify='center' align='middle'>
                                        <Col span={24}>
                                            <Skeleton title paragraph={false} className='w-40 m-auto' />
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                        {activeTab.checkoutElement && (
                            <>
                                <Row className='justify-center h-[4rem]'>
                                    <Col span={24} className='justify-center m-0'>
                                        <Button
                                            type='primary'
                                            block
                                            size='large'
                                            className='bg-[#000] text-[#fff] w-full'
                                        >
                                            Continue
                                            <ArrowRightOutlined />
                                        </Button>
                                        <Skeleton title paragraph={false} className='w-full m-auto p-1' />
                                    </Col>
                                </Row>
                                <Row justify='center' align='middle'>
                                    <Col span={24}>
                                        <Skeleton title paragraph={false} className='w-[10rem] m-auto' />
                                    </Col>
                                </Row>
                            </>
                        )}

                        <Row>
                            <Col span={24} className='justify-center'>
                                <img src={footerImg} alt='footer' className='w-full' />
                            </Col>
                        </Row>
                    </Footer>
                </Layout>
            )}
        </div>
    )
}

export default MobileStepUI
