import { Badge, Button, Card, Col, Divider, Form, Input, message, Radio, Row, Select, Switch } from 'gokwik-ui-kit'
import BookDownload from '@library/images/book-download.svg'
import Sparkle from '@library/images/PixelIcons/sparkle.svg'
import Link from '@library/images/PixelIcons/link.svg'
import React, { useEffect, useState } from 'react'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { deepEqualAndDiff } from '@gokwik/utilities'
import { useNavigate } from 'react-router-dom'

const CheckoutElements = () => {
    const [checkoutForm]: [any] = Form.useForm()
    const customConfig = Form.useWatch('custom_config', checkoutForm)
    const merchantConfig = Form.useWatch('merchant_config', checkoutForm)
    const staticEdd = Form.useWatch('static_edd', checkoutForm)
    const exitPopout = Form.useWatch('exit_popout', checkoutForm)
    const navigate = useNavigate()
    const [isChanged, setIsChanged] = useState(false)
    const { config, loading, refetch, saveConfig } = useMerchantConfigApi({
        useCache: true,
    })

    const fields = {
        oneClickCheckout: {
            title: (
                <>
                    One Click Checkout
                    <span className='ml-2 px-2 py-1 bg-blue-100 text-blue-500 text-xs font-medium rounded'>
                        <img src={Sparkle} style={{ width: '15px', height: '15px' }}></img>
                        Recommended
                    </span>
                </>
            ),
            fields: [
                {
                    type: 'switch',
                    name: ['custom_config', 'skip_to_payment'],

                    extra: 'Begin checkout with pre-selected delivery address on payment page for logged in customers.',
                    span: 24,
                },
            ],
        },
        discountApplication: {
            title: 'Discount Application',
            fields: [
                {
                    type: 'switch',
                    name: ['custom_config', 'ab', 'payOption'],
                    label: (
                        <>
                            Disable discounts application on checkout
                            <span className='ml-2 px-2 py-1 bg-red-100 text-red-500 text-xs font-medium rounded'>
                                Not Recommended
                            </span>
                        </>
                    ),
                    extra: 'Enabling this feature blocks all users from adding any manual coupon codes on checkout (disables the entire coupon section including loyalty points).',
                    span: 24,
                },
                // {
                //     type: 'switch',
                //     name: ['custom_config', 'hide_discount_on_login_step'],
                //     label: 'Hide discounts option on login step',
                //     extra: 'Lorem ipsum dolor sit amet consectetur. Vel ac facilisis nunc pellentesque magna vitae.',
                //     span: 24,
                // },
                {
                    type: 'switch',
                    name: ['custom_config', 'show_discount_confetti'],
                    label: 'Show a confetti on discount application',
                    extra: 'Enable this feature to enhance customer experience on successful discount application.',
                    span: 24,
                },
            ],
        },
        exitPopout: {
            title: 'Exit Popout',
            span: 24,
            fields: [
                {
                    type: 'radio',
                    name: ['exit_popout', 'value'],
                    span: 24,
                    options: [
                        {
                            value: 'standard',
                            label: 'Show a standard exit popup',
                            description:
                                'Show an exit confirmation message and nudge with applied discounts or free gifts when present.',
                        },
                        {
                            value: 'capture',
                            label: (
                                <>
                                    Capture reasons for exiting checkout{' '}
                                    <span className='ml-2 px-2 py-1 bg-blue-100 text-blue-500 text-xs font-medium rounded'>
                                        <img src={Sparkle} style={{ width: '15px', height: '15px' }}></img>
                                        Recommended
                                    </span>
                                </>
                            ),
                            description:
                                'Customers can respond from a list of reasons (will be shown in analytics and reports).',
                        },
                        // {
                        //     value: 'prompt',
                        //     label: 'Prompt users with a discount on exiting',
                        //     description: 'Show users a specific discount upon exiting checkout',
                        // },
                    ],
                },
                // {
                //     type: 'input',
                //     name: ['exit_popout', 'discount_code'],
                //     label: 'Enter Discount Code',
                //     span: 12,
                //     hidden: exitPopout?.value !== 'prompt',
                //     divider: false,
                //     rules: [
                //         {
                //             required: true,
                //             validator: (_, value) => {
                //                 if (!value) {
                //                     return Promise.reject(new Error('Please enter discount code'))
                //                 }
                //                 return Promise.resolve()
                //             },
                //         },
                //     ],
                // },
            ],
        },
        orderSummarySettings: {
            title: 'Order Summary Settings',
            fields: [
                {
                    type: 'switch',
                    name: ['custom_config', 'hide_Mrp'],
                    label: (
                        <>
                            Show MRP (compare-at price) with a strikethrough{' '}
                            <span className='ml-2 px-2 py-1 bg-blue-100 text-blue-500 text-xs font-medium rounded'>
                                <img src={Sparkle} style={{ width: '15px', height: '15px' }}></img>
                                Recommended
                            </span>
                        </>
                    ),
                    extra: 'Enable this to show discounts on MRP and a slashed price in order summary to the customers on checkout.',
                    span: 24,
                },
                {
                    type: 'switch',
                    name: ['merchant_config', 'show_gst_order_summary_cart'],
                    label: 'Show Taxes on order summary',
                    extra: 'Enable this to show tax breakup in order summary to the customers (tax can be inclusive or exclusive).',
                    span: 24,
                },
                {
                    type: 'select',
                    name: ['merchant_config', 'tax_inclusion'],
                    label: 'Tax Type on Product Price',
                    hidden: !merchantConfig?.show_gst_order_summary_cart,
                    divider: false,
                    rules: [{ required: true }],
                    options: [
                        { label: 'Inclusive', value: 'inclusive' },
                        { label: 'Exclusive', value: 'exclusive' },
                    ],
                },
            ],
        },
        showStaticEDD: {
            title: 'Static EDD',
            fields: [
                {
                    type: 'switch',
                    name: ['custom_config', 'edd_enable'],
                    label: 'Show Static EDD',
                    extra: 'Enable to show static edd on checkout',
                    span: 24,
                    divider: false,
                },
                {
                    type: 'radio',
                    name: ['static_edd', 'value'],
                    span: 24,
                    hidden: !customConfig?.edd_enable,
                    options: [
                        {
                            value: 'default',
                            label: (
                                <>
                                    Use default format{' '}
                                    <span className='ml-2 px-2 py-1 bg-blue-100 text-blue-500 text-xs font-medium rounded'>
                                        <img src={Sparkle} style={{ width: '15px', height: '15px' }}></img>
                                        Recommended
                                    </span>
                                </>
                            ),
                            description:
                                '“Earliest delivery by 1st January” format will be shown where date is calculated based on the number of days input provided',
                        },
                        {
                            value: 'custom',
                            label: 'Use custom message',
                            description:
                                'Show a standard delivery information text to customers on checkout. Eg: "Delivery in 3-5 days"',
                        },
                    ],
                },
                {
                    type: 'input',
                    name: ['merchant_config', 'static_edd_value'],
                    label: 'Enter Number of Days',
                    span: 12,
                    hidden: staticEdd?.value !== 'default',
                    divider: false,
                    rules: [
                        {
                            required: true,
                            validator: (_, value) => {
                                if (!value) {
                                    return Promise.reject(new Error('Please enter number of days'))
                                }
                                return Promise.resolve()
                            },
                        },
                    ],
                },
                {
                    type: 'input',
                    name: ['custom_config', 'custom_edd_message'],
                    label: 'Enter Custom Message',
                    span: 12,
                    hidden: staticEdd?.value !== 'custom',
                    divider: false,
                    rules: [
                        {
                            required: true,
                            validator: (_, value) => {
                                if (!value || value?.trim().length === 0) {
                                    return Promise.reject(new Error('Please enter custom message'))
                                }
                                return Promise.resolve()
                            },
                        },
                    ],
                },
            ],
        },
        enableGST: {
            title: 'Enable GST',
            fields: [
                {
                    type: 'switch',
                    name: ['custom_config', 'gst_billing'],
                    label: 'Capture GST details',
                    extra: 'GST details are verified with government data and billing name and addresses are captured and passed to Shopify orders.',
                    span: 24,
                },
            ],
        },
    }
    const formInitialValues = {
        merchant_config: {
            tax_inclusion: 'inclusive',
        },
        static_edd: {
            value: 'default',
        },

        exit_popout: {
            value: 'standard',
        },
        custom_config: {
            hide_Mrp: true,
        },
    }

    const handleSave = async () => {
        try {
            try {
                await checkoutForm.validateFields()
            } catch (errorInfo) {
                if (errorInfo.errorFields?.length > 0) {
                    const firstErrorField = errorInfo.errorFields[0].name
                    checkoutForm.scrollToField(firstErrorField, {
                        behavior: 'smooth',
                        block: 'center',
                    })
                    return
                }
            }

            const { custom_config = {}, merchant_config = {}, ...rest } = checkoutForm.getFieldsValue()
            if (custom_config?.custom_edd_message) {
                custom_config.custom_edd_message = custom_config.custom_edd_message.trim()
            }
            custom_config.hide_Mrp = !custom_config?.hide_Mrp
            if (rest?.exit_popout?.value === 'standard') {
                custom_config.exit_flow_feedback = false
                // custom_config.exitPopout_discount_code = ''
            } else if (rest?.exit_popout?.value === 'capture') {
                custom_config.exit_flow_feedback = true
                // custom_config.exitPopout_discount_code = ''
            }
            //  else {
            //     custom_config.exit_flow_feedback = false
            //     custom_config.exitPopout_discount_code = exitPopout?.discount_code
            // }

            if (custom_config?.edd_enable && rest?.static_edd?.value === 'default') {
                custom_config.use_custom_edd = false
                merchant_config.use_static_edd = true
            } else if (custom_config?.edd_enable && rest?.static_edd?.value === 'custom') {
                custom_config.use_custom_edd = true
                merchant_config.use_static_edd = false
            } else {
                custom_config.use_custom_edd = false
                merchant_config.use_static_edd = false
            }
            if (merchant_config?.show_gst_order_summary_cart === false) {
                merchant_config.tax_inclusion = 'inclusive'
            }
            const configPayload = deepEqualAndDiff(config, {
                custom_config: {
                    ...config?.custom_config,
                    ...custom_config,
                },
                ...merchant_config,
            })

            if (configPayload.isEqual) {
                setIsChanged(false)
                return
            }
            saveConfig(configPayload.diff)
        } catch (error) {
            console.error('Validation or Save Error:', error)
        }
    }

    useEffect(() => {
        if (!config?.custom_config?.exit_flow_feedback && !config?.custom_config?.exitPopout_discount_code) {
            checkoutForm.setFieldsValue({
                exit_popout: {
                    value: 'standard',
                },
            })
        } else if (config?.custom_config?.exit_flow_feedback) {
            checkoutForm.setFieldsValue({
                exit_popout: {
                    value: 'capture',
                },
            })
        }
        // else if (config?.custom_config?.exitPopout_discount_code) {
        //     checkoutForm.setFieldsValue({
        //         exit_popout: {
        //             value: 'prompt',
        //             discount_code: config?.custom_config?.exitPopout_discount_code,
        //         },
        //     })
        // }
        if (!config?.custom_config?.use_custom_edd) {
            checkoutForm.setFieldsValue({
                static_edd: {
                    value: 'default',
                },
                static_edd_value: config?.static_edd_value,
            })
        } else {
            checkoutForm.setFieldsValue({
                static_edd: {
                    value: 'custom',
                },
                custom_edd_message: config?.custom_config?.custom_edd_message,
            })
        }
        checkoutForm.setFieldsValue({
            custom_config: {
                ...config?.custom_config,
            },
            merchant_config: {
                ...config,
            },
            ...config,
        })
        if (config?.custom_config?.hide_Mrp === true) {
            checkoutForm.setFieldsValue({
                custom_config: {
                    hide_Mrp: false,
                },
            })
            console.log(checkoutForm.getFieldsValue())
        } else {
            checkoutForm.setFieldsValue({
                custom_config: {
                    hide_Mrp: true,
                },
            })
        }
    }, [config])
    return (
        <Form
            name='myForm'
            layout='vertical'
            className='gap-5 flex flex-col'
            form={checkoutForm}
            requiredMark='optional'
            onValuesChange={(values) => {
                if (values) {
                    setIsChanged(true)
                }
            }}
            initialValues={formInitialValues}
        >
            <div className='flex justify-between align-center'>
                <div className='m-0 align-center'>
                    <span className='text-lg font-semibold'>Checkout Elements</span>
                    <p className='text-gray-500 gap-0'>
                        Control checkout UI related elements like order summary, exit pop-up and more.
                    </p>
                </div>
                <div className='m-0'>
                    <Button type='primary' htmlType='submit' disabled={!isChanged} onClick={handleSave}>
                        Save Changes
                    </Button>
                </div>
            </div>
            {Object.entries(fields).map(([key, section]) => {
                return (
                    <Card size='small' key={key}>
                        <div className='flex justify-between items-center pb-2'>
                            <p className='font-medium text-base'>{section.title}</p>
                            {key === 'orderSummarySettings' && (
                                <Button
                                    className='border-gray-300 font-normal'
                                    onClick={() => navigate('/checkout/settings/tax-and-others')}
                                >
                                    <span>
                                        <img src={Link} className='w-3 h-3 mr-2' alt='link icon' />
                                        Configure Tax Settings
                                    </span>
                                </Button>
                            )}
                            {key === 'showStaticEDD' && (
                                <Button
                                    className='border-gray-300 font-normal'
                                    onClick={() =>
                                        navigate('/checkout/settings/integrations', {
                                            state: { activeTab: 'edd-shipping' },
                                        })
                                    }
                                >
                                    <span>
                                        <img src={Link} className='w-3 h-3 mr-2' alt='link icon' />
                                        Configure 3rd Party Edd
                                    </span>
                                </Button>
                            )}
                        </div>
                        <Row gutter={[16, 16]}>
                            {section.fields.map((field, index) =>
                                field.hidden ? null : (
                                    <React.Fragment key={index}>
                                        {index != 0 && !field.divider && <Divider style={{ margin: '0px 0' }} />}
                                        <Col
                                            span={field.span}
                                            className={
                                                field.type === 'switch' ? 'flex justify-between items-center' : ''
                                            }
                                        >
                                            {field.type === 'switch' && (
                                                <div className='flex justify-between items-center w-full'>
                                                    <div className='flex-1'>
                                                        <p className='mb-1 font-md text-sm font-normal'>
                                                            {field.label}
                                                        </p>
                                                        {field.extra && (
                                                            <p className='text-gray-500 text-sm mt-1'>{field.extra}</p>
                                                        )}
                                                    </div>

                                                    <Form.Item name={field.name} valuePropName='checked' noStyle>
                                                        <Switch className='mr-2' />
                                                    </Form.Item>
                                                </div>
                                            )}
                                            {field.type === 'radio' && (
                                                <Form.Item name={field.name} style={{ marginBottom: 0 }}>
                                                    <Radio.Group>
                                                        {field.options.map((option, optIndex) => (
                                                            <React.Fragment key={optIndex}>
                                                                <div>
                                                                    <Radio
                                                                        value={option.value}
                                                                        className='font-md text-sm font-normal'
                                                                    >
                                                                        {option.label}
                                                                    </Radio>
                                                                    {option.description && (
                                                                        <p className='text-gray-500 text-sm ml-6'>
                                                                            {option.description}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                                {optIndex < field.options.length - 1 && (
                                                                    <Divider style={{ margin: '8px 0' }} />
                                                                )}
                                                            </React.Fragment>
                                                        ))}
                                                    </Radio.Group>
                                                </Form.Item>
                                            )}
                                            {field.type === 'input' && (
                                                <div>
                                                    <Form.Item
                                                        label={<span className='font-medium'>{field.label}</span>}
                                                        name={field.name}
                                                        rules={field.rules}
                                                        className='mb-2'
                                                        normalize={(value) => {
                                                            if (
                                                                field.name?.[0] === 'custom_config' &&
                                                                field.name?.[1] === 'custom_edd_message'
                                                            ) {
                                                                return value
                                                            }
                                                            return value?.replace(/\s+/g, ' ').trim()
                                                        }}
                                                    >
                                                        <Input className='w-full' />
                                                    </Form.Item>
                                                </div>
                                            )}
                                            {field.type === 'button' && (
                                                <Button
                                                    className={`w-full outline-none focus:outline-none ${
                                                        field.name === 'tax' ? 'border-gray-400' : ''
                                                    }`}
                                                    size='small'
                                                >
                                                    {field.label}
                                                </Button>
                                            )}
                                            {field.type === 'select' && (
                                                <div className='min-w-96'>
                                                    <Form.Item
                                                        name={field.name}
                                                        label={<span className='font-medium'>{field.label}</span>}
                                                        className='mb-2'
                                                        rules={field.rules}
                                                    >
                                                        <Select className='w-full'>
                                                            {field.options?.map((option) => (
                                                                <Select.Option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            )}
                                        </Col>
                                    </React.Fragment>
                                ),
                            )}
                        </Row>
                    </Card>
                )
            })}
        </Form>
    )
}

export default CheckoutElements
