const getRule = (discount_type) => {
    switch (discount_type) {
        case 'capping':
            return [
                {
                    rule_id: '',
                    priority: 1,
                    conditions: [
                        {
                            discount_type: 'CartDiscount',
                            min_cart_value: 1,
                            minimum_purchase_requirement: {
                                minimum_purchase_amount: null,
                                minimum_quantity: null,
                                no_minimum_requirement: true
                            }
                        },
                    ],
                    actions: [
                        {
                            discount_type: 'CartDiscount',
                            applicability_type: 'cart_percentage_discount',
                            value: null,
                            max_discount: null,
                        },
                    ],
                },
            ]

        case 'buy_x_at_y':
            return [
                {
                    rule_id: '',
                    priority: 0,
                    conditions: [
                        {
                            discount_type: 'ProductBundle',
                            min_product_quantity: null,
                            product_attribute: 'ProductId',
                            applicable_list: null,
                        },
                    ],
                    actions: [
                        {
                            discount_type: 'ProductBundle',
                            product_attribute: 'ProductId',
                            applicability_type: 'flat',
                            value: null,
                        },
                    ],
                },
            ]

        case 'dynamic':
            return []

        case 'quantity':
            return []

        case 'gift_with_product':
            return [
                {
                    rule_id: '',
                    priority: 0,
                    conditions: [
                        {
                            discount_type: 'ProductBundle',
                            min_product_quantity: null,
                            min_product_amount: null,
                            product_attribute: 'ProductId',
                            applicable_list: null,
                            limit_per_order: null,
                        },
                    ],
                    actions: [
                        {
                            discount_type: 'ProductBundle',
                            applicability_type: 'flat',
                            product_attribute: 'ProductId',
                            free_product_quantity: null,
                            applicable_list: null,
                        },
                    ],
                },
            ]

        default:
            break
    }
}

export { getRule }
