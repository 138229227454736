import { Button, Card, Col, Drawer, Form, InfoCircleOutlined, message, Row, Switch, Tooltip } from 'gokwik-ui-kit'
import BookDownload from '@library/images/book-download.svg'
import Mason from '@library/images/PixelIcons/mason.svg'
import Customfit from '@library/images/PixelIcons/customfit.svg'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectMerchantCreds } from '@store/integrations/selectors'
import { useAppDispatch } from '@library/utilities/hooks'
import RenderForm from '@library/components/integrations/formRender'
import { saveCreds } from '@store/integrations'

const OtherIntegrations = () => {
    const [form] = Form.useForm()
    const { config, refetch, saveConfig } = useMerchantConfigApi()
    const customConfig = config?.custom_config
    const merchantCredentials = useSelector(selectMerchantCreds)

    const dispatch = useAppDispatch()

    const handleSwitchChange = (key, checked, item) => {
        if (item.key === 'mason') {
            saveConfig({ is_mason_enabled: checked })
            return
        }
        if (key === 'custom_fit' && !checked) {
            saveConfig({
                custom_config: {
                    ...customConfig,
                    pixels: customConfig.pixels?.filter((pixel) => pixel !== 'customFit'),
                },
            })
        } else {
            saveConfig({
                custom_config: {
                    ...customConfig,
                    pixels: [...(customConfig.pixels || []), 'customFit'],
                },
            })
        }
    }

    useEffect(() => {
        if (merchantCredentials) {
            form.setFieldsValue({
                custom_fit: merchantCredentials.custom_fit,
            })
        }
    }, [config, merchantCredentials])

    const data = [
        {
            key: 'mason',
            title: 'Mode Magic (Mason)',
            checked: config?.is_mason_enabled,
            icon: Mason,
        },
        {
            key: 'custom_fit',
            title: 'Custom Fit',
            checked: customConfig?.pixels.includes('customFit'),
            icon: Customfit,
        },
    ]

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <div className='flex items-center text-lg leading-7'>
                        <p className='text-lg font-semibold'>Other Integrations</p>
                    </div>
                </Col>
                {data.map((item) => (
                    <Col span={24} key={item.key}>
                        <Card className='custom-card'>
                            <Row align='middle'>
                                <Col flex='40px'>
                                    <img src={item.icon} alt={item.title} />
                                </Col>
                                <Col flex='auto'>
                                    <p className='font-semibold'>
                                        {item.key === 'mason' ? (
                                            <>
                                                Mode Magic (Mason)
                                                <Tooltip
                                                    title='Please add the following key in gokwik.liquid file on your theme under
                                                             window.merchantinfo: "modeMagicEnabled: true"'
                                                >
                                                    <InfoCircleOutlined className='text-gray-600 pl-1 cursor-pointer' />
                                                </Tooltip>
                                            </>
                                        ) : (
                                            item.title
                                        )}
                                    </p>
                                </Col>
                                <Col>
                                    <Switch
                                        className='focus:outline-none'
                                        checked={item.checked}
                                        onChange={(checked) => handleSwitchChange(item.key, checked, item)}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default OtherIntegrations
