import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import ProductSearch from '@library/components/discounts/productSearch'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { formatAmount } from '@library/utilities/helpers/helper'
import { AbandonedCart, CreateOrder, DiscountShippingPayload } from '@library/utilities/interface'
import { getMerchantDetails } from '@store/user/selectors'
import {
    Breadcrumb,
    Button,
    Card,
    ClockCircleOutlined,
    CloseOutlined,
    Col,
    ColumnProps,
    CopyOutlined,
    DeleteOutlined,
    Divider,
    Drawer,
    Form,
    InfoCircleOutlined,
    Input,
    InputNumber,
    message,
    MinusCircleOutlined,
    Modal,
    PlusCircleOutlined,
    Radio,
    Row,
    Space,
    Table,
    TextArea,
    Tooltip,
} from 'gokwik-ui-kit'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { navigateToUrl } from 'single-spa'

const editAbcCart = () => {
    const location = useLocation()
    const reqId = location.state?.requestId
    const customerPhone = location?.state?.customerPhone
    const { id } = useParams()
    const merchantId = useSelector(getMerchantDetails)?.id
    const [shippingDetails] = Form.useForm()
    const [editShipping] = Form.useForm()
    const [abcDetailsData, setAbcDetailsData] = useState<AbandonedCart>(null)
    const [searchType, setSearchType] = useState<'products' | 'json'>('products')
    const [countdown, setCountdown] = useState(1200)
    const [customerAddresses, setCustomerAddresses] = useState([])
    const [selectedAddress, setSelectedAddress] = useState(null)
    const [lineItems, setLineItems] = useState([])
    const intervalRef = useRef<NodeJS.Timeout | null>(null)
    const initialLineItemsRef = useRef([])
    const [state, setState] = useState({
        isEdit: false,
        isModalOpen: false,
        updatedShippingData: {},
        paymentModal: false,
        paymentMode: null,
        jsonInput: '',
        newAddress: false,
        showDiscountShipping: false,
        paymentSuccess: false,
        paymentFailed: false,
        shippingCode: 0,
        couponCode: '',
        discountAmount: null,
        paymentLink: '',
        shippingAddress: false,
        customDiscount: 0,
        customShipping: 0,
        newCustomer: false,
        customerId: 0,
        isAutomaticDiscount: false,
        cod: false,
        customerPhoneNotFound: false,
        isCouponApplied: false,
        orderName: '',
    })

    const updateState = (updates: Record<string, any>) => {
        setState((prevState) => ({
            ...prevState,
            ...updates,
        }))
    }
    const [customerDetail, setCustomerDetail] = useState({
        fullName: '',
        phoneNumber: '',
        email: '',
    })

    const [shippingData, setShippingData] = useState({
        fullName: '',
        phoneNumber: '',
        email: '',
        address: '',
        city: '',
        state: '',
        pincode: null,
    })
    const [paymentNumber, setPaymentNumber] = useState(shippingData.phoneNumber || '')

    const fetchCustomer = async ({ phone, customerId }: { phone?: any; customerId?: any }) => {
        const params = phone ? { phone } : customerId ? { customer_id: customerId } : {}
        try {
            const response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getCustomer,
                params: params,
            })
            if (response.data?.status_code === 200) {
                const data = response?.data?.data?.data
                if (phone) {
                    fetchCustomerAddressess(data?.id)
                }

                setCustomerDetail({
                    fullName: (data?.firstname || '') + ' ' + (data?.lastname || ''),
                    phoneNumber: data?.phone || '',
                    email: data?.email || '',
                })
                updateState({
                    customerId: data?.id,
                    customerPhoneNotFound: true,
                })
            } else {
                message.error('Customer not found')
                updateState({
                    customerPhoneNotFound: false,
                })
            }
        } catch (error) {
            console.error('Error fetching customer:', error)
        }
    }
    const createCustomer = async (customer) => {
        const { firstname, lastname, phone, state, city, pincode, address, email } = customer
        const payload = {
            firstname,
            lastname,
            phone,
            state,
            city,
            pincode,
            address1: address,
            email,
        }
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.createCustomer,

                payload,
            })
            if (response.data?.status_code === 200) {
                const data = response?.data?.data?.data
                setCustomerDetail({
                    fullName: (data?.firstname || '') + ' ' + (data?.lastname || ''),
                    phoneNumber: data?.phone || '',
                    email: data?.email || '',
                })
                createAddress(customer, data?.id)
                updateState({
                    customerId: data?.id,
                    newCustomer: true,
                })
            } else {
                message.error('Unable to create customer')
            }
        } catch (error) {
            console.error('Error creating customer:', error)
        }
    }
    const createAddress = async (addressObj, customerId) => {
        const { firstname, lastname, phone, state, city, pincode, address, email } = addressObj
        const payload = {
            firstname,
            lastname,
            phone,
            state,
            city,
            email,
            pincode,
            address,
            customer_id: abcDetailsData?.customer_id || customerId,
            mid: merchantId,
        }
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.createAddress,

                payload,
            })
            if (response.data?.status_code === 200) {
                const addressData = response?.data?.data?.data?.address
                setShippingData({
                    fullName: (addressData?.firstname || '') + ' ' + (addressData.lastname || ''),
                    phoneNumber: addressData?.phone || customerPhone || '',
                    email: addressData?.email || '',
                    address: addressData?.address || '',
                    pincode: addressData.pincode || '',
                    city: addressData.city || '',
                    state: addressData.state || '',
                })
                setCustomerAddresses((prev) => [...prev, addressData])
                setSelectedAddress(addressData.id)
            } else {
                message.error('Unable to create shipping address')
            }
        } catch (error) {
            console.error('Error creating address:', error)
        }
    }

    const fetchCustomerAddressess = async (customerId) => {
        try {
            const response = await makeAPICall({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.getCustomerAddresses}`,
                params: {
                    customer_id: Number(customerId),
                },
            })
            if (response.data?.status_code === 200) {
                const addressData = response?.data?.data?.data?.address
                setCustomerAddresses(addressData)
            } else {
                message.error('Unable to fetch customer addresses')
            }
        } catch (error) {
            console.error('Error fetching customer addresses:', error)
        }
    }
    const fetchAddress = async (aid) => {
        try {
            const response = await makeAPICall({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.getAddress}`,
                params: {
                    aid: aid,
                },
            })
            if (response.data?.status_code === 200) {
                const addressData = response?.data?.data?.data?.address
                setShippingData({
                    fullName: (addressData?.firstname || '') + ' ' + (addressData.lastname || ''),
                    phoneNumber: addressData?.phone || customerPhone || '',
                    email: addressData?.email || '',
                    address: addressData?.address || '',
                    pincode: addressData.pincode || '',
                    city: addressData.city || '',
                    state: addressData.state || '',
                })
            } else {
                message.error('Unable to fetch customer address')
            }
        } catch (error) {
            console.error('Error fetching customer addresses:', error)
        }
    }

    const createOrder = async (method) => {
        const payload: CreateOrder = {
            cart_id: abcDetailsData?.id,
            request_id: reqId || abcDetailsData?.request_id,
            aid: selectedAddress,
            customer_id: abcDetailsData?.customer_id || state.customerId,
            phone: paymentNumber || shippingData?.phoneNumber,
            payment_method: '',
            notify: {
                sms: false,
                whatsapp: false,
            },
        }
        if (method === 'cod') {
            payload.payment_method = 'cod'
        } else if (method === 'whatsapp' || method === 'sms') {
            payload.payment_method = 'payment-link'
            payload.notify = {
                sms: method === 'sms',
                whatsapp: method === 'whatsapp',
            }
        } else if (method === 'payment-link') {
            payload.payment_method = 'payment-link'
        }
        try {
            const response = await makeAPICall({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.createAbcOrder}`,
                payload: payload,
            })
            if (response.data?.status_code === 200) {
                const data = response?.data?.data?.data
                if (method === 'cod') {
                    updateState({
                        isModalOpen: false,
                        paymentSuccess: true,
                    })
                } else if (method === 'whatsapp' || method === 'sms') {
                    setCountdown(data?.timer)
                    updateState({
                        isModalOpen: false,
                        paymentModal: true,
                    })

                    capturePayment({
                        gokwik_oid: data?.gokwik_oid,
                        request_id: reqId || abcDetailsData?.request_id,
                        customer_id: abcDetailsData?.customer_id || state.customerId,
                    })
                } else {
                    setCountdown(data?.timer)
                    updateState({
                        isModalOpen: false,
                        paymentModal: true,
                    })
                    capturePayment({
                        gokwik_oid: data?.gokwik_oid,
                        request_id: reqId || abcDetailsData?.request_id,
                        customer_id: abcDetailsData?.customer_id || state.customerId,
                    })
                }
                updateState({
                    paymentLink: data?.payment_link,
                    orderName: data?.order_name,
                })
            } else {
                message.error(response?.response?.data?.data?.statusMessage)
            }
        } catch (error) {
            console.error('Error creating order:', error)
        }
    }
    const capturePayment = (payload) => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
        }

        intervalRef.current = setInterval(async () => {
            try {
                const response = await makeAPICall({
                    method: 'post',
                    url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.capturePayment}`,
                    payload,
                })

                if (response.data?.status_code === 200) {
                    const data = response?.data?.data?.data
                    if (data?.order_status === 'confirmed') {
                        updateState({
                            paymentModal: false,
                            orderName: data?.order_name,
                            paymentSuccess: true,
                        })

                        clearInterval(intervalRef.current!)
                        intervalRef.current = null
                    } else if (data?.order_status === 'failed') {
                        if (state.isModalOpen) {
                            updateState({ isModalOpen: false })
                        }
                        clearInterval(intervalRef.current!)
                        intervalRef.current = null
                        updateState({ paymentFailed: true })
                    }
                } else {
                    message.error('Unable to capture payment')
                }
            } catch (error) {
                console.error('Error capturing payment:', error)
            }
        }, 10000)
    }
    const clearCapturePaymentInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
            intervalRef.current = null
        }
    }

    const updateCustomerAddress = async (shippingData, customerCreated, customerId) => {
        const { fullName, state, city, pincode, address, phone, email } = shippingData
        const nameParts = fullName?.trim().split(/\s+/) || []
        const firstname = nameParts.slice(0, -1).join(' ') || nameParts[0] || ''
        const lastname = nameParts.length > 1 ? nameParts[nameParts.length - 1] : '.'
        const payload = {
            firstname,
            lastname,
            phone,
            address,
            email,
            pincode,
            state,
            city,
            customerCreated,
            customer_id: abcDetailsData?.customer_id || customerId,
            aid: selectedAddress,
        }
        try {
            const response = await makeAPICall({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.updateCustomerAddress}`,
                payload,
            })
            if (response.data?.status_code === 200) {
                const addressData = response?.data?.data?.data?.address
                setShippingData({
                    fullName: (addressData?.firstname || '') + ' ' + (addressData.lastname || ''),
                    phoneNumber: addressData?.phone || customerPhone || '',
                    email: addressData?.email || '',
                    address: addressData?.address || '',
                    pincode: addressData.pincode || '',
                    city: addressData.city || '',
                    state: addressData.state || '',
                })
                if (customerPhone) {
                    fetchCustomerAddressess(addressData?.customer_id)
                }
            } else {
                message.error('Unable to edit address')
            }
        } catch (error) {
            console.error('Error updating Address:', error)
        }
    }

    const discountShipping = async (discountPayload) => {
        let payload: DiscountShippingPayload = {
            cart_id: Number(id) || abcDetailsData?.id,
            request_id: reqId || abcDetailsData?.request_id,
            ...discountPayload,
            pincode: shippingData?.pincode ? String(shippingData.pincode) : null,
            aid: discountPayload.aid ? discountPayload.aid : selectedAddress || null,
        }
        try {
            const response = await makeAPICall({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.discountShipping}`,
                payload,
            })

            if (response.data?.status_code === 200) {
                const data = response?.data?.data?.data

                if (data?.discount?.errorMsg) {
                    message.error(data?.discount?.errorMsg)
                    updateState({
                        customDiscount: 0,
                        couponCode: data?.discount?.applied_discount?.title,
                        isCouponApplied: false,
                    })
                } else if (data?.discount?.is_valid && data?.discount?.applied_discount?.total_discount > 0) {
                    updateState({
                        customDiscount: data?.discount?.applied_discount?.total_discount,
                        couponCode: data?.discount?.applied_discount?.title,
                        isCouponApplied: true,
                    })
                    if (data?.discount?.applied_discount?.automatic_discount) {
                        updateState({
                            isAutomaticDiscount: true,
                        })
                    } else {
                        updateState({ isAutomaticDiscount: false })
                    }
                }

                if (data?.shipping?.shipping) {
                    updateState({ customShipping: data?.shipping?.shipping?.price })
                }
            } else {
                message.error('Error fetching discount/shipping')
            }
        } catch (error) {
            console.error('Error applying discount on shipping:', error)
        }
    }

    const createAbcCart = async (items) => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.createAbcCart}`,
                payload: {
                    cart: {
                        items: items.map((item) => ({
                            ...item,
                            id: +item.id,
                            product_id: +item.product_id,
                            variant_id: +item.variant_id,
                        })),
                    },
                },
            })
            if (response.data?.status_code === 200) {
                const data = response?.data?.data?.data
                setAbcDetailsData(data)
                if (data?.discount) {
                    updateState({
                        customDiscount: data?.discount?.applied_discount?.total_price,
                        couponCode: data?.discount?.title,
                        isCouponApplied: true,
                    })
                    if (data?.discount?.automatic_discount) {
                        updateState({
                            isAutomaticDiscount: true,
                        })
                    }
                }
                setSelectedAddress(data?.aid)
                initialLineItemsRef.current = data?.items || []
                setLineItems(data?.items)
                message.success('Cart Created')
                let payload = {
                    cart_id: data?.id,
                    request_id: data?.request_id,
                    shipping: {
                        custom_shipping: true,
                        price: 0,
                    },
                    intent: 'shipping',
                }
                discountShipping(payload)
            } else {
                message.error('Cart Creation Error')
            }
        } catch (error) {
            console.error('Error creating ABC cart:', error)
        }
    }
    const updateAbcCart = async (items) => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.updateAbcCart}`,
                payload: {
                    cart: {
                        items: items.map((item) => ({
                            ...item,
                            id: +item.id,
                            product_id: +item.product_id,
                            variant_id: +item.variant_id,
                        })),
                    },
                    request_id: reqId || abcDetailsData?.request_id,
                    cart_id: abcDetailsData?.id,
                },
            })
            if (response.data?.status_code === 200) {
                const data = response?.data?.data?.data
                setAbcDetailsData(data)
                setSelectedAddress(data?.aid || selectedAddress)
                initialLineItemsRef.current = data?.items || []
                if (data?.discount) {
                    updateState({
                        customDiscount: data?.discount?.total_discount,
                        couponCode: data?.discount?.title,
                        isCouponApplied: true,
                    })
                }
                let payload = {}
                if (!data?.shipping) {
                    payload = {
                        cart_id: data?.id,
                        request_id: data?.request_id,
                        shipping: {
                            custom_shipping: true,
                            price: 0,
                        },
                        intent: 'shipping',
                    }
                    discountShipping(payload)
                }
                if (data?.discount || data?.shipping) {
                    if (data?.discount) {
                        payload = {
                            ...payload,
                            aid: data?.aid,
                            coupon_code: data?.discount?.title,
                            intent: 'discount',
                            offer_type: 'store_offer',
                        }
                    }

                    if (data?.shipping) {
                        payload = {
                            ...payload,
                            shipping: {
                                custom_shipping: true,
                                price: data?.shipping?.price || 0,
                            },
                            shippingId: data.shipping.id,
                            intent: 'shipping',
                        }
                    }
                    if (!data?.discount?.automatic_discount) {
                        await discountShipping(payload)
                    }
                }

                setLineItems(data?.items)
                message.success('Cart Updated')
            } else {
                message.error('Unable to update cart')
            }
        } catch (error) {
            console.error('Error updating ABC cart:', error)
        }
    }

    const fetchCart = async () => {
        try {
            const response = await makeAPICall({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.getAbcCart}`,
                params: {
                    request_id: reqId,
                },
            })
            if (response.data?.status_code === 200) {
                const data = response?.data?.data

                const convertToRupees = [
                    'original_total_price',
                    'total_price',
                    'total_discount',
                    'items_subtotal_price',
                    'original_price',
                ]
                convertToRupees.forEach((key) => {
                    if (data[key]) data[key] = +data[key] / 100
                })
                if (data?.customer_id) {
                    fetchCustomer({ customerId: data?.customer_id })
                }
                if (data?.aid) {
                    fetchAddress(data?.aid)
                } else {
                    updateState({
                        shippingAddress: true,
                    })
                }
                setAbcDetailsData(data)
                setSelectedAddress(data?.aid)
                initialLineItemsRef.current = data?.items || []
                setLineItems(data?.items)
                if (data?.discount) {
                    updateState({
                        customDiscount: data?.discount?.total_discount,
                        couponCode: data?.discount?.title,
                        isCouponApplied: true,
                    })
                    if (data?.discount?.automatic_discount) {
                        updateState({
                            isAutomaticDiscount: true,
                        })
                    }
                }
                let payload = {
                    aid: data?.aid,
                    pincode: data?.pincode,
                    shipping: {
                        custom_shipping: true,
                        price: 0,
                    },
                    intent: 'shipping',
                }
                discountShipping(payload)
            } else {
                message.error('Error fetching Abandoned Cart')
            }
        } catch (error) {
            console.error('Error fetching ABC details:', error)
        }
    }

    const fetchPincodeDetails = async (value) => {
        if (value && value?.toString().length === 6) {
            try {
                const res = await makeAPICall({
                    method: 'get',
                    url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.getAddressDetails}?pincode=${value}`,
                })

                if (res.status === 200) {
                    editShipping.setFieldsValue({
                        city: res?.data?.data?.city,
                        state: res?.data?.data?.state,
                    })
                    shippingDetails.setFieldsValue({
                        city: res?.data?.data?.city,
                        state: res?.data?.data?.state,
                    })
                }
            } catch (error) {
                console.error('Error fetching pincode details:', error)
            }
        }
    }
    const fetchMethods = async () => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.methods}`,
                payload: {
                    cart_id: abcDetailsData?.id,
                    request_id: reqId || abcDetailsData?.request_id,
                    aid: selectedAddress,
                    phone: shippingData?.phoneNumber,
                },
            })
            if (response.data?.status_code === 200) {
                const data = response?.data?.data?.data
                if (data?.payment_methods?.cod?.method === true) {
                    updateState({
                        cod: true,
                    })
                } else {
                    message.error(data?.payment_methods?.cod?.reason)
                }
            } else {
                message.error('Error fetching payment methods')
            }
        } catch (error) {
            console.error('Error fetching methods', error)
        }
    }
    const isCartChanged = useMemo(() => {
        const isChanged = lineItems !== initialLineItemsRef.current
        return isChanged
    }, [lineItems])

    useEffect(() => {
        if (id) {
            fetchCart()
        }
        if (customerPhone) {
            fetchCustomer({ phone: customerPhone })
        }
    }, [id, customerPhone])

    useEffect(() => {
        if (customerAddresses.length > 0 && selectedAddress === null) {
            const firstAddress = customerAddresses[0]
            setSelectedAddress(firstAddress.id)
        }
        if (selectedAddress) {
            const selectedShippingAddress = customerAddresses.find((address) => address.id === selectedAddress)
            setShippingData({
                fullName: `${selectedShippingAddress?.firstname} ${selectedShippingAddress?.lastname}`,
                phoneNumber: selectedShippingAddress?.phone,
                email: selectedShippingAddress?.email || '',
                address: selectedShippingAddress?.address,
                city: selectedShippingAddress?.city,
                state: selectedShippingAddress?.state,
                pincode: selectedShippingAddress?.pincode,
            })
        }
    }, [selectedAddress, customerAddresses])

    const columns: ColumnProps<any>[] = [
        { title: 'S No.', dataIndex: 'index', key: 'index', width: 100, align: 'left' },
        {
            title: 'Product',
            dataIndex: 'product',
            key: 'product',
            width: 300,
            render: (text, record) => (
                <div className='flex items-center gap-2'>
                    <img src={record.image} alt={text} className='w-10 h-10 rounded' />
                    <span>{text}</span>
                </div>
            ),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: 150,
            align: 'center',
            render: (text) => <span>₹{text}</span>,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 200,
            align: 'center',
            render: (_, record) => (
                <div className='justify-center mt-2'>
                    <Space>
                        <div className='border-solid border-[0.4px] border-gray-500 rounded-md px-3 py-2 gap-x-3 flex items-center  '>
                            <MinusCircleOutlined
                                onClick={() => {
                                    record.quantity > 1
                                        ? modifyQty(record.variant_id, record.quantity - 1)
                                        : handleDelete(record.variant_id)
                                }}
                                className='cursor-pointer'
                            />
                            {_}
                            <PlusCircleOutlined
                                onClick={() => modifyQty(record.variant_id, record.quantity + 1)}
                                className='cursor-pointer'
                            />
                        </div>
                    </Space>
                    <p className='mt-1 text-xs text-gray-400'>Each @ ₹{(+record.price).toFixed(2)}</p>
                </div>
            ),
        },
        {
            title: 'Total',
            dataIndex: 'total',
            width: 150,
            align: 'center',
            key: 'total',
            render: (text) => <span>₹{text}</span>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Button type='text' danger>
                    <DeleteOutlined
                        onClick={() => {
                            handleDelete(record.variant_id)
                        }}
                    />
                </Button>
            ),
        },
    ]
    const validateName = (name) => {
        if (name.replace(/ /g, '').length >= 3 && /^[a-zA-Z]+(?:[ .][a-zA-Z]*)*$/.test(name)) {
            return Promise.resolve()
        }
        return Promise.reject('Please enter a valid full name with at least 3 letters.')
    }
    const validationRules = {
        fullName: [
            { required: true, message: 'Please enter the full name' },
            { validator: (_, value) => validateName(value) },
        ],
        phoneNumber: [{ required: true, message: 'Please enter a 10-digit number' }],
        email: [{ required: true, message: 'Please enter email' }],
        address: [
            { required: true, message: 'Please enter your full address' },
            { pattern: /^[^?}{+=~`><[\]}!@$%^*_]+$/, message: 'Address cannot contain special characters' },
            {
                validator: (_, value) =>
                    value && value.trim().length >= 12
                        ? Promise.resolve()
                        : Promise.reject(new Error('Address must be at least 12 characters long')),
            },
        ],
        pincode: [{ required: true, message: 'Please enter your PIN Code' }],
        city: [{ required: true, message: 'Please enter your city' }],
        state: [{ required: true, message: 'Please enter your state' }],
    }

    const inputProps = {
        phoneNumber: {
            maxLength: 10,
            parser: (value: string) => value.replace(/\D/g, ''),
            onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => {
                if (!/^\d$/.test(event.key)) {
                    event.preventDefault()
                }
            },
        },
        pincode: {
            maxLength: 6,
            parser: (value: string) => value.replace(/\D/g, ''),
            onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => {
                if (!/^\d$/.test(event.key)) {
                    event.preventDefault()
                }
            },
        },
    }
    const data = lineItems?.map((item, i) => ({
        key: i,
        index: i + 1,
        product: item.title || `${item.product_name}-${item.variant_name}`,
        quantity: item.quantity || 1,
        total: (item.quantity * (item.original_price || item.price)) / 100,
        price: (item.original_price || item.price) / 100,
        image: item.image || item.product_thumbnail_url,
        variant_id: item.variant_id,
        variants: item.variants || [],
    }))
    const handleJsonSubmit = () => {
        try {
            const parsedData = JSON.parse(state.jsonInput)
            const items = Array.isArray(parsedData.items) ? parsedData.items : []

            if (!items.length) {
                message.warning('No items found in the JSON input.')
                return
            }

            setLineItems((prev = []) => {
                const updatedLineItems = [...prev]

                items.forEach((item) => {
                    const existingItemIndex = updatedLineItems.findIndex(
                        (lineItem) => lineItem.variant_id === item.variant_id,
                    )

                    if (existingItemIndex !== -1) {
                        updatedLineItems[existingItemIndex].quantity += item.quantity || 1
                        updatedLineItems[existingItemIndex].total += (item.quantity || 1) * item.original_price
                    } else {
                        updatedLineItems.push({
                            key: updatedLineItems.length,
                            index: updatedLineItems.length + 1,
                            title: item.title || `${item.product_title} - ${item.variant_title}`,
                            quantity: item.quantity || 1,
                            total: (item.quantity || 1) * item.original_price,
                            original_price: item.original_price,
                            image: item.image || item.featured_image?.url,
                            variant_id: item.variant_id,
                            variants: item.variant_options || [],
                            product_id: item.product_id,
                            id: item.id,
                        })
                    }
                })
                return updatedLineItems
            })
            updateState({
                jsonInput: '',
            })
            message.success('JSON data added successfully!')
        } catch (error) {
            message.error('Invalid JSON format. Please check your input.')
        }
    }
    const handleCartSave = async (items) => {
        if (items === initialLineItemsRef.current) {
            message.error('No Change in cart')
            return
        }
        if (id || abcDetailsData?.id) {
            await updateAbcCart(items)
        } else {
            await createAbcCart(items)
        }
        updateState({
            showDiscountShipping: true,
        })
    }

    const modifyQty = (id, qty) => {
        const tempLineItems = lineItems.map((item) =>
            item.variant_id === id
                ? {
                      ...item,
                      quantity: qty,
                      type: 'adjust',
                  }
                : item,
        )
        setLineItems(tempLineItems)
    }
    const handleAdd = (items) => {
        const updatedItems = items.map((item) => {
            if (item.variants && item.variants.length > 0) {
                return {
                    ...item,
                    price: item.price * 100,
                    quantity: item.quantity ?? 1,
                    product_name: item.product_name,
                    product_thumbnail_url: item.product_thumbnail_url,
                    variants: item.variants.map((variant) => ({
                        ...variant,
                        variant_name: variant.variant_name,
                        variant_id: variant.variant_id,
                    })),
                }
            } else {
                return {
                    ...item,
                    price: item.price * 100,
                    quantity: item.quantity ?? 1,
                }
            }
        })
        setLineItems((prevItems) => [...prevItems, ...updatedItems])
    }
    const handleRemove = (removedItem) => {
        setLineItems((prevItems) => prevItems.filter((item) => item.id !== removedItem.id))
    }
    const handleDelete = (id) => {
        const tempLineItems = [...lineItems].filter((item) => item.variant_id !== id)
        setLineItems([...tempLineItems])
    }

    const grandTotal = () => {
        const originalPrice = +abcDetailsData?.original_total_price || 0
        let shipping = +abcDetailsData?.shipping?.price || state.customShipping || 0
        let discount = state.customDiscount || 0
        return Math.max(originalPrice + shipping - discount, 0)
    }

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60)
        const secs = seconds % 60
        return `${minutes}:${secs.toString().padStart(2, '0')}`
    }
    useEffect(() => {
        if (state.paymentModal && countdown > 0) {
            const interval = setInterval(() => {
                setCountdown((prev) => (prev > 0 ? prev - 1 : 0))
            }, 1000)
            return () => clearInterval(interval)
        }

        if (countdown === 0 && state.paymentModal) {
            updateState({ paymentModal: false, isModalOpen: true })
            message.error('Payment Link Expired')

            if (intervalRef.current) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
            }
        }
    }, [state.paymentModal, countdown])
    useEffect(() => {
        updateBreadcrumbs((prev) =>
            prev[prev?.length - 1]?.text === String(id)
                ? prev
                : [
                      prev[0],

                      {
                          key: 'abc',
                          href: '/checkout/abandoned-cart',
                          text: 'Abandoned Cart',
                      },
                      ...(id
                          ? [
                                {
                                    key: 'abc-details',
                                    href: `/checkout/abandoned-cart/${id}`,
                                    text: String(id),
                                },
                                {
                                    key: 'edit',
                                    href: `/checkout/abandoned-cart/edit/${id}`,
                                    text: 'Edit',
                                },
                            ]
                          : [
                                {
                                    key: 'create',
                                    href: `/checkout/abandoned-cart/${id}`,
                                    text: 'Create-Cart',
                                },
                            ]),
                  ],
        )
    }, [])

    return (
        <div className='w-full'>
            <Row gutter={24}>
                <Col className='w-full mb-4'>
                    <Card size='small'>
                        <div className='flex justify-between items-center gap-4 '>
                            <div>
                                <p className='text-lg font-medium'>
                                    Abandoned Cart {abcDetailsData?.id ? `#${abcDetailsData.id}` : ''}
                                </p>
                            </div>
                            <Button
                                onClick={() => {
                                    if (lineItems.length === 0) {
                                        message.error('Cart is empty. Unable to Place Order.')
                                        return
                                    }
                                    if (selectedAddress) {
                                        updateState({
                                            isModalOpen: true,
                                        })
                                    } else {
                                        message.error('Shipping Address is required')
                                        return
                                    }
                                    fetchMethods()
                                }}
                            >
                                Place Order
                            </Button>
                        </div>
                    </Card>
                </Col>
                <Col span={18}>
                    <Card size='small' className='mb-4'>
                        <p className='text-base font-medium'>Add Products</p>
                        <div className='flex items-center gap-2'>
                            <Radio.Group value={searchType} onChange={(e) => setSearchType(e.target.value)}>
                                <Radio value='products'>By Search</Radio>
                                <Radio value='json'>By JSON</Radio>
                            </Radio.Group>

                            {searchType === 'products' && (
                                <div className=' ml-auto'>
                                    <ProductSearch
                                        items={{ collections: [], products: lineItems }}
                                        searchType={searchType}
                                        hideSearch={false}
                                        onAdd={handleAdd}
                                        onRemove={handleRemove}
                                        children={''}
                                    />
                                </div>
                            )}
                            {searchType === 'json' && (
                                <div className='ml-auto'>
                                    <div className='flex items-center gap-4'>
                                        <Tooltip title='Add the complete cart.js object to add your products.'>
                                            <InfoCircleOutlined className='text-gray-500' />
                                        </Tooltip>
                                        <TextArea
                                            rows={2}
                                            placeholder='Add JSON'
                                            className='w-full'
                                            value={state.jsonInput}
                                            onChange={(e) => updateState({ jsonInput: e.target.value })}
                                        ></TextArea>
                                        <Button onClick={handleJsonSubmit}>Submit Data</Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Card>

                    <Card size='small' className='mb-4'>
                        <div className='flex justify-between items-center gap-4 '>
                            <p className='text-base font-medium mb-4'>Checkout Details</p>
                            {lineItems?.length ? (
                                <div>
                                    <Button
                                        className='ml-2'
                                        type='primary'
                                        disabled={!isCartChanged}
                                        onClick={() => handleCartSave(lineItems)}
                                    >
                                        Save Cart
                                    </Button>
                                </div>
                            ) : null}
                        </div>
                        {lineItems?.length ? <Table columns={columns} dataSource={data} pagination={false} /> : null}

                        <div className='mt-4'>
                            {state.customDiscount > 0 && (
                                <div className='flex justify-between items-center gap-4'>
                                    <p>Discount Applied:</p>
                                    <p>{formatAmount(+(state.customDiscount || 0), 2) || 0}</p>
                                </div>
                            )}
                            {(abcDetailsData?.shipping?.price > 0 || state.customShipping > 0) && (
                                <div className='flex justify-between items-center gap-4'>
                                    <p>Shipping:</p>
                                    <p> ₹{(abcDetailsData?.shipping?.price || state.customShipping || 0).toFixed(2)}</p>
                                </div>
                            )}
                            <div className='flex justify-between items-center gap-4'>
                                <p>Total:</p>
                                <p> ₹{Number(abcDetailsData?.original_total_price || 0).toFixed(2)}</p>
                            </div>
                            <Divider style={{ margin: '4px 0' }} />
                            <div className='flex justify-between items-center gap-4'>
                                <p className='font-semibold'>To be payed by customer:</p>
                                <p> ₹{(grandTotal() || 0).toFixed(2)}</p>
                            </div>
                        </div>
                    </Card>

                    {(customerPhone || state.shippingAddress) && (
                        <Card size='small'>
                            <div className='flex justify-between items-center mb-2'>
                                <p className='text-base font-medium'>Customer’s Shipping Details</p>
                                <div>
                                    <Button onClick={() => updateState({ newAddress: true })}>Add New Address</Button>
                                </div>
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 w-full'>
                                {customerAddresses?.map((address) => (
                                    <Card
                                        key={address.id}
                                        size='small'
                                        className='w-full'
                                        onClick={() => setSelectedAddress(address.id)}
                                    >
                                        <div className='flex items-center space-x-2'>
                                            <Radio.Group
                                                onChange={(e) => {
                                                    const selectedId = e.target.value
                                                    setSelectedAddress(selectedId)
                                                }}
                                                value={selectedAddress}
                                            >
                                                <Radio value={address.id} />
                                            </Radio.Group>
                                            <div>
                                                <span className='text-gray-500 font-medium'>
                                                    {address.firstname + ' ' + address.lastname}
                                                </span>
                                                <p className='text-sm text-gray-500'>{address.phone}</p>
                                                <p className='text-sm text-gray-500'>{address.address}</p>
                                            </div>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        </Card>
                    )}
                </Col>
                <Col span={6}>
                    <Card size='small' className='mb-4'>
                        <p className='text-base font-medium mb-4'>Customer Details</p>
                        <p>{customerDetail.fullName || ''}</p>
                        <p>
                            {' '}
                            {customerDetail?.phoneNumber || customerPhone
                                ? `+91${customerDetail?.phoneNumber || customerPhone}`
                                : null}
                        </p>

                        <p>{customerDetail.email || ''}</p>
                    </Card>
                    <Card size='small' className='mb-4'>
                        <div className='flex justify-between items-center'>
                            <div>
                                <p className='text-base font-medium mb-4 mt-1'>Shipping Address</p>
                                <p>{shippingData?.fullName}</p>
                                <p>{shippingData?.phoneNumber ? `+91 ${shippingData.phoneNumber}` : null}</p>
                                <p>
                                    {shippingData?.address ||
                                    shippingData?.city ||
                                    shippingData?.pincode ||
                                    shippingData.state
                                        ? [
                                              shippingData?.address,
                                              shippingData?.city,
                                              shippingData?.state,
                                              shippingData?.pincode,
                                          ]
                                              .filter(Boolean)
                                              .join(', ')
                                        : null}
                                </p>
                            </div>
                            {selectedAddress ? (
                                <div className='self-start'>
                                    <Button
                                        type='link'
                                        onClick={() => {
                                            updateState({ isEdit: true })
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </div>
                            ) : null}
                        </div>
                    </Card>
                    {selectedAddress > 0 && (
                        <div>
                            {(!customerPhone || state.showDiscountShipping) && (
                                <>
                                    <Card size='small' className='mb-4'>
                                        <p className='text-base font-medium mb-4'>Discount Price / Coupon Code</p>

                                        {state.isCouponApplied && state.couponCode && !state.isAutomaticDiscount ? (
                                            <div className='flex items-center gap-2 mb-4'>
                                                <Input value={state.couponCode} disabled />
                                                <Button
                                                    type='text'
                                                    danger
                                                    onClick={() => {
                                                        discountShipping({
                                                            intent: 'discount',
                                                            coupon_code: '',
                                                            offer_type: state.discountAmount
                                                                ? 'agent_discount'
                                                                : 'store_offer',
                                                        })
                                                        updateState({
                                                            isCouponApplied: false,
                                                            couponCode: '',
                                                            customDiscount: 0,
                                                        })
                                                    }}
                                                >
                                                    <DeleteOutlined />
                                                </Button>
                                            </div>
                                        ) : (
                                            <div>
                                                <Input
                                                    placeholder='Enter Discount Amount'
                                                    value={state.discountAmount ? String(state.discountAmount) : ''}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/[^0-9.]/g, '')
                                                        updateState({
                                                            discountAmount: value,
                                                        })
                                                    }}
                                                    prefix='₹'
                                                />
                                                <Divider style={{ fontWeight: 'normal' }}>OR</Divider>
                                                <Input
                                                    placeholder='Enter Coupon Code'
                                                    value={state.couponCode || ''}
                                                    disabled={state.isAutomaticDiscount}
                                                    onChange={(e) =>
                                                        updateState({
                                                            couponCode: e.target.value,
                                                            isCouponApplied: false,
                                                        })
                                                    }
                                                />
                                                <Button
                                                    className='mt-4 w-full'
                                                    onClick={() => {
                                                        if (
                                                            state.discountAmount &&
                                                            state.couponCode &&
                                                            !state.isAutomaticDiscount
                                                        ) {
                                                            message.error(
                                                                'You can only apply either a discount amount or a coupon code, not both.',
                                                            )
                                                            return
                                                        }
                                                        if (
                                                            state.discountAmount !== null &&
                                                            (Number(state.discountAmount) === 0 ||
                                                                Number(state.discountAmount) >
                                                                    Number(abcDetailsData?.original_total_price))
                                                        ) {
                                                            message.error(
                                                                'Discount value cannot be 0 or more than total price.',
                                                            )
                                                            return
                                                        }

                                                        if (state.discountAmount) {
                                                            discountShipping({
                                                                discount: {
                                                                    custom_discount: true,
                                                                    amount: Number(state.discountAmount),
                                                                },
                                                                coupon_code: 'agent_discount',
                                                                offer_type: 'agent_discount',
                                                                intent: 'discount',
                                                            })
                                                        } else {
                                                            discountShipping({
                                                                coupon_code: state.couponCode,
                                                                intent: 'discount',
                                                                offer_type: 'store_offer',
                                                            })
                                                        }
                                                        updateState({
                                                            isCouponApplied: true,
                                                        })
                                                    }}
                                                >
                                                    Apply
                                                </Button>
                                            </div>
                                        )}
                                    </Card>
                                    <Card size='small'>
                                        <p className='text-base font-medium mb-4'>Shipping Price</p>
                                        <p>Add Custom Price</p>
                                        <div className='flex justify-between items-center gap-4 mt-2'>
                                            <Input
                                                placeholder='Enter Shipping'
                                                prefix='₹'
                                                value={state.shippingCode ? String(state.shippingCode) : ''}
                                                onChange={(e) => {
                                                    const value = e.target.value.replace(/[^0-9.]/g, '')
                                                    updateState({ shippingCode: value })
                                                }}
                                            />
                                            <Button
                                                onClick={() => {
                                                    let payload = {}
                                                    if (state.isCouponApplied) {
                                                        payload = {
                                                            coupon_code: state.couponCode,
                                                            discount: {
                                                                custom_discount: true,
                                                                amount: Number(state.discountAmount),
                                                            },
                                                            shipping: {
                                                                custom_shipping: true,
                                                                price: Number(state.shippingCode),
                                                            },
                                                            intent: 'shipping',
                                                        }
                                                    } else {
                                                        payload = {
                                                            shipping: {
                                                                custom_shipping: true,
                                                                price: Number(state.shippingCode),
                                                            },
                                                            intent: 'shipping',
                                                        }
                                                    }
                                                    discountShipping(payload)
                                                }}
                                            >
                                                Apply
                                            </Button>
                                        </div>
                                    </Card>
                                </>
                            )}
                        </div>
                    )}
                </Col>
            </Row>

            <Drawer
                title='Shipping Details'
                width={480}
                open={state.isEdit}
                onClose={() => updateState({ isEdit: false })}
                footer={
                    <div className='flex justify-end gap-2'>
                        <Button onClick={() => updateState({ isEdit: false })}>Discard</Button>
                        <Button
                            type='primary'
                            onClick={async () => {
                                try {
                                    const values = await editShipping.validateFields()
                                    const newAddress = {
                                        fullName: values.fullName,
                                        phone: values.phoneNumber,
                                        email: values.email,
                                        address: values.address,
                                        pincode: values.pincode,
                                        city: values.city,
                                        state: values.state,
                                        note: values.note,
                                    }
                                    if (state.newCustomer) {
                                        updateCustomerAddress(newAddress, true, state.customerId)
                                    } else {
                                        updateCustomerAddress(newAddress, false, state.customerId)
                                    }
                                    updateState({
                                        updatedShippingData: {},
                                        isEdit: false,
                                    })
                                } catch (error) {
                                    console.error('Validation failed:', error)
                                }
                            }}
                        >
                            Save
                        </Button>
                    </div>
                }
            >
                <Form
                    form={editShipping}
                    layout='vertical'
                    requiredMark='optional'
                    onValuesChange={(values) => {
                        updateState({
                            updatedShippingData: { ...state.updatedShippingData, ...values },
                        })
                    }}
                    initialValues={{
                        pincode: shippingData?.pincode,
                        state: shippingData?.state,
                        city: shippingData?.city,
                        address: shippingData?.address,
                        fullName: shippingData?.fullName,
                        phoneNumber: shippingData?.phoneNumber || customerPhone,
                        email: shippingData?.email,
                    }}
                >
                    <p className='italic mb-2 font-light'>All fields are Mandatory.</p>
                    <div className='grid grid-cols-3 gap-4'>
                        <Form.Item label='PIN Code' name='pincode' rules={validationRules.pincode}>
                            <InputNumber
                                className='w-full'
                                {...inputProps.pincode}
                                onChange={(value: number) => {
                                    fetchPincodeDetails(value)
                                }}
                            />
                        </Form.Item>
                        <Form.Item label='State' name='state' rules={validationRules.state}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item label='City' name='city' rules={validationRules.city}>
                            <Input />
                        </Form.Item>
                    </div>

                    <div className='w-full'>
                        <Form.Item label='Full Address' name='address' rules={validationRules.address}>
                            <Input maxLength={255} />
                        </Form.Item>
                    </div>

                    <div className='grid grid-cols-2 gap-4'>
                        <Form.Item label='Full Name' name='fullName' rules={validationRules.fullName}>
                            <Input maxLength={20} />
                        </Form.Item>
                        <Form.Item label='Phone Number' name='phoneNumber' rules={validationRules.phoneNumber}>
                            <InputNumber disabled className='w-full' {...inputProps.phoneNumber} />
                        </Form.Item>
                    </div>

                    <div className='w-full'>
                        <Form.Item label='Email ID' name='email' rules={validationRules.email}>
                            <Input />
                        </Form.Item>
                    </div>
                </Form>
            </Drawer>
            <Modal
                title={state.paymentFailed ? null : 'Place Order'}
                width={350}
                open={state.paymentFailed || state.isModalOpen}
                onCancel={() => {
                    updateState({
                        [state.paymentFailed ? 'paymentFailed' : 'isModalOpen']: false,
                    })
                    clearCapturePaymentInterval()
                }}
                footer={null}
                centered
            >
                {state.paymentFailed ? (
                    <div className='text-center'>
                        <h2 className='text-red-500 font-bold text-lg'>Payment Failed!</h2>
                        <p className='text-gray-600 mt-2'>
                            Looks like there was a problem with the transaction. Please ask the user to try again.
                        </p>
                    </div>
                ) : (
                    <Button block onClick={() => createOrder('cod')} disabled={!state.cod}>
                        {grandTotal() === 0 ? 'Place Order' : 'Place COD Order'}
                    </Button>
                )}

                <Divider>OR</Divider>

                <p>Send payment link on</p>
                <Input
                    maxLength={10}
                    placeholder='Enter'
                    defaultValue={shippingData.phoneNumber}
                    onChange={(e) => setPaymentNumber(e.target.value)}
                />

                <div className='flex justify-between gap-1 mt-2'>
                    <Button
                        block
                        disabled
                        onClick={() => {
                            updateState({
                                paymentFailed: false,
                                isModalOpen: false,
                                paymentMode: 'whatsapp',
                                paymentModal: true,
                            })

                            createOrder('whatsapp')
                        }}
                    >
                        WhatsApp
                    </Button>
                    <Button
                        block
                        disabled={grandTotal() === 0}
                        onClick={() => {
                            updateState({
                                paymentFailed: false,
                                isModalOpen: false,
                                paymentMode: 'sms',
                                paymentModal: false,
                            })

                            createOrder('sms')
                        }}
                    >
                        Via SMS
                    </Button>
                </div>

                <Divider>OR</Divider>
                <Button
                    block
                    className='mt-2 mb-2'
                    disabled={grandTotal() === 0}
                    onClick={() => {
                        updateState({
                            paymentFailed: false,
                            isModalOpen: false,
                            paymentMode: 'payment-link',
                            paymentModal: false,
                        })
                        createOrder('payment-link')
                    }}
                >
                    Get Payment Link
                </Button>
            </Modal>

            <Modal
                title={
                    state.paymentMode === 'payment-link'
                        ? 'Payment Link'
                        : state.paymentMode === 'whatsapp'
                        ? 'WhatsApp'
                        : 'SMS'
                }
                width={'350px'}
                open={state.paymentModal}
                onCancel={() => {
                    updateState({
                        paymentModal: false,
                    })
                    clearCapturePaymentInterval()
                    setCountdown(1200)
                }}
                footer={null}
            >
                {state.paymentMode === 'payment-link' ? (
                    <p>
                        Copy the below payment link to pay the amount <strong>₹{grandTotal()}</strong>.
                    </p>
                ) : (
                    <p>
                        Customer must receive a payment link notification on{' '}
                        {state.paymentMode === 'whatsapp' ? 'WhatsApp' : 'SMS'} and ask them to pay{' '}
                        <strong>₹{grandTotal()}</strong> using that payment link.
                    </p>
                )}

                <p className='text-yellow-600 flex items-center gap-1'>
                    <ClockCircleOutlined /> {formatTime(countdown)} Left
                </p>

                <Input
                    placeholder='Payment Link'
                    value={state.paymentLink}
                    addonAfter={
                        <CopyOutlined
                            onClick={() => {
                                navigator.clipboard.writeText(state.paymentLink)
                                message.success('Link copied!')
                            }}
                            className='cursor-pointer text-gray-500 hover:text-black'
                        />
                    }
                />
            </Modal>
            <Modal
                onOk={async () => {
                    try {
                        const values = await shippingDetails.validateFields()
                        let fullName = values.fullName?.trim()
                        const nameParts = fullName.split(/\s+/)

                        let firstname = nameParts.slice(0, -1).join(' ') || nameParts[0]
                        let lastname = nameParts.length > 1 ? nameParts[nameParts.length - 1] : '.'

                        const newAddress = {
                            firstname,
                            lastname,
                            phone: values.phoneNumber,
                            email: values.email,
                            address: values.address,
                            pincode: values.pincode,
                            city: values.city,
                            state: values.state,
                            note: values.note,
                        }
                        if (!(customerDetail?.fullName && customerDetail?.email)) {
                            createCustomer(newAddress)
                            updateState({
                                newCustomer: true,
                                newAddress: false,
                            })
                        } else {
                            createAddress(newAddress, state.customerId)
                            updateState({
                                newAddress: false,
                            })
                        }
                    } catch (error) {
                        console.error('Validation failed:', error)
                    }
                }}
                okText='Add Address'
                cancelText='Discard'
                title='Add New Address'
                open={state.newAddress}
                onCancel={() => updateState({ newAddress: false })}
            >
                <Form
                    form={shippingDetails}
                    layout='vertical'
                    className='mt-2'
                    requiredMark='optional'
                    initialValues={{
                        fullName: customerDetail?.fullName || '',
                        phoneNumber: customerDetail?.phoneNumber || customerPhone || '',
                        email: customerDetail?.email || '',
                    }}
                    onValuesChange={(values) => {
                        updateState({
                            updatedShippingData: (prev) => ({ ...prev, ...values }),
                        })
                    }}
                >
                    <div className='grid grid-cols-3 gap-4'>
                        <Form.Item name='fullName' label='Full Name' rules={validationRules.fullName}>
                            <Input placeholder='Enter full name' />
                        </Form.Item>
                        <Form.Item name='phoneNumber' label='Phone Number' rules={validationRules.phoneNumber}>
                            <Input
                                placeholder='Phone Number'
                                {...inputProps.phoneNumber}
                                disabled={state.customerPhoneNotFound}
                            />
                        </Form.Item>
                        <Form.Item name='email' label='Email ID' rules={validationRules.email}>
                            <Input placeholder='Enter email ID' />
                        </Form.Item>
                    </div>
                    <div className='w-full'>
                        <Form.Item name='address' label='Shipping Address' rules={validationRules.address}>
                            <Input placeholder='Enter shipping address' maxLength={255} />
                        </Form.Item>
                    </div>
                    <div className='grid grid-cols-3 gap-4'>
                        <Form.Item name='pincode' label='Pincode' rules={validationRules.pincode}>
                            <InputNumber
                                className='w-full'
                                placeholder='Enter pincode'
                                {...inputProps.pincode}
                                onChange={(value: number) => {
                                    fetchPincodeDetails(value)
                                }}
                            />
                        </Form.Item>
                        <Form.Item name='city' label='City' rules={validationRules.city}>
                            <Input placeholder='Enter city' />
                        </Form.Item>
                        <Form.Item name='state' label='State' rules={validationRules.state}>
                            <Input placeholder='Select state' disabled />
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
            <Modal open={state.paymentSuccess} footer={null} centered closable={false}>
                <div className='text-center'>
                    <h2 className='text-green-600 font-bold text-lg'>Great Job!</h2>
                    <p className='text-gray-600 mt-2'>
                        Payment has been received, and the customer’s order has been placed successfully.
                    </p>
                    <p>Order Name: {state.orderName}</p>
                    <Button
                        className='mt-4 w-full'
                        onClick={() => {
                            updateState({ paymentSuccess: false })
                            navigateToUrl('/checkout/abandoned-cart')
                        }}
                    >
                        Go to Dashboard
                    </Button>
                </div>
            </Modal>
        </div>
    )
}

export default editAbcCart
