import { RecommendedOffer } from '@library/utilities/interface'
import {  Drawer, Row, Col, Switch, Select, Button } from 'gokwik-ui-kit'
import { useState } from 'react'

interface Props {
    offer: Partial<RecommendedOffer>
    open: boolean
    onCancel: () => void
    onSave: (offer: RecommendedOffer) => void
    discounts: any
}
const OneClickOffer = ({ offer, open, onCancel, onSave, discounts }: Props) => {      
    const [inputs, setInputs] = useState({
      is_active: offer?.is_active ?? null,
      auto_apply: offer?.auto_apply ?? null,
      type: offer?.type ?? null,
      code: offer?.code ?? null,
    })    
    return (
        <Drawer title={'Recommended offer'} open={open} onClose={onCancel}>
        <Row gutter={[16, 16]}>
             <Col span={8}>
                    <div className='flex items-center gap-x-2'>
                        <span>Status</span>
                        <Switch
                            checked={inputs.is_active}
                            onChange={(value) => setInputs((prev) => ({ ...prev, is_active: value }))}
                        />
                    </div>
                </Col>
                <Col span={10}>
                    <div className='flex items-center gap-x-2'>
                        <span>Auto Apply</span>
                        <Switch
                            checked={inputs.auto_apply}
                            onChange={(value) => setInputs((prev) => ({ ...prev, auto_apply: value }))}
                        />
                    </div>
                </Col>
                  <Col span={24}>
                    <Select
                        className='w-full'
                        label='Type*'
                        placeholder='Please select'
                        value={inputs.type}
                        onChange={(value) => setInputs((prev) => ({ ...prev, type: value }))}
                        options={[
                            { label: 'Exact Code', value: 'exact_discount' },
                            { label: 'Min Discount', value: 'min_discount' },
                            { label: 'Max Discount', value: 'max_discount' },
                        ]}
                    />
                </Col>
                {inputs.type === 'exact_discount' && 
                  <Col span={24}>                   
                      <Select
                        className='w-full'
                        label='Select Discount Code*'
                        placeholder='Please select discount'
                        value={inputs.code}
                        onChange={(value) => setInputs((prev) => ({ ...prev, code: value }))}
                        options={discounts}
                    />
                </Col>
              }
                <Col span={12} className='mt-2'>
                    <Button
                        className='w-full'
                        variant='primary'
                        onClick={() => {                           
                                 onSave({ ...inputs })                         
                        }}
                    >
                        Save
                    </Button>
                </Col>
                <Col span={12} className='mt-2'>
                    <Button
                        className='w-full'
                        onClick={() => {
                            onCancel()
                        }}
                    >
                        Cancel
                    </Button>
                </Col>
        </Row>
        </Drawer>
    )
}
export default OneClickOffer;