import { useEffect, useState } from 'react'
import {
    Form,
    Input,
    Button,
    Select,
    Row,
    Col,
    Alert,
    Divider,
    InputNumber,
    DeleteOutlined,
    message,
} from 'gokwik-ui-kit'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import {
    OperatorObjects,
    ShippingRuleActionOptions,
    ShippingRuleConditionOptions,
    ShippingRuleSubConditions,
    ShippingConditionInputTypesAndOptions,
    IndianProvinceCodes,
    ShippingMethodSortOperators,
    ShippingMethodHideOperators,
    ShippingMethodSortValues,
    UserTypes,
    CountryCodes,
} from './ruleConstant'
import { ICommonEvents } from '@library/utilities/helpers/eventsHelper'

const ShippingRuleForm = (props: { setShowModal, ruleInfo, userAction, fetchConfig, setIsConfigSaved, events: ICommonEvents }) => {
    const { setShowModal, ruleInfo, userAction, fetchConfig, setIsConfigSaved, events } = props;
    const { add: addConfigEvents, edit: editConfigEvents, discardSave: discardSaveConfigEvent } = events.configsUpsert;
    const [form] = Form.useForm()
    const [totalConditions, setTotalConditions] = useState(0)
    const [addDescription, setAddDescription] = useState(ruleInfo?.description?.length > 0)
    const [action, setAction] = useState({})
    const maxAllowedConditions = 3
    let subConditionsCount = 0

    const ShippingRuleArrayInputs = ['COLLECTION_ID', 'DESTINATION_PINCODE', 'PRODUCT_TAG', 'CUSTOMER_EMAIL', 'CUSTOMER_PHONE']
    const DiscountBasedFields = ['DISCOUNT_PERCENTAGE','CART_CONTAINS_DISCOUNT'];
    
    const preProcessRuleInfo = (ruleInfo) => {
        const { subConditions, actions, ...rest } = ruleInfo

        let transformedSubConditions = subConditions?.map((item, index) => {
            if (!item.subConditions) {
                subConditionsCount = subConditionsCount + 1
                if (ShippingRuleArrayInputs.includes(item?.field)) {
                    return {
                        subConditions: [
                            {
                                field: item?.field,
                                operator: item?.operator,
                                value: item?.value?.join(',')?.trim(),
                            },
                        ],
                    }
                } else return { subConditions: [item] }
            } else {
                subConditionsCount = subConditionsCount + item?.subConditions?.length
                const modifiedSubcondition = item?.subConditions?.map((subItem, index) => {
                    if (ShippingRuleArrayInputs?.includes(subItem?.field)) {
                        return {
                            field: subItem?.field,
                            operator: subItem?.operator,
                            value: subItem?.value?.join(',')?.trim(),
                        }
                    } else return subItem
                })

                return {
                    subConditions: modifiedSubcondition,
                }
            }
        })
        let transformedAction = actions
        if (actions?.field == 'RENAME') {
            transformedAction = {
                field: actions.field,
                operator: actions.operator || '',
                value: actions.value.map((item) => {
                    const key = Object.keys(item)[0]
                    return { oldName: key, newName: item[key] }
                }),
            }
        } else if (actions?.field == 'REORDER') {
            transformedAction = {
                field: actions.field,
                operator: actions.operator || '',
                value: actions.value.map((item) => {
                    const key = Object.keys(item)[0]
                    return { method: key, index: item[key] }
                }),
            }
        }
        else if (actions?.field == 'SORT' || actions?.field == 'HIDE') {
            transformedAction = {
                field: actions.field + ':' + actions.operator,
                operator: actions.operator,
                value: actions.value
            }
        }
        return {
            ...rest,
            subConditions: transformedSubConditions,
            actions: transformedAction
        }
    }

    const initialValues = () => {
        if (ruleInfo) {
            return preProcessRuleInfo(ruleInfo)
        } else {
            subConditionsCount = subConditionsCount + 1
            return {
                subConditions: [
                    {
                        subConditions: [{}],
                    },
                ],
                actions: {
                    field: '',
                    operator: '',
                    value: [undefined],
                },
            }
        }
    }

    useEffect(() => {
        setTotalConditions(subConditionsCount)
    }, [subConditionsCount])

    function fireEvent(newConfig: any[], apiStatus: string, errMessage: string) {
        if (apiStatus === 'success') {
            if (userAction?.name === 'add') {
                addConfigEvents.fireSuccessEvent({
                    previousConfig: ruleInfo,
                    currentConfig: newConfig
                })
            } else {
                editConfigEvents.fireSuccessEvent({
                    previousConfig: ruleInfo,
                    currentConfig: newConfig
                });
            }
        } else {
            if (userAction?.name === 'add') {
                addConfigEvents.fireFailureEvent({
                    previousConfig: ruleInfo,
                    currentConfig: newConfig,
                    errorMessage: errMessage,
                    apiStatus
                })
            } else {
                editConfigEvents.fireFailureEvent({
                    previousConfig: ruleInfo,
                    currentConfig: newConfig,
                    errorMessage: errMessage,
                    apiStatus
                });
            }
        }
    }

    const saveRuleChanges = async (values) => {
        const customShippingConditions = [
            {
                ...values,
                enabled: values.enabled || true,
                description: addDescription ? values.description : '',
            },
        ]
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.shopifyShippingAndPaymentRule,
                payload: { customShippingConditions, shippingCustomization: true },
            })
            if (response.data.status_code === 200) {
                setIsConfigSaved(true)
                message.success(`shipping rule ${userAction?.name === 'add' ? 'added' : 'updated'} successfully`)
                fetchConfig()
                fireEvent(customShippingConditions[0], 'success', '');
            } else {
                fireEvent(customShippingConditions[0], 'failure', 'Shipping Customisations API Failed with API Code ' + response.data.status_code);
            }
        } catch (error) {
            fireEvent(customShippingConditions[0], 'failure', error?.message || 'Failed to save Shipping Customisations');
            console.error('Error:', error)
            message.error(`Failed to ${userAction?.name === 'add' ? 'add' : 'update'} shipping rule`)
        }
    }

    const processFormData = (formData) => {
        if (ruleInfo?.id) {
            formData.id = ruleInfo.id
        }

        const { subConditions, ...rest } = formData

        let transformedSubConditions = subConditions?.map((item) => {
            item?.subConditions?.forEach((element) => {
                if (ShippingRuleArrayInputs?.includes(element?.field)) {
                    element.value = element?.value
                        ?.split(',')
                        ?.map((item) => item.trim())
                        ?.filter((item) => item != '')
                }
            })
            if (item?.subConditions?.length === 1) {
                return item.subConditions[0]
            } else {
                return item
            }
        })
        let actionObject = {
            ...formData?.actions
        }
        if (formData?.actions?.field == 'RENAME') {
            actionObject.value = formData?.actions?.value?.map((item) => {
                return { [item.oldName]: item.newName }
            })
        } else if (formData?.actions?.field == 'REORDER') {
            actionObject.value = formData?.actions?.value?.map((item) => {
                return { [item.method]: item.index }
            })
        } else if (formData?.actions?.field.includes('HIDE') || formData?.actions?.field.includes('SORT')) {
            const tempVar = formData?.actions?.field.split(':');
            actionObject.field = tempVar[0]
            actionObject.operator = tempVar[1]
        }

        formData.subConditions = transformedSubConditions
        formData.actions = actionObject
    }

    const onFinish = (values) => {
        const formData = {
            ...values,
        }
        processFormData(formData)

        const trimmedData = trimValues(formData)
        saveRuleChanges(trimmedData)
        form.resetFields()
        setShowModal(false)
    }

    const onDiscard = () => {
        form.resetFields()
        setShowModal(false)
        discardSaveConfigEvent();
    }

    const trimValues = (obj) => {
        if (typeof obj === 'string') {
            return obj.trim()
        } else if (Array.isArray(obj)) {
            return obj.map(trimValues)
        } else if (typeof obj === 'object' && obj !== null) {
            const trimmedObj = {}
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const trimmedKey = key.trim()
                    trimmedObj[trimmedKey] = trimValues(obj[key])
                }
            }
            return trimmedObj
        }
        return obj
    }

    const eligibleOperators = (condition) => {
        let options = []
        ShippingRuleSubConditions[condition]?.forEach((value, _) => {
            if (OperatorObjects[value]) {
                options.push(OperatorObjects[value])
            }
        })
        return options
    }

    const eligibleActionsOperators = (condition) => {
        let options = []
        ShippingRuleSubConditions[condition]?.forEach((value, _) => {
            if (OperatorObjects[value]) {
                options.push(OperatorObjects[value])
            }
        })
        return options
    }

    const getInputTypeAndOptions = (condition: string) => {
        return ShippingConditionInputTypesAndOptions[condition] || { InputType: 'String', placeHolder: 'Enter value' }
    }

    const handleAddSubcondition = () => {
        setTotalConditions(totalConditions + 1)
    }

    const handleRemoveSubcondition = () => {
        setTotalConditions(totalConditions - 1)
    }

    return (
        <Form form={form} onFinish={onFinish} layout='vertical' initialValues={initialValues()}>
            <Divider orientation='left' />
            <Form.Item
                name='name'
                label={<span style={{ fontWeight: 'bold' }}>Shipping Rule Name</span>}
                rules={[
                    {
                        required: true,
                        message: 'Please enter the shipping rule name',
                    },
                ]}
            >
                <Input showCount maxLength={64} disabled={userAction?.name === 'view'} />
            </Form.Item>

            {userAction?.name !== 'view' && (
                <Button
                    type='link'
                    onClick={() => setAddDescription((prev) => !prev)}
                    className='p-0 -mt-4 items-left border-none focus:outline-none'
                >
                    <span className='hover:underline'>{addDescription ? 'Remove' : 'Add a'} description</span>
                </Button>
            )}

            {addDescription && (
                <Form.Item name='description' label={<span style={{ fontWeight: 'bold' }}>Rule Description</span>}>
                    <Input showCount maxLength={100} disabled={userAction?.name === 'view'} />
                </Form.Item>
            )}

            <Divider orientation='left' />

            <div>
                <Form.List name='subConditions'>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                                <div key={field.key}>
                                    {index > 0 && (
                                        <Row key={field.key} justify={'space-between'}>
                                            <Col span={21}>
                                                <p
                                                    style={{
                                                        fontWeight: 'bold',
                                                        marginLeft: 350,
                                                    }}
                                                >
                                                    Or
                                                </p>
                                            </Col>
                                            <Col span={3}>
                                                <Form.Item>
                                                    <Button
                                                        type='text'
                                                        style={{ color: 'red' }}
                                                        onClick={() => {
                                                            const item = form.getFieldValue([
                                                                'subConditions',
                                                                field.name,
                                                            ])
                                                            if (item?.subConditions) {
                                                                setTotalConditions(
                                                                    totalConditions - item.subConditions.length,
                                                                )
                                                            }
                                                            remove(field.name)
                                                        }}
                                                        disabled={userAction?.name === 'view'}
                                                    >
                                                        Remove
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )}
                                    <Form.Item>
                                        <Form.List name={[field.name, 'subConditions']}>
                                            {(subFields, subOpt) => (
                                                <>
                                                    {subFields.map((subField, subIndex) => (
                                                        <Row key={subField.key} gutter={[8, 8]} justify='space-between'>
                                                            {subIndex == 0 && (
                                                                <Col span={4}>
                                                                    <span style={{ fontWeight: 'bold' }}>WHEN</span>
                                                                </Col>
                                                            )}
                                                            {subIndex > 0 && (
                                                                <Col span={4}>
                                                                    <span style={{ fontWeight: 'bold' }}>AND</span>
                                                                </Col>
                                                            )}
                                                            
                                                            <Col span={10}>
                                                                <Form.Item
                                                                    {...subField}
                                                                    name={[subField.name, 'field']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please select a condition',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        placeholder='Select Condition'
                                                                        style={{ width: '100%' }}
                                                                        options={ShippingRuleConditionOptions}
                                                                        onChange={() => {
                                                                            form.setFieldValue(
                                                                                [
                                                                                    'subConditions',
                                                                                    field.name,
                                                                                    'subConditions',
                                                                                    subField.name,
                                                                                    'operator',
                                                                                ],
                                                                                undefined,
                                                                            )
                                                                            form.setFieldValue(
                                                                                [
                                                                                    'subConditions',
                                                                                    field.name,
                                                                                    'subConditions',
                                                                                    subField.name,
                                                                                    'value',
                                                                                ],
                                                                                undefined,
                                                                            )
                                                                        }}
                                                                        disabled={userAction?.name === 'view'}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={10}>
                                                                <Form.Item noStyle shouldUpdate>
                                                                    {() =>
                                                                        eligibleOperators(
                                                                            form.getFieldValue([
                                                                                'subConditions',
                                                                                field.name,
                                                                                'subConditions',
                                                                                subField.name,
                                                                                'field',
                                                                            ]),
                                                                        ) && (
                                                                            <Form.Item
                                                                                {...subField}
                                                                                name={[subField.name, 'operator']}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message:
                                                                                            'Please select an operator',
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <Select
                                                                                    placeholder='Select Operator'
                                                                                    style={{ width: '100%' }}
                                                                                    options={eligibleOperators(
                                                                                        form.getFieldValue([
                                                                                            'subConditions',
                                                                                            field.name,
                                                                                            'subConditions',
                                                                                            subField.name,
                                                                                            'field',
                                                                                        ]),
                                                                                    )}
                                                                                    disabled={
                                                                                        userAction?.name === 'view'
                                                                                    }
                                                                                />
                                                                            </Form.Item>
                                                                        )
                                                                    }
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={18} offset={4} style={{ marginTop: -10 }}>
                                                                <Form.Item noStyle shouldUpdate>
                                                                    {() => {
                                                                        const { InputType, placeHolder } =
                                                                            getInputTypeAndOptions(
                                                                                form.getFieldValue([
                                                                                    'subConditions',
                                                                                    field.name,
                                                                                    'subConditions',
                                                                                    subField.name,
                                                                                    'field',
                                                                                ]),
                                                                            )
                                                                        return (
                                                                            <Form.Item
                                                                                {...subField}
                                                                                name={[subField.name, 'value']}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: 'Please enter a value',
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                {InputType == 'Integer[]' && (
                                                                                    <Input
                                                                                        placeholder={placeHolder}
                                                                                        style={{ width: '100%' }}
                                                                                        disabled={
                                                                                            userAction?.name === 'view'
                                                                                        }
                                                                                    />
                                                                                )}
                                                                                {InputType == 'String[]' && (
                                                                                    <Input
                                                                                        placeholder={placeHolder}
                                                                                        style={{ width: '100%' }}
                                                                                        disabled={
                                                                                            userAction?.name === 'view'
                                                                                        }
                                                                                    />
                                                                                )}
                                                                                {InputType == 'StateCode[]' && (
                                                                                    <Select
                                                                                        mode='multiple'
                                                                                        placeholder={placeHolder}
                                                                                        options={IndianProvinceCodes}
                                                                                        style={{ width: '100%' }}
                                                                                        disabled={
                                                                                            userAction?.name === 'view'
                                                                                        }
                                                                                    />
                                                                                )}
                                                                                {InputType == 'Country[]' && (
                                                                                    <Select
                                                                                        mode='multiple'
                                                                                        placeholder={placeHolder}
                                                                                        options={CountryCodes}
                                                                                        style={{ width: '100%' }}
                                                                                        disabled={
                                                                                            userAction?.name === 'view'
                                                                                        }
                                                                                    />
                                                                                )}
                                                                                {InputType == 'UserTypes' && (
                                                                                    <Select
                                                                                        placeholder={placeHolder}
                                                                                        options={UserTypes}
                                                                                        style={{ width: '100%' }}
                                                                                        disabled={
                                                                                            userAction?.name === 'view'
                                                                                        }
                                                                                    />
                                                                                )}
                                                                                {InputType == 'Integer' && (
                                                                                    <InputNumber
                                                                                        placeholder={placeHolder}
                                                                                        style={{ width: '100%' }}
                                                                                        min={1}
                                                                                        max={Number.MAX_SAFE_INTEGER}
                                                                                        disabled={
                                                                                            userAction?.name === 'view'
                                                                                        }
                                                                                    />
                                                                                )}
                                                                                {InputType == 'Boolean' && (
                                                                                    <Select
                                                                                        placeholder={placeHolder}
                                                                                        style={{ width: '100%' }}
                                                                                        disabled={
                                                                                            userAction?.name === 'view'
                                                                                        }
                                                                                    >
                                                                                        <Select.Option value={true}>
                                                                                            True
                                                                                        </Select.Option>
                                                                                        <Select.Option value={false}>
                                                                                            False
                                                                                        </Select.Option>
                                                                                    </Select>
                                                                                )}
                                                                                {InputType == 'String' && (
                                                                                    <Input
                                                                                        placeholder={placeHolder}
                                                                                        style={{ width: '100%' }}
                                                                                        disabled={
                                                                                            userAction?.name === 'view'
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </Form.Item>
                                                                        )
                                                                    }}
                                                                </Form.Item>
                                                            </Col>

                                                            <Col span={2} style={{ marginTop: -10 }}>
                                                                <Form.Item>
                                                                    <Button
                                                                        onClick={() => {
                                                                            subOpt.remove(subField.name)
                                                                            handleRemoveSubcondition()
                                                                        }}
                                                                        danger
                                                                        disabled={
                                                                            subIndex === 0 ||
                                                                            userAction?.name === 'view'
                                                                        }
                                                                    >
                                                                        <DeleteOutlined />
                                                                    </Button>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    <Row justify='end'>
                                                        <Col>
                                                            <Button
                                                                style={{ marginRight: '8px' }}
                                                                onClick={() => {
                                                                    subOpt.add()
                                                                    handleAddSubcondition()
                                                                }}
                                                                disabled={
                                                                    totalConditions >= maxAllowedConditions ||
                                                                    userAction?.name === 'view'
                                                                }
                                                                block
                                                            >
                                                                + Add AND Condition
                                                            </Button>
                                                        </Col>
                                                        <Col offset={1}>
                                                            <Button
                                                                onClick={() => {
                                                                    add({ subConditions: [{}] })
                                                                    handleAddSubcondition()
                                                                }}
                                                                disabled={
                                                                    totalConditions >= maxAllowedConditions ||
                                                                    userAction.name === 'view'
                                                                }
                                                                block
                                                            >
                                                                + Add OR Condition
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </Form.List>
                                    </Form.Item>
                                </div>
                            ))}

                            {userAction.name != 'view' && totalConditions >= maxAllowedConditions && (
                                <Alert
                                    message="You're limited to combining a maximum of 3 conditions using 'OR' and 'AND'"
                                    type='info'
                                    showIcon
                                    style={{ textAlign: 'center' }}
                                />
                            )}
                        </>
                    )}
                </Form.List>
            </div>
            <Divider orientation='left' />

            <div>
                <Row gutter={16} justify='space-between'>
                    <Col span={4}>
                        <span style={{ fontWeight: 'bold' }}>THEN</span>
                    </Col>
                    <Col span={20}>
                        <Form.Item
                            name={['actions', 'field']}
                            rules={[{ required: true, message: 'Please select an action' }]}
                        >
                            <Select
                                placeholder='Select Action'
                                options={ShippingRuleActionOptions}
                                onChange={(value)=> {
                                    setAction(value)
                                    form.setFieldValue(['actions', 'value'], [''])
                                }}
                                disabled={userAction?.name === 'view'}
                            />
                            
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item 
                    noStyle 
                    shouldUpdate
                >
                    {form.getFieldValue(['actions', 'field']) === 'RENAME' && (
                        <Form.List
                            name={['actions', 'value']}
                            rules={[
                                {
                                    validator: async (_, condition) => {
                                        if (!condition || condition?.length < 1) {
                                            return Promise.reject(new Error('At least one Shipping method is required.'))
                                        }
                                    },
                                },
                            ]}
                        >
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }, index) => (
                                        <Row key={key} gutter={8} justify='space-between'>
                                            <Col span={9} offset={4}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'oldName']}
                                                    rules={[
                                                        { required: true, message: 'Please add a shipping method' },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                const allValues = getFieldValue(['actions', 'value']) || []
                                                                const oldNames = allValues.map((item) => item.oldName)
                                                                const count = oldNames.reduce((acc, val) => {
                                                                    return val === value ? acc + 1 : acc
                                                                }, 0)
                                                                if (count > 1) {
                                                                    return Promise.reject(
                                                                        new Error('Shipping method already added'),
                                                                    )
                                                                } else return Promise.resolve()
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder='Enter exact shipping method name'
                                                        style={{ width: '100%' }}
                                                        disabled={userAction?.name === 'view'}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={9}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'newName']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please add new name',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder='New Name' disabled={userAction?.name === 'view'} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <Form.Item>
                                                    <Button
                                                        onClick={() => remove(name)}
                                                        danger
                                                        disabled={index === 0 || userAction?.name === 'view'}
                                                    >
                                                        <DeleteOutlined />
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    ))}

                                    <Row justify='end'>
                                        <Col>
                                            <Form.Item>
                                                <Button
                                                    onClick={() => {
                                                        add()
                                                    }}
                                                    disabled={userAction?.name === 'view'}
                                                >
                                                    + Add Shipping Method
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {form.getFieldError(['actions', 'value']).map((error) => (
                                        <Alert message={error} type='error' showIcon style={{ textAlign: 'center' }} />
                                    ))}
                                </>
                            )}
                        </Form.List>
                    )}
                    {form.getFieldValue(['actions', 'field']) === 'REORDER' && (
                        <Form.List
                            name={['actions', 'value']}
                            rules={[
                                {
                                    validator: async (_, condition) => {
                                        if (!condition || condition.length < 1) {
                                            return Promise.reject(new Error('At least one shipping method is required.'))
                                        }
                                    },
                                },
                            ]}
                        >
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Row key={field.key} gutter={8} justify='space-between'>
                                            <Col span={9} offset={4}>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'method']}
                                                    rules={[
                                                        { required: true, message: 'Please add a shipping method' },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                const allValues = getFieldValue(['actions', 'value']) || []
                                                                const prevMethods = allValues.map((item) => item.method)
                                                                const count = prevMethods.reduce((acc, val) => {
                                                                    return val === value ? acc + 1 : acc
                                                                }, 0)
                                                                if (count > 1) {
                                                                    return Promise.reject(
                                                                        new Error('Shipping method already added'),
                                                                    )
                                                                } else return Promise.resolve()
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder='Enter exact shipping method name'
                                                        style={{ width: '100%' }}
                                                        disabled={userAction?.name === 'view'}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={9}>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'index']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please add desired position',
                                                        },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                const allValues = getFieldValue(['actions', 'value']) || []
                                                                const prevIndex = allValues.map((item) => item.index)
                                                                const count = prevIndex.reduce((acc, val) => {
                                                                    return val === value ? acc + 1 : acc
                                                                }, 0)
                                                                if (count > 1) {
                                                                    return Promise.reject(
                                                                        new Error('Shipping method already added'),
                                                                    )
                                                                } else return Promise.resolve()
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <InputNumber
                                                        min={1}
                                                        max={10}
                                                        placeholder='Desired Position'
                                                        style={{ width: '100%' }}
                                                        disabled={userAction?.name === 'view'}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <Form.Item>
                                                    <Button
                                                        onClick={() => remove(field.name)}
                                                        danger
                                                        disabled={index === 0 || userAction?.name === 'view'}
                                                    >
                                                        <DeleteOutlined />
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    ))}

                                    <Row justify='end'>
                                        <Col>
                                            <Form.Item>
                                                <Button
                                                    onClick={() => {
                                                        add()
                                                    }}
                                                    disabled={userAction?.name === 'view'}
                                                >
                                                    + Add Shipping Method
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {form.getFieldError(['actions', 'value']).map((error) => (
                                        <Alert message={error} type='info' showIcon style={{ textAlign: 'center' }} />
                                    ))}
                                </>
                            )}
                        </Form.List>
                    )}
                    {ShippingMethodHideOperators.includes(form.getFieldValue(['actions', 'field'])) && (
                        <Form.List
                            name={['actions', 'value']}
                            rules={[
                                {
                                    validator: async (_, condition) => {
                                        if (!condition || condition.length < 1) {
                                            return Promise.reject(new Error('At least one shipping method is required.'))
                                        }
                                    },
                                },
                            ]}
                        >
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Row key={field.key} gutter={8} justify='space-between'>
                                            <Col span={18} offset={4}>
                                                <Form.Item
                                                    {...field}
                                                    rules={[
                                                        { required: true, message: 'Please add a shipping method' },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                const allValues = getFieldValue(['actions', 'value']) || []
                                                                const count = allValues.reduce((acc, val) => {
                                                                    return val === value ? acc + 1 : acc
                                                                }, 0)
                                                                if (count > 1) {
                                                                    return Promise.reject(
                                                                        new Error('shipping method already added'),
                                                                    )
                                                                } else return Promise.resolve()
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder='Enter the exact shipping method name'
                                                        style={{ width: '100%' }}
                                                        disabled={userAction?.name === 'view'}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <Form.Item>
                                                    <Button
                                                        onClick={() => remove(field.name)}
                                                        danger
                                                        disabled={index === 0 || userAction?.name === 'view'}
                                                    >
                                                        <DeleteOutlined />
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    ))}

                                    <Row justify='end'>
                                        <Col>
                                            <Form.Item>
                                                <Button
                                                    onClick={() => {
                                                        add()
                                                    }}
                                                    disabled={userAction?.name === 'view'}
                                                >
                                                    + Add Shipping Method
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    {form.getFieldError(['actions', 'value']).map((error) => (
                                        <Alert message={error} type='info' showIcon style={{ textAlign: 'center' }} />
                                    ))}
                                </>
                            )}
                        </Form.List>
                    )}
                    {ShippingMethodSortOperators.includes(form.getFieldValue(['actions', 'field'])) && (
                        <Row gutter={16} justify='end'>
                            <Col span={20}>
                                <Form.Item
                                    name={['actions', 'value']}
                                    rules={[
                                        {
                                            validator: async (_, condition) => {
                                                if (!condition || condition.length < 1 || !condition[0]) {
                                                    return Promise.reject(new Error('Please select sort order.'))
                                                }
                                            },
                                        },
                                    ]}                        >
                                    <Select
                                        placeholder='Select Value'
                                        options={ShippingMethodSortValues}
                                        disabled={userAction?.name === 'view'}
                                    />
                                    
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                </Form.Item>
            </div>
            <Divider orientation='left' />
            <Form.Item>
                <Row justify='end'>
                    <Col>
                        <Button
                            onClick={onDiscard}
                            style={{ marginRight: '8px' }}
                            disabled={userAction?.name === 'view'}
                        >
                            Discard
                        </Button>
                    </Col>
                    <Col>
                        <Button type='primary' htmlType='submit' disabled={userAction?.name === 'view'}>
                            Save Changes
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    )
}
export default ShippingRuleForm
