import ColorPicketComponent from '@library/components/colorpicker'
import RichTextEditor from '@library/components/richtexteditor'
import { Button, Card, Col, Divider, Form, Input, message, Row, Switch } from 'gokwik-ui-kit'
import React, { useEffect, useState } from 'react'
import BookDownload from '@library/images/book-download.svg'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { deepEqualAndDiff } from '@gokwik/utilities'
import Sparkle from '@library/images/PixelIcons/sparkle.svg'
const initialValues = {
    'primary-button-text': 'Continue',
    custom_config: {
        city_editable: true,
    },
}
const address = ({ onChange }) => {
    const [form] = Form.useForm()
    const customConfig = Form.useWatch('custom_config', form)
    const merchantConfig = Form.useWatch('merchant_config', form)
    const { config, loading, refetch, saveConfig } = useMerchantConfigApi({
        useCache: true,
    })
    const [visibility, setVisibility] = useState({
        isBannerTextVisible: true,
        isBannerVisible: false,
        isInputCheck: false,
    })
    const [ischanged, setIsChanged] = useState(false)
    const [addressData, setAddressData] = useState<any>({
        banner_content: '',
        background_color: '#000000',
        text_color: '#FFFFFF',
        primary_button_text: '',
        primary_color: '#000000',
        primary_text_color: '#FFFFFF',
    })

    const addressFields = {
        addressSettings: {
            title: 'Address Settings',
            fields: [
                {
                    type: 'switch',
                    name: ['merchant_config', 'enable_extra_addresses'],
                    label: 'Show all addresses on prefill step',
                    description:
                        'Show all previously used addresses on checkout to the customers. By default we show 2 most recently and frequently used addresses to the customers.',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['merchant_config', 'show_address_count'],
                    label: 'Enter number of addresses to Show',
                    hidden: !merchantConfig?.enable_extra_addresses,
                    span: 12,
                    divider: false,
                },
                {
                    type: 'switch',
                    name: ['custom_config', 'bypass_email'],

                    label: (
                        <>
                            Make Email Id Optional
                            <span className='ml-2 px-2 py-1 bg-red-100 text-red-500 text-xs font-medium rounded'>
                                Not Recommended
                            </span>
                        </>
                    ),
                    description: (
                        <>
                            Make email ID field non-mandatory on address step. Pls note: We pass
                            {'{phone_number}@dummy.com'} on Shopify to create customers.
                        </>
                    ),
                    span: 24,
                },
                {
                    type: 'switch',
                    name: ['custom_config', 'enable_recipient_phone'],
                    label: 'Capture recipient phone number on address',
                    description:
                        'Enabling this feature allows customers to add a recipient phone number which gets passed as part of shipping address on orders.',
                    span: 24,
                },
                {
                    type: 'switch',
                    name: ['custom_config', 'show_auto_suggestion'],
                    label: (
                        <>
                            Show Auto-Suggestion of addresses (Powered by Gokwik)
                            <span className='ml-2 px-2 py-1 bg-blue-100 text-blue-500 text-xs font-medium rounded'>
                                <img src={Sparkle} style={{ width: '15px', height: '15px' }}></img>
                                New
                            </span>
                        </>
                    ),
                    description: (
                        <>
                            Enabling this feature will show auto-suggested addresses for new address addition on
                            checkout. This is powered by GoKwik's self developed address suggestion model.
                        </>
                    ),
                    span: 24,
                },
                {
                    type: 'switch',
                    name: ['custom_config', 'additional_address'],
                    label: 'Add an additional Address Field',
                    description:
                        'Enabling this feature allows customers to add additional address details like landmark,etc in an optional field on Checkout.',
                    span: 24,
                },
                {
                    type: 'switch',
                    name: ['custom_config', 'city_editable'],
                    label: 'Keep City Editable',
                    description:
                        'Disabling this config will ensure that users can’t edit City Name after entering PIN Code.',
                    span: 24,
                },
            ],
        },
    }

    const handleVisibilityChange = (key: string, value: boolean) => {
        setVisibility((prev) => ({ ...prev, [key]: value }))
        setAddressData((prev) => ({ ...prev, [key]: value }))
        setIsChanged(true)
    }

    const handleRichTextChange = (field: string) => (content: JSON) => {
        form.setFieldValue(field, content || {})
        setAddressData((prev) => ({ ...prev, [field]: content }))
        setVisibility((prev) => ({ ...prev, isBannerTextVisible: false }))
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldValue('primary_button_text', e.target.value)
        setVisibility((prev) => ({ ...prev, isInputCheck: true }))
        setAddressData((prev: any) => ({ ...prev, primary_button_text: e.target.value }))
        setIsChanged(true)
    }

    const handleFormSubmit = async () => {
        try {
            await form.validateFields()
            const { custom_config = {}, merchant_config = {}, ...rest } = form.getFieldsValue()
            const configPayload = deepEqualAndDiff(config, {
                custom_config: {
                    ...config.custom_config,
                    ...custom_config,
                    address_data: { ...addressData },
                },
                ...merchant_config,
            })
            if (configPayload.isEqual) {
                setIsChanged(false)
                return
            }
            saveConfig(configPayload.diff)
            setIsChanged(false)
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        if (config && config.custom_config.address_data) {
            setAddressData((prev) => ({ ...prev, ...(config.custom_config.address_data || addressData) }))
            form.setFieldValue('background_color', config.custom_config.address_data.background_color)
            form.setFieldValue('text_color', config.custom_config.address_data.text_color)
            form.setFieldValue('primary_color', config.custom_config.address_data.primary_color)
            form.setFieldValue('primary_text_color', config.custom_config.address_data.primary_text_color)
            config?.custom_config?.address_data?.isBannerVisible
                ? setVisibility((prev) => ({ ...prev, isBannerTextVisible: true, isBannerVisible: true }))
                : setVisibility((prev) => ({ ...prev, isBannerTextVisible: false, isBannerVisible: false }))
            config?.custom_config?.address_data?.isInputCheck
                ? setVisibility((prev) => ({ ...prev, isInputCheck: true }))
                : setVisibility((prev) => ({ ...prev, isInputCheck: false }))
        }
        form.setFieldsValue({
            custom_config: {
                ...config?.custom_config,
            },
            merchant_config: {
                ...config,
            },
        })
    }, [config])
    useEffect(() => {
        onChange(addressData)
    }, [addressData])

    const renderFormItem = (
        label: string,
        subLabel: string,
        switchKey: string,
        editorKey: string,
        repsonseText: string,
        text: boolean,
        colorPicker: boolean,
        editorChangeHandler: (content: JSON) => void,
        description,
    ) => (
        <Form.Item name={editorKey} label={<span className='text-base  font-medium'>{label}</span>} className='m-0'>
            <div className='flex w-full flex-row justify-between gap-x-2 pb-2'>
                <span className='font-md text-sm font-normal'>{subLabel}</span>
                <Switch checked={text} onChange={(checked) => handleVisibilityChange(switchKey, checked)} />
            </div>
            <p className='text-sm text-gray-500'>{description}</p>
            {visibility[switchKey] && (
                <span>
                    <RichTextEditor
                        onChange={(content) => {
                            editorChangeHandler(content)
                            setIsChanged(true)
                        }}
                        selectedValue={repsonseText}
                    />
                    {colorPicker && (
                        <>
                            <Divider />
                            <ColorPicketComponent
                                label={''}
                                primary={'background'}
                                secondary={'text'}
                                defaultValues={{
                                    firstColor: addressData.background_color,
                                    secondColor: addressData.text_color,
                                }}
                            />
                        </>
                    )}
                </span>
            )}
        </Form.Item>
    )

    return (
        <Form
            form={form}
            layout='vertical'
            initialValues={initialValues}
            className='gap-5 flex flex-col'
            onValuesChange={(value) => {
                const allowedKeys = [
                    'banner_content',
                    'background_color',
                    'text_color',
                    'primary_button_text',
                    'primary_color',
                    'primary_text_color',
                ]

                const filteredValues = Object.keys(value)
                    .filter((key) => allowedKeys.includes(key))
                    .reduce((acc, key) => {
                        acc[key] = value[key]
                        return acc
                    }, {} as Record<string, any>)
                setAddressData({ ...addressData, ...filteredValues })
                if (value) {
                    setIsChanged(true)
                }
            }}
        >
            <div className='flex justify-between align-center'>
                <div className='m-0 align-center'>
                    <span className='text-lg font-semibold'>Address</span>
                    <p className='text-gray-500 gap-0'>
                        Control address step related UI and features on checkout - add banners, control address fields
                        and more.
                    </p>
                </div>
                <div className='m-0'>
                    <Button type='primary' htmlType='submit' disabled={!ischanged} onClick={handleFormSubmit}>
                        Save Changes
                    </Button>
                </div>
            </div>
            <Card size='small'>
                {renderFormItem(
                    'Banner',
                    'Display Banner',
                    'isBannerVisible',
                    'banner_content',
                    addressData.banner_content?.actualText || 'Enter Banner Text',
                    visibility.isBannerVisible,
                    true,
                    handleRichTextChange('banner_content'),
                    'Enable this to display an announcement banner to the customers on address step of checkout',
                )}
            </Card>
            <Card size='small'>
                <Form.Item
                    name='primary_button_text'
                    label={<span className='text-base font-medium'>Primary Button</span>}
                    className='m-0 p-0'
                >
                    <div className='flex w-full flex-row justify-between gap-x-2 pb-2'>
                        <span className='font-md text-sm font-normal'>CTA Text</span>
                        <Switch
                            checked={visibility.isInputCheck}
                            onChange={(checked) => {
                                handleVisibilityChange('isInputCheck', checked)
                            }}
                        />
                    </div>
                    <p className='text-sm text-gray-500 pb-2'>
                        Control the text to shown on main CTA button on address step.
                    </p>
                    {visibility.isInputCheck && (
                        <>
                            <Input
                                name='primary_button_text'
                                count={{ show: true, max: 15 }}
                                maxLength={15}
                                onChange={handleInputChange}
                                defaultValue={addressData.primary_button_text}
                            />
                            <Divider />
                            <ColorPicketComponent
                                label={''}
                                primary={'primary'}
                                secondary={'primary_text'}
                                customName={'text'}
                                address={true}
                                defaultValues={{
                                    firstColor: addressData.primary_color || '#000000',
                                    secondColor: addressData.primary_text_color,
                                }}
                            />
                        </>
                    )}
                </Form.Item>
            </Card>
            <Card size='small'>
                <div className='mb-2'>
                    <span className='text-base font-medium'>{addressFields.addressSettings.title}</span>
                </div>
                <Row gutter={[16, 16]}>
                    {addressFields.addressSettings.fields.map((field, index) =>
                        field.hidden ? null : (
                            <React.Fragment key={index}>
                                {index != 0 && !field.divider && <Divider style={{ margin: '0px 0' }} />}
                                <Col span={field.span || 24}>
                                    <div>
                                        {field.type === 'switch' && (
                                            <div className='flex justify-between items-center w-full'>
                                                <div className='flex-1'>
                                                    <p>{field.label}</p>
                                                    {field.description && (
                                                        <p className='text-gray-500 text-sm mt-1'>
                                                            {field.description}
                                                        </p>
                                                    )}
                                                </div>

                                                <Form.Item name={field.name} valuePropName='checked' noStyle>
                                                    <Switch
                                                        className='mr-2'
                                                        checked={
                                                            form.getFieldValue(field.name) ||
                                                            customConfig?.show_auto_suggestion
                                                        }
                                                        disabled={
                                                            field.name[1] === 'additional_address' &&
                                                            customConfig?.show_auto_suggestion
                                                        }
                                                        onChange={(checked) => {
                                                            form.setFieldsValue({
                                                                custom_config: {
                                                                    ...form.getFieldValue('custom_config'),
                                                                    [field.name[1]]: checked,
                                                                },
                                                            })

                                                            if (field.name[1] === 'show_auto_suggestion' && checked) {
                                                                form.setFieldsValue({
                                                                    custom_config: {
                                                                        ...form.getFieldValue('custom_config'),
                                                                        additional_address: true,
                                                                    },
                                                                })
                                                                message.info(
                                                                    'Additional address field is mandatory with this feature.',
                                                                    3,
                                                                )
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        )}
                                        {field.type === 'input' && (
                                            <div>
                                                <Form.Item
                                                    label={<span className='font-medium'>{field.label}</span>}
                                                    name={field.name}
                                                    normalize={(value) => value?.replace(/\s+/g, ' ').trim()}
                                                    rules={[
                                                        {
                                                            validator: (_, value) => {
                                                                if (!value) {
                                                                    return Promise.reject(
                                                                        new Error('Please enter a number'),
                                                                    )
                                                                }
                                                                return Promise.resolve()
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    {field.description && (
                                                        <p className='text-sm text-gray-500 mt-1'>
                                                            {field.description}
                                                        </p>
                                                    )}
                                                    <Input min={1} className='w-full' style={{ maxWidth: '100%' }} />
                                                </Form.Item>
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </React.Fragment>
                        ),
                    )}
                </Row>
            </Card>
        </Form>
    )
}

export default address
