export const DefaultPaymentMethods = [
    'upi',
    'wallet-airtelmoney',
    'wallet-phonepe',
    'wallet-mobikwik',
    'cc',
    'dc',
    'netbanking'
]

export const PaymentMethodsLabelNameMapping = {
    'cc': 'Credit Cards',
    'dc': 'Debit Cards',
    'upi': 'UPI',
    'netbanking': 'Internet Banking (NetBanking)',
    'credpay': 'Cred UPI (credPay)',
}

export const DiscountTypeMapping = {
    'fixed_amount': 'Fixed Amount',
    'percentage': 'Percentage'
}