import { ThunkAction, configureStore } from '@reduxjs/toolkit'
import { Action, combineReducers } from 'redux'
import filters from './filters'
import user from './user'
import analytics from './analytics'
import shopifyApp from './shopify-app'

const rootReducer = combineReducers({
    filters,
    user,
    analytics,
    shopifyApp,
})

const store = configureStore({
    reducer: rootReducer,
    devTools: true,
})

export default store
export type RootStoreType = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootStoreType, unknown, Action<string>>
