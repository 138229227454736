import { fetchShopifyCollectionsOrProducts } from '@library/utilities/api'
import {
    ShopifyCollectionPlaceholderImage,
    ShopifyProductPlaceholderImage,
} from '@library/utilities/constants/constants'
import { useAppDispatch } from '@library/utilities/hooks'
import { useIntersectionObserver } from '@library/utilities/hooks'

import { SearchedCollection, SearchedProduct, SelectedProduct } from '@library/utilities/interface'

import {
    Checkbox,
    CloseOutlined,
    Input,
    MinusCircleOutlined,
    Modal,
    PlusCircleOutlined,
    SearchOutlined,
    Skeleton,
} from 'gokwik-ui-kit'
import { useEffect, useRef, useState } from 'react'

const ProductSearch = ({
    children,
    searchType = 'products',
    hideSearch = false,
    items,
    onAdd,
    onRemove,
    collateProducts = false,
    showQuantity = false,
}: {
    children: React.ReactNode
    searchType?: 'products' | 'collections'
    hideSearch?: boolean
    items: {
        collections: any
        products: any
    }
    onAdd: (data: any) => void
    onRemove: (data: any) => void
    showQuantity?: boolean
    collateProducts?: boolean
}) => {
    const [loading, setLoading] = useState(false)
    const [itemsToAdd, setItemsToAdd] = useState({})
    const [searchedText, setSearchedText] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [result, setResult] = useState([])
    const [page, setPage] = useState(1)
    const lastElementRef = useRef()
    const dispatch = useAppDispatch()
    const modalSearchRef = useRef(null)
    const [hasMore, setHasMore] = useState(true)
    const lastElementVisible = useIntersectionObserver({
        ref: lastElementRef,
        reset: !loading,
    })

    const parseResponse = (res) => {
        if (searchType === 'products' && collateProducts) {
            const updatedItemsToAdd = {}
            const productsMapped = res.data.data.reduce((acc, curr) => {
                const itemAlreadyAdded = items?.products?.data?.find(
                    (product) => product.product_id === curr.product_id,
                )
                if (itemAlreadyAdded && !updatedItemsToAdd[curr.product_id]) {
                    updatedItemsToAdd[curr.product_id] = { ...itemAlreadyAdded }
                }
                return {
                    ...acc,
                    [curr.product_id]: {
                        ...(!acc[curr.product_id]
                            ? {
                                  product_id: curr.product_id,
                                  product_name: curr.product_name,
                                  product_thumbnail_url: curr.product_thumbnail_url,
                              }
                            : acc[curr.product_id]),
                        variants: acc[curr.product_id]?.variants ? [...acc[curr.product_id].variants, curr] : [curr],
                        minPrice:
                            acc[curr.product_id]?.minPrice < curr.price ? acc[curr.product_id]?.minPrice : curr.price,
                    },
                }
            }, {})
            setItemsToAdd((prev) =>
                Object.entries(updatedItemsToAdd).reduce(
                    (result, [key, val]: any) => ({
                        ...result,
                        [key]: {
                            ...val,
                            totalVariants: productsMapped[key].variants.length,
                        },
                    }),
                    { ...prev },
                ),
            )

            setResult((prev) =>
                searchedText ? Object.values(productsMapped) : [...prev, ...Object.values(productsMapped)],
            )
        } else
            setResult((prev) =>
                searchType === 'products'
                    ? searchedText
                        ? res.data.data
                        : [...prev, ...res.data.data]
                    : [res.data.data.collectionData || res.data.data],
            )
    }

    const fetchData = async (page) => {
        if (searchType === 'collections') return
        setLoading(true)
        const res =
            searchType === 'products'
                ? await fetchShopifyCollectionsOrProducts({
                      searchType,
                      page,
                      pageSize: 25,
                  })
                : await fetchShopifyCollectionsOrProducts({
                      searchType,
                  })
        setLoading(false)
        if (res.success) {
            const data = res.data.data
            if (res.data.data.length) {
                setHasMore(res.data.hasMore)
                parseResponse(res)
            }
        }
    }

    useEffect(() => {
        if (searchedText.length < 3) return
        const delayDebounceFn = setTimeout(async () => {
            setLoading(true)
            const res = await fetchShopifyCollectionsOrProducts(
                searchType === 'collections'
                    ? {
                          searchType,
                          title: searchedText,
                      }
                    : {
                          searchType,
                          title: searchedText,
                      },
            )

            setLoading(false)
            if (res.success) {
                parseResponse(res)
            }
        }, 400)
        return () => clearTimeout(delayDebounceFn)
    }, [searchedText])

    useEffect(() => {
        if (!showModal) {
            setPage(1)
            setHasMore(true)
            setResult([])
        }
    }, [showModal])

    useEffect(() => {
        if (lastElementVisible && !searchedText && hasMore && !loading) {
            setPage((prev) => prev + 1)
            fetchData(page + 1)
        }
    }, [lastElementVisible])

    const onClosingModal = () => {
        setItemsToAdd({})
        setShowModal(false)
        setResult([])
        setSearchedText('')
    }
    const removeItem = (item) => {
        let prodItem = JSON.parse(JSON.stringify(itemsToAdd))
        if (prodItem[item.variant_id].quantity <= 1) {
            prodItem[item.variant_id] = null
        } else {
            prodItem[item.variant_id].quantity--
        }
        setItemsToAdd(() => ({ ...prodItem }))
    }
    const incrementItem = (item) => {
        let prodItem = JSON.parse(JSON.stringify(itemsToAdd))
        prodItem[item.variant_id].quantity++
        setItemsToAdd(() => ({ ...prodItem }))
    }

    const onConfirm = () => {
        if (Object.keys(itemsToAdd).length === 0) return

        const mappedItems = Object.values(itemsToAdd)
            .filter((item) => item)
            .map((item: SearchedProduct | SearchedCollection) => {
                if (searchType === 'products') {
                    const product: any = item
                    return {
                        id: collateProducts ? product.product_id : product.variant_id,
                        product_id: product.product_id,
                        variant_id: product.variant_id,
                        product_name: product.product_name,
                        variant_name: product.variant_name,
                        price: product.price,
                        product_thumbnail_url: product.product_thumbnail_url,
                        quantity: product.quantity,
                        variants: product.variants,
                        totalVariants: product.totalVariants,
                        minPrice: product.minPrice,
                    }
                } else {
                    const collection = item as SearchedCollection
                    return {
                        id: collection.id,
                        title: collection.title,
                        url: collection.image?.url,
                        handle: collection.name,
                    }
                }
            })
        onAdd(Object.values(mappedItems))
        onClosingModal()
    }

    if (hideSearch) {
        return <>{children}</>
    }

    return (
        <>
            <div className='w-full flex items-end justify-between my-2'>
                {children}
                <div>
                    <Input
                        className='w-60'
                        onClick={() => {
                            setShowModal(true)

                            fetchData(page)
                        }}
                        readOnly
                        placeholder={'Search for ' + searchType}
                        suffix={<SearchOutlined />}
                    />
                </div>
            </div>
            <div className='w-full mt-3 flex flex-col gap-y-2'>
                {searchType === 'products'
                    ? items?.products?.data?.map((product) => (
                          <div
                              key={product.product_id}
                              className='w-full flex justify-between p-2 bg-gray-50 border border-solid border-gray-300   rounded-md'
                          >
                              <div className='flex gap-x-2'>
                                  <img
                                      height={40}
                                      width={40}
                                      src={product.product_thumbnail_url || ShopifyProductPlaceholderImage}
                                      alt='product'
                                  />
                                  <div className='flex flex-col gap-y-1'>
                                      <span className='text-sm'>{product.product_name}</span>

                                      <span className='text-sm'>
                                          {collateProducts
                                              ? `${product.variants?.length} variants selected`
                                              : `${product.variant_name || ''} ${
                                                    showQuantity && `Qty: ${product.quantity}`
                                                }`}
                                      </span>
                                  </div>
                              </div>
                              <CloseOutlined className='cursor-pointer text-xl' onClick={() => onRemove(product)} />
                          </div>
                      ))
                    : items?.collections?.data?.map((collection) => (
                          <div
                              key={collection.id}
                              className='w-full flex justify-between p-2 bg-gray-50 border border-solid border-gray-300   rounded-md'
                          >
                              <div className='flex gap-x-2'>
                                  <img
                                      height={40}
                                      width={40}
                                      src={collection.image || ShopifyCollectionPlaceholderImage}
                                      alt='collection'
                                  />
                                  <div className='flex flex-col gap-y-1'>
                                      <span className='text-sm'>{collection.title}</span>
                                  </div>
                              </div>
                              <CloseOutlined className='cursor-pointer text-xl' onClick={() => onRemove(collection)} />
                          </div>
                      ))}
            </div>
            <Modal
                open={showModal}
                width={'40vw'}
                title={'Add ' + searchType}
                okText={'Add'}
                onOk={onConfirm}
                okButtonProps={{ content: 'Add', className: 'px-6' }}
                onCancel={onClosingModal}
                afterOpenChange={(open) => {
                    if (open) {
                        setTimeout(() => {
                            modalSearchRef.current?.focus()
                        }, 1000)
                    }
                }}
            >
                <div className='w-full flex justify-between'>
                    <Input
                        placeholder={'Search for ' + searchType}
                        value={searchedText}
                        onChange={(e) => {
                            if (!e.target.value?.trim()) {
                                setPage(1)

                                fetchData(1)
                            }
                            setSearchedText(e.target.value)
                        }}
                        ref={modalSearchRef}
                        suffix={<SearchOutlined />}
                    />
                </div>
                <div className='w-full mt-3 max-h-[50vh] overflow-y-auto'>
                    {loading && searchedText ? (
                        <Skeleton />
                    ) : !result?.length ? (
                        <div className='py-6 w-full text-center  bg-gray-50 flex flex-col border border-solid border-gray-300 rounded-md'>
                            {searchedText ? (
                                <span>No {searchType} found </span>
                            ) : (
                                <span>
                                    Search by entering{' '}
                                    {searchType === 'collections'
                                        ? 'name of collection'
                                        : 'product ID or first three characters of product name'}
                                </span>
                            )}
                        </div>
                    ) : searchType === 'products' ? (
                        collateProducts ? (
                            <div className='w-full flex flex-col gap-y-2'>
                                {result.map(
                                    (product: any, index) =>
                                        product.variants && (
                                            <div
                                                key={product.product_id}
                                                className='w-full bg-gray-50 flex flex-col gap-y-2 p-2 items-center'
                                            >
                                                <div className='flex w-full gap-x-2'>
                                                    <Checkbox
                                                        checked={
                                                            itemsToAdd[product.product_id]?.variants?.length ===
                                                            product?.variants?.length
                                                        }
                                                        indeterminate={
                                                            itemsToAdd[product.product_id]?.variants?.length &&
                                                            itemsToAdd[product.product_id]?.variants?.length !==
                                                                product?.variants?.length
                                                        }
                                                        onChange={(checked) => {
                                                            setItemsToAdd((prev) => ({
                                                                ...prev,
                                                                [product.product_id]: {
                                                                    ...product,
                                                                    variants: checked.target.checked
                                                                        ? product.variants.map(
                                                                              (variant) => variant.variant_id,
                                                                          )
                                                                        : [],
                                                                    totalVariants: product.variants.length,
                                                                },
                                                            }))
                                                        }}
                                                    />
                                                    <img
                                                        height={40}
                                                        width={40}
                                                        className='rounded'
                                                        src={
                                                            product.variants[0].product_thumbnail_url ||
                                                            ShopifyProductPlaceholderImage
                                                        }
                                                        alt='product'
                                                    />
                                                    <span className='text-sm'>{product.product_name}</span>
                                                </div>
                                                <div className='w-full pl-2 flex  flex-col gap-y-4'>
                                                    {product.variants.map((variant: SearchedProduct) => (
                                                        <div
                                                            key={variant.variant_id}
                                                            className='flex justify-between gap-x-2 items-center'
                                                        >
                                                            <div className='flex gap-x-2 items-center'>
                                                                <Checkbox
                                                                    checked={
                                                                        !!itemsToAdd[
                                                                            product.product_id
                                                                        ]?.variants?.includes(variant.variant_id)
                                                                    }
                                                                    onChange={() => {
                                                                        setItemsToAdd((prev) =>
                                                                            prev[
                                                                                product.product_id
                                                                            ]?.variants?.includes(variant.variant_id)
                                                                                ? {
                                                                                      ...prev,
                                                                                      [product.product_id]: {
                                                                                          ...product,
                                                                                          ...prev[product.product_id],
                                                                                          variants: prev[
                                                                                              product.product_id
                                                                                          ].variants.filter(
                                                                                              (id) =>
                                                                                                  id !==
                                                                                                  variant.variant_id,
                                                                                          ),
                                                                                          totalVariants:
                                                                                              product.variants.length,
                                                                                      },
                                                                                  }
                                                                                : {
                                                                                      ...prev,

                                                                                      [product.product_id]: {
                                                                                          ...product,

                                                                                          ...prev[product.product_id],
                                                                                          variants: [
                                                                                              ...(prev[
                                                                                                  product.product_id
                                                                                              ]?.variants || []),
                                                                                              variant.variant_id,
                                                                                          ],
                                                                                          totalVariants:
                                                                                              product.variants.length,
                                                                                      },
                                                                                  },
                                                                        )
                                                                    }}
                                                                />
                                                                <div className='flex flex-col gap-y-2'>
                                                                    <span className='text-sm'>
                                                                        {variant.variant_name}
                                                                    </span>
                                                                    {+variant.inventory_quantity === 0 ? (
                                                                        <span className='text-sm text-red-500'>
                                                                            Out of stock
                                                                        </span>
                                                                    ) : (
                                                                        <span className='text-sm'>
                                                                            In stock: {variant.inventory_quantity}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <span className='pr-2 font-semibold'>₹{variant.price}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ),
                                )}
                            </div>
                        ) : (
                            <div className='w-full flex flex-col gap-y-2'>
                                {result.map((product: SearchedProduct, index) => (
                                    <div
                                        key={product.variant_id}
                                        className='w-full bg-gray-50 flex justify-between p-2 items-center'
                                    >
                                        <div className='flex gap-x-2'>
                                            <Checkbox
                                                checked={!!itemsToAdd[product.variant_id]}
                                                onChange={() => {
                                                    setItemsToAdd((prev) =>
                                                        prev[product.variant_id]
                                                            ? {
                                                                  ...prev,
                                                                  [product.variant_id]: null,
                                                              }
                                                            : {
                                                                  ...prev,
                                                                  [product.variant_id]: {
                                                                      ...product,
                                                                      quantity: 1,
                                                                  },
                                                              },
                                                    )
                                                }}
                                            />
                                            <img
                                                height={40}
                                                width={40}
                                                className='rounded'
                                                src={product.product_thumbnail_url || ShopifyProductPlaceholderImage}
                                                alt='product'
                                            />
                                            <div className='flex flex-col gap-y-1'>
                                                <span className='text-sm'>{product.product_name}</span>
                                                <span className='text-sm'>{product.variant_name}</span>
                                            </div>

                                            {!!itemsToAdd?.[product.variant_id] && showQuantity && (
                                                <div className='flex gap-x-2 items-center'>
                                                    <span>
                                                        <strong className='font-semibold'>Qty</strong>
                                                    </span>

                                                    <div className='border-solid border-[0.4px] border-gray-500 rounded-md px-3 py-2 gap-x-3 flex items-center  '>
                                                        <MinusCircleOutlined
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                removeItem(product)
                                                            }}
                                                            className='cursor-pointer'
                                                        />

                                                        <span>{itemsToAdd?.[product.variant_id]?.quantity}</span>
                                                        <PlusCircleOutlined
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                incrementItem(product)
                                                            }}
                                                            className='cursor-pointer'
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <span className='pr-2 font-semibold text-lg'>₹{product.price}</span>
                                    </div>
                                ))}
                            </div>
                        )
                    ) : (
                        <div className='w-full flex flex-col gap-y-2'>
                            {result.map((collection: SearchedCollection) => (
                                <div
                                    key={collection.collection_id || collection.id}
                                    className='w-full bg-gray-50 flex justify-between p-2'
                                >
                                    <div className='flex gap-x-2'>
                                        <Checkbox
                                            checked={!!itemsToAdd[collection.id]}
                                            onChange={() => {
                                                setItemsToAdd((prev) =>
                                                    prev[collection.id]
                                                        ? {
                                                              ...prev,
                                                              [collection.id]: null,
                                                          }
                                                        : { ...prev, [collection.id]: collection },
                                                )
                                            }}
                                        />
                                        <img
                                            height={40}
                                            width={40}
                                            className='rounded'
                                            src={collection.image?.url || ShopifyCollectionPlaceholderImage}
                                            alt='collection'
                                        />
                                        <div className='flex flex-col gap-y-1'>
                                            <span className='text-sm'>{collection.name || collection.title}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    <div ref={lastElementRef} />
                </div>
            </Modal>
        </>
    )
}

export default ProductSearch
