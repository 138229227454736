import { fetchShopifyCollectionsOrProducts } from '@library/utilities/api'
import { stateList, utList } from '@library/utilities/constants/constants'
import { debounce } from '@library/utilities/helpers/helper'
import RenderField from '@library/utilities/helpers/renderFormItem'
import type { TaxOverride as OverrideInterface } from '@library/utilities/interface'
import { Button, Col, DeleteOutlined, Form, message, Row, Select, Table } from 'gokwik-ui-kit'
import { useCallback, useEffect, useState } from 'react'

const gstRates = [0, 0.25, 3, 5, 12, 18, 28]

const TaxOverride = ({
    taxOverrides,
    onAdd,
    onRemove,
}: {
    taxOverrides: OverrideInterface[]
    onAdd: (values: OverrideInterface[]) => void
    onRemove: (index: number) => void
}) => {
    const [form] = Form.useForm()
    const overrideType = Form.useWatch('type_of_override', form)
    const taxType = Form.useWatch('tax_type', form)
    const [collections, setCollections] = useState([])

    const checkIfOverrideExists = (values: OverrideInterface) => {
        const allsStatesOrUTs = ['All States', 'All UTs'].includes(values.state)
        const existingOverride = taxOverrides.find(
            (override) =>
                override.type_of_override === values.type_of_override &&
                (values.type_of_override === 'Product' ? override.collectionID === values.collectionID : true) &&
                override.tax_type === values.tax_type &&
                override.value === values.value &&
                (allsStatesOrUTs
                    ? (values.state === 'All States' ? stateList : utList).includes(override.state)
                    : override.state === values.state),
        )
        return existingOverride
    }

    const onFormFinish = (values: OverrideInterface) => {
        const allsStatesOrUTs = ['All States', 'All UTs'].includes(values.state)
        values.value = +values.value
        if (checkIfOverrideExists(values)) {
            message.error('Similar tax override already exists')
            return
        }
        if (allsStatesOrUTs) {
            const allStatesOrUTs = values.state === 'All States' ? stateList : utList
            const overrides = allStatesOrUTs.map((state) => {
                return {
                    ...values,
                    state,
                }
            })
            onAdd(overrides.reverse())
        } else onAdd([values])
        form.resetFields()
    }

    const fetchCollections = useCallback(
        debounce(async (text: string) => {
            text = text.trim()
            if (!text || text.length < 3) {
                return
            }
            const res = await fetchShopifyCollectionsOrProducts({
                searchType: 'collections',
                title: text,
            })
            const collection = res?.data?.data?.collectionData || res?.data?.data
            if (collection?.handle) {
                setCollections((prev) => [
                    ...(prev || []).filter((c) => c.label !== collection.title),
                    {
                        label: collection.title,
                        value: collection.id?.split('Collection/')[1],
                    },
                ])
            }
        }, 500),
        [],
    )

    const fields = [
        {
            type: 'select',
            name: ['type_of_override'],
            label: 'Tax Override For?',
            span: 4,
            inputProps: {
                options: ['Product', 'Shipping'].map((type) => ({ label: type, value: type })),
            },
        },

        {
            type: 'select',
            name: ['collectionID'],
            label: 'Collection',
            span: 4,
            hidden: overrideType === 'Shipping',
            inputProps: {
                placeholder: 'Search Collection by name',
                onSearch: fetchCollections,
                showSearch: true,
                options: collections,
            },
        },
        {
            type: 'select',
            name: ['state'],
            label: 'State',
            span: 4,
            inputProps: {
                placeholder: 'Select State',
                options: ['All States', 'All UTs', ...stateList, ...utList].map((state) => ({
                    label: state,
                    value: state,
                })),
            },
        },
        {
            type: 'select',
            name: ['tax_type'],
            label: 'Tax Type',
            span: 4,
            inputProps: {
                placeholder: 'Select Tax Type',
                options: ['SGST', 'CGST', 'IGST'].map((tax) => ({ label: tax, value: tax })),
            },
        },
        {
            type: 'select',
            name: ['value'],
            label: 'Tax Rate (%)',
            span: 4,
            inputProps: {
                placeholder: 'Select Tax Rate',
                options: !taxType
                    ? null
                    : gstRates.map((rate) => {
                          const multiplier = taxType === 'IGST' ? 1 : 0.5
                          rate = rate * multiplier
                          return { label: `${rate}%`, value: `${rate}` }
                      }),
                filterSort: (optionA: any, optionB: any) => +optionA.value - +optionB.value,
                notFoundContent: 'Please select Tax Type first',
            },
        },
    ]

    return (
        <>
            <Form
                form={form}
                layout='vertical'
                requiredMark={'optional'}
                initialValues={{
                    type_of_override: 'Product',
                    tax_type: null,
                    value: null,
                    collectionId: null,
                    state: null,
                }}
                onFinish={onFormFinish}
            >
                <Row gutter={[16, 16]} align={'bottom'}>
                    {fields
                        .filter((field) => !field.hidden)
                        .map((field, i) => (
                            <Col key={field.label} span={field.span}>
                                <RenderField
                                    field={{
                                        ...field,
                                        rules: [{ required: true, message: `Please input ${field.label}` }],
                                    }}
                                    form={form}
                                />
                            </Col>
                        ))}
                    <Col>
                        <Button
                            onClick={() => {
                                form.submit()
                            }}
                        >
                            Add Override
                        </Button>
                    </Col>
                    <Col span={24}>
                        <Table
                            columns={[
                                {
                                    title: 'Type of Override',
                                    dataIndex: 'type_of_override',
                                    filters: [
                                        {
                                            text: 'Product',
                                            value: 'Product',
                                        },
                                        {
                                            text: 'Shipping',
                                            value: 'Shipping',
                                        },
                                    ],
                                    filterMode: 'menu',
                                    filterSearch: true,
                                    onFilter: (value: string, record: OverrideInterface) =>
                                        record.type_of_override.startsWith(value),
                                },
                                {
                                    title: 'Collection',
                                    dataIndex: 'collectionID',
                                    render: (value) => value || 'N/A',
                                },
                                {
                                    title: 'State',
                                    dataIndex: 'state',
                                    filters: [
                                        ...[...stateList, ...utList]
                                            .sort((a, b) => a.localeCompare(b))
                                            .map((state) => ({ text: state, value: state })),
                                    ],
                                    filterSearch: true,
                                    filterMode: 'menu',
                                    onFilter: (value: string, record: OverrideInterface) =>
                                        record.state.startsWith(value),
                                },
                                {
                                    title: 'Tax Type',
                                    dataIndex: 'tax_type',
                                    filters: [
                                        {
                                            text: 'SGST',
                                            value: 'SGST',
                                        },
                                        {
                                            text: 'CGST',
                                            value: 'CGST',
                                        },
                                        {
                                            text: 'IGST',
                                            value: 'IGST',
                                        },
                                    ],
                                    filterMode: 'menu',
                                    filterSearch: true,
                                    onFilter: (value: string, record: OverrideInterface) =>
                                        record.tax_type.startsWith(value),
                                },
                                {
                                    title: 'Tax Rate (%)',
                                    dataIndex: 'value',
                                    render: (value) => `${value}%`,
                                    filters: [
                                        ...gstRates.reduce((acc, rate) => {
                                            if (rate === 0) return [...acc, { text: `${rate}%`, value: rate }]
                                            return [
                                                ...acc,
                                                { text: `${rate * 0.5}%`, value: rate * 0.5 },
                                                { text: `${rate}%`, value: rate },
                                            ]
                                        }, []),
                                    ],
                                    align: 'right',
                                    filterMode: 'menu',
                                    filterSearch: true,
                                    onFilter: (value: string, record: OverrideInterface) => record.value === +value,
                                },
                                {
                                    title: '',
                                    render: (_, __, index) => (
                                        <DeleteOutlined
                                            className='cursor-pointer text-error-500'
                                            onClick={() => {
                                                onRemove(index)
                                            }}
                                        />
                                    ),
                                },
                            ]}
                            dataSource={taxOverrides?.reverse?.() || []}
                            pagination={{
                                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default TaxOverride
