import { OfferAndDiscount } from '@library/utilities/interface'
import {
    Button,
    Col,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    Switch,
    TextArea,
    message,
    Popconfirm,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'

interface Props {
    discount?: Partial<OfferAndDiscount>
    preHighlightedDiscount?: any
    action: any
    setShowModal: any
    saveDiscount: (discount: Partial<OfferAndDiscount>, action: string) => Promise<void>
}

const DiscountSuggestionForm = ({ discount, action, preHighlightedDiscount, setShowModal, saveDiscount }: Props) => {
    const [form] = Form.useForm()

    const [discountStatus, setDiscountStatus] = useState(true)
    const [additionalDetails, setAdditionalDetails] = useState('')
    const [loading, setLoading] = useState(false)
    const [highlightedDiscount, setHightlightedDiscount] = useState(discount?.highlighted)

    const handleChange = (e) => {
        const lines = e.target.value?.split('\n')
        const formattedLines = lines?.map((line) => {
            if (line.startsWith('• ') && line.length >= 3) return line
            else if (!line.startsWith('• ') && line.length >= 1) return `• ${line}`
        })
        setAdditionalDetails(formattedLines.join('\n'))
    }

    useEffect(() => {
        if (action.name === 'edit' || action.name === 'view') {
            if (discount.additional_details?.bullet_points) {
                let lines = discount?.additional_details?.bullet_points
                if (typeof lines === 'string') {
                    lines = lines?.split('\n')
                }
                const formattedLines = lines?.map((line) => {
                    if (line?.trim()?.length) {
                        return `• ${line.trim()}`
                    }
                })
                setAdditionalDetails(formattedLines?.join('\n'))
            }
            setDiscountStatus(discount?.is_active)
            setHightlightedDiscount(discount?.highlighted || false)
        }
    }, [])

    const initialValues = () => {
        if (action.name === 'edit' || action.name === 'view') {
            if (discount?.max_price === 0 || discount?.max_price === Number.MAX_SAFE_INTEGER) delete discount.max_price
            if (discount?.max_quantity === 0 || discount?.max_quantity === Number.MAX_SAFE_INTEGER)
                delete discount.max_quantity
            return discount
        }
    }

    const validateDiscountData = (discountData) => {
        if (!discountData?.discount_code?.length) {
            return {
                msg: 'Please enter the discount name',
                status: false,
            }
        }
        if (!discountData?.discount_detail?.length) {
            return {
                msg: 'Please enter the description',
                status: false,
            }
        }
        if ((discountData?.max_price ?? Number.MAX_SAFE_INTEGER) <= discountData?.min_price) {
            return {
                msg: 'Max price should be greater than min price',
                status: false,
            }
        }
        if ((discountData?.max_quantity ?? Number.MAX_SAFE_INTEGER) <= discountData?.min_quantity) {
            return {
                msg: 'Max quantity should be greater than min quantity',
                status: false,
            }
        }
        return {
            msg: 'Discount validation success',
            status: true,
        }
    }

    const onFinish = async (values) => {
        setLoading(true)
        try {
            for (const key in values) {
                if (typeof values[key] === 'string') {
                    values[key] = values[key].trim()
                }
            }
            const validation = validateDiscountData(values)
            if (validation.status) {
                values.is_active = discountStatus
                values.highlighted = highlightedDiscount
                values.additional_details = {
                    ...discount?.additional_details,
                    bullet_points: additionalDetails
                        ?.split('\n')
                        ?.map((line) => (line?.startsWith('• ') ? line.slice(2) : line))
                        ?.filter((line) => line?.trim()?.length),
                }
                await saveDiscount({ ...discount, ...values }, action.name)
            } else {
                message.error(validation.msg)
            }
        } finally {
            setLoading(false)
        }
    }

    const onDiscard = () => {
        form.resetFields()
        setShowModal(false)
    }

    return (
        <Form form={form} onFinish={onFinish} layout='vertical' initialValues={initialValues()}>
            <Row gutter={12} justify={'space-between'}>
                <Col span={8}>
                    <Form.Item
                        label='Discount Name'
                        name='discount_code'
                        rules={[{ required: true, message: 'Please enter the discount name' }]}
                    >
                        <Input placeholder='Enter name here' disabled={action?.name === 'view'} />
                    </Form.Item>
                </Col>
                <Col span={16}>
                    <Form.Item
                        label='Description'
                        name='discount_detail'
                        rules={[{ required: true, message: 'Please enter the description' }]}
                    >
                        <Input placeholder='Enter description here' disabled={action?.name === 'view'} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label='Min Price' name='min_price'>
                        <InputNumber
                            type='number'
                            className='w-full'
                            prefix='₹'
                            min={0}
                            max={Number.MAX_SAFE_INTEGER}
                            disabled={action?.name === 'view'}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label='Max Price' name='max_price'>
                        <InputNumber
                            type='number'
                            className='w-full'
                            prefix='₹'
                            min={0}
                            max={Number.MAX_SAFE_INTEGER}
                            disabled={action?.name === 'view'}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label='Min Quantity' name='min_quantity'>
                        <InputNumber
                            type='number'
                            className='w-full'
                            min={0}
                            max={Number.MAX_SAFE_INTEGER}
                            disabled={action?.name === 'view'}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label='Max Quantity' name='max_quantity'>
                        <InputNumber
                            type='number'
                            className='w-full'
                            min={0}
                            max={Number.MAX_SAFE_INTEGER}
                            disabled={action?.name === 'view'}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        label='User Type'
                        name='user_type'
                        rules={[{ required: true, message: 'Please select user type' }]}
                    >
                        <Select placeholder='Select' disabled={action?.name === 'view'}>
                            <Select.Option value={'all'}>All</Select.Option>
                            <Select.Option value={'new'}>New Users Only</Select.Option>
                            <Select.Option value={'existing'}>Repeat Users Only</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        label='Visibility'
                        name='visibility'
                        rules={[{ required: true, message: 'Please select visibility' }]}
                    >
                        <Select placeholder='Select' disabled={action?.name === 'view'}>
                            <Select.Option value={'ALWAYS'}>Always</Select.Option>
                            <Select.Option value={'ONLY_WHEN_ELIGIBLE'}>Only When Eligible</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={12} className='flex items-center'>
                    <Form.Item>
                        <span className='mr-2'>Discount Status </span>
                        <Switch
                            value={discountStatus}
                            onChange={(checked) => setDiscountStatus(checked)}
                            disabled={action?.name === 'view'}
                        />
                    </Form.Item>
                </Col>
                <Col span={12} className='flex items-center justify-end'>
                    <Form.Item>
                        <span className='mr-2'>Highlight Discount </span>
                        {!highlightedDiscount ? (
                            <Popconfirm
                                title={
                                    preHighlightedDiscount?.highlighted
                                        ? `Are you sure you want to highlight this discount? This action will remove the previously highlighted discount: ${preHighlightedDiscount?.discount_code}`
                                        : 'Are you sure you want to highlight this discount?'
                                }
                                okText='Yes'
                                okButtonProps={{
                                    className: 'bg-error-500 hover:!bg-error-500 text-white',
                                }}
                                overlayStyle={{ width: 500 }}
                                cancelText='No'
                                onConfirm={() => setHightlightedDiscount(true)}
                                onCancel={() => setHightlightedDiscount(false)}
                            >
                                <Switch
                                    value={highlightedDiscount}
                                    onChange={(checked) => {
                                        if (!checked) setHightlightedDiscount(false)
                                    }}
                                    disabled={action?.name === 'view'}
                                />
                            </Popconfirm>
                        ) : (
                            <Switch
                                value={highlightedDiscount}
                                onChange={(checked) => {
                                    setHightlightedDiscount(checked)
                                }}
                                disabled={action?.name === 'view'}
                            />
                        )}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label='Terms and Conditions Details'>
                        <TextArea
                            rows={4}
                            value={additionalDetails}
                            onChange={(e) => handleChange(e)}
                            maxLength={300}
                            showCount
                            placeholder='Enter items, each on a new line'
                        />
                    </Form.Item>
                </Col>
            </Row>
            {action?.name !== 'view' && (
                <Form.Item className='mt-5'>
                    <Row justify='end'>
                        <Col>
                            <Button onClick={onDiscard} style={{ marginRight: '8px' }}>
                                Discard
                            </Button>
                        </Col>
                        <Col>
                            <Button type='primary' htmlType='submit' loading={loading} iconPosition='end'>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            )}
        </Form>
    )
}

export default DiscountSuggestionForm
