import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { Button, Col, DeleteOutlined, Input, message, Row, Table } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { utmDiscounts } from '@library/utilities/interface'
import { titleCase } from '@gokwik/utilities'

const UTMDiscounting = () => {
    const [utmValues, setUtmValues] = useState<utmDiscounts>({
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_term: '',
        utm_content: '',
        discount_code: '',
    })
    const [dataSource, setDataSource] = useState([])
    const [isSaveDisabled, setIsSaveDisabled] = useState(true)
    const { config, loading, refetch, saveConfig } = useMerchantConfigApi({
        useCache: true,
        configSaveUrl: APIEndPoints.updateDiscountConfig,
    })
    const handleChange = (e) => {
        const { name, value } = e.target
        setUtmValues({
            ...utmValues,
            [name]: value,
        })
    }

    const handleAdd = () => {
        const { utm_source, utm_medium, utm_campaign, utm_term, utm_content, discount_code } = utmValues

        const trimmedValues = {
            utm_source: utm_source.trim(),
            utm_medium: utm_medium.trim(),
            utm_campaign: utm_campaign.trim(),
            utm_term: utm_term.trim(),
            utm_content: utm_content.trim(),
            discount_code: discount_code.trim(),
        }

        if (!trimmedValues.utm_source) {
            message.error('Need to add the UTM Source')
            return
        }
        if (!trimmedValues.discount_code) {
            message.error('Need to add the UTM Discount Code')
            return
        }
        const isDuplicate = dataSource.some(
            (item) =>
                item.utm_source.toLowerCase() === trimmedValues.utm_source.toLowerCase() &&
                item.utm_medium.toLowerCase() === trimmedValues.utm_medium.toLowerCase() &&
                item.utm_campaign.toLowerCase() === trimmedValues.utm_campaign.toLowerCase() &&
                item.utm_term.toLowerCase() === trimmedValues.utm_term.toLowerCase() &&
                item.utm_content.toLowerCase() === trimmedValues.utm_content.toLowerCase(),
        )
        if (isDuplicate) {
            message.error('UTM parameters cannot be the same as an existing entry')
            return
        }
        setDataSource([...dataSource, trimmedValues])

        setUtmValues({
            utm_source: '',
            utm_medium: '',
            utm_campaign: '',
            utm_term: '',
            utm_content: '',
            discount_code: '',
        })
        setIsSaveDisabled(false)
    }
    const handleSave = () => {
        saveConfig({ utm_discounts: dataSource }, () => {
            setIsSaveDisabled(true)
        })
    }
    const handleDelete = (record) => {
        const newDataSource = dataSource.filter((item) => item !== record)
        setDataSource(newDataSource)
        setIsSaveDisabled(false)
    }
    useEffect(() => {
        if (config?.utm_discounts) {
            setDataSource(config.utm_discounts)
        }
    }, [config])

    const columns = [
        {
            title: 'UTM Source',
            dataIndex: 'utm_source',
            key: 'utm_source',
            render: (value) => titleCase(value),
        },
        {
            title: 'UTM Medium',
            dataIndex: 'utm_medium',
            key: 'utm_medium',
            render: (value) => (value ? titleCase(value) : '-'),
        },
        {
            title: 'UTM Campaign',
            dataIndex: 'utm_campaign',
            key: 'utm_campaign',
            render: (value) => (value ? titleCase(value) : '-'),
        },
        {
            title: 'UTM Term',
            dataIndex: 'utm_term',
            key: 'utm_term',
            render: (value) => (value ? titleCase(value) : '-'),
        },
        {
            title: 'UTM Content',
            dataIndex: 'utm_content',
            key: 'utm_content',
            render: (value) => (value ? titleCase(value) : '-'),
        },
        {
            title: 'UTM Discount Code',
            dataIndex: 'discount_code',
            key: 'discount_code',
            render: (value) => titleCase(value),
        },
        {
            title: '',
            key: 'action',
            render: (text, record, index) => {
                return <DeleteOutlined className='text-error-500' onClick={() => handleDelete(record)} />
            },
        },
    ]
    return (
        <Col className='bg-white' span={24}>
            <Row gutter={[16, 24]}>
                <Col span={24}>
                    <Row gutter={[16, 16]} align={'bottom'}>
                        <Col span={6}>
                            <label>UTM Source*</label>
                            <Input
                                name='utm_source'
                                placeholder='UTM Source'
                                onChange={handleChange}
                                value={utmValues.utm_source}
                            />
                        </Col>
                        <Col span={6}>
                            <label>UTM Medium</label>
                            <Input
                                name='utm_medium'
                                placeholder='UTM Medium'
                                onChange={handleChange}
                                value={utmValues.utm_medium}
                            />
                        </Col>
                        <Col span={6}>
                            <label>UTM Campaign</label>
                            <Input
                                name='utm_campaign'
                                placeholder='UTM Campaign'
                                onChange={handleChange}
                                value={utmValues.utm_campaign}
                            />
                        </Col>
                        <Col span={6}>
                            <label>UTM Term</label>
                            <Input
                                name='utm_term'
                                placeholder='UTM Term'
                                onChange={handleChange}
                                value={utmValues.utm_term}
                            />
                        </Col>
                        <Col span={6}>
                            <label>UTM Content</label>
                            <Input
                                name='utm_content'
                                placeholder='UTM Content'
                                onChange={handleChange}
                                value={utmValues.utm_content}
                            />
                        </Col>

                        <Col span={6}>
                            <label>UTM Discount Code*</label>
                            <Input
                                name='discount_code'
                                placeholder='Enter Shopify discount code'
                                onChange={handleChange}
                                value={utmValues.discount_code}
                            />
                        </Col>
                        <Col span={4}>
                            <Button variant='primary' onClick={handleAdd}>
                                Add new
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Table dataSource={dataSource} columns={columns} pagination={false} />
                </Col>
                <Col>
                    <Button variant='primary' disabled={isSaveDisabled} onClick={handleSave}>
                        Save
                    </Button>
                </Col>
            </Row>
        </Col>
    )
}

export default UTMDiscounting
