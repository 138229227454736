import { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import ReactDOM from 'react-dom'
import dayjs from 'dayjs'
import {
    addBreadcrumb,
    convertIsoToLocaleString,
    deepEqualAndDiff,
    filterDateFormatter,
    makeAPICall,
    setBreadcrumb,
    titleCase,
    updateBreadcrumbs,
} from '@gokwik/utilities'
import { Link } from 'react-router-dom'
import {
    Table,
    Tag,
    Tooltip,
    ColumnProps,
    message,
    Checkbox,
    InfoCircleOutlined,
    Button,
    Col,
    Dropdown,
    FilterOutlined,
    Menu,
    Row,
} from 'gokwik-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { previousOrdersFilters } from '@store/filters/selectors'
import { getMerchantDetails, getMode, getUserDetails } from '@store/user/selectors'
import { saveFiltersData } from '@store/filters'
import RenderSearchFilters from '@library/components/search-filter'
import { getMerchantPlatform, isMerchantSelector, getUserConfig } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { internalUserColumns, merchantUserColumns } from './columnsData'
import { fetchUtmList } from '@library/utilities/helpers/fetchUtmList'
import { UtmListParams } from '@library/utilities/interface'
import { handleError } from '@library/utilities/helpers/handleError'
import { navigateToUrl } from 'single-spa'
import RenderColumnPreferences from '@library/components/column-preferences'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'

const today = dayjs()
const formattedDate = filterDateFormatter(today)
let utmListParams: any = {}

export default function () {
    const prevFilters = useSelector(previousOrdersFilters)
    const [total, setTotal] = useState(0)
    const [orders, setOrders] = useState([])
    const dispatch = useDispatch()
    const isMerchantUser = useSelector(isMerchantSelector)
    const merchantPlatform = useSelector(getMerchantPlatform)
    const [utmList, setUtmList] = useState<any>({})
    const merchantDetails = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const [parameters, setParameters] = useState({
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
        start_dt: formattedDate,
        end_dt: formattedDate,
        ...prevFilters,
    })
    const merchantConfig = useSelector(getUserConfig)
    const isRtoRiskIncluded = merchantConfig?.kc_config?.include_rto_risk || false
    const showRTOScoreToMerchant = merchantConfig?.show_rto_score || false
    const merchantColumns = useMemo(() => {
        const temp = [...merchantUserColumns]
        temp.splice(2, 0, {
            title: merchantPlatform === 'shopify' ? 'Merchant Order#' : 'Platform Order ID',
            dataIndex: merchantPlatform === 'shopify' ? 'order_name' : 'moid',
            width: 140,
        })
        temp.splice(3, 0, {
            title: merchantPlatform === 'shopify' ? 'Shopify Order Status' : 'Platform Order Status',
            dataIndex: 'm_order_status',
            // width: 180,
            ellipsis: true,
            render: (text: string) => (
                <Tooltip placement='topLeft' title={text}>
                    <span>{`${titleCase(text)}`}</span>
                </Tooltip>
            ),
            align: 'center',
        })
        if (showRTOScoreToMerchant) {
            temp.splice(10, 0, {
                title: 'RTO Score',
                dataIndex: 'rto_score',
                ellipsis: true,
                align: 'right',
            });
        }
        return temp
    }, [merchantPlatform, showRTOScoreToMerchant])

    const internalColumns = useMemo(() => {
        const temp = [...internalUserColumns] || []
        delete temp[2]
        delete temp[3]
        delete temp[4]
        temp?.splice(2, 0, {
            title: merchantPlatform === 'shopify' ? 'Merchant Order#' : 'Platform Order Number',
            dataIndex: merchantPlatform === 'shopify' ? 'order_name' : 'moid',
            width: 140,
        })
        temp?.splice(3, 0, {
            title: merchantPlatform === 'shopify' ? 'Shopify Order Status' : 'Platform Order Status',
            dataIndex: 'm_order_status',
            // width: 180,
            ellipsis: true,
            render: (text: string) => (
                <Tooltip placement='topLeft' title={text}>
                    <span>{`${titleCase(text)}`}</span>
                </Tooltip>
            ),
            align: 'left',
        })
        temp?.splice(4, 0, {
            title: merchantPlatform === 'shopify' ? 'Shopify Order ID' : 'Platform Order ID',
            dataIndex: 'moid',
            width: 180,
            ellipsis: true,
            render: (text: string) => (
                <Tooltip placement='topLeft' title={text}>
                    <span>{`${titleCase(text)}`}</span>
                </Tooltip>
            ),
            align: 'left',
        })

        return temp
    }, [merchantPlatform])
    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
        makeOrdersApiCall({ ...parameters, page: current, pageSize })
    }

    useEffect(() => {
        if (Object.keys(prevFilters).length && JSON.stringify(prevFilters) !== JSON.stringify(parameters)) {
            setParameters({ ...prevFilters })
        }
        logEvent(
            'order_clicked',
            'click',
            'Orders',
            user_details?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            user_details?.name,
            parameters,
        )
    }, [prevFilters])

    const reset = (e) => {
        e.preventDefault()
        const newParams = {
            page: 1,
            sortKey: 'created_at',
            sortOrder: -1,
            pageSize: 25,
            start_dt: formattedDate,
            end_dt: formattedDate,
        }
        setParameters({ ...newParams })
        makeOrdersApiCall(newParams)
    }

    async function makeOrdersApiCall(parameters: any) {
        if (!parameters.page) return
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getOrders,
                params: {
                    ...parameters,
                    start_dt: parameters.start_dt,
                    end_dt: parameters.end_dt,
                },
            })

            if (response.data?.data?.orders?.length > 0) {
                setOrders(response.data?.data?.orders)
                setTotal(response.data?.data?.totalCount)
            } else {
                setOrders([])
                setTotal(0)
            }
            dispatch<any>(saveFiltersData('orders', { ...parameters }))
        } catch (error) {
            handleError(error)
        }
    }

    const getUtmOptions = async (data: Record<string, string>) => {
        const keys = Object.keys(data)

        if (
            !['searchUtmCampaign', 'searchUtmSource', 'searchUtmMedium'].find((searchKey) =>
                keys.includes(searchKey),
            ) &&
            !(['start_dt', 'end_dt'].find((searchKey) => keys.includes(searchKey)) && utmList.params)
        )
            return
        const params: UtmListParams = {
            start_dt: data.start_dt || parameters.start_dt,
            end_dt: data.end_dt || parameters.end_dt,
            table: 'order',
            searchUtmCampaign:
                ('searchUtmCampaign' in data ? data.searchUtmCampaign : parameters.searchUtmCampaign) || '',
            searchUtmSource: ('searchUtmSource' in data ? data.searchUtmSource : parameters.searchUtmSource) || '',
            searchUtmMedium: ('searchUtmMedium' in data ? data.searchUtmMedium : parameters.searchUtmMedium) || '',
        }
        if (deepEqualAndDiff(utmListParams, params).isEqual) return

        const res: {
            campaigns: any
            mediums: any
            sources: any
        } = await fetchUtmList(params)

        if (res) {
            setUtmList({
                searchUtmCampaign: res.campaigns.map((item) => ({
                    text: item.mkt_campaign,
                    value: item.mkt_campaign,
                })),
                searchUtmMedium: res.mediums.map((item) => ({
                    text: item.mkt_medium,
                    value: item.mkt_medium,
                })),
                searchUtmSource: res.sources.map((item) => ({
                    text: item.mkt_source,
                    value: item.mkt_source,
                })),
                params,
            })
        } else {
            setUtmList((prev) => ({ ...prev, params }))
        }
        utmListParams = {
            ...params,
        }
    }

    useEffect(() => {
        if (parameters.page >= 1) {
            makeOrdersApiCall({ ...parameters, searchOrderStatus: 'confirmed' })
            getUtmOptions(parameters)
        }
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'orders',
                href: '/checkout/orders',
                text: 'Orders',
            },
        ])
        return () => {
            utmListParams = {}
        }
    }, [])

    const handleSearchClick = (e) => {
        e?.preventDefault()

        //Email validation
        if (parameters?.['searchEmail']) {
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

            if (!parameters?.['searchEmail'].match(emailPattern)) {
                message.error('Invalid email format.')

                return
            }
        }

        //Phone number validation
        if (parameters?.['searchPhone'] && parameters?.['searchPhone']?.toString()?.length < 10) {
            message.error('Phone number should be 10-digits.')
            return
        }

        //A merchant name should be selected to make api call
        // parameters['search_merchant_id'] = merchantDetails?.id;
        makeOrdersApiCall({ ...parameters, page: 1 })
        // if (isMerchantUser || parameters?.['search_merchant_id']) {
        //     setParameters((prev) => ({ ...prev, page: 1 }))
        //     makeOrdersApiCall({ ...parameters, page: 1 })
        // } else {
        //     message.error('Merchant name is required')
        // }
    }

    const includeRtoRiskCheckboxClicked = () => {
        navigateToUrl('/rto/postOrderVerification')
    }

    const RenderInfoToolTip = () => {
        return (
            <div className='p-1 text-black'>
                <p>1. Customer RTO Risk</p>
                <p>2. Address RTO Risk</p>
                <p>Ship orders after verifying RTO Risk.</p>
            </div>
        )
    }
    const [filteredColumns, setFilteredColumns] = useState([])
    useEffect(() => {
        const insertReactComponent = () => {
            const ulElement = document.querySelector('.ant-pagination') as HTMLElement
            if (ulElement) {
                ulElement.style.width = '97%'
            }
        }
        insertReactComponent()
    }, [orders?.length])

    return (
        <div className='w-full'>
            <div className='orders-page overflow-auto'>
                {isRtoRiskIncluded ? (
                    <div className='flex justify-end mb-1 text-sm'>
                        <span className='gap-2 flex items-center'>
                            <Checkbox onClick={includeRtoRiskCheckboxClicked} />
                            <span>Include RTO Risk</span>
                            <Tooltip title={<RenderInfoToolTip />} color='#fff'>
                                <InfoCircleOutlined className='text-gray-400' />
                            </Tooltip>
                        </span>
                    </div>
                ) : null}
                <div className='bg-white rounded'>
                    <RenderSearchFilters
                        values={parameters}
                        setValues={(data, reset) => {
                            setParameters((prev) => (reset ? { ...data } : { ...prev, ...data }))
                            getUtmOptions(data)
                        }}
                        page='orders'
                        onSearch={handleSearchClick}
                        onReset={reset}
                        customFilterOnSelect={(key) => {
                            getUtmOptions({ [key]: '' })
                        }}
                        customFilterOptions={utmList}
                        sixMonthsRange={true}
                    />
                </div>
                <div className='mt-5 bg-white rounded overflow-clip relative'>
                    {!!orders?.length && (
                        <RenderColumnPreferences
                            columns={isMerchantUser ? merchantColumns : internalColumns}
                            page={'orders'}
                            setFilteredColumns={setFilteredColumns}
                            isMerchantUser={isMerchantUser}
                        />
                    )}
                    <Table
                        columns={filteredColumns}
                        dataSource={orders || []}
                        pagination={{
                            current: parameters.page, // Current page number
                            pageSize: parameters.pageSize, // Number of items to display per page
                            total: total, // Total number of items in the data array
                            showSizeChanger: false,
                            onChange: handlePaginationChange,

                            position: ['topLeft', 'bottomLeft'],
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                        }}
                        scroll={{ x: 'max-content' }}
                    />
                </div>
            </div>
        </div>
    )
}
