import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'

export const fetchShopifyAppMerchantConfig = createAsyncThunk('shopifyAppConfig', async () => {
    const response = await makeAPICall({
        method: 'get',
        url: process.env.REACT_APP_BASE_URL + APIEndPoints.shopifyMerchantConfigs,
    })
    if (response.success) {
        return response.data.data
    }
    return { error: true }
})

type State = {
    config: any
    loading: boolean
    error: any
}

const initialState: State = {
    config: {},
    loading: false,
    error: null,
}


const shopifyAppSlice = createSlice({
    name: 'shopifyAppConfig',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchShopifyAppMerchantConfig.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchShopifyAppMerchantConfig.fulfilled, (state, action) => {
                state.loading = false
                state.config = action.payload
            })
            .addCase(fetchShopifyAppMerchantConfig.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
    },
})

export default shopifyAppSlice.reducer
