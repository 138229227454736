import { Button, Tour } from 'gokwik-ui-kit'
import TourIcon from '@library/images/tour.svg'
import { useState } from 'react'

export function RenderTour({ showTour, steps, onClose, onChange, followCurrentStep = false, onFinish }) {
    const [currentStep, setCurrentStep] = useState(0);
    const handleStepChange = (newStep) => {
        if (followCurrentStep) {
            onChange(newStep)
        } else if (newStep > currentStep) {
            onChange(currentStep)
        }
        setCurrentStep(newStep);
    };

    return showTour ? (
        <Tour
            open={showTour}
            onClose={onClose}
            onFinish={onFinish}
            steps={steps}
            onChange={handleStepChange}
            closeIcon={
                <Button type='text' className='text-blue mr-2'>
                    Skip
                </Button>
            }
            
            indicatorsRender={(current, total) => {
                return (<span>
                    {current + 1} / {total}
                </span>)
            }}
        />
    ) : null
}

export function FloatingTourIcon({ onClick }) {
    return (
        <Button className='fixed bottom-9 left-100 [z-1000] bg-[#000248] border-none rounded-[5px_20px_20px_20px] overflow-hidden whitespace-nowrap transition-all duration-300 w-12 h-8 hover:w-auto hiver:px-4' id='start-tour-button' type='primary' size='middle' onClick={() => onClick()}>
            <img className='mr-1' src={TourIcon} height={'20'} width={'20'} />
            <span className='ml-2 opacity-100 text-white'>Start Tour</span>
        </Button>
    )
}
