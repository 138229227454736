import { Card, Col, DeleteOutlined, Divider, EditOutlined, Popconfirm, Row, Segmented, Tooltip } from 'gokwik-ui-kit'
import React, { useEffect, useState } from 'react'
import { NoDataIcon } from '@library/images/noDataIcon'
import DiscountSuggestionsPreview from './discountSuggestionsPreview'

const DiscountList = ({
    discountCodes,
    setDiscountCodes,
    draggable,
    handleActionButtonClick,
    isNonPlusMerchant,
    setChangeReordering,
}) => {
    const [listItems, setListItems] = useState(null)
    const [draggedListItem, setDraggedListItem] = useState(null)
    const [ongoingDrag, setOngoingDrag] = useState(false)
    const previewPage = isNonPlusMerchant ? 'login' : 'checkout'

    useEffect(() => {
        if (discountCodes?.length) {
            setListItems(discountCodes)
        } else setListItems(null)
    }, [discountCodes])

    const discountUserType = {
        all: 'All',
        new: 'New Users Only',
        existing: 'Repeat Users Only',
    }

    const handleDragStart = (e, index) => {
        setTimeout(() => {
            setDraggedListItem(index)
        })
        e.dataTransfer.setData('index', index)
        setOngoingDrag(true)
    }

    const handleDragOver = (e, index) => {
        e.preventDefault()
        if (draggedListItem !== index) {
            const newData = [...listItems]
            const [removedItem] = newData.splice(draggedListItem, 1)
            newData.splice(index, 0, removedItem)
            setListItems(newData)
            setDraggedListItem(index)
            setDiscountCodes(newData)
            setChangeReordering(true)
        }
    }

    const handleDragEnd = (e) => {
        setDraggedListItem(null)
        setOngoingDrag(false)
    }

    const handleDrop = (e) => {
        setDraggedListItem(null)
        setOngoingDrag(false)
    }

    return (
        <div className='w-full flex flex-col gap-4'>
            {listItems && listItems?.length > 0 ? (
                <Row gutter={24} justify={'space-between'}>
                    <Col className=' rounded-lg' span={16}>
                        <>
                            {listItems?.map((item, index) => {
                                return (
                                    <Card
                                        key={item?.id}
                                        hoverable
                                        draggable={draggable}
                                        onDragStart={(e) => handleDragStart(e, index)}
                                        onDragOver={(e) => handleDragOver(e, index)}
                                        onDragEnd={(e) => handleDragEnd(e)}
                                        onDrop={(e) => handleDrop(e)}
                                        className='mb-4'
                                    >
                                        <div className='flex items-center'>
                                            {draggable && (
                                                <div className='flex flex-col justify-between h-4 mr-4'>
                                                    <span className='block w-5 h-0.5 bg-gray-300'></span>
                                                    <span className='block w-5 h-0.5 bg-gray-300'></span>
                                                    <span className='block w-5 h-0.5 bg-gray-300'></span>
                                                </div>
                                            )}
                                            <div className='flex flex-col w-full'>
                                                <Row justify={'space-between'} className='w-full'>
                                                    <div className='font-bold flex items-center'>
                                                        {item?.discount_code}{' '}
                                                        <span
                                                            className={`font-normal text-sm ml-2 bg-gray-100 border rounded-[20px_20px_20px_20px] px-2 py-1 ${
                                                                item?.is_active
                                                                    ? 'bg-green-50 text-green-500'
                                                                    : 'bg-yellow-50 text-yellow-500'
                                                            }`}
                                                        >
                                                            {item?.is_active ? 'Active' : 'Inactive'}
                                                        </span>
                                                    </div>

                                                    <div className=' border opacity-60 border-solid border-gray-300 rounded-md p-1 cursor-pointer'>
                                                        <Tooltip title={'Edit'}>
                                                            <EditOutlined
                                                                onClick={() => handleActionButtonClick('edit', item)}
                                                            />
                                                        </Tooltip>
                                                        <Divider type='vertical' />
                                                        <Popconfirm
                                                            title='Are you sure to delete this discount?'
                                                            okText='Yes'
                                                            okButtonProps={{
                                                                className:
                                                                    'bg-error-500 hover:!bg-error-500 text-white',
                                                            }}
                                                            cancelText='No'
                                                            onConfirm={() => handleActionButtonClick('delete', item)}
                                                        >
                                                            <Tooltip title={'Delete'}>
                                                                <DeleteOutlined className='text-error-500' />
                                                            </Tooltip>
                                                        </Popconfirm>
                                                    </div>
                                                </Row>

                                                <p>{item?.discount_detail}</p>
                                                <div className='flex justify-between'>
                                                    <div className='flex space-x-2 mt-2'>
                                                        <div className='bg-gray-100 border rounded-[20px_20px_20px_20px] px-2 py-1'>
                                                            Visibility:{' '}
                                                            <span className='font-semibold capitalize'>
                                                                {item?.visibility?.split('_')?.join(' ')?.toLowerCase()}
                                                            </span>
                                                        </div>
                                                        <div className='bg-gray-100 border rounded-[20px_20px_20px_20px] px-2 py-1'>
                                                            User Type:{' '}
                                                            <span className='font-semibold'>
                                                                {discountUserType[item?.user_type]}
                                                            </span>
                                                        </div>
                                                        {item?.min_price >= 0 && (
                                                            <div className='bg-gray-100 border rounded-[20px_20px_20px_20px] px-2 py-1'>
                                                                Min Price:{' '}
                                                                <span className='font-semibold'>
                                                                    ₹{item?.min_price}
                                                                </span>
                                                            </div>
                                                        )}
                                                        {item?.max_price > 0 &&
                                                            item?.max_price !== Number.MAX_SAFE_INTEGER && (
                                                                <div className='bg-gray-100 border rounded-[20px_20px_20px_20px] px-2 py-1'>
                                                                    Max Price:{' '}
                                                                    <span className='font-semibold'>
                                                                        ₹{item?.max_price}
                                                                    </span>
                                                                </div>
                                                            )}
                                                    </div>
                                                    {item?.highlighted && (
                                                        <div className='flex mt-2 ml-2 items-center bg-gray-100 border rounded-[20px_20px_20px_20px] px-2 py-1 bg-green-50 text-green-600'>
                                                            Highlighted Discount
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                )
                            })}
                        </>
                    </Col>
                    <Col span={8}>
                        <div className='text-lg font-semibold mb-2 items-center text-center'>Preview</div>
                        {/* <Segmented
                            options={['Login', 'Checkout']}
                            defaultValue={'Checkout'}
                            onChange={(value: string) => setPreviewPage(value.toLowerCase())}
                            block
                        /> */}
                        <DiscountSuggestionsPreview previewPage={previewPage} listItems={listItems} />
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col span={24}>
                        <div className='p-20 text-center flex flex-col items-center align-middle justify-center'>
                            <NoDataIcon />
                            <h3>No Discount added.</h3>
                        </div>
                    </Col>
                </Row>
            )}
        </div>
    )
}

export default DiscountList
