import { useState, useEffect, useCallback, useRef } from 'react'
import axios from 'axios'
import APIEndPoints from '../constants/apiEndpoints'
import { makeAPICall } from '@gokwik/utilities'
import { MerchantConfig } from '../interface'
import { message } from 'gokwik-ui-kit'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import { useLocation } from 'react-router-dom'

// Simple in-memory cache
const cache: { [m_id: string]: { [url: string]: MerchantConfig } } = {}

interface UseConfigOptions {
    configGetUrl?: string
    configSaveUrl?: string
    useCache?: boolean
    patch?: boolean
}

const useMerchantConfigApi = ({
    configGetUrl = APIEndPoints.merchantConfigs,
    useCache = false,
    configSaveUrl = APIEndPoints.merchantConfigs,
    patch = false,
}: UseConfigOptions = {}) => {
    const [config, setConfig] = useState<MerchantConfig | null>(null)
    const [loading, setLoading] = useState(false)
    const merchantDetails = useSelector(getMerchantDetails)
    const firstRender = useRef(true)
    const location = useLocation()
    const isIntegrationPage = location.pathname.includes('settings/integrations')

    const fetchConfig = useCallback(
        async (refetch?: boolean) => {
            setLoading(true)
            try {
                if (!refetch && useCache && cache[merchantDetails.m_id]?.[configGetUrl]) {
                    setConfig(cache[merchantDetails.m_id][configGetUrl])
                } else {
                    let params = null
                    if (configGetUrl === APIEndPoints.offersAndDiscounts) {
                        params = {
                            page: 1,
                            pageSize: 150,
                        }
                    }

                    const response = await makeAPICall({
                        method: 'get',
                        url: process.env.REACT_APP_BASE_URL + configGetUrl,
                        ...(params && { params }),
                    })
                    cache[merchantDetails.m_id] = { [configGetUrl]: JSON.parse(JSON.stringify(response.data.data)) } // Update cache
                    setConfig(response.data.data)
                }
            } catch (error) {
                console.error('Failed to fetch config:', error)
            } finally {
                setLoading(false)
            }
        },
        [configGetUrl, useCache],
    )

    const saveConfig = useCallback(
        async (newConfig: Partial<MerchantConfig>, callback?: () => void) => {
            setLoading(true)
            try {
                const res = await makeAPICall({
                    method: isIntegrationPage || patch ? 'patch' : 'post',
                    url: process.env.REACT_APP_BASE_URL + configSaveUrl,
                    payload: newConfig,
                })
                if (res.success) {
                    if (callback) {
                        callback()
                    }
                    refetch()
                    message.success('Settings saved successfully')
                } else {
                    message.error(res.response?.data?.message || 'Failed to save settings')
                    console.error('Failed to save config:', res.response?.data?.message)
                }
            } catch (error) {
                message.error('Failed to save settings')
                console.error('Failed to save config:', error)
            } finally {
                setLoading(false)
            }
        },
        [configGetUrl],
    )

    const refetch = useCallback(() => {
        fetchConfig(true)
    }, [fetchConfig])

    useEffect(() => {
        fetchConfig()
    }, [fetchConfig])

    useEffect(() => {
        if (!Object.keys(cache).includes(merchantDetails?.m_id) && !firstRender.current) {
            refetch()
        }
    }, [merchantDetails?.m_id])

    return { config, loading, saveConfig, refetch }
}

export default useMerchantConfigApi
