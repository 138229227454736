import React, { useEffect, useState } from 'react'
import {
    addBreadcrumb,
    convertIsoToLocaleDateString,
    convertIsoToLocaleString,
    makeAPICall,
    setBreadcrumb,
    updateBreadcrumbs,
} from '@gokwik/utilities'
// import csv from 'csvtojson'
import {
    Button,
    Table,
    Tag,
    Tooltip,
    Row,
    Drawer,
    Col,
    Select,
    Upload,
    ColumnProps,
    Switch,
    UploadProps,
    RedoOutlined,
    DownloadOutlined,
    InboxOutlined,
    UploadOutlined,
    SearchOutlined,
    InfoCircleOutlined,
    FileExcelFilled,
    Input,
    Modal,
    RightOutlined,
    CheckCircleFilled,
    InfoCircleFilled,
    message,
    Segmented,
    Card,
    Divider,
    Pagination,
    Alert,
    Radio,
} from 'gokwik-ui-kit'
import Link from '@library/images/PixelIcons/link.svg'

import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserDetails, isMerchantSelector } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import dayjs from 'dayjs'
import Test from '@public/assets/pincode_sample.csv'
import { getCookie, setCookie } from '@library/utilities/helpers/cookieHelper'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'
import { useNavigate } from 'react-router-dom'
import { NoDataIcon } from '@library/images/noDataIcon'
const { Option } = Select
const { Dragger } = Upload

export default function index(props) {
    const isMerchantUser = useSelector(isMerchantSelector)
    const userDetails = useSelector(getUserDetails)
    const [parameters, setParameters] = useState({
        merchant: null,
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
    })
    const [isDrawerOpen, setDrawerOpen] = useState(false)
    const [merchantList, setMerchantList] = useState([])
    const [pincodeList, setPincodeList] = useState([])
    const [pincodeDetails, setDetails] = useState<any>(null)
    const [uploadPincodesList, setUploadedPincodes] = useState([])
    const [uploadedFile, setUploadedFile] = useState([])
    const [file, setFile] = useState({ file: '', fileName: '' })
    const [total, setTotalPincode] = useState(0)
    const [filesList, setFileLists] = useState([])
    const [fileSelected, setCurrentFileSeletecd] = useState<any>(null)
    const [showReplaceWarning, setReplaceWarning] = useState(false)
    const [showUpload, setShowUpload] = useState(false)
    const [currentPincode, setCurrentPincode] = useState(null)
    const [searchPincode, setSearchPincode] = useState('')
    const merchantDetails = useSelector(getMerchantDetails)
    const [showTour, setShowTour] = useState(false)
    const navigate = useNavigate()
    const alertTexts = [
        { text: 'Unlisted PIN codes will be non-serviceable for prepaid and COD.' },
        { text: 'Invalid or non-Indian PIN codes will be marked non-serviceable at checkout.' },
        {
            text: 'If 3rd-party PIN code serviceability is enabled, this list acts as a fallback for API failures.',
            button: (
                <Button
                    className='mt-2 focus:outline-none'
                    onClick={() =>
                        navigate('/checkout/settings/integrations', {
                            state: {
                                activeTab: 'edd-shipping',
                            },
                        })
                    }
                >
                    <img src={Link} className='w-3 h-3 mr-2' alt='link icon' />
                    <span>Take me there</span>
                </Button>
            ),
        },
    ]

    useEffect(() => {
        const checkCookies = () => {
            const isTourVisited = getCookie('pincodeServiceabilityTour')
            if (!isTourVisited) {
                setShowTour(true)
            }
        }
        setTimeout(checkCookies, 100)
    }, [])

    const closeTour = () => {
        setShowTour(false)
        setCookie('pincodeServiceabilityTour', true, 365)
    }

    const columns: ColumnProps<any>[] = [
        {
            title: <span>PIN Code</span>,
            dataIndex: 'pincode',
            width: 150,
            ellipsis: true,
            align: 'center',
        },
        {
            title: <span>Cash on Delivery</span>,
            dataIndex: 'cod',
            width: 150,
            align: 'center',
            render: (itm) => <Tag variant={itm ? 'default' : 'error'}>{itm ? 'Enabled' : 'Disabled'}</Tag>,
        },

        {
            title: <span>Prepaid</span>,
            dataIndex: 'upi',
            width: 150,
            align: 'center',
            render: (itm) => <Tag variant={itm ? 'default' : 'error'}>{itm ? 'Enabled' : 'Disabled'}</Tag>,
        },
    ]

    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
    }

    const downloadCSV = async (file) => {
        try {
            const response = await makeAPICall({
                url:
                    process.env.REACT_APP_BASE_URL +
                    '/api/dashboard/pincode/download/file' +
                    '?file_name=' +
                    'latest' +
                    '&response_mode=download',
                method: 'get',
            })
            console.log(response)
            if (response.status == 200) {
                const filename = 'Pincode.csv'
                const link = document.createElement('a')
                var blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' })
                link.href = window.URL.createObjectURL(blob)
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                message.success('Downloaded successfully!')
                // console.log(response.data)
            } else {
                console.error('Failed to fetch Pincode file :', response.status, response.statusText)
                message.error('Failed to fetch Pincode file !')
            }
        } catch (error) {
            console.error('Download Failed:', error)
            message.error('Download failed!')
        }
    }

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'settings',
                href: '/checkout/settings',
                text: 'Checkout Settings',
            },
            {
                key: 'pincode',
                href: '/checkout/settings/pincode',
                text: 'PIN Code Serviceability',
            },
        ])

        if (!isMerchantUser) {
            fetchMerchantList()
        } else {
            makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.merchantPincodes,
                params: {
                    page: parameters.page,
                    pageSize: parameters.pageSize,
                },
            }).then((res) => {
                setPincodeList(res.data.data?.pincodes)
                setTotalPincode(res.data.data?.totalCount)
                setCurrentPincode({
                    file: res.data.data.file_name,
                    fileName: res.data.data.uploaded_file,
                })
            })
        }
    }, [parameters.pageSize, parameters.page])

    useEffect(() => {
        makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.getPin + '?checkout_platform=' + props.checkoutPlatform,
        }).then((res) => {
            setDetails(res.data.data)
        })
        getFilesUploadedList()
    }, [])

    const getFilesUploadedList = () => {
        makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.merchantPincodeFiles,
        }).then((res) => {
            setFileLists(res.data.data)
        })
    }

    useEffect(() => {
        if (searchPincode.length === 6 || searchPincode.length === 0) {
            getPincodeLists()
        }
    }, [searchPincode])

    const handleSearchClick = (e) => {
        e?.preventDefault()
        if (isMerchantUser || merchantDetails?.id) {
            getPincodeLists()
        } else {
            message.error('Merchant name is required')
        }
    }

    const fetchMerchantList = async () => {
        const respnse = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.getMerchantList,
        })
            .then((res) => {
                if (res.data.data) {
                    let arr = res.data.data.map((u) => ({
                        name: u.short_name,
                        id: u.id,
                        m_id: u.m_id,
                    }))
                    let final = arr
                    setMerchantList(final)
                }
            })
            .catch(() => {
                message.error('Unable to fetch merchants')
            })
    }

    const togglePincodeServiceablity = () => {
        makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.enablePin,
            // (!isMerchantUser ? '?merchant_id=' + merchantDetails.id : ''),
            payload: {
                enable: !pincodeDetails.enable_pincode,
                checkout_platform: props.checkoutPlatform,
            },
        }).then((res) => {
            getPincodeDetails()
        })
    }

    const getPincodeLists = () => {
        setParameters((prev) => ({ ...prev, page: 1 }))

        makeAPICall({
            method: 'get',
            url:
                process.env.REACT_APP_BASE_URL +
                APIEndPoints.pincodeList +
                `?page=${parameters.page}&pageSize=${parameters.pageSize}&pincode=${searchPincode}`,
            // (!isMerchantUser ? '?merchant_id=' + parameters.merchant.id : ''),
        }).then((res) => {
            setPincodeList(res.data.data?.pincodes)
            setTotalPincode(res.data.data?.totalCount)
            setCurrentPincode({
                file: res.data.data.file_name,
                fileName: res.data.data.uploaded_file,
            })
        })
        makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.getPin + '?checkout_platform=' + props.checkoutPlatform,
        }).then((res) => {
            setDetails(res.data.data)
        })
    }

    const getPincodeDetails = () => {
        makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.getPin + '?checkout_platform=' + props.checkoutPlatform,
        }).then((res) => {
            console.log(res)
            setDetails(res.data.data)
        })
    }

    const donwloadSample = () => {
        let csvContent = 'pincode,cod,upi\r\n'
        csvContent += ['506001,true,true', '506002,true,false', '506003,false,true'].join('\r\n')
        var pom = document.createElement('a')
        // var blob = new Blob(Test, { type: 'text/csv;charset=utf-8;' })
        // var url = URL.createObjectURL(blob)
        pom.href = Test
        pom.setAttribute('download', 'pincode.csv')
        pom.click()

        // document.body.removeChild(link)
        message.success('Sample downloaded successfully!')
    }

    function uploadPincodeCsv(file) {
        let reader = new FileReader()
        reader.readAsText(file)
        reader.onload = (e) => {
            const content = e.target.result
            const rows = content
                //@ts-ignore
                .split('\n')
                .filter((row) => row.trim() !== '')
                .map((row) => row.split(','))
            if (
                rows.length < 2 ||
                rows[0].length < 3 ||
                (rows[0][1].toLowerCase() !== 'cod' && rows[0][2].toLowerCase() !== 'upi')
            ) {
                message.error('Wrong CSV file structure. Please look at the sample to know the right structure!')
                return
            }
            // Remove header row
            rows.shift()
            // Validate and process each row
            for (let i = 0; i < rows.length; i++) {
                const [pincode, cod, upi] = rows[i].map((value) => value.trim())
                if (!/^\d+$/.test(pincode) || !/^(true|false)$/i.test(cod) || !/^(true|false)$/i.test(upi)) {
                    message.error('Invalid value in CSV detected. Please check and upload.')
                    return
                }
            }
            // Convert rows to an array of objects
            const pincodes = rows.map((row) => ({
                pincode: Number(row[0]),
                cod: row[1].toLowerCase() === 'true',
                upi: row[2].toLowerCase() === 'true',
            }))
            setUploadedPincodes([...pincodes])
            setFile({
                file: file,
                fileName: file,
            })
            uploadPincodesData2(file, file.name)
        }

        reader.readAsText(file)
    }

    const getSignedUrl = async () => {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.fileS3PreSignedUrl,
            params: { file_name: 'Pincode.csv', ...(!isMerchantUser && { merchant_id: merchantDetails?.id }) },
        })
        if (response.success) {
            return response.data.data.url
        }
    }

    async function uploadPincodesData2(csvFile, fileName) {
        try {
            const url = await getSignedUrl()
            const awsResponse = await fetch(url, {
                method: 'PUT',
                body: csvFile,
            })
            if (awsResponse.status == 200) {
                const parsedUrl = new URL(url)
                const parts = parsedUrl.pathname.split('/')
                const nonEmptyParts = parts.filter((part) => part !== '')
                const filename = nonEmptyParts.pop()

                const postData = {
                    file_name: filename,
                    ...(!isMerchantUser && { merchant_id: merchantDetails?.id + '' }),
                }
                const response = await makeAPICall({
                    method: 'post',
                    url: process.env.REACT_APP_BASE_URL + APIEndPoints.fileS3UploadNotifyUrl + '?file=' + fileName,
                    payload: {
                        ...postData,
                    },
                }).then(() => {
                    message.success('Upload successfully!')
                    getPincodeLists()
                    setDrawerOpen(false)
                    setShowUpload(null)
                    setUploadedFile([])
                    setReplaceWarning(false)
                    setCurrentFileSeletecd(null)
                    getFilesUploadedList()
                })
                if (response.status != 200)
                    console.error('Failed to make POST request:', response.status, response.data, response.statusText)
            } else {
                console.error('Failed to make POST request:', awsResponse.status, awsResponse.statusText)
            }
        } catch {
            // toast.error('Upload Failed!', {
            //     position: 'top-center',
            //     autoClose: 1500,
            // })
        }
    }
    async function replacePincodesData() {
        try {
            const postData = {
                file_name: fileSelected.file_name,
                ...(!isMerchantUser && { merchant_id: merchantDetails?.id + '' }),
            }

            const response = await makeAPICall({
                method: 'post',
                url:
                    process.env.REACT_APP_BASE_URL +
                    APIEndPoints.fileS3UploadNotifyUrl +
                    '?file=' +
                    fileSelected.file +
                    '&replace=true',
                payload: {
                    ...postData,
                },
            })
            if (response.status != 200)
                console.error('Failed to make POST request:', response.status, response.data, response.statusText)
            // } else {
            //     console.error('Failed to make POST request:', awsResponse.status, awsResponse.statusText)
            // }

            message.success('Upload successfully!')
            getPincodeLists()
            setDrawerOpen(false)
            setShowUpload(null)
            setReplaceWarning(false)
            setCurrentFileSeletecd(null)
            getFilesUploadedList()
        } catch {
            message.error('Upload Failed!')
        }
    }

    const UploadArea = (
        <div className='pt-6 flex flex-col gap-y-6'>
            <Dragger
                accept='.csv,text/csv'
                name='file'
                maxCount={1}
                multiple={false}
                // itemRender={(originNode, file) => {
                //     return (
                //         <div className='w-full'>
                //             <span>{file.name}</span>
                //         </div>
                //     )
                // }}

                fileList={uploadedFile}
                onChange={(info) => {
                    if (!info.fileList.length) {
                        setUploadedFile([])
                        return
                    }

                    const { status, originFileObj, size } = info.file
                    if (size > 200_000_000) {
                        message.error('File size should be less than 200 MB')
                        return
                    }
                    info.file.status = 'done'
                    setUploadedFile([originFileObj])
                }}
            >
                <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                </p>
                <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                <p className='text-sm text-black/40'>Supports .csv file (Maximum file size 200 MB)</p>
            </Dragger>
            <Alert
                showIcon
                type='info'
                message={
                    <div className='flex-grow flex justify-between items-center'>
                        <p className='text-xs'>Use the suggested format shown in the sample file.</p>
                        <Button onClick={donwloadSample} type='link' variant='default' className='m-0 p-0'>
                            <DownloadOutlined />
                            Download Sample
                        </Button>
                    </div>
                }
            />
        </div>
    )

    return (
        <>
            {currentPincode?.file ? (
                <Row gutter={[16, 16]}>
                    <Col xl={18} lg={24}>
                        <div className='w-full px-2 flex flex-col gap-2 inter  bg-white p-3   rounded-md '>
                            <div className='flex flex-col justify-between gap-1'>
                                <p className='text-lg font-semibold'>PIN Codes Settings</p>

                                <p className='text-sm  text-gray-400'>
                                    <p className='text-sm  text-gray-400'>
                                        Control PIN Code against payment method and disable/enable service.
                                    </p>
                                </p>
                            </div>

                            <Card size='small'>
                                <div className='flex flex-col gap-y-4'>
                                    <Row gutter={16} wrap={false} align='middle'>
                                        <Col flex={'auto'}>
                                            <span className='text-sm font-medium'>PIN Code Serviceability</span>
                                            <p className='text-sm  text-gray-400'>
                                                By enabling this feature you can define the serviceable PIN codes for
                                                your brand and also define PIN code level prepaid and cash on delivery
                                                availability.
                                            </p>
                                        </Col>
                                        {currentPincode?.file && (
                                            <Col flex={'none'}>
                                                <Switch
                                                    id='enable-pincode'
                                                    onClick={togglePincodeServiceablity}
                                                    checked={
                                                        Object.keys(pincodeDetails || {}).includes('enable_pincode') &&
                                                        pincodeDetails.enable_pincode
                                                    }
                                                    className='mx-3 '
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                    <Divider className='my-0' />
                                    {currentPincode?.file && (
                                        <Row wrap={false} gutter={16} align='middle'>
                                            <Col flex={'auto'} className='flex flex-col'>
                                                <span className='text-sm font-medium'>Serviced PIN Codes</span>
                                            </Col>
                                            <Col flex={'none'}>
                                                <div className='gap-x-3 rounded-md flex items-center justify-between  '>
                                                    <div className='flex gap-2'>
                                                        <div
                                                            onClick={() => downloadCSV(currentPincode?.file)}
                                                            className='flex gap-2 items-center cursor-pointer text-primary-450'
                                                        >
                                                            <DownloadOutlined />
                                                            <span>{currentPincode?.fileName}</span>
                                                        </div>

                                                        <span className='opacity-40'>• {total} PIN codes </span>
                                                    </div>
                                                    <Button
                                                        id='upload-pincode'
                                                        onClick={() => {
                                                            setDrawerOpen(true)
                                                            filesList.length === 1 && setShowUpload(true)
                                                        }}
                                                    >
                                                        Change
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                    {!!pincodeList?.length && (
                                        <Row gutter={[16, 16]}>
                                            <Col span={8}>
                                                <Input
                                                    onChange={(e) => setSearchPincode(e.target.value)}
                                                    type='text'
                                                    placeholder='Search PIN Code'
                                                    maxLength={6}
                                                    suffix={<SearchOutlined />}
                                                />
                                            </Col>
                                            <Col span={16} className='flex justify-end'>
                                                <Pagination
                                                    current={parameters.page}
                                                    pageSize={parameters.pageSize}
                                                    total={total}
                                                    showSizeChanger={false}
                                                    onChange={handlePaginationChange}
                                                />
                                            </Col>
                                            <Col span={24}>
                                                <Table
                                                    columns={columns}
                                                    dataSource={pincodeList || []}
                                                    pagination={{
                                                        current: parameters.page, // Current page number
                                                        className: 'pt-3',
                                                        pageSize: parameters.pageSize, // Number of items to display per page
                                                        total: total, // Total number of items in the data array
                                                        showSizeChanger: false,
                                                        onChange: handlePaginationChange,
                                                        showTotal: (total, range) =>
                                                            `Showing ${range[0]}-${range[1]} of ${total} Records`,
                                                        position: ['bottomRight'],
                                                    }}
                                                    // className='pt-2'
                                                    scroll={{ x: 300 }}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                    {!currentPincode?.file && UploadArea}
                                </div>
                            </Card>
                        </div>
                    </Col>
                    <Col xl={6} lg={12}>
                        <div className='w-full flex items-center gap-x-2 text-base'>
                            <InfoCircleFilled className='text-primary-450' />
                            <h3 className='font-medium my-0'>Important!</h3>
                        </div>
                        <div className='flex flex-col gap-y-2 mt-6'>
                            {alertTexts.map((item, index) => (
                                <Alert
                                    key={index}
                                    type='info'
                                    className='flex flex-col items-start'
                                    message={item.text}
                                    action={item.button}
                                />
                            ))}
                        </div>
                    </Col>
                </Row>
            ) : (
                <div className='w-full px-2 flex flex-col gap-2 inter  bg-white p-3   rounded-md '>
                    <div className='flex flex-col justify-between gap-1'>
                        <p className='text-lg font-semibold'>PIN Codes Settings</p>

                        <p className='text-sm  text-gray-400'>
                            <p className='text-sm  text-gray-400'>
                                By enabling this feature you can define the serviceable PIN Codes for your brand and
                                also define PIN Code level prepaid and cash on delivery availability.
                            </p>
                        </p>
                    </div>
                    <Card>
                        <div className='flex flex-col gap-y-3 items-center'>
                            <div className='w-full flex flex-col gap-x-2 items-center'>
                                <NoDataIcon />
                                <h3 className='my-0 text-base font-semibold'>No PIN Codes List Yet!</h3>
                            </div>
                            <p className='text-center max-w-md'>
                                Please ensure that the uploaded file is in the suggested format as shown in the sample
                                file on the right.{' '}
                                <Button onClick={donwloadSample} type='link' variant='default' className='m-0 p-0'>
                                    <DownloadOutlined />
                                    Download Sample
                                </Button>
                            </p>
                            <Button
                                onClick={() => {
                                    setDrawerOpen(true)
                                    setShowUpload(true)
                                }}
                                type='primary'
                                className='mt-4'
                            >
                                <UploadOutlined />
                                Upload
                            </Button>
                        </div>
                    </Card>
                </div>
            )}
            <Modal
                okText={showUpload ? 'Upload' : showReplaceWarning ? 'Yes Replace' : 'Replace'}
                onOk={() => {
                    showUpload
                        ? uploadPincodeCsv(uploadedFile[0])
                        : showReplaceWarning
                        ? replacePincodesData()
                        : fileSelected && setReplaceWarning(true)
                }}
                okButtonProps={{
                    disabled: showUpload && !uploadedFile.length,
                }}
                title={filesList.length > 1 ? 'Change PIN codes' : 'Upload PIN codes'}
                className='w-[80vw] p-0 min-w-[620px]'
                onCancel={() => {
                    if (showUpload) {
                        setUploadedFile([])
                        setDrawerOpen(false)
                        setShowUpload(false)
                    }
                    showReplaceWarning ? setReplaceWarning(false) : setDrawerOpen(false)
                }}
                cancelText={showReplaceWarning ? 'No! Go Back' : 'Discard'}
                open={isDrawerOpen}
            >
                {filesList.length > 1 && (
                    <Segmented
                        label={null}
                        defaultValue='Choose from pre-existing file'
                        value={showUpload ? 'Upload File' : 'Choose from pre-existing file'}
                        onChange={(e) => {
                            if (e == 'Upload File') {
                                setReplaceWarning(false)
                                setShowUpload(true)
                            } else if (e == 'Choose from pre-existing file') {
                                setReplaceWarning(false)
                                setShowUpload(false)
                            }
                            // fileSelected && showReplaceWarning && setReplaceWarning(true)
                        }}
                        options={['Upload File', 'Choose from pre-existing file']}
                    />
                )}

                {!showReplaceWarning && !showUpload && (
                    <Radio.Group
                        onChange={(e) => {
                            setCurrentFileSeletecd(filesList.find((item) => item.id === e.target.value))
                        }}
                        className='flex flex-col gap-y-3 mt-6 max-h-[60vh] overflow-y-auto'
                        value={fileSelected?.id}
                    >
                        {filesList?.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <Radio value={item.id}>
                                    <Row justify='space-between' align='middle' className='pincode-file-radio-label'>
                                        <Col span={12}>{item.file}</Col>
                                        <Col className='text-sm text-gray-400 text-right' span={6}>
                                            {item.records_count < 10 ? '0' : ''}
                                            {item.records_count} PIN Codes
                                        </Col>
                                        <Col className='text-sm text-gray-400 text-right' span={6}>
                                            <span className='ml-2'>{dayjs(item.updated_at).format('DD MMM YYYY')}</span>
                                        </Col>
                                    </Row>
                                </Radio>
                                {filesList.length - 1 !== index && <Divider className='my-0' />}
                            </React.Fragment>
                        ))}
                    </Radio.Group>
                )}
                {fileSelected && showReplaceWarning && (
                    <div className='flex flex-col items-center justify-center py-4'>
                        <p className='text-xl font-semibold mt-2'>Do you wish to go further?</p>
                        <p className='my-6 leading-8 text-center'>
                            The current pincode file{' '}
                            <span
                                onClick={() => downloadCSV(currentPincode?.file)}
                                className='border-[0.7px] border-solid border-gray-300 rounded-md py-1 px-2 cursor-pointer'
                            >
                                <span className='text-primary-450'>{currentPincode?.fileName}</span> • {total} PIN codes
                            </span>{' '}
                            will be replaced by{' '}
                            <span
                                onClick={() => downloadCSV(fileSelected.file)}
                                className='border-[0.7px] border-solid border-gray-300 rounded-md py-1 px-2 cursor-pointer'
                            >
                                <span className='text-primary-450'>{fileSelected?.file}</span> •{' '}
                                {fileSelected?.records_count} PIN codes
                            </span>
                        </p>
                        <Alert
                            type='info'
                            message='Changing the pincode file will impact all of your shipping related settings.'
                            showIcon
                        />
                    </div>
                )}

                {showUpload && UploadArea}
            </Modal>
        </>
    )
}
