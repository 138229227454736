import { Alert, Button, Col, Divider, Form, Row } from 'gokwik-ui-kit'
import BookDownload from '@library/images/book-download.svg'
import React, { useEffect, useMemo } from 'react'
import RenderField from '@library/utilities/helpers/renderFormItem'
import TaxOverride from '@library/components/tax-settings/taxOverride'
import { MerchantConfig } from '@library/utilities/interface'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { deepEqualAndDiff } from '@gokwik/utilities'
import { Link } from 'react-router-dom'
import LinkImg from '@library/images/PixelIcons/link.svg'

const TaxConfig = () => {
    const [form] = Form.useForm()
    const merchantConfig: MerchantConfig = Form.useWatch('merchant_config', { form, preserve: true })
    const { config, saveConfig } = useMerchantConfigApi({
        patch: true,
    })
    const changedValues = useMemo(
        () =>
            deepEqualAndDiff(
                {
                    enable_tax_display: config?.enable_tax_display,
                    tax_override_enable: config?.tax_override_enable,
                    tax_override_config: config?.tax_override_config,
                    tax_inclusion: config?.tax_inclusion,
                    show_gst_order_summary_cart: config?.show_gst_order_summary_cart,
                },
                form.getFieldsValue(true).merchant_config,
            ),
        [merchantConfig, config],
    )

    const fields = [
        {
            type: 'switch',
            name: ['merchant_config', 'enable_tax_display'],
            label: 'Enable Tax Display on Shopify Order Details',
            description: 'Enabling this config would ensure that tax breakup is passed on to shopify orders.',
            // knowMoreLink:
            //   'https://scribehow.com/page/Guide_to_Set_Up_Shipping_Serviceability_on_GoKwik_Dashboard__cxszaSrOQ6GjY8KvNdtWOg',
            span: 24,
        },
        {
            type: 'switch',
            name: ['merchant_config', 'show_gst_order_summary_cart'],
            label: 'Show Taxes on Order Summary',
            description: 'Enable this to show tax breakup in order summary to the customers.',
            span: 24,
            additionalFields: [
                {
                    type: 'select',
                    name: ['merchant_config', 'tax_inclusion'],
                    label: 'Tax Type on Product Price',
                    rules: [{ required: merchantConfig?.show_gst_order_summary_cart }],
                    hidden: !merchantConfig?.show_gst_order_summary_cart,
                    inputProps: {
                        options: [
                            { label: 'Inclusive', value: 'inclusive' },
                            { label: 'Exclusive', value: 'exclusive' },
                        ],
                    },
                },
                ,
            ],
        },
        {
            type: 'switch',
            name: ['merchant_config', 'tax_override_enable'],
            label: 'Enable Tax Override',
            description:
                'This config allows you to set different tax slab on product/shipping level for different states as per your requirements.',
            // knowMoreLink:
            //'https://scribehow.com/page/Guide_to_Set_Up_Shipping_Serviceability_on_GoKwik_Dashboard__cxszaSrOQ6GjY8KvNdtWOg',
            alertMessage:
                'Tax override settings configured on Shopify need to be replicated here, as Shopify does not provide access to them.',
            span: 24,
            additionalFields: [
                {
                    type: 'custom',
                    name: ['tax_override_config'],
                    render: () => (
                        <TaxOverride
                            taxOverrides={merchantConfig?.tax_override_config || []}
                            onAdd={(values) =>
                                form.setFieldValue(
                                    ['merchant_config', 'tax_override_config'],
                                    [...(merchantConfig?.tax_override_config || []), ...values],
                                )
                            }
                            onRemove={(index) =>
                                form.setFieldValue(
                                    ['merchant_config', 'tax_override_config'],
                                    merchantConfig?.tax_override_config?.filter((_, i) => i !== index),
                                )
                            }
                        />
                    ),
                    hidden: !merchantConfig?.tax_override_enable,
                    span: 24,
                    label: 'Tax Overrides',
                },
            ],
        },
    ]
    const onFinish = async (values) => {
        const payload = { ...changedValues.diff }
        if (payload.show_gst_order_summary_cart === false && merchantConfig.tax_inclusion === 'exclusive') {
            payload.tax_inclusion = 'inclusive'
        }
        await saveConfig(payload)
    }
    useEffect(() => {
        form.setFieldsValue({
            merchant_config: {
                enable_tax_display: config?.enable_tax_display,
                tax_override_enable: config?.tax_override_enable,
                tax_override_config: config?.tax_override_config,
                tax_inclusion: config?.tax_inclusion,
                show_gst_order_summary_cart: config?.show_gst_order_summary_cart,
            },
        })
    }, [config])
    return (
        <Row gutter={[16, 16]} className='px-3'>
            <Col span={18}>
                <div className='flex items-center text-lg leading-7'>
                    <p className='text-lg font-semibold'>Tax Settings</p>
                    {/* <a
                        className='ml-2 flex items-center text-sm font-normal'
                        href='https://scribehow.com/page/Guide_to_Set_Up_Shipping_Serviceability_on_GoKwik_Dashboard__cxszaSrOQ6GjY8KvNdtWOg'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <img className='mr-1' src={BookDownload} height='18' width='18' alt='Open Guide' />
                        Open Guide
                    </a> */}
                </div>

                <p className='py-0 text-gray-600'>Configure your tax override settings here.</p>
            </Col>
            <Col span={6} className='text-right'>
                <Button
                    type='primary'
                    disabled={changedValues.isEqual}
                    onClick={() => {
                        form.submit()
                    }}
                >
                    Save
                </Button>
            </Col>
            <Col span={24}>
                <Form form={form} layout='vertical' requiredMark={'optional'} onFinish={onFinish} scrollToFirstError>
                    <Row gutter={[16, 16]}>
                        {fields.map((field, i) => (
                            <React.Fragment key={field.label}>
                                <Col span={field.span}>
                                    <RenderField field={field} form={form} />
                                    {field.alertMessage && (
                                        <Alert message={field.alertMessage} type='info' showIcon className='mt-2' />
                                    )}
                                </Col>
                                {field.additionalFields &&
                                    field.additionalFields.map((additionalField) =>
                                        additionalField.hidden ? null : (
                                            <Col span={additionalField.span} key={additionalField.label}>
                                                <RenderField field={additionalField} form={form} />
                                            </Col>
                                        ),
                                    )}

                                {i !== fields.length - 1 && <Divider className='my-0' />}
                            </React.Fragment>
                        ))}
                        {merchantConfig?.tax_override_enable && (
                            <Form.Item
                                className='pl-4 hide-control-input mb-0'
                                name={['merchant_config', 'tax_override_config']}
                                rules={[
                                    {
                                        required: merchantConfig?.tax_override_enable,
                                        validator: (_, value) => {
                                            if (!value || value.length === 0) {
                                                return Promise.reject('Please add at least one tax override')
                                            }
                                            return Promise.resolve()
                                        },
                                    },
                                ]}
                            />
                        )}
                    </Row>
                </Form>
            </Col>
            <Divider className='my-0' />
            <Col span={24}>
                <div className='flex flex-col gap-y-4'>
                    <p>Want to Display Taxes on Checkout Order Summary?</p>
                    <Link
                        to='/checkout/settings/customise-ui#myForm_merchant_config_show_gst_order_summary_cart'
                        state={{ activeTab: 'checkout-element' }}
                    >
                        <Button>
                            <img src={LinkImg} className='w-3 h-3 mr-2' alt='link icon' />
                            Configure Tax Settings
                        </Button>
                    </Link>
                </div>
            </Col>
        </Row>
    )
}

export default TaxConfig
