import { Divider, Input, Space, Row, Col, Button, Form, InputNumber } from 'gokwik-ui-kit'
import { CardLayout } from './cardLayout'
import React, { FC } from 'react'
import { DiscountFormState } from '../helpers/useCreateDiscount'

interface DiscountValueCardProps {
    formState?: DiscountFormState
}

const Card: FC<DiscountValueCardProps> = (props) => {
    const { formState } = props

    return (
        <CardLayout
            title='How should this discount function? Set discount values'
            subtitle='Specify the discount percentage to determine how much customers save, and set a maximum discount limit to ensure the promotion remains within your budget'
            // baselineExplainer='One liner here to tell the users the impact of this capping value'
        >
            <Form.List name={['rules']} initialValue={formState?.rules}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Row className={`mt-5`} gutter={12} key={key}>
                                <Col span={12}>
                                    <Form.Item
                                        {...restField}
                                        name={[0, 'actions', 0, 'value']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter the discount percentage.',
                                            },
                                            {
                                                validator: async (_, value) => {
                                                    if (value && (isNaN(value) || value > 100)) {
                                                        throw new Error(
                                                            'Please enter a percentage value between 1 and 100.',
                                                        )
                                                    }
                                                },
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            placeholder='Discount percentage'
                                            addonBefore='%'
                                            type='number'
                                            min={1}
                                            max={100}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        {...restField}
                                        name={[0, 'actions', 0, 'max_discount']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter the maximum discount limit.',
                                            },
                                            {
                                                validator: async (_, value) => {
                                                    if (value && (isNaN(value) || value < 1)) {
                                                        throw new Error('Please enter a value greater than 0.')
                                                    }
                                                },
                                            },
                                        ]}
                                    >
                                        <InputNumber placeholder='Maximum discount limit' addonBefore='₹' min={1} type='number' />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ))}
                    </>
                )}
            </Form.List>
        </CardLayout>
    )
}

export const DiscountValueCard = React.memo(Card)
