import { ColumnsType, Popover, Tooltip, message, CopyOutlined } from 'gokwik-ui-kit'
import { LineItem, Transaction } from '@library/utilities/interface'
import { convertIsoToLocaleString, titleCase } from '@gokwik/utilities'
import dayjs from 'dayjs'
import { MergedRepeatedRefund } from '.'
import { formatAmount } from '@library/utilities/helpers/helper'

const statusMappingForColors = {
    confirmed: 1,
    success: 1,
    completed: 1,
    pending: 2,
    true: 1,
    false: 3,
    cancelled: 3,
    initiated: 2,
    payment_initiated: 2,
    payment_failed: 3,
    failed: 3,
}

const renderAmount = (val) => <span className='font-medium'>₹ {(+(val || 0)).toFixed(2)}</span>
const renderStatus = (itm) => (
    <span
        className={
            statusMappingForColors[itm] === 1
                ? 'text-success-600'
                : statusMappingForColors[itm] === 2
                ? 'text-warning-500'
                : statusMappingForColors[itm] === 3
                ? 'text-error-700'
                : 'text-black'
        }
    >
        {titleCase(itm || '')}
    </span>
)

const handleCopy = (textToCopy) => {
    try {
        navigator.clipboard.writeText(textToCopy)
        message.success('URL Copied')
    } catch (err) {
        console.error('Unable to copy to clipboard', err)
        message.error('Failed to copy to clipboard')
    }
}

export const lineItemColumns: ColumnsType<any> = [
    {
        title: 'Product ID',
        dataIndex: 'product_id',
        key: 'product_id',
        width: 150,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'SKU',
        dataIndex: 'sku',
        key: 'sku',
        width: 150,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Product Name',
        dataIndex: 'p_name',
        key: 'p_name',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Qty',
        dataIndex: 'quantity',
        key: 'quantity',
        width: 80,
        align: 'right',
    },
    {
        title: 'Price',
        dataIndex: 'price',
        width: 100,
        key: 'price',
        align: 'right',
        render: (t, record) => <p>{formatAmount(t, 2)}</p>,
    },
    {
        title: 'Total',
        dataIndex: 'total',
        width: 100,
        align: 'right',
        key: 'total',
        render: (t, record) => <p>{formatAmount(Number(t), 2)}</p>,
    },
    {
        title: 'Status',
        dataIndex: 'order_status',
        key: 'order_status',
        render: renderStatus,
        width: 100,
    },
    {
        title: 'Awb Number',
        dataIndex: 'awb_number',
        key: 'awb_number',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {titleCase(name || '')}
            </Tooltip>
        ),
    },
    {
        title: 'Awb Status',
        dataIndex: 'awb_status',
        key: 'awb_status',
        width: 150,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Shipping Provider',
        dataIndex: 'shipping_provider',
        key: 'shipping_provider',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Product URL',
        dataIndex: 'product_url',
        key: 'product_url',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Product Thumbnail URL',
        dataIndex: 'product_thumbnail_url',
        key: 'product_thumbnail_url',
        width: 200,
        ellipsis: {
            showTitle: true,
        },
        render: (name, record: any) =>
            name || record.image ? (
                <Popover
                    placement='topLeft'
                    // title={}
                    content={
                        <img
                            className='max-w-full max-h-[200px] w-auto h-auto object-contain block mx-auto my-0'
                            src={name || record.image}
                        />
                    }
                >
                    <CopyOutlined onClick={() => handleCopy(name || record.image)} className='cursor-pointer' />{' '}
                    {name || record.image}
                </Popover>
            ) : null,
    },
    {
        title: 'Line Item ID',
        dataIndex: 'line_item_id',
        key: 'line_item_id',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Variant ID',
        dataIndex: 'variant_id',
        key: 'variant_id',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Notes',
        dataIndex: 'notes',
        key: 'variant_id',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
]

export const paymentColumns: ColumnsType<any> = [
    {
        title: 'Payment ID',
        dataIndex: 'payment_id',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        width: 100,
        align: 'right',
        render: (t, record) => <p>{formatAmount(t, 2)}</p>,
    },
    {
        title: 'Payment Method',
        dataIndex: 'payment_method',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Provider',
        dataIndex: 'payment_provider',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: renderStatus,
        width: 90,
    },
    {
        title: 'Auto Refund',
        dataIndex: 'is_auto_refund',
        render: (val) => `${val}`,
        width: 150,
    },
    {
        title: 'Is Deleted',
        dataIndex: 'is_deleted',
        render: (val) => `${val}`,
        width: 90,
    },
    {
        title: 'Link',
        dataIndex: 'upi_link',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Created Date',
        dataIndex: 'created_at',
        width: 200,
        defaultSortOrder: 'descend',
        sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
        render: (val) => convertIsoToLocaleString(val),
    },
]

export const refundColumns: ColumnsType<any> = [
    {
        title: 'Payment ID',
        dataIndex: 'payment_id',
        width: 250,
        ellipsis: {
            showTitle: false,
        },
        fixed: 'left',
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Reference ID',
        dataIndex: 'cust_ref_id',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Provider',
        dataIndex: 'payment_provider',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        width: 90,
        align: 'right',
        render: (t, record) => <p>{formatAmount(t, 2)}</p>,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        width: 160,
        render: (val: string) => renderStatus(val?.toLowerCase()),
    },
    {
        title: 'Auto Refund',
        dataIndex: 'is_auto_refund',
        width: 150,
        render: (val) => `${val}`,
    },
    {
        title: 'Created At',
        dataIndex: 'created_at',
        width: 200,
        defaultSortOrder: 'descend',
        sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
        render: (val) => convertIsoToLocaleString(val),
    },
    {
        title: 'Refunded At',
        dataIndex: 'refunded_at',
        width: 200,
        render: (val) => (val ? convertIsoToLocaleString(val) : '-'),
    },
    {
        title: 'Initiated By',
        dataIndex: 'initiator',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Refund Request Description',
        dataIndex: 'refund_request_description',
        width: 300,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Refund Request Doc',
        dataIndex: 'refund_request_file',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Attempts',
        dataIndex: 'attempts',
        width: 90,
        ellipsis: {
            showTitle: false,
        },
        align: 'right',
        fixed: 'right',
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
]

export const giftCards: ColumnsType<any> = [
    {
        title: 'Card',
        dataIndex: 'card',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Amount',
        dataIndex: 'amountUsed',
        align: 'right',
        width: 200,
        render: (t, record) => <p>{formatAmount(t, 2)}</p>,
    },
]

export const storeCredits: ColumnsType<any> = [
    {
        title: 'Provider',
        dataIndex: 'provider',
        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        align: 'right',
        width: 200,
        render: (t, record) => <p>{formatAmount(t, 2)}</p>,
    },
]
