import React, { FC, useEffect, useState } from 'react'
import { DiscountFormState } from '../helpers/useCreateDiscount'
import { CardLayout } from './cardLayout'
import { Checkbox, Form, InputNumber } from 'gokwik-ui-kit'

interface DiscountLimitPreventionCardProps {
    formState?: DiscountFormState
    discountAction?: any
}

const Card: FC<DiscountLimitPreventionCardProps> = (props) => {


    const { formState, discountAction } = props
    const [currentSelection, setCurrentSelection] = useState(['one_use_per_cx'])
 
    useEffect(() => {
        if (formState?.available_coupons && formState?.method !== 'bulk') {
            setCurrentSelection((prev) => [...prev, 'total_usage_limit'])
        }
    }, [formState])

    const handleChange = (value: string, checked: boolean) => {
         setCurrentSelection((prevSelection) => {
            if (checked && !prevSelection.includes(value)) {
                return [...prevSelection, value]
            } else if (!checked) {
                return prevSelection.filter((item) => item !== value)
            }
            return prevSelection
        })
    }

    return (
        <CardLayout title='Choose your discount limits to prevent customer abuse'>
            <br />

            <Form.Item name='max_discount_per_customer' valuePropName='checked'>
                <Checkbox
                    disabled={formState?.discount_category === 'bulk' || discountAction === 'view'}
                    // onChange={handleChange}
                    checked={
                        currentSelection?.includes('one_use_per_cx') ||
                        formState?.discount_category === 'bulk' ||
                        formState?.method === 'bulk'
                    }
                    className='my-2'
                    value={1}
                >
                    Limit to one use per customer
                </Checkbox>
            </Form.Item>
            {/* <Form.Item
                name='max_discount_per_customer'
                rules={[
                    {
                        validator: validateNonNegativeAndNonZeroNumbers,
                    },
                ]}
            >
                <Input
                    className='w-full'
                    disabled={
                        !currentSelection?.includes('one_use_per_cx') ||
                        formState?.discount_category === 'bulk' ||
                        discountAction === 'view'
                    }
                    type='number'
                    placeholder={formState?.discount_category === 'bulk' ? '1' : ''}
                />
            </Form.Item> */}

            <Form.Item name='is_total_usage_limit' valuePropName='checked'>
                <Checkbox
                    disabled={formState?.discount_category === 'bulk' || discountAction === 'view'}
                    className='my-2'
                    value={1}
                    checked={
                        currentSelection.includes('total_usage_limit') ||
                        formState?.method === 'bulk' ||
                        formState?.available_coupons > 0
                    }
                    onChange={(e) => handleChange('total_usage_limit', e.target.checked)}
                >
                    Limit number of times this discount can be used in total
                </Checkbox>
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
                {()=> 
                    currentSelection.includes('total_usage_limit') && 
                    <Form.Item
                        name='available_coupons'
                        rules={[
                            {
                                validator: async (_, condition) => {
                                    if (!condition || condition < 1) {
                                        return Promise.reject(new Error('value must be greater than 0'))
                                    } else return Promise.resolve()
                                },
                            },
                        ]}
                        initialValue={formState?.discount_category === 'bulk' ? 1 : undefined}
                        >
                        <InputNumber
                            className='w-full -mt-5'
                            placeholder=''
                            disabled={
                                !currentSelection?.includes('total_usage_limit') ||
                                formState?.discount_category === 'bulk' ||
                                discountAction === 'view'
                            }
                            min={1}
                        />
                    </Form.Item>
                }
            </Form.Item>
            {/* <Form.Item
                name='available_coupons'
                rules={[
                    {
                        validator: validateNonNegativeAndNonZeroNumbers,
                    },
                ]}
                initialValue={formState?.discount_category === 'bulk' ? 1 : undefined}
            >
                <InputNumber
                    className='w-full'
                    placeholder=''
                    disabled={
                        // !currentSelection?.includes('total_usage_limit') ||
                        formState?.discount_category === 'bulk' ||
                        discountAction === 'view'
                    }
                    min={1}
                />
            </Form.Item> */}
        </CardLayout>
    )
}

export const DiscountLimitPreventionCard = React.memo(Card)
