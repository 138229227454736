import {
    clickPostPayload,
    delhiveryPayload,
    ecomPayload,
    farziPayload,
    ga4CustomEvents,
    shiprocketPayload,
    snapchatEvents,
} from '@library/utilities/constants/constants'
import {
    Alert,
    Button,
    Card,
    Checkbox,
    ChipInput,
    Col,
    DeleteOutlined,
    Form,
    Input,
    InputNumber,
    message,
    QuestionCircleOutlined,
    Radio,
    Row,
    Select,
    Switch,
    TextArea,
    Tooltip,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'

const MaxRedeemableEasyRewardsTooltip = () => (
    <div className='flex flex-col'>
        <span className='text-sm font-semibold'>Max redeemable credit should contain any one of the following:</span>
        <ol className='list-decimal list-inside pl-2'>
            <li> An integer value like: 100,200 in case of amount based capping.</li>
            <li>It should be in percentage like: 10%,20% in case of % based capping on cart value.</li>
            <li>It should be in this format if (10%,100) based capping upto a certain amount has to be implemented.</li>
            <li>It should be blank if we need to show overall available credits to the user.</li>
        </ol>
    </div>
)

const RenderForm = ({
    form,
    fieldKey,
    resetFields,
}: {
    form: any
    fieldKey: string
    resetFields?: boolean //added to reset forms on EDD and shipping page to use initial values
}) => {
    const customConfig = Form.useWatch('custom_config', form)
    const merchantConfig = Form.useWatch('merchant_config', form)
    const [chips, setChips] = useState([])

    const pincodeConfigData = {
        options: [
            { label: 'With country code (+91)', value: '+91' },
            { label: 'With country code (91)', value: '91' },
            { label: 'Without country code', value: '' },
        ],
        ignoreMandatory: true,
        rules: [
            {
                required: true,
                validator: (_, value) =>
                    value === '' || value
                        ? Promise.resolve()
                        : Promise.reject(new Error('Please select a phone format')),
            },
        ],
    }

    const loyaltyInputs = [
        {
            type: 'switch',
            name: ['custom_config', 'enable_cod_with_loyalty'],
            label: (
                <>
                    Allow Cash on Delivery
                    <Tooltip title='Enabling this config allows customers to place cash on delivery orders if loyalty point is already applied.'>
                        <QuestionCircleOutlined className='ml-1' />
                    </Tooltip>
                </>
            ),
            optional: true,
            span: 24,
        },
        {
            type: 'switch',
            name: ['custom_config', 'enable_discount_with_credits'],
            label: (
                <>
                    Allow Stacking with Manual Discount Codes
                    <Tooltip title='Enabling this config allows tiered prepaid discounts to be stack able with loyalty points.'>
                        <QuestionCircleOutlined className='ml-1' />
                    </Tooltip>
                </>
            ),
            span: 24,
            optional: true,
        },
        {
            type: 'switch',
            name: ['custom_config', 'enable_auto_apply_credits'],
            hidden: !customConfig?.enable_discount_with_credits,
            label: (
                <>
                    Allow Auto Application on Checkout
                    <Tooltip title=''>
                        <QuestionCircleOutlined className='ml-1' />
                    </Tooltip>
                </>
            ),
            span: 24,
            optional: true,
        },
        {
            type: 'input',
            name: ['custom_config', 'loyalty_display_name'],
            label: (
                <>
                    Show Custom Name for Reward Points
                    <Tooltip title='The custom name provided will show on the checkout UI to the user instead of standard name "Reward points". You can use this to whitelist the loyalty program at your end, eg "Kwik Coins".'>
                        <QuestionCircleOutlined />
                    </Tooltip>
                </>
            ),
            span: 24,
            inputProps: {
                suffix: `${customConfig?.loyalty_display_name?.length || 0} /16`,
                maxLength: 16,
            },
            optional: true,
        },
    ]

    const rewardInputs = [
        {
            type: 'switch',
            name: ['merchant_config', 'enable_cod_with_gift_card'],
            label: (
                <>
                    Allow Cash on Delivery
                    <Tooltip title='Enabling this config allows customers to place cash on delivery orders if gift card is applied.'>
                        <QuestionCircleOutlined />
                    </Tooltip>
                </>
            ),
            optional: true,
            span: 24,
        },
        {
            type: 'switch',
            name: ['custom_config', 'enable_prepaid_discount_with_gift_card'],
            label: (
                <>
                    Allow Prepaid Discounts
                    <Tooltip title='Enabling this config allows tiered prepaid discounts to be stack able with gift card.'>
                        <QuestionCircleOutlined />
                    </Tooltip>
                </>
            ),
            optional: true,
            span: 24,
        },
        {
            type: 'switch',
            name: ['custom_config', 'enable_discount_with_giftcard'],
            label: (
                <>
                    Allow Stacking with Manual Discount Codes
                    <Tooltip title='Enabling this config allows manual discount codes to be stack able with gift card.'>
                        <QuestionCircleOutlined />
                    </Tooltip>
                </>
            ),
            optional: true,
            span: 24,
        },
    ]

    const fields = {
        fb_pixels: {
            fields: [
                {
                    type: 'checkbox',
                    name: ['custom_config', 'pixels'],
                    label: 'Select events to be tracked',
                    options: [
                        {
                            label: 'Initiate Checkout',
                            value: 'fb_initiate_checkout',
                        },
                        {
                            label: 'Add Payment Info',
                            value: 'fb_add_payment_info',
                        },
                        {
                            label: 'Purchase',
                            value: 'fb_purchase',
                        },
                    ],
                    span: 24,
                    rules: [
                        {
                            validator: (_, value) =>
                                value.some((item: string) => item.startsWith?.('fb'))
                                    ? Promise.resolve()
                                    : Promise.reject(new Error('Please select at least one event')),
                        },
                    ],
                },
                ,
                {
                    span: 24,
                    customRender: () => (
                        <Form.List name='fb_pixels'>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }, index) => (
                                        <Card
                                            key={key}
                                            title={`Pixel ${index + 1}`}
                                            size='small'
                                            className={`w-full ${index > 0 ? 'mt-4' : ''}`}
                                            extra={
                                                fields.length > 1 && (
                                                    <Button
                                                        type='text'
                                                        shape='circle'
                                                        icon={
                                                            <DeleteOutlined className='focus:outline-none text-red-500' />
                                                        }
                                                        onClick={() => remove(name)}
                                                        children={''}
                                                    />
                                                )
                                            }
                                        >
                                            <Form.Item
                                                {...restField}
                                                label={`Pixel ID`}
                                                className='label-bold'
                                                name={[name, 'pixelId']}
                                                rules={[{ required: true, message: 'Please enter pixel id' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                label='Access Token'
                                                className='label-bold mt-4'
                                                name={[name, 'access_token']}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Card>
                                    ))}
                                    <Button
                                        type='link'
                                        className='text-blue-500 outline-none focus:outline-none p-0'
                                        onClick={() => add()}
                                    >
                                        + Add Another Pixel
                                    </Button>
                                </>
                            )}
                        </Form.List>
                    ),
                },
            ],
            initialValues: {
                meta: [{ id: '', access_token: '' }],
                custom_config: {
                    pixels: ['fb_initiate_checkout', 'fb_add_payment_info', 'fb_purchase'],
                },
            },
        },
        Snapchat: {
            fields: [
                {
                    type: 'checkbox',
                    name: ['custom_config', 'pixels'],
                    label: 'Select events to be tracked',
                    options: Object.values(snapchatEvents).map((event) => ({
                        label: event.replace('snapchat_', ''),
                        value: event,
                    })),
                    span: 24,
                    rules: [
                        {
                            validator: (_, value) =>
                                value.some((item: string) => item.startsWith?.('snapchat'))
                                    ? Promise.resolve()
                                    : Promise.reject(new Error('Please select at least one event')),
                        },
                    ],
                },

                {
                    span: 24,
                    customRender: () => (
                        <Form.List name='snapchat'>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }, index) => (
                                        <Card
                                            key={key}
                                            title={`Pixel ${index + 1}`}
                                            size='small'
                                            className={`w-full ${index > 0 ? 'mt-4' : ''}`}
                                            extra={
                                                fields.length > 1 && (
                                                    <Button
                                                        type='text'
                                                        shape='circle'
                                                        icon={
                                                            <DeleteOutlined className='focus:outline-none text-red-500' />
                                                        }
                                                        onClick={() => remove(name)}
                                                        children={''}
                                                    />
                                                )
                                            }
                                        >
                                            <Form.Item
                                                {...restField}
                                                label={`Pixel ID`}
                                                className='label-bold'
                                                name={[name, 'pixelId']}
                                                rules={[{ required: true, message: 'Please enter pixel id' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                className='label-bold mt-4'
                                                label='Access Token'
                                                name={[name, 'access_token']}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Card>
                                    ))}
                                    <Button
                                        type='link'
                                        className='text-blue-500 outline-none focus:outline-none p-0'
                                        onClick={() => add()}
                                    >
                                        + Add Another Pixel
                                    </Button>
                                </>
                            )}
                        </Form.List>
                    ),
                },
            ],

            initialValues: {
                snapchat: [{ id: '', access_token: '' }],
                custom_config: {
                    pixels: Object.values(snapchatEvents),
                },
            },
        },
        edgeTag: {
            fields: [
                {
                    type: 'radio',
                    name: ['custom_config', 'analytics_pixel_data', 'edgeTag', 'value'],
                    label: 'Identity Type',
                    span: 24,
                    options: [
                        {
                            label: 'Phone',
                            value: 'phone',
                        },
                        {
                            label: 'E-mail address',
                            value: 'email',
                        },
                        {
                            label: 'Shopify Customer ID',
                            value: 'customer_id',
                        },
                    ],
                },
                {
                    type: 'radio',
                    label: 'Phone Number Format',
                    name: ['custom_config', 'analytics_pixel_data', 'edgeTag', 'phone_format'],
                    span: 24,
                    hidden: customConfig?.analytics_pixel_data?.edgeTag?.value !== 'phone',
                    ...pincodeConfigData,
                },
            ],
        },
        magic_pixel: {
            fields: [
                {
                    type: 'input',
                    name: ['magic_pixel', 'envId'],
                    label: 'Env Id',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['magic_pixel', 'envName'],
                    label: 'Env Name',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['magic_pixel', 'xApiKey'],
                    label: 'Api Key',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['magic_pixel', 'vendorId'],
                    label: 'Vendor ID',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['magic_pixel', 'projectId'],
                    label: 'Project ID',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['magic_pixel', 'xClientId'],
                    label: 'xClient ID',
                    span: 24,
                },
            ],
        },
        ga4: {
            fields: [
                {
                    type: 'input',
                    name: ['custom_config', 'ga4Id'],
                    label: 'Measurement ID',
                    span: 24,
                    inputProps: {
                        prefix: 'G-',
                    },
                },
                {
                    type: 'switch',
                    name: ['custom_config', 'is_ga4S2s_enabled'],
                    label: (
                        <>
                            Enable Server Side Events{' '}
                            <Tooltip
                                title={
                                    'Enabling server side events ensure higher events accuracy as compared to client side tracking for all checkout related events.'
                                }
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </>
                    ),
                    span: 24,
                    inputProps: {
                        onChange: (value) => {
                            const ga4_pixels = form.getFieldValue('ga4_pixels')
                            if (!ga4_pixels?.ga4_custom?.length) {
                                form.setFieldsValue({
                                    ga4_pixels: {
                                        ...ga4_pixels,
                                        ga4_custom: value
                                            ? ga4CustomEvents.filter((pixel, index) =>
                                                  [
                                                      'gokwik_checkout_initiated',
                                                      'coupon_applied_success',
                                                      'coupon_applied_failed',
                                                  ].includes(pixel),
                                              )
                                            : [...ga4CustomEvents],
                                    },
                                })
                            } else if (value && ga4_pixels?.ga4_custom?.length) {
                                form.setFieldsValue({
                                    ga4_pixels: {
                                        ...ga4_pixels,
                                        ga4_custom: ga4_pixels.ga4_custom.filter((pixel, index) =>
                                            [
                                                'gokwik_checkout_initiated',
                                                'coupon_applied_success',
                                                'coupon_applied_failed',
                                            ].includes(pixel),
                                        ),
                                    },
                                })
                            } else if (!value && ga4_pixels?.ga4_custom?.length) {
                                form.setFieldsValue({
                                    ga4_pixels: {
                                        ...ga4_pixels,
                                        ga4_custom: [...ga4CustomEvents],
                                    },
                                })
                            }
                        },
                    },

                    optional: true,
                },
                {
                    type: 'input',
                    name: ['ga4', 'credentials', 'api_secret'],
                    label: 'API Secret Key',
                    span: 24,
                    hidden: !customConfig?.is_ga4S2s_enabled,
                },
                {
                    type: 'checkbox',
                    name: ['ga4_pixels', 'ga4_custom'],
                    label: 'Custom GoKwik Events',
                    span: 24,
                    className: 'grid grid-cols-2 gap-2',
                    options: ga4CustomEvents.map((event) => ({
                        label:
                            event === 'gokwik_checkout_initiated' ? (
                                <span className='flex'>
                                    {event}{' '}
                                    <Tooltip title='This event helps bifurcate gokwik checkout initiations from all checkout initiations in case of multiple checkouts available on a store or in event of A/B with other checkout.'>
                                        {' '}
                                        <QuestionCircleOutlined />{' '}
                                    </Tooltip>{' '}
                                </span>
                            ) : (
                                event
                            ),
                        value: event,
                        disabled:
                            event === 'gokwik_checkout_initiated' ||
                            (!event.includes('coupon_applied') && customConfig?.is_ga4S2s_enabled),
                    })),
                },
                {
                    type: 'checkbox',
                    name: ['ga4_pixels', 'ga4'],
                    label: 'Standard Events',
                    className: 'grid grid-cols-2 gap-2',
                    span: 24,
                    options: ['begin_checkout', 'add_shipping_info', 'add_payment_info', 'purchase'],
                },
            ],
            initialValues: {
                ga4: {
                    ga4_pixels: {
                        ga4_custom: [
                            'gokwik_checkout_initiated',
                            'order_summary_clicked_gk',
                            'mobile_number_entered_gk',
                            'otp_sent_gk',
                            'otp_entered_gk',
                            'otp_verified_gk',
                            'edit_address_clicked_gk',
                            'edit_mobile_clicked_gk',
                            'add_address_page_landed_gk',
                            'add_address_continue_gk',
                            'coupon_list_viewed',
                            'coupon_applied_success',
                            'coupon_applied_failed',
                        ],
                        ga4: ['begin_checkout', 'add_shipping_info', 'add_payment_info', 'purchase'],
                    },
                },
            },
        },
        gyftr: {
            fields: [
                {
                    type: 'input',
                    name: ['gyftr', 'username'],
                    label: 'User Name',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['gyftr', 'password'],
                    label: 'Password',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['gyftr', 'terminal_id'],
                    label: 'Terminal ID',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['gyftr', 'brand_key'],
                    label: 'Brand Key',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['gyftr', 'crypt_key'],
                    label: 'Crypt Key',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['gyftr', 'crypt_iv'],
                    label: 'Crypt IV',
                    span: 24,
                },
                ...rewardInputs,
            ],
        },
        store_credits: {
            fields: [
                {
                    type: 'inputNumber',
                    name: ['merchant_config', 'store_credits', 'shopify', 'maximum_redeem_amount'],
                    label: (
                        <>
                            Max Redeemable Credit
                            <Tooltip title='This setting allows you to cap the maximum redeemable credit on a single order at a time by the user.'>
                                <QuestionCircleOutlined className='ml-1' />
                            </Tooltip>
                        </>
                    ),
                    inputProps: {
                        min: 0,
                    },
                    optional: true,
                    span: 24,
                },
            ],
        },
        giftCard: {
            fields: [
                ...rewardInputs,
                {
                    type: 'alert',
                    text: 'Please ensure that gift card adjustment permissions are enabled for Gokwikcheckout app on your store. Know More',
                },
            ],
        },
        qwik_cilver: {
            fields: [
                {
                    type: 'input',
                    name: ['qwik_cilver', 'username'],
                    label: 'User Name',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['qwik_cilver', 'password'],
                    label: 'Password',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['qwik_cilver', 'terminal_id'],
                    label: 'Terminal ID',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['qwik_cilver', 'api_url'],
                    label: 'API URL',
                    span: 24,
                },

                ...rewardInputs,
            ],
        },
        custom_fit: {
            fields: [
                {
                    type: 'input',
                    name: ['custom_fit', 'url'],
                    label: 'URL',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['custom_fit', 'authKey'],
                    label: 'Auth Key',
                    span: 24,
                },
            ],
        },
        clickpost_edd: {
            fields: [
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'edd', 'apiCall', 'url'],
                    label: 'API URL',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'edd', 'apiCall', 'payload', 'pickup_pincode'],
                    label: 'Pickup Pincode',
                    span: 24,
                },
                {
                    type: 'alert',
                    text: 'You need to be onboarded with the selected partner and ensure that you have an active API account',
                },
                {
                    type: 'alert',
                    text: 'You can setup static EDD powered by Gokwik',
                },
            ],
            initialValues: {
                pincode_serviceability: { edd: { ...clickPostPayload().edd } },
            },
        },
        clickpost_serviceability: {
            fields: [
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'serviceability', 'apiCall', 'url'],
                    label: 'API URL',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'serviceability', 'apiCall', 'payload', 'pickup_pincode'],
                    label: 'Pickup Pincode',
                    span: 24,
                },
                {
                    type: 'alert',
                    text: 'You need to be onboarded with the selected partner and ensure that you have an active API account',
                },
                {
                    type: 'alert',
                    text: 'You can setup Pincode Serviceability powered by Gokwik',
                },
            ],
            initialValues: {
                pincode_serviceability: { serviceability: { ...clickPostPayload().serviceability } },
            },
        },
        shiprocket_edd: {
            fields: [
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'edd', 'apiCall', 'url'],
                    label: 'API URL',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'edd', 'apiCall', 'payload', 'store_url'],
                    label: 'Store URL',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'edd', 'apiCall', 'payload', 'company_id'],

                    label: 'Company ID',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'edd', 'apiCall', 'payload', 'channel_code'],

                    label: 'Channel Code',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'edd', 'vendor_name'],

                    label: 'Vendor Name',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'edd', 'tokenCall', 'url'],

                    label: 'Token URL',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'edd', 'tokenCall', 'payload', 'email'],

                    label: 'Token Email',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'edd', 'tokenCall', 'payload', 'password'],
                    label: 'Token Password',
                    span: 12,
                },
                {
                    type: 'alert',
                    text: 'You need to be onboarded with the selected partner and ensure that you have an active API account',
                },
                {
                    type: 'alert',
                    text: 'You can setup static EDD powered by Gokwik',
                },
            ],
            initialValues: {
                pincode_serviceability: {
                    edd: { ...shiprocketPayload().edd },
                },
            },
        },
        shiprocket_serviceability: {
            fields: [
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'serviceability', 'apiCall', 'url'],
                    label: 'API URL',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'serviceability', 'apiCall', 'payload', 'weight'],
                    label: 'Weight',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'serviceability', 'apiCall', 'payload', 'pickup_postcode'],

                    label: 'Pickup Postcode',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'serviceability', 'vendor_name'],

                    label: 'Vendor Name',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'serviceability', 'tokenCall', 'url'],

                    label: 'Token URL',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'serviceability', 'tokenCall', 'payload', 'email'],

                    label: 'Token Email',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'serviceability', 'tokenCall', 'payload', 'password'],
                    label: 'Token Password',
                    span: 12,
                },
                {
                    type: 'alert',
                    text: 'You need to be onboarded with the selected partner and ensure that you have an active API account',
                },
                {
                    type: 'alert',
                    text: 'You can setup Pincode Serviceability powered by Gokwik',
                },
            ],
            initialValues: {
                pincode_serviceability: {
                    serviceability: { ...shiprocketPayload().serviceability },
                },
            },
        },
        customUrl_edd: {
            fields: [
                {
                    type: 'textArea',
                    name: ['pincode_serviceability', 'edd'],
                    label: 'Code Snippet',
                    rules: [
                        {
                            validator: (_, value) => {
                                try {
                                    JSON.parse(value)
                                    return Promise.resolve()
                                } catch (e) {
                                    return Promise.reject(new Error('Please enter a valid JSON'))
                                }
                            },
                        },
                    ],
                    span: 24,
                },
                {
                    type: 'alert',
                    text: 'You need to be onboarded with the selected partner and ensure that you have an active API account',
                },
                {
                    type: 'alert',
                    text: 'You can setup static EDD powered by Gokwik',
                },
            ],
        },
        customUrl_serviceability: {
            fields: [
                {
                    type: 'textArea',
                    name: ['pincode_serviceability', 'serviceability'],
                    label: 'Code Snippet',
                    rules: [
                        {
                            validator: (_, value) => {
                                try {
                                    JSON.parse(value)
                                    return Promise.resolve()
                                } catch (e) {
                                    return Promise.reject(new Error('Please enter a valid JSON'))
                                }
                            },
                        },
                    ],
                    span: 24,
                },
                {
                    type: 'alert',
                    text: 'You need to be onboarded with the selected partner and ensure that you have an active API account',
                },
                {
                    type: 'alert',
                    text: 'You can setup pincode serviceability powered by Gokwik',
                },
            ],
        },
        delhivery: {
            fields: [
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'serviceability', 'apiCall', 'url'],

                    label: 'URL',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'serviceability', 'apiCall', 'payload', 'token'],

                    label: 'Token',
                    span: 24,
                },
                {
                    type: 'alert',
                    text: 'You need to be onboarded with the selected partner and ensure that you have an active API account',
                },
                {
                    type: 'alert',
                    text: 'You can setup pincode serviceability powered by Gokwik',
                },
            ],
            initialValues: {
                pincode_serviceability: {
                    serviceability: {
                        ...delhiveryPayload().serviceability,
                    },
                },
            },
        },
        ecom: {
            fields: [
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'serviceability', 'apiCall', 'url'],

                    label: 'URL',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'serviceability', 'apiCall', 'payload', 'username'],

                    label: 'Username',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'serviceability', 'apiCall', 'payload', 'password'],

                    label: 'Password',
                    span: 12,
                },
                {
                    type: 'alert',
                    text: 'You need to be onboarded with the selected partner and ensure that you have an active API account',
                },
                {
                    type: 'alert',
                    text: 'You can setup pincode serviceability powered by Gokwik',
                },
            ],
            initialValues: {
                pincode_serviceability: {
                    serviceability: {
                        ...ecomPayload().serviceability,
                    },
                },
            },
        },
        farzi: {
            fields: [
                {
                    type: 'input',
                    name: ['pincode_serviceability', 'serviceability', 'apiCall', 'url'],
                    label: 'URL',
                    span: 24,
                },
                {
                    type: 'alert',
                    text: 'You need to be onboarded with the selected partner and ensure that you have an active API account',
                },
                {
                    type: 'alert',
                    text: 'You can setup pincode serviceability powered by Gokwik',
                },
            ],
            initialValues: {
                pincode_serviceability: {
                    serviceability: {
                        ...farziPayload().serviceability,
                    },
                },
            },
        },
        easy_rewards_coupon: {
            fields: [
                {
                    type: 'input',
                    name: ['easy_rewards_coupon', 'AppId'],
                    label: 'App ID',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['easy_rewards_coupon', 'DevId'],
                    label: 'Dev ID',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['easy_rewards_coupon', 'baseUrl'],
                    label: 'Base URL',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['easy_rewards_coupon', 'UserName'],
                    label: 'Username',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['easy_rewards_coupon', 'StoreCode'],
                    label: 'Store Code',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['easy_rewards_coupon', 'CountryCode'],
                    label: 'Country Code',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['easy_rewards_coupon', 'ProgramCode'],
                    label: 'Program Code',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['easy_rewards_coupon', 'ActivityCode'],
                    label: 'Activity Code',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['easy_rewards_coupon', 'UserPassword'],
                    label: 'User Password',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['easy_rewards_coupon', 'TokenUserName'],
                    label: 'Token Username',
                    span: 24,
                },
                {
                    type: 'alert',
                    text: 'Easy reward coupons are user specific which are discoverable on Gokwikcheckout once user is logged in. The discount is tagged as "Specially for you" view offers section for better UX',
                },
            ],
        },
        farzi_api: {
            fields: [
                {
                    type: 'input',
                    name: ['farzi_api', 'url'],
                    label: 'URL',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['farzi_api', 'client'],
                    label: 'Client Name',
                    span: 24,
                },
                {
                    type: 'alert',
                    text: 'You can setup bulk discount codes powered by Gokwik',
                },
            ],
        },
        marmeto_api: {
            fields: [
                {
                    type: 'input',
                    name: ['marmeto_api', 'url'],
                    label: 'URL',
                    span: 24,
                },
                {
                    type: 'chip',
                    name: ['merchant_config', 'bulk_discount_config', 'marmeto_prefixes'],
                    label: 'Discount Prefixes',
                    span: 24,
                    rules: [
                        {
                            validator: (_, value) =>
                                value?.length > 0
                                    ? Promise.resolve()
                                    : Promise.reject(new Error('Please enter at least one prefix')),
                        },
                    ],
                },
                {
                    type: 'alert',
                    text: 'You can setup bulk discount codes powered by Gokwik',
                },
            ],
        },
        pop_coins: {
            fields: [
                {
                    type: 'input',
                    name: ['pop_coins', 'token'],
                    label: 'Token',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['pop_coins', 'base_url'],
                    label: 'Base URL',
                    span: 24,
                    inputProps: {
                        disabled: true,
                    },
                },
                {
                    type: 'inputNumber',
                    name: ['pop_coins', 'max_redeemable_amount'],
                    label: (
                        <>
                            Max Redeemable Credit
                            <Tooltip title='This setting allows you to cap the maximum redeemable credit on a single order at a time by the user.'>
                                <QuestionCircleOutlined className='ml-1' />
                            </Tooltip>
                        </>
                    ),
                    inputProps: {
                        min: 0,
                    },
                    optional: true,
                    span: 24,
                },

                {
                    type: 'input',
                    name: ['custom_config', 'enable_auto_apply_credits'],
                    label: 'Allow Auto Application on Checkout',
                    span: 24,
                    hidden: customConfig?.disabled_discount_credits != true,
                },
                ...loyaltyInputs,
            ],
            initialValues: {
                pop_coins: {
                    base_url: 'https://prodreplica.mypopcoins.com',
                },
            },
        },
        xeno: {
            fields: [
                {
                    type: 'input',
                    name: ['xeno', 'api_key'],
                    label: 'API Key',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['xeno', 'store_key'],
                    label: 'Store Key',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['xeno', 'base_url'],
                    label: 'Base URL',
                    span: 24,
                },
                {
                    type: 'inputNumber',
                    name: ['xeno', 'max_redeemable_amount'],
                    label: (
                        <>
                            Max Redeemable Credit
                            <Tooltip title='This setting allows you to cap the maximum redeemable credit on a single order at a time by the user.'>
                                <QuestionCircleOutlined className='ml-1' />
                            </Tooltip>
                        </>
                    ),
                    inputProps: {
                        min: 0,
                    },
                    optional: true,
                    span: 24,
                },
                ...loyaltyInputs,
            ],
        },
        yotpo: {
            fields: [
                {
                    type: 'input',
                    name: ['yotpo', 'xGuid'],
                    label: 'x GUID',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['yotpo', 'xApiKey'],
                    label: 'x API Key',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['yotpo', 'base_url'],
                    label: 'Base URL',
                    span: 24,
                },
                {
                    type: 'inputNumber',
                    name: ['yotpo', 'max_redeemable_amount'],
                    label: (
                        <>
                            Max Redeemable Credit
                            <Tooltip title='This setting allows you to cap the maximum redeemable credit on a single order at a time by the user.'>
                                <QuestionCircleOutlined className='ml-1' />
                            </Tooltip>
                        </>
                    ),
                    inputProps: {
                        min: 0,
                    },
                    optional: true,
                    span: 24,
                },
                ...loyaltyInputs,
            ],
        },
        flits: {
            fields: [
                {
                    type: 'input',
                    name: ['flits', 'token'],
                    label: 'Token',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['flits', 'user_id'],
                    label: 'User ID',
                    span: 12,
                    rules: [
                        {
                            validator: (_, value) =>
                                value && !/^\d+$/.test(value)
                                    ? Promise.reject(new Error('User ID should only contain numbers'))
                                    : Promise.resolve(),
                        },
                    ],
                },
                {
                    type: 'select',
                    name: ['flits', 'credit_strategy'],
                    label: 'Credit Strategy',
                    span: 24,
                    options: [
                        {
                            label: 'Prioritise Coins',
                            value: 'prioritise_coins',
                        },
                        {
                            label: 'Prioritise Credits',
                            value: 'prioritise_credits',
                        },
                        {
                            label: 'Coins and Credits',
                            value: 'coins_and_credits',
                        },
                        {
                            label: 'Max',
                            value: 'max',
                        },
                    ],
                },
                ...loyaltyInputs,
            ],
        },
        credit_yard: {
            fields: [
                {
                    type: 'input',
                    name: ['credit_yard', 'token'],
                    label: 'Token',
                    span: 24,
                },
                ...loyaltyInputs,
            ],
        },
        nector: {
            fields: [
                {
                    type: 'input',
                    name: ['nector', 'webhookId'],
                    label: 'Webhook ID',
                    span: 24,
                },
                {
                    type: 'select',
                    name: ['nector', 'walletType'],
                    label: 'Wallet Type',
                    options: [
                        {
                            label: 'Coins',
                            value: 'coins',
                        },
                        {
                            label: 'Credits',
                            value: 'credits',
                        },
                        {
                            label: 'Coins and Credits',
                            value: 'coins_and_credits',
                        },
                    ],
                    span: 24,
                },
                ...loyaltyInputs,
            ],
            initialValues: {
                nector: {
                    walletType: 'coins',
                },
            },
        },
        easy_rewardz: {
            fields: [
                {
                    type: 'input',
                    name: ['easy_rewardz', 'AppId'],
                    label: 'App ID',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['easy_rewardz', 'DevId'],
                    label: 'Dev ID',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['easy_rewardz', 'baseUrl'],
                    label: 'Base URL',
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['easy_rewardz', 'UserName'],
                    label: 'Username',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['easy_rewardz', 'StoreCode'],
                    label: 'Store Code',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['easy_rewardz', 'CountryCode'],
                    label: 'Country Code',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['easy_rewardz', 'ProgramCode'],
                    label: 'Program Code',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['easy_rewardz', 'ActivityCode'],
                    label: 'Activity Code',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['easy_rewardz', 'UserPassword'],
                    label: 'User Password',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['easy_rewardz', 'TokenUserName'],
                    label: 'Token Username',
                    span: 12,
                },
                {
                    type: 'input',
                    name: ['easy_rewardz', 'max_redeemable_credit'],
                    label: (
                        <>
                            Max Redeemable Credit
                            <Tooltip title={<MaxRedeemableEasyRewardsTooltip />}>
                                <QuestionCircleOutlined className='ml-1' />
                            </Tooltip>
                        </>
                    ),
                    span: 12,
                },
                {
                    type: 'switch',
                    name: ['merchant_config', 'enable_cod_with_gift_card'],
                    optional: true,
                    label: (
                        <>
                            Allow Cash on Delivery
                            <Tooltip title=''>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </>
                    ),
                    span: 24,
                },
                {
                    type: 'switch',
                    optional: true,
                    name: ['custom_config', 'enable_prepaid_discount_with_gift_card'],
                    label: (
                        <>
                            Allow Prepaid Discounts
                            <Tooltip title=''>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </>
                    ),
                    span: 24,
                },
                {
                    type: 'switch',
                    optional: true,
                    name: ['custom_config', 'enable_discount_with_giftcard'],
                    label: (
                        <>
                            Allow Stacking with Manual Discount Codes
                            <Tooltip title=''>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </>
                    ),
                    span: 24,
                },
                {
                    ...loyaltyInputs[3],
                },
                {
                    type: 'alert',
                    text: 'Easy Rewards is redeemed in form of gift card - please ensure that gift card adjustment permissions are enabled for your store',
                },
                {
                    type: 'alert',
                    text: 'Easy Rewards is only applicable for Shopify Plus brands',
                },
            ],
        },
        netcore: {
            fields: [
                {
                    type: 'radio',
                    name: ['custom_config', 'analytics_pixel_data', 'netcore', 'value'],
                    label: 'Identity Type',
                    span: 24,
                    options: [
                        {
                            label: 'Phone',
                            value: 'phone',
                        },
                        {
                            label: 'E-mail address',
                            value: 'email',
                        },
                        {
                            label: 'Shopify Customer ID',
                            value: 'customer_id',
                        },
                    ],
                },
                {
                    type: 'radio',
                    name: ['custom_config', 'analytics_pixel_data', 'netcore', 'phone_format'],
                    label: 'Phone Number Format',
                    span: 24,
                    hidden: customConfig?.analytics_pixel_data?.netcore?.value !== 'phone',
                    ...pincodeConfigData,
                },
            ],
        },
        Moengage: {
            fields: [
                {
                    type: 'radio',
                    name: ['custom_config', 'analytics_pixel_data', 'moengage', 'value'],
                    label: 'Identity Type',
                    span: 24,
                    options: [
                        {
                            label: 'Phone',
                            value: 'phone',
                        },
                        {
                            label: 'E-mail address',
                            value: 'email',
                        },
                        {
                            label: 'Shopify Customer ID',
                            value: 'customer_id',
                        },
                    ],
                },
                {
                    type: 'radio',
                    name: ['custom_config', 'analytics_pixel_data', 'moengage', 'phone_format'],
                    label: 'Phone Number Format',
                    span: 24,
                    hidden: customConfig?.analytics_pixel_data?.moengage?.value !== 'phone',
                    ...pincodeConfigData,
                },
            ],
        },
        webengage: {
            fields: [
                {
                    type: 'radio',
                    name: ['custom_config', 'analytics_pixel_data', 'webengage', 'value'],
                    label: 'Identity Type',
                    span: 24,
                    options: [
                        {
                            label: 'Phone',
                            value: 'phone',
                        },
                        {
                            label: 'E-mail address',
                            value: 'email',
                        },
                        {
                            label: 'Shopify Customer ID',
                            value: 'customer_id',
                        },
                    ],
                },
                {
                    type: 'radio',
                    name: ['custom_config', 'analytics_pixel_data', 'webengage', 'phone_format'],
                    label: 'Phone Number Format',
                    span: 24,
                    hidden: customConfig?.analytics_pixel_data?.webengage?.value !== 'phone',
                    ...pincodeConfigData,
                },
            ],
        },
        growlytics: {
            fields: [
                {
                    type: 'radio',
                    name: ['custom_config', 'analytics_pixel_data', 'growlytics', 'value'],
                    label: 'Identity Type',
                    span: 24,
                    options: [
                        {
                            label: 'Phone',
                            value: 'phone',
                        },
                        {
                            label: 'E-mail address',
                            value: 'email',
                        },
                        {
                            label: 'Shopify Customer ID',
                            value: 'customer_id',
                        },
                    ],
                },
                {
                    type: 'radio',
                    name: ['custom_config', 'analytics_pixel_data', 'growlytics', 'phone_format'],
                    label: 'Phone Number Format',
                    span: 24,
                    hidden: customConfig?.analytics_pixel_data?.growlytics?.value !== 'phone',
                    ...pincodeConfigData,
                },
            ],
        },
        bik: {
            fields: [
                {
                    type: 'input',
                    name: ['bik', 'api_secret'],
                    label: 'API Secret Key',
                    span: 24,
                    inputProps: {
                        prefix: 'Basic ',
                    },
                },
            ],
        },
        omnisend: {
            fields: [{ type: 'input', name: ['omnisend', 'apiKey'], label: 'API Key', span: 24 }],
        },
        clevertap: {
            fields: [
                {
                    type: 'radio',
                    name: ['custom_config', 'analytics_pixel_data', 'clevertap', 'value'],
                    label: 'Identity Type',
                    span: 24,
                    options: [
                        {
                            label: 'Phone',
                            value: 'phone',
                        },
                        {
                            label: 'E-mail address',
                            value: 'email',
                        },
                        {
                            label: 'Shopify Customer ID',
                            value: 'customer_id',
                        },
                    ],
                },
                {
                    type: 'radio',
                    name: ['custom_config', 'analytics_pixel_data', 'clevertap', 'phone_format'],
                    label: 'Phone Number Format',
                    span: 24,
                    hidden: customConfig?.analytics_pixel_data?.clevertap?.value !== 'phone',
                    ...pincodeConfigData,
                },
                {
                    type: 'switch',
                    name: [
                        'custom_config',
                        'analytics_pixel_data',
                        'clevertap',
                        'split_product_array_to_multiple_events',
                    ],
                    label: (
                        <>
                            Split Product Arrays into Multiple Events
                            <Tooltip title='Enabling this ensures that you are abke to use customisation on the product level for custom events sent to clevertap.'>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </>
                    ),
                    optional: true,
                    span: 24,
                },
                {
                    type: 'switch',
                    name: ['custom_config', 'analytics_pixel_data', 'clevertap', 'send_charged_event'],
                    label: (
                        <>
                            Send Charged Event Instead of GK_Charged
                            <Tooltip title='Enabling this would mean that instead of our custom GK_Charged event the standard Charged event is sent to Clevertap.'>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </>
                    ),
                    optional: true,
                    span: 24,
                },
                {
                    type: 'switch',
                    name: ['custom_config', 'is_clevertap_s2s_enabled'],
                    optional: true,
                    label: (
                        <>
                            Enable S2S for Charged Events
                            <Tooltip title='Enabling server side events ensure higher events accuracy as compared to client side tracking for Charged (Purchase) event).'>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </>
                    ),
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['clevertap', 'api_url'],
                    label: 'API URL',
                    hidden: !customConfig?.is_clevertap_s2s_enabled,
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['clevertap', 'passcode'],
                    label: 'Passcode',
                    hidden: !customConfig?.is_clevertap_s2s_enabled,
                    span: 24,
                },
                {
                    type: 'input',
                    name: ['clevertap', 'account_id'],
                    label: 'Account ID',
                    hidden: !customConfig?.is_clevertap_s2s_enabled,
                    span: 24,
                },
            ],
        },
        abc_webhook_config: {
            fields: [
                {
                    type: 'input',
                    name: ['abc_webhook_config', 'url'],
                    label: 'Webhook URL',
                    span: 24,
                },
                {
                    type: 'switch',
                    label: 'Enable with KwikChat?',
                    name: ['abc_webhook_config', 'kwikchat_enabled'],
                    span: 24,
                    optional: true,
                },
            ],
        },
    }

    useEffect(() => {
        resetFields && form.resetFields()
    }, [resetFields])

    return (
        <Form
            name={fieldKey + 'Form'}
            form={form}
            key={fieldKey}
            layout='vertical'
            requiredMark='optional'
            className='integration-form'
            initialValues={fields[fieldKey].initialValues}
        >
            <Row gutter={[16, 16]}>
                {fields[fieldKey].fields.map((field, index) =>
                    field.customRender ? (
                        <Col span={field.span} key={index}>
                            {field.customRender()}
                        </Col>
                    ) : field.type === 'alert' ? (
                        <Col span={24} key={index}>
                            <Alert message={field.text} showIcon type={'info'} className='mb-2' />
                        </Col>
                    ) : field.hidden ? null : (
                        <Col
                            span={field.span}
                            key={index}
                            className={field.type === 'switch' ? 'flex justify-between items-center' : ''}
                        >
                            {field.type === 'switch' && <span className='mr-2 font-semibold'>{field.label}</span>}
                            <Form.Item
                                label={field.type !== 'switch' ? field.label : ''}
                                name={field.name}
                                className='label-bold'
                                valuePropName={
                                    field.type === 'switch' ? 'checked' : field.type === 'radio' ? 'value' : undefined
                                }
                                rules={
                                    !field.optional && [
                                        ...(!field.ignoreMandatory
                                            ? [
                                                  {
                                                      required: true,
                                                      message: `Please ${field.type === 'radio' ? 'select' : 'enter'} ${
                                                          field.label
                                                      }`,
                                                  },
                                              ]
                                            : []),
                                        {
                                            validator: (_, value) => {
                                                if (
                                                    (field.type === 'input' || field.type === 'textArea') &&
                                                    value &&
                                                    !`${value}`?.trim()
                                                ) {
                                                    return Promise.reject(new Error('Please enter a valid value'))
                                                }
                                                return Promise.resolve()
                                            },
                                        },

                                        ...(field.label.toLowerCase?.().includes('email') ? [{ type: 'email' }] : []),
                                        ...(field.label.toLowerCase?.().includes('phone') ? [{ type: 'phone' }] : []),
                                        ...(field.label.toLowerCase?.().includes('url') &&
                                        !field.label.toLowerCase?.().includes('store')
                                            ? [{ type: 'url', warningOnly: true }]
                                            : []),
                                        ...(field.rules || []),
                                    ]
                                }
                            >
                                {field.type === 'radio' ? (
                                    <Radio.Group options={field.options} />
                                ) : field.type === 'checkbox' ? (
                                    <Checkbox.Group className={field.className} options={field.options} />
                                ) : field.type === 'switch' ? (
                                    <Switch {...(field.inputProps || {})} />
                                ) : field.type === 'textArea' ? (
                                    <TextArea rows={6} placeholder='Enter your code here' />
                                ) : field.type === 'chip' ? (
                                    <ChipInput
                                        chips={form.getFieldValue(field.name) || []}
                                        onAddChip={(chip) => {
                                            if (!chip.trim()) return
                                            const updatedChips = [...(form.getFieldValue(field.name) || []), chip]
                                            setChips(updatedChips)
                                            form.setFieldValue(field.name, updatedChips)
                                        }}
                                        onRemoveChip={(value) => {
                                            const updatedChips = form
                                                .getFieldValue(field.name)
                                                ?.filter((chip) => chip !== value)
                                            setChips(updatedChips)
                                            form.setFieldValue(field.name, updatedChips)
                                        }}
                                        placeholder='Add tag and press enter'
                                    />
                                ) : field.type === 'inputNumber' ? (
                                    <InputNumber className='w-full' {...(field.inputProps || {})} />
                                ) : field.type === 'select' ? (
                                    <Select
                                        placeholder={`Select ${field.label}`}
                                        className='w-full'
                                        {...(field.inputProps || {})}
                                        options={field.options}
                                    />
                                ) : (
                                    <Input {...(field.inputProps || {})} />
                                )}
                            </Form.Item>
                        </Col>
                    ),
                )}
            </Row>
        </Form>
    )
}

export default RenderForm
