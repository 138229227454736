import { Button, Col, message, Row, Dropdown, Checkbox, Menu, BarsOutlined, Input, Tooltip } from 'gokwik-ui-kit';
import React, { useEffect, useRef, useState } from 'react';
import { makeAPICall } from '@gokwik/utilities';
import APIEndPoints from '@library/utilities/constants/apiEndpoints';
import { useSelector } from 'react-redux';
import { getMerchantDetails, isMerchantSelector } from '@store/user/selectors';
import { handleError } from '@library/utilities/helpers/handleError';
import './column.css'
const RenderColumnPreferences = ({ columns, page, setFilteredColumns, isMerchantUser }) => 
    {
    const merchantDetails = useSelector(getMerchantDetails);

    const [apiResponse, setApiResponse] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [openColumnMenu, setOpenColumnMenu] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [enableSave, setEnableSave] = useState(false);
    const [initialCheckedList, setInitialCheckedList] = useState([]);

    useEffect(() => {
        getColumnPreferences();
    }, []);

    useEffect(() => {
        setCheckedList(apiResponse.filter(col => !col.is_default && col.is_selected).map(col => col.key_name));
        setInitialCheckedList(apiResponse.filter(col => !col.is_default && col.is_selected).map(col => col.key_name));
    }, [apiResponse]);

    const defaultKeys = apiResponse
        .filter(item => item.is_default)
        .map(item => item.key_name);

    const options = columns
                    .filter(col => !defaultKeys.includes(col.dataIndex))
                    .map(({ dataIndex, title }) => ({ label: title, value: dataIndex }));

    useEffect(()=>{
        setFilteredColumns(columns.filter((col) => checkedList.includes(col.dataIndex) || defaultKeys.includes(col.dataIndex)))
    },[checkedList]);
    
    const getColumnPreferences = async () => {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getColumnPreferences,
                params: {
                    page: page,
                    dashboard: isMerchantUser ? 'merchant' : 'admin'
                }
            });
            if (response?.data?.status_code === 200) {
                setApiResponse(response?.data?.data);
            }
        } catch (error) {
            handleError(error);
            message.error("Error fetching column preferences.");
        }
    };

    const columnPreferenceUpdate = async (list) => {
        try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateColumnPreferences,
                payload: {
                    filters: list
                },
                params: {
                    page: page,
                    dashboard: isMerchantUser ? 'merchant' : 'admin'
                }
            });
            if (response?.data?.status_code === 200) {
                message.success("Column Preferences Updated");
                getColumnPreferences();
            }
        } catch (error) {
            message.error("Error updating column preferences.");
        }
    };
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setOpenColumnMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const filteredOptions = options.filter(
        (option) =>
            typeof option.label === 'string' &&
            option.label.toLowerCase().includes(searchText.toLowerCase())
    );

    const handleSelectAll = () => {
        const allValues = filteredOptions.map(({ value }) => value);
        setCheckedList(allValues);
    };

    const handleCheckboxChange = (value) => {
        setCheckedList(prevCheckedList => {
            const newCheckedValues = [...value];
    
            const prevCheckedValues = [...prevCheckedList];
            const filteredOptionsValues = filteredOptions.map(option => option.value);
            prevCheckedValues?.forEach(val => {
                if (!filteredOptionsValues.includes(val)) {
                    newCheckedValues.push(val);
                }
            });
            return newCheckedValues;
        });
    };
    const columnMenu = (
        <div ref={menuRef}>
            <Menu className=' w-60'>
                <div className='p-2'>
                    <Input
                        placeholder='Search columns'
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </div>
                <div className='max-h-60 overflow-y-auto'>
                    <Checkbox.Group
                        value={checkedList}
                        onChange={(value) => handleCheckboxChange(value)}
                        className='flex flex-col p-2'
                    >
                        {filteredOptions.map(({ label, value }) => (
                            <Menu.Item key={value} className='block border-r-0 custom-hover'>
                                <Checkbox value={value}> {label}</Checkbox>
                            </Menu.Item>
                        ))}
                    </Checkbox.Group>
                </div>
                <div className='flex justify-end p-2'>
                    <Button
                        onClick={() => { setOpenColumnMenu(false); columnPreferenceUpdate(checkedList); }}
                        variant='primary'
                        className='mt-1 w-52'
                        disabled={JSON.stringify(initialCheckedList.slice().sort()) === JSON.stringify(checkedList.slice().sort())}
                    >
                        Save
                    </Button>
                </div>
            </Menu>
        </div>
    );

    return (
        <div className="flex justify-end items-center px-4 py-2 absolute top-1 right-0 z-10">
        <Dropdown overlay={columnMenu} open={openColumnMenu} trigger={['click']}>
            <Tooltip title="Column Filters">
                <BarsOutlined className='flex items-center' onClick={() => setOpenColumnMenu(true)} rotate={180}/>
            </Tooltip>
        </Dropdown>
    </div>
    )
};

export default RenderColumnPreferences;


