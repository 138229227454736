import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { SearchedProduct, SelectedProduct } from '@library/utilities/interface'
import { Checkbox, Col, Input, MinusCircleOutlined, Modal, PlusCircleOutlined, Row, Skeleton } from 'gokwik-ui-kit'
import { useCallback, useEffect, useRef, useState } from 'react'

const SearchProducts = ({
    showModal,
    onClose,
    onConfirm,
    alreadySelected,
}: {
    showModal: boolean
    onClose: () => void
    onConfirm: (data: SelectedProduct[]) => void
    alreadySelected: SelectedProduct[]
}) => {
    const [searchLoading, setSearchLoading] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [selectedProduct, setSelectedProduct] = useState<SelectedProduct[]>([])
    const [productList, setProductList] = useState<SearchedProduct[]>([])
    const inputRef = useRef(null)
    const searchProduct = async () => {
        setSearchLoading(true)
        const res = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.productSearch,
            params: {
                name: searchText,
            },
            skipLoader: true,
        })
        if (res.success) {
            setProductList(res.data?.data)
        }
        setSearchLoading(false)
    }

    const removeItem = useCallback(
        (item) => {
            let prodItem = JSON.parse(JSON.stringify(selectedProduct))
            let tmpIdx = selectedProduct.findIndex((itm) => itm.id === item.id)
            if (prodItem[tmpIdx].quantity <= 1) {
                let temp = selectedProduct.filter((itm) => itm.id !== item.id)
                setSelectedProduct((prev) => [...temp])
                return
            }
            prodItem[tmpIdx].quantity--
            setSelectedProduct(() => [...prodItem])
        },
        [selectedProduct],
    )

    const incrementItem = useCallback(
        (item) => {
            let prodItem = JSON.parse(JSON.stringify(selectedProduct))
            let tmpIdx = selectedProduct.findIndex((itm) => itm.id === item.id)
            prodItem[tmpIdx].quantity++
            setSelectedProduct(() => [...prodItem])
        },
        [selectedProduct],
    )

    const addItem = useCallback(
        (item, index?) => {
            let isExit = selectedProduct.find((itm) => itm.id === item.id)
            if (isExit) {
                let temp = selectedProduct.filter((itm) => itm.id !== item.id)
                setSelectedProduct((prev) => [...temp])
            } else {
                setSelectedProduct((prev) => [...prev, { ...item, quantity: 1 }])
            }
        },
        [selectedProduct],
    )
    useEffect(() => {
        if (!searchText || searchText.length < 3) return
        const delayDebounceFn = setTimeout(() => {
            searchProduct()
        }, 400)
        return () => clearTimeout(delayDebounceFn)
    }, [searchText])

    useEffect(() => {
        setSelectedProduct(alreadySelected)
    }, [alreadySelected])

    useEffect(() => {
        if (showModal) {
            console.log({ inputRef: inputRef.current })

            inputRef.current?.focus()
        }
    }, [showModal, inputRef])

    console.log(inputRef.current)

    return (
        <Modal
            open={showModal}
            width={'50vw'}
            closable={false}
            okText={'Add'}
            onOk={() => {
                setProductList([])
                setSearchText('')
                onConfirm(selectedProduct)
            }}
            okButtonProps={{ className: 'px-6' }}
            onCancel={() => {
                setProductList([])
                setSearchText('')
                onClose()
            }}
        >
            <Row gutter={[16, 24]}>
                <Col span={24}>
                    <Input
                        ref={inputRef}
                        placeholder='Search products by entering minimum 3 characters of product name or product ID'
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </Col>
                <Col span={24} className='max-h-[70vh] overflow-y-auto'>
                    <Row gutter={[16, 16]}>
                        {searchLoading ? (
                            <Skeleton />
                        ) : (
                            (searchText ? productList : selectedProduct).map((product) => (
                                <Col key={product.id} span={12}>
                                    <Row wrap={false} gutter={12} align={'middle'}>
                                        <Col>
                                            <Checkbox
                                                onChange={() => addItem(product)}
                                                checked={!!selectedProduct?.find((i) => product.id === i.id)}
                                            />
                                        </Col>
                                        <Col>
                                            <img
                                                src={product.product_thumbnail_url}
                                                alt={product.product_name}
                                                className='max-w-[6rem] max-h-32 rounded-md'
                                            />
                                        </Col>
                                        <Col>
                                            <p className='text-lg cursor-pointer' onClick={() => addItem(product)}>
                                                {product.product_name}
                                                {product.variant_name && (
                                                    <span className='text-base'>({product.variant_name})</span>
                                                )}
                                            </p>

                                            <div className='flex min-h-[40px] gap-x-4 w-full items-center my-4 '>
                                                <span className='font-semibold'>₹ {product.price}</span>
                                                {!!selectedProduct?.find((i) => product.id === i.id) && (
                                                    <div className='flex gap-x-2 items-center'>
                                                        <span>
                                                            <strong className='font-semibold'>Qty</strong>
                                                        </span>

                                                        <div className='border-solid border-[0.4px] border-gray-500 rounded-md px-3 py-2 gap-x-3 flex items-center  '>
                                                            <MinusCircleOutlined
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    removeItem(product)
                                                                }}
                                                                className='cursor-pointer'
                                                            />
                                                            {
                                                                selectedProduct?.find((i) => product.id === i.id)
                                                                    ?.quantity
                                                            }
                                                            <PlusCircleOutlined
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    incrementItem(product)
                                                                }}
                                                                className='cursor-pointer'
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            ))
                        )}
                    </Row>
                </Col>
            </Row>
        </Modal>
    )
}

export default SearchProducts
