import { Button, Col, Divider, Form, InputNumber, QuestionCircleOutlined, Row, Select, Tooltip } from "gokwik-ui-kit"
import { DiscountTypeMapping, PaymentMethodsLabelNameMapping } from "./constants";

const PrepaidDiscountRuleForm = ({ setShowModal, discountInfo, saveConfig, enabledPaymentMethods, tourInProgress = false, discounts = [] }) => {
    const [form] = Form.useForm();

    const initialValues = () => {
        if (discountInfo) {
            return {
                discountCode: discountInfo.discountCode,
                methodType: [discountInfo.methodType],
                lowerLimit: discountInfo.lowerLimit,
                upperLimit: discountInfo.upperLimit,
                cappingLimit: discountInfo.cappingLimit,
                type: discountInfo.type,
                value: discountInfo.value,
                id: discountInfo.id
            }
        } else {
            return {
                discountCode: '',
                methodType: ['upi'],
                lowerLimit: 0,
                type: 'fixed_amount',
                value: null
            }
        }
    }

    function handleOverLappingTiers(methodType, discountValues) {
        const response = {
            success: true,
            errMessage: ''
        }
        const filteredDiscounts = discounts.filter((discount) => methodType.includes(discount.methodType) || methodType.includes('all'));
        const existingDiscountIndex = discounts.sort((a,b) => a.lowerLimit - b.lowerLimit).findIndex(discount => discount.id == discountInfo?.id);

        if (!discountValues.upperLimit) discountValues.upperLimit = Number.MAX_SAFE_INTEGER;

        filteredDiscounts.sort((a,b) => a.lowerLimit - b.lowerLimit).forEach((discount, index) => {
            if (!discountInfo?.id) {
                if (
                    (
                        // checking for overlapping tiers
                        (discountValues.lowerLimit >= +(discount.lowerLimit).toFixed(2) && discountValues.lowerLimit <= +(discount.upperLimit).toFixed(2)) ||
                        (discountValues.upperLimit >= +(discount.lowerLimit).toFixed(2) && discountValues.upperLimit <= +(discount.upperLimit).toFixed(2))
                    ) || 
                    (
                        // checking for engulfing tiers
                        (discountValues.lowerLimit <= +(discount.lowerLimit).toFixed(2) && discountValues.upperLimit >= +(discount.upperLimit).toFixed(2))
                    )
                ) {
                    response.success = false;
                    response.errMessage = `Tier ${discount.lowerLimit.toFixed(2)} - ${discount.upperLimit.toFixed(2)} already exists for ${getPaymentMethodLabel(discount.methodType)} payment method.`
                    if (discount.upperLimit == Number.MAX_SAFE_INTEGER) {
                        response.errMessage = `Cannot add another tier for ${getPaymentMethodLabel(discount.methodType)} payment method! Please update existing tiers first.`
                    }
                    return response;
                }
            } else if (discountInfo?.id != discount.id) {
                if (discountValues.lowerLimit <= +(discount.upperLimit).toFixed(2) && existingDiscountIndex > index) {
                    response.success = false;
                    response.errMessage = `Tier ${discount.lowerLimit.toFixed(2)} - ${discount.upperLimit.toFixed(2)} already exists for ${getPaymentMethodLabel(discount.methodType)} payment method.`
                    if (discount.upperLimit == Number.MAX_SAFE_INTEGER) {
                        response.errMessage = `Cannot add another tier for ${getPaymentMethodLabel(discount.methodType)} payment method! Please update existing tiers first.`
                    }
                    return response;
                }
                if (discountValues.upperLimit >= +(discount.lowerLimit).toFixed(2) && existingDiscountIndex < index) {
                    response.success = false;
                    response.errMessage = `Tier ${discount.lowerLimit.toFixed(2)} - ${discount.upperLimit.toFixed(2)} already exists for ${getPaymentMethodLabel(discount.methodType)} payment method.`
                    if (discount.upperLimit == Number.MAX_SAFE_INTEGER) {
                        response.errMessage = `Cannot add another tier for ${getPaymentMethodLabel(discount.methodType)} payment method! Please update existing tiers first.`
                    }
                    return response;
                }
            }
        })

        return response;
    }

    const createGkPrepaidDiscountConfigPayload = (formData) => {
        const { methodType, ...restProps } = formData

        if (methodType?.includes('all')) {
            return enabledPaymentMethods?.map((paymentMethod) => ({
                ...restProps,
                methodType: paymentMethod,
            }))
        } else {
            return methodType?.map((paymentMethod) => ({
                ...restProps,
                methodType: paymentMethod,
            }))
        }
    }

    const processFormData = (formData) => {
        if (discountInfo?.id) {
            formData.id = discountInfo.id
        } 

        const gkPrepaidDiscountConfig = createGkPrepaidDiscountConfigPayload(formData)

        saveConfig({ gkPrepaidDiscountConfig })    
    }

    function getDecimalValues(values) {
        return {
            lowerLimit: +(values.lowerLimit).toFixed(2),
            ...(
                values.upperLimit && 
                {
                    upperLimit: +(values.upperLimit).toFixed(2)
                }
            ),
            ...(
                values.cappingLimit && 
                {
                    cappingLimit: +(values.cappingLimit).toFixed(2)
                }
            ),
            value: +(values.value).toFixed(2)
        }
    }

    const onFinish = (values) => {
        const formData = {
            ...values,
            ...getDecimalValues(values)
        }
        processFormData(formData)

        form.resetFields()
        setShowModal(false)
    }

    const onDiscard = () => {
        form.resetFields()
        setShowModal(false)
    }

    const getPaymentMethodLabel = (paymentMethod) => {
        if (paymentMethod.includes('wallet')) {
            return paymentMethod
                .split('-')
                .reverse()
                .map((string) => { 
                    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
                })
                .join(' ')
        } else {
            return PaymentMethodsLabelNameMapping[paymentMethod] ?? paymentMethod
        }   
    }

    const getPaymentMethods = () => {
        const response = enabledPaymentMethods?.map((paymentMethod) => {
            return {
                label: getPaymentMethodLabel(paymentMethod),
                value: paymentMethod
            }
        })

        return [...response, {label: 'All Payment Methods', value: 'all'}]
    }

    const getDiscountType = () => {
        return Object.entries(DiscountTypeMapping).map(([key, value]) => {
            return {
                label: value,
                value: key
            }
        })
    }

    return (
        <Form form={form} onFinish={onFinish} layout='vertical' initialValues={initialValues()} className="open-prepaid-discount-modal-tour">
            <Divider orientation='left' />
            <Form.Item
                name='type'
                label={<span style={{ fontWeight: 'bold' }}>Discount Type</span>}
                rules={[
                    {
                        required: true,
                        message: 'Please select the discount type.',
                    },
                ]}
                className="view-prepaid-discount-type-tour"
            >
                 <Select
                    placeholder='Select Discount Type'
                    options = {getDiscountType()}
                />
            </Form.Item>

            <Form.Item
                name='value'
                label={<span style={{ fontWeight: 'bold' }}>Discount Value</span>}
                rules={[
                    {
                        required: true,
                        message: 'Please enter the discount value.'
                    },
                    {
                        message: 'Percentage must be less than 100',
                        validator: async (_, value) => {
                            if (form.getFieldValue(['type']) === 'percentage' && +value > 100) {
                                return Promise.reject(new Error('Percentage must be less than 100'))
                            }
                        },
                    },
                    {
                        message: 'Percentage must cannot be 0',
                        validator: async (_, value) => {
                            if (form.getFieldValue(['type']) === 'percentage' && +value == 100) {
                                return Promise.reject(new Error('Percentage cannot be 0'))
                            }
                        },
                    }
                ]}
            >
                <InputNumber
                    placeholder={'Enter discount value'}
                    style={{ width: '100%' }}
                    min={1}
                    max={Number.MAX_SAFE_INTEGER}
                />
            </Form.Item>

            <div className="view-prepaid-discount-tiers-tour">
                <Form.Item
                    name='lowerLimit'
                    label={
                        <div>
                            <span style={{ fontWeight: 'bold' }}>Discount Lower Limit</span>
                            <Tooltip title={<>The minimum applicable cart value, excluding shipping above which the discount is applicable.</>}>
                                <QuestionCircleOutlined className='ml-2' />{' '}
                            </Tooltip>
                        </div>
                    }
                    rules={[
                        {
                            required: true,
                            message: 'Please enter the discount lower limit value.',
                        },
                        {
                            validator: async (_, value) => {
                                const response = handleOverLappingTiers(form.getFieldValue('methodType'),{lowerLimit: value, upperLimit: form.getFieldValue('upperLimit')});
                                if (!response.success) {
                                    return Promise.reject(new Error(response.errMessage))
                                }
                            } 
                        }
                    ]}
                >
                    <InputNumber
                        placeholder={'Enter discount amount'}
                        style={{ width: '100%' }}
                        min={0}
                        max={Number.MAX_SAFE_INTEGER}
                    />
                </Form.Item>

                <Form.Item
                    name='upperLimit'
                    label={
                        <div>
                            <span style={{ fontWeight: 'bold' }}>Discount Upper Limit</span>
                            <Tooltip title={<>The maximum applicable cart value, excluding shipping below which the discount is applicable.</>}>
                                <QuestionCircleOutlined className='ml-2' />{' '}
                            </Tooltip>
                        </div>
                    }
                    rules={
                        [
                            {
                                validator: async (_, value) => {
                                    const lowerLimitComparator = value === null ? Number.MAX_SAFE_INTEGER : value;
                                    if (value < 0) {
                                        return Promise.reject(new Error('Discount Upper Limit must be positive'))
                                    } else if (value == 0) {
                                        return Promise.reject(new Error('Discount Upper Limit must be greater than 0'))
                                    } else if (form.getFieldValue(['lowerLimit']) && lowerLimitComparator <= +form.getFieldValue(['lowerLimit'])) {
                                        return Promise.reject(new Error('Discount Upper Limit must be greater than Discount Lower Limit'))
                                    }
                                    const response = handleOverLappingTiers(form.getFieldValue('methodType'),{lowerLimit: form.getFieldValue('lowerLimit'), upperLimit: lowerLimitComparator});
                                    if (!response.success) {
                                        return Promise.reject(new Error(response.errMessage))
                                    }
                                }
                            }
                        ]
                    }
                >
                    <InputNumber
                        placeholder={'Enter discount upper limit, leave empty if not applicable'}
                        style={{ width: '100%' }}
                        max={Number.MAX_SAFE_INTEGER}
                    />
                </Form.Item>

                <Form.Item
                    name='cappingLimit'
                    label={
                        <div>
                            <span style={{ fontWeight: 'bold' }}>Discount Capping Limit</span>
                            <Tooltip title={<>The maximum applicable discount amount, it's best suited with "Percentage" type discounts.</>}>
                                <QuestionCircleOutlined className='ml-2' />{' '}
                            </Tooltip>
                        </div> 
                    }
                    rules={[{
                        validator: async (_, value) => {
                            if (value < 0) {
                                return Promise.reject(new Error('Discount Capping Limit must be positive'))
                            } else if (value == 0) {
                                return Promise.reject(new Error('Discount Capping Limit must be greater than 0'))
                            } else if (form.getFieldValue(['type']) === 'fixed' && form.getFieldValue(['lowerLimit']) && value > form.getFieldValue(['lowerLimit'])) {
                                return Promise.reject(new Error('Capping Limit must be lesser than Discount Lower Limit for "Fixed Amount Discount" Type'))
                            }
                        },
                    }]}
                >
                    <InputNumber
                        placeholder={'Enter discount capping limit, leave empty if not applicable'}
                        style={{ width: '100%' }}
                        max={Number.MAX_SAFE_INTEGER}
                    />
                </Form.Item>
            </div>

            <Form.Item
                name='methodType'
                label={<span style={{ fontWeight: 'bold' }}>Discount Payment Method</span>}
                rules={[
                    {
                        required: true,
                        message: 'Please select the payment method on which prepaid discount can be applied.',
                    },
                    {
                        validator: async (_, value) => {
                            const response = handleOverLappingTiers(value,{lowerLimit: form.getFieldValue('lowerLimit'), upperLimit: form.getFieldValue('upperLimit')});
                            if (!response.success) {
                                return Promise.reject(new Error(response.errMessage))
                            }
                        } 
                    }
                ]}
                className="view-prepaid-discount-method-type-tour"
            >
                 <Select
                    placeholder='Select Payment Method'
                    mode='multiple'
                    options = {getPaymentMethods()}
                />                    
            </Form.Item>
            
            <Form.Item>
                <Row justify='end'>
                    <Col>
                        <Button
                            onClick={onDiscard}
                            style={{ marginRight: '8px' }}
                        >
                            Discard
                        </Button>
                    </Col>
                    <Col>
                        <Button type='primary' htmlType='submit' disabled={tourInProgress}>
                            Save Changes
                        </Button>
                    </Col>
                </Row>
            </Form.Item>

        </Form>
    )
}
export default PrepaidDiscountRuleForm