import { titleCase } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import useMerchantConfigApi from '@library/utilities/hooks/useMerchantConfig'
import { SalesChannelDiscount } from '@library/utilities/interface'
import {
    Button,
    Col,
    ColumnsType,
    DeleteOutlined,
    Input,
    PlusOutlined,
    Row,
    Select,
    Table,
    message,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'

const matchTypes = ['exact_match', 'contains', 'starts_with', 'ends_with']

const Inputs = [
    {
        label: 'Discount Code*',
        placeholder: 'Enter Shopify discount code',
        key: 'discount_code',
        component: Input,
    },
    {
        label: 'Match Type*',
        placeholder: 'Select option',
        key: 'match_type',
        component: Select,
        options: matchTypes.map((value) => ({
            value,
            label: titleCase(value.replace('_', ' ')),
        })),
    },
    {
        label: 'Sales Channel*',
        placeholder: 'Select option',
        key: 'channel',
        component: Select,
        options: ['app', 'web'].map((option) => ({
            label: titleCase(option),
            value: option,
        })),
    },
]

const SalesChannelSpecificDiscount = () => {
    const { config, loading, refetch, saveConfig } = useMerchantConfigApi({
        useCache: true,
        configSaveUrl: APIEndPoints.updateDiscountConfig,
    })
    const [updatedDiscountConfig, setUpdatedDiscountConfig] = useState<{
        channel_level_discounts: SalesChannelDiscount[] | []
    }>({
        channel_level_discounts: [],
    })
    const [discountApplicable, setDiscountApplicable] = useState<SalesChannelDiscount>({
        discount_code: null,
        match_type: null,
        channel: null,
    })

    const [discountData, setDiscountData] = useState<{
        channel_level_discounts: SalesChannelDiscount[] | []
    }>({
        channel_level_discounts: [],
    })
    const [isSaved, setIsSaved] = useState(true)

    const discountApplicableAddition = () => {
        if (!discountApplicable.match_type) {
            message.error('Please select match type')
            return
        } else if (!discountApplicable.channel) {
            message.error('Please select channel')
            return
        } else if (!discountApplicable.discount_code) {
            message.error('Please enter discount code')
            return
        }

        const temp = JSON.parse(
            JSON.stringify({
                ...discountApplicable,
            }),
        )

        setDiscountApplicable({
            discount_code: null,
            match_type: null,
            channel: null,
        })

        setUpdatedDiscountConfig((prev) => ({
            ...prev,
            channel_level_discounts: [...(prev.channel_level_discounts || []), { ...temp }],
        }))

        setDiscountData((prev) => ({
            ...prev,
            channel_level_discounts: [...(prev?.channel_level_discounts || []), { ...temp }],
        }))
        setIsSaved(false)
    }

    const discountApplicableDelete = (item: SalesChannelDiscount, idx: number) => {
        const tempUpdated = JSON.parse(JSON.stringify(updatedDiscountConfig))
        let isDeletedDiscountTemporary: boolean
        if (tempUpdated?.channel_level_discounts) {
            isDeletedDiscountTemporary = !!tempUpdated?.channel_level_discounts?.find(
                (itm: SalesChannelDiscount) => itm.discount_code === item.discount_code,
            )
        }
        if (isDeletedDiscountTemporary) {
            setUpdatedDiscountConfig((prev) => ({
                ...prev,
                channel_level_discounts: tempUpdated?.channel_level_discounts?.filter(
                    (itm: SalesChannelDiscount) => itm.discount_code !== item.discount_code,
                ),
            }))
        } else {
            setUpdatedDiscountConfig((prev) => ({
                ...prev,
                channel_level_discounts: [...(prev?.channel_level_discounts || [])],
            }))
        }
        setDiscountData((prev) => ({
            ...prev,
            channel_level_discounts: prev.channel_level_discounts.filter((it, idex) => idex != idx),
        }))
        setIsSaved(false)
    }

    useEffect(() => {
        if (config) {
            setDiscountData({
                channel_level_discounts: config.channel_level_discounts,
            })
        }
    }, [config])

    const columns: ColumnsType<any> = [
        {
            title: 'Match Type',
            render: (text, record, index) => titleCase(record.match_type.replace('_', ' ')),
        },
        {
            title: 'Sales Channel',
            render: (text, record, index) => titleCase(record.channel),
        },
        {
            title: 'Discount Code',
            dataIndex: 'discount_code',
            key: 'discount_code',
        },
        ...(discountData.channel_level_discounts && discountData.channel_level_discounts.length > 0
            ? [
                  {
                      title: '',
                      key: 'action',
                      render: (text, record, index) => (
                          <DeleteOutlined
                              className='text-error-500'
                              onClick={() => discountApplicableDelete(record, index)}
                          />
                      ),
                  },
              ]
            : []),
    ]

    const handleChange = (value: any, keys: string[], temp: any) => {
        if (keys.length === 1) {
            temp[keys[0]] = value
            return temp
        } else {
            return { ...temp, [keys[0]]: { ...handleChange(value, keys.slice(1), temp[keys[0]]) } }
        }
    }

    const isSaveDisabled = isSaved

    return (
        <Col className='bg-white' span={24}>
            <Row gutter={[16, 16]} align={'bottom'}>
                {Inputs.map((input, index) => (
                    <Col span={4} key={index}>
                        <input.component
                            className='w-full '
                            placeholder={input.placeholder}
                            label={input.label}
                            value={discountApplicable[input.key]}
                            options={input.options}
                            onChange={(e: any) =>
                                setDiscountApplicable((prev) =>
                                    typeof e === 'object'
                                        ? { ...prev, [input.key]: e.target.value }
                                        : { ...prev, [input.key]: e },
                                )
                            }
                        />
                    </Col>
                ))}

                <Col span={4}>
                    <Button onClick={discountApplicableAddition}>
                        <PlusOutlined /> Add New Discount
                    </Button>
                </Col>
                <Col span={24}>
                    {discountData && (
                        <Table dataSource={discountData.channel_level_discounts} columns={columns} pagination={false} />
                    )}
                </Col>
                <Col>
                    <Button
                        variant='primary'
                        disabled={isSaveDisabled}
                        onClick={() => {
                            saveConfig(
                                {
                                    ...updatedDiscountConfig,
                                    channel_level_discounts: discountData.channel_level_discounts,
                                },
                                () => {
                                    setUpdatedDiscountConfig({ channel_level_discounts: [] })
                                    setIsSaved(true)
                                },
                            )
                        }}
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        </Col>
    )
}

export default SalesChannelSpecificDiscount
