import React, { FC, useEffect, useState } from 'react'
import { DiscountFormState } from '../helpers/useCreateDiscount'
import { CardLayout } from './cardLayout'
import { Col, DatePicker, Form, Row, Switch } from 'gokwik-ui-kit'
import dayjs from 'dayjs'

interface DiscountTimeCardProps {
    formState?: DiscountFormState
}
dayjs.locale('en')

const Card: FC<DiscountTimeCardProps> = (props) => {
    const { formState } = props
    const [showEndDateTime, setShowEndDateTime] = useState(formState?.end_date ? true : false)

    useEffect(() => {
        if (formState?.end_date) {
            setShowEndDateTime(true)
        } else {
            setShowEndDateTime(false)
        }
    }, [formState])

    const onSwitchChange = (checkedValue) => {
        setShowEndDateTime(checkedValue)
        if(!checkedValue) {
            formState.end_date = null
        }
    }
    return (
        <CardLayout title='Specify the start and end dates for your discount'>
            <div className='flex justify-end'>
                <label className='flex items-center gap-1'>
                    Set End Date
                    <Switch checked={showEndDateTime} onChange={onSwitchChange} />
                </label>
            </div>
            <br />

            <Row gutter={12}>
                <Col span={12}>
                    <Form.Item
                        initialValue={formState?.start_date ? dayjs(formState?.start_date) : dayjs()}
                        name='start_date'
                        rules={[
                            {
                                required: true,
                                message: 'Start date is required',
                            },
                        ]}
                    >
                        <DatePicker
                            placeholder='Select start date & time'
                            className='w-full'
                            size='middle'
                            // use12Hours
                            format={'MMMM DD, YYYY : hh:mm A'}
                            allowClear
                            showTime
                        />
                    </Form.Item>
                </Col>

                {showEndDateTime && (
                    <Col span={12}>
                        <Form.Item
                            initialValue={formState?.end_date ? dayjs(formState?.end_date) : undefined}
                            name='end_date'
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (value && getFieldValue('start_date').isBefore(value)) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject('End date must be after start date')
                                    },
                                }),
                            ]}
                        >
                            <DatePicker
                                placeholder='Select end date & time'
                                className='w-full'
                                size='middle'
                                // use12Hours
                                format={'MMMM DD, YYYY : hh:mm A'}
                                allowClear
                                showTime
                            />
                        </Form.Item>
                    </Col>
                )}
            </Row>
        </CardLayout>
    )
}

export const DiscountScheduleCard = React.memo(Card)
