import { FC, useEffect, useState } from 'react'
import { CardLayout } from './cardLayout'
import {
    Input,
    Radio,
    Space,
    Row,
    Button,
    Col,
    Form,
    Divider,
    Select,
    FileFilled,
    FolderFilled,
    Switch,
    Checkbox,
    message,
    CloseOutlined,
    FileImageOutlined,
    InputNumber,
} from 'gokwik-ui-kit'
import { DiscountFormState } from '../helpers/useCreateDiscount'
import React from 'react'
import { ProductsOrCollectionsSearchModal } from './products&CollectionsSearchModal'

interface DiscountBuyXYFunctionCardProps {
    setFormState?: any
    discountAction?: any
    formState?: any
}

const Card: FC<DiscountBuyXYFunctionCardProps> = (props) => {
    const { setFormState, discountAction, formState } = props
    const [showProductSection, setShowProductSection] = useState(true)
    const [showMaxInput, setShowMaxInput] = useState(false)
    const [showProductSelectionModal, setShowProductSelectionModal] = useState(false)
    const [category, setCategory] = useState(updateCategory())
    const [searchValue, setSearchValue] = useState('')
    const [modalProps, setModalProps] = useState({ title: '', searchPlaceholder: 'Search products' })
    const [selectedProducts, setSelectedProducts] = useState(formState?.rules[0]?.conditions[0]?.selected_products || [])
    const [selectedProductVariants, setSelectedProductVariants] = useState(formState?.rules[0]?.conditions[0]?.selected_product_variants || [])
    const [renderProductMapping, setRenderProductMapping] = useState(formState?.rules[0]?.conditions[0]?.product_name_mapping || []);
    const [selectedCollections, setSelectedCollections] = useState(formState?.rules[0]?.conditions[0]?.collection_name_mapping || [])
    const [showCheckedListTags, setShowCheckedListTags] = useState(false)
    const [quantity, setQuantity] = useState(null)
    const [bundleValue, setBundleValue] = useState(null)
    
   
    
    function updateCategory() {
        return formState?.rules[0]?.conditions[0]?.collection_name_mapping?.length > 0 ? 'specific_collections' : 'specific_products'
    }

    useEffect(() => {
        if (category) {
            if (category === 'specific_products') {
                setModalProps({
                    title: 'Choose from products',
                    searchPlaceholder: 'Search products',
                })

            } else {
                setModalProps({
                    title: 'Choose from collections',
                    searchPlaceholder: 'Search collections',
                })
            }
        }
    }, [category])


    const handleBrowseProduct = () => {
        if (!category) return message.error('Please select a category of items from which to browse')
        setShowProductSelectionModal(true)
    }

    const handleInputBrowseInputChange = (e) => {
        if (!category && e.target.value) {
            message.error('Please select a category of items from which to browse')
            return
        }
        if (category && e.target.value) {
            setSearchValue(e.target.value)
            setShowProductSelectionModal(true)
        }
    }

    const handleCategoryChange = (value) => {
        setCategory(value)
    }

    const removeProductFromList = (product_id) => {
        setSelectedProducts((prevProducts) => prevProducts?.filter((product) => product?.product_id !== product_id))
        setSelectedProductVariants((prevVariants) => prevVariants?.filter((variant) => variant?.product_id !== product_id))
        setRenderProductMapping((prevProducts) => prevProducts?.filter((product) => product?.product_id !== product_id))
        setFormState((prev) => {
            const temp = JSON.parse(JSON.stringify(prev))

            temp.rules[0].conditions[0].product_name_mapping = temp?.rules[0]?.conditions[0]?.product_name_mapping?.filter(
                (item) => item?.product_id !== product_id,
            )
            temp.rules[0].conditions[0].applicable_list = temp?.rules[0]?.conditions[0]?.applicable_list?.filter(
                (item) => item !== product_id,
            )
            temp.rules[0].conditions[0].product_variant_applicable_list = temp?.rules[0]?.conditions[0]?.product_variant_applicable_list?.filter(variant => {
                return temp?.rules[0]?.conditions[0]?.product_name_mapping?.some(product =>
                    product?.variant_ids?.some(selectedVariant => selectedVariant?.variant_id === variant)
                );
            });
            temp.rules[0].conditions[0].selected_products = temp?.rules[0]?.conditions[0]?.selected_products?.filter(
                (item) => item?.product_id !== product_id,
            )
            temp.rules[0].conditions[0].selected_product_variants = temp?.rules[0]?.conditions[0]?.selected_product_variants?.filter(
                (item) => item?.product_id !== product_id,
            )
            return temp
        })
    }
    const removeCollectionFromList = (id) => {
        setSelectedCollections((prevCollections) => prevCollections.filter((collection) => collection.collection_id !== id))
        setFormState((prev) => {
            const temp = JSON.parse(JSON.stringify(prev))

            temp.rules[0].conditions[0].collection_name_mapping = temp.rules[0].conditions[0].collection_name_mapping?.filter(
                (item) => item?.collection_id !== id,
            )
            temp.rules[0].conditions[0].applicable_list = temp.rules[0].conditions[0].applicable_list?.filter(
                (item) => item !== id,
            )
            return temp
        })
    }

    const handleQuantityChange = (e) => {
        setQuantity(e?.target?.value)
        setFormState((prev) => {
            const temp = JSON.parse(JSON.stringify(prev))
            temp.rules[0].conditions[0].min_product_quantity = Number(e?.target?.value)
            return temp
        })
    }
    const handleValueChange = (e) => {
        setBundleValue(e?.target?.value)
        setFormState((prev) => {
            const temp = JSON.parse(JSON.stringify(prev))
            temp.rules[0].actions[0].value = Number(e?.target?.value)
            return temp
        })
    }

    const formatter = (value) => {
        return `${value}`.replace(/[^\d]/g, '').replace(/^0+/, '')
    }

    const parser = (value) => {
        return value.replace(/[^\d]/g, '').replace(/^0+/, '')
    }

    useEffect(() => {
        if (category === 'specific_products') {
            setFormState((prev) => {
                const temp = JSON.parse(JSON.stringify(prev))
                temp.rules[0].conditions[0].product_name_mapping = renderProductMapping?.map((item) => ({
                    product_name: item?.product_name,
                    product_id: item?.product_id,
                    variant_ids: item?.variant_ids?.length > 0 ? item?.variant_ids.map(variant => ({
                            variant_id: variant?.variant_id,
                            variant_name: variant?.variant_name,
                        })) : [],
                }))
                temp.rules[0].conditions[0].product_attribute = 'ProductId'
                temp.rules[0].actions[0].product_attribute = 'ProductId'
                temp.rules[0].conditions[0].applicable_list = renderProductMapping?.filter(item => item?.variant_ids?.length === 0).map(item => item?.product_id);

                if ('collection_name_mapping' in temp.rules[0].conditions[0]) {
                    delete temp.rules[0].conditions[0].collection_name_mapping;
                }
                 return temp
            })
        } else if (category === 'specific_collections') {
            setFormState((prev) => {
                const temp = JSON.parse(JSON.stringify(prev))
                temp.rules[0].conditions[0].collection_name_mapping = selectedCollections?.map((item) => ({
                    collection_id: item?.collection_id,
                    collection_name: item?.collection_name,
                }))
                temp.rules[0].conditions[0].applicable_list = selectedCollections?.map(item => item.collection_id)
                temp.rules[0].conditions[0].product_attribute = 'CollectionId'
                temp.rules[0].actions[0].product_attribute = 'CollectionId'
                if ('product_name_mapping' in temp.rules[0].conditions[0]) {
                    delete temp.rules[0].conditions[0].product_name_mapping;
                }
                 return temp
            })
        }
    }, [category])

    useEffect(() => {
        const products = formState?.rules[0]?.conditions[0]?.product_name_mapping || []
        if(products.length > 0 ) {
            setRenderProductMapping(products)
            setCategory('specific_products')
         }
    }, [discountAction && formState?.rules[0]?.conditions[0]?.product_name_mapping?.length])

    useEffect(() => {
        if (
            formState?.rules &&
            formState?.rules?.length &&
            formState?.rules[0]?.conditions[0]?.selected_products?.length
        ) {
            setSelectedProducts(formState?.rules[0]?.conditions[0]?.selected_products)
            setSelectedProductVariants(formState?.rules[0]?.conditions[0]?.selected_product_variants)
            setRenderProductMapping(formState?.rules[0]?.conditions[0]?.product_name_mapping);
        }
    }, [formState?.rules[0]?.conditions[0]?.selected_product_variants])

    useEffect(() => {
        const collections = formState?.rules[0]?.conditions[0]?.collection_name_mapping || []
        if(collections.length > 0) {
             setSelectedCollections(collections)
            setCategory('specific_collections')
         }
    }, [discountAction && formState?.rules[0]?.conditions[0]?.collection_name_mapping?.length])
 
    return (
        <> 
            {showProductSelectionModal && (
                <ProductsOrCollectionsSearchModal
                    showProductSelectionModal={showProductSelectionModal}
                    setShowProductSelectionModal={setShowProductSelectionModal}
                    modalProps={modalProps}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    category={category}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                    setRenderProductMapping={setRenderProductMapping}
                    selectedProductVariants={selectedProductVariants}
                    setSelectedProductVariants={setSelectedProductVariants}
                    selectedCollections={selectedCollections}
                    setSelectedCollections={setSelectedCollections}
                    setShowCheckedListTags={setShowCheckedListTags}
                    setFormState={setFormState}
                    formState={formState}
                />
            )}
            <CardLayout title='How should this discount function? Set the buy and get parameters'>
                <p className=' text-sm font-semibold mt-4'>Customer buys</p>
                <p className='text-[#00000080] text-sm font-normal'>
                    Select whether the discount triggers by quantity of items purchased or a minimum spend. Specify the
                    number of items or the purchase amount required
                </p>
                <Form.List name={['rules']}>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <div key={key}>
                                    {/* <Form.Item name='buy_get_params' className='my-4'>
                                        <Radio.Group
                                            onChange={(e) => {
                                                console.log(e)

                                                e?.target?.value === 'min_purchase_amount'
                                                    ? setShowProductSection(false)
                                                    : setShowProductSection(true)
                                            }}
                                            defaultValue={'min_purchase_amount'}
                                        >
                                            <Space direction='horizontal'>
                                                <Radio value={'min_purchase_amount'}>Minimum purchase amount</Radio>
                                                <Radio value={'min_quantity_items'}>Minimum Quantity of Items</Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item> */}

                                    <div>
                                        <Row gutter={[12, 0]}>
                                            <Col span={5}>
                                                {!showProductSection ? (
                                                    <Form.Item>
                                                        <InputNumber
                                                            min={1}
                                                            label='Amount'
                                                            placeholder=''
                                                            name='min_amount'
                                                        />
                                                    </Form.Item>
                                                ) : (
                                                    <Form.Item
                                                    // {...restField}
                                                    // name={[0, 'conditions', 0, 'min_product_quantity']}
                                                    >
                                                        <InputNumber
                                                            required
                                                            onChange={(value) =>
                                                                handleQuantityChange({ target: { value: value } })
                                                            }
                                                            type='number'
                                                            min={1}
                                                            label='Quantity'
                                                            placeholder=''
                                                            name='min_quantity'
                                                            value={
                                                                (formState?.rules &&
                                                                    formState?.rules?.length &&
                                                                    formState?.rules[0]?.conditions[0]
                                                                        ?.min_product_quantity) ||
                                                                quantity
                                                            }
                                                            formatter={formatter}
                                                            parser={parser}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Col>

                                            <Col span={19}>
                                                {/* <Form.Item> */}
                                                <Select
                                                    label='Any items from'
                                                    placeholder=''
                                                    // allowClear
                                                    onChange={handleCategoryChange}
                                                    defaultValue={category}
                                                    value={category}
                                                >
                                                    <Select.Option value='specific_products'>
                                                        Specific Products
                                                    </Select.Option>
                                                    <Select.Option value='specific_collections'>Specific Collections</Select.Option>
                                                </Select>
                                                {/* </Form.Item> */}
                                            </Col>
                                        </Row>
                                        <Row gutter={[12, 0]}>
                                            <Col span={18}>
                                                <Form.Item>
                                                    <Input
                                                        value={searchValue}
                                                        allowClear
                                                        placeholder={modalProps?.searchPlaceholder}
                                                        onChange={handleInputBrowseInputChange}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item>
                                                    <Button className='w-full' onClick={handleBrowseProduct}>
                                                        <FolderFilled /> Browse
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>

                                    {/* check for show checkedlisttags flag */}

                                    {category === 'specific_products' ? renderProductMapping && renderProductMapping?.length > 0 && (
                                        <div className='flex gap-2 flex-wrap'>
                                            {renderProductMapping?.map((item, index) => {
                                                return (
                                                    <div className='border border-solid border-[#00000026] rounded-lg' key={index}>
                                                        <div className='flex justify-between gap-2 p-1 min-w-max items-center'>
                                                            <div className='flex gap-1 items-center truncate'>
                                                                <span className='bg-[#627F99] rounded-md'>
                                                                    <FileImageOutlined className='text-sm text-white p-[6px]' />
                                                                </span>
                                                                <span className='text-sm font-semibold truncate'>
                                                                    {item?.product_name}
                                                                </span>
                                                            </div>

                                                            <div
                                                                className={
                                                                    discountAction === 'view'
                                                                        ? 'pointer-events-none'
                                                                        : 'cursor-pointer'
                                                                }
                                                                onClick={() => removeProductFromList(item?.product_id)}
                                                            >
                                                                <CloseOutlined className='text-[#00000073]' />
                                                            </div>
                                                        </div>
                                                        <Divider className='my-1'/>
                                                        <div>
                                                            {item?.variant_ids?.length > 0 ? (
                                                                <ul className='list-none m-0 p-0'>
                                                                    {item.variant_ids.map((variant, vIndex) => (
                                                                        <li key={vIndex} className='py-1 text-sm text-gray-500 flex items-center ml-1'>
                                                                            <span className='bg-gray-200 rounded-full px-2 py-1 text-xs mr-1'>{vIndex + 1}</span>
                                                                            {variant?.variant_name}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            ) : (
                                                                <p className='text-sm text-gray-500 mr-1 ml-1'>All variants selected</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    ) :selectedCollections && selectedCollections?.length > 0 && (
                                        <div className='flex gap-2 flex-wrap'>
                                            {selectedCollections?.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div className='border border-solid border-[#00000026] rounded-lg flex justify-between gap-2 p-1 min-w-max items-center'>
                                                            <div className='flex gap-1 items-center truncate'>
                                                                <span className='bg-[#627F99] rounded-md'>
                                                                    <FileImageOutlined className='text-sm text-white p-[6px]' />
                                                                </span>
                                                                <span className='text-sm font-semibold truncate'>
                                                                    {item?.collection_name}
                                                                </span>
                                                            </div>

                                                            <div
                                                                className={
                                                                    discountAction === 'view'
                                                                        ? 'pointer-events-none'
                                                                        : 'cursor-pointer'
                                                                }
                                                                onClick={() => removeCollectionFromList(item?.collection_id)}
                                                            >
                                                                <CloseOutlined className='text-[#00000073]' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}

                                    {/* {showCheckedListTags && } */}

                                    {/* <Form.Item {...restField} name={[0, 'conditions', 0, 'applicable_list']} hidden>
                                        <Select mode='tags'>
                                            {selectedProducts?.map((product, i) => (
                                                <Select.Option value={product?.product_id} key={i}>
                                                    1
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item> */}

                                    <Divider className=' mt-2' />
                                    <p className='text-sm font-semibold mt-4'>Customer gets</p>
                                    <p className='text-[#00000080] text-sm font-normal'>
                                        Set the fixed discounted price for the qualifying bundle. This special offer
                                        applies once customers meets the above 'Buy X' conditions
                                    </p>

                                    <InputNumber
                                        required
                                        value={
                                            (formState?.rules &&
                                                formState?.rules?.length &&
                                                formState?.rules[0]?.actions[0]?.value) ||
                                            bundleValue
                                        }
                                        onChange={(value) => handleValueChange({ target: { value: value } })}
                                        type='number'
                                        min={1}
                                        placeholder='Enter value'
                                        label='Discounted value for each bundle in ₹'
                                        className='w-full'
                                        formatter={formatter}
                                        parser={parser}
                                    />

                                    {/* <Form.Item className='m-0'>
                                        <Row className='gap-2'>
                                            <Checkbox
                                                onChange={(e) =>
                                                    e?.target?.checked ? setShowMaxInput(true) : setShowMaxInput(false)
                                                }
                                            >
                                                Set the maximum number of uses per order
                                            </Checkbox>
                                        </Row>
                                    </Form.Item> */}
                                </div>
                            ))}
                        </>
                    )}
                </Form.List>
            </CardLayout>
        </>
    )
}

export const DiscountBuyXYFunction = React.memo(Card)
