import { AnalyticsDate, ConversionData } from '@library/utilities/interface'
import { Col, Row } from 'gokwik-ui-kit'
import { useCallback, useMemo } from 'react'

const texts = {
    CheckoutStarted: 'Checkout Initiated',
    addressLanded: 'Address Step Reached',
    paymentMethodSelected: 'Payment Method Selected',
    paymentStepReached: 'Payment Step Reached',
    successfulOrders: 'Sessions Converted',
    AddressStepReached: 'Address Step Reached',
    AddressPrefilled: 'Address Prefilled',
}
const maxHeights = 210
const checkoutAnalyticsNumberFormatter = (val) =>
    Intl.NumberFormat('en-IN', {
        notation: 'compact',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    })
        .format(val || 0)
        .replace('T', 'K')

interface FunnelProps {
    dates: { start: AnalyticsDate; end: AnalyticsDate }
    data: ConversionData
    hideComparison?: boolean
}
const FunnelChart = ({ dates, data, hideComparison }: FunnelProps) => {
    const getMax = useCallback(
        (values: number[]) => Math.max(values[0] || 0, (!hideComparison && values[1]) || 0),
        [hideComparison],
    )
    const baseValues = useMemo(
        () => data.conversionFunnel?.map((funnel) => getMax([+funnel.primaryValue, +funnel.secondaryValue])),
        [data?.conversionFunnel, hideComparison],
    )

    const showSecondary = useMemo(() => {
        if (!data) return true
        return data.conversionFunnel?.some((funnel) => funnel.secondaryValue)
    }, data?.conversionFunnel)
    return (
        <Row className='flex h-full inter'>
            <Col span={24}>
                {data?.conversion || data?.conversionFunnel ? (
                    <Row className='h-full' gutter={[0, 16]} align={'bottom'} justify={'space-between'}>
                        {data.conversionFunnel?.map((funnel, i) => (
                            <Col
                                key={funnel.event}
                                className={` flex items-end`}
                                style={{
                                    width: `${100 / data.conversionFunnel.length - 1}%`,
                                }}
                            >
                                <div className='w-full flex flex-col justify-end bg-primary-25 rounded-t px-4 pt-4'>
                                    <span className='text-sm inter font-semibold text-gray-500'>
                                        {texts[funnel.event]}
                                    </span>
                                    <span className='text-xs inter font-medium text-gray-400'>
                                        {(+funnel.conversion).toString()}%
                                    </span>
                                    <div className='columns flex items-end  mt-3'>
                                        <div
                                            className={`min-h-[30px] bg-primary-500 text-white bora-3 text-center py-2 w-1/2 rounded-t`}
                                            style={{
                                                height: `${
                                                    (maxHeights * +funnel.primaryValue * (funnel.conversion / 100)) /
                                                    baseValues[i]
                                                }px`,
                                            }}
                                        >
                                            {checkoutAnalyticsNumberFormatter(+funnel.primaryValue)}
                                        </div>
                                        {!hideComparison && (funnel.secondaryValue || funnel.secondaryValue === 0) && (
                                            <div
                                                className={`min-h-[30px] bg-primary-100 text-white bora-3 text-center py-2 w-1/2 rounded-t`}
                                                style={{
                                                    height: `${
                                                        (maxHeights *
                                                            +funnel.secondaryValue *
                                                            (funnel.conversion / 100)) /
                                                        baseValues[i]
                                                    }px`,
                                                }}
                                            >
                                                {checkoutAnalyticsNumberFormatter(+funnel.secondaryValue)}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        ))}
                        <Col span={24} className='legends flex'>
                            <Row gutter={16}>
                                <Col className='flex items-center gap-x-1'>
                                    <div className={`rounded bg-primary-500 w-3 h-3 mr-1`} />
                                    <span className='text-gray-500 font-normal text-xs'>{dates.start.label}</span>
                                </Col>
                                {!hideComparison && showSecondary && (
                                    <Col className='flex items-center gap-x-1'>
                                        <div className={`rounded bg-primary-100 w-3 h-3 mr-1`} />
                                        <span className='text-gray-500 font-normal text-xs'>{dates.end.label}</span>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                ) : (
                    <div className='h-full flex items-center justify-center'>
                        <p className='text-center lowercase first-letter:uppercase'>
                            {typeof data === 'string' ? data : 'No Data'}
                        </p>
                    </div>
                )}
            </Col>
        </Row>
    )
}

export default FunnelChart
